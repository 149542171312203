import Dadata from '@classes/Dadata/Dadata.ts';
import { addCursorList, deleteCursorList } from '@functions/listsHandler.ts';

import I from '../types.ts';

const cityListHandler: I['cityListHandler'] = async function ({ value, e }) {
    const { prop, withCitySupport, cardName, name, onChange } = this.props;
    const listId = `${cardName}-${name}`;

    if (withCitySupport) {
        if (value && value.length > 2) {
            if (this.cityListTimer) {
                clearTimeout(this.cityListTimer);
            }

            this.cityListTimer = setTimeout(async () => {
                const result = await new Dadata().get(value);

                if (result.length) {
                    addCursorList({
                        e: e!,
                        list: {
                            _id: listId,
                            type: 'staticList',
                            dir: 'bottom',
                            items: result.map((item) => ({ _id: item, content: item })),
                            callback: (modelId, model: any) => {
                                if (model) {
                                    const div = document.createElement('div');

                                    div.innerHTML = model.content;

                                    onChange({ [prop]: div.innerText });

                                    deleteCursorList({ id: listId });
                                }
                            },
                            checkClose: () => !document.querySelector('.body__v2popup._filter'),
                        },
                        settings: { targetClassName: '.v2widgetField__field' },
                    });
                } else {
                    deleteCursorList({ id: listId });
                }
            }, 500);
        } else {
            deleteCursorList({ id: listId });
        }
    }
};

export default cityListHandler;
