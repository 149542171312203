import getRealParams, { RealParamsItemT } from '@functions/getRealParams.ts';
import removeTransition from '@functions/removeTransition.ts';

import ListAbsoluteI from '../types.ts';

const getParams: ListAbsoluteI['getParams'] = function (props = {}) {
    const {
        parent,
        items,
        classNameItem,
        setParamsParent,
        paramsParent = {},
        isClearStyleParent = true,
        clearStyleElems = [],
        withoutParams,
        isSmoothShow = false,
        isNotParams,
        isTransform = true,
        classNameInner,
        parentWidthOffset = 0,
        isClearStyleElems,
    } = this.props;
    const classNames = ['_static', ...(this.props.classNames || [])];
    let resultClearStyleElems = [
        { className: `.${this.getClass(classNameItem)}`, params: ['width', 'height'] },
        ...clearStyleElems,
    ];

    // console.log(`.${classNameItem.split(' ').join('.')}`)

    if (classNameInner) {
        resultClearStyleElems.push({
            className: `.${this.getClass(classNameInner)}`,
            params: ['width'],
        });
    }

    if (isClearStyleElems === false) {
        resultClearStyleElems = [];
    }

    return new Promise((resolve) => {
        const resultParams = this.state.resultParams || {};

        if (isNotParams) {
            if (!this.state.isInit && !isSmoothShow) {
                removeTransition({ item: `.${classNameItem}`, isCurrent: true });

                this.setState({ isInit: true });
            }

            resolve();
        } else if (parent) {
            const elems: { className: string; id: string }[] = items.map((item) => ({
                className: `.${this.getClass(classNameItem)}[data-${this.getProp()}="${
                    item[this.getProp()]
                }"]`,
                id: item[this.getProp()] as string,
            }));

            if (classNameInner) {
                elems.push({
                    className: `.${classNameInner}`,
                    id: 'inner',
                });
            }

            const settings: {
                parent: HTMLElement;
                elems: { className: string; id: string }[];
                classNames?: string[];
                isClearStyleParent?: boolean;
                clearStyleElems?: { className: string; params: string[] }[];
                isNotRemove: boolean;
                isTransform: boolean;
                width?: number | string;
                height?: number | string;
                name?: string;
            } = {
                parent,
                elems,
                classNames,
                isClearStyleParent,
                clearStyleElems: resultClearStyleElems,
                isNotRemove: this.props.name === 'test',
                isTransform,
                name: this.props.name,
                ...props,
            };

            if (paramsParent.width) {
                if (typeof paramsParent.width === 'number') {
                    settings.width = paramsParent.width;
                } else {
                    settings.width =
                        paramsParent.width === 'auto'
                            ? 'auto'
                            : parent.offsetWidth + (parentWidthOffset || 0);
                }
            }

            if (paramsParent.height) {
                if (typeof paramsParent.height === 'number') {
                    settings.height = paramsParent.height;
                } else {
                    settings.height = paramsParent.height === 'auto' ? 'auto' : parent.offsetHeight;
                }
            }

            const params = getRealParams(settings);

            items.forEach((item) => {
                if (params[item[this.getProp()] as keyof typeof item]) {
                    resultParams[item[this.getProp()] as keyof typeof item] = params[
                        item[this.getProp()] as keyof typeof item
                    ] as RealParamsItemT;
                }
            });

            if (params.inner) {
                const innerElem = parent.querySelector(`.${classNameInner}`) as HTMLElement;

                if (innerElem) {
                    innerElem.style.width = `${(params.inner as RealParamsItemT).width}px`;
                }
            }

            const setParams = (): Promise<void> =>
                new Promise((resolveParams) => {
                    if (withoutParams || !params.condForChange) {
                        resolveParams();
                    } else {
                        this.setState(
                            { resultParams: JSON.parse(JSON.stringify(resultParams)) },
                            resolveParams,
                        );
                    }
                });

            setParams().then(() => {
                if (!this.state.isInit && !isSmoothShow) {
                    removeTransition({ item: `.${classNameItem}`, isCurrent: true });

                    this.setState({ isInit: true });
                }
                if (setParamsParent) {
                    setParamsParent(params.parent);
                }

                resolve();
            });
        } else {
            resolve();
        }
    });
};

export default getParams;
