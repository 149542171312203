import { MouseEvent } from 'react';

import { setCursorActions } from '@functions/actionsHandler.ts';
import copyInBuffer from '@functions/copyInBuffer.js';
import getPageLink from '@functions/getPageLink.ts';

import I from '../types.ts';

const contextMenuHandler: I['contextMenuHandler'] = function (e) {
    const ref = this.parent.current as HTMLElement;
    const { pageName, storePages, ids } = this.props;

    if (
        process.env.REACT_APP_SYSTEM === 'crm' &&
        (ref === e.target || ref.contains(e.target as HTMLElement))
    ) {
        let href = this.getHref(this.props.href ?? this.state.href);

        href = getPageLink({ name: pageName as string, storePages, ids });

        if (href) {
            href = `${process.env.REACT_APP_CRM_DOMEN}/${href}`;
        }

        const props = setCursorActions(
            {
                _id: `contextMenu-${this.contextId}`,
                dir: 'top',
                withCursor: true,
                items: [
                    {
                        key: 'targetBlank',
                        name: 'targetBlank',
                        onClick: async () => {
                            window.open(href, '_blank');
                        },
                    },
                    {
                        key: 'copyLink',
                        name: 'copyLink',
                        onClick: async () => {
                            copyInBuffer(href);
                        },
                    },
                ],
            },
            { targetClassName: '._CLICK', stopPropagation: true },
        );

        console.log(props);

        props.onClick(e as unknown as MouseEvent);
    }
};

export default contextMenuHandler;
