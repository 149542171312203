import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Ymaps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.ymapsLoad = this.ymapsLoad.bind(this);

        this.parent = React.createRef();
    }

    apikey =
        process.env.REACT_APP_ENV === 'prod'
            ? '203d747e-25c0-4295-a482-f527dd1c6be9'
            : '16967adf-21da-4bec-9bc0-7b65f38fc8f2';

    bootstrap() {
        const { center = [55.755819, 37.617644], zoom = 10, callback } = this.props;

        const { ymaps } = window;

        const map = new ymaps.Map(this.parent.current, {
            center,
            zoom,
            controls: [],
        });

        if (callback) {
            callback({ ymaps, map });
        }
    }

    checkTimerId;

    ymapsLoad() {
        window.isProccessLoadingYmap = false;

        this.init();

        clearTimeout(this.checkTimerId);
    }

    init() {
        if (window.isProccessLoadingYmap && !this.isWaitLoad) {
            this.isWaitLoad = true;

            this.checkTimerId = setTimeout(() => {
                if (window.ymaps) {
                    window.ymaps.ready().then(
                        () => {
                            this.bootstrap();
                        },
                        () => null,
                    );
                }
            }, 1000);

            document.addEventListener('ymapsLoad', this.ymapsLoad);
        } else if (window.ymaps) {
            window.ymaps.ready().then(
                () => {
                    this.bootstrap();
                },
                () => null,
            );
        } else {
            const script = document.createElement('script');

            script.onload = () => {
                const { ymaps } = window;

                ymaps.ready().then(
                    () => {
                        this.bootstrap();

                        script.remove();
                    },
                    () => null,
                );

                window.isProccessLoadingYmap = false;

                document.dispatchEvent(new CustomEvent('ymapsLoad'));
            };

            script.type = 'text/javascript';
            script.src = `https://api-maps.yandex.ru/2.1/?apikey=${this.apikey}&lang=ru_RU`;
            script.async = 'async';

            window.isProccessLoadingYmap = true;

            document.querySelector('body').appendChild(script);
        }
    }

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        document.removeEventListener('ymapsLoad', this.ymapsLoad);
    }

    render() {
        const { forCalc } = this.props;

        return (
            <div
                ref={this.parent}
                style={{ width: '100%', height: '100%', display: forCalc ? 'none' : null }}
            />
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Ymaps);

Ymaps.propTypes = {
    center: PropTypes.object,
    zoom: PropTypes.number,
    callback: PropTypes.func,
    forCalc: PropTypes.bool,
    callbackLoad: PropTypes.func,
};
