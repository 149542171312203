import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../../components/Icon.jsx';
import getArticles from '../../../../requests/getArticles';
import getFormatedDate from '../../../../functions/getFormatedDate';
import getEndText from '../../../../functions/getEndText';
import Top from '../../../../components/crm/public/Top.jsx';
import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import Link from '../../../../components/Link.jsx';
import CustomHead from '../../../../components/CustomHead.jsx';

import setSpacesInText from '../../../../functions/setSpacesInText';
import changePage from '../../../../functions/changePage';
import getPageLink from '../../../../functions/getPageLink';

import scrollToBlock from '../../../../functions/scrollToBlock';

class PublicBlogInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.init = this.init.bind(this);

        this.parent = React.createRef();
    }

    init() {
        const { setPagesKey, device } = this.props;

        setPagesKey();

        this.parent.current.querySelectorAll('._ancor').forEach((ancor) => {
            ancor.onclick = () => {
                const id = ancor.getAttribute('data-ancor');

                const block = this.parent.current.querySelector(`._ancorBlock[data-ancor="${id}"]`);

                if (block) {
                    scrollToBlock({
                        scrollBlock: this.parent.current.closest('.public__innerPage'),
                        block,
                        offset: device === 'mobile' ? 100 : 100,
                    });
                }
            };
        });
    }

    getArticle() {
        const { id } = this.props;

        getArticles('blog', { params: [{ key: 'url', value: id }] }).then(
            ({ article }) => {
                this.setState({ article }, this.init);
            },
            () => {
                changePage({ href: getPageLink({ name: 'public-blog-inner', ids: { 2: '' } }) });
            },
        );
    }

    componentDidMount() {
        this.getArticle();
    }

    render() {
        const { article } = this.state;
        const { device } = this.props;
        const imageSrc = `${process.env.REACT_APP_STATIC}/articles/${article?.preview.path}`;

        return (
            <>
                <CustomHead
                    title={article?.metaTitle}
                    description={article?.metaDescription}
                    preview={imageSrc}
                />
                <div
                    ref={this.parent}
                    className={`publicBlogInner _SECTION ${article ? '_ready' : ''}`}
                >
                    <div className="publicBlogInner__inner">
                        <div className="publicBlogInner__content _col">
                            <Link
                                className="publicBlogInner__back _row"
                                pageName="public-blog-inner"
                                ids={{ 2: article?.section }}
                            >
                                <i className="publicBlogInner__backIcon">
                                    <Icon name="arrow-prev-2" />
                                </i>
                                Назад
                            </Link>
                            <Animate className="publicBlogInner__loader _loader" isShow={!article}>
                                <div className="publicBlogInner__loaderItem _loaderItem _public">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <div className="publicBlogInner__contentInner">
                                {device === 'mobile' && (
                                    <div className="publicBlogInner__head _mob _row">
                                        <div className="publicBlogInner__headInfo _row _left">
                                            <i className="publicBlogInner__headInfoIcon">
                                                <Icon name="blog-view" />
                                            </i>
                                            {article?.viewCounter || 0}
                                        </div>
                                    </div>
                                )}
                                <div className="publicBlogInner__head _row">
                                    <div className="publicBlogInner__headInfo _row">
                                        {getFormatedDate({
                                            date: new Date(article?.createdDate),
                                            type: 'textYear',
                                        })}
                                    </div>
                                    <div className="publicBlogInner__headInfo _time _row">
                                        <i className="publicBlogInner__headInfoIcon">
                                            <Icon name="blog-time" />
                                        </i>
                                        {article?.time}{' '}
                                        {getEndText(article?.time, ['минута', 'минуты', 'минут'])}
                                    </div>
                                    <div className="publicBlogInner__headInfo _row">
                                        {article?.sectionText}
                                    </div>
                                    {device === 'desktop' && (
                                        <>
                                            <div className="publicBlogInner__headInfo _row _left">
                                                <i className="publicBlogInner__headInfoIcon">
                                                    <Icon name="blog-view" />
                                                </i>
                                                {article?.viewCounter || 0}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="publicBlogInner__article">
                                    <h1
                                        className="publicBlogInner__articleTitle"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(article?.title),
                                        }}
                                    ></h1>
                                    <img
                                        src={imageSrc}
                                        alt=""
                                        className="publicBlogInner__articleImage"
                                    />
                                    <div
                                        className="publicBlogInner__articleInner"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(article?.content),
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(article?.nextArticle || article?.prevArticle) && (
                        <>
                            <div className="publicBlogInner__foot _row">
                                {['prevArticle', 'nextArticle'].map((key) => {
                                    if (!article?.[key]) {
                                        return null;
                                    }
                                    const isOne = !article.prevArticle || !article.nextArticle;

                                    return (
                                        <Link
                                            className={`publicBlogInner__footBlock _col ${
                                                isOne ? '_one' : ''
                                            } _${key}`}
                                            key={key}
                                            pageName="public-blog-inner"
                                            ids={{ 2: article[key].url }}
                                        >
                                            <div className="publicBlogInner__footBlockInner _col">
                                                <div className="publicBlogInner__footBlockTitle">
                                                    {key === 'prevArticle' ? (
                                                        <>
                                                            Предыдущий
                                                            {device === 'mobile' && !isOne && (
                                                                <br />
                                                            )}{' '}
                                                            материал:
                                                        </>
                                                    ) : (
                                                        <>
                                                            Следующий
                                                            {device === 'mobile' && !isOne && (
                                                                <br />
                                                            )}{' '}
                                                            материал:
                                                        </>
                                                    )}
                                                    <i className="publicBlogInner__footBlockTitleIcon">
                                                        <Icon
                                                            name={
                                                                key === 'prevArticle'
                                                                    ? 'arrow-prev-2'
                                                                    : 'arrow-next-2'
                                                            }
                                                        />
                                                    </i>
                                                </div>
                                                <div
                                                    className="publicBlogInner__footBlockName"
                                                    dangerouslySetInnerHTML={{
                                                        __html: setSpacesInText(article[key].title),
                                                    }}
                                                ></div>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
                <div className="public__section _footer">
                    <Top isFooter={true} />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
        device: state.device,
    };
}

export default connect(mapStateToProps)(PublicBlogInner);

PublicBlogInner.propTypes = {
    id: PropTypes.string,
    setPagesKey: PropTypes.func,
    device: PropTypes.string,
};
