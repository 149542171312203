import changePage from '@functions/changePage.ts';

import MainI from '../types.ts';

const getActions: MainI['getActions'] = function () {
    return [
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'chatTemplates',
        },
        {
            type: 'link',
            content: '+ Новый шаблон',
            name: 'add',
            className: '_main',
            group: 'links',
            onClick: async () => {
                changePage<'crm'>({ pageName: 'chat-template-inner', ids: { 2: 'new' } });
            },
        },
    ];
};

export default getActions;
