import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';

import InvitesI from '../types.ts';

const sendSms: InvitesI['sendSms'] = async function ({ join }) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API}/join?phone=${join.phone}&link=${join.scriptLink}`,
            { headers: getHeaders() },
        );

        const { success } = response.data;

        if (success) {
            setNotification({ notification: 'success-change-info' });
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
};

export default sendSms;
