const infoCards = [
    {
        title: 'Подключение',
        content: 'Самозанятый резидент/нерезидент подключается к сервису',
    },
    {
        title: 'Контроль',
        content: 'Проверяем документы и наличие статуса самозанятого',
    },
    {
        title: 'Договор',
        content: 'Подписываем договор с помощью ПЭП',
    },
    {
        title: 'Инструменты',
        content:
            'Предоставляем инструменты для работы с нерезидентами или берем эту заботу на себя',
    },
    {
        title: 'Интеграция с ФНС',
        content: 'Формируем акты и чеки автоматически&!nbsp;в момент выплаты',
    },
    {
        title: 'Готово!',
        content: 'Инструмент по массовым выплатам без ограничений',
    },
] as const;

export default infoCards;
