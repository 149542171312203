import PropTypes from 'prop-types';

import React from 'react';

import Avatar from '@components/avatar/Avatar';

import handlerSupportCursor from '../../../functions/handlerSupportCursor';

class CorporationInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { logo, name, className = '', short } = this.props;

        return name ? (
            <div
                className={`manualCorporationInfo ${className} ${short ? '_short' : ''}`}
                onMouseEnter={(e) => {
                    if (short) {
                        handlerSupportCursor({
                            action: 'enter',
                            content: `Компания<br/>${name}`,
                            e,
                            data: { className: '_normalCase _center' },
                        });
                    }
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                <div className="manualCorporationInfo__logo">
                    <Avatar image={logo?.fullSrc} firstSymb={name?.[0]} className="_grey" />
                </div>

                {!short && <div className="manualCorporationInfo__name">{name}</div>}
            </div>
        ) : null;
    }
}

export default CorporationInfo;

CorporationInfo.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.object,
    name: PropTypes.string,
    short: PropTypes.bool,
};
