import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import getUpdateFormData from '@functions/getUpdateFormData.js';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const uploadFile: I['uploadFile'] = async function (e) {
    const { model } = this.state;
    const formData = new FormData();
    const { target } = e;
    const file = target!.files?.[0];

    if (!file) {
        return;
    }

    formData.set('file', file);

    if (model?.projectId) {
        formData.set('projectId', model.projectId);
    }

    target.value = '';

    await handlerLoading.call(this, 'save');

    try {
        const response = await axios.post<
            {},
            { data: ReqResponseT<{ id: string; message?: string }> }
        >(`${process.env.REACT_APP_HARD_API}/pays`, getUpdateFormData(formData), {
            headers: getHeaders(),
        });

        const { success, data } = response.data;

        if (success) {
            const { id } = data;

            changePage<'crm'>({
                pageName: 'pays-groups-inner-main',
                ids: { 3: id },
            });

            this.close();
        } else {
            const { message } = data;

            if (message === 'Corporation not verify') {
                handlerPopup('verificationPopup', {
                    isShow: true,
                    callback: this.close,
                });
            } else {
                setNotification({ notification: 'incorrect-template-excel' });
            }
        }
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default uploadFile;
