import React from 'react';

import JoinContractT from '@global/models/JoinContract';

type ParamsT = {
    joinContract: JoinContractT;
};

export default function Status({ joinContract }: ParamsT): React.ReactNode {
    const { status: itemStatus } = joinContract;
    let statusContent;
    const statusEnd = joinContract.type === 'userProxy' ? 'а' : '';
    let className = '_wait';

    if (itemStatus === 'wait') {
        statusContent = `Не подписан${statusEnd}`;
    } else if (itemStatus === 'corporationSign') {
        statusContent = `Подписан${statusEnd}<br/>компанией`;
        className = '_process';
    } else if (itemStatus === 'executorSign') {
        if (!joinContract.type) {
            statusContent = `Подписан${statusEnd}<br/>исполнителем`;
        } else {
            statusContent = `Подписан${statusEnd}<br/>сотрудником`;
        }

        className = '_process';
    } else {
        statusContent = `Подписан${statusEnd}`;
        className = '_success';
    }

    if (itemStatus === 'process') {
        statusContent = 'В процессе';
        className = '';
    }

    if (joinContract.inactive) {
        statusContent = `Расторгнут${statusEnd}`;
        className = '_alert';
    }

    return (
        <div
            className={`v2table__status ${className}`}
            dangerouslySetInnerHTML={{ __html: statusContent }}
        ></div>
    );
}
