import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import save from './methods/save.ts';

import Interface from './types.ts';

import renderContent from './renders/renderContent.tsx';

class ExecutorCreate
    extends EditBlock<Interface['props'], Interface['state']>
    implements Interface
{
    constructor(props: Interface['props']) {
        super(props);
        this.state = {};
    }

    close = close;
    save = save;

    renderContent = renderContent;

    componentDidMount() {
        const { executorCreatePopup } = this.props;

        this.init({ fields: {} });

        this.setState({ isJoin: executorCreatePopup.isJoin });
    }

    render() {
        return (
            <Popup
                name="executorCreatePopup"
                close={this.close.bind(this)}
                checkClose={() =>
                    !document.querySelector('.v2cursorLists__item') &&
                    !document.querySelector('.body__v2popup._alert')
                }
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        executorCreatePopup: state.executorCreatePopup,
    };
}

export default connect(mapStateToProps)(ExecutorCreate);
