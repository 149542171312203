import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Pages from '../../components/Pages.jsx';

import Blog from './content/Blog.jsx';
import Info from './content/Info.jsx';

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = {
        'content-info': {
            render() {
                return <Info />;
            },
        },
        'content-blog': {
            render() {
                return <Blog />;
            },
        },
        'content-announce': {
            render() {
                return <Blog name="announce" />;
            },
        },
        'content-legal': {
            render() {
                return <Blog name="legal" />;
            },
        },
    };

    render() {
        const { heightPage = 0 } = this.state;

        return (
            <div ref={this.parent} className="content">
                <div className="content__inner" style={{ height: `${heightPage}px` }}>
                    <Pages
                        classNamePage="content__page"
                        filter={(page) => page.parentName === 'content' && page.level === 2}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Content);

Content.propTypes = {
    user: PropTypes.object,
};
