import lists from '../lists';

const listTags = {
    ...lists,
    query: [{ key: 'type', value: 'tags' }],
    emptyInfo: {
        title: 'Тегов пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listTags;
