export default function getStatusDate({ date }) {
    const getDays = (time) => time / (1000 * 60 * 60 * 24);

    if (date) {
        const dateActionTime = new Date(date).getTime();
        const dateNowTime = new Date().getTime();

        if (dateActionTime < dateNowTime) {
            return {
                status: 'error',
                className: '_errorColor',
            };
        }

        if (getDays(dateActionTime) - getDays(dateNowTime) <= 30) {
            return {
                status: 'medium',
                className: '_mediumColor',
            };
        }

        return {
            status: 'success',
            className: '_successColor',
        };
    }

    return {
        status: '',
        className: '',
    };
}
