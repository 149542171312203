import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getArticles from '../../../requests/getArticles';

import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';
import ListScroll from '../../ListScroll.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import NewsCard from './NewsCard.jsx';
import readNotificationsNews from '../../../functions/crm/readNotificationsNews';
import Table from '../manual/Table.jsx';

// import Notification from './Notification.jsx';

class News extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderNotification = this.renderNotification.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);

        this.parent = React.createRef();
    }

    classNameItem = '.notificationsBar__item';

    stepCounter = 10;

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    readNotificationsNews() {
        this.handlerLoading('read').then(() => {
            readNotificationsNews('all').then(
                () => {
                    this.handlerLoading(null);

                    this.setState((state) => {
                        const newState = { ...state };
                        const items = [...newState.items];

                        items.forEach((item) => {
                            item.isRead = true;
                        });

                        newState.items = items;
                        newState.readCounter = this.state.counter;
                        newState.notReadCounter = 0;

                        return newState;
                    });
                },
                () => null,
            );
        });
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getArticles('announce', { ...query }).then(
                ({ articles, isLimit, counter, notReadCounter }) => {
                    this.setItems(articles, false, isLimit, counter).then(() => {
                        this.setState({ notReadCounter, readCounter: counter - notReadCounter });

                        resolve();
                    });
                },
            );
        });
    }

    renderNotification({ item, prop: id }) {
        const { loadingKey } = this.state;
        const { setCurrentPage } = this.props;

        return (
            <div className="notificationsBar__item">
                <div className="notificationsBar__itemInner">
                    {id === 'read' || id === 'notRead' ? (
                        <>
                            <div className="notificationsBar__itemHead _row">
                                <div className="notificationsBar__itemTitle">
                                    {id === 'read' ? 'Прочитанные' : 'Новые'} ({item.counter})
                                </div>
                                {id === 'notRead' && (
                                    <div
                                        className={`notificationsBar__itemButton ${
                                            loadingKey === 'read' ? '_loading' : ''
                                        }`}
                                        onClick={() => {
                                            if (!loadingKey) {
                                                this.readNotificationsNews();
                                            }
                                        }}
                                    >
                                        <Animate
                                            className="notificationsBar__itemButtonLoader _loader"
                                            isShow={loadingKey === 'read'}
                                        >
                                            <div className="notificationsBar__itemButtonLoaderItem _loaderItem">
                                                <Loader className="_main" />
                                            </div>
                                        </Animate>
                                        <div className="notificationsBar__itemButtonInner _click">
                                            Прочитать все
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <NewsCard {...item} setCurrentPage={setCurrentPage} />
                        </>
                    )}
                </div>
            </div>
        );
    }

    getItemsCards() {
        const { items = [], readCounter, notReadCounter } = this.state;

        const groups = {
            notRead: [
                {
                    _id: 'notRead',
                    counter: notReadCounter,
                },
            ],
            read: [
                {
                    _id: 'read',
                    counter: readCounter,
                },
            ],
        };

        items.forEach((item) => {
            if (item.isRead) {
                groups.read.push(item);
            } else {
                groups.notRead.push(item);
            }
        });

        if (groups.notRead.length === 1) {
            groups.notRead = [];
        }

        if (groups.read.length === 1) {
            groups.read = [];
        }

        return [...groups.notRead, ...groups.read];
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    render() {
        const { isReady, isDisabledScroll, isShowLoaderList, isLimit } = this.state;
        const { isChangeCorporationProccess, idOfCurrentCorporation } = this.props;
        const items = this.getItemsCards();

        return (
            <div ref={this.parent} className="widget__blockInner">
                <Animate
                    className="notificationsBar__loader _loader"
                    isShow={isChangeCorporationProccess || !isReady}
                >
                    <div className="notificationsBar__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate
                    className="notificationsBar__empty"
                    isShow={isReady && !isChangeCorporationProccess && items.length === 0}
                >
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">
                                На данный момент
                                <br />
                                новостей нет
                            </div>
                            <p className="empty__content">
                                Они будут отображаться здесь
                                <br />в виде карточек
                            </p>
                        </div>
                    </div>
                </Animate>
                <Animate
                    className="notificationsBar__scrollLoader _loaderScroll"
                    isShow={isShowLoaderList}
                >
                    <div className="notificationsBar__scrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="widget__contentInner">
                    <Animate
                        className="notificationsBar__box"
                        isShow={!isChangeCorporationProccess && isReady}
                    >
                        <div className="notificationsBar__itemsInner" key={idOfCurrentCorporation}>
                            <ListScroll
                                isInit={isReady}
                                getParent={() =>
                                    this.parent.current?.querySelector('.widget__contentInner')
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={items.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={isDisabledScroll || !isReady}
                            >
                                <ListAbsoluteMain
                                    name="notificationsNews"
                                    className="notificationsBar__items _col"
                                    items={items}
                                    renderItem={this.renderNotification}
                                    classNameItem="notificationsBar__item"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    propsForUpdate={['isRead', 'counter']}
                                />
                            </ListScroll>
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(News);

News.propTypes = {
    user: PropTypes.object,
    isHide: PropTypes.bool,
    handlerHideState: PropTypes.func,
    setCounter: PropTypes.func,
    news: PropTypes.array,
    isChangeCorporationProccess: PropTypes.bool,
    idOfCurrentCorporation: PropTypes.string,
    setCurrentPage: PropTypes.func,
};
