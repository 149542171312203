import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import getUser from '@functions/getUser.js';
import handlerLoading from '@functions/handlerLoading.ts';
import setNotification from '@functions/setNotification.js';
import { CorporationT } from '@global/models/Corporation.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const fields = this.checkChange(true).model;

    let response;

    await handlerLoading.call(this, 'save');

    try {
        response = await axios.post<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: CorporationT;
                    error?: { name: string; text: string };
                }>;
            }
        >(`${process.env.REACT_APP_API}/corporation`, { fields }, { headers: getHeaders() });
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    const { success, data } = response.data;

    if (success) {
        this.close();

        getUser(null, true);

        setNotification({ notification: 'corporation-create' });
    } else {
        const { error } = data;

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default save;
