import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../Button.jsx';
import AppButton from '../AppButton.jsx';

class PublicExecutorsFnsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <section className="publicExecutorsInfo _fns">
                <div className="publicExecutorsInfo__inner">
                    <div className="publicExecutorsInfo__content _col">
                        <div className="publicExecutorsInfo__contentBack" />
                        <div className="publicExecutorsInfo__info _col">
                            <div className="publicExecutorsInfo__title">
                                Хотите стать
                                <br />
                                самозанятым?
                            </div>
                            <div className="publicExecutorsInfo__description">
                                Регистрируйтесь в качестве самозанятого через официальное приложение
                                ФНС России и&nbsp;получайте официальный доход!
                            </div>
                            <div className="publicExecutorsInfo__actions _col">
                                <div className="publicExecutorsInfo__appBtns _row">
                                    <div className="publicExecutorsInfo__appBtn">
                                        <AppButton name="apple" />
                                    </div>
                                    <div className="publicExecutorsInfo__appBtn">
                                        <AppButton name="google" />
                                    </div>
                                    <div className="publicExecutorsInfo__appBtn">
                                        <AppButton name="rustore" />
                                    </div>
                                    <div className="publicExecutorsInfo__appBtn">
                                        <AppButton name="appgalery" />
                                    </div>
                                </div>

                                <a
                                    rel="noreferrer"
                                    className="publicExecutorsInfo__actionsButton"
                                    href="https://npd.nalog.ru/faq/"
                                    target="_blank"
                                >
                                    <Button className="_white">Подробнее на сайте ФНС</Button>
                                </a>
                            </div>
                        </div>
                        <img
                            src={require('../../../../img/crm/public/executors-info-image-2.png')}
                            alt=""
                            className="publicExecutorsInfo__image"
                        />
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(PublicExecutorsFnsInfo);

PublicExecutorsFnsInfo.propTypes = {
    windowIsReady: PropTypes.bool,
};
