import React from 'react';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { children } = this.props;

    return <div className="v2widgetCard__content">{children || this.renderCards()}</div>;
};

export default renderContent;
