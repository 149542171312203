import BalanceI from '../types.ts';

const getQuery: BalanceI['getQuery'] = function (this: BalanceI) {
    const { corporationId, type } = this.props;
    const query = [{ key: 'type', value: 'balance' }];

    if (corporationId) {
        query.push({ key: 'adminCorporationId', value: corporationId });
    }

    if (type === 'income') {
        query.push({ key: 'income', value: 'true' });
    }

    return query;
};

export default getQuery;
