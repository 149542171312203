import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import changePage from '../../functions/changePage';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import getHeaders from '../../functions/getHeaders';
import getPageLink from '../../functions/getPageLink';
import getQueryFilter from '../../functions/getQueryFilter';
import removeTransition from '../../functions/removeTransition.ts';
import setNotification from '../../functions/setNotification';

import Filter from '../../classes/Filter';
import createChat from '../../requests/createChat';
import getFilter from '../../requests/getFilter';
import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';
import AnimateChangeUp from '../AnimateChangeUp.jsx';
import Button from '../Button.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import ListScroll from '../ListScroll.jsx';
import Loader from '../Loader.jsx';
import Search from '../Search.jsx';
import WidgetFilter from '../crm/widget/Filter.jsx';
import Preview from './Preview.jsx';

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            counterChange: 0,
            counterResize: 0,
            isReady: false,
        };

        this.changeChat = this.changeChat.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.updatePreview = this.updatePreview.bind(this);
        this.createChat = this.createChat.bind(this);
        this.sortPreviews = this.sortPreviews.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.renderPreview = this.renderPreview.bind(this);
        this.handlerResize = this.handlerResize.bind(this);
        this.getMorePreviews = this.getMorePreviews.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.filterCallback = this.filterCallback.bind(this);
        this.setChoice = this.setChoice.bind(this);
        this.choiceChatPreview = this.choiceChatPreview.bind(this);
        this.deleteChats = this.deleteChats.bind(this);
        this.deleteChatPreview = this.deleteChatPreview.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 20;

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState({ search: value }, () => {
                    if (!value || value.length >= 3) {
                        this.changeChat();
                    }

                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    getGlobalName() {
        const { levels } = this.props;
        const chatType = levels[1];

        return `chats-${chatType}`;
    }

    setPreviews({ previews, isConcat, isLimit = false }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.previews = isConcat
                        ? this.state.previews.concat(...previews)
                        : previews;
                    newState.isLimit = isLimit;
                    newState.updateKey = new Date().getTime();

                    if (!isConcat) {
                        newState.choices = [];
                        newState.updateListKey = new Date().getTime();
                    }

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    getPreviews(isConcat, isCheck) {
        const { search, counterScroll, filter } = this.state;
        const { user, type, levels, getChatsCounters } = this.props;

        let query =
            type === 'fix'
                ? ''
                : (user.type === 'juristic' && `entity.idOfCompany=${user.idOfCurrentCompany}`) ||
                  '';

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            const chatType = levels[2];

            if (chatType !== 'all') {
                query += `&chatType=${chatType}`;
            }
        }

        const filterQuery = getQueryFilter({ filter });

        filterQuery.forEach((item) => {
            query += `&${item.key}=${item.value}`;
        });

        if (!isCheck) {
            query += `&limit=${this.stepCounter}`;
            query += `&skip=${counterScroll ? counterScroll - this.stepCounter : 0}`;
        }

        if (search) {
            query += `&searchForName=${search}`;
        }

        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        return new Promise((resolve) => {
            axios
                .get(`${process.env.REACT_APP_API}/chat?${query}`, {
                    signal: this.controller.signal,
                    headers: getHeaders(),
                })
                .then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            const { chats: previews, isLimit, counter } = data;

                            if (getChatsCounters) {
                                getChatsCounters({ counter });
                            }

                            if (!isCheck) {
                                this.setPreviews({ previews, isConcat, isLimit }).then(() => {
                                    this.itemTransDom = removeTransition({
                                        item: '.chatList__item',
                                        isCurrent: true,
                                        isNotRemove: true,
                                    }).styleComponent;

                                    setTimeout(() => {
                                        this.setState({ isReady: true });
                                    }, 100);
                                });

                                resolve();
                            } else {
                                resolve({ previews });
                            }
                        }
                    },
                    () => null,
                );
        });
    }

    getMorePreviews(counter, isStart) {
        const actionPrev = () =>
            new Promise((resolve) => {
                this.setState({ isDisabledScroll: true }, () => {
                    if (isStart) {
                        resolve();
                    } else {
                        this.getPreviews(true).then(resolve);
                    }
                });
            });

        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                actionPrev().then(() => {
                    setTimeout(() => {
                        this.setState({ isDisabledScroll: false }, resolve);
                    }, 100);
                });
            });
        });
    }

    timerId = null;

    changeChat() {
        const handler = (isChange) =>
            new Promise((resolve) => {
                this.setState((state) => {
                    const newState = { ...state };

                    newState.isChange = isChange;
                    newState.isDisabledScroll = isChange;
                    newState.counterScroll = 0;

                    return newState;
                }, resolve);
            });

        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        handler(true).then(() => {
            if (this.controller) {
                this.controller.abort();
            }

            this.timerId = setTimeout(() => {
                this.getPreviews().then(() => {
                    this.setState({ counterChange: this.state.counterChange + 1 }, () => {
                        setTimeout(() => {
                            handler(false);
                        }, 100);
                    });
                });
            }, 300);
        });
    }

    getInfo() {
        const { search } = this.state;
        const { type } = this.props;
        const previews = this.getPreviewsOrder();

        if (search && previews.length === 0) {
            return {
                type: 'filter',
                title: 'Чат не найден',
                description: 'По вашему поиску обращений нет',
            };
        }

        if (this.saveLevel1 !== 'all' && previews.length === 0) {
            return {
                type: 'filter',
                title: 'Пока нет чатов',
                description: 'В данной категории нет чатов',
            };
        }

        if (previews.length === 0) {
            return {
                title: 'Пока нет чатов',
                description:
                    process.env.REACT_APP_SYSTEM === 'crm'
                        ? 'Они будут отображаться здесь<br/>в виде карточек'
                        : type === 'fix'
                          ? 'Создайте обращение, нажав на кнопку ниже'
                          : 'Создайте обращение, нажав на кнопку справа',
            };
        }

        return {
            title: 'Больше чатов нет',
            description: 'Пока это все обращения <br/>на данный момент',
        };
    }

    timerCounter = 0;

    setCheckCounter() {
        this.timerCounter += 1;
    }

    checkCounter() {
        if (this.timerCounter > 0) {
            this.timerCounter = 0;

            this.changeChat();
        }
    }

    updatePreview({ id, props }) {
        let notFound = false;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const previews = JSON.parse(JSON.stringify(newState.previews || []));
                    const indexPreview = previews.findIndex((preview) => preview._id === id);

                    if (indexPreview !== -1) {
                        Object.keys(props).forEach((key) => {
                            previews[indexPreview][key] = props[key];
                        });

                        newState.updateKey = new Date().getTime();
                    } else if (props.lastMessage) {
                        notFound = true;
                    }

                    newState.previews = previews;

                    return newState;
                },
                () => {
                    if (notFound) {
                        this.setCheckCounter();
                    }

                    resolve();
                },
            );
        });
    }

    updatePreviews({ preview }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const previews = [...(newState.previews || [])];

                    previews.push(preview);

                    newState.previews = previews;

                    return newState;
                },
                () => {
                    resolve();
                },
            );
        });
    }

    createChat() {
        const { type, setChat } = this.props;
        const setChatLoader = (chatLoader) =>
            new Promise((resolve) => {
                this.setState((state) => {
                    const newState = { ...state };

                    newState.chatLoader = chatLoader;

                    return newState;
                }, resolve);
            });

        setChatLoader(true).then(() => {
            createChat({ changeToChatPage: type !== 'fix' }).then(
                ({ id }) => {
                    if (type === 'fix') {
                        setChat(id);
                    }
                    setChatLoader(false);
                },
                () => null,
            );
        });
    }

    getPreviewsOrder() {
        const { previews = [] } = this.state;

        const items = this.sortPreviews(previews);

        return items;
    }

    sortPreviews(previews) {
        const { user } = this.state;
        const previewsSort = JSON.parse(JSON.stringify(previews));
        const statusKey = process.env.REACT_APP_SYSTEM === 'app' ? 'entityStatus' : 'userStatus';

        previewsSort.sort((a, b) => b.updatedMessageTime - a.updatedMessageTime);

        previewsSort.sort((a, b) => b[statusKey] - a[statusKey]);

        if (user?.idOfCurrentCorporation !== 'admin') {
            previewsSort.sort((a, b) => {
                const weightA = a.type === 'support' ? 1 : 0;
                const weightB = b.type === 'support' ? 1 : 0;

                return weightB - weightA;
            });
        }

        return previewsSort;
    }

    setChoice({ id }) {
        this.setState((state) => {
            const newState = { ...state };
            const choices = [...(newState.choices || [])];
            const index = choices.indexOf(id);

            if (index !== -1) {
                choices.splice(index, 1);
            } else {
                choices.push(id);
            }

            newState.choices = choices;

            return newState;
        });
    }

    choiceChatPreview({ detail: { id } }) {
        this.setChoice({ id });
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    deleteChats(oneId) {
        const { choices } = this.state;
        let ids = '';

        choices.forEach((id) => {
            ids += `ids=${id}&`;
        });

        ids = ids.slice(0, -1);

        if (oneId) {
            ids = `ids=${oneId}`;
        }

        return new Promise((resolve) => {
            this.handlerLoading('deleteChats').then(() => {
                axios
                    .delete(`${process.env.REACT_APP_API}/chat?${ids}`, { headers: getHeaders() })
                    .then(
                        (res) => {
                            const { success } = res.data;

                            if (success) {
                                // this.changeChat();

                                setNotification({ notification: 'success-change-info' });

                                changePage({
                                    href: getPageLink({ name: 'chat' }),
                                });
                            }

                            this.handlerLoading(null);

                            resolve();
                        },
                        () => null,
                    );
            });
        });
    }

    deleteChatPreview({ detail: { id, resolve } }) {
        this.deleteChats(id).then(() => {
            resolve();
        });
    }

    renderPreview({ item: preview, prop: id }) {
        const { choices = [] } = this.state;
        const { setChat, type, getParent } = this.props;

        return (
            <div
                className="chatList__item"
                onClick={() => {
                    if (setChat && typeof setChat === 'function') {
                        setChat(id);
                    }
                }}
            >
                <div className="chatList__itemInner">
                    <Preview
                        typeChat={type}
                        typePreview={preview?.entity?.type}
                        {...preview}
                        updatePreview={this.updatePreview}
                        getParent={getParent}
                        isChoice={choices.includes(id)}
                        isChoiceMode={choices.length > 0}
                        setChoice={this.setChoice}
                        storeUser={this.state.user}
                    />
                </div>
            </div>
        );
    }

    checkListEmpty() {
        const previews = this.getPreviewsOrder();

        return previews.length === 0;
    }

    getHeightEmpty() {
        const { boxParams } = this.state;
        let resultHeight = 0;
        const heightBox = boxParams?.height || 0;

        if (this.parent.current) {
            const inner = this.parent.current.querySelector('.chatList__box');
            const innerHeight = inner?.offsetHeight || 0;

            resultHeight = innerHeight - heightBox - 20 - (this.checkListEmpty() ? 0 : 8);

            if (resultHeight < 0) {
                resultHeight = 0;
            }
        }

        return resultHeight;
    }

    getCondForInfo() {
        return this.getHeightEmpty() > 100;
    }

    handlerResize() {
        removeTransition({ item: '.chatList__info' });

        this.setState({ counterResize: this.state.counterResize + 1 });
    }

    checkChangeType(isStart) {
        const { levels } = this.props;
        const chatType = levels[1] === 'chat' ? levels[2] : 'all';

        if (isStart) {
            this.getPreviews();

            this.chatType = chatType;
        } else if (
            process.env.REACT_APP_SYSTEM === 'crm' &&
            levels[1] === 'chat' &&
            this.chatType !== chatType
        ) {
            this.chatType = chatType;

            this.saveLevel1 = null;

            this.changeChat();
        }
    }

    handlerLoaderList(isScrollLoading) {
        return new Promise((resolve) => {
            this.setState({ isScrollLoading }, resolve);
        });
    }

    filterName = 'crm-filter-chat';

    getFilter() {
        getFilter({ name: 'chats' }).then(
            ({ blocks }) => {
                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    filterCallback({ filter, isChange }) {
        return new Promise((resolve) => {
            if (!isChange) {
                resolve();
            } else {
                localStorage.setItem(this.filterName, JSON.stringify(filter));

                this.setState({ filter }, () => {
                    this.changeChat();

                    resolve();
                });
            }
        });
    }

    handlerSocket({ detail }) {
        const { previews } = this.state;
        const { user } = this.props;
        const { name, data } = detail;

        if (name === 'chat' && previews) {
            const { fields, type, idOfChat } = data;

            if (type === 'newMessage') {
                const {
                    newMessages,
                    isClose,
                    updatedMessageTime,
                    corporationId,
                    userStatus,
                    entityStatus,
                } = fields;
                const filteredMessages = newMessages.filter((item) => !item.type);
                const newMessage = filteredMessages[filteredMessages.length - 1];

                if (corporationId === user.idOfCurrentCorporation || fields.type === 'support') {
                    if (newMessage && !newMessage.type) {
                        this.updatePreview({
                            id: idOfChat,
                            props: {
                                lastMessage: newMessage,
                                updatedMessageTime,
                                userStatus,
                                entityStatus,
                            },
                        });
                    }

                    if (checkValueOfEmpty(isClose)) {
                        this.updatePreview({
                            id: idOfChat,
                            props: {
                                isClose,
                                updatedMessageTime,
                                userStatus,
                                entityStatus,
                            },
                        });
                    }
                }
            }

            if (previews?.find((preview) => preview._id === idOfChat)) {
                if (type === 'deleteMessages') {
                    this.updatePreview({
                        id: idOfChat,
                        props: { lastMessage: fields.lastMessage },
                    });
                }

                if (type === 'changeInfo') {
                    this.updatePreview({
                        id: idOfChat,
                        props: fields,
                    });
                }
            }

            if (type === 'newChat') {
                const { preview } = data;
                const { corporationId } = preview;

                if (corporationId === user.idOfCurrentCorporation) {
                    this.updatePreviews({ preview });
                }
            }

            if (type === 'deleteChat') {
                this.changeChat();
            }
        }
    }

    componentDidMount() {
        const { type, setFilterCallback, user } = this.props;

        this.setState({ user });

        this.checkChangeType(true);

        this.getFilter();

        if (setFilterCallback) {
            setFilterCallback(this.filterCallback);
        }

        this.handlerFilter = new Filter({
            context: this,
        });

        if (type !== 'fix') {
            document.addEventListener('changeCompany', this.changeChat);
        }

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            document.addEventListener('changeCorporation', this.changeChat);
        }

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeHeightWindow', this.handlerResize);
        document.addEventListener('choiceChatPreview', this.choiceChatPreview);
        document.addEventListener('deleteChatPreview', this.deleteChatPreview);

        this.intervalCounterId = setInterval(() => {
            this.checkCounter();
        }, 7_000);
    }

    componentDidUpdate() {
        this.checkChangeType();
    }

    componentWillUnmount() {
        document.removeEventListener('changeCompany', this.changeChat);
        document.removeEventListener('changeCorporation', this.changeChat);
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('changeHeightWindow', this.handlerResize);
        document.removeEventListener('choiceChatPreview', this.choiceChatPreview);
        document.removeEventListener('deleteChatPreview', this.deleteChatPreview);

        clearInterval(this.intervalCounterId);
    }

    render() {
        const {
            isChange,
            search,
            chatLoader,
            isReady,
            counterChange,
            updateKey,
            isLimit,
            isDisabledScroll,
            isScrollLoading,
            filter,
            choices = [],
            loadingKey,
        } = this.state;
        const { type, getParent } = this.props;
        const info = this.getInfo();
        const previews = this.getPreviewsOrder();

        if (choices.length > 0) {
            this.choicesCounter = choices.length;
        }

        return (
            <div
                ref={this.parent}
                className={`chatList ${isChange ? '_isChange' : ''} ${type ? `_${type}` : ''} ${
                    this.checkListEmpty() ? '_empty' : ''
                } ${isReady ? '_isReady' : ''}`}
            >
                <Animate isShow={isChange || !isReady} className="chatList__loader _loader">
                    <div className="chatList__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="chatList__inner">
                    <div className="chatList__head _row">
                        <div className="chatList__search">
                            <Search
                                name={type === 'fix' ? 'chatFix' : 'chatList'}
                                value={search}
                                support="Введите имя собеседника"
                                callback={this.handlerSearch}
                            />
                        </div>
                        {process.env.REACT_APP_SYSTEM === 'crm' && (
                            <div className="chatList__filter">
                                <WidgetFilter
                                    name="chat"
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    getParent={getParent}
                                    centers={{
                                        top: 1,
                                        left: 1,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="chatList__box">
                        <div className="chatList__boxScroll" key={counterChange}>
                            <div className="chatList__boxInner">
                                <ListScroll
                                    getParent={() =>
                                        this.parent.current?.querySelector('.chatList__boxScroll')
                                    }
                                    callback={this.getMorePreviews}
                                    startCounter={this.stepCounter}
                                    stepCounter={this.stepCounter}
                                    maxCounter={Infinity}
                                    lengthCurrent={previews.length}
                                    handlerLoaderList={this.handlerLoaderList}
                                    isLimit={isLimit}
                                    keyUpdate={counterChange}
                                    isDisabledScroll={isChange || !isReady || isDisabledScroll}
                                >
                                    <ListAbsoluteMain
                                        classNames={type === 'fix' ? ['_fix'] : []}
                                        className="chatList__items _col"
                                        items={this.getPreviewsOrder()}
                                        renderItem={this.renderPreview}
                                        classNameItem="chatList__item"
                                        prop="_id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                        propsForUpdate={[
                                            'isClose',
                                            'type',
                                            'responsible',
                                            'counterNotRead',
                                            'userStatus',
                                            'entityStatus',
                                        ]}
                                        keyUpdateItem={updateKey}
                                        // sort={this.sortPreviews}
                                        callback={({ type: cbType, params }) => {
                                            if (cbType === 'parent') {
                                                this.setState({ boxParams: params });
                                            }

                                            if (this.itemTransDom) {
                                                setTimeout(() => {
                                                    this.itemTransDom.remove();
                                                }, 100);
                                            }
                                        }}
                                    />
                                </ListScroll>
                                <Animate
                                    className="chatList__info"
                                    isShow={this.getCondForInfo()}
                                    style={{ height: `${this.getHeightEmpty()}px` }}
                                    name="info"
                                >
                                    <div
                                        className={`empty _col _block ${
                                            type === 'fix' ? '_notBack' : ''
                                        }`}
                                    >
                                        <AnimateChange
                                            className="empty__inner"
                                            type="_translate _transFast"
                                            prop={info.title}
                                        >
                                            <div className="empty__innerItem">
                                                <div className="empty__title">{info.title}</div>
                                                <p
                                                    className="empty__content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: info.description,
                                                    }}
                                                ></p>
                                                <Animate
                                                    className="empty__button"
                                                    isShow={
                                                        type === 'fix' &&
                                                        previews.length === 0 &&
                                                        info.type !== 'filter'
                                                    }
                                                    onClick={this.createChat}
                                                >
                                                    <Button
                                                        showLoader={chatLoader}
                                                        className="_mediumSize _mainNotBorder"
                                                    >
                                                        Создать обращение
                                                    </Button>
                                                </Animate>
                                            </div>
                                        </AnimateChange>
                                    </div>
                                </Animate>
                            </div>
                        </div>
                        <Animate
                            className="chatList__boxLoader _loaderScroll"
                            isShow={isScrollLoading}
                        >
                            <div className="chatList__boxLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        {process.env.REACT_APP_SYSTEM === 'crm' && (
                            <Animate className="chatList__choices" isShow={choices.length > 0}>
                                <div className="chatList__choicesInner">
                                    <div className="chatList__choicesHead _row">
                                        <div className="chatList__choicesTitle _row">
                                            Выбрано чатов:{' '}
                                            <AnimateChangeUp
                                                className="chatList__choicesTitleCounter"
                                                renderKey={this.choicesCounter}
                                            >
                                                {this.choicesCounter}
                                            </AnimateChangeUp>
                                        </div>
                                    </div>
                                    <div className="chatList__choicesDelete">
                                        <Button
                                            className="_alertLight _medium2Size"
                                            showLoader={loadingKey === 'deleteChats'}
                                            onClick={() => this.deleteChats()}
                                        >
                                            Удалить выбранные чаты
                                        </Button>
                                    </div>
                                </div>
                            </Animate>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(List);

List.propTypes = {
    type: PropTypes.string,
    setChat: PropTypes.func,
    user: PropTypes.object,
    levels: PropTypes.array,
    getParent: PropTypes.func,
    setFilterCallback: PropTypes.func,
    getChatsCounters: PropTypes.func,
};
