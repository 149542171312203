import React from 'react';

import { getTextError, inputValidate } from '../../../functions/inputValidate';

import Field from '../../Field.jsx';
import Animate from '../../Animate.jsx';
import Icon from '../../Icon.jsx';
import handlerSupportCursor from '../../../functions/handlerSupportCursor';
import setSpacesInText from '../../../functions/setSpacesInText';

const passwordsRules = require('../../../infos/passwordsRules.json');

class AuthForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerField = this.handlerField.bind(this);
        this.checkValidateFields = this.checkValidateFields.bind(this);
        this.handlerLoading = this.handlerLoading.bind(this);
        this.renderBlock = this.renderBlock.bind(this);
    }

    groups = {
        'reg-name': {
            support: 'Личные данные',
            fields: ['secondName', 'firstName', 'thirdName'],
        },
        'reg-contacts': {
            support: 'Контактные данные',
            fields: ['phone', 'email'],
        },
        'reg-passwords': {
            support: 'ДАННЫЕ ДЛЯ ВХОДА',
            fields: ['password', 'passwordAgain'],
        },
        login: {
            fields: ['email', 'password'],
        },
        'forget-email': {
            support: 'Введите данные',
            fields: ['email'],
        },
        'forget-passwords': {
            support: 'Введите данные',
            fields: ['password', 'passwordAgain'],
        },
    };

    errorsText = {};

    renderGroup({ name }) {
        const { fields } = this.state;
        const group = this.groups[name];
        const { support, fields: orderFields } = group;

        return (
            <div className="authForm__contentGroup _col" key={name}>
                {support && (
                    <div className="authForm__contentGroupSupport">
                        {support}
                        {name === 'reg-passwords' && (
                            <div
                                className="authForm__contentGroupSupportIcon _click"
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: passwordsRules.content,
                                        e,
                                        data: { className: '_normalCase _center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                <Icon name="info" />
                            </div>
                        )}
                    </div>
                )}

                <div className="authForm__contentGroupFields">
                    {orderFields.map((nameField) => {
                        const field = fields?.[nameField];
                        let error = field?.error;
                        let fieldType = 'crmAuth';

                        if (name === 'forget-passwords') {
                            fieldType = 'cabinet';
                        }

                        if (
                            ['password', 'passwordAgain'].every(
                                (namePassField) => orderFields.indexOf(namePassField) !== -1,
                            ) &&
                            ['password', 'passwordAgain'].indexOf(nameField) !== -1
                        ) {
                            if (
                                ['password', 'passwordAgain'].every(
                                    (namePassField) =>
                                        fields?.[namePassField] &&
                                        inputValidate({
                                            name: 'password',
                                            value: fields?.[namePassField].value,
                                        }),
                                ) &&
                                fields?.password.value !== fields?.passwordAgain.value
                            ) {
                                error = 'passwordsNotEqual';
                            }
                        }

                        const resultError =
                            error ||
                            field?.error ||
                            (!field?.isFocus &&
                            field?.value &&
                            !inputValidate({
                                name: nameField,
                                value: field?.value,
                            })
                                ? 'validate'
                                : null);

                        if (resultError) {
                            this.errorsText[nameField] = getTextError({
                                name: nameField,
                                value: field?.value || '',
                                error,
                            });
                        }

                        const errorText = this.errorsText[nameField];

                        return (
                            <div className="authForm__contentGroupField" key={nameField}>
                                <div className="authForm__contentGroupFieldBox">
                                    <Field
                                        type={fieldType}
                                        keyName={nameField}
                                        name={nameField}
                                        value={field?.value}
                                        id={`input-auth-${this.name}-${nameField}`}
                                        error={resultError}
                                        callback={this.handlerField}
                                        className="_grey _fontMedium"
                                    />
                                </div>
                                <Animate
                                    className="authForm__contentGroupFieldError"
                                    isShow={!!resultError}
                                    type="static"
                                    classInner="authForm__contentGroupFieldErrorWrapper"
                                    actionInit={() =>
                                        this.setState({ counterUpdateError: new Date().getTime() })
                                    }
                                    actionPrevRemove={() =>
                                        this.setState({ counterUpdateError: new Date().getTime() })
                                    }
                                >
                                    <div className="authForm__contentGroupFieldErrorWrapper">
                                        <div
                                            className="authForm__contentGroupFieldErrorInner"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(errorText),
                                            }}
                                        ></div>
                                    </div>
                                </Animate>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    handlerLoading(loadingKey = null) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    checkValidateFields(allFields = this.getAllFields()) {
        const { fields } = this.state;
        const validateFields = allFields.filter((nameField) =>
            inputValidate({ name: nameField, value: fields[nameField].value }),
        );
        const notValidateFields = allFields.filter(
            (nameField) => !inputValidate({ name: nameField, value: fields[nameField].value }),
        );
        let errorFieldName = null;
        const { length: lengthValidateFields } = validateFields;

        errorFieldName = notValidateFields[0];

        return {
            isSuccess: lengthValidateFields === allFields.length,
            errorFieldName,
        };
    }

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const fields = JSON.parse(JSON.stringify(newState.fields));

                    if (action === 'change') {
                        fields[name].value = value;
                        fields[name].error = null;
                    }

                    if (action === 'focus') {
                        fields[name].isFocus = true;
                    }

                    if (action === 'blur') {
                        fields[name].isFocus = false;
                    }

                    if (action === 'error') {
                        fields[name].error = value || null;
                    }

                    newState.fields = fields;

                    return newState;
                },
                () => {
                    if (action === 'change' && this.checkProgress) {
                        this.checkProgress();
                    }

                    resolve();
                },
            );
        });
    }

    getAllFields(orderGroups = this.orderGroups) {
        const fields = [];

        (orderGroups || []).forEach((nameGroup) => {
            const group = this.groups[nameGroup];

            group.fields.forEach((nameField) => {
                fields.push(nameField);
            });
        });

        return fields;
    }

    initFields() {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const fields = {};

                this.getAllFields().forEach((nameField) => {
                    fields[nameField] = {
                        value: '',
                        error: null,
                    };
                });

                newState.fields = fields;

                return newState;
            }, resolve);
        });
    }

    getOrderBlocks() {
        const { currentStep } = this.state;

        if (currentStep && this.blocks[currentStep]) {
            return [{ id: currentStep }];
        }

        return [];
    }

    renderBlock({ prop: id }) {
        const block = this.blocks[id];

        return <div className={`authForm__contentBlock _col _${id}`}>{block.render.call(this)}</div>;
    }

    renderPagination(isShow = true) {
        const { currentStep } = this.state;
        const blockKeys = Object.keys(this.blocks).filter(
            (blockName) => this.name !== 'forget' || blockName !== 'success',
        );

        return (
            <Animate className="authForm__headPagination _row" isShow={isShow}>
                {blockKeys.map((blockName) => (
                    <div
                        className={`authForm__headPaginationItem _click ${
                            blockName === currentStep ? '_current' : ''
                        }`}
                        key={blockName}
                        onClick={() => {
                            if (this.name !== 'forget') {
                                this.handlerStep({ currentStep: blockName });
                            }
                        }}
                    ></div>
                ))}
            </Animate>
        );
    }

    componentDidMount() {
        this.initFields().then(() => {
            if (this.componentDidMountOther) {
                this.componentDidMountOther();
            }
        });
    }
}

export default AuthForm;
