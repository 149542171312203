import React from 'react';

import checkStateOfLessItems from './methods/checkStateOfLessItems.ts';
import checkUpdate from './methods/checkUpdate.ts';
import handlerScroll from './methods/handlerScroll.ts';
import init from './methods/init.ts';

import ListScrollI from './types.ts';

class ListScroll extends React.Component<ListScrollI['props']> implements ListScrollI {
    timerIdCheckState: ListScrollI['timerIdCheckState'];
    parent: ListScrollI['parent'];

    constructor(props: ListScrollI['props']) {
        super(props);
        this.state = {};

        this.handlerScroll = this.handlerScroll.bind(this);
    }

    lengthCurrent = 0;
    isProccess = false;
    isInit = false;

    handlerScroll = handlerScroll;
    checkStateOfLessItems = checkStateOfLessItems;
    checkUpdate = checkUpdate;
    init = init;

    componentDidMount() {
        const { callbackDuration = 300 } = this.props;
        this.init();

        this.timerIdCheckState = setTimeout(() => {
            const { isDisabledScroll } = this.props;

            if (!isDisabledScroll) {
                this.checkStateOfLessItems();
            }
        }, callbackDuration);
    }

    componentDidUpdate() {
        this.init();
        this.checkUpdate();
    }

    componentWillUnmount() {
        const { handlerLoaderList } = this.props;
        this.parent?.removeEventListener('scroll', this.handlerScroll);

        if (handlerLoaderList) {
            handlerLoaderList(false);
        }
    }

    render() {
        const { children } = this.props;

        return <>{children}</>;
    }
}

export default ListScroll;
