import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';

class HideNotificationsBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { handlerHideState, counter } = this.props;

        return (
            <div
                ref={this.parent}
                className={`hideNotificationsBar _click ${counter > 0 ? '_shake' : ''}`}
                onClick={(e) => {
                    handlerHideState();

                    e.stopPropagation();
                }}
            >
                <div className="hideNotificationsBar__inner _col">
                    <i className="hideNotificationsBar__icon">
                        <Icon name="bar-notifications" />
                    </i>
                    <div className="hideNotificationsBar__counter">
                        <AnimateChangeUp
                            className="hideNotificationsBar__counterInner"
                            renderKey={counter}
                        >
                            {counter}
                        </AnimateChangeUp>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isProccessLogin: state.isProccessLogin,
    };
}

export default connect(mapStateToProps)(HideNotificationsBar);

HideNotificationsBar.propTypes = {
    user: PropTypes.object,
    isProccessLogin: PropTypes.bool,
    handlerHideState: PropTypes.func,
    counter: PropTypes.number,
};
