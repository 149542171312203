import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';

import getActions from './methods/getActions.ts';
import getQuery from './methods/getQuery.ts';

import BalanceI from './types.ts';

import renderHeadTitle from './renders/renderHeadTitle.tsx';

class Balance extends WidgetWrapper<BalanceI['props'], BalanceI['state']> implements BalanceI {
    parent: BalanceI['parent'];

    constructor(props: BalanceI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderHeadTitle = renderHeadTitle;

    getQuery = getQuery;
    getActions = getActions;

    render() {
        const { counter = 0 } = this.state;
        const { type } = this.props;

        return (
            <>
                <Widget
                    name="corporationBalances"
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    className="_white"
                    withBack={false}
                    inWidget={true}
                    filterQuery={(this as BalanceI).getFilterQuery?.()}
                    renderHeadTitle={this.renderHeadTitle.bind(this)}
                >
                    <Table
                        name="corporationBalances"
                        isNotLink={true}
                        isInit={true}
                        inWidget={true}
                        getQuery={this.getQuery!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        callback={this.tableCallback!.bind(this)}
                        localFilterName={type}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Balance);
