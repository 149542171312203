import React from 'react';

import CalendarI, { DayT } from '../types.ts';

const renderBlock: CalendarI['renderBlock'] = function ({ item }) {
    return (
        <div className="v2calendar__contentBlock _ITEM">
            {item.days.map((day: DayT) => (
                <div className="v2calendar__contentItem _COL" key={day.id}>
                    <div
                        className={`v2calendar__contentDate _COL ${
                            day.isMonthCurrent ? '_active' : ''
                        } ${day.isCurrent ? '_current' : ''} ${
                            day.disabled ? '_disabled' : '_CLICK'
                        } ${day.inside ? '_inside' : ''} ${day.isNow ? '_now' : ''}`}
                        onClick={() => {
                            if (!day.disabled) {
                                this.setDate(new Date(day.time), true);
                            }
                        }}
                    >
                        {day.day}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default renderBlock;
