import AlertI from '../../types';

const deletePayProject = {
    title: 'Подтвердите удаление<br/>проекта',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить проект <b>${info}</b>?`;
    },
    buttonText: 'Удалить',
} as const;

export default deletePayProject;
