import removeTransition from '../../../functions/removeTransition.ts';

import ItemsI, { ItemT } from '../types.ts';

const setItems: ItemsI['setItems'] = function (
    this: ItemsI,
    { items, filter, isFilter, isLimit, counter, isReverse },
) {
    const { setInit, setCounter } = this.props;

    return new Promise((resolve) => {
        this.setState(
            (state) => {
                const newState = { ...state } as ItemsI['state'];
                const { items: stateItems = [] } = newState;

                newState.isLimit = isLimit ?? items.length < this.stepCounter;

                if (items.length === 0) {
                    newState.isLimit = true;
                }

                newState.counter = counter;

                if (!newState.filter) {
                    newState.filter = filter;
                }

                if (isFilter) {
                    newState.items = items;
                    newState.updateKey = new Date().getTime();
                } else {
                    items.forEach((item) => {
                        const stateItemIndex = stateItems.findIndex(
                            (stateItem: ItemT) => stateItem._id === item._id,
                        );

                        if (stateItemIndex === -1) {
                            stateItems[isReverse ? 'unshift' : 'push'](item);
                        } else {
                            stateItems[stateItemIndex] = item;
                        }
                    });

                    newState.items = JSON.parse(JSON.stringify(stateItems));
                }

                newState.updateListKey = new Date().getTime();

                newState.saveItems = JSON.parse(JSON.stringify(newState.items || []));

                return newState;
            },
            () => {
                removeTransition({
                    item: this.classNameItem,
                    isCurrent: true,
                });

                setTimeout(() => {
                    if (!this.state.isReady) {
                        this.setState({ isReady: true }, () => {
                            if (typeof setInit === 'function') {
                                setInit();
                            }
                        });
                    }
                }, 10);

                if (typeof setCounter === 'function') {
                    setCounter(counter || 0);
                }

                resolve();
            },
        );
    });
};

export default setItems;
