import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import Inner from '../../../../components/crm/manual/Inner.jsx';
import getArticles from '../../../../requests/getArticles';
import setSpacesInText from '../../../../functions/setSpacesInText';

class ContentInfoInner extends Inner {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingFilter: false,
        };

        this.parent = React.createRef();
    }

    mainPage = 'content-infoPublic';

    getArticle() {
        const { levels, setInfoHead } = this.props;
        const url = levels[1];

        getArticles('info', { params: [{ key: 'url', value: url }] }).then(({ article }) => {
            this.setState({ article }, () => {
                setInfoHead({ key: 'article', value: article });

                setTimeout(() => {
                    this.setState({ isReady: true });
                }, 100);
            });
        }, this.handlerErrorGetModel);
    }

    componentDidMount() {
        this.getArticle();
    }

    render() {
        const { isReady, isLoadingFilter, article } = this.state;

        return (
            <div
                ref={this.parent}
                className={`widget ${!isLoadingFilter && isReady ? '_ready' : ''}`}
            >
                <Animate className="widget__loader _loader" isShow={!isReady || isLoadingFilter}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _full _inner _scroll">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox _padding">
                            <div className="contentInfoPublic">
                                <div className="contentInfoPublic__article">
                                    <div className="contentInfoPublic__articleSection">
                                        {article?.sectionText}
                                    </div>
                                    <h1
                                        className="contentInfoPublic__articleTitle"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(article?.title),
                                        }}
                                    ></h1>
                                    <div
                                        className="contentInfoPublic__articleInner"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(article?.content),
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(ContentInfoInner);

ContentInfoInner.propTypes = {
    setHeightPage: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
    levels: PropTypes.array,
    search: PropTypes.string,
};
