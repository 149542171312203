import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Notifications from './Notifications.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import News from './News.jsx';
import NewsInner from './NewsInner.jsx';

class NotificationsBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: localStorage.getItem('notificationsCurrentPage') || 'notifications',
        };

        this.renderPage = this.renderPage.bind(this);
        this.setCurrentPage = this.setCurrentPage.bind(this);

        this.parent = React.createRef();
    }

    pagesOrder = ['notifications', 'news', 'news-inner'];

    getPagesOrder() {
        const { currentPage } = this.state;

        return [{ key: currentPage }];
    }

    pages = {
        notifications: {
            title: 'Уведомления',
            render() {
                const { notifications = [] } = this.props;

                return (
                    <>
                        <Notifications notifications={notifications} />
                    </>
                );
            },
        },
        news: {
            title: 'Новости',
            render() {
                return (
                    <>
                        <News setCurrentPage={this.setCurrentPage} />
                    </>
                );
            },
        },
        'news-inner': {
            render() {
                const { newsId } = this.state;

                return (
                    <>
                        <NewsInner id={newsId} setCurrentPage={this.setCurrentPage} />
                    </>
                );
            },
        },
    };

    setCurrentPage(currentPage, newsId) {
        if (currentPage !== 'news-inner') {
            localStorage.setItem('notificationsCurrentPage', currentPage);
        }

        this.setState({ currentPage, newsId });
    }

    renderPage({ prop: key }) {
        const page = this.pages[key];

        return <div className="widget__block">{page?.render?.call(this)}</div>;
    }

    render() {
        const { currentPage } = this.state;
        const { isHide, notificationCounter, announceCounter } = this.props;

        return (
            <div ref={this.parent} className="notificationsBar">
                <div className="notificationsBar__inner">
                    <div className={`widget _ready ${isHide ? '_windowFull' : ''}`}>
                        <div className="widget__head _row">
                            <div className="widget__headContent">
                                <div className="widget__headInner _row">
                                    <div className="notificationsBar__head _row">
                                        {['notifications', 'news'].map((key) => {
                                            const page = this.pages[key];
                                            const isCurrent =
                                                key === 'news'
                                                    ? currentPage.includes('news')
                                                    : currentPage === key;

                                            const counter =
                                                key === 'news'
                                                    ? announceCounter
                                                    : notificationCounter;

                                            return (
                                                <div
                                                    className={`notificationsBar__headTab _row _click ${
                                                        isCurrent ? '_current' : ''
                                                    } ${counter > 0 ? '_alert' : ''}`}
                                                    key={key}
                                                    onClick={() => this.setCurrentPage(key)}
                                                >
                                                    <div className="notificationsBar__headTabTitle">
                                                        {page.title}
                                                    </div>
                                                    <AnimateChangeUp
                                                        className="notificationsBar__headTabCounter"
                                                        renderKey={counter}
                                                    >
                                                        <>({counter})</>
                                                    </AnimateChangeUp>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`widget__content _scroll ${!isHide ? '_full' : ''}`}>
                            <ListAbsoluteMain
                                className="widget__blocks"
                                items={this.getPagesOrder()}
                                renderItem={this.renderPage}
                                classNameItem="widget__block"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={[]}
                                isNotParamsItem={true}
                                allItems={this.pagesOrder}
                                currentItemKey={currentPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NotificationsBar);

NotificationsBar.propTypes = {
    user: PropTypes.object,
    isHide: PropTypes.bool,
    notifications: PropTypes.array,
    notificationCounter: PropTypes.number,
    announceCounter: PropTypes.number,
};
