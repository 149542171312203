const corporationRoleSections = {
    colsOrder: ['name', 'actions'],
    cols: {
        name: { content: 'Название раздела', width: 78 },
        actions: { content: 'Статус', width: 22 },
    },
    deletesCols: [],
    emptyInfo: {
        title: 'Ролей пока нет',
    },
} as const;

export default corporationRoleSections;
