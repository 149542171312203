import React from 'react';

import Balance from '../components/balance/Balance';
import Docs from '../components/docs/Docs';
import Integrations from '../components/integrations/Integrations';
import Logs from '../components/logs/Logs';
import Main from '../components/main/Main';
import Reports from '../components/reports/Reports';
import Roles from '../components/roles/Roles';
import Users from '../components/users/Users';

import Contracts from '../../joins/contracts/Contracts';
import I from '../types';

const pages = {
    'settings-main': {
        render(this: I) {
            const { model } = this.state;

            return (
                <>
                    <Main model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'settings-operations': {
        render(this: I) {
            return (
                <>
                    <div className="body__innerPageWrapper">
                        <Balance />
                    </div>
                </>
            );
        },
    },
    'settings-docs': {
        render(this: I) {
            const { model } = this.state;

            return <Docs model={model!} setModel={this.setModel.bind(this)} />;
        },
    },
    'settings-users': {
        render(this: I) {
            return (
                <div className="body__innerPageWrapper">
                    <Users />
                </div>
            );
        },
    },

    'settings-roles': {
        render(this: I) {
            const { model } = this.state;

            return (
                <>
                    <Roles model={model!} />
                </>
            );
        },
    },
    'settings-proxy': {
        render(this: I) {
            return (
                <div className="body__innerPageWrapper">
                    <Contracts type="userProxy" inWidget={false} />
                </div>
            );
        },
    },
    'settings-integrations': {
        render(this: I) {
            const { model } = this.state;

            return <Integrations model={model!} setModel={this.setModel.bind(this)} />;
        },
    },
    'settings-reports': {
        render(this: I) {
            return (
                <div className="body__innerPageWrapper">
                    <Reports />
                </div>
            );
        },
    },
    'settings-incomes': {
        render(this: I) {
            return (
                <>
                    <div className="body__innerPageWrapper">
                        <Balance type="income" />
                    </div>
                </>
            );
        },
    },
    'settings-logs': {
        render(this: I) {
            const { model } = this.state;

            return <Logs corporationId={model!._id} />;
        },
    },
} as const;

export default pages;
