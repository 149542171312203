import React from 'react';

import ActionChangeI from './types.ts';

class ActionChange
    extends React.Component<ActionChangeI['props'], ActionChangeI['state']>
    implements ActionChangeI
{
    constructor(props: ActionChangeI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { active, className, onClick } = this.props;

        return (
            <div className={`_ACTION_CHANGE_WRAPPER _CLICK ${className}`} onClick={onClick}>
                <div className={`v2actionChange ${active ? '_active' : ''}`} />
            </div>
        );
    }
}

export default ActionChange;
