const corporationBalances = {
    colsOrder: ['date', 'description', 'amount'],
    cols: {
        date: {  content: 'Дата и время',
            width: 13,
        },
        description: {  content: 'Назначение платежа',
            width: 72,
            isAdminMain: true,
        },
        amount: {  content: 'Сумма',
            width: 15,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'corporation',
    emptyInfo: {
        title: 'Операций пока нет',
        description: 'Они будут сохраняться здесь<br/>при списаниях и начислениях',
    },
} as const;

export default corporationBalances;
