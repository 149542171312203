import I from '../types.ts';

const movePinArea: I['movePinArea'] = function () {
    const area = this.getPinArea();

    const resultX = this.moveX;
    const resultY = this.moveY;

    area.style.transform = `translate(${resultX}px,${resultY}px)`;
};

export default movePinArea;
