import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import WidgetI from '../../types.ts';

const renderClose: WidgetI['renderHeadAction'] = function ({ prop }) {
    const { actions } = this.props;
    const action = actions?.find((innerAction) => innerAction.name === prop);
    const onClick = action?.onClick;

    return (
        <div className="v2widget__headClose _CLICK" onClick={onClick}>
            <i className="v2widget__headCloseIcon">
                <Icon name="popup-close" />
            </i>
        </div>
    );
};

export default renderClose;
