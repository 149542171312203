import ListScrollI from '../types.ts';

const handlerScroll: ListScrollI['handlerScroll'] = function (isForced) {
    const {
        callback,
        lengthCurrent,
        stepCounter,
        maxCounter,
        handlerLoaderList,
        direction = 'vertical',
        isLimit,
        isDisabledScroll,
        isReverse,
        startCounter,
        hideCallback,
        callbackDuration = 300,
        otherItemsCounter = 0,
        offsetY = () => 0,
    } = this.props;
    const parent = this.parent;

    const update = () => {
        this.isProccess = true;

        if (handlerLoaderList) {
            handlerLoaderList(true);
        }

        setTimeout(() => {
            callback(lengthCurrent + stepCounter - otherItemsCounter);
        }, callbackDuration);
    };

    if (parent) {
        const valScroll =
            (direction === 'horizontal' ? parent.scrollLeft : parent.scrollTop) + offsetY();
        const sizeScroll =
            direction === 'horizontal'
                ? parent.scrollWidth - parent.offsetWidth
                : parent.scrollHeight - parent.offsetHeight;

        if (
            !isLimit &&
            !this.isProccess &&
            lengthCurrent < maxCounter &&
            isDisabledScroll !== true
        ) {
            if ((isReverse ? -valScroll : valScroll) >= sizeScroll - 10 || isForced === true) {
                update();
            }
        }

        if (hideCallback && lengthCurrent > startCounter && !isDisabledScroll) {
            if ((!isReverse && valScroll <= 10) || (isReverse && valScroll >= sizeScroll - 10)) {
                hideCallback();
            }
        }
    }
};

export default handlerScroll;
