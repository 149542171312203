import TableI from '../types.ts';

import { TableT } from '../static/tables.ts';

const getOrderCols: TableI['getOrderCols'] = function () {
    const { name } = this.props;
    const table = this.tables[name];
    const orderCols = [...table.colsOrder] as string[];

    if (this.checkAdmin()) {
        const { adminIndex, deletesCols } = table as unknown as TableT;

        if (typeof adminIndex === 'number') {
            orderCols.splice(adminIndex, 0, 'corporationInfo');

            deletesCols.forEach((key) => {
                const index = orderCols.indexOf(key);

                if (index !== -1) {
                    orderCols.splice(index, 1);
                }
            });
        }
    }

    return orderCols;
};

export default getOrderCols;
