import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';

class PublicButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { children, className = '' } = this.props;

        return (
            <div className={`publicButton _row _click ${className}`}>
                {children}
                <i className="publicButton__icon">
                    <Icon name="arrow-next-2" />
                </i>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(PublicButton);

PublicButton.propTypes = {
    children: PropTypes.object,
    className: PropTypes.string,
};
