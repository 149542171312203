export default function getDateFromString({ date }) {
    if (!date || typeof date !== 'string') {
        return null;
    }

    if (!/\d\d.\d\d.\d\d\d\d/gi.test(date)) {
        return null;
    }

    const [day, month, year] = date.split('.');

    const dateObject = new Date();

    dateObject.setFullYear(year);
    dateObject.setMonth(+month - 1);
    dateObject.setDate(day);

    return dateObject;
}
