const paysGroups = {
    colsOrder: ['name', 'counter', 'amount', 'date', 'signStatus', 'payStatus'],
    cols: {
        name: { content: 'Название', width: (admin: boolean) => (admin ? 30 : 35) },
        counter: {
            content: 'Акты',
            width: (admin: boolean) => (admin ? 13 : 8),
            isAdminMain: true,
        },
        amount: { content: 'Общая сумма', width: 17 },
        date: { content: (admin: boolean) => (admin ? 'Дата' : 'Дата загрузки'), width: 14 },
        signStatus: { content: 'Подписаны', width: 13 },
        payStatus: { content: 'Оплачены', width: 13 },
    },
    adminIndex: 0,
    deletesCols: ['amount', 'date'],
    url: 'pays',
    emptyInfo: {
        title: 'Реестров пока нет',
        description: 'Вы можете загрузить реестр по кнопке справа',
    },
} as const;

export default paysGroups;
