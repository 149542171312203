import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../../../Field.jsx';
import Select from '../../../Select.jsx';
import Switch from '../../../Switch.jsx';
import Animate from '../../../Animate.jsx';
import Loader from '../../../Loader.jsx';

import { findPointWords, geocode } from '../../../../functions/handlerYmap';

import getDateFromString from '../../../../functions/getDateFromString';
import getStatusDate from '../../../../functions/crm/getStatusDate';
import checkValueOfEmpty from '../../../../functions/checkValueOfEmpty';
import Ymaps from '../../../Ymaps.jsx';
import Icon from '../../../Icon.jsx';

const fieldsManual = require('../../../../infos/crm/fieldsManual.json');

class ManualField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerField = this.handlerField.bind(this);
        this.handlerAction = this.handlerAction.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);

        this.parent = React.createRef();
    }

    actions = {
        checkSmzInn: {
            icon: 'reload',
        },
        appChat: {
            icon: 'executor-chat',
        },
        telegramChat: {
            icon: 'telegram',
        },
    };

    getClassName() {
        const { type, name, value, otherInfo } = this.props;

        if (type === 'executor') {
            if (name === 'rate' && value) {
                if (+value >= 4) {
                    return '_successColor';
                }

                if (+value >= 3 && +value < 4) {
                    return '_mediumColor';
                }

                if (+value < 3) {
                    return '_errorColor';
                }
            }

            if (name === 'status') {
                if (value === 'active') {
                    return '_successColor';
                }

                if (value === 'invite') {
                    return '_mediumColor';
                }

                if (value === 'check-invite' || value === 'app-join' || value === 'app-invite') {
                    return '_mediumColor';
                }

                if (value === 'inactive') {
                    return '_errorColor';
                }
            }

            if (name === 'dateAction') {
                const date = getDateFromString({ date: value });

                return getStatusDate({ date }).className;
            }
        }

        if (type === 'car') {
            if (name === 'status') {
                if (value === 'active') {
                    return '_successColor';
                }

                if (value === 'inactive') {
                    return '_errorColor';
                }
            }
        }

        if (value && type === 'organization' && name === 'inn' && otherInfo) {
            if (otherInfo.isJurStatusActive === true) {
                return '_successColor';
            }
            if (otherInfo.isJurStatusActive === false) {
                return '_errorColor';
            }
        }

        return '';
    }

    handlerField({ ...props }) {
        const { ymaps, suggestError } = this.state;
        const { withAddressList, handler, name } = this.props;

        const handlerAddress = () =>
            new Promise((resolve) => {
                if (!withAddressList) {
                    handler({ ...props }).then(resolve);
                } else if (props.action === 'change') {
                    this.setState({ address: props.value }, () => {
                        if (props.value.length > 2 && ymaps) {
                            findPointWords(ymaps, props.value).then(
                                (response) => {
                                    const addressList = response.map((item) => item.displayName);

                                    this.setState({
                                        suggestError: false,
                                        addressList,
                                    });
                                },
                                () => {
                                    this.setState({ suggestError: true });
                                },
                            );
                        } else {
                            this.setState({ addressList: null });
                        }

                        if (suggestError) {
                            handler({
                                action: 'change',
                                name,
                                value: props.value,
                            });
                        } else {
                            handler({
                                action: 'change',
                                name,
                                value: '',
                                otherData: { coords: null },
                            });
                        }

                        resolve();
                    });
                } else {
                    resolve();
                }
            });

        return new Promise((resolve) => {
            handlerAddress().then(resolve);
        });
    }

    setAddress(address) {
        const { ymaps } = this.state;
        const { handler, name } = this.props;

        const middleGeocode = () =>
            new Promise((resolve) => {
                if (name !== 'addressFact') {
                    resolve();
                } else {
                    geocode(ymaps, address).then((response) => {
                        const coords = response.geometry._coordinates;

                        resolve(coords);
                    });
                }
            });

        middleGeocode().then((coords) => {
            handler({
                action: 'change',
                name,
                value: address,
                ...(coords ? { otherData: { coords } } : {}),
            }).then(() => {
                this.setState({
                    address,
                    addressList: null,
                });
            });
        });
    }

    checkChangeAddress() {
        const { saveValue } = this.state;
        const { value, withAddressList } = this.props;

        if (withAddressList && value && value !== saveValue) {
            this.setState({ saveValue: value, address: value });
        }
    }

    handerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerAction() {
        const { handlerAction } = this.props;

        if (handlerAction) {
            this.handerLoadingKey('action').then(() => {
                handlerAction().then(() => {
                    this.handerLoadingKey(null);
                });
            });
        }
    }

    handlerMissClick({ target }) {
        const { addressList } = this.state;

        if (
            addressList &&
            this.parent.current !== target &&
            !this.parent.current.contains(target)
        ) {
            setTimeout(() => {
                this.setState({ addressList: null });
            }, 10);
        }
    }

    componentDidMount() {
        this.checkChangeAddress();

        document.addEventListener('click', this.handlerMissClick);
    }

    componentDidUpdate() {
        this.checkChangeAddress();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const { address, addressList = [], loadingKey } = this.state;
        const {
            type,
            name,
            keyField,
            value,
            handler,
            group,
            isReadOnly,
            isDisabled,
            min,
            max,
            items,
            notItems,
            isError,
            className = '',
            classNameField = '',
            isLoading = false,
            id,
            withAddressList,
            action,
            isEditmode = true,
            tag,
        } = this.props;
        const fields = fieldsManual[type];
        const resultFields = fields?.[keyField || name];
        const { type: typeOfField } = resultFields;
        const support = this.props.fieldSupport || resultFields.support;
        const { inServerData, withSearch } = resultFields;
        let { keyName, nameSelect } = resultFields;

        if (this.props.nameSelect) {
            nameSelect = this.props.nameSelect;
        }

        if (this.props.keyName) {
            keyName = this.props.keyName;
        }

        return (
            <div
                ref={this.parent}
                className={`manualField _row _${typeOfField} _${type} _${name} ${className} ${
                    action ? `_withAction _${action}` : ''
                } ${tag === 'area' ? '_area' : ''}`}
            >
                {resultFields && (
                    <>
                        <p className="manualField__support">{support}</p>
                        <div className="manualField__box">
                            <div className="manualField__boxInner">
                                {checkValueOfEmpty(isLoading) && (
                                    <Animate className="manualField__loader _loader" isShow={isLoading}>
                                        <div className="manualField__loaderIcon _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                )}
                                {typeOfField === 'input' && (
                                    <>
                                        <Field
                                            support={this.props.support || '–'}
                                            name={name}
                                            keyName={keyName || name}
                                            value={withAddressList ? address : value}
                                            callback={this.handlerField}
                                            className={`_manualField ${classNameField} _${name} ${this.getClassName()}`}
                                            isDisabled={
                                                isReadOnly ??
                                                isDisabled ??
                                                (isLoading ? true : undefined)
                                            }
                                            min={min}
                                            max={max}
                                            group={group}
                                            isEditmode={isEditmode}
                                            error={isError}
                                            id={id}
                                            tag={tag}
                                        />
                                        {withAddressList && (
                                            <>
                                                <Ymaps
                                                    forCalc={true}
                                                    callback={({ ymaps, map }) =>
                                                        this.setState({ ymaps, map })
                                                    }
                                                />
                                                <Animate
                                                    className="manualField__list"
                                                    isShow={addressList?.length > 0}
                                                >
                                                    {addressList?.map((item, key) => (
                                                        <div
                                                            className="manualField__listItem _click"
                                                            key={key}
                                                            onClick={() => {
                                                                this.setAddress(item);
                                                            }}
                                                        >
                                                            {item}
                                                        </div>
                                                    ))}
                                                </Animate>
                                            </>
                                        )}
                                    </>
                                )}
                                {typeOfField === 'select' && (
                                    <Select
                                        keyName={keyName || name}
                                        support="–"
                                        name={name}
                                        type={nameSelect}
                                        value={value}
                                        callback={handler}
                                        className={`_fullWidthList _manualField _${name} ${classNameField} ${this.getClassName()}`}
                                        group={group}
                                        isDisabled={
                                            isReadOnly ??
                                            isDisabled ??
                                            (isLoading ? true : undefined)
                                        }
                                        isEditmode={isEditmode}
                                        items={items}
                                        notItems={notItems}
                                        error={isError}
                                        id={id}
                                        inServerData={inServerData}
                                        withSearch={withSearch}
                                    />
                                )}
                                {typeOfField === 'switch' && (
                                    <Switch
                                        value={value}
                                        name={name}
                                        className={`_manual _size3 ${classNameField}`}
                                        isDisabled={isReadOnly || isDisabled || isLoading}
                                        isEditmode={isEditmode}
                                        handler={handler}
                                        id={id}
                                    />
                                )}
                            </div>
                            {action && this.actions[action] && (
                                <>
                                    <div
                                        className={`manualField__action _col _click _${action}`}
                                        onClick={this.handlerAction}
                                    >
                                        <Animate
                                            className="manualField__actionLoader _loader"
                                            isShow={loadingKey === 'action'}
                                        >
                                            <div className="manualField__actionLoaderItem _loaderItem">
                                                <Loader className="_main" />
                                            </div>
                                        </Animate>
                                        <i className="manualField__actionIcon">
                                            <Icon name={this.actions[action]?.icon} />
                                        </i>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualField);

ManualField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    keyField: PropTypes.string,
    value: PropTypes.string,
    handler: PropTypes.func,
    group: PropTypes.string,
    isReadOnly: PropTypes.bool,
    isDisabled: PropTypes.bool,
    min: PropTypes.object,
    max: PropTypes.object,
    items: PropTypes.array,
    notItems: PropTypes.array,
    isError: PropTypes.bool,
    className: PropTypes.string,
    classNameField: PropTypes.string,
    isLoading: PropTypes.bool,
    id: PropTypes.string,
    withAddressList: PropTypes.bool,
    nameSelect: PropTypes.string,
    keyName: PropTypes.string,
    otherInfo: PropTypes.object,
    action: PropTypes.string,
    handlerAction: PropTypes.func,
    isEditmode: PropTypes.bool,
    support: PropTypes.string,
    tag: PropTypes.string,
    fieldSupport: PropTypes.string,
};
