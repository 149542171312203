import { getCookie } from './handlerCookies';

export default function getEnvParams() {
    const system = process.env.REACT_APP_SYSTEM;

    return {
        keyLevelsChatInner: system === 'crm' ? 3 : 2,
        nameParentChat: system === 'crm' ? 'chat' : 'cabinet-chat',
        checkChangeChat({ levels, idOfChat, isCheckParent }) {
            if (system === 'crm') {
                return (
                    levels[1] === 'chat' && ((levels[3] && levels[3] !== idOfChat) || isCheckParent)
                );
            }

            return levels[1] === 'chat' && ((levels[2] && levels[2] !== idOfChat) || isCheckParent);
        },
        textLinkChatInOrderDetails: system === 'crm' ? 'Написать водителю' : 'Написать в поддержку',
        checkMeOfChatMessage({ chat, user, message }) {
            if (system === 'crm') {
                return chat && chat.entity.id !== message.creater;
            }

            if (getCookie('hashOrderInfo')) {
                return message.creater === getCookie('hashOrderInfo');
            }

            return user._id === message.creater;
        },
        heightChatPreviewItem: system === 'crm' ? 80 : 74,
        paddingChatPage: system === 'crm' ? 20 : 20,
        classNameFilterButton: system === 'crm' ? '_mainNotBorder' : '',
    };
}
