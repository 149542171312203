import getFormatedNumber from '../../../functions/getFormatedNumber.ts';

import CalendarI from '../types.ts';

const setTime: CalendarI['setTime'] = function ({ value: time }) {
    const currentDate = new Date(this.state.currentDate as Date);
    let updatedTimeKey = this.state.updatedTimeKey;

    time = time.toString();

    if (this.getId(currentDate) === this.getId(new Date())) {
        if (time.replace(/[^\d]/gi, '').length === 4) {
            const [hours, minutes] = time.split(':');

            if (
                +hours < new Date().getHours() ||
                (+hours === new Date().getHours() && +minutes < new Date().getMinutes())
            ) {
                time = `${getFormatedNumber(new Date().getHours())}:${getFormatedNumber(
                    new Date().getMinutes(),
                )}`;

                updatedTimeKey = new Date().getTime();
            }
        }
    }

    return new Promise((resolve) => {
        this.setState({ currentTime: time, updatedTimeKey, error: null }, resolve);
    });
};

export default setTime;
