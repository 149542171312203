import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function ({ executorMvd }) {
    const { user } = this.state;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'executorMvd',
                    modelId: executorMvd._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (
        getPermissions(user, {
            key: 'joins',
            items: [{ key: 'mvd', rules: ['update'] }],
        })
    ) {
        actions.unshift({
            key: 'edit',
            name: 'edit',
            onClick: async () => {
                handlerPopup('executorMvdPopup', {
                    isShow: true,
                    id: executorMvd._id,
                });
            },
        });
    }

    return actions;
};

export default getMoreActions;
