import ListDynamicI, { IndexesStateItemsT, ItemT, ItemsT } from '../types.ts';

const checkItems: ListDynamicI['checkItems'] = function (isStart) {
    const { items: itemsState = [] } = this.state;
    const { items: itemsProps, callback, keyUpdateItem, proccessCallback } = this.props;
    const propsForUpdate = JSON.parse(JSON.stringify(this.props.propsForUpdate || []));
    const updatedProps = [];

    let isChangeLen = false;
    let isChangeOrder = false;
    let isChange = false;
    let isDelete = false;
    const indexesStateItems: IndexesStateItemsT = {};
    const indexesSavedStateItems: IndexesStateItemsT = {};

    itemsState.forEach((item, key) => {
        indexesStateItems[this.getItem(item)] = key;
    });

    if (this.savedPropsItems) {
        this.savedPropsItems.forEach((item, key) => {
            indexesSavedStateItems[this.getItem(item)] = key;
        });
    }

    itemsProps.forEach((itemProps, itemPropsIndex) => {
        const itemKey = this.getItem(itemProps as ItemT);
        const itemStateIndex = indexesStateItems[itemKey] ?? -1;
        const itemSavedPropsIndex = indexesSavedStateItems[itemKey] ?? -1;
        const itemState = itemsState[itemStateIndex];

        if (keyUpdateItem && keyUpdateItem !== this.keyUpdateItem) {
            Object.keys(itemProps).forEach((key) => {
                propsForUpdate.push(key);
            });
        }

        if (!itemState) {
            isChange = true;
        } else {
            propsForUpdate.forEach((key: string) => {
                if (itemState[key] !== itemProps[key]) {
                    isChange = true;

                    updatedProps.push(key);
                }
            });
        }

        if (itemSavedPropsIndex !== itemPropsIndex) {
            isChangeOrder = true;
        }

        if (this.itemsDelete[itemKey]) {
            this.itemsNotDelete[itemKey] = true;

            isDelete = true;

            isChange = true;
        }
    });

    if (keyUpdateItem && keyUpdateItem !== this.keyUpdateItem) {
        this.keyUpdateItem = keyUpdateItem;
    }

    if (isDelete) {
        this.itemsDelete = {};
    }

    const showNewItems = (): Promise<void> =>
        new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const items = JSON.parse(JSON.stringify(newState.items || [])) as ItemT[];

                items.forEach((item) => {
                    if (item.isShow === false) {
                        item.isShow = true;
                    }
                });

                newState.items = items;

                return newState;
            }, resolve);
        });

    if (isChange) {
        const itemsNew: ItemsT[] = [];

        this.savedPropsItems = JSON.parse(JSON.stringify(itemsProps));

        if (proccessCallback && !isStart) {
            proccessCallback({ type: 'add' });
        }

        this.setState(
            (state) => {
                const newState = { ...state };
                const items = JSON.parse(JSON.stringify(newState.items || [])) as ItemT[];

                itemsProps.forEach((itemProps) => {
                    const itemKey = this.getItem(itemProps);
                    const stateItemIndex = indexesStateItems[itemKey] ?? -1;

                    if (stateItemIndex === -1) {
                        items.push({ ...itemProps, isShow: !!isStart });

                        if (!this.itemsNotDelete[itemKey]) {
                            itemsNew.push(itemProps);
                        }

                        isChangeLen = true;
                    } else {
                        const itemState = items[stateItemIndex];

                        propsForUpdate.forEach((key: keyof ItemT) => {
                            if (itemState[key] !== itemProps[key]) {
                                itemState[key] = itemProps[key];
                            }
                        });
                    }

                    // console.log(isChangeOrder, itemsProps);
                });

                newState.items = items;

                return newState;
            },
            () => {
                setTimeout(() => {
                    if (!isStart) {
                        showNewItems().then(() => {
                            if (callback) {
                                callback({
                                    isChangeLen,
                                    isChangeOrder,
                                    itemsNew,
                                    isUpdateItem: updatedProps.length > 0,
                                });
                            }
                        });
                    } else if (callback) {
                        callback({
                            isChangeLen,
                            isChangeOrder,
                            itemsNew,
                            isUpdateItem: updatedProps.length > 0,
                        });
                    }
                }, 10);
            },
        );
    } else if (isChangeOrder && callback) {
        this.savedPropsItems = JSON.parse(JSON.stringify(itemsProps));

        setTimeout(() => {
            callback({ isChangeOrder: true });
        }, 10);
    } else {
        this.savedPropsItems = JSON.parse(JSON.stringify(itemsProps));
    }
};

export default checkItems;
