import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';
import EditInnerI from '@components/editInner/types.ts';
import Switch from '@components/switch/Switch.tsx';
import sortAlphabet from '@functions/sortAlphabet.ts';
import RoleT from '@global/models/Role.ts';

import { RenderColsT } from '../../types.ts';

const rules = {
    read: 'read',
    update: 'update',
    create: 'create',
    delete: 'delete',
    sign: 'sign',
} as const;

const renderCorporationRoleSection: RenderColsT<'corporationRoleSection'> = function ({
    name: colName,
    item,
}) {
    const sectionItem = item as RoleT['sections'][number]['items'][number];
    let content;
    let className;
    const { otherData } = this.props;
    const role = otherData?.role as RoleT;
    const section = otherData?.section as RoleT['sections'][number];
    const change = otherData?.change as EditInnerI['change'];
    const { templateSections } = role;
    const templateSection = templateSections.find((innerItem) => innerItem.key === section.key);
    const templateSectionItems = templateSection?.items || [];
    const templateSectionItem = templateSectionItems.find(
        (innerItem) => innerItem.key === sectionItem.key,
    );

    if (colName === 'name') {
        className = '_full';

        const isAll = !!(
            templateSectionItem?.rules.every((rule) => sectionItem.rules.includes(rule)) &&
            templateSectionItem?.actions.every((action) => sectionItem.actions.includes(action.key))
        );

        content = (
            <>
                <div className="v2table__roleSection _COL">
                    <label className="v2table__roleSectionTitle">
                        <div className="v2table__roleSectionTitleCheckbox">
                            <Checkbox
                                className="_green"
                                name={section.key}
                                onChange={() => {
                                    if (isAll) {
                                        change({
                                            [`sections.${section._id}.items.${sectionItem._id}.rules`]:
                                                [],
                                            [`sections.${section._id}.items.${sectionItem._id}.actions`]:
                                                [],
                                        });
                                    } else {
                                        change({
                                            [`sections.${section._id}.items.${sectionItem._id}.rules`]:
                                                [...(templateSectionItem?.rules || [])].sort(
                                                    sortAlphabet.bind(null, undefined),
                                                ),
                                            [`sections.${section._id}.items.${sectionItem._id}.actions`]:
                                                [
                                                    ...(templateSectionItem?.actions.map(
                                                        (action) => action.key,
                                                    ) || []),
                                                ].sort(sortAlphabet.bind(null, undefined)),
                                        });
                                    }
                                }}
                                value={
                                    sectionItem.rules.length > 0 || sectionItem.actions.length > 0
                                }
                            >
                                <b>{sectionItem.name}</b>
                            </Checkbox>
                        </div>
                    </label>
                    {templateSectionItem && templateSectionItem.actions.length > 0 && (
                        <div className="v2table__roleSectionActions _COL">
                            {templateSectionItem.actions.map((action) => (
                                <label className="v2table__roleSectionAction" key={action.key}>
                                    <div className="v2table__roleSectionActionCheckbox">
                                        <Checkbox
                                            className="_green"
                                            name={section.key}
                                            onChange={() => {
                                                change({
                                                    [`sections.${section._id}.items.${sectionItem._id}.actions`]:
                                                        action.key,
                                                });
                                            }}
                                            value={sectionItem.actions.includes(action.key)}
                                        >
                                            {action.name}
                                        </Checkbox>
                                    </div>
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            </>
        );
    } else {
        content = (
            <>
                {templateSectionItem?.rules.includes(rules[colName]) ? (
                    <Switch
                        className="_mediumSize _green"
                        value={sectionItem.rules.includes(rules[colName])}
                        name="isActive"
                        // disabled={!!loadings[section._id]}
                        onChange={() => {
                            change({
                                [`sections.${section._id}.items.${sectionItem._id}.rules`]:
                                    rules[colName],
                            });
                        }}
                    />
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderCorporationRoleSection;
