import axios from 'axios';

import getHeaders from '../getHeaders';

export default function deleteNotifications({ id }) {
    return new Promise((resolve) => {
        axios
            .delete(`${process.env.REACT_APP_API}/notifications?id=${id}`, {
                headers: getHeaders(),
            })
            .then(resolve, () => null);
    });
}