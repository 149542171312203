import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { ExecutorT } from '@global/models/Executor.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const fnsHandler: I['fnsHandler'] = async function (props) {
    const model = this.state.model!;
    let response;

    try {
        response = await axios.patch<{}, { data: ReqResponseT<{ model?: ExecutorT }> }>(
            `${process.env.REACT_APP_API}/executor`,
            {
                id: model._id,
                action: 'handler-fns',
                ...props,
            },
            {
                headers: getHeaders(),
            },
        );
    } catch (error) {
        return Promise.reject(error);
    }

    const { success, data } = response.data;

    if (success) {
        const resultModel = data.model;

        if (resultModel) {
            this.init({ fields: resultModel });
            this.props.setModel(resultModel);
        }

        return;
    }

    const { error } = data;

    this.setState({ error });
};

export default fnsHandler;
