import checkHolder from '@functions/crm/checkHolder.js';
import getPermissions from '@functions/crm/getPermissions.ts';
import download from '@functions/download';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import ContractsI from '../types.ts';

const getMoreActions: ContractsI['getMoreActions'] = function ({ joinContract }) {
    const { user } = this.state;
    const { type } = this.props;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'joinContract',
                    modelId: joinContract._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (joinContract.fileName) {
        actions.unshift(
            ...([
                {
                    key: 'open',
                    name: 'openContract',
                    text: 'Открыть',
                    onClick: async () => {
                        window.open(
                            `${process.env.REACT_APP_STATIC}/corporations-docs/${joinContract.fileName}`,
                            '_blank',
                        );
                    },
                },
                {
                    key: 'download',
                    name: 'downloadContract',
                    text: 'Скачать',
                    onClick: download.bind(null, {
                        name: `Договор_${joinContract.executorName?.split(' ').join('_') || ''}`,
                        files: [{ src: `corporations-docs/${joinContract.fileName}` }],
                    }),
                },
            ] as CursorActionsItemT[]),
        );
    }

    if (
        !joinContract.inactive &&
        (getPermissions(user, {
            notAdminCorporation: true,
            key: 'joins',
            items: [
                {
                    key: 'contracts',
                    rules: ['sign'],
                },
            ],
        }) ||
            (type === 'userProxy' &&
                checkHolder({ user }) &&
                user?.idOfCurrentCorporation !== 'admin'))
    ) {
        actions.push({
            key: 'delete',
            name: 'inactiveContract',
            text: 'Расторгнуть',
            onClick: this.inactiveContract.bind(this, { joinContract, contractType: type }),
        });
    }

    if (
        !joinContract.inactive &&
        (getPermissions(user, {
            adminCorporation: true,
            key: 'joins',
            items: [{ key: 'contracts', rules: [], actions: ['refresh'] }],
        }) ||
            (type === 'userProxy' &&
                checkHolder({ user }) &&
                user?.idOfCurrentCorporation !== 'admin'))
    ) {
        actions.push({
            key: 'repeat',
            name: 'refreshContract',
            text: 'Обновить',
            onClick: this.contractHandler.bind(this, { joinContract, action: 'refresh' }),
        });
    }

    return actions;
};

export default getMoreActions;
