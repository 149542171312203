import React from 'react';

import EditBlockI from '@components/editBlock/types';
import { FilterBlockT } from '@global/types';

type ParamsT = {
    change: EditBlockI['change'];
    index: number;
} & Pick<FilterBlockT, 'items' | 'value'>;

export default function Checkbox(props: ParamsT): React.ReactNode {
    const { items, value, change, index } = props;

    return (
        <div className="v2filter__blockItems _ROW">
            {items.map((item) => {
                const isCurrent = (value as string[]).indexOf(item.id) !== -1;

                return (
                    <div
                        className={`v2filter__blockItem _FOCUS _CLICK ${
                            isCurrent ? '_current' : ''
                        }`}
                        key={item.id}
                        onClick={() => {
                            change({
                                [`${index}.value`]: item.id,
                            });
                        }}
                    >
                        {item.content}
                    </div>
                );
            })}
        </div>
    );
}
