import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { isEdit, model } = this.state;
    const method = isEdit ? 'patch' : 'post';
    const fields = this.checkChange(true).model;

    await handlerLoading.call(this, 'save');

    const response = await axios[method]<
        {},
        {
            data: ReqResponseT<{
                message: string;
                error?: { name: string; text: string };
            }>;
        }
    >(
        `${process.env.REACT_APP_API}/role`,
        { fields, ...(isEdit ? { id: model!._id } : {}) },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        this.close();

        document.dispatchEvent(
            new CustomEvent('refreshTable', { detail: { name: 'corporationRoles' } }),
        );
    } else {
        const { error } = data;

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default save;
