const executorCreate = {
    fieldsOrder: ['secondName', 'firstName', 'thirdName', 'phone', 'line', 'type', 'scriptId'],
    fields: {
        secondName: {
            support: 'Фамилия',
            prop: 'secondName',
            type: 'input',
            group: 1,
            width: 1,
        },
        firstName: {
            support: 'Имя',
            prop: 'firstName',
            type: 'input',
            group: 1,
            width: 1,
        },
        thirdName: {
            support: 'Отчество',
            prop: 'thirdName',
            type: 'input',
            group: 2,
            width: 1,
        },
        phone: {
            support: 'Телефон',
            prop: 'phone',
            type: 'input',
            reg: 'phone',
            group: 2,
            width: 1,
        },
        line: {
            type: 'line',
        },
        type: {
            support: 'Категория',
            prop: 'type',
            selectTextKey: 'typeName',
            type: 'select',
            selectList: 'executorTypes',
            selectProps: { name: 'typeName' },
            group: 3,
            width: 1,
        },
        scriptId: {
            support: 'Сценарий',
            prop: 'scriptId',
            selectTextKey: 'scriptName',
            type: 'select',
            selectList: 'joinScripts',
            selectProps: {
                name: 'scriptName',
                link: 'scriptLink',
            },
            group: 3,
            width: 1,
        },
    },
} as const;

export default executorCreate;
