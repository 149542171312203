import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Pages from '@components/pages/Pages.tsx';
import getUserName from '@functions/getUserName.ts';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getQuery from './methods/getQuery.ts';

import ProfileI from './types.ts';

import pages from './static/pages.tsx';

class Profile extends WidgetWrapper<ProfileI['props'], ProfileI['state']> implements ProfileI {
    parent: ProfileI['parent'];

    constructor(props: ProfileI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    url = 'user';
    pages = pages;

    getActions = getActions;
    getQuery = getQuery;

    componentDidMount(): void {
        this.getModel();
    }

    render() {
        const { model } = this.state;

        return (
            <>
                <Widget
                    name="user"
                    title="Профиль"
                    descriptionInner={model ? getUserName({ user: model }) : ''}
                    actions={this.getActions!()}
                    isFull={true}
                >
                    <div className={`v2widget__contentWait ${model ? '_show' : ''}`}>
                        {model && (
                            <Pages
                                className="body__innerPages _ITEMS"
                                classNamePage="body__innerPage _ITEM"
                                filter={(page) => page.parentName === 'profile'}
                                pages={this.pages}
                                context={this}
                            />
                        )}
                    </div>
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Profile);
