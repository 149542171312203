import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

class Window extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, isShow, isReverseY, left, top, children, className = '' } = this.props;

        return (
            <div
                className={`body__window ${isShow ? '_show' : ''} ${
                    isReverseY ? '_reverseY' : ''
                } ${className}`}
                style={{ left: `${left}px`, top: `${top}px` }}
                data-key={id}
            >
                <div className="body__windowInner">{children}</div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Window);

Window.propTypes = {
    id: PropTypes.string,
    isShow: PropTypes.bool,
    isReverseY: PropTypes.bool,
    left: PropTypes.number,
    top: PropTypes.number,
    children: PropTypes.node,
    className: PropTypes.string,
};
