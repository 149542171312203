import getFormatedNumber from '../../../functions/getFormatedNumber.ts';

import CalendarI from '../types.ts';

const setDate: CalendarI['setDate'] = function (date, withCurrent) {
    const { momentCallback, isDouble } = this.props;
    const activeDate = new Date(date);

    date.setHours(12);

    if (isDouble) {
        if (
            this.savedChangedDoubleDateKey === 'currentDate' &&
            (this.state.doubleCurrentDate as Date)?.getTime() < date.getTime()
        ) {
            this.savedChangedDoubleDateKey = 'doubleCurrentDate';
        }

        if (
            this.savedChangedDoubleDateKey === 'doubleCurrentDate' &&
            (this.state.currentDate as Date)?.getTime() > date.getTime()
        ) {
            this.savedChangedDoubleDateKey = 'currentDate';
        }
    }

    const dateProp = isDouble ? this.savedChangedDoubleDateKey : 'currentDate';

    const dateTextProp = `${dateProp}Text`;

    activeDate.setDate(1);

    let currentDateText: string;

    if (withCurrent) {
        const currentDate = new Date(date);

        currentDateText = `${getFormatedNumber(currentDate.getDate())}.${getFormatedNumber(
            currentDate.getMonth() + 1,
        )}.${getFormatedNumber(currentDate.getFullYear())}`;

        if (this.getId(currentDate) === this.getId(new Date())) {
            this.setNowTime();
        }
    }

    return new Promise((resolve) => {
        this.setState(
            {
                activeDate,
                ...(withCurrent
                    ? { [dateProp]: new Date(date), [dateTextProp]: currentDateText }
                    : {}),
            },
            () => {
                if (withCurrent) {
                    this.savedChangedDoubleDateKey =
                        this.savedChangedDoubleDateKey === 'doubleCurrentDate'
                            ? 'currentDate'
                            : 'doubleCurrentDate';

                    if (typeof momentCallback === 'function') {
                        momentCallback({ [dateProp]: new Date(date).getTime() });
                    }
                }

                resolve();
            },
        );
    });
};

export default setDate;
