import ListDynamicI, { ItemT } from '../types.ts';

const handlerItems: ListDynamicI['handlerItems'] = function (e) {
    const { detail } = e;
    const { name, items = [], callback } = detail;

    if (name && this.props.name && name === this.props.name) {
        this.setState(
            {
                isDisabled: true,
                items: (items as ItemT[]).map((item) => ({ ...item, isShow: true })),
            },
            () => {
                if (typeof callback === 'function') {
                    callback();
                }

                if (typeof this.props.callback === 'function') {
                    this.props.callback({ isChangeLen: true });
                }
            },
        );

        setTimeout(() => {
            this.setState({ isDisabled: false });
        }, 300);
    }

    return e as CustomEvent;
};

export default handlerItems;
