import I from '../types.ts';

const searchItems: I['searchItems'] = function (this: I, name) {
    this.setState({ isLoadingSearch: true }, () => {
        if (this.searchTimers[name]) {
            clearTimeout(this.searchTimers[name]);
        }

        this.searchTimers[name] = setTimeout(async () => {
            await this.updateItems();

            this.setState({ isLoadingSearch: false });
        }, 300);
    });
};

export default searchItems;
