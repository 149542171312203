import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import FilterI from '../types.ts';

const renderContent: FilterI['renderContent'] = function () {
    const { model = [], name } = this.state;
    const info = name ? this.info[name] : null;
    const { filterQuery = [] } = this.state;
    const { isChange } = this.checkChange();

    const withButton = isChange || filterQuery.length > 0;

    return (
        <>
            <div className="v2popup__head _ROW">
                <div className="v2popup__title">{info?.title}</div>
                <div className="v2popup__close _CLICK" onClick={this.close}>
                    <Icon name="popup-close" />
                </div>
            </div>
            <div className="v2popup__content">
                <div className={`v2filter ${withButton ? '_withButton' : ''}`}>
                    <div className="v2filter__scroll _NOSCROLL">
                        {model.map((block, index) => this.renderBlock({ block, index }))}
                    </div>
                    {this.renderButton()}
                </div>
            </div>
        </>
    );
};

export default renderContent;
