const walletCard = {
    fieldsOrder: ['number', 'current'],
    fields: {
        number: {
            support: 'Номер карты',
            type: 'input',
            regExp: /[^\d]/gi,
        },
        current: {
            support: 'Сделать основным',
            type: 'switch',
        },
    },
} as const;

export default walletCard;
