import React from 'react';

import Button from '@components/button/Button';
import Table from '@components/crm/table/Table';
import Icon from '@components/icon/Icon';

import PaySignI from '../../types';

export default function renderItems(this: PaySignI): React.ReactNode {
    const { loadingKey } = this.state;
    const { paySignPopup } = this.props;
    const { isSign } = paySignPopup;

    return (
        <>
            <div className="v2popup__description _bottomLine">
                {isSign ? (
                    <>
                        Вы хотите подписать выбранные акты —<br />
                        подтвердите операцию смс-кодом
                    </>
                ) : (
                    <>
                        Вы хотите оплатить выбранные акты —<br />
                        подтвердите операцию смс-кодом
                    </>
                )}
            </div>
            <div className="v2popupSign">
                <div className="v2popupSign__inner">
                    <div className="v2popupSign__table">
                        <Table
                            inWidget={true}
                            name="signPays"
                            isNotLink={true}
                            isInit={true}
                            isRowHeight={true}
                            init={this.tableInit!.bind(this)}
                            callback={this.tableCallback?.bind(this)}
                            getQuery={this.getQuery!.bind(this)}
                            withHead={false}
                            // getRowClassName={() => '_notBottomBorder'}
                        />
                    </div>
                </div>
                <div className="v2popupSign__foot _COL">
                    <div className="v2popupSign__button">
                        <Button
                            className="_main"
                            onClick={this.sign.bind(this)}
                            loading={loadingKey === 'items'}
                        >
                            Смс-код{' '}
                            <i className="_arrowNext">
                                <Icon name="arrow-next" />
                            </i>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
