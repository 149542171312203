import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import Interface from '../types.ts';

const getParentActions: Interface['getParentActions'] = function () {
    const { user, payGroup } = this.state;
    const { groupId } = this.props;
    const actions = [] as WidgetActionsT[];

    if (
        getPermissions(user, {
            key: 'pays',
            items: [{ key: 'acts', rules: ['create'] }],
        }) &&
        (user?.idOfCurrentCorporation !== 'admin' || payGroup?.adminParse)
    ) {
        actions.push({
            type: 'link',
            name: 'add',
            group: 'buttons',
            content: '+ Новый акт',
            className: '_main',
            onClick: async () => {
                handlerPopup('payPopup', {
                    isShow: true,
                    ...(groupId !== 'main' ? { groupId } : {}),
                });
            },
        });
    }

    if (
        groupId &&
        groupId !== 'main' &&
        getPermissions(user, {
            key: 'pays',
            items: [{ key: 'acts', rules: ['delete'] }],
        }) &&
        payGroup?.corporationId === user?.idOfCurrentCorporation &&
        !payGroup?.type
    ) {
        actions.push({
            type: 'link',
            name: 'delete',
            group: 'buttons',
            content: 'Удалить',
            className: '_alertEmpty',
            onClick: this.deleteGroup.bind(this),
        });
    }

    return actions;
};

export default getParentActions;
