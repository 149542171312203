import { FilterQueryT } from '@global/types.ts';

import I from '../types.ts';

const getQuery: I['getQuery'] = function (this: I) {
    const { contractSignPopup } = this.props;
    const { items = [], type, executorId } = contractSignPopup;
    const query: FilterQueryT[] = [];

    query.push({ key: 'notCompleted', value: true });

    if (type) {
        query.push({ key: 'type', value: type });
    }

    if (executorId) {
        query.push({ key: 'executorId', value: executorId });
    }

    if (items[0] !== 'all') {
        query.push(...items.map((id) => ({ key: 'listIds', value: id })));
    }

    return query;
};

export default getQuery;
