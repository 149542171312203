const joinsTemplates = {
    colsOrder: ['name', 'date'],
    cols: {
        name: {
            content: 'Название шаблона',
            width: 60,
            isAdminMain: true,
        },
        date: {
            content: 'Дата создания',
            width: 40,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'join-template',
    emptyInfo: {
        title: 'Шаблонов пока нет',
        description: 'Вы можете создать шаблон по кнопке справа',
    },
} as const;

export default joinsTemplates;
