import React from 'react';
import { connect } from 'react-redux';

import Pages from '@components/pages/Pages.tsx';

import ScriptsI from './types.ts';

import pages from './static/pages.tsx';

class Scripts extends React.Component<ScriptsI['props'], ScriptsI['state']> implements ScriptsI {
    parent: ScriptsI['parent'];

    constructor(props: ScriptsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = pages;

    render() {
        return (
            <>
                <Pages
                    className="body__innerPages _ITEMS"
                    classNamePage="body__innerPage _ITEM"
                    filter={(page) => page.parentName === 'joins-scripts'}
                    pages={this.pages}
                    context={this}
                />
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Scripts);
