import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Switch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { value, name, handler, className = '', isDisabled, isEditmode } = this.props;

        return (
            <label
                className={`switch ${className} ${isEditmode ? '_editmode' : ''} ${
                    isDisabled !== undefined && !isDisabled ? '_isEdit' : ''
                } ${isDisabled ? '_disabled' : ''}`}
            >
                <input
                    type="checkbox"
                    className="switch__input"
                    checked={value}
                    name={name}
                    disabled={isDisabled}
                    onChange={() => {
                        if (handler && typeof handler === 'function') {
                            handler({ action: 'change', name, value: !value });
                        }
                    }}
                />
                <div className="switch__view _click" />
            </label>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Switch);

Switch.propTypes = {
    value: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    handler: PropTypes.func,
    isDisabled: PropTypes.bool,
    isEditmode: PropTypes.bool,
};
