import axios from 'axios';

import changePage from '../functions/changePage';
import getHeaders from '../functions/getHeaders';
import setNotification from '../functions/setNotification';

export default function createChat({
    idOfOrder,
    changeToChatPage = true,
    isNotNotificate = false,
}) {
    const body = {};

    if (idOfOrder) {
        body.idOfOrder = idOfOrder;
    }

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_API}/chat`, body, { headers: getHeaders() })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success) {
                        const { id } = data;

                        if (!isNotNotificate) {
                            setNotification({ notification: 'success-create-chat' });
                        }

                        if (changeToChatPage) {
                            changePage({
                                href: 'cabinet-chat-inner',
                                id,
                            });
                        }

                        resolve({ id });
                    } else {
                        const { message, id } = data;

                        if (!isNotNotificate && message === 'Chat already create') {
                            if (idOfOrder) {
                                setNotification({ notification: 'chat-already-create' });
                            } else {
                                setNotification({ notification: 'opened-chat-already-create' });
                            }
                        }

                        resolve({ id });
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
