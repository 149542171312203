import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Avatar from '@components/avatar/Avatar.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import changeCorporation from '../../../functions/crm/changeCorporation';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import getFormatPrice from '../../../functions/getFormatPrice';
import handlerPopup from '../../../functions/handlerPopup';

import Animate from '../../Animate.jsx';
import Button from '../../Button.jsx';
import Link from '../../Link.jsx';
import Loader from '../../Loader.jsx';

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHide: false,
        };

        this.changeCorporation = this.changeCorporation.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
        this.renderListCurrentItem = this.renderListCurrentItem.bind(this);
        this.renderListInfo = this.renderListInfo.bind(this);

        this.parent = React.createRef();
    }

    saveCorporation(isStart) {
        const { user } = this.props;

        if (user) {
            const currentCorporation = getCurrentCorporation({ user });

            if (
                currentCorporation &&
                currentCorporation._id !== this.state.currentCorporation?._id
            ) {
                setTimeout(
                    () => {
                        this.setState({ currentCorporation });
                    },
                    isStart ? 0 : 300,
                );
            }
        }
    }

    changeCorporation({ detail: { id: idOfCurrentCorporation } }) {
        if (idOfCurrentCorporation) {
            this.saveCorporation();
        }
    }

    setLoading(changeCompanyId) {
        return new Promise((resolve) => {
            this.setState({ changeCompanyId }, resolve);
        });
    }

    getListItems(isAll = false) {
        const { currentCorporation } = this.state;
        const { user } = this.props;

        return (
            user?.corporationsInfo.filter(
                (item) => isAll || (currentCorporation?._id !== item._id && !item.isAdmin),
            ) || []
        );
    }

    renderListItem({ item: corp, prop: id }) {
        const { changeCompanyId } = this.state;
        const { user, handlerState, setUser } = this.props;
        const items = this.getListItems(true);
        const item = items.find((itemLoop) => itemLoop._id === id) || corp;
        const resultId = item?.isAdmin ? 'admin' : id;

        return (
            <div
                className={`sideBarCompany__listItem _click`}
                onClick={() => {
                    changeCorporation({ user, id: resultId }).then(() => {
                        handlerState(false);

                        setUser();
                    });
                }}
                key={id}
            >
                <div className="sideBarCompany__listItemInner _row">
                    <div className="sideBarCompany__listItemPreview _col">
                        <Animate
                            className="sideBarCompany__listItemPreviewLoader _loader"
                            isShow={changeCompanyId === id}
                            isSmoothShow={true}
                        >
                            <div className="sideBarCompany__listItemPreviewLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div className="sideBarCompany__listItemPreviewLogo">
                            <Avatar
                                image={item.logo?.fullSrc}
                                firstSymb={item.fullName?.[0].toUpperCase()}
                                className="_grey"
                            />
                        </div>
                    </div>
                    <div className="sideBarCompany__listItemContent _col">
                        <div className="sideBarCompany__listItemName">
                            {item.shortName || item.fullName}
                        </div>
                        <div className="sideBarCompany__listItemDescription">
                            {getFormatPrice(item?.balance || 0)}₽
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getCurrentCorporation({ user }) {
        const { admin } = this.props;

        if (admin) {
            return user.corporationsInfo.find((item) => item.isAdmin);
        }

        return getCurrentCorporation({ user });
    }

    getCurrentItems() {
        const { user } = this.props;
        const currentCorporation = this.getCurrentCorporation({ user });

        return currentCorporation ? [currentCorporation] : [];
    }

    renderListCurrentItem({ item, prop: id, isShow }) {
        const { user } = this.props;
        const currentCorporation = this.getCurrentCorporation({ user });
        const state = currentCorporation?._id === item?._id ? '_next' : '_prev';

        return (
            <div
                className={`sideBarCompany__infoContent _col ${isShow ? '_show' : ''} ${state}`}
                key={id}
                data-_id={id}
            >
                <div className="sideBarCompany__name">
                    {item?.shortName || item?.fullName || '–'}
                </div>
                <div className="sideBarCompany__description">
                    <>{getFormatPrice(item?.balance || 0)}₽</>
                </div>
            </div>
        );
    }

    renderListInfo() {
        const { handlerState } = this.props;

        return (
            <div className="sideBarCompany__listInfoItem _col">
                <div className="sideBarCompany__listInfoTitle">Это все компании</div>
                <p className="sideBarCompany__listInfoDescription">Но вы можете добавить ещё:</p>
                <div className="sideBarCompany__listInfoButton">
                    <Button
                        showLoader={false}
                        className="_mediumSize _mainNotBorder"
                        onClick={() => {
                            handlerPopup('corporationCreatePopup', {
                                isShow: true,
                            });
                            handlerState(false);
                        }}
                    >
                        Добавить компанию
                    </Button>
                </div>
            </div>
        );
    }

    handlerMissClick({ target }) {
        const { handlerState } = this.props;

        if (target !== this.parent.current && !this.parent.current.contains(target)) {
            handlerState(false);
        }
    }

    componentDidMount() {
        document.addEventListener('changeCorporation', this.changeCorporation);
        document.addEventListener('click', this.handlerMissClick);

        this.saveCorporation(true);
    }

    componentDidUpdate() {
        this.saveCorporation();
    }

    componentWillUnmount() {
        document.removeEventListener('changeCorporation', this.changeCorporation);
        document.removeEventListener('click', this.handlerMissClick);
    }

    render() {
        const { itemsHeight = 0 } = this.state;
        const { user, handlerState, isShowCompanyList, setContentHeight, admin, isList } =
            this.props;
        const currentCorporation = this.getCurrentCorporation({ user });
        const condForList = !admin;
        const withScroll = itemsHeight > 240;
        const resultItemsHeight = withScroll ? 240 : itemsHeight;

        return (
            <div
                ref={this.parent}
                className={`sideBarCompany ${isShowCompanyList ? '_show' : ''} ${
                    !condForList ? '_disabled' : ''
                } ${user?.isAdmin ? '_admin' : ''} ${isList ? '_list' : ''}`}
            >
                {!isList && (
                    <div
                        className={`sideBarCompany__head ${condForList ? '_click' : ''}`}
                        onClick={() => {
                            if (condForList) {
                                handlerState();
                            }
                        }}
                    >
                        <div className="sideBarCompany__headInner _row">
                            <Link
                                pageName="corporation"
                                className="sideBarCompany__preview"
                                isStopPropagation={true}
                            >
                                <AnimateChange
                                    className="sideBarCompany__previewInner _default"
                                    renderKey={currentCorporation?._id}
                                    styles={[]}
                                >
                                    <Avatar
                                        image={currentCorporation?.logo.fullSrc}
                                        firstSymb={currentCorporation?.fullName?.[0]?.toUpperCase()}
                                    />
                                </AnimateChange>
                            </Link>
                            <div className="sideBarCompany__info _col">
                                <ListAbsoluteMain
                                    className="sideBarCompany__infoInner"
                                    classNameItem="sideBarCompany__infoContent"
                                    items={this.getCurrentItems()}
                                    renderItem={this.renderListCurrentItem}
                                    callback={() => {
                                        setContentHeight();
                                    }}
                                    prop="_id"
                                    propsForUpdate={['balance', 'shortName']}
                                    itemParams={['height', 'width']}
                                    itemOffsetWidth={2}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {condForList && (
                    <Animate
                        className={`sideBarCompany__list ${withScroll ? '_withScroll' : ''}`}
                        isShow={isShowCompanyList}
                        actionInit={() => {
                            this.setState({ isInitList: true });
                        }}
                    >
                        <div className="sideBarCompany__listBox">
                            <div
                                className="sideBarCompany__listInner"
                                style={{ height: `${resultItemsHeight}px` }}
                            >
                                <ListAbsoluteMain
                                    className="sideBarCompany__listItems"
                                    classNameItem="sideBarCompany__listItem"
                                    items={this.getListItems()}
                                    renderItem={this.renderListItem}
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    callback={({ type, params }) => {
                                        if (type === 'parent') {
                                            this.setState({ itemsHeight: params.height });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {!isList && (
                            <ListAbsoluteMain
                                className="sideBarCompany__listInfo"
                                items={[{ key: 'info' }]}
                                renderItem={this.renderListInfo}
                                classNameItem="sideBarCompany__listInfoItem"
                                prop="key"
                                styles={['height']}
                                isNotParamsItem={true}
                            />
                        )}
                    </Animate>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isProccessLogin: state.isProccessLogin,
    };
}

export default connect(mapStateToProps)(Company);

Company.propTypes = {
    user: PropTypes.object,
    isProccessLogin: PropTypes.bool,
    handlerState: PropTypes.func,
    isShowCompanyList: PropTypes.bool,
    setContentHeight: PropTypes.func,
    admin: PropTypes.bool,
    setUser: PropTypes.func,
    isList: PropTypes.bool,
};
