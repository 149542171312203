const lists = {
    colsOrder: ['name'],
    cols: {
        name: {
            content: 'Название',
            width: 100,
        },
    },
    deletesCols: [],
    url: 'list',
} as const;

export default lists;
