import React from 'react';
import { connect } from 'react-redux';

import getPageLink from '@functions/getPageLink.ts';
import { s } from '@functions/seo.ts';
import { StoreT } from '@global/types.ts';

import checkChange from './methods/checkChange.ts';
import checkCurrent from './methods/checkCurrent.ts';
import clickHandler from './methods/clickHandler.ts';
import contextMenuHandler from './methods/contextMenuHandler.ts';
import getHref from './methods/getHref.ts';
import setHref from './methods/setHref.ts';

import LinkI from './types.ts';

class Link extends React.Component<LinkI['props'], LinkI['state']> implements LinkI {
    parent: LinkI['parent'];
    contextId: LinkI['contextId'];

    constructor(props: LinkI['props']) {
        super(props);
        this.state = {};

        this.contextMenuHandler = this.contextMenuHandler.bind(this);

        this.parent = React.createRef();
    }

    ids = {};

    checkCurrent = checkCurrent;
    getHref = getHref;
    setHref = setHref;
    checkChange = checkChange;
    clickHandler = clickHandler;
    contextMenuHandler = contextMenuHandler;

    componentDidMount() {
        this.checkChange(true);

        this.contextId = `contextMenu-${this.props.href}-${new Date().getTime()}-${Math.random() * Math.random()}`;

        document.addEventListener('contextmenu', this.contextMenuHandler);
    }

    componentDidUpdate() {
        this.checkChange();
    }

    componentWillUnmount(): void {
        document.removeEventListener('contextmenu', this.contextMenuHandler);
    }

    render() {
        const {
            children,
            className = '',
            tag,
            pageName,
            storePages,
            ids,
            ...otherProps
        } = this.props;
        let LinkTag = (tag || 'div') as React.ElementType;
        let href = this.getHref(this.props.href ?? this.state.href);
        const isCurrent = (this.props.isCurrent ?? this.checkCurrent()) || null;

        if (s()) {
            href = getPageLink({ name: pageName as string, storePages, ids });
            LinkTag = 'a';
        }

        return (
            <LinkTag
                className={`${className} _CLICK ${isCurrent ? '_current' : ''}`}
                onClick={this.clickHandler.bind(this)}
                ref={this.parent}
                href={href?.[0] === '/' ? href : `/${href}`}
                {...(!s() ? otherProps : {})}
            >
                {children}
            </LinkTag>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        levels: state.levels,
        storePages: state.pages,
        user: state.user,
    };
}

export default connect(mapStateToProps)(Link);
