import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import checkValueOfEmpty from '../../functions/checkValueOfEmpty';

import getEndText from '../../functions/getEndText';

import Search from '../Search.jsx';
import Icon from '../Icon.jsx';
import Animate from '../Animate.jsx';
import AnimateChange from '../AnimateChange.jsx';

import Avatar from '../Avatar.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import ListScroll from '../ListScroll.jsx';
import removeTransition from '../../functions/removeTransition.ts';

class List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            isShow: false,
            isFresh: false,
        };

        this.handlerSearch = this.handlerSearch.bind(this);
        this.filterItem = this.filterItem.bind(this);
        this.sortItems = this.sortItems.bind(this);
        this.filterForGroup = this.filterForGroup.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.getMoreItems = this.getMoreItems.bind(this);
        this.filterScrollItem = this.filterScrollItem.bind(this);
        this.getSortedItems = this.getSortedItems.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.hideCallback = this.hideCallback.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 10;

    choicesInfo = {
        creater: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['сотрудник', 'сотрудника', 'сотрудников'],
        },
        responsible: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['ответственный', 'ответственных', 'ответственных'],
        },
        crew: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['исполнитель', 'исполнителя', 'исполнителей'],
        },
        cars: {
            keySearch: 'infoCars',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['автомобиль', 'автомобиля', 'автомобилей'],
        },
        bodyworks: {
            keySearch: 'default',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['кузов', 'кузова', 'кузовов'],
        },
        executors: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['исполнитель', 'исполнителя', 'исполнителей'],
        },
        contractors: {
            keySearch: 'default',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['контрагент', 'контрагента', 'контрагентов'],
        },
        clients: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['клиент', 'клиента', 'клиентов'],
        },
        companies: {
            keySearch: 'default',
            supports: ['Выбрана', 'Выбраны', 'Выбрано'],
            contents: ['компания', 'компании', 'компаний'],
        },
        executorUsers: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['водитель', 'водителя', 'водителей'],
        },
        organizations: {
            keySearch: 'name',
            supports: ['Выбран', 'Выбрано', 'Выбрано'],
            contents: ['клиент', 'клиента', 'клиентов'],
        },
        corporations: {
            keySearch: 'default',
            supports: ['Выбрана', 'Выбраны', 'Выбрано'],
            contents: ['компания', 'компании', 'компаний'],
        },
    };

    groupSearch = {
        name: {
            support: 'Введите ФИО',
            params: ['firstName', 'secondName', 'thirdName', 'name'],
        },
        infoCars: {
            support: 'Введите марку или номер',
            params: ['nameModel', 'number'],
        },
        default: {
            support: 'Введите название',
            params: ['content'],
        },
    };

    orderActions = ['add', 'delete'];

    renderChoice() {
        const { name, choices } = this.props;
        let content = null;
        const info = this.choicesInfo[name];

        if (choices.length === 0) {
            content = `Не ${info.supports[0]?.toLowerCase()}`;
        } else {
            const support = `${getEndText(choices.length, info.supports)}: `;

            content = (
                <>
                    <AnimateChange
                        className="filterList__choiceInner"
                        classNameParent="_parent"
                        prop={support}
                        type="_translateMedium"
                    >
                        {support}
                    </AnimateChange>
                    <AnimateChange
                        className="filterList__choiceInner"
                        classNameParent="_parent"
                        prop={choices.length}
                        type="_translateMedium"
                    >
                        {`${choices.length} ${getEndText(
                            choices.length,
                            this.choicesInfo[name].contents,
                        )}`}
                    </AnimateChange>
                </>
            );
        }

        return (
            <>
                <AnimateChange
                    className={`filterList__choice _row ${choices.length === 0 ? '_empty' : ''}`}
                    prop={choices.length === 0}
                    type="_translateMedium"
                >
                    {content}
                </AnimateChange>
            </>
        );
    }

    handlerSearch({ action, value: search }) {
        const { callback } = this.props;

        if (action === 'change') {
            this.setState({ search }, () => {
                callback({});
            });
        }
    }

    filterForGroup(item) {
        const { search } = this.state;
        const info = this.getInfoSearch();
        const { params } = info;

        return (
            !search ||
            params.find(
                (prop) =>
                    item[prop] &&
                    item[prop].toLowerCase().indexOf(search.toLowerCase().trim()) !== -1,
            )
        );
    }

    filterItem(item) {
        const { search } = this.state;
        const { choices } = this.props;

        return (
            !search ||
            this.filterForGroup(item) ||
            choices.findIndex((choice) => choice === item.id) !== -1
        );
    }

    sortItems(a, b) {
        const { choices } = this.props;

        const weightA = choices.findIndex((choice) => choice === a.id) !== -1 ? 1 : 0;
        const weightB = choices.findIndex((choice) => choice === b.id) !== -1 ? 1 : 0;

        return weightB - weightA;
    }

    getSortedItems(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortItems);
    }

    handlerDropProps({ prop, value }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState[prop] = value;

                return newState;
            }, resolve);
        });
    }

    handlerDrop({ isShow: isShowRes }) {
        const { callback } = this.props;
        const isShow = checkValueOfEmpty(isShowRes) ? isShowRes : !this.state.isShow;

        if (this.timerDrop) {
            clearTimeout(this.timerDrop);
        }

        if (isShow) {
            this.handlerDropProps({ prop: 'isShow', value: isShow }).then(() => {
                callback({});

                this.timerDrop = setTimeout(() => {
                    this.handlerDropProps({ prop: 'isFresh', value: true });
                }, 300);
            });
        } else {
            this.handlerDropProps({ prop: 'isFresh', value: false }).then(() => {
                setTimeout(() => {
                    callback({});
                    this.handlerDropProps({ prop: 'isShow', value: isShow });
                }, 10);
            });
        }
    }

    getItems() {
        const { items = [] } = this.props;

        return items.filter(this.filterItem);
    }

    renderItem({ item: itemCome, prop: id, key: orderKey }) {
        const { items = [], choices, callback } = this.props;
        const item = items.find((itemLoop) => itemLoop.id === id) || itemCome;
        const isCurrent = choices.findIndex((choice) => choice === item.id) !== -1;
        const icon = isCurrent ? 'delete' : 'add';

        return (
            <div
                className={`filterList__dropItem _row ${item.className || ''} ${
                    isCurrent ? '_current' : ''
                }`}
                style={{
                    zIndex: items.length - orderKey,
                }}
            >
                <div className="filterList__dropItemContent _row">
                    <Avatar
                        className="filterList__dropItemPreview _col"
                        type={item.type}
                        holder={{ ...item }}
                        src={item.iconSrc}
                    />
                    <div
                        className="filterList__dropItemContentInner"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                    ></div>
                </div>
                <div
                    className="filterList__dropItemAction"
                    onClick={() =>
                        callback({ action: 'change', id: item.id }).then(() => {
                            this.parent.current.querySelector('.filterList__drop').scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        })
                    }
                >
                    <div className={`action _col _animate _click _${icon}`}>
                        {this.orderActions.map((action) => (
                            <Animate
                                key={action}
                                className={`action__icon _${action}`}
                                isShow={action === icon}
                            >
                                <Icon name={action} />
                            </Animate>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    getInfoSearch() {
        const { name } = this.props;
        const info = this.choicesInfo[name];
        const { keySearch } = info;

        return this.groupSearch[keySearch];
    }

    getMoreItems(counter) {
        return new Promise((resolve) => {
            this.setState(
                {
                    counterScroll: counter,
                    isDisabledScroll: true,
                },
                () => {
                    removeTransition({ item: '.filterList__dropItem', isCurrent: true });

                    resolve();

                    setTimeout(() => {
                        this.setState({ isDisabledScroll: false });
                    }, 300);
                },
            );
        });
    }

    filterScrollItem(log, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    hideCallback() {
        const { name } = this.props;
        const resultItems = this.getItems();
        const sortedItems = this.getSortedItems(resultItems).filter(
            (item, key) => key < this.stepCounter,
        );

        document.dispatchEvent(
            new CustomEvent('handlerListDynamic', {
                detail: {
                    name,
                    items: sortedItems,
                    callback: () => {
                        this.setState(
                            {
                                counterScroll: this.stepCounter,
                                isHide: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ isHide: false });
                                }, 300);
                            },
                        );
                    },
                },
            }),
        );
    }

    render() {
        const {
            search,
            isShow,
            isFresh,
            heightInner = 0,
            isInit,
            isDisabledScroll,
            isHide,
        } = this.state;
        const { items = [], name = '', choices = [] } = this.props;
        const info = this.getInfoSearch();
        const resultItems = this.getItems();
        const filteredItems = this.getSortedItems(resultItems).filter(this.filterScrollItem);

        return (
            <div
                ref={this.parent}
                className={`filterList _${name} ${isShow ? '_active' : ''} ${
                    items.filter(this.filterItem).length === 0 ? '_empty' : ''
                }`}
            >
                <div className="filterList__view _row _click" onClick={() => this.handlerDrop({})}>
                    {this.renderChoice()}
                </div>
                <div
                    className="filterList__content"
                    style={
                        isShow
                            ? isFresh
                                ? {}
                                : {
                                      height: `${heightInner}px`,
                                  }
                            : { height: 0 }
                    }
                >
                    <div className="filterList__contentInner">
                        <div className="filterList__search">
                            <Search
                                name="filter"
                                value={search}
                                support={info.support}
                                callback={this.handlerSearch}
                                id={name}
                            />
                        </div>
                        <div className="filterList__drop">
                            <ListScroll
                                isInit={isInit}
                                getParent={() =>
                                    this.parent.current?.querySelector('.filterList__drop')
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={filteredItems.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={filteredItems.length === resultItems.length}
                                isDisabledScroll={isDisabledScroll || !isInit || isHide}
                                hideCallback={this.hideCallback}
                                callbackDuration={100}
                            >
                                <ListAbsoluteMain
                                    name={name}
                                    className="filterList__dropItems _col"
                                    items={filteredItems}
                                    renderItem={this.renderItem}
                                    classNameItem="filterList__dropItem"
                                    prop="id"
                                    paramsParent={{ width: true }}
                                    sort={this.getSortedItems}
                                    styles={['height']}
                                    callback={({ type, params, isInit: cbIsInit }) => {
                                        if (type === 'parent') {
                                            this.setState({
                                                heightInner:
                                                    (params.height > 200 ? 200 : params.height) +
                                                    (this.parent.current?.querySelector(
                                                        '.filterList__search',
                                                    ).offsetHeight || 0),
                                            });
                                        }
                                        if (cbIsInit && !this.state.isInit) {
                                            this.setState({ isInit: true });
                                        }
                                    }}
                                    keyRender={choices.length}
                                />
                            </ListScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(List);

List.propTypes = {
    name: PropTypes.string,
    items: PropTypes.array,
    choices: PropTypes.array,
    callback: PropTypes.func,
};
