import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';

import I from '../types.ts';

const renderCheckboxes: I['renderCheckboxes'] = function () {
    const { model, name, value, onChange, checkboxes, checkboxesCol, disabled } = this.props;

    return (
        <>
            <div className="v2widgetField__field _checkboxes">
                <div className={`v2widgetField__checkboxes ${checkboxesCol ? '_COL' : '_ROW'}`}>
                    {(checkboxes?.(model) || []).map((checkbox) => (
                        <label className="v2widgetField__checkbox" key={checkbox.id}>
                            <div className="v2widgetField__checkboxBox">
                                <Checkbox
                                    name={name}
                                    onChange={() => {
                                        onChange({
                                            [name]: value === checkbox.id ? null : checkbox.id,
                                        });
                                    }}
                                    value={value === checkbox.id}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="v2widgetField__checkboxTitle">{checkbox.title}</div>
                            {checkbox.description && (
                                <div
                                    className="v2widgetField__checkboxDescription"
                                    dangerouslySetInnerHTML={{ __html: checkbox.description }}
                                ></div>
                            )}
                        </label>
                    ))}
                </div>
            </div>
        </>
    );
};

export default renderCheckboxes;
