import React from 'react';

import { RenderColsT } from '../../types.ts';

const renderListPassportViews: RenderColsT<'listPassportViews'> = function ({
    name: colName,
    item,
}) {
    let content;
    let className;

    const country = item as {
        _id: string;
        name: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text _right">{country.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListPassportViews;
