import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (force) {
    const { isEdit } = this.state;
    const model = this.state.model!;
    const { groupId } = model;
    const { fields: changedFields } = this.checkChange();

    const pay: Record<any, unknown> = {
        name: model.name,
        userId: model.userId,
        date: model.date,
        amount: model.amount,
        projectId: model.projectId,
        isNotExecutorSign: !!model.isNotExecutorSign,
    };

    if (isEdit) {
        pay._id = model._id;
    }

    if (groupId !== 'main') {
        pay.groupId = groupId;
    }

    if (model.period) {
        const [start, end] = model.period.split('–');
        const startPeriod = start?.trim();
        const endPeriod = end?.trim();

        pay.startPeriod = startPeriod;
        pay.endPeriod = endPeriod;
    }

    if (isEdit && model.projectId && (changedFields.amount || changedFields.projectId) && force !== true) {
        handlerPopup('alertPopup', {
            isShow: true,
            type: 'checkCalcPayAmount',
            callback: save.bind(this, true),
        });

        return;
    }

    await handlerLoading.call(this, 'save');

    const response = await axios.post<
        {},
        { data: ReqResponseT<{ message: string; error?: { name: string; text: string } }> }
    >(`${process.env.REACT_APP_API}/pays`, { pay }, { headers: getHeaders() });

    const { success, data } = response.data;

    if (success) {
        this.close();

        document.dispatchEvent(new CustomEvent('refreshTable', { detail: { name: 'pays' } }));
    } else {
        const { message, error } = data;

        if (error) {
            this.setState({ error });
        }

        if (message === 'Corporation not verify') {
            handlerPopup('verificationPopup', {
                isShow: true,
                callback: this.close.bind(this),
            });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
