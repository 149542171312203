import ListScrollI from '../types.ts';

const init: ListScrollI['init'] = function () {
    const {
        callback,
        startCounter,
        getParent,
        keyUpdate,
        isLimit,
        otherItemsCounter = 0,
    } = this.props;

    const parent = getParent();

    if (parent && !this.isInit) {
        this.isInit = true;
        this.parent = parent;
        this.keyUpdate = keyUpdate;
        this.isLimit = isLimit;

        this.parent.addEventListener('scroll', this.handlerScroll);

        callback(startCounter - otherItemsCounter, true);

        this.lengthCurrent = startCounter;
    }
};

export default init;
