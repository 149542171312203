import removeTransition from '@functions/removeTransition.ts';

import I from '../types.ts';

const setInit: I['setInit'] = function () {
    const { currents } = this.props;

    this.setState({ isReady: true });

    const widget = document.querySelector(`.id${this.id!}`);

    removeTransition({ item: `.id${this.id} .v2widget__headActionsElem`, isCurrent: true });

    if (widget) {
        const content = widget.querySelector('.v2table__content') as HTMLElement;
        const height = content.offsetHeight;

        if (height < 220) {
            this.setState({ tableHeight: height });
        }

        this.setState({ showList: currents ? currents.length > 0 : false });
    }
};

export default setInit;
