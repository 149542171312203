import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../../functions/setSpacesInText';

import Button from '../Button.jsx';
import Icon from '../../../Icon.jsx';

class PublicExecutorsAbout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    cards = [
        {
            icon: 'public-about-1',
            title: 'Легальный доход без&!nbsp;статуса ИП',
            content:
                'Можно оказывать услуги без&!nbsp;регистрации в качестве ИП. Доход подтверждается справкой из приложения.',
        },
        {
            icon: 'public-about-2',
            title: 'Выгодные налоговые&!nbsp;ставки',
            content:
                '4% — с доходов от физлиц.<br/>6% — с доходов от юрлиц и ИП. Других обязательных платежей&!nbsp;нет.',
        },
        {
            icon: 'public-about-3',
            title: 'Не нужно считать налог&!nbsp;к уплате',
            content:
                'Налог начисляется автоматически в приложении. Уплата — не позднее 25 числа следующего месяца.',
        },
        {
            icon: 'public-about-4',
            title: 'Простая регистрация через интернет',
            content: 'Регистрация в мобильном приложении без визита в налоговую инспекцию.',
        },
        {
            icon: 'public-about-5',
            title: 'Чек формируется в приложении',
            content:
                'Не надо покупать ККТ. Чек можно сформировать в мобильном приложении «Мой налог».',
        },
        {
            icon: 'public-about-6',
            title: 'Не нужно платить страховые взносы',
            content:
                'Нет обязанности уплачивать фиксированные взносы на пенсионное и медицинское страхование.',
        },
        {
            icon: 'public-about-7',
            title: 'Бонус для новых пользователей',
            content:
                'Налоговый вычет — 10 000 ₽. Ставка 4% уменьшается до 3%, ставка 6% уменьшается до 4%. Расчёт автоматический.',
        },
        {
            icon: 'public-about-8',
            title: 'Нет отчётов и деклараций',
            content:
                'Декларацию предоставлять не нужно. Учёт доходов ведётся автоматически в мобильном приложении.',
        },
        {
            icon: 'public-about-9',
            title: 'Совмещение с работой по трудовому договору',
            content:
                'Зарплата не учитывается при расчёте налога. Трудовой стаж по месту работы не прерывается.',
        },
    ];

    render() {
        const { device } = this.props;

        return (
            <section className="publicExecutorsAbout">
                <div className="publicExecutorsAbout__inner">
                    <div className="publicExecutorsAbout__content _col">
                        <div className="publicExecutorsAbout__contentBack" />
                        <div className="publicExecutorsAbout__head _col">
                            <h2 className="publicExecutorsAbout__title">Кто такие самозанятые?</h2>
                            <p className="publicExecutorsAbout__description">
                                Самозанятые — это специалисты, которые самостоятельно оказывают
                                услуги физическим и&nbsp;юридическим лицам. Такую активность легко
                                совмещать с&nbsp;основной занятостью
                            </p>
                            <a
                                rel="noreferrer"
                                className="publicExecutorsAbout__button"
                                href="https://npd.nalog.ru/app/"
                                target="_blank"
                            >
                                <Button>Стать самозанятым</Button>
                            </a>
                        </div>
                        <div className="publicExecutorsAbout__title">Почему это выгодно?</div>
                        {device === 'mobile' && (
                            <>
                                <div className="publicExecutorsAbout__description">
                                    Листайте вправо,
                                    <br />
                                    чтобы увидеть больше
                                </div>
                            </>
                        )}
                        <div className="publicExecutorsAbout__cards">
                            <div className="publicExecutorsAbout__cardsInner _row">
                                {this.cards.map((card, key) => (
                                    <div className="publicExecutorsAbout__card" key={key}>
                                        <div className="publicExecutorsAbout__cardInner _col">
                                            <div className="publicExecutorsAbout__cardIcon">
                                                <i className="publicExecutorsAbout__cardIconItem">
                                                    <Icon name={card.icon} />
                                                </i>
                                                <i className="publicExecutorsAbout__cardIconItem _shadow">
                                                    <Icon name={card.icon} />
                                                </i>
                                            </div>
                                            <div
                                                className="publicExecutorsAbout__cardTitle"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(card.title),
                                                }}
                                            />
                                            <p
                                                className="publicExecutorsAbout__cardDescription"
                                                dangerouslySetInnerHTML={{
                                                    __html: setSpacesInText(card.content),
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
    };
}

export default connect(mapStateToProps)(PublicExecutorsAbout);

PublicExecutorsAbout.propTypes = {
    device: PropTypes.string,
};
