import I from '../types.ts';

const mounthHandler: I['mounthHandler'] = async function () {
    const { walletPopup } = this.props;
    const { wallet, organization } = walletPopup;

    this.setState({
        isEdit: !!wallet,
        currentType: wallet?.type || (organization === 'FL' ? 'card' : 'account'),
        organization,
    });

    const fields: Record<any, unknown> = {
        _id: wallet?._id,
        current: false,
    };

    if (wallet) {
        if (wallet.type === 'card') {
            fields.number = wallet.number;
        }

        if (wallet.type === 'account') {
            fields.bic = wallet.bic;
            fields.number = wallet.number;
            fields.addressatName = wallet.addressatName;
        }
    }

    this.init({ fields });
};

export default mounthHandler;
