import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import getEndText from '@functions/getEndText.ts';

import I from '../types.ts';

const renderDocsBlock: I['renderDocsBlock'] = function () {
    const { model, updatedListKey, error, loadingKey, docsHeight } = this.state;
    const docs = model!.allDocs || [];

    return (
        <>
            <div className="v2widgetJoinScript__block _docs _COL">
                <div
                    className="v2widgetJoinScript__blockInner _COL _content"
                    style={{ height: `${docsHeight}px` }}
                >
                    <div className="v2widgetJoinScript__blockHead">
                        <div className="v2widgetJoinScript__blockHeadTitle">Сбор документов</div>
                        <AnimateChange
                            className="v2widgetJoinScript__blockHeadDescription"
                            renderKey={model?.docs.length || 0}
                            styles={['height']}
                        >
                            <>
                                Выбрано: {model?.docs.length || 0}{' '}
                                {getEndText(model?.docs.length || 0, [
                                    'документ',
                                    'документа',
                                    'документов',
                                ])}
                            </>
                        </AnimateChange>
                    </div>
                    <div className="v2widgetJoinScript__blockContent _NOSCROLL">
                        <ListAbsoluteMain
                            className="v2widgetJoinScript__docs _ITEMS"
                            items={docs}
                            renderItem={this.renderDoc.bind(this)}
                            classNameItem="v2widgetJoinScript__docsItem"
                            prop="_id"
                            paramsParent={{ width: true }}
                            keyRender={updatedListKey}
                        />
                    </div>
                </div>
                <div className="v2widgetJoinScript__foot _COL">
                    <AnimateChange
                        className="v2widgetJoinScript__footError"
                        renderKey={error?.text}
                        styles={['height']}
                        paramsParent={{ width: true }}
                        callback={this.setHeightDocs.bind(this)}
                    >
                        <>
                            <div className="v2widgetJoinScript__footErrorText">{error?.text}</div>
                        </>
                    </AnimateChange>
                    <div className="v2widgetJoinScript__footButton">
                        <Button
                            className="_main _whiteDisabled"
                            disabled={!this.checkChange().isChange}
                            onClick={this.save.bind(this)}
                            loading={loadingKey === 'save'}
                        >
                            Сохранить сценарий
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderDocsBlock;
