import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import setSpacesInText from '../../../../functions/setSpacesInText';
import getFormatedDate from '../../../../functions/getFormatedDate';

import Icon from '../../../Icon.jsx';
import Link from '../../../Link.jsx';
import ImageLazy from '../../../ImageLazy.jsx';
import getEndText from '../../../../functions/getEndText';
import Switch from '../../../Switch.jsx';
import Loader from '../../../Loader.jsx';
import Animate from '../../../Animate.jsx';

import getHeaders from '../../../../functions/getHeaders';
import handlerLoading from '../../../../functions/handlerLoading';
import handlerSupportCursor from '../../../../functions/handlerSupportCursor';

class ArticleCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getSrc() {
        const { preview, cardName, isAdmin } = this.props;

        if (isAdmin && cardName === 'googleVision') {
            return require('../../../../img/crm/google-vision-preview.jpg');
        }

        if (!preview.path) {
            return null;
        }

        if (cardName === 'info') {
            return `${process.env.REACT_APP_STATIC}/info-articles/${preview.path}`;
        }

        if (cardName === 'googleVision') {
            return `${process.env.REACT_APP_STATIC}/google-items/${preview.path}`;
        }

        return `${process.env.REACT_APP_STATIC}/articles/${preview.path}`;
    }

    getTitle() {
        const { title, cardName, isAdmin, createdDate } = this.props;

        if (cardName === 'googleVision') {
            if (isAdmin) {
                return 'Добавленные';
            }

            return getFormatedDate({ date: new Date(createdDate), type: 'full' });
        }

        return title;
    }

    getDescription() {
        const { sectionText, words } = this.props;

        if (words) {
            return `${words.length} ${getEndText(words.length, ['объект', 'объекта', 'объектов'])}`;
        }

        return `Раздел «${sectionText}»`;
    }

    getLinkProps() {
        const { _id, cardName } = this.props;

        if (cardName === 'googleVision') {
            const { isAdmin } = this.props;

            if (isAdmin) {
                return {
                    pageName: 'content-googleVision-list',
                };
            }

            return {
                pageName: 'content-googleVision-inner',
                ids: { 3: _id },
            };
        }

        return {
            pageName: `content-${cardName}-inner-main`,
            ids: { 3: _id },
        };
    }

    changePublicate(isPublicate) {
        const { loadingKey } = this.state;
        const { _id, changeItem } = this.props;

        if (!loadingKey) {
            handlerLoading.call(this, 'publicate').then(() => {
                axios
                    .patch(
                        `${process.env.REACT_APP_API}/article`,
                        { id: _id, fields: JSON.stringify({ isPublicate }) },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            handlerLoading.call(this, null);

                            const { success } = res.data;

                            if (success) {
                                changeItem({
                                    values: [{ key: 'isPublicate', value: isPublicate }],
                                });
                            }
                        },
                        () => {
                            handlerLoading.call(this, null);
                        },
                    );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;
        const { cardName, isPublicate, isAdmin, placeText } = this.props;
        const description = this.getDescription();

        return (
            <div
                className={`contentBlogArticleCard ${cardName ? `_${cardName}` : ''} ${
                    !this.getSrc() ? '_emptyImage' : ''
                }`}
            >
                <div className="contentBlogArticleCard__inner">
                    <div className="contentBlogArticleCard__head _col">
                        {cardName === 'googleVision' ? (
                            <>
                                {isAdmin && (
                                    <div className="contentBlogArticleCard__pin _col">
                                        <i className="contentBlogArticleCard__pinIcon">
                                            <Icon name="pin" />
                                        </i>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div
                                    className="contentBlogArticleCard__switch"
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: isPublicate
                                                ? 'Скрыть статью'
                                                : 'Показать статью',
                                            e,
                                            data: { className: '' },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({ action: 'leave', e });
                                    }}
                                >
                                    <Switch
                                        value={!!isPublicate}
                                        className="_green _size3"
                                        handler={() => {
                                            this.changePublicate(!isPublicate);

                                            handlerSupportCursor({ action: 'leave' });
                                        }}
                                    />
                                    <Animate
                                        className="contentBlogArticleCard__switchLoader"
                                        isShow={loadingKey === 'publicate'}
                                    >
                                        <Loader />
                                    </Animate>
                                </div>
                            </>
                        )}
                        {cardName === 'announce' ? (
                            <>
                                <i className="contentBlogArticleCard__image">
                                    <Icon name="article-announce" />
                                </i>
                            </>
                        ) : cardName === 'legal' ? (
                            <>
                                <i className="contentBlogArticleCard__image">
                                    <Icon name="file-type-doc" />
                                </i>
                            </>
                        ) : (
                            <>
                                {this.getSrc() && (
                                    <ImageLazy
                                        src={this.getSrc()}
                                        className="contentBlogArticleCard__image"
                                    />
                                )}
                            </>
                        )}
                    </div>
                    <div className="contentBlogArticleCard__content _col">
                        <div
                            className="contentBlogArticleCard__title"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(this.getTitle()) }}
                        ></div>
                        <div className="contentBlogArticleCard__descriptions">
                            {cardName !== 'legal' && (
                                <div
                                    className="contentBlogArticleCard__description"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(description),
                                    }}
                                ></div>
                            )}
                            {placeText && (
                                <div className="contentBlogArticleCard__description">
                                    {placeText}
                                </div>
                            )}
                        </div>

                        <Link
                            className="contentBlogArticleCard__edit _row"
                            {...this.getLinkProps()}
                        >
                            Редактировать
                            <i className="contentBlogArticleCard__editIcon">
                                <Icon name="arrow-next" />
                            </i>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ArticleCard);

ArticleCard.propTypes = {
    _id: PropTypes.string,
    preview: PropTypes.object,
    icon: PropTypes.object,
    title: PropTypes.string,
    createdDate: PropTypes.string,
    sectionText: PropTypes.string,
    categoryText: PropTypes.string,
    isPublicate: PropTypes.bool,
    cardName: PropTypes.string,
    isAdmin: PropTypes.bool,
    words: PropTypes.array,
    changeItem: PropTypes.func,
    placeText: PropTypes.string,
};
