import getFormatedDate from '@functions/getFormatedDate';

import { FieldT } from '../cards';

const payEdit = {
    fieldsOrder: ['userId', 'name', 'period', 'date', 'amount', 'projectId', 'isNotExecutorSign'],
    fields: {
        userId: {
            support: 'Исполнитель',
            prop: 'userId',
            selectTextKey: 'executorName',
            type: 'select',
            selectList: 'executors',
            selectProps: { fullName: 'executorName' },
            setSelectChange(model?: { contract?: { number: number; date: string } }) {
                if (model?.contract) {
                    return {
                        name: `Платеж по договору №${model.contract.number}`,
                    };
                }

                return {
                    name: '',
                };
            },
        },
        name: {
            support: 'Назначение',
            type: 'input',
        },
        amount: {
            support: 'Сумма акта',
            type: 'input',
            isAmount: true,
            concat: {
                text: ' ₽',
                position: 'end',
                exp: /[^\d.]/gi,
            },
            max: 200_000,
        },
        period: {
            support: 'Период акта',
            type: 'date',
            reg: 'datePeriod',
            isDouble: true,
            withPast: true,
            setDate: ({
                date,
                doubleDate,
            }: Parameters<NonNullable<FieldT['setDate']>>[0]): string => {
                const dates = [date, ...(doubleDate ? [doubleDate] : [date])];

                return dates
                    .map((thisDate) => getFormatedDate({ date: new Date(thisDate) }))
                    .join(' – ');
            },
        },
        date: {
            support: 'Дата акта',
            type: 'date',
            reg: 'date',
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
        isNotExecutorSign: {
            type: 'checkbox',
            checkboxTitle: 'Односторонний акт',
            checkboxDescription: 'Подпись со стороны исполнителя не требуется',
        },
        projectId: {
            support: 'Проект',
            prop: 'projectId',
            selectTextKey: 'projectName',
            type: 'select',
            selectList: 'payProjects',
            selectProps: { name: 'projectName' },
        },
    },
} as const;

export default payEdit;
