import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from '../Field.jsx';
import Button from '../Button.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Search from '../Search.jsx';
import getExecutors from '../../requests/getExecutors';
import Avatar from '../Avatar.jsx';
import ActionChange from '../ActionChange.jsx';
import ListScroll from '../ListScroll.jsx';
import removeTransition from '../../functions/removeTransition.ts';
import Animate from '../Animate.jsx';
import Loader from '../Loader.jsx';
import Icon from '../Icon.jsx';
import handlerWindow from '../../functions/handlerWindow';
import setNotification from '../../functions/setNotification';
import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import Table from '../crm/manual/Table.jsx';

class ChatUserSettings extends Table {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            isDropShow: false,
            isDisabledScroll: true,
        };

        this.renderSearchItem = this.renderSearchItem.bind(this);
        this.renderExecutor = this.renderExecutor.bind(this);
        this.filterScrollItem = this.filterScrollItem.bind(this);
        this.getSortedExecutors = this.getSortedExecutors.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.handlerField = this.handlerField.bind(this);
        this.save = this.save.bind(this);
        this.setMaxListHeight = this.setMaxListHeight.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 15;

    fieldsOrder = ['name', 'phone'];

    handlerField({ action, name, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState((state) => {
                    const newState = { ...state };
                    const fields = { ...newState.fields };

                    if (!fields[name]) {
                        fields[name] = {};
                    }

                    fields[name].value = value;
                    fields[name].error = null;

                    newState.fields = fields;

                    return newState;
                }, resolve);
            }
        });
    }

    handlerStateDrop(isDropShow = !this.state.isDropShow) {
        if (this.dropTimerId) {
            clearTimeout(this.dropTimerId);
        }

        this.setState({ isDropShow, isDisabledScroll: true }, () => {
            if (isDropShow && !this.isInit) {
                this.isInit = true;

                this.getItems(true);
            }

            this.dropTimerId = setTimeout(() => {
                this.setState({ isDisabledScroll: false });
            }, 300);
        });
    }

    getSearchItems() {
        const { isDropShow } = this.state;

        return isDropShow ? [{ key: 'search' }] : [];
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                const savedSearch = this.state.resultSearch;

                this.setState(
                    { search: value, ...(value.length > 2 ? {} : { resultSearch: null }) },
                    () => {
                        if (value.length > 2 || savedSearch) {
                            this.setSearch();
                        }
                    },
                );
            }
        });
    }

    setSearch() {
        this.setState({ resultSearch: this.state.search, isDisabledScroll: true }, () => {
            this.updateItems().then(() => {
                this.setState({ isDisabledScroll: false });
            });
        });
    }

    renderSearchItem() {
        const { search = '', isProcessSearch } = this.state;

        return (
            <div className="chatUserSettings__contentSearch">
                <Search
                    name="filter"
                    className="_grey"
                    value={search}
                    support="Введите ФИО исполнителя"
                    callback={this.handlerSearch}
                    regType="russianText"
                    isShowLoader={isProcessSearch}
                />
            </div>
        );
    }

    getSortedExecutors(executors) {
        const { currentId } = this.state;

        return JSON.parse(JSON.stringify(executors)).sort((a, b) => {
            const weightA = currentId === a._id ? 1 : 0;
            const weightB = currentId === b._id ? 1 : 0;

            return weightB - weightA;
        });
    }

    filterScrollItem(item, key) {
        const { counterScroll, currentId } = this.state;

        return key < counterScroll || currentId === item._id || item.type === 'info';
    }

    getExecutorsList() {
        const { items: executors = [], isInit } = this.state;

        if (isInit) {
            if (executors.length === 0) {
                return [{ _id: 'empty', type: 'info' }];
            }

            if (executors.length === 0 && 0) {
                return [{ _id: 'emptyFilter', type: 'info' }];
            }
        }

        return executors;
    }

    renderExecutor({ item: executor, prop: id }) {
        if (executor.type === 'info') {
            return (
                <div className="chatUserSettings__contentListItem">
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            {id === 'empty' ? (
                                <>
                                    <div className="empty__title">Исполнителей нет</div>
                                    <p className="empty__content">
                                        Чтобы прикрепить чат
                                        <br />
                                        создайте исполнителя
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div className="empty__title">
                                        По вашему фильтру
                                        <br />
                                        ничего не найдено
                                    </div>
                                    <p className="empty__content">
                                        Измените поиск
                                        <br />и попробуйте снова
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        const { currentId } = this.state;
        const { firstName, secondName, thirdName, phone } = executor;
        const isCurrent = currentId === id;

        return (
            <div className="chatUserSettings__contentListItem">
                <div className={`chatUserSettings__contentExecutor ${isCurrent ? '_current' : ''}`}>
                    <div className="chatUserSettings__contentExecutorInner _row">
                        <div className="chatUserSettings__contentExecutorAvatar">
                            <div className="chatUserSettings__contentExecutorCurrent _col">
                                <i className="chatUserSettings__contentExecutorCurrentIcon">
                                    <Icon name="done-3" />
                                </i>
                            </div>
                            <Avatar holder={{ secondName: secondName || phone }} />
                        </div>
                        <div className="chatUserSettings__contentExecutorName">
                            {firstName || secondName ? (
                                <>
                                    {' '}
                                    <b>{secondName}</b> {firstName} {thirdName}
                                </>
                            ) : (
                                <>
                                    <b>{phone}</b>{' '}
                                </>
                            )}
                        </div>
                        <ActionChange
                            className="chatUserSettings__contentExecutorAction"
                            isShow={true}
                            isSmoothShow={true}
                            isHas={isCurrent}
                            onClick={() => {
                                this.setState(
                                    {
                                        currentId: isCurrent ? null : id,
                                    },
                                    () => {
                                        this.parent.current
                                            .querySelector('.chatUserSettings__contentListWrapper')
                                            .scrollTo({
                                                top: 0,
                                                behavior: 'smooth',
                                            });
                                    },
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    save() {
        const { saveCurrentId, currentId, fields } = this.state;
        const { setSettings, anyInfo } = this.props;
        let isChange = false;
        const resultFields = {};

        if ((currentId || currentId === null) && currentId !== anyInfo?.userId) {
            isChange = true;

            resultFields.userId = currentId;
        }

        ['name', 'phone'].forEach((key) => {
            if (
                (!saveCurrentId || key === 'phone') &&
                checkValueOfEmpty(fields[key]?.value, true) &&
                fields[key]?.value !== anyInfo?.[key]
            ) {
                isChange = true;

                resultFields[key] = fields[key]?.value;
            }
        });

        if (isChange) {
            this.handlerLoading('save').then(() => {
                setSettings({ ...resultFields }).then(
                    () => {
                        setNotification({ notification: 'success-change-info' });

                        handlerWindow({
                            action: 'hide',
                        });
                    },
                    () => null,
                );
            });
        } else {
            handlerWindow({
                action: 'hide',
            });
        }
    }

    setMaxListHeight() {
        const parent = this.parent.current;
        const top = parent.getBoundingClientRect().y;
        let maxHeight = document.documentElement.clientHeight - top - 42;

        const title = parent.querySelector('.chatUserSettings__title');

        maxHeight -= title.offsetHeight;

        const fields = parent.querySelector('.chatUserSettings__fields');

        maxHeight -= fields.offsetHeight;

        const foot = parent.querySelector('.chatUserSettings__foot');

        maxHeight -= foot.offsetHeight;

        maxHeight -= 90; // Is height of elem .chatUserSettings__contentHead

        if (maxHeight !== this.state.maxListHeight) {
            if (this.state.maxListHeight) {
                removeTransition({ item: '.chatUserSettings' });
            }

            this.setState({ maxListHeight: maxHeight });
        }
    }

    getQueryForRequest() {
        const { resultSearch, currentId } = this.state;
        const query = super.getQueryForRequest();

        query.params.push({ key: 'inList', value: 'true' });

        if (resultSearch) {
            query.params.push({ key: 'searchForName', value: resultSearch });
        }

        if (currentId) {
            query.params.push({ key: 'currentId', value: currentId });
        }

        return query;
    }

    getItems() {
        const query = this.getQueryForRequest();

        query.params.push({ key: 'type', value: 'chatSettings' });

        return new Promise((resolve) => {
            getExecutors(query).then(({ items, isLimit, counter }) => {
                this.setItems(items, false, isLimit, counter).then(() => {
                    this.setState({ isShowLoaderList: false });

                    removeTransition({
                        item: '.chatUserSettings__contentListItem',
                        isCurrent: true,
                    });

                    resolve();
                });
            });
        });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    componentDidMount() {
        const { anyInfo } = this.props;

        if (anyInfo) {
            this.setState(
                {
                    saveCurrentId: anyInfo.userId,
                    currentId: anyInfo.userId,
                    fields: {
                        name: {
                            value: anyInfo.name || '',
                        },
                        phone: {
                            value: anyInfo.phone || '',
                        },
                    },
                    ...(anyInfo.userId ? { isDropShow: true } : {}),
                },
                () => {
                    if (anyInfo.userId) {
                        this.getItems();
                    }
                },
            );
        }

        this.setMaxListHeight();

        document.addEventListener('changeHeightWindow', this.setMaxListHeight);
    }

    componentWillUnmount() {
        document.removeEventListener('changeHeightWindow', this.setMaxListHeight);
    }

    render() {
        const {
            fields,
            isDropShow,
            listHeight,
            isReady,
            isDisabledScroll,
            isHide,
            saveCurrentId,
            currentId,
            maxListHeight,
            isLimit,
            isLoadingFilter = false,
            isShowLoaderList = false,
        } = this.state;
        let resultListHeight = listHeight > maxListHeight ? maxListHeight : listHeight;

        if (!isReady || isLoadingFilter) {
            resultListHeight = 100;
        }

        if (!isDropShow) {
            resultListHeight = 0;
        }

        const executors = this.getExecutorsList();
        const filteredExecutors = this.getSortedExecutors(executors);

        return (
            <div ref={this.parent} className="chatUserSettings _windowWithCursor _topCenter">
                <div className="chatUserSettings__inner">
                    <div className="chatUserSettings__title">Редактировать данные</div>
                    <div className="chatUserSettings__fields">
                        {this.fieldsOrder.map((name) => {
                            const field = fields[name];

                            return (
                                <div className="chatUserSettings__field" key={name}>
                                    <Field
                                        type="chatUserSettings"
                                        name={name}
                                        keyName={name}
                                        reg=""
                                        className="_grey _medium2Padding _mediumRadius"
                                        value={field?.value}
                                        error={field?.error}
                                        callback={this.handlerField}
                                        isDisabled={!!saveCurrentId && name !== 'phone'}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={`chatUserSettings__content ${isDropShow ? '_dropShow' : ''}`}>
                        <div className="chatUserSettings__contentHead">
                            <div
                                className="chatUserSettings__contentHeadInner _click"
                                onClick={() => this.handlerStateDrop()}
                            >
                                Выбрать из списка:
                            </div>
                            <ListAbsoluteMain
                                className="chatUserSettings__contentSearchWrapper"
                                items={this.getSearchItems()}
                                renderItem={this.renderSearchItem}
                                classNameItem="chatUserSettings__contentSearch"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['height']}
                            />
                        </div>
                        <div className="chatUserSettings__contentListBox">
                            <Animate
                                className="chatUserSettings__contentListLoader _loaderScroll _white"
                                isShow={isShowLoaderList}
                            >
                                <div className="chatUserSettings__contentListLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <div
                                className="chatUserSettings__contentListWrapper"
                                style={{ height: `${resultListHeight}px` }}
                            >
                                <Animate
                                    className="chatUserSettings__contentListLoader _loader"
                                    isShow={!isReady || isLoadingFilter}
                                >
                                    <div className="chatUserSettings__contentListLoaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>

                                <ListScroll
                                    isInit={isReady}
                                    getParent={() =>
                                        this.parent.current?.querySelector(
                                            '.chatUserSettings__contentListWrapper',
                                        )
                                    }
                                    callback={this.getMoreItems}
                                    startCounter={this.stepCounter}
                                    stepCounter={this.stepCounter}
                                    maxCounter={Infinity}
                                    lengthCurrent={filteredExecutors.length}
                                    handlerLoaderList={this.handlerLoaderList}
                                    isLimit={isLimit}
                                    isDisabledScroll={
                                        isDisabledScroll || !isReady || isHide || isLoadingFilter
                                    }
                                >
                                    <ListAbsoluteMain
                                        name="chatUserSettings"
                                        className={`chatUserSettings__contentList _col ${
                                            !isReady || isLoadingFilter ? '' : '_show'
                                        }`}
                                        items={filteredExecutors}
                                        renderItem={this.renderExecutor}
                                        classNameItem="chatUserSettings__contentListItem"
                                        prop="_id"
                                        paramsParent={{ width: true }}
                                        styles={['height']}
                                        callback={({ type: cbType, params }) => {
                                            if (cbType === 'parent') {
                                                this.setState({ listHeight: params.height });
                                            }
                                        }}
                                        sort={this.getSortedExecutors}
                                        keyRender={currentId}
                                    />
                                </ListScroll>
                            </div>
                        </div>
                    </div>
                    <div className="chatUserSettings__foot _col">
                        <div className="chatUserSettings__footButton">
                            <Button className="_mainNotBorder _mediumSize" onClick={this.save}>
                                Сохранить данные
                            </Button>
                        </div>
                        <div className="chatUserSettings__footButton _cancel">
                            <Button
                                className="_alert _mediumSize"
                                onClick={() => {
                                    handlerWindow({
                                        action: 'hide',
                                    });
                                }}
                            >
                                Отменить
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ChatUserSettings);

ChatUserSettings.propTypes = {
    setSettings: PropTypes.func,
    anyInfo: PropTypes.object,
};
