import AnimateChangeI from '../types.ts';

const getItems: AnimateChangeI['getItems'] = function () {
    const { renderKey } = this.props;

    if (
        typeof renderKey === 'string' ||
        typeof renderKey === 'number' ||
        typeof renderKey === 'boolean'
    ) {
        this.childrens[encodeURIComponent(renderKey)] = this.props.children;
    }

    return typeof renderKey === 'string' ||
        typeof renderKey === 'number' ||
        typeof renderKey === 'boolean'
        ? [{ key: encodeURIComponent(renderKey as string | number | boolean) }]
        : [];
};

export default getItems;
