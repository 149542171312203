import AlertI from '../../types';

const inviteUser = {
    title: 'Пользователь уже<br/>есть в системе',
    description(this: AlertI) {
        const info = this.state.info as { name: string; email: string };

        return `Хотите пригласить к себе в компанию пользователя <b>${info.name}</b>, ${info.email}`;
    },
    buttonText: 'Пригласить',
} as const;

export default inviteUser;
