import { ListenerT } from '@global/types.ts';

import MobPopupI from '../types.ts';

const handlerEvents: MobPopupI['handlerEvents'] = function (isAdd) {
    const inner = this.getInner();

    if (inner) {
        if (isAdd) {
            if (
                !this.checkHideDisabled ||
                (typeof this.checkHideDisabled === 'function' && !this.checkHideDisabled())
            ) {
                (inner.addEventListener as ListenerT<TouchEvent>)('touchstart', this.handlerStart, {
                    passive: false,
                });
                (document.addEventListener as ListenerT<TouchEvent>)(
                    'touchmove',
                    this.handlerMove,
                    { passive: false },
                );
                (document.addEventListener as ListenerT<TouchEvent>)('touchend', this.handlerEnd);
            }
        } else {
            (inner.removeEventListener as ListenerT<TouchEvent>)('touchstart', this.handlerStart);
            (document.removeEventListener as ListenerT<TouchEvent>)('touchmove', this.handlerMove);
            (document.removeEventListener as ListenerT<TouchEvent>)('touchend', this.handlerEnd);
        }
    }
};

export default handlerEvents;
