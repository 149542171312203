import React from 'react';
import PropTypes from 'prop-types';

import getFormatedDate from '../../functions/getFormatedDate';

import getUserInfo from '../../functions/getUserInfo';
import getFormatPrice from '../../functions/getFormatPrice';

const QRCode = require('qrcode');

class FnsReceipt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    componentDidMount() {
        const { pdf, init } = this.props;
        const { data } = pdf;
        const { pay, executor } = data;
        const domen =
            process.env.REACT_APP_ENV === 'prod'
                ? 'https://lknpd.nalog.ru'
                : 'https://lknpd-adp.gnivc.ru';

        const canvas = this.parent.current.querySelector('canvas');
        const link = `${domen}/api/v1/receipt/${executor.inn}/${pay.fnsReceiptId}/print`;

        QRCode.toCanvas(canvas, link, () => {
            init();
        });
    }

    render() {
        const { pdf } = this.props;
        const { data } = pdf;
        const { pay, executor } = data;

        return (
            <div ref={this.parent} className="pdf__inner _col">
                <div className="pdf__innerBox">
                    <div className="pdfFnsReceipt">
                        <div className="pdfFnsReceipt__head">
                            <div className="pdfFnsReceipt__title">Чек №{pay.fnsReceiptId}</div>
                            <div className="pdfFnsReceipt__date">
                                {getFormatedDate({
                                    date: new Date(pay.date),
                                    type: 'full',
                                    withTimezone: true,
                                })}
                            </div>
                        </div>
                        <div className="pdfFnsReceipt__executor">
                            <div className="pdfFnsReceipt__executorName">
                                {getUserInfo({ type: 'name', user: executor })}
                            </div>
                        </div>
                        <div className="pdfFnsReceipt__info _row">
                            <div className="pdfFnsReceipt__infoBlock _col">
                                <div className="pdfFnsReceipt__infoText _head">Наименование</div>
                                <div className="pdfFnsReceipt__infoText">1. {pay.name}</div>
                            </div>
                            <div className="pdfFnsReceipt__infoBlock _col">
                                <div className="pdfFnsReceipt__infoText _head">Сумма</div>
                                <div className="pdfFnsReceipt__infoText">
                                    {getFormatPrice(pay.amount)} ₽
                                </div>
                            </div>
                        </div>
                        <div className="pdfFnsReceipt__total _row">
                            <div className="pdfFnsReceipt__totalText">Итого:</div>
                            <div className="pdfFnsReceipt__totalText">
                                {getFormatPrice(pay.amount)} ₽
                            </div>
                        </div>
                        <div className="pdfFnsReceipt__info _row">
                            <div className="pdfFnsReceipt__infoBlock _col">
                                <div className="pdfFnsReceipt__infoText">
                                    Режим НО
                                    <br />
                                    ИНН
                                </div>
                            </div>
                            <div className="pdfFnsReceipt__infoBlock _col">
                                <div className="pdfFnsReceipt__infoText">
                                    НПД
                                    <br />
                                    {executor.inn}
                                </div>
                            </div>
                        </div>
                        <div className="pdfFnsReceipt__info _row">
                            <div className="pdfFnsReceipt__infoBlock _col">
                                <div className="pdfFnsReceipt__infoText _head">Покупатель</div>
                                <div className="pdfFnsReceipt__infoText">
                                    ИНН: {pay.customerInn}
                                </div>
                            </div>
                        </div>
                        <div className="pdfFnsReceipt__qr _col">
                            <canvas />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FnsReceipt;

FnsReceipt.propTypes = {
    pdf: PropTypes.object,
    init: PropTypes.func,
    getPages: PropTypes.func,
    renderFoot: PropTypes.func,
    renderPagination: PropTypes.func,
};
