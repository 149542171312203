import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification';

import InvitesI from '../types.ts';

const deleteJoin: InvitesI['deleteJoin'] = async function ({ join }) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API}/join?id=${join._id}`, {
            headers: getHeaders(),
        });

        const { success } = response.data;

        if (success) {
            setNotification({ notification: 'success-change-info' });

            return;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
};

export default deleteJoin;
