import { CorporationT } from '@global/models/Corporation';

import IntegrationsI from '../../../../../views/crm/settings/components/integrations/types';

const corporationIntegration1C = {
    title: '1C',
    description: (model: CorporationT['integration']) =>
        model?.['1CToken'] ? 'Подключен' : 'Не подключен',
    fieldsOrder: ['token'],
    widthPercent: true,
    fields: {
        token: {
            support: 'Токен',
            prop: '1CToken',
            type: 'input',
            group: 1,
            width: 50,
            readOnly: true,
            action: (model: any, data: Record<any, unknown>) => {
                if (!data?.generate1CToken) {
                    return;
                }

                const generate1CToken = data.generate1CToken as IntegrationsI['generate1CToken'];

                return {
                    icon: 'actions-repeat',
                    onClick: generate1CToken,
                    support: 'Сгенерировать токен',
                };
            },
        },
    },
} as const;

export default corporationIntegration1C;
