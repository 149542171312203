const staticList = {
    colsOrder: ['name'],
    cols: {
        name: {
            content: 'Название',
            width: 100,
        },
    },
    deletesCols: [],
} as const;

export default staticList;
