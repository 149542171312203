import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Widget from '@components/crm/manual/widget/Widget.tsx';

import Image from '../components/image/Image.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { title, files = [], currentId } = this.state;
    const { pin } = this.props;
    const currentFileIndex = files.findIndex((file) => file._id === currentId);
    const currentFile = files[currentFileIndex];
    const currentSrc = currentFile ? `${currentFile.fullSrc}?${currentFile.key || ''}` : undefined;

    return (
        <>
            <Widget
                name="popupChatTemplates"
                title={title}
                descriptionInner={`${currentFileIndex + 1}/${files.length}`}
                actions={this.getActions!()}
                className="_white _notBorder"
                withBack={false}
            >
                <div className="v2popupGalery">
                    <div className="v2popupGalery__inner">
                        <AnimateChange
                            className="v2popupGalery__image _default"
                            renderKey={currentSrc}
                            styles={[]}
                            withoutParams={true}
                        >
                            {currentFile && (
                                <Image
                                    src={currentSrc!}
                                    name={currentFile.name}
                                    scale={pin ? 2.5 : 2}
                                />
                            )}
                        </AnimateChange>
                    </div>
                </div>
            </Widget>
            {this.renderFoot()}
        </>
    );
};

export default renderContent;
