import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import { IconT } from '@components/icon/types.ts';
import Switch from '@components/switch/Switch.tsx';
import RoleT from '@global/models/Role.ts';

import RolesInnerI from '../../../../../views/crm/settings/components/roles/components/inner/types.ts';

import { RenderColsT } from '../../types.ts';

const sectionsIcons: Record<string, { icon: IconT }> = {
    chat: {
        icon: 'role-chat',
    },
    joins: {
        icon: 'role-joins',
    },
    pays: {
        icon: 'role-pays',
    },
    orders: {
        icon: 'role-orders',
    },
    reports: {
        icon: 'role-reports',
    },
    manual: {
        icon: 'role-manual',
    },
    content: {
        icon: 'role-content',
    },
    settings: {
        icon: 'role-settings',
    },
    corporations: {
        icon: 'role-corporations',
    },
};

const renderCorporationRoleSections: RenderColsT<'corporationRoleSections'> = function ({
    name: colName,
    item,
}) {
    const { loadings } = this.state;
    const { otherData } = this.props;
    const section = item as RoleT['sections'][number];
    const sectionsIcon = sectionsIcons[section.key]?.icon;
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__role">
                    <div className="v2table__roleIcon">
                        <Icon name={sectionsIcon} />
                    </div>
                    <div className="v2table__roleContent">
                        <div className="v2table__text _block _bigSize _bottom">{section.name}</div>
                        <div className="v2table__text _block _grey">
                            Кликните, чтобы настроить раздел
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (colName === 'actions') {
        className = '_full';

        const setActive = otherData?.setActive as (
            data: Parameters<RolesInnerI['setActive']>[1],
        ) => Promise<void>;

        content = (
            <>
                <div className="v2table__roleSwitch _COL">
                    <div
                        className="v2table__roleSwitchBox"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Switch
                            className="_mediumSize _green"
                            value={section.isActive}
                            name="isActive"
                            disabled={!!loadings[section._id]}
                            onChange={async () => {
                                await this.loadingHandler({ name: section._id, loading: true });

                                try {
                                    await setActive({
                                        section,
                                        isActive: !section.isActive,
                                    });
                                } catch (error) {}

                                await this.loadingHandler({
                                    name: section._id,
                                    loading: undefined,
                                });
                            }}
                        />
                    </div>
                    <AnimateChange
                        className="v2table__text _block _grey _dynamic"
                        renderKey={section.isActive}
                    >
                        {section.isActive ? 'Отключить раздел' : 'Включить раздел'}
                    </AnimateChange>
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderCorporationRoleSections;
