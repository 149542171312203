export default function getExecutorPassportInfo({ passport }) {
    let passportInfo = '';

    if (passport) {
        if (passport.series) {
            passportInfo += `Серия ${passport.series}`;
        }

        if (passport.number) {
            passportInfo += ' ';
            passportInfo += `№ ${passport.number}`;
        }

        if (passport.dateGet) {
            passportInfo += ' ';
            passportInfo += `Выдан ${passport?.dateGet} ${passport?.nameGet || ''}`;
        }

        if (passport.code) {
            passportInfo += ' ';
            passportInfo += `КОД ${passport?.code}`;
        }
    }

    return passportInfo;
}
