import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import { PageNamesT } from '@redux/pages.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function (this: I) {
    const { user, model } = this.state;

    let actions: WidgetActionsT[] = [
        {
            type: 'link',
            name: 'main',
            group: 'links',
            pageName: 'manual-executors-inner-main',
        },
        {
            type: 'link',
            name: 'docs',
            group: 'links',
            pageName: 'manual-executors-inner-docs',
        },
        {
            type: 'link',
            name: 'wallets',
            group: 'links',
            pageName: 'manual-executors-inner-wallets',
        },
        {
            type: 'link',
            name: 'delete',
            group: 'delete',
            className: '_alertEmpty',
            content: 'Удалить',
            onClick: this.deleteModel.bind(this, false),
        },
    ];

    const otherPages: { name: string; pageName: PageNamesT['crm']; forAdmin?: boolean }[] = [
        { name: 'pays', pageName: 'manual-executors-inner-pays' },
        { name: 'balance', pageName: 'manual-executors-inner-balance' },
        { name: 'contracts', pageName: 'manual-executors-inner-contracts' },
        { name: 'logs', pageName: 'manual-executors-inner-logs' },
    ];

    if (otherPages.length) {
        actions.splice(actions.length - 1, 0, {
            type: 'link',
            name: 'more',
            content: 'Ещё',
            group: 'links',
            className: '_white',
            list: otherPages,
        });
    }

    if (model && user?.idOfCurrentCorporation !== 'admin') {
        const corporation = model.corporations.find(
            (item) => item.id === user?.idOfCurrentCorporation,
        );

        if (corporation?.status === 'invite') {
            actions = [
                {
                    type: 'link',
                    name: 'main',
                    group: 'links',
                    pageName: 'manual-executors-inner-main',
                },
                {
                    type: 'link',
                    name: 'delete',
                    group: 'delete',
                    className: '_alertEmpty',
                    content: 'Удалить',
                    onClick: this.deleteModel.bind(this, false),
                },
            ];
        }
    }

    return actions;
};

export default getActions;
