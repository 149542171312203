import { ModelsT } from '../models';

const joinDocs: ModelsT = {
    url: 'join-doc',
    logName: 'joinDoc',
    title: 'Подключение',
    parentPage: 'joins-docs',
    tablePage: 'joins-docs-main',
    tableName: 'joinsDocs',
    modelPage: 'joins-docs-inner',
    deleteAlertPopup: 'deleteJoinDoc',
    deleteDeniedAlertPopup: 'deleteJoinDocDenied',
    tableLink: {
        pageName: 'joins-docs-inner',
        level: 3,
    },
    tableWrap: true,
    descriptions: {
        table: 'Документы',
        model: {
            new: 'Новый документ',
            default: 'Документ',
        },
    },
    addButton: 'Добавить документ',
    cardName: 'joinDocMain',
};

export default joinDocs;
