import removeTransition from '@functions/removeTransition.ts';

import Interface from '../types.ts';

const changePage: Interface['changePage'] = function (e) {
    const { detail } = e;
    const { changeIsHard } = detail;
    const { classNamePage } = this.props;

    if (changeIsHard && classNamePage) {
        removeTransition({ item: `.${classNamePage}`, isCurrent: true });
    }
};

export default changePage;
