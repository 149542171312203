import I from '../types.ts';

const getPinBtn: I['getPinBtn'] = function () {
    if (!this.props.pin) {
        return null;
    }

    return this.getPinArea().querySelector('.v2widget__head') as HTMLElement;
};

export default getPinBtn;
