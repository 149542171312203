import download from '@functions/download';
import setPdf from '@requests/setPdf.js';

import MainI from '../types.ts';

const downloadAct: MainI['downloadAct'] = async function ({ pay }) {
    try {
        const { fileName } = await setPdf({
            id: `payAct-${new Date().getTime()}`,
            key: 'payAct',
            name: `Договор-счёт-акт.pdf`,
            otherData: {
                payId: pay._id,
                isNonResident: true,
            },
            inMoment: true,
        });

        if (fileName) {
            download({
                name: 'Договор-счёт-акт',
                files: [
                    {
                        src: `corporations-docs/${fileName}`,
                        isDelete: true,
                    },
                ],
            });
        }
    } catch (error) {
        return Promise.reject();
    }
};

export default downloadAct;
