import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import save from './methods/save.ts';

import CorporationRoleSectionI from './types.ts';

import renderAllCheckbox from './renders/renderAllCheckbox.tsx';
import renderContent from './renders/renderContent.tsx';

class CorporationRoleSection
    extends EditBlock<CorporationRoleSectionI['props'], CorporationRoleSectionI['state']>
    implements CorporationRoleSectionI
{
    constructor(props: CorporationRoleSectionI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;
    renderAllCheckbox = renderAllCheckbox;

    close = close;
    save = save;

    componentDidMount(): void {
        const { roleSectionPopup } = this.props;
        const { role, sectionKey } = roleSectionPopup;

        this.init({ fields: role });

        this.setState({ sectionKey });
    }

    render() {
        const { model } = this.state;

        return (
            <Popup
                name="roleSectionPopup"
                close={this.close.bind(this)}
                checkClose={() => !document.querySelector('.body__v2popup._alert')}
            >
                <>{model && this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        roleSectionPopup: state.roleSectionPopup,
    };
}

export default connect(mapStateToProps)(CorporationRoleSection);
