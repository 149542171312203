const chatMailings = {
    colsOrder: ['date', 'tags', 'content', 'files', 'actions'],
    cols: {
        date: {
            content: 'Дата',
            width: 13,
        },
        tags: {
            content: 'Теги',
            width: 15,
        },
        content: {
            content: 'Текст рассылки',
            width: 33,
        },
        files: {
            content: 'Вложения',
            width: 11,
        },
        actions: {
            content: 'Действия',
            width: 28,
        },
    },
    deletesCols: [],
    url: 'mailing',
    emptyInfo: {
        title: 'Рассылок пока нет',
        description: 'Вы можете создать рассылку по кнопке справа',
    },
} as const;

export default chatMailings;
