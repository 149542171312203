const roleEdit = {
    fieldsOrder: ['name'],
    fields: {
        name: {
            support: 'Название',
            prop: 'name',
            type: 'input',
        },
    },
} as const;

export default roleEdit;
