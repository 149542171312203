import React from 'react';

import AnimateChangeUp from '@components/AnimateChangeUp.jsx';
import Button from '@components/Button.jsx';

import BusinessAdvantagesI from '../types.ts';

import { fieldsOrder } from '../static/fields.ts';

const renderForm: BusinessAdvantagesI['renderForm'] = function () {
    const { loadingKey, error, success } = this.state;

    return (
        <div className="publicBusinessAdvantages__form _COL">
            <div className="publicBusinessAdvantages__formContent _COL">
                <div className="publicBusinessAdvantages__formTitle _TITLE _PUBLIC _WHITE _MEDIUMSIZE">
                    Форма обратной связи
                </div>
                <div className="publicBusinessAdvantages__formFields _ROW">
                    {fieldsOrder.map((name) => this.renderFormField(name))}
                    <div className="publicBusinessAdvantages__formField _button">
                        <div className="publicBusinessAdvantages__formFieldButton">
                            <Button
                                onClick={this.sendForm.bind(this)}
                                showLoader={loadingKey === 'send'}
                            >
                                Отправить
                            </Button>
                        </div>
                        <div className="publicBusinessAdvantages__formFieldInfo">
                            Нажимая кнопку «Отправить», вы&nbsp;даёте{' '}
                            <a
                                href={`${process.env.REACT_APP_CRM_DOMEN}/legal`}
                                target="_blank"
                                className="_CLICK"
                                rel="noreferrer"
                            >
                                согласие на обработку персональных данных
                            </a>
                        </div>
                    </div>
                </div>
                <AnimateChangeUp
                    renderKey={success ? true : error ? error.text : undefined}
                    className="publicBusinessAdvantages__formAlert _center"
                >
                    <div
                        className={`publicBusinessAdvantages__formAlertItem ${success ? '_success' : ''}`}
                    >
                        {success ? 'Заявка успешно отправлена' : error?.text}
                    </div>
                </AnimateChangeUp>
            </div>
        </div>
    );
};

export default renderForm;
