import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import JoinInvitesI from '../types.ts';

const renderHead: JoinInvitesI['renderHead'] = function (this: JoinInvitesI) {
    const { tabs = {}, tabsOrder = [], currentTab } = this.state;

    return (
        <div className={`v2widget__tabs ${this.state.tabs ? '_show' : ''}`}>
            <Animate className="v2widget__tabsFake _LOADERWAIT" isShow={!this.state.tabs} />
            <div className="v2widget__tabsInner _NOSCROLL">
                {tabsOrder.map((name) => {
                    const tab = tabs[name];
                    const { content } = tab;

                    return (
                        <div
                            className={`v2widget__tab _click ${currentTab === name ? '_current' : ''}`}
                            key={name}
                            onClick={() => {
                                this.setState({ currentTab: name });
                            }}
                        >
                            <div className="v2widget__tabContent">{content}&nbsp;</div>
                            <AnimateChange
                                className="v2widget__tabCounter"
                                renderKey={tab.counter}
                                isNotNullParentSize={true}
                            >
                                <>({tab.counter})</>
                            </AnimateChange>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default renderHead;
