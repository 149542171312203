import React from 'react';

import WidgetVerificationCard from '@components/crm/widgetVerificationCard/WidgetVerificationCard.tsx';
import getPermissions from '@functions/crm/getPermissions.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const renderVerification: I['renderVerification'] = function () {
    const { user } = this.state;
    const model = this.state.model!;
    const status = model.verification.status;

    const title = status === 'active' ? 'Верификация пройдена' : 'Верификация не пройдена';
    let description = '';
    const links = [];

    if (status === 'active') {
        description = getFormatedDate({
            date: new Date(model.verification.date!),
            type: 'full',
        });
    } else if (status === 'wait') {
        description = 'Компания на проверке';
    } else {
        description = 'На доработке';
    }

    if (
        user?.idOfCurrentCorporation === 'admin' &&
        getPermissions(user, {
            adminCorporation: true,
            key: 'settings',
            items: [{ key: 'docs', rules: [], actions: ['verification'] }],
        })
    ) {
        if (status === 'reject' || status === 'wait') {
            links.push({
                id: 'active',
                content: 'Верифицировать',
                onClick: this.save.bind(this, {
                    verification: { status: 'active' },
                }),
            });
        }

        if (status === 'active' || status === 'wait') {
            links.push({
                id: 'reject',
                className: '_alert',
                content: 'На доработку',
                onClick: async () => {
                    handlerPopup('alertPopup', {
                        type: 'rejectVerification',
                        isShow: true,
                        callback: async ({ message }: { message: string }) => {
                            this.save({ verification: { status: 'reject', comment: message } });
                        },
                    });
                },
            });
        }
    }

    return (
        <>
            <WidgetVerificationCard
                title={title}
                description={description}
                status={model.verification.status}
                links={links}
                rejectText={
                    user?.idOfCurrentCorporation !== 'admin' &&
                    model.verification.status !== 'active'
                        ? model.verification.comment || undefined
                        : undefined
                }
            />
        </>
    );
};

export default renderVerification;
