import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = function () {
    handlerPopup('executorMvdPopup', {
        isShow: false,
        id: undefined,
    });
};

export default close;
