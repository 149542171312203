import TableI from '../types.ts';

const rowBlockSizeHandler: TableI['rowBlockSizeHandler'] = function () {
    const { items = [], activeBlockId } = this.state;
    const { isBlock, isWrap } = this.props;

    if (isBlock) {
        items.forEach((item) => {
            const row = this.parent.current?.querySelector(
                `.v2table__row[data-id="${item._id}"]`,
            ) as HTMLElement;

            if (row) {
                const mainBlock = row.querySelector('.v2table__rowBlock._main') as HTMLElement;

                if (mainBlock) {
                    let height = mainBlock.offsetHeight;

                    if (activeBlockId === item._id) {
                        height = 0;

                        row.querySelectorAll('.v2table__rowBlock').forEach((block) => {
                            height += (block as HTMLElement).offsetHeight;
                        });
                    }

                    if (isWrap) {
                        height += 6;
                    }

                    row.style.height = `${height}px`;
                }
            }
        });
    }
};

export default rowBlockSizeHandler;
