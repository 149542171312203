import axios from 'axios';

import downloadFile from '@functions/downloadFile.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';

import I from '../types.ts';

const download: I['download'] = async function () {
    const { model } = this.state;
    const { corporationAmountDocPopup } = this.props;
    const { id } = corporationAmountDocPopup;

    await handlerLoading.call(this, 'save');

    const amount = model!.amount;

    let response;

    try {
        response = await axios.patch<{}, { data: string | { text: () => Promise<string> } }>(
            `${process.env.REACT_APP_API}/corporation`,
            {
                id,
                amount: +amount,
                action: 'download-amount-doc',
            },
            {
                responseType: 'blob',
                headers: getHeaders(),
            },
        );
    } catch (error) {
        return Promise.reject();
    }

    try {
        const result = await (response.data as { text: () => Promise<string> }).text();

        const { success } = JSON.parse(result);

        if (success === false) {
            console.log(result);
        }
    } catch (error) {
        const filename = `Счет на оплату от ${getFormatedDate({ date: new Date() })}.docx`;

        downloadFile({
            name: filename,
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            data: response.data as string,
        });

        this.close();
    }

    await handlerLoading.call(this, undefined);
};

export default download;
