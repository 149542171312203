import React from 'react';

import Logs from '../components/Logs/Logs';
import Main from '../components/Main/Main';

import InnerI from '../types';

const pages = {
    'joins-scripts-inner-main': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <Main model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'joins-scripts-inner-logs': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <Logs id={model!._id} />
                </>
            );
        },
    },
} as const;

export default pages;
