import axios from 'axios';

import getHeaders from '../functions/getHeaders';

export default function getChat({ idOfChat, params = [] }) {
    let query = '';

    if (idOfChat) {
        query += `id=${idOfChat}&`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    const headers = getHeaders();

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/chat?${query}`, {
                headers,
            })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;

                    if (success === true) {
                        const { chat, chats = [], messages, isLimit, counter } = data;

                        resolve({ chat, chats, messages, isLimit, counter });
                    } else {
                        reject(res.data);
                    }
                } else {
                    reject(res.data);
                }
            }, reject);
    });
}
