import ListAbsoluteMainI from '../types.ts';

const getListProps: ListAbsoluteMainI['getListProps'] = function () {
    const {
        className = '',
        items = [],
        styles = ['width', 'height'],
        isNotParams,
        parentStyle,
        minHeight,
        maxHeight,
        offsetWidth,
        offsetHeight,
        minWidth,
        maxWidth,
    } = this.props;
    const style: { [s: string]: any } = {};

    if (!isNotParams) {
        styles.forEach((prop) => {
            style[prop] = this.state[prop];
        });
    }

    if (parentStyle) {
        Object.keys(parentStyle).forEach((key) => {
            style[key] = parentStyle[key];
        });
    }

    if (minWidth && (!style.width || +style.width < minWidth)) {
        style.width = minWidth;
    }

    if (maxWidth && style.width && +style.width > maxWidth) {
        style.width = maxWidth;
    }

    if (offsetWidth && style.width) {
        style.width += offsetWidth;
    }

    if (minHeight && (!style.height || +style.height < minHeight)) {
        style.height = minHeight;
    }

    if (maxHeight && style.height && +style.height > maxHeight) {
        style.height = maxHeight;
    }

    if (offsetHeight && style.height) {
        style.height += offsetHeight;
    }

    const tagProps = className
        ? {
              style,
              ref: this.parent,
              className: `${className} ${items.length === 0 ? '_empty' : ''}`,
          }
        : {};

    return tagProps;
};

export default getListProps;
