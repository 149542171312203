const cities = {
    colsOrder: ['name', 'region'],
    cols: {
        name: {
            content: 'Название города',
            width: 75,
        },
        region: {
            content: 'Регион',
            width: 25,
        },
    },
    deletesCols: [],
    url: 'cities',
    emptyInfo: {
        title: 'Городов пока нет',
        description: 'Вы можете создать город по кнопке справа',
    },
} as const;

export default cities;
