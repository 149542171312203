import I from '../types.ts';

const save: I['save'] = function () {
    const { items = [], currents = [] } = this.state;
    const { chatTemplatesPopup } = this.props;
    const callback = chatTemplatesPopup.callback;
    const template = currents[0] ? items.find((item) => item._id === currents[0].id) : null;

    if (callback && template) {
        callback({ template });

        this.close();
    }
};

export default save;
