import MobPopupI from '../types.ts';

const getMaxHeight: MobPopupI['getMaxHeight'] = function () {
    const { windowHeight } = this.props;

    if (this.isFull) {
        return windowHeight!;
    }

    return +(windowHeight! - 48).toFixed(0);
};

export default getMaxHeight;
