import React from 'react';

import getFormatPrice from '@functions/getFormatPrice.ts';
import PayProjectT from '@global/models/PayProject.ts';

import { RenderColsT } from '../../types.ts';

const renderListPayProjects: RenderColsT<'listPayProjects'> = function ({ name: colName, item }) {
    let content;
    let className;

    const project = item as Pick<PayProjectT, '_id' | 'name' | 'minimal' | 'percent'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{project.name}</div>
                {project.minimal && (
                    <div className="v2table__text">, мин. {getFormatPrice(project.minimal)} ₽</div>
                )}
                {project.percent && <div className="v2table__text">, {project.percent}%</div>}
            </>
        );
    }

    return { content, className };
};

export default renderListPayProjects;
