import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../../manual/card/Head.jsx';
import Field from '../../manual/card/Field.jsx';
import File from '../../manual/card/File.jsx';

class ContentBlogMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getFields() {
        const { name } = this.props;

        if (name === 'legal') {
            return [
                { name: 'title' },
                { name: 'place', isReadOnly: !this.props.isNew },
                { name: 'viewType', isReadOnly: !this.props.isNew },
            ];
        }

        return [
            { name: 'title' },
            { name: 'place', isReadOnly: !this.props.isNew },
            { name: 'section' },
            { name: 'viewType', isReadOnly: !this.props.isNew },
            { name: 'metaTitle' },
            { name: 'metaDescription' },
        ];
    }

    render() {
        const {
            article = {},
            checkEditmode,
            changeArticle,
            errors = [],
            uploadMainFile,
            newFiles,
            name,
        } = this.props;

        return (
            <div ref={this.parent} className={`manualCard _blogMain _${name}`}>
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description={article?.title || '–'}></Head>
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__main _row">
                        <div className="manualCard__mainItem _fields">
                            <div className="manualCard__fields _row">
                                {this.getFields().map((field) => {
                                    const { name: fieldName, isReadOnly } = field;
                                    const { [fieldName]: value } = article;
                                    let items;
                                    const isDisabled = !checkEditmode();

                                    if (fieldName === 'place') {
                                        items = article.places;
                                    }

                                    if (fieldName === 'section') {
                                        items = article.sections;
                                    }

                                    if (fieldName === 'viewType') {
                                        items = article.viewTypes;
                                    }

                                    return (
                                        <div
                                            className={`manualCard__field _${fieldName}`}
                                            key={fieldName}
                                        >
                                            <Field
                                                value={value}
                                                type="blog"
                                                name={fieldName}
                                                handler={changeArticle}
                                                group="blogMain"
                                                isDisabled={isDisabled}
                                                isReadOnly={isReadOnly}
                                                isError={errors.indexOf(fieldName) !== -1}
                                                items={items}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {name !== 'legal' && (
                            <div className="manualCard__mainItem _preview">
                                <File
                                    _id={article.preview?._id}
                                    file={newFiles.preview || article.preview}
                                    folder="articles"
                                    name="preview"
                                    uploadFile={({ files }) => {
                                        uploadMainFile({ name: 'preview', files });
                                    }}
                                    isDisabled={!checkEditmode()}
                                >
                                    <span>Обложка</span> статьи
                                </File>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ContentBlogMain);

ContentBlogMain.propTypes = {
    article: PropTypes.object,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
    changeArticle: PropTypes.func,
    uploadMainFile: PropTypes.func,
    isNew: PropTypes.bool,
    newFiles: PropTypes.object,
    name: PropTypes.string,
};
