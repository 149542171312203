import I from '../types.ts';

const eventStartHandler: I['eventStartHandler'] = function (e) {
    if (!this.isMove) {
        this.isMove = true;

        const { x, y } = this.getPosition(e);

        this.startX = x;
        this.startY = y;

        this.setState({ isMove: true });
    }
};

export default eventStartHandler;
