import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const clearSmsTryHandler: I['clearSmsTryHandler'] = async function () {
    const model = this.state.model!;
    let response;

    try {
        response = await axios.patch<{}, { data: ReqResponseT }>(
            `${process.env.REACT_APP_API}/executor`,
            {
                id: model._id,
                action: 'clear-sms-try',
            },
            {
                headers: getHeaders(),
            },
        );
    } catch (error) {
        return Promise.reject(error);
    }

    const { success, data } = response.data;

    if (success) {
        return;
    }

    const { error } = data;

    this.setState({ error });
};

export default clearSmsTryHandler;
