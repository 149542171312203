import React from 'react';
import PropTypes from 'prop-types';

import setNotification from '../../../functions/setNotification';
import { inputValidate } from '../../../functions/inputValidate';

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.condForValidateProp = this.condForValidateProp.bind(this);
        this.validate = this.validate.bind(this);
    }

    checkEditmode(name) {
        const { editName } = this.state;
        const { isNew } = this.props;

        return editName === name || isNew;
    }

    validateField({ name, value, isRequire = false, keyName, validate }) {
        if (this.name === 'executor') {
            if (['type', 'status', 'organization', 'country', 'categories'].indexOf(name) !== -1) {
                return value && value.length > 0;
            }
        }

        if (this.name === 'car') {
            if (name === 'loadings') {
                return value && value.length > 0;
            }

            if (
                [
                    'perm-mass',
                    'full-mass',
                    'pallets',
                    'tonnage',
                    'long',
                    'width',
                    'height',
                    'heightWithFloor',
                    'heightCargo',
                ].indexOf(name) !== -1
            ) {
                return value && +value > 0;
            }
        }

        if (this.name === 'article' && name === 'time') {
            return !!value;
        }

        if (this.name === 'organization' && name === 'comments') {
            return true;
        }

        return (
            (!isRequire && !value) ||
            (value &&
                typeof value !== 'object' &&
                (validate === false || inputValidate({ name: keyName || name, value, isRequire })))
        );
    }

    condForValidateProp(nameBlock) {
        const { [this.name]: model } = this.props;

        if (this.name === 'executor') {
            return (
                nameBlock !== 'driverPassport' || ['driver', 'auto-courier'].includes(model.type)
            );
        }

        return true;
    }

    validate({ props, nameProp, isShowNotification = true }) {
        const { [this.name]: model } = this.props;
        let isSuccess = true;
        const errors = {};
        const propsAll = {};

        const orderCards = this.getOrderCards ? this.getOrderCards.call(this) : this.orderCards;

        orderCards.forEach((name) => {
            const nameBlock = typeof name === 'object' ? name.key : name;
            const requiredProps = this.cards[nameBlock].getRequiredProps
                ? this.cards[nameBlock].getRequiredProps.call(this)
                : this.cards[nameBlock].requiredProps;

            if (requiredProps && this.condForValidateProp(nameBlock)) {
                propsAll[nameBlock] = requiredProps;
            }
        });

        const checkBlock = ({ propsLoop, nameBlock }) => {
            const { keyTarget } = this.cards[nameBlock];
            errors[nameBlock] = [];

            propsLoop.forEach((nameRes) => {
                const name = typeof nameRes === 'object' ? nameRes.name : nameRes;
                const keyName = typeof nameRes === 'object' ? nameRes.keyName : null;
                const validate = typeof nameRes === 'object' ? nameRes.validate : null;
                const target = !keyTarget ? model : props?.[nameProp] || model[nameBlock];

                const isSuccessField = this.validateField({
                    name,
                    value: target[name],
                    nameBlock,
                    isRequire: propsAll[nameBlock]?.find((item) =>
                        typeof item === 'object' ? item.name === name : item === name,
                    ),
                    keyName,
                    validate,
                });

                isSuccess = isSuccess && isSuccessField;

                if (!isSuccessField) {
                    errors[nameBlock].push(name);
                }
            });
        };

        if (props) {
            checkBlock({ propsLoop: props, nameBlock: nameProp });
        } else {
            Object.keys(propsAll).forEach((namePropLoop) => {
                checkBlock({ propsLoop: propsAll[namePropLoop], nameBlock: namePropLoop });
            });
        }

        if (isShowNotification && !isSuccess) {
            setNotification({ notification: 'required-fields-not-complete' });
        }

        return { isSuccess, errors };
    }

    handlerErrors({ action, errors, error, block }) {
        this.setState((state) => {
            const newState = { ...state };
            const errorsState = { ...newState.errors };

            if (action === 'set') {
                newState.errors = errors;
            } else if (action === 'add') {
                if (!errorsState[block]) {
                    errorsState[block] = [];
                }

                const indexError = errorsState[block].indexOf(error);

                if (indexError === -1) {
                    if (error) {
                        errorsState[block].push(error);
                    } else {
                        errorsState[block].push(...errors);
                    }
                }

                newState.errors = errorsState;
            } else if (action === 'delete' && errorsState[block]) {
                const indexError = errorsState[block].indexOf(error);

                if (indexError !== -1) {
                    errorsState[block].splice(indexError, 1);
                }

                newState.errors = errorsState;
            }

            return newState;
        });
    }

    handlerLoadingBlock(loadingKey) {
        const { setLoadingKey } = this.props;

        return new Promise((resolve) => {
            this.setState({ loadingKey }, () => {
                if (typeof setLoadingKey === 'function') {
                    setLoadingKey(loadingKey);
                }

                resolve();
            });
        });
    }

    handlerLoadingSave(isLoadingSave) {
        return new Promise((resolve) => {
            this.setState({ isLoadingSave }, resolve);
        });
    }
}

export default Main;

Main.propTypes = {
    isNew: PropTypes.bool,
    setLoadingKey: PropTypes.func,
};
