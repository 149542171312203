import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = function () {
    handlerPopup('executorCreatePopup', {
        isShow: false,
        isJoin: undefined,
    });
};

export default close;
