import React from 'react';
import { connect } from 'react-redux';

import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import getActions from './methods/getActions.ts';
import save from './methods/save.ts';

import ChatTemplatesPopupI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderFoot from './renders/renderFoot.tsx';

class ChatTemplatesPopup
    extends WidgetWrapper<ChatTemplatesPopupI['props'], ChatTemplatesPopupI['state']>
    implements ChatTemplatesPopupI
{
    parent: ChatTemplatesPopupI['parent'];

    constructor(props: ChatTemplatesPopupI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    close = close;
    getActions = getActions;
    save = save;

    renderContent = renderContent;
    renderFoot = renderFoot;

    render() {
        return (
            <>
                <Popup
                    name="chatTemplatesPopup"
                    close={this.close.bind(this)}
                    checkClose={() => !document.querySelector('.v2popup._filterPopup')}
                    sendHandler={this.save.bind(this)}
                    checkSend={() => !document.querySelector('.v2popup._filterPopup')}
                >
                    <>{this.renderContent()}</>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        chatTemplatesPopup: state.chatTemplatesPopup,
    };
}

export default connect(mapStateToProps)(ChatTemplatesPopup);
