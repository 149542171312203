import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const getHeadActions: I['getHeadActions'] = function () {
    const { user } = this.state;
    const actions = [] as WidgetActionsT[];

    if (
        getPermissions(user, {
            notAdminCorporation: true,
            key: 'joins',
            items: [
                {
                    key: 'contracts',
                    rules: ['create'],
                },
            ],
        })
    ) {
        actions.push({
            type: 'link',
            content: '+ Новый договор',
            name: 'newContract',
            className: '_main',
            group: 'links',
            onClick: async () => {
                handlerPopup('joinContractPopup', { isShow: true });
            },
        });
    }

    return actions;
};

export default getHeadActions;
