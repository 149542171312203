import getCurrentPage from '@functions/getCurrentPage.ts';

import RootI from '../types.ts';

const checkPage: RootI['checkPage'] = function () {
    const { storePages } = this.props;

    const currentPage = getCurrentPage({ storePages, filter: (page) => !page.level });

    if (currentPage && currentPage !== this.currentPage) {
        this.currentPage = currentPage;

        (document.querySelector('html') as HTMLElement).setAttribute('data-page', currentPage);
    }
};

export default checkPage;
