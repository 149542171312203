import getCurrentCorporation from './getCurrentCorporation';

export default function checkRights({ user }) {
    if (!user) {
        return false;
    }

    const corporation = getCurrentCorporation({ user });

    if (!corporation) {
        return false;
    }

    return true;
}
