import React from 'react';

import Balance from '../components/balance/Balance';
import Docs from '../components/docs/Docs';
import Logs from '../components/logs/Logs';
import Main from '../components/main/Main';
import Users from '../components/users/Users';

import I from '../types';

const innerPages = {
    'corporations-inner-main': {
        render(this: I) {
            const { model } = this.state;

            return (
                <>
                    <Main inner={true} model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'corporations-inner-operations': {
        render(this: I) {
            const { model } = this.state;

            return (
                <div className="body__innerPageWrapper">
                    <Balance corporationId={model?._id} />
                </div>
            );
        },
    },
    'corporations-inner-docs': {
        render(this: I) {
            const { model } = this.state;

            return <Docs inner={true} model={model!} setModel={this.setModel.bind(this)} />;
        },
    },
    'corporations-inner-users': {
        render(this: I) {
            const { model } = this.state;

            return (
                <div className="body__innerPageWrapper">
                    <Users corporationId={model?._id} />
                </div>
            );
        },
    },
    'corporations-inner-logs': {
        render(this: I) {
            const { model } = this.state;

            return (
                <Logs
                    corporationId={model!._id}
                    models={[
                        { key: 'models', value: `corporationInfo=${model!._id}` },
                        { key: 'models', value: `corporationVerification=${model!._id}` },
                        { key: 'models', value: `userVerification=${model?.holder?._id}` },
                    ]}
                />
            );
        },
    },
} as const;

export default innerPages;
