import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';
import getParentActions from './methods/getParentActions.ts';
import getQuery from './methods/getQuery.ts';
import getRowClassName from './methods/getRowClassName.ts';
import handlerSocket from './methods/handlerSocket.ts';
import updateGroup from './methods/updateGroup.ts';

import PaysI from './types.ts';

import adminParse from './actions/adminParse.ts';
import checkError from './actions/checkError.ts';
import deleteGroup from './actions/deleteGroup.ts';
import deletePays from './actions/deletePays.ts';
import downloadAct from './actions/downloadAct.ts';
import refreshDoc from './actions/refreshDoc.ts';
import setPays from './actions/setPays.ts';
import stopAct from './actions/stopAct.ts';
import stopActs from './actions/stopActs.ts';
import renderHeadTitle from './renders/renderHeadTitle.tsx';

class Pays extends WidgetWrapper<PaysI['props'], PaysI['state']> implements PaysI {
    parent: PaysI['parent'];

    constructor(props: PaysI['props']) {
        super(props);
        this.state = {
            items: [],
        };

        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    renderHeadTitle = renderHeadTitle;

    getActions = getActions;
    getQuery = getQuery;
    getRowClassName = getRowClassName;
    updateGroup = updateGroup;
    getMoreActions = getMoreActions;
    getParentActions = getParentActions;

    handlerSocket = handlerSocket;

    deletePays = deletePays;
    setPays = setPays;
    checkError = checkError;
    downloadAct = downloadAct;
    deleteGroup = deleteGroup;
    refreshDoc = refreshDoc;
    stopAct = stopAct;
    adminParse = adminParse;
    stopActs = stopActs;

    renderWidget() {
        const { counter = 0, currents = [] } = this.state;
        const { executorId, groupId } = this.props;

        let localFilterName;

        if (groupId && groupId !== 'main') {
            localFilterName = groupId;
        }

        if (executorId) {
            localFilterName = executorId;
        }

        return (
            <Widget
                name="pays"
                title="Выплаты"
                description="акты"
                descriptionInner={counter}
                counter={counter}
                actions={this.getActions!()}
                isFull={true}
                inWidget={true}
                withBack={false}
                className="_white"
                renderHeadTitle={this.renderHeadTitle.bind(this)}
                filterQuery={(this as PaysI).getFilterQuery?.()}
            >
                <Table
                    inWidget={true}
                    name="pays"
                    isNotLink={true}
                    isInit={true}
                    isRowHeight={true}
                    init={this.tableInit!.bind(this)}
                    callback={this.tableCallback?.bind(this)}
                    getQuery={this.getQuery!.bind(this)}
                    currentsHandler={this.currentsHandler.bind(this)}
                    getRowClassName={this.getRowClassName.bind(this)}
                    currents={currents}
                    localFilterName={localFilterName}
                    otherData={{
                        getMoreActions: this.getMoreActions.bind(this),
                    }}
                />
            </Widget>
        );
    }

    componentDidMount(): void {
        this.setState({ user: this.props.user });

        (document.addEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    render() {
        const { payGroup } = this.state;
        const { inGroups, executorId } = this.props;

        if (executorId) {
            return this.renderWidget();
        }

        return (
            <>
                <Widget
                    name="pays"
                    title="Выплаты"
                    description={inGroups ? 'Реестр' : 'Акты'}
                    descriptionInner={inGroups ? payGroup?.name || '' : undefined}
                    isFull={true}
                    className="_innerPadding"
                    actions={this.getParentActions()}
                >
                    {this.renderWidget()}
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Pays);
