export default function getPositionFromParent({ target, parent }) {
    if (target && parent) {
        const { x: leftTarget, y: topTarget } = target.getBoundingClientRect();
        const { x: leftParent, y: topParent } = parent.getBoundingClientRect();

        return [Math.round(leftTarget - leftParent), Math.round(topTarget - topParent)];
    }

    return [0, 0];
}
