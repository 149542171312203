import React from 'react';
import { connect } from 'react-redux';

import InfoBlock from '@components/infoBlock/InfoBlock.tsx';
import Items from '@components/items/Items.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import checkAdmin from './methods/checkAdmin.ts';
import checkInit from './methods/checkInit.ts';
import checkPropsQuery from './methods/checkPropsQuery.ts';
import checkScroll from './methods/checkScroll.ts';
import getColWidth from './methods/getColWidth.ts';
import getItems from './methods/getItems.ts';
import getOrderCols from './methods/getOrderCols.ts';
import getOtherQuery from './methods/getOtherQuery.ts';
import getScrollParent from './methods/getScrollParent.ts';
import getTableClasses from './methods/getTableClasses.ts';
import loadingHandler from './methods/loadingHandler.ts';
import mountHandler from './methods/mountHandler.ts';
import refreshTable from './methods/refreshTable.ts';
import rowBlockSizeHandler from './methods/rowBlockSizeHandler.ts';
import setFakeItems from './methods/setFakeItems.ts';
import setLocalFilter from './methods/setLocalFilter.ts';
import showFilter from './methods/showFilter.ts';

import ManualTableI from './types.ts';

import renderCol from './renders/renderCol.tsx';
import renderContent from './renders/renderContent.tsx';
import renderFake from './renders/renderFake.tsx';
import renderHeadRow from './renders/renderHeadRow.tsx';
import renderRow from './renders/renderRow.tsx';
import renderUser from './renders/renderUser.tsx';
import tables, { TableT } from './static/tables.ts';

class ManualTable
    extends Items<ManualTableI['props'], ManualTableI['state']>
    implements ManualTableI
{
    parent: ManualTableI['parent'];
    queryKey: ManualTableI['queryKey'];

    constructor(props: ManualTableI['props']) {
        super(props);
        this.state = {
            fakeItems: [],
            loadings: {},
        };

        this.showFilter = this.showFilter.bind(this);
        this.refreshTable = this.refreshTable.bind(this);

        this.parent = React.createRef();
    }

    tables = tables;
    corporationColWidth = this.props.isWrap ? 18 : 15;
    classNameItem = '.v2table__row';

    renderHeadRow = renderHeadRow;
    renderContent = renderContent;
    renderRow = renderRow;
    renderCol = renderCol;
    renderUser = renderUser;
    renderFake = renderFake;

    getOrderCols = getOrderCols;
    getTableClasses = getTableClasses;
    checkInit = checkInit;
    getItems = getItems;
    getOtherQuery = getOtherQuery;
    getScrollParent = getScrollParent;
    refreshTable = refreshTable;
    showFilter = showFilter;
    checkAdmin = checkAdmin;
    getColWidth = getColWidth;
    checkPropsQuery = checkPropsQuery;
    rowBlockSizeHandler = rowBlockSizeHandler;
    setFakeItems = setFakeItems;
    loadingHandler = loadingHandler;
    mountHandler = mountHandler;
    setLocalFilter = setLocalFilter;
    checkScroll = checkScroll;

    componentDidMount(): void {
        this.mountHandler();

        (document.addEventListener as CustomListenerT)('refreshTable', this.refreshTable);
        (document.addEventListener as CustomListenerT)('showFilter', this.showFilter);
    }

    componentDidUpdate(): void {
        this.checkInit();
        this.checkPropsQuery();
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('refreshTable', this.refreshTable);
        (document.removeEventListener as CustomListenerT)('showFilter', this.showFilter);
    }

    render() {
        const { isShowMoreLoader = false, items = [], hasScroll } = this.state;
        const { inWidget, name, maxHeight, minHeight, withHead, staticItems } = this.props;
        const classes = this.getTableClasses();
        const isLoading = this.checkLoading();
        const table = this.tables[name] as TableT;
        const style: { maxHeight?: string; minHeight?: string } = {};

        if (maxHeight) {
            style.maxHeight = `${maxHeight}px`;
        }

        if (minHeight) {
            style.minHeight = `${minHeight}px`;
        }

        const resultItems = staticItems || items || [];

        return (
            <div
                ref={this.parent}
                className={`v2table ${isLoading ? '_isLoading' : ''} ${classes.join(' ')} ${hasScroll ? '_hasScroll' : ''} ${withHead === false ? '_withoutHead' : ''}`}
            >
                <LoaderBlock
                    className={`v2table__scrollLoader ${inWidget ? '_white' : ''}`}
                    itemClassName="v2table__loaderItem"
                    loaderClassName="_main"
                    isShow={isShowMoreLoader}
                    isScroll={true}
                />
                {table.emptyInfo && (
                    <InfoBlock
                        isShow={resultItems.length === 0 && !isLoading}
                        title={table.emptyInfo.title}
                        description={table.emptyInfo.description}
                    />
                )}

                {this.renderFake()}
                <div className="v2table__scroll _NOSCROLL" style={style}>
                    <div className="v2table__inner">
                        {this.renderHeadRow()}
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualTable);
