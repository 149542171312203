import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

class CustomHead extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { children, title, description, link, preview } = this.props;
        const propsHead = {
            meta: [{ property: 'og:type', content: 'website' }],
        };

        if (title) {
            propsHead.title = title;
            propsHead.meta.push({
                name: 'twitter:title',
                content: title,
            });
            propsHead.meta.push({
                property: 'og:title',
                content: title,
            });
        }

        if (description) {
            propsHead.meta.push({
                name: 'description',
                content: description,
            });
            propsHead.meta.push({
                name: 'twitter:description',
                content: description,
            });
            propsHead.meta.push({
                property: 'og:description',
                content: description,
            });
        }

        if (preview) {
            propsHead.meta.push({
                property: 'og:image',
                content: preview,
            });
            propsHead.meta.push({
                name: 'twitter:image:src',
                content: preview,
            });
        }

        if (link) {
            propsHead.meta.push({
                name: 'twitter:domain',
                content: process.env.REACT_APP_DOMEN,
            });
            propsHead.meta.push({
                name: 'twitter:url',
                content: link,
            });
            propsHead.meta.push({
                property: 'og:url',
                content: link,
            });
        }

        return (
            <Helmet {...propsHead}>
                {children}
            </Helmet>
        );
    }
}

export default CustomHead;

CustomHead.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    preview: PropTypes.string,
};
