import React from 'react';

import Icon from '@components/icon/Icon.tsx';

import FilterI from '../types.ts';

import Checkbox from './blocks/Checkbox.tsx';
import DateComp from './blocks/Date.tsx';
import Input from './blocks/Input.tsx';
import Select from './blocks/Select.tsx';
import Switch from './blocks/Switch.tsx';

const renderBlock: FilterI['renderBlock'] = function ({ block, index }) {
    const { name: blockName, support, inputSupport, type, items, value, reg, replaceReg } = block;
    let regExp;

    if (replaceReg === 'amount') {
        regExp = /[^\d.-]/gi;
    }

    if (replaceReg === 'number') {
        regExp = /[^\d]/gi;
    }

    return (
        <div className="v2filter__block" key={blockName}>
            <div className="v2filter__blockHead">
                {support}:
                <div
                    className="v2filter__blockHeadDelete _FOCUS _CLICK"
                    onClick={() => {
                        this.change({
                            [`${index}.value`]:
                                type === 'checkbox' || type === 'select'
                                    ? []
                                    : type === 'switch'
                                      ? false
                                      : '',
                            [`${index}.currentDate`]: null,
                            [`${index}.doubleCurrentDate`]: null,
                            [`${index}.clearedKey`]: new Date().getTime(),
                        });
                    }}
                >
                    <Icon name="filter-delete" />
                </div>
            </div>

            <div className="v2filter__blockContent">
                {type === 'input' && (
                    <Input
                        blockName={blockName}
                        inputSupport={inputSupport}
                        value={value as string}
                        index={index}
                        change={this.change.bind(this)}
                        reg={reg}
                        regExp={regExp}
                    />
                )}
                {type === 'checkbox' && (
                    <Checkbox
                        items={items}
                        value={value as string}
                        index={index}
                        change={this.change.bind(this)}
                    />
                )}
                {type === 'date' && (
                    <DateComp
                        currentDate={block.currentDate}
                        doubleCurrentDate={block.doubleCurrentDate}
                        index={index}
                        change={this.change.bind(this)}
                        updatedKey={block.clearedKey}
                        init={this.setSizeInit.bind(this)}
                    />
                )}
                {type === 'switch' && (
                    <div className="v2filter__blockSwitch">
                        <Switch
                            value={!!value}
                            blockName={blockName}
                            change={this.change.bind(this)}
                            index={index}
                        />
                    </div>
                )}
                {type === 'select' && (
                    <div className="v2filter__blockSwitch">
                        <Select
                            value={value as string[]}
                            change={this.change.bind(this)}
                            index={index}
                            selectList={block.selectList}
                            updatedKey={block.clearedKey}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default renderBlock;
