export default function checkTagParent({ node, tag, className }) {
    let isCheck = true;

    if (node.nodeName.toLowerCase() !== tag) {
        isCheck = false;
    }

    if (className && !node.className?.includes(className)) {
        isCheck = false;
    }

    return isCheck;
}
