import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import AnimateChange from '../../components/AnimateChange.jsx';
import EmodziList from '../../components/EmodziList.jsx';
import Filter from '../../components/Filter.jsx';
import Link from '../../components/Link.jsx';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import WindowActions from '../../components/WindowActions.jsx';
import WindowPrompt from '../../components/WindowPrompt.jsx';
import Windows from '../../components/Windows.jsx';
import CounterNotRead from '../../components/chat/CounterNotRead.jsx';
import UserSettings from '../../components/chat/UserSettings.jsx';
import Back from '../../components/crm/widget/Back.jsx';
import InfoHead from '../../components/crm/widget/InfoHead.jsx';

import checkValueOfEmpty from '../../functions/checkValueOfEmpty';
import checkRights from '../../functions/crm/checkRights';
import download from '../../functions/download';
import getEnvParams from '../../functions/getEnvParams';
import getHeaders from '../../functions/getHeaders';
import handlerWindow from '../../functions/handlerWindow';
import setNotification from '../../functions/setNotification';

import changeCardNumberFromChat from '../../requests/changeCardNumberFromChat';
import closeChat from '../../requests/closeChat';
import setChatResponsible from '../../requests/setChatResponsible';
import setCommentsChat from '../../requests/setCommentsChat';
import Chat from '../Chat.jsx';

const pages = require('../../redux/pages').default;

class ChatPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.close = this.close.bind(this);
        this.setComments = this.setComments.bind(this);

        this.renderWindowsPromptComments = this.renderWindowsPromptComments.bind(this);
        this.renderWindowsPromptClose = this.renderWindowsPromptClose.bind(this);
        this.renderWindowsChatSettings = this.renderWindowsChatSettings.bind(this);
        this.renderChatPreviewActions = this.renderChatPreviewActions.bind(this);
        this.renderMessageActions = this.renderMessageActions.bind(this);
        this.renderMessageDocActions = this.renderMessageDocActions.bind(this);
        this.renderResponsibleAlert = this.renderResponsibleAlert.bind(this);
        this.renderMessageCardNumber = this.renderMessageCardNumber.bind(this);

        this.getChatsCounters = this.getChatsCounters.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilterCallback = this.setFilterCallback.bind(this);
        this.checkRights = this.checkRights.bind(this);
        this.setSettings = this.setSettings.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    getPages() {
        const pagesChat = pages.filter((page) => page.level === 2 && page.parentName === 'chat');

        return pagesChat;
    }

    renderLink({ item: page }) {
        const { name, contentOfLink } = page;

        return (
            <Link pageName={name} className="widget__headActionsElem widget__headLink" key={name}>
                <div className="widget__headLinkInner _click">
                    {name === 'chat-important' && (
                        <div className="widget__headCounter">
                            <CounterNotRead type="all" />
                        </div>
                    )}
                    {contentOfLink}
                </div>
            </Link>
        );
    }

    close({ hide, handlerLoader }) {
        return new Promise((resolve) => {
            const { levels } = this.props;
            const idOfChat = levels[getEnvParams().keyLevelsChatInner];

            closeChat({ id: idOfChat }).then(
                () => {
                    handlerLoader(false);
                    hide();

                    resolve();
                },
                () => null,
            );
        });
    }

    renderWindowsPromptClose() {
        return <WindowPrompt className="_topRight" name="closeChat" callback={this.close} />;
    }

    setComments({ hide, handlerLoader, valueArea }) {
        return new Promise((resolve) => {
            const { levels } = this.props;
            const idOfChat = levels[getEnvParams().keyLevelsChatInner];

            setCommentsChat({ id: idOfChat, comments: valueArea }).then(
                () => {
                    handlerLoader(false);
                    hide();

                    resolve();
                },
                () => null,
            );
        });
    }

    renderWindowsPromptComments() {
        return (
            <WindowPrompt className="_topRight" name="commentsChat" callback={this.setComments} />
        );
    }

    setSettings({ ...props }) {
        return new Promise((resolve) => {
            const { levels } = this.props;
            const id = levels[getEnvParams().keyLevelsChatInner];

            const fields = {};

            ['userId', 'name', 'phone'].forEach((key) => {
                if (checkValueOfEmpty(props[key], true) || props[key] === null) {
                    fields[key] = props[key];
                }
            });

            axios
                .patch(
                    `${process.env.REACT_APP_API}/chat`,
                    { id, action: 'set-userInfo', ...fields },
                    { headers: getHeaders() },
                )
                .then(
                    (res) => {
                        const { success } = res.data;

                        resolve(success);
                    },
                    () => null,
                );
        });
    }

    renderWindowsChatSettings({ anyInfo }) {
        return <UserSettings anyInfo={anyInfo} setSettings={this.setSettings} />;
    }

    getChatsCounters({ counter, increment }) {
        this.setState({ chatCounter: increment ? (this.state.chatCounter || 0) + 1 : counter });
    }

    setFilterCallback(callbackFilter) {
        this.callbackFilter = callbackFilter;
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    renderEmodziList({ addEmodzi, area, startPosition }) {
        return <EmodziList addEmodzi={addEmodzi} input={area} startPosition={startPosition} />;
    }

    renderChatPreviewActions({ chatId, savedTarget, responsible, items }) {
        const callback = ({ key }) =>
            new Promise((resolve, reject) => {
                const { user } = this.props;

                if (key === 'choice') {
                    document.dispatchEvent(
                        new CustomEvent('choiceChatPreview', { detail: { id: chatId } }),
                    );

                    resolve();
                } else if (key === 'delete') {
                    document.dispatchEvent(
                        new CustomEvent('deleteChatPreview', { detail: { id: chatId, resolve } }),
                    );
                } else if (key === 'setResponsibleSelf') {
                    if (responsible && responsible !== user._id) {
                        handlerWindow({
                            parent: this.parent.current,
                            parentResize: this.parent.current,
                            parentScroll: this.parent.current.querySelector('.chatList__boxScroll'),
                            target: savedTarget,
                            action: 'show',
                            name: 'chatResponsibleAlert',
                            className: '_left _prompt',
                            uniqKey: key,
                            chatId,
                            isHideFromScroll: true,
                            alertClassName: '_centerLeft',
                            centers: {
                                left: 1,
                                top: 0.5,
                            },
                        });
                    } else {
                        setChatResponsible({
                            idOfResponsible: user._id,
                            id: chatId,
                        }).then(resolve, reject);
                    }
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                    });
                }}
            />
        );
    }

    renderMessageActions({ items, deleteMessage, editMessage }) {
        const callback = ({ key }) =>
            new Promise((resolve, reject) => {
                if (key === 'edit') {
                    editMessage();

                    resolve();
                } else if (key === 'delete') {
                    deleteMessage().then(resolve, reject);
                } else {
                    reject();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'chatMessageActions',
                    });
                }}
            />
        );
    }

    renderMessageCardNumber({ chatId, cardNumber, cardNumberCallback }) {
        return (
            <WindowPrompt
                className="_bottomCenter"
                name="chatCardNumber"
                description={`Заменить номер карты<br/>на <b>${cardNumber}</b>?`}
                callback={({ hide, handlerLoader }) => {
                    changeCardNumberFromChat({ id: chatId, cardNumber }).then(
                        () => {
                            setNotification({ notification: 'success-update-bank-card' });

                            cardNumberCallback();

                            hide();
                        },
                        () => {
                            handlerLoader(null);
                        },
                    );
                }}
            />
        );
    }

    renderResponsibleAlert({ chatId, alertClassName }) {
        const { user } = this.props;

        return (
            <WindowPrompt
                className={alertClassName}
                name="chatResponsibleAlert"
                callback={({ hide, handlerLoader }) => {
                    setChatResponsible({
                        idOfResponsible: user._id,
                        id: chatId,
                    }).then(hide, () => handlerLoader(null));
                }}
            />
        );
    }

    renderMessageDocActions({ items, handlerDoc, file }) {
        const callback = ({ key }) =>
            new Promise((resolve, reject) => {
                if (key === 'add') {
                    handlerDoc();

                    resolve();
                } else if (key === 'download') {
                    download({ files: [file] }).then(resolve, reject);
                } else {
                    reject();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'chatMessageDocActions',
                    });
                }}
            />
        );
    }

    checkChangeType() {
        const { levels } = this.props;
        const chatType = levels[1];

        if (this.chatType !== chatType) {
            this.chatType = chatType;
        }
    }

    checkRights() {
        const { user } = this.props;

        return checkRights({ user, key: 'chat', isEdit: true });
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;

        if (name === 'chat') {
            const { type } = data;

            if (type === 'newChat') {
                this.getChatsCounters({ increment: true });
            }
        }
    }

    componentDidMount() {
        this.checkChangeType(true);

        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentDidUpdate() {
        this.checkChangeType();
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const { isInitBack, chatCounter = 0 } = this.state;

        return (
            <div ref={this.parent} className="widget _ready _parent">
                <Windows name="promptCloseChat" renderContent={this.renderWindowsPromptClose} />
                <Windows
                    name="promptCommentsChat"
                    renderContent={this.renderWindowsPromptComments}
                />
                <Windows name="chatUserSettings" renderContent={this.renderWindowsChatSettings} />
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="emodziList" renderContent={this.renderEmodziList} />
                <Windows name="chatPreviewActions" renderContent={this.renderChatPreviewActions} />
                <Windows name="chatResponsibleAlert" renderContent={this.renderResponsibleAlert} />
                <Windows name="chatMessageActions" renderContent={this.renderMessageActions} />
                <Windows
                    name="chatMessageCardNumber"
                    renderContent={this.renderMessageCardNumber}
                />

                <Windows
                    name="chatMessageDocActions"
                    renderContent={this.renderMessageDocActions}
                />
                <div className="widget__head _row">
                    <Back group="chat" init={() => this.setState({ isInitBack: true })} />

                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead title="Чат" className={`${isInitBack ? '_withBack' : ''}`}>
                                <AnimateChange
                                    className="widget__headNameItemInner _chat"
                                    prop={chatCounter}
                                    type="_translateMedium"
                                >
                                    <>({chatCounter})</>
                                </AnimateChange>
                            </InfoHead>
                            <div className="widget__headActions">
                                <ListAbsoluteMain
                                    className="widget__headActionsGroup _dynamic"
                                    items={this.getPages()}
                                    renderItem={this.renderLink}
                                    classNameItem="widget__headLink"
                                    prop="name"
                                    itemParams={['offsetRight']}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content">
                    <div className="chatPage">
                        <div className="chatPage__inner">
                            <Chat
                                setHeightPage={() => null}
                                getParent={() => this.parent.current}
                                setFilterCallback={this.setFilterCallback}
                                checkRights={this.checkRights}
                                getChatsCounters={this.getChatsCounters}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(ChatPage);

ChatPage.propTypes = {
    levels: PropTypes.array,
    user: PropTypes.object,
};
