import React from 'react';

import getEndText from '@functions/getEndText.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import JoinDocT from '@global/models/JoinDoc.ts';

import { RenderColsT } from '../../types.ts';

const renderJoinsDocs: RenderColsT<'joinsDocs'> = function ({ name: colName, item }) {
    let content;
    let className;

    const joinsDoc = item as JoinDocT;

    if (colName === 'name') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(joinsDoc.name) }}
                ></div>
            </>
        );
    }

    if (colName === 'files') {
        content = (
            <>
                <div className="v2table__text">
                    {joinsDoc.filesCounter}{' '}
                    {getEndText(joinsDoc.filesCounter, ['файл', 'файла', 'файлов'])}
                </div>
            </>
        );
    }

    if (colName === 'date') {
        content = (
            <>
                <div className="v2table__text">
                    {getFormatedDate({ date: new Date(joinsDoc.createdDate) })}
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderJoinsDocs;
