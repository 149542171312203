import I from '../types.ts';

const checkUpdate: I['checkUpdate'] = function (this: I) {
    const { updatedKey } = this.props;

    if (this.updatedKey !== updatedKey) {
        this.updatedKey = updatedKey;

        if (this.updateHandler) {
            this.updateHandler();
        }
    }
};

export default checkUpdate;
