import MobPopupI from '../types.ts';

const move: MobPopupI['move'] = function (isTrust = true, isStart = false) {
    const inner = this.getInner();
    const downBreak = this.getDownBreak(isStart);

    if (!inner) {
        return;
    }

    if (this.moveY > 0) {
        this.moveY = 0;
    }

    if (-this.moveY > downBreak) {
        this.moveY = -downBreak;
    }

    if (!isTrust && this.moveY === 0 && this.state.moveDirection !== 'start') {
        this.setState({ moveDirection: 'start' });
    }

    document.dispatchEvent(
        new CustomEvent('popup', {
            detail: { action: 'move', name: this.name, move: this.moveY },
        }),
    );

    inner.style.transform = `translate(0,${-this.moveY}px)`;
};

export default move;
