import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const inactiveContract: I['inactiveContract'] = async function ({ joinContract, contractType }) {
    const info = {
        name: joinContract.name,
        number: joinContract.number,
        type: contractType,
    };

    const handler = (type: string) => {
        handlerPopup('alertPopup', {
            isShow: true,
            type,
            info,
            important: type === 'inactiveJoinContractHasMvd',
            callback: this.contractHandler.bind(this, { joinContract, action: 'delete' }, true),
        });
    };

    try {
        await this.contractHandler({ joinContract, action: 'delete' });

        handler('inactiveJoinContract');

        return;
    } catch (e) {
        const error = e as { hasMvd?: boolean };

        if (error.hasMvd) {
            handler('inactiveJoinContractHasMvd');

            return;
        }

        return Promise.reject();
    }
};

export default inactiveContract;
