import React from 'react';

import TagT from '@global/models/Tag.ts';

import { RenderColsT } from '../../types.ts';

const renderListTags: RenderColsT<'listTags'> = function ({ name: colName, item }) {
    let content;
    let className;

    const tag = item as Pick<TagT, '_id' | 'name'> & { groupName: string; color: string };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__colorPin" style={{ background: tag.color }} />&nbsp;&nbsp;
                <div className="v2table__text">{tag.name}</div>
                {tag.groupName && <div className="v2table__text _grey">, {tag.groupName}</div>}
            </>
        );
    }

    return { content, className };
};

export default renderListTags;
