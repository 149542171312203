import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const deleteModel: I['deleteModel'] = async function (force) {
    const { model } = this.state;
    const { config } = this.props;

    let query = `id=${model?._id}`;

    if (force === true) {
        query += `&force=${force}`;
    }

    try {
        const response = await axios.delete<ReqResponseT<{ message: string }>>(
            `${process.env.REACT_APP_API}/${config.url}?${query}`,
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            if (!force) {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: config.deleteAlertPopup,
                    info: model?.name,
                    callback: deleteModel.bind(this, true),
                });

                return;
            }

            changePage<'crm'>({ pageName: config.tablePage });

            return;
        }

        const { message } = data;

        if (message === 'Model denied delete' && config.deleteDeniedAlertPopup) {
            handlerPopup('alertPopup', {
                isShow: true,
                type: config.deleteDeniedAlertPopup,
                info: model?.name,
                denied: true,
            });

            return;
        }
    } catch (error) {}

    return Promise.reject();
};

export default deleteModel;
