import React from 'react';

import I from '../../types.ts';

const renderCorporationMain: I['renderCards'] = function () {
    const { data } = this.props;

    if (data?.create) {
        return (
            <>
                <div className="v2widgetCard__fields _bottom _ROW _ROW-ALIGN-CENTER _ROWWRAP">
                    {this.renderFields({
                        fieldsOrder: ['inn', 'bic', 'info', 'line'],
                        fragment: true,
                    })}
                </div>
                <div className="v2widgetCard__fields _bottom _ROW _ROWWRAP">
                    {this.renderFields({
                        fieldsOrder: [
                            'kpp',
                            'fullName',
                            'bankName',
                            'corrAccount',
                            'bankAccount',
                            'line',
                        ],
                        fragment: true,
                    })}
                </div>
            </>
        );
    }

    return <>{this.renderFields()}</>;
};

export default renderCorporationMain;
