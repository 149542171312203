import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@components/avatar/Avatar.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import { StoreT } from '@global/types.ts';

import getMoreActions from './methods/getMoreActions.ts';
import save from './methods/save.ts';

import InnerMainI from './types.ts';

import checkFns from './actions/checkFns.ts';
import clearSmsTryHandler from './actions/clearSmsTryHandler.ts';
import findInn from './actions/findInn.ts';
import fnsHandler from './actions/fnsHandler.ts';
import renderCommentsCard from './renders/renderCommentsCard.tsx';
import renderTagsCard from './renders/renderTagsCard.tsx';
import renderVerificationCard from './renders/renderVerificationCard.tsx';

class InnerMain
    extends WidgetPageWrapper<InnerMainI['props'], InnerMainI['state']>
    implements InnerMainI
{
    parent: InnerMainI['parent'];

    constructor(props: InnerMainI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    fnsHandler = fnsHandler;
    checkFns = checkFns;
    findInn = findInn;
    clearSmsTryHandler = clearSmsTryHandler;

    renderTagsCard = renderTagsCard;
    renderVerificationCard = renderVerificationCard;
    renderCommentsCard = renderCommentsCard;

    getMoreActions = getMoreActions;

    save = save;

    render() {
        const { model, error, user } = this.state;

        return (
            <>
                <WidgetPage
                    name="executors"
                    save={this.save.bind(this)}
                    disabled={!this.checkChange().isChange}
                    error={error}
                >
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__card _bottom">
                                    <WidgetCard
                                        name="executorMain"
                                        model={model}
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                        user={user}
                                        moreActions={this.getMoreActions()}
                                        headInfo={
                                            model.logoSrc ? (
                                                <>
                                                    <div
                                                        className="v2widgetCard__headAvatar _CLICK"
                                                        onClick={() => {
                                                            handlerPopup('galeryPopup', {
                                                                isShow: true,
                                                                title: 'Аватар в системе',
                                                                files: [
                                                                    {
                                                                        _id: '',
                                                                        fullSrc: model.logoSrc,
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    >
                                                        <Avatar image={model.logoSrc} />
                                                    </div>
                                                </>
                                            ) : undefined
                                        }
                                    />
                                </div>
                                <div className="v2widgetPage__card _tags">
                                    {this.renderTagsCard()}
                                </div>
                                <div className="v2widgetPage__card _comments">
                                    {this.renderCommentsCard()}
                                </div>
                                <div className="v2widgetPage__card _verification">
                                    {this.renderVerificationCard()}
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(InnerMain);
