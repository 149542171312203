import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { CorporationT } from '@global/models/Corporation.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { model, type, limitType } = this.state;
    const { corporationEditPopup } = this.props;
    const { callback } = corporationEditPopup;
    const fields = this.checkChange(true).model;
    const resultFields: Record<any, unknown> = {};

    if (type === 'tariff') {
        resultFields.tariff = {
            pay: fields,
        };
    } else if (type === 'limits') {
        resultFields.limits = {
            [limitType!]: fields,
        };
    }

    await handlerLoading.call(this, 'save');

    const response = await axios.patch<
        {},
        {
            data: ReqResponseT<{
                model?: CorporationT;
                message: string;
                error?: { name: string; text: string };
            }>;
        }
    >(
        `${process.env.REACT_APP_API}/corporation`,
        {
            fields: resultFields,
            id: model?._id,
        },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        this.close();

        if (typeof callback === 'function') {
            callback(data.model!);
        }

        document.dispatchEvent(new CustomEvent('refreshTable', { detail: { name: 'users' } }));
    } else {
        const { error } = data;

        if (error) {
            this.setState({ error });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
