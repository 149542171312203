import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dispatcher } from '../../../redux/redux';

import login from '../../../functions/login';

import AuthForm from './Form.jsx';

import Button from '../../Button.jsx';
import Link from '../../Link.jsx';
import changePage from '../../../functions/changePage';
import setNotification from '../../../functions/setNotification';
import getPageLink from '../../../functions/getPageLink';

class AuthLogin extends AuthForm {
    constructor(props) {
        super(props);
        this.state = {};

        this.login = this.login.bind(this);

        this.parent = React.createRef();
    }

    name = 'login';

    orderGroups = ['login'];

    login() {
        const { fields } = this.state;
        const { setSuccessLogin } = this.props;
        const { isSuccess, errorFieldName } = this.checkValidateFields();

        if (!isSuccess) {
            if (errorFieldName) {
                const input = this.parent.current.querySelector(
                    `#input-auth-login-${errorFieldName}`,
                );

                if (input) {
                    input.focus();
                }
            }
        } else {
            this.handlerLoading('login').then(() => {
                login({ login: fields.email.value, password: fields.password.value }).then(
                    (res) => {
                        const { success, data } = res.data;

                        if (success) {
                            setSuccessLogin().then(() => {
                                setTimeout(() => {
                                    dispatcher({ type: 'isProccessLogin', data: false });

                                    changePage({ href: getPageLink({ name: 'corporation' }) });
                                }, 3000);

                                this.handlerLoading(null);
                            });
                        } else {
                            const { message } = data;

                            if (message === 'User not found') {
                                setNotification({ notification: `error-login-username` });

                                this.handlerField({
                                    action: 'error',
                                    name: 'email',
                                    value: 'userNotFound',
                                });
                            }

                            if (message === 'Error password') {
                                setNotification({ notification: `error-login-password` });

                                this.handlerField({
                                    action: 'error',
                                    name: 'password',
                                    value: 'passwordError',
                                });
                            }

                            this.handlerLoading(null);
                        }
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;

        return (
            <>
                <div ref={this.parent} className="authForm _login">
                    <div className="authForm__inner">
                        <div className="authForm__head">
                            <div className="authForm__headInner">
                                <div className="authForm__headTitle">
                                    <div className="authForm__headTitleInner">
                                        Войдите в систему
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="authForm__content">
                            <div className="authForm__contentBlock _col">
                                <div className="authForm__contentGroups">
                                    {this.orderGroups.map((nameGroup) =>
                                        this.renderGroup({ name: nameGroup }),
                                    )}
                                </div>
                                <div className="authForm__contentButton">
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        onClick={this.login}
                                        showLoader={loadingKey === 'login'}
                                    >
                                        Войти в CRM
                                    </Button>
                                </div>
                                <div className="authForm__contentLinks _row">
                                    <Link className="authForm__contentLink" pageName="forget">
                                        Я не помню пароль
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="authForm__back _col">
                    <Link className="authForm__backButton" pageName="registration">
                        <Button className="_blue _medium2Size">Зарегистрироваться</Button>
                    </Link>
                    <p className="authForm__backDescription">
                        После регистрации вы получите доступ ко&nbsp;всем возможностям LIVECARGO CRM
                    </p>
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(AuthLogin);

AuthLogin.propTypes = {
    setSuccessLogin: PropTypes.func,
};
