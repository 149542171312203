import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handler() {
        const { name, handler, returnValue, value } = this.props;
        // const resValue = !returnValue ? !value : ((value && null) || returnValue);
        const resultValue = returnValue ? (value === true ? null : returnValue) : !value;

        handler({ action: 'change', name, value: resultValue });
    }

    render() {
        const { value, className, children, id, isDisabled, icon, isError } = this.props;

        return (
            <label
                className={`checkbox ${className || ''} ${!children ? '_empty' : ''} ${
                    isError ? '_error' : ''
                }`}
            >
                <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={value}
                    onChange={(e) => {
                        e.stopPropagation();

                        this.handler();
                    }}
                    id={id || new Date().getTime()}
                    disabled={isDisabled}
                />
                <div className="checkbox__view _click">
                    <div className="checkbox__point _col">
                        <i className="checkbox__pointIcon">
                            <Icon name={icon || 'done-3'} />
                        </i>
                    </div>
                    <div className="checkbox__content">{children}</div>
                </div>
            </label>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Checkbox);

Checkbox.propTypes = {
    value: PropTypes.bool,
    name: PropTypes.string,
    className: PropTypes.string,
    handler: PropTypes.func,
    children: PropTypes.node,
    returnValue: PropTypes.any,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    icon: PropTypes.string,
    isError: PropTypes.bool,
};
