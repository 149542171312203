export default function setDateFromStr(date: string): Date | null {
    const resultDate = new Date();
    const [day, month, year] = date.split('.');

    resultDate.setUTCFullYear(+year);
    resultDate.setUTCMonth(+month - 1);
    resultDate.setUTCDate(+day);
    resultDate.setUTCHours(-new Date().getTimezoneOffset() / 60, 0, 0, 0);

    if (Number.isNaN(resultDate.getTime())) {
        return null;
    }

    return resultDate;
}
