import ReportsI from '../types.ts';

const getQuery: ReportsI['getQuery'] = function (this: ReportsI) {
    const { corporationId } = this.props;
    const query = [{ key: 'type', value: 'balance' }];

    if (corporationId) {
        query.push({ key: 'adminCorporationId', value: corporationId });
    }

    return query;
};

export default getQuery;
