import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const stopAct: I['stopAct'] = async function ({ pay }) {
    try {
        const response = await axios.patch<{}, { data: ReqResponseT<{ message?: string }> }>(
            `${process.env.REACT_APP_API}/pays`,
            { id: pay._id, action: 'stop' },
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            return;
        }

        const { message } = data;

        if (message === 'Process already start') {
            setNotification({ notification: 'pay-not-stop' });
        }
    } catch (error) {}

    return Promise.reject();
};

export default stopAct;
