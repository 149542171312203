import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function ({ join }) {
    const { user } = this.state;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'join',
                    modelId: join._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (join.status === 'wait') {
        actions.unshift({
            key: 'again',
            name: 'smsAgain',
            onClick: this.sendSms.bind(this, { join }),
        });
    }

    if (
        !['check', 'complete', 'inactive'].includes(join.status) &&
        getPermissions(user, {
            key: 'joins',
            items: [
                {
                    key: 'invites',
                    rules: ['delete'],
                },
            ],
        })
    ) {
        actions.unshift({
            key: 'delete',
            name: 'deleteJoin',
            text: 'Удалить приглашение',
            onClick: async () => {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'deleteJoin',
                    info: [
                        ...(join.executorName ? [join.executorName] : []),
                        `<span class="_NOWRAP">${join.executorPhone}</span>`,
                    ].join(', '),
                    callback: this.deleteJoin.bind(this, { join }),
                    successCallback: () => {
                        this.tableUpdateItems!();
                    },
                });
            },
        });
    }

    return actions;
};

export default getMoreActions;
