import React from 'react';

const blocks = [
    {
        icon: 'adv-icon-4.svg',
        title: 'Веб-приложение',
        content:
            'Наше приложение можно установить на устройство с любой операционной системой (iOS, Android). Ограничений для установки нет — вам нужен только браузер и доступ в интернет. <b>Приложение позволяет решить следующие задачи:</b><br/>— Оформлять новых исполнителей<br/>— Подключать CRM LIVECARGO к «МойНалог»<br/>— Подписывать договоры и акты для выплат<br/>— Общаться в чате с менеджером<br/>— Автоматизировать налоговые выплаты',
        decor() {
            return (
                <>
                    <img
                        src={require('../../../../../../../img/crm/public/business/adv-block-1-image-1.png')}
                        alt=""
                        className="publicBusinessAdvantages__blockDecorImage _1"
                    />
                </>
            );
        },
    },
    {
        icon: 'adv-icon-6.svg',
        title: 'Уведомления в МВД',
        content:
            'Теперь можно в одном месте хранить уведомления МВД по нерезидентам. Уведомление привязывается к конкретному договору. После подписания договора с обеих сторон автоматически формируется уведомление в МВД. На его подачу по закону отводится 3 рабочих дня.<br/><br/>Интерфейс позволяет формировать бумажный бланк и контролировать сроки подачи. Также он облегчает работу с Госуслугами — доступны поиск по номеру уведомления и ФИО исполнителя и возможность хранения поданных заявлений. ',
        decor() {
            return (
                <>
                    <div className="publicBusinessAdvantages__blockDecorItem _1">
                        <img
                            src={require('../../../../../../../img/crm/public/business/adv-block-2-image-1.jpg')}
                            alt=""
                            className="publicBusinessAdvantages__blockDecorImage"
                        />
                    </div>
                    <div className="publicBusinessAdvantages__blockDecorItem _2">
                        <img
                            src={require('../../../../../../../img/crm/public/business/adv-block-2-image-2.jpg')}
                            alt=""
                            className="publicBusinessAdvantages__blockDecorImage"
                        />
                    </div>
                </>
            );
        },
    },
    {
        icon: 'adv-icon-7.svg',
        title: 'Интеграции',
        content:
            '<b class="_UPPER">Telegram</b>В сервисе доступна интеграция с Telegram. Она позволяет оперативно налаживать связь с исполнителями: отправлять и получать сообщения.<br/><b class="_UPPER">Модуль обработки для 1С</b>Устанавливайте дополнительный модуль обработки для 1С, чтобы настроить обмен данными, выгружать документы и выписки в автоматическом или ручном режиме.',
        decor() {
            return (
                <>
                    <div className="publicBusinessAdvantages__blockDecorItem _1">
                        <img
                            src={require('../../../../../../../img/crm/public/business/adv-block-3-image-1.jpg')}
                            alt=""
                            className="publicBusinessAdvantages__blockDecorImage"
                        />
                    </div>
                </>
            );
        },
    },
] as const;

export default blocks;
