import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getHeadActions from './methods/getHeadActions.ts';
import getMoreActions from './methods/getMoreActions.ts';
import getQuery from './methods/getQuery.ts';
import handlerSocket from './methods/handlerSocket.ts';

import ContractsI from './types.ts';

import contractHandler from './actions/contractHandler.ts';
import inactiveContract from './actions/inactiveContract.ts';
import setSign from './actions/setSign.ts';
import renderHeadTitle from './renders/renderHeadTitle.tsx';

class Contracts
    extends WidgetWrapper<ContractsI['props'], ContractsI['state']>
    implements ContractsI
{
    parent: ContractsI['parent'];
    tableUpdateItem: ContractsI['tableUpdateItem'];
    tableUpdateItems: ContractsI['tableUpdateItems'];

    constructor(props: ContractsI['props']) {
        super(props);
        this.state = {
            counter: 0,
            items: [],
        };

        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    getActions = getActions;
    getQuery = getQuery;
    getMoreActions = getMoreActions;
    getHeadActions = getHeadActions;
    handlerSocket = handlerSocket;

    contractHandler = contractHandler;
    inactiveContract = inactiveContract;
    setSign = setSign;

    renderHeadTitle = renderHeadTitle;

    renderWidget() {
        const { counter = 0, currents = [] } = this.state;

        return (
            <Widget
                name="joinsContracts"
                descriptionInner={counter}
                counter={counter}
                isFull={true}
                className="_white"
                withBack={false}
                inWidget={true}
                renderHeadTitle={this.renderHeadTitle.bind(this)}
                actions={this.getActions!()}
                filterQuery={(this as ContractsI).getFilterQuery?.()}
            >
                <Table
                    name="joinsContracts"
                    isNotLink={true}
                    isInit={true}
                    callback={this.tableCallback!.bind(this)}
                    inWidget={true}
                    getQuery={this.getQuery!.bind(this)}
                    otherData={{
                        getMoreActions: this.getMoreActions.bind(this),
                    }}
                    init={this.tableInit!.bind(this)}
                    currentsHandler={this.currentsHandler.bind(this)}
                    currents={currents}
                />
            </Widget>
        );
    }

    componentDidMount(): void {
        const { user } = this.props;

        this.setState({ user });

        (document.addEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    render() {
        const { counter = 0 } = this.state;
        const { inWidget = true } = this.props;

        if (!inWidget) {
            return this.renderWidget();
        }

        return (
            <>
                <Widget
                    name="joinsContracts"
                    title="Подключение"
                    description="Договоры"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getHeadActions()}
                    isFull={true}
                    className="_innerPadding"
                >
                    {this.renderWidget()}
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Contracts);
