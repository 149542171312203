import React from 'react';

import WidgetDocs from '@components/crm/widgetDocs/WidgetDocs.tsx';
import getPermissions from '@functions/crm/getPermissions.ts';
import DocT from '@global/models/Doc';

import I from '../types';

const renderDocs: I['renderDocs'] = function () {
    const { model, error, user } = this.state;
    const docs = model?.docs || [];

    // const adminRules = getPermissions(user, {
    //     adminCorporation: true,
    //     key: 'manual',
    //     items: [{ key: 'executors', rules: ['update'] }],
    // });
    const editRules = getPermissions(user, {
        adminCorporation: !!(model?.verification.status === 'active'),
        key: 'manual',
        items: [{ key: 'executors', rules: ['update'] }],
    });
    const checkChange = (doc: DocT) =>
        editRules &&
        (user?.idOfCurrentCorporation === doc.corporationId ||
            user?.idOfCurrentCorporation === 'admin');

    return (
        <>
            <WidgetDocs
                docs={docs}
                change={this.change.bind(this)}
                addArrayItem={this.addArrayItem.bind(this)}
                deleteArrayItem={this.deleteArrayItem.bind(this)}
                error={error}
                checkChangeName={(doc: DocT) =>
                    editRules &&
                    (user?.idOfCurrentCorporation === 'admin' ||
                        (user?.idOfCurrentCorporation === doc.corporationId && doc.canDelete))
                }
                checkChange={checkChange}
                checkAddDocs={() => editRules}
                checkDeleteDoc={checkChange}
                setDoc={(resultDoc) => {
                    this.setDoc(resultDoc);
                }}
            />
        </>
    );
};

export default renderDocs;
