import { MouseEvent } from 'react';

import { ListenerT } from '@global/types.ts';

import CallPopupI from '../types.ts';

import { fieldsOrder } from '../static/fields.ts';

const init: CallPopupI['init'] = function () {
    const stateFields: CallPopupI['state']['fields'] = {};

    fieldsOrder.forEach((key) => {
        stateFields[key] = '';
    });

    this.setState({ fields: stateFields });

    setTimeout(() => {
        (document.addEventListener as ListenerT<MouseEvent>)('click', this.outClickHandler);
    }, 10);
};

export default init;
