import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';

import getActions from './methods/getActions.ts';

import MainI from './types.ts';

class Main extends WidgetWrapper<MainI['props'], MainI['state']> implements MainI {
    parent: MainI['parent'];

    constructor(props: MainI['props']) {
        super(props);
        this.state = {
            counter: 0,
        };

        this.parent = React.createRef();
    }

    getActions = getActions;

    render() {
        const { counter } = this.state;

        return (
            <>
                <Widget
                    name="executors"
                    title="Справочник"
                    description="Исполнители"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as MainI).getFilterQuery?.()}
                >
                    <Table
                        name="executors"
                        isBlockSkinny={true}
                        isBlock={true}
                        isNotLink={true}
                        isInit={true}
                        callback={this.tableCallback!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        getRowClassName={() => '_colCenter'}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Main);
