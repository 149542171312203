import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const setSign: I['setSign'] = async function ({ isAll }) {
    const { currents = [] } = this.state;
    const { type, executorId } = this.props;
    const resultItems = isAll ? ['all'] : currents.map(({ id }) => id);

    handlerPopup('contractSignPopup', {
        isShow: true,
        items: resultItems,
        successCallback: () => {
            this.setState({ currents: [] });
        },
        type,
        executorId,
    });
};

export default setSign;
