import React from 'react';

import Button from '@components/button/Button.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const renderGroupCards: I['renderGroupCards'] = function () {
    const { user, error } = this.state;
    const model = this.state.model!;
    const { inner } = this.props;
    const { lastReplenishmentDate } = model;
    const lastPay = lastReplenishmentDate
        ? getFormatedDate({ date: new Date(lastReplenishmentDate), isShortYear: true })
        : '–';
    const isAdminSettings = user?.idOfCurrentCorporation === 'admin' && !inner;

    return (
        <>
            <div className="v2widgetPage__cardInner">
                <div className="v2widgetCard">
                    <div className="v2widgetCard__content _full">
                        <div className="v2widgetCardInfo _COL">
                            <Link
                                className="v2widgetCardInfo__balance _col"
                                pageName={
                                    inner ? 'corporations-inner-operations' : 'settings-operations'
                                }
                                ids={inner ? { 2: model._id } : {}}
                            >
                                <i className="v2widgetCardInfo__balanceIcon">
                                    <Icon name="corporation-balance" />
                                </i>
                            </Link>
                            <div className="v2widgetCardInfo__support">Баланс компании</div>
                            <div className="v2widgetCardInfo__title">
                                {getFormatPrice(model.balance)} ₽
                            </div>
                            <div className="v2widgetCardInfo__description">
                                Последнее пополнение: {lastPay}
                            </div>
                            {!isAdminSettings && (
                                <div className="v2widgetCardInfo__button">
                                    <Button
                                        className="_mediumSize _main _shadow"
                                        onClick={() => {
                                            handlerPopup('corporationAmountDocPopup', {
                                                isShow: true,
                                                id: model._id,
                                            });
                                        }}
                                    >
                                        Пополнить Баланс
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="v2widgetPage__cardInner _other">
                <WidgetCard
                    model={model}
                    name="corporationOther"
                    change={this.change.bind(this)}
                    getValue={this.getValue.bind(this)}
                    error={error}
                    withHead={false}
                />
            </div>
        </>
    );
};

export default renderGroupCards;
