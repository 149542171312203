import React from 'react';

import I from '../types.ts';

const renderText: I['renderText'] = function () {
    const { text } = this.props;

    return (
        <>
            <div className="v2widgetField__field">
                <div
                    className="v2widgetField__text"
                    dangerouslySetInnerHTML={{ __html: text! }}
                ></div>
            </div>
        </>
    );
};

export default renderText;
