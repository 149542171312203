import axios from 'axios';

import getHeaders from '../functions/getHeaders';

export default function getUser({ id, params = [], signal }) {
    let query = '';

    if (id) {
        query += `id=${id}&`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve) => {
        axios
            .get(`${process.env.REACT_APP_API}/user?${query}`, {
                signal,
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    if (res?.data?.data && typeof res?.data?.data === 'object') {
                        const { success, data } = res.data;
                        const { users = [], user, item, isLimit, counter } = data;

                        if (success) {
                            resolve({
                                users,
                                user,
                                item,
                                isLimit,
                                counter,
                            });
                        }
                    }
                },
                () => null,
            );
    });
}
