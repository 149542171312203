import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getRealParams from '../functions/getRealParams.ts';
import checkValueOfEmpty from '../functions/checkValueOfEmpty';

class ListScroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerScroll = this.handlerScroll.bind(this);
    }

    isProccess = false;

    handlerScroll(isForced = false) {
        const {
            callback,
            lengthCurrent,
            stepCounter,
            maxCounter,
            handlerLoaderList,
            direction = 'vertical',
            isLimit,
            isDisabledScroll,
            isReverse,
            startCounter,
            hideCallback,
            callbackDuration = 300,
            otherItemsCounter = 0,
            offsetY = () => 0,
        } = this.props;

        const update = () => {
            this.isProccess = true;

            if (handlerLoaderList) {
                handlerLoaderList(true);
            }

            setTimeout(() => {
                callback(lengthCurrent + stepCounter - otherItemsCounter);
            }, callbackDuration);
        };

        const valScroll =
            (direction === 'horizontal' ? this.parent.scrollLeft : this.parent.scrollTop) +
            offsetY();
        const sizeScroll =
            direction === 'horizontal'
                ? this.parent.scrollWidth - this.parent.offsetWidth
                : this.parent.scrollHeight - this.parent.offsetHeight;

        if (
            !isLimit &&
            !this.isProccess &&
            lengthCurrent < maxCounter &&
            isDisabledScroll !== true
        ) {
            // console.log(-valScroll, sizeScroll);
            if ((isReverse ? -valScroll : valScroll) >= sizeScroll - 10 || isForced === true) {
                update();
            }
        }

        if (hideCallback && lengthCurrent > startCounter && !isDisabledScroll) {
            if ((!isReverse && valScroll <= 10) || (isReverse && valScroll >= sizeScroll - 10)) {
                hideCallback();
            }
        }
    }

    checkStateOfLessItems() {
        const {
            lengthCurrent,
            maxCounter,
            direction,
            idOfItemLast,
            classNamesForParams = [],
            classNameItem,
            isParentDocument,
        } = this.props;

        const update = () => {
            this.lengthCurrent = lengthCurrent;
            this.handlerScroll(true);
        };

        if (isParentDocument) {
            const itemLast = document.querySelector(`[data-id="${idOfItemLast}"]`);

            if (itemLast) {
                const query = {
                    parent: document.querySelector('.body'),
                    clearStyleElems: [classNameItem],
                    elem: `[data-id="${idOfItemLast}"]`,
                    classNames: ['_parentForce', ...classNamesForParams],
                    // isNotRemove: true,
                };

                const params = getRealParams(query);

                const { height: heightItem, getBoundingClientRect } = params;
                const offsetTop = getBoundingClientRect.y + heightItem + 200;

                if (offsetTop < document.documentElement.clientHeight) {
                    update();
                }
            }
        } else {
            const query = {
                parent: this.parent,
                width: this.parent.offsetWidth,
                height: this.parent.offsetHeight,
                isClearStyleParent: true,
                isClearStyles: true,
                classNames: ['_parentForce', ...classNamesForParams],
                // isNotRemove: true,
            };

            const params = getRealParams(query);
            const { width, height, scrollWidth, scrollHeight } = params;
            const sizeScroll =
                direction === 'horizontal' ? scrollWidth - width : scrollHeight - height;

            if (sizeScroll <= 0 && lengthCurrent < maxCounter) {
                update();
            }
        }
    }

    timerIdCheckState = null;

    checkUpdate() {
        const { lengthCurrent, handlerLoaderList, isDisabledScroll, keyUpdate, isLimit } =
            this.props;

        if (!isLimit && (!checkValueOfEmpty(isDisabledScroll) || isDisabledScroll !== true)) {
            if (lengthCurrent !== this.lengthCurrent) {
                this.lengthCurrent = lengthCurrent;
                this.isProccess = false;

                if (handlerLoaderList) {
                    handlerLoaderList(false);
                }

                if (this.timerIdCheckState) {
                    clearTimeout(this.timerIdCheckState);
                }

                setTimeout(() => {
                    this.checkStateOfLessItems();
                }, 10);
            } else if (keyUpdate !== this.keyUpdate) {
                this.keyUpdate = keyUpdate;

                if (this.timerIdCheckState) {
                    clearTimeout(this.timerIdCheckState);
                }

                setTimeout(() => {
                    this.checkStateOfLessItems();
                }, 10);
            } else if (isDisabledScroll !== this.isDisabledScroll) {
                this.isDisabledScroll = isDisabledScroll;

                if (handlerLoaderList) {
                    handlerLoaderList(false);
                }

                // setTimeout(() => {
                //     this.checkStateOfLessItems();
                // }, 10);
            }
        }

        if (isLimit !== this.isLimit) {
            this.isLimit = isLimit;
            this.isProccess = false;

            if (handlerLoaderList) {
                handlerLoaderList(false);
            }
        }

        if (keyUpdate !== this.keyUpdate) {
            this.keyUpdate = keyUpdate;

            if (handlerLoaderList) {
                handlerLoaderList(false);
            }
        }
    }

    isInit = false;

    init() {
        const {
            callback,
            startCounter,
            getParent,
            keyUpdate,
            isLimit,
            otherItemsCounter = 0,
        } = this.props;

        if (getParent() && !this.isInit) {
            this.isInit = true;
            this.parent = getParent();
            this.keyUpdate = keyUpdate;
            this.isLimit = isLimit;

            this.parent.addEventListener('scroll', this.handlerScroll);

            callback(startCounter - otherItemsCounter, true);

            this.lengthCurrent = startCounter;
        }
    }

    componentDidMount() {
        const { callbackDuration = 300 } = this.props;
        this.init();

        this.timerIdCheckState = setTimeout(() => {
            const { isDisabledScroll } = this.props;

            if (!isDisabledScroll) {
                this.checkStateOfLessItems();
            }
        }, callbackDuration);
    }

    componentDidUpdate() {
        this.init();
        this.checkUpdate();
    }

    componentWillUnmount() {
        const { handlerLoaderList } = this.props;
        this.parent?.removeEventListener('scroll', this.handlerScroll);

        if (handlerLoaderList) {
            handlerLoaderList(false);
        }
    }

    render() {
        const { children } = this.props;

        return <>{children}</>;
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ListScroll);

ListScroll.propTypes = {
    children: PropTypes.node,
    getParent: PropTypes.func,
    callback: PropTypes.func,
    startCounter: PropTypes.number,
    stepCounter: PropTypes.number,
    lengthCurrent: PropTypes.number,
    maxCounter: PropTypes.number,
    handlerLoaderList: PropTypes.func,
    direction: PropTypes.string,
    idOfItemLast: PropTypes.string,
    classNamesForParams: PropTypes.array,
    isDisabledScroll: PropTypes.bool,
    keyUpdate: PropTypes.any,
    isLimit: PropTypes.bool,
    classNameItem: PropTypes.string,
    isParentDocument: PropTypes.bool,
    isReverse: PropTypes.bool,
    hideCallback: PropTypes.func,
    callbackDuration: PropTypes.number,
    otherItemsCounter: PropTypes.number,
    offsetY: PropTypes.func,
};
