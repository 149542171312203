import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';

import FilterI from '../types.ts';

const renderButton: FilterI['renderButton'] = function () {
    const { filterQuery = [] } = this.state;
    const { isChange } = this.checkChange();

    return (
        <div className="v2filter__buttonWrapper">
            <AnimateChange
                className="v2filter__button"
                renderKey={isChange ? 1 : filterQuery.length ? 2 : null}
                styles={[]}
            >
                {isChange ? (
                    <>
                        <div className="v2filter__buttonInner">
                            <Button
                                className="_main _shadow"
                                onClick={() => {
                                    this.send(this.getChange());
                                }}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </>
                ) : filterQuery.length ? (
                    <>
                        <div className="v2filter__buttonInner">
                            <Button
                                className="_main _shadow"
                                onClick={() => {
                                    this.send([]);
                                }}
                            >
                                Сбросить
                            </Button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </AnimateChange>
        </div>
    );
};

export default renderButton;
