import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';
import Loader from './Loader.jsx';

class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { className, editName, handlerEditmode, name, isLoader } = this.props;

        return (
            <div
                className={`${className || ``} _editBtn ${editName === name ? '_isShow' : ''}`}
                onClick={() => {
                    if (!isLoader) {
                        handlerEditmode({ editName: editName === name ? null : name });
                    }
                }}
            >
                <div
                    className={`edit _click ${editName === name ? '_isShow' : ''} ${
                        isLoader ? '_isLoader' : ''
                    }`}
                >
                    <div className="edit__loader">
                        <Loader className="_main" />
                    </div>
                    <i className="edit__icon _done">
                        <Icon name="done" />
                    </i>
                    <i className="edit__icon _edit">
                        <Icon name="edit" />
                    </i>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Edit);

Edit.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    editName: PropTypes.string,
    handlerEditmode: PropTypes.func,
    isLoader: PropTypes.bool,
};
