const tags = {
    colsOrder: ['name', 'group', 'color', 'date'],
    cols: {
        name: {
            content: 'Название',
            width: (admin: boolean) => (admin ? 47 : 40),
            isAdminMain: true,
        },
        group: {
            content: 'Группа',
            width: 20,
        },
        color: {
            content: 'Цвет',
            width: 13,
        },
        date: {
            content: 'Дата создания',
            width: (admin: boolean) => (admin ? 20 : 27),
        },
    },
    deletesCols: [],
    url: 'tag',
    emptyInfo: {
        title: 'Тегов пока нет',
        description: 'Вы можете создать тег по кнопке справа',
    },
} as const;

export default tags;
