import axios from 'axios';

import getHeaders from '@functions/getHeaders';
import { ReqResponseT } from '@global/types';

type ParamsT = {
    inn?: string;
    bic?: string;
};

export default async function getDaData({ inn, bic }: ParamsT): Promise<Record<any, unknown>> {
    const query = inn ? `inn=${inn}` : bic ? `bic=${bic}` : '';
    let response;

    try {
        response = await axios.get<{}, { data: ReqResponseT<{ data: Record<any, unknown> }> }>(
            `${process.env.REACT_APP_API}/corporation?${query}`,
            {
                headers: getHeaders(),
            },
        );
    } catch (error) {
        return Promise.reject();
    }

    const { success, data } = response.data;

    if (success) {
        return data.data;
    }

    return Promise.reject({ message: data.message });
}
