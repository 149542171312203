import React from 'react';

import getFormatedDate from '@functions/getFormatedDate';
import PayGroupT from '@global/models/PayGroup';

export default function DateStr({ payGroup }: { payGroup: PayGroupT }): React.ReactNode {
    return (
        <div className="v2table__text">
            {getFormatedDate({ date: new Date(payGroup.createdDate!) })}
        </div>
    );
}
