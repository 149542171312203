import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField.tsx';
import Icon from '@components/icon/Icon.tsx';
import { setCursorActions } from '@functions/actionsHandler.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import DocT from '@global/models/Doc.ts';
import { CursorActionsItemT } from '@global/types.ts';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    const { updatedPropsKey } = this.state;
    const {
        doc,
        deleteHandler: docsDeleteHandler,
        change: propsChange,
        checkChange,
        checkDeleteDoc,
        setDoc,
    } = this.props;
    const { actionDate, canDelete } = doc;
    const actions: CursorActionsItemT[] = [];
    const canChange = !checkChange || checkChange(doc);
    const canDeleteDoc = !checkDeleteDoc || checkDeleteDoc(doc);

    if (!(doc as { isCreate?: boolean }).isCreate && setDoc && canChange) {
        actions.push({
            key: 'edit',
            name: 'edit',
            text: 'Детали',
            onClick: async () => {
                handlerPopup('docPopup', {
                    isShow: true,
                    id: doc._id,
                    setModel: ({ model }: { model: DocT }) => setDoc(model),
                });
            },
        });
    }

    if (canDelete && canDeleteDoc) {
        actions.push({
            key: 'delete',
            name: 'delete',
            text: 'Удалить',
            onClick: docsDeleteHandler,
        });
    }

    const props = setCursorActions(
        { _id: `doc-actions-${doc._id}`, dir: 'top', items: actions },
        { targetClassName: '.v2widgetDoc__more' },
    );
    const renderKey = (doc as any).version;

    return (
        <div className="v2widgetDoc__foot">
            <div className="v2widgetDoc__date">
                <WidgetField
                    model={doc}
                    support="Годен до"
                    name="actionDate"
                    prop="actionDate"
                    type="date"
                    reg="date"
                    cardName={`doc-${doc._id}`}
                    onChange={async (fields, update) => {
                        await propsChange({ ...fields });

                        if (update) {
                            this.setState({ updatedPropsKey: new Date().getTime() });
                        }
                    }}
                    setDate={({ date }) => getFormatedDate({ date: new Date(date) })}
                    withTime={false}
                    value={actionDate}
                    updatedKey={`${updatedPropsKey}${renderKey}`}
                    disabled={!canChange}
                />
            </div>
            {actions.length > 0 && (
                <div className="v2widgetDoc__more _CLICK _COL" {...props}>
                    <div className="v2widgetDoc__moreIcon">
                        <Icon name="more-vertical" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default renderFoot;
