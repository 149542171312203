import ListAbsoluteMainI from '../types.ts';

const checkChangeCurrentKey: ListAbsoluteMainI['checkChangeCurrentKey'] = function () {
    const { currentItemKey } = this.props;

    if (this.currentItemKey !== currentItemKey) {
        this.currentItemKey = currentItemKey;

        setTimeout(() => {
            this.setState({ currentItemKey });
        }, 10);
    }
};

export default checkChangeCurrentKey;
