import React from 'react';

import getFormatedDate from '@functions/getFormatedDate.ts';
import TagT from '@global/models/Tag.ts';

import { RenderColsT } from '../../types.ts';

const renderTags: RenderColsT<'tags'> = function ({ name: colName, item }) {
    let content;
    let className;
    const tag = item as TagT;

    if (colName === 'name') {
        content = <>{tag.name}</>;
    }

    if (colName === 'group') {
        content = <>{tag.groupName}</>;
    }

    if (colName === 'color') {
        content = (
            <>
                <div className="v2table__color" style={{ background: tag.color }}></div>
            </>
        );
    }

    if (colName === 'date') {
        content = <>{getFormatedDate({ date: new Date(tag.createdDate) })}</>;
    }

    return { content, className };
};

export default renderTags;
