import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup';

import GroupsMainI from '../types.ts';

const getActions: GroupsMainI['getActions'] = function (this: GroupsMainI) {
    const { user } = this.state;
    const actions = [
        {
            type: 'filter',
            filterName: 'paysGroups',
            name: 'filter',
            group: 'actions',
        },
    ] as WidgetActionsT[];

    if (
        getPermissions(user, {
            key: 'pays',
            items: [{ key: 'acts', rules: ['create'] }],
        })
    ) {
        actions.push({
            type: 'link',
            name: 'link',
            group: 'link',
            content: 'Загрузить пачку актов',
            className: '_main',
            onClick: async () => {
                handlerPopup('payGroupUpload', { isShow: true });
            },
        });
    }

    return actions;
};

export default getActions;
