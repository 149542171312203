import CalendarI from '../types.ts';

const checkChange: CalendarI['checkChange'] = function () {
    const { updatedKey } = this.props;

    if (updatedKey !== this.updatedKey) {
        this.updatedKey = updatedKey;

        this.setState(
            {
                currentDate: null,
                currentDateText: '',
                doubleCurrentDate: null,
                doubleCurrentDateText: '',
            },
            () => {
                this.init();
            },
        );
    }
};

export default checkChange;
