import ListAbsoluteMainI from '../types.ts';

const renderItem: ListAbsoluteMainI['renderItem'] = function ({ ...props }) {
    const { item: itemElem, prop, ...otherProps } = props;
    const { items, renderItem: propsRenderItem } = this.props;

    return this.renderFullItem({
        itemElem,
        prop,
        ...otherProps,
        item: propsRenderItem({ prop, item: itemElem, ...otherProps }, items),
    });
};

export default renderItem;
