import I from '../types.ts';

const setHeight: I['setHeight'] = function () {
    if (this.ref) {
        const inner = this.ref.querySelector('.v2popup__contentInner') as HTMLElement;

        if (inner) {
            this.setState({ innerHeight: inner.offsetHeight });
        }
    }
};

export default setHeight;
