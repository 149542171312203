import React from 'react';

import save from './methods/save.ts';

import WidgetPageI from './types.ts';

import renderFoot from './renders/renderFoot.tsx';

class WidgetPage
    extends React.Component<WidgetPageI['props'], WidgetPageI['state']>
    implements WidgetPageI
{
    parent: WidgetPageI['parent'];

    constructor(props: WidgetPageI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    save = save;

    renderFoot = renderFoot;

    render() {
        const { children, name, className = '' } = this.props;

        return (
            <div className={`v2widgetPage _${name} ${className}`}>
                <div className="v2widgetPage__scroll _NOSCROLL">{children}</div>
                {this.renderFoot()}
            </div>
        );
    }
}

export default WidgetPage;
