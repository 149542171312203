import getAllNodes from './getAllNodes';
import getSiblingTextNode from './getSiblingTextNode';

export default function getSelection({ isInline }) {
    if (this.selection) {
        let { startContainer, startOffset, endContainer, endOffset } = this.selection;

        if (isInline) {
            if (startContainer !== endContainer) {
                if (startOffset !== 0) {
                    const leftNode = document.createTextNode(
                        startContainer.nodeValue.slice(0, startOffset),
                    );
                    const rightNode = document.createTextNode(
                        startContainer.nodeValue.slice(startOffset),
                    );
                    const wrapper = document.createElement('span');

                    wrapper.appendChild(leftNode);
                    wrapper.appendChild(rightNode);

                    startContainer.replaceWith(...wrapper.childNodes);

                    startContainer = rightNode;
                    startOffset = 0;
                }

                if (endContainer.nodeValue && endOffset !== endContainer.nodeValue.length) {
                    const leftNode = document.createTextNode(
                        endContainer.nodeValue.slice(0, endOffset),
                    );
                    const rightNode = document.createTextNode(
                        endContainer.nodeValue.slice(endOffset),
                    );
                    const wrapper = document.createElement('span');

                    wrapper.appendChild(leftNode);
                    wrapper.appendChild(rightNode);

                    endContainer.replaceWith(...wrapper.childNodes);

                    endContainer = leftNode;
                    endOffset = leftNode.nodeValue.length;
                }
            } else if (
                startOffset !== 0 ||
                (startContainer.nodeValue && endOffset !== startContainer.nodeValue.length)
            ) {
                const wrapper = document.createElement('span');

                if (startOffset !== 0) {
                    const leftNode = document.createTextNode(
                        startContainer.nodeValue.slice(0, startOffset),
                    );

                    wrapper.appendChild(leftNode);
                }

                const middleNode = document.createTextNode(
                    startContainer.nodeValue.slice(startOffset, endOffset),
                );

                wrapper.appendChild(middleNode);

                if (endOffset !== startContainer.nodeValue.length) {
                    const rightNode = document.createTextNode(
                        startContainer.nodeValue.slice(endOffset),
                    );

                    wrapper.appendChild(rightNode);
                }

                startContainer.replaceWith(...wrapper.childNodes);

                startContainer = middleNode;
                startOffset = 0;
                endContainer = middleNode;
                endOffset = middleNode.nodeValue.length;
            }
        }

        if (startContainer.nodeType !== 3) {
            const textNodes = getAllNodes(startContainer, undefined, true);

            if (textNodes.length) {
                startContainer = textNodes[0];
            } else {
                startContainer = getSiblingTextNode({
                    node: startContainer,
                    dir: 'next',
                    withBr: true,
                });
            }
        }

        if (endContainer.nodeType !== 3) {
            const textNodes = getAllNodes(endContainer, undefined, true);

            if (textNodes.length) {
                endContainer = textNodes[textNodes.length - 1];
            } else {
                endContainer = getSiblingTextNode({
                    node: endContainer,
                    dir: 'prev',
                    withBr: true,
                });
            }
        }

        // console.log('startContainer', startContainer);
        // console.log('endContainer', endContainer);

        return { startContainer, startOffset, endContainer, endOffset };
    }

    return {};
}
