import I from '../types.ts';

const getQuery: I['getQuery'] = function (this: I) {
    const { user, inner, levels } = this.props;
    const id = inner ? levels[2] : user?.idOfCurrentCorporation;

    if (id) {
        return [{ key: 'id', value: id }];
    }

    return [];
};

export default getQuery;
