import handlerPopup from '@functions/handlerPopup.ts';

import Interface from '../types.ts';

const getActions: Interface['getActions'] = function () {
    return [
        {
            type: 'link',
            name: 'add',
            group: 'links',
            className: '_main',
            content: 'Добавить роль',
            onClick: async () => {
                handlerPopup('rolePopup', {
                    isShow: true,
                });
            },
        },
        {
            type: 'filter',
            name: 'filter',
            group: 'filter',
            filterName: 'corporationRoles',
        },
    ];
};

export default getActions;
