import getRealParams from './getRealParams.ts';
import setAnimate from './setAnimate';

export default function scrollToPosition({ position, parent, classNameElem, offset = 0 }) {
    const { scrollTop: scrollCards } = parent;
    let scrollCard;

    if (position === 'center') {
        const params = getRealParams({
            parent,
            elem: classNameElem,
            width: parent.offsetWidth,
            // isNotRemove: true,
        });

        const { height: sizeCards, scrollHeight: sizeScroll, scrollTop } = params.parent;
        const { height: sizeCard } = params;
        const offsetCard = params.getBoundingClientRect.y - params.parent.getBoundingClientRect.y;
        const scrollBreak = sizeScroll - sizeCards - scrollTop;

        scrollCard = Math.round(sizeCards / 2 - sizeCard / 2 - offsetCard);

        scrollCard += offset / 2;

        if (-scrollCard > scrollBreak) {
            scrollCard = -scrollBreak;
        }

        if (scrollCard > scrollTop) {
            scrollCard = scrollTop;
        }
    }

    if (position === 'top') {
        scrollCard = scrollCards;
    }

    setAnimate({
        draw: (progress) => {
            parent.scrollTo({
                top: scrollCards - progress * scrollCard,
            });
        },
        duration: 300,
    });
}