import I from '../types.ts';

const setDoc: I['setDoc'] = function (doc) {
    this.setState(
        (state) => {
            const newState = { ...state };
            const model = JSON.parse(JSON.stringify(newState.model)) as NonNullable<
                I['state']['model']
            >;
            const { docs } = model;

            if (docs) {
                const indexDoc = docs.findIndex((item) => item._id === doc._id);

                if (indexDoc !== -1) {
                    model.docs[indexDoc] = doc;
                }
            }

            newState.savedModel = JSON.parse(
                JSON.stringify({ ...this.state.savedModel, docs: model.docs }),
            );

            newState.model = model;
            newState.updatedKey = new Date().getTime();

            return newState;
        },
        () => {
            document.dispatchEvent(
                new CustomEvent('updateDynamicList', { detail: { id: 'corporation-docs' } }),
            );
        },
    );
};

export default setDoc;
