import JoinInvitesI from '../types.ts';

const getQuery: JoinInvitesI['getQuery'] = function (this: JoinInvitesI) {
    const { currentTab } = this.state;

    if (currentTab && currentTab !== 'all') {
        return [{ key: 'group', value: currentTab }];
    }

    return [];
};

export default getQuery;
