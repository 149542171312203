import getAllNodes from './getAllNodes';

export default function getRangeNodes({ startContainer, endContainer, withBr }) {
    const rangeNodes = [];
    let nextNode = startContainer;

    const checkNode = (node) => node.nodeType === 3 || (withBr && node.nodeName === 'BR');

    if (startContainer) {
        while (nextNode && nextNode !== endContainer) {
            if (!checkNode(nextNode)) {
                let allNodes = getAllNodes(nextNode, undefined, withBr);
                const startNodeIndex = allNodes.indexOf(startContainer);

                if (startNodeIndex !== -1) {
                    allNodes = allNodes.filter((item, itemKey) => itemKey > startNodeIndex);
                }

                const textNodes = allNodes.filter((innerNode) => !rangeNodes.includes(innerNode));

                if (textNodes.length) {
                    nextNode = textNodes[0];
                } else {
                    nextNode = nextNode.parentNode;
                }
            } else if (nextNode.nextSibling && checkNode(nextNode.nextSibling)) {
                rangeNodes.push(nextNode);

                nextNode = nextNode.nextSibling;
            } else if (!nextNode.nextSibling) {
                rangeNodes.push(nextNode);

                nextNode = nextNode.parentNode;
            } else {
                rangeNodes.push(nextNode);

                nextNode = nextNode.nextSibling;
            }
        }

        if (checkNode(endContainer)) {
            rangeNodes.push(endContainer);
        }
    }

    return rangeNodes;
}
