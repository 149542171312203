import { ModelsT } from '../models';

const executorTypes: ModelsT = {
    url: 'executorTypes',
    logName: 'executorType',
    title: 'Справочник',
    parentPage: 'manual-executorTypes',
    tablePage: 'manual-executorTypes-main',
    tableName: 'executorTypes',
    modelPage: 'manual-executorTypes-inner',
    deleteAlertPopup: 'deletetExecutorType',
    deleteDeniedAlertPopup: 'deletetExecutorTypeDenied',
    descriptions: {
        table: 'Категории исполнителей',
        model: {
            new: 'Новая категория',
            default: 'Категория',
        },
    },
    addButton: 'Добавить категорию',
    cardName: 'executorTypeMain',
};

export default executorTypes;
