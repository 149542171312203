import I from '../types.ts';

const inputHandler: I['inputHandler'] = function ({ key, value }) {
    const { count, callback } = this.props;

    const resultValue = value.replace(/[^\d]/gi, '');

    this.setState(
        (state) => {
            const newState = { ...state };
            const inputs = { ...newState.inputs };
            let currentKey = key + (resultValue === '' ? -1 : resultValue.split('').length);

            if (currentKey < 0) {
                currentKey = 0;
            }

            if (currentKey > count - 1) {
                currentKey = count - 1;
            }

            if (resultValue === '') {
                inputs[key] = '';
            } else {
                resultValue.split('').forEach((char, index) => {
                    if (key + index <= count - 1) {
                        inputs[key + index] = char;
                    }
                });
            }

            const item = this.parent.current?.querySelector(
                `.v2codeInputs__item[data-key="${currentKey}"]`,
            ) as HTMLElement;

            if (item) {
                const input = item.querySelector('input');

                if (input) {
                    input.focus();
                }
            }

            newState.inputs = inputs;

            return newState;
        },
        () => {
            const code = Object.keys(this.state.inputs)
                .map((innerKey) => this.state.inputs[+innerKey])
                .join('');

            if (code.length === count) {
                callback(code);
            }
        },
    );
};

export default inputHandler;
