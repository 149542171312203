import MobPopupI from '../types.ts';

const handlerMove: MobPopupI['handlerMove'] = function (e) {
    const inner = this.getInner();

    if (!inner) {
        return;
    }

    if (this.isProccess) {
        this.moveY = this.startY - e.changedTouches[0].pageY + this.lastY;

        if (Math.abs(this.moveY) > 3 && this.deltaY === null) {
            this.deltaY = this.moveY < 0 ? -1 : 1;
        }

        if (this.getScroll() < 3 && this.deltaY === -1) {
            e.preventDefault();

            inner.classList.add('_isDrag');

            this.move();
        }
    }
};

export default handlerMove;
