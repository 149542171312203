import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import save from './methods/save.ts';
import textMessageHandler from './methods/textMessageHandler.ts';

import AlertI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderDesktop from './renders/renderDesktop.tsx';
import renderMobile from './renders/renderMobile.tsx';
import contents from './static/contents.ts';

class AlertPopup extends EditBlock<AlertI['props'], AlertI['state']> implements AlertI {
    callback: AlertI['callback'];
    successCallback: AlertI['successCallback'];

    constructor(props: AlertI['props']) {
        super(props);
        this.state = {
            data: {},
        };
    }

    contents = contents;

    save = save;
    close = close;

    textMessageHandler = textMessageHandler;

    renderDesktop = renderDesktop;
    renderMobile = renderMobile;
    renderContent = renderContent;

    componentDidMount() {
        const { alertPopup } = this.props;
        const { type, callback, successCallback, ...props } = alertPopup;

        if (typeof callback === 'function') {
            this.callback = callback;
        }

        if (typeof successCallback === 'function') {
            this.successCallback = successCallback;
        }

        this.setState({ type, ...(props as any) });

        this.init({ fields: {} });
    }

    render() {
        return (
            <>
                {this.renderDesktop()}
                {this.renderMobile()}
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        alertPopup: state.alertPopup,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(AlertPopup);
