const corporationRoles = {
    colsOrder: ['name', 'usersCounter', 'actions'],
    cols: {
        name: { content: 'Название', width: 55 },
        usersCounter: { content: 'Сотрудники', width: 20 },
        actions: { content: 'Действие', width: 25 },
    },
    deletesCols: [],
    url: 'role',
    emptyInfo: {
        title: 'Ролей пока нет',
    },
} as const;

export default corporationRoles;
