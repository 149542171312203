import I from '../types.ts';

const updateWallets: I['updateWallets'] = async function (wallet) {
    const { setModel } = this.props;

    setModel(wallet);

    await this.init({ fields: wallet });
};

export default updateWallets;
