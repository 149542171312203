import I from '../types.ts';

const checkScroll: I['checkScroll'] = function () {
    const scrollParent = this.getScrollParent();

    if (scrollParent) {
        const scrollAreaHeight = scrollParent.scrollHeight - scrollParent.offsetHeight;

        this.setState({ hasScroll: scrollAreaHeight > 0 });
    }
};

export default checkScroll;
