import React from 'react';
import { connect } from 'react-redux';

import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';

import save from './methods/save.ts';
import setHeightDocs from './methods/setHeightDocs.ts';

import InnerMainI from './types.ts';

import renderDoc from './renders/renderDoc.tsx';
import renderDocsBlock from './renders/renderDocsBlock.tsx';
import renderMainBlock from './renders/renderMainBlock.tsx';

class InnerMain
    extends WidgetPageWrapper<InnerMainI['props'], InnerMainI['state']>
    implements InnerMainI
{
    parent: InnerMainI['parent'];

    constructor(props: InnerMainI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    setHeightDocs = setHeightDocs;

    renderMainBlock = renderMainBlock;
    renderDocsBlock = renderDocsBlock;

    renderDoc = renderDoc;

    save = save;

    mountCallback() {
        this.setHeightDocs();
    }

    render() {
        const { model } = this.state;

        return (
            <>
                <div className="v2widgetJoinScript" ref={this.parent}>
                    {model && (
                        <div className="v2widgetJoinScript__inner _ROW">
                            {this.renderMainBlock()}
                            {this.renderDocsBlock()}
                        </div>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(InnerMain);
