import React from 'react';
import { connect } from 'react-redux';

import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';
import { StoreT } from '@global/types.ts';

import deleteWallet from './methods/deleteWallet.ts';
import updateWallets from './methods/updateWallets.ts';

import InnerWalletsI from './types.ts';

import renderWallet from './renders/renderWallet.tsx';
import renderWalletsCard from './renders/renderWalletsCard.tsx';

class InnerWallets
    extends WidgetPageWrapper<InnerWalletsI['props'], InnerWalletsI['state']>
    implements InnerWalletsI
{
    parent: InnerWalletsI['parent'];

    constructor(props: InnerWalletsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderWalletsCard = renderWalletsCard;
    renderWallet = renderWallet;

    updateWallets = updateWallets;
    deleteWallet = deleteWallet;

    render() {
        const { model } = this.state;

        return (
            <>
                <WidgetPage name="executorsWallets">
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__card _main">
                                    {this.renderWalletsCard()}
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(InnerWallets);
