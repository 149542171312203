import ItemsI from '../types.ts';

const updateFilter: ItemsI['updateFilter'] = function (this: ItemsI, { key, value }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state };
            const filterQuery = [...(newState.filterQuery || [])];

            let index = filterQuery.findIndex((item) => item.key === key);

            if (index !== -1 && !value) {
                filterQuery.splice(index, 1);
            } else {
                if (index === -1) {
                    filterQuery.push({ key, value });

                    index = filterQuery.length - 1;
                }

                filterQuery[index].value = value;
            }

            newState.filterQuery = filterQuery;

            return newState;
        }, resolve);
    });
};

export default updateFilter;
