import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const sign: I['sign'] = async function (action) {
    const { code, isAutoPay, hash } = this.state;
    const { paySignPopup } = this.props;
    const { groupId, items = [], isSign, successCallback, filterQuery = [] } = paySignPopup;
    const query = filterQuery.map((item) => `${item.key}=${item.value}`).join('&');
    let response;

    await handlerLoading.call(this, action === 'send' ? 'code' : 'items');

    try {
        response = await axios.put<{}, { data: ReqResponseT<{ hash?: string; message?: string }> }>(
            `${process.env.REACT_APP_HARD_API}/pays?${query}`,
            {
                groupId,
                itemsIds: items,
                isForce: true,
                isSign,
                notCompleted: items[0] === 'all',
                ...(action === 'send' ? { code, isAutoPay, hash } : {}),
            },
            { headers: getHeaders() },
        );
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    await handlerLoading.call(this, undefined);

    const { success, data } = response.data;

    if (success) {
        if (action === 'send') {
            this.close();

            if (typeof successCallback === 'function') {
                successCallback();
            }
        } else {
            const { hash: resultHash } = data;

            this.setState({ hash: resultHash, currentBlock: 'code' });
        }

        if (action === 'again') {
            setNotification({ notification: 'success-send-code' });
        }

        return;
    }

    const { message } = data;

    if (message === 'Code is incorrect') {
        setNotification({ notification: 'error-auth-code' });
    } else if (message === 'Phone is incorrect') {
        setNotification({ notification: 'error-corporation-phone' });
    } else if (message === 'Not enough amount') {
        setNotification({ notification: 'error-corporation-balance' });
    } else if (message === 'Error balance limit') {
        setNotification({
            notification: 'error-corporation-balance-limit',
        });
    }
};

export default sign;
