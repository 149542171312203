import getAllNodes from './getAllNodes';

export default function getSiblingTextNode({ node, dir = 'next', withBr }) {
    const editor = document.querySelector('.editor__areaInner');
    const prop = dir === 'next' ? 'nextSibling' : 'previousSibling';

    if (node[prop] && node[prop].nodeType === 3) {
        return node[prop];
    }

    if (node[prop]) {
        const allTextNodes = getAllNodes(node[prop], undefined, withBr);

        if (allTextNodes.length) {
            const needNode = allTextNodes[dir === 'next' ? 0 : allTextNodes.length - 1];

            return needNode;
        }

        return getSiblingTextNode({ node: node[prop], dir, withBr });
    }

    if (node.parentNode === editor) {
        return null;
    }

    return getSiblingTextNode({ node: node.parentNode, dir, withBr });
}
