import React from 'react';

import getFieldWidth from './methods/getFieldWidth.ts';
import setUpdatedKeys from './methods/setUpdatedKeys.ts';

import WidgetCardI from './types.ts';

import renderCards from './renders/cards/renderCards.tsx';
import renderContent from './renders/renderContent.tsx';
import renderFields from './renders/renderFields.tsx';
import renderHead from './renders/renderHead.tsx';
import cards from './static/cards.ts';

class WidgetCard
    extends React.Component<WidgetCardI['props'], WidgetCardI['state']>
    implements WidgetCardI
{
    parent: WidgetCardI['parent'];

    constructor(props: WidgetCardI['props']) {
        super(props);
        this.state = {
            updatedKeys: {},
        };

        this.parent = React.createRef();
    }

    cards = cards;

    getFieldWidth = getFieldWidth;
    setUpdatedKeys = setUpdatedKeys;

    renderHead = renderHead;
    renderContent = renderContent;
    renderCards = renderCards;
    renderFields = renderFields;

    render() {
        const { name, className = '' } = this.props;

        return (
            <div ref={this.parent} className={`v2widgetCard _${name} ${className}`}>
                {this.renderHead()}
                {this.renderContent()}
            </div>
        );
    }
}

export default WidgetCard;
