import React from 'react';

import getEndText from '@functions/getEndText.ts';
import ExecutorTypeT from '@global/models/ExecutorType.ts';

import { RenderColsT } from '../../types.ts';

const renderExecutorTypes: RenderColsT<'executorTypes'> = function ({ name: colName, item }) {
    let content;
    let className;
    const executorType = item as ExecutorTypeT;

    if (colName === 'name') {
        content = <>{executorType.name}</>;
    }

    if (colName === 'executorsCounter') {
        const counter = executorType.executorsCounter || 0;

        content =
            counter === 0 ? (
                '–'
            ) : (
                <>
                    {counter} {getEndText(counter, ['исполнитель', 'исполнителя', 'исполнителей'])}
                </>
            );
    }

    return { content, className };
};

export default renderExecutorTypes;
