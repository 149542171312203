import removeTransition from '@functions/removeTransition.ts';

import WidgetI from '../types.ts';

const setSize: WidgetI['setSize'] = function () {
    const { inWidget } = this.props;

    if (inWidget) {
        const widget = this.parent.current as HTMLElement;
        const head = widget.querySelector('.v2widget__head') as HTMLElement;
        const content = widget.querySelector('.v2widget__content') as HTMLElement;

        if (head && content) {
            const height = widget.offsetHeight - head.offsetHeight;

            removeTransition({ item: '.v2widget__content', isCurrent: true });

            content.style.height = `${height}px`;
        }
    }
};

export default setSize;
