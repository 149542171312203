import MobPopupI from '../types.ts';

const sizeInit: MobPopupI['sizeInit'] = function () {
    const { initCallback } = this.props;

    if (!this.isInit) {
        this.moveY = -this.getDownBreak(true);

        this.move(true, true);
    }

    setTimeout(() => {
        if (!this.isInit) {
            this.setMaxHeight();
            this.checkChangeDisabled();

            this.moveY = -this.getDownBreak();

            this.move(true);

            setTimeout(() => {
                if (typeof initCallback === 'function') {
                    initCallback();
                }
            }, 300);
        }

        this.checkShow();
    }, 10);
};

export default sizeInit;
