import ListAbsoluteMainI from '../types.ts';

const handlerResize: ListAbsoluteMainI['handlerResize'] = function () {
    const { resizeParent } = this.props;

    if (resizeParent) {
        if (this.observer) {
            this.observer.disconnect();
        }

        this.observer = new ResizeObserver(() => {
            if ((resizeParent as HTMLElement).offsetWidth !== this.resizeWidth) {
                const resizeWidth = (resizeParent as HTMLElement).offsetWidth;

                this.resizeWidth = resizeWidth;

                setTimeout(() => {
                    this.setState({
                        keyResize: new Date().getTime(),
                    });
                }, 10);
            }
        });

        this.observer.observe(resizeParent as HTMLElement);
    }
};

export default handlerResize;
