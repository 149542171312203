import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

class SupportCursor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { supportCursor } = this.props;

        if (!supportCursor) {
            return null;
        }

        const { className, content } = supportCursor;

        return (
            supportCursor.show === true && (
                <div
                    className={`body__supportCursor ${
                        supportCursor.showAnimate === true ? '_show' : ''
                    }`}
                >
                    <div className="body__supportCursorInner">
                        <div
                            className={`supportCursor _col ${className || ''}`}
                            dangerouslySetInnerHTML={{
                                __html: content,
                            }}
                        ></div>
                    </div>
                </div>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        supportCursor: state.supportCursor,
    };
}

export default connect(mapStateToProps)(SupportCursor);

SupportCursor.propTypes = {
    supportCursor: PropTypes.object,
};
