import React from 'react';

const cards = [
    {
        category: 'ПОДКЛЮЧЕНИЕ ИСПОЛНИТЕЛЕЙ',
        title: 'Оформите исполнителя всего за 5 минут',
        content:
            'Настройте сценарий подключения. Укажите&!nbsp;документы, которые вам необходимы для оформления самозанятого и загрузите ваш договор. Система поможет удаленно подключить исполнителя, и вы всегда будете видеть его статус.',
        decor() {
            return (
                <>
                    <div className="publicBusinessCases__blockDecorItem _1">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-1.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                    <div className="publicBusinessCases__blockDecorItem _2">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-2.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                </>
            );
        },
    },
    {
        category: 'ДОКУМЕНТООБОРОТ',
        title: 'Оцифруем документы',
        content:
            'Система автоматически сформирует и подпишет все необходимые документы онлайн с помощью ПЭП. Таким образом, вы можете забыть о бумажных документах и быть уверены, что ваша бухгалтерия ничего не потеряет.',
        decor() {
            return (
                <>
                    <div className="publicBusinessCases__blockDecorItem _1">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-3.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                    <div className="publicBusinessCases__blockDecorItem _2">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-4.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                </>
            );
        },
    },
    {
        category: 'ВЫПЛАТЫ И НАЛОГИ',
        title: 'Платите исполнителям просто и безопасно',
        content:
            'Проверим статус самозанятого и лимит по доходу перед выплатами. Осуществим оплату каждого акта или целого реестра согласно вашему графику платежей.',
        decor() {
            return (
                <>
                    <div className="publicBusinessCases__blockDecorItem _1">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-5.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                    <div className="publicBusinessCases__blockDecorItem _2">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-6.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                </>
            );
        },
    },
    {
        category: 'САМОЗАНЯТЫМ',
        title: 'Налоговая копилка для ваших исполнителей',
        content:
            'CRM LIVECARGO является официальным партнером ФНС — оператором электронной площадки. Это значит, что сервис аккредитован и имеет право удерживать и оплачивать налог за самозанятого. С каждой выплаты, которую исполнитель получает через сервис, мы откладываем средства и оплачиваем налог.',
        decor() {
            return (
                <>
                    <div className="publicBusinessCases__blockDecorItem _1">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-7.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                    <div className="publicBusinessCases__blockDecorItem _2">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-8.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                    <div className="publicBusinessCases__blockDecorItem _3">
                        <img
                            src={require('../../../../../../../img/crm/public/business/cases-image-9.jpg')}
                            alt=""
                            className="publicBusinessCases__blockDecorImage"
                        />
                    </div>
                </>
            );
        },
    },
] as const;

export default cards;
