import handlerPopup from '@functions/handlerPopup.ts';

import MainI from '../types.ts';

const setPays: MainI['setPays'] = async function ({ isSign, isAll }) {
    const { groupId } = this.props;
    const { currents = [] } = this.state;
    const filterQuery = this.getFilterQuery!();

    handlerPopup('paySignPopup', {
        isShow: true,
        groupId,
        items: isAll ? ['all'] : currents.map(({ id }) => id),
        isSign,
        filterQuery,
        successCallback: () => {
            this.setState({ currents: [] });

            if (isAll) {
                this.tableUpdateItems!(true);
            }
        },
    });
};

export default setPays;
