import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const rotate: I['rotate'] = async function () {
    const { files = [], currentId } = this.state;
    const currentFile = files.find((file) => file._id === currentId)!;

    const response = await axios.patch<{}, { data: ReqResponseT<{}> }>(
        `${process.env.REACT_APP_API}/image`,
        { src: currentFile.src },
        { headers: getHeaders() },
    );

    const { success } = response.data;

    if (success) {
        this.setState((state) => {
            const newState = { ...state };
            const resultFiles = [...(newState.files || [])];
            const fileIndex = resultFiles.findIndex((item) => item._id === currentId);

            if (fileIndex !== -1) {
                resultFiles[fileIndex].key = new Date().getTime();
            }

            newState.files = resultFiles;

            return newState;
        });

        return;
    }

    return Promise.reject();
};

export default rotate;
