import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import UploadJoinsPopup from './UploadJoinsPopup.jsx';

class UploadOrdersPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { uploadJoinsPopup } = this.props;

        return (
            <Animate className="body__uploadOrdersPopup" isShow={uploadJoinsPopup.isShow}>
                <UploadJoinsPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        uploadJoinsPopup: state.uploadJoinsPopup,
    };
}

export default connect(mapStateToProps)(UploadOrdersPopupWrapper);

UploadOrdersPopupWrapper.propTypes = {
    uploadJoinsPopup: PropTypes.object,
};
