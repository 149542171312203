import React from 'react';

import Calendar from '@components/calendar/Calendar.tsx';
import Popup from '@components/popup/Popup.tsx';
import handlerPopup from '@functions/handlerPopup.ts';

import CalendarI from '../types.ts';

const renderContent: CalendarI['renderContent'] = function () {
    const { calendarPopup } = this.props;
    const { startDate, startDoubleDate, callback, withPast, isDouble, withTime } = calendarPopup;

    return (
        <Popup
            name="calendarPopup"
            close={() => {
                handlerPopup('calendarPopup', { isShow: false });
            }}
        >
            <div className="v2popup__content _padding _COL _CENTER _NOSCROLL">
                <Calendar
                    callback={callback}
                    startDate={startDate}
                    startDoubleDate={startDoubleDate}
                    withPast={withPast}
                    isDouble={isDouble}
                    withTime={withTime}
                />
            </div>
        </Popup>
    );
};

export default renderContent;
