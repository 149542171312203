import React from 'react';

import Loader from '@components/loader/Loader.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';
import { monthNames } from '@functions/getFormatedDate.ts';
import CorporationReportT from '@global/models/CorporationReport.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderCorporationReports: RenderColsT<'corporationReports'> = function ({
    name: colName,
    item,
}) {
    const report = item as CorporationReportT;
    let content;
    let className;

    if (colName === 'period') {
        content = (
            <>
                <div className="v2table__text">
                    <b>
                        {monthNames[report.month][0]} {report.year}
                    </b>
                </div>
            </>
        );
    }

    if (colName === 'doc') {
        content = (
            <>
                {item.fileName ? (
                    <>
                        <a
                            className="v2table__text _click _link _full"
                            target="_blank"
                            href={`${process.env.REACT_APP_STATIC}/corporations-reports/${item.fileName}`}
                            rel="noreferrer"
                        >
                            Отчёт №{report.number} CRM LIVECARGO
                        </a>
                    </>
                ) : (
                    <>
                        <div className="v2table__text _loading">
                            Формируем отчёт
                            <div className="v2table__textLoader">
                                <Loader className="_grey" />
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }

    if (colName === 'amount') {
        content = <>{getFormatPrice(report.amount)} ₽</>;
    }

    if (colName === 'status') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            report: CorporationReportT;
        }) => CursorActionsItemT[];

        let statusClassName = '_grey';

        if (item.status === 'sign') {
            statusClassName = '_successColor';
        }

        className = '_innerRow';

        content = (
            <>
                <div className={`v2table__text _mediumWeight ${statusClassName}`}>
                    {report.statusText}
                </div>
                <More id={`report-${report._id}`} items={getMoreActions({ report })} />
            </>
        );
    }

    return { content, className };
};

export default renderCorporationReports;
