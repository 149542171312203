import React from 'react';

import { ListItemT } from '@components/listDynamic/types.ts';
import { RealParamsItemT } from '@functions/getRealParams.ts';
import { s } from '@functions/seo.ts';

import ListAbsoluteMainI from '../types.ts';

const renderFullItem: ListAbsoluteMainI['renderFullItem'] = function ({
    itemElem,
    prop,
    item,
    saveItems,
    ...props
}) {
    const { currentItemKey } = this.state;
    const {
        itemParams = ['offsetLeft', 'offsetTop'],
        isNotParamsItem,
        defaultLeft,
        defaultTop,
        isTransform = true,
        allItems,
        isNotNullItemParams,itemOffsetWidth
    } = this.props;
    const {
        isShow,
        isDelete,
        isFirst,
        isLast,
        key: keyOrder,
        params,
    }: ListItemT & { params: RealParamsItemT } = props as ListItemT & { params: RealParamsItemT };

    const itemProps = item?.props || {};
    const { className, style, ...otherItemProps } = itemProps;
    const itemStyle: { width?: number | string; height?: number | string } = itemElem
        ? (item as unknown as HTMLElement).style || {}
        : {};
    let left;

    if (itemParams.includes('offsetRight')) {
        left = -params?.offsetRight;
    }

    if (itemParams.includes('offsetLeft')) {
        left = params?.offsetLeft;
    }

    let top;

    if (itemParams.includes('offsetBottom')) {
        top = -params?.offsetBottom;
    }

    if (itemParams.includes('offsetTop')) {
        top = params?.offsetTop;
    }

    if (itemParams.includes('width')) {
        itemStyle.width = params?.width + (itemOffsetWidth || 2);
    }

    if (itemParams.includes('height')) {
        if (!isNotNullItemParams || params?.height) {
            itemStyle.height = params?.height;
        }
    }

    const getDefaultTop = () =>
        typeof defaultTop === 'function' ? defaultTop({ item: itemElem }) : defaultTop;
    const getDefaultLeft = () =>
        typeof defaultLeft === 'function' ? defaultLeft({ item: itemElem }) : defaultLeft;

    const resultTop = (isShow ? (top ?? getDefaultTop()) : (getDefaultTop() ?? top)) ?? 0;
    const resultLeft = (isShow ? (left ?? getDefaultLeft()) : (getDefaultLeft() ?? left)) ?? 0;

    let stateClassName = '';

    if (currentItemKey !== undefined && currentItemKey !== null && allItems) {
        const currentIndex =
            allItems === 'number'
                ? +(currentItemKey as string)
                : allItems.indexOf(currentItemKey as string);
        const index = allItems === 'number' ? +prop : allItems.indexOf(prop as string);

        if (index > currentIndex) {
            stateClassName = '_next';
        }

        if (index < currentIndex) {
            stateClassName = '_prev';
        }
    }

    const resultProps = {
        className: `${className || ''} ${stateClassName} ${isShow ? '_show' : ''} ${
            isDelete ? '_hide' : ''
        } ${isFirst ? '_first' : ''} ${isLast ? '_last' : ''} ${
            saveItems?.length === 0 || saveItems?.length === 1 ? '_end' : ''
        }`,
        key: prop,
        [`data-${this.props.prop}`]: prop,
        style: {
            ...(isNotParamsItem
                ? {}
                : {
                      ...(!isTransform
                          ? { left: `${resultLeft}px`, top: `${resultTop}px` }
                          : { transform: `translate(${resultLeft}px,${resultTop}px)` }),
                      order: keyOrder,
                  }),
            ...itemStyle,
            ...style,
        },
        ...otherItemProps,
    };

    if (s()) {
        delete resultProps.style;
        delete resultProps[`data-${prop}`];
    }

    return React.cloneElement(item, resultProps);
};

export default renderFullItem;
