import React from 'react';
import { connect } from 'react-redux';

import Pages from '@components/pages/Pages.tsx';

import RolesI from './types.ts';

import pages from './static/pages.tsx';

class Roles extends React.Component<RolesI['props'], RolesI['state']> implements RolesI {
    constructor(props: RolesI['props']) {
        super(props);
        this.state = {};
    }

    pages = pages;

    render() {
        return (
            <>
                <Pages
                    className="body__innerPages _ITEMS"
                    classNamePage="body__innerPage _ITEM"
                    filter={(page) => page.parentName === 'settings-roles'}
                    pages={this.pages}
                    context={this}
                />
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Roles);
