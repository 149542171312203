import getPermissions from './getPermissions';

export default function setPermissions(settings) {
    const userProp = settings?.userProp || 'user';

    const { [userProp]: user } = this.props;

    if (user) {
        // this.setState({ user });

        setTimeout(() => {
            this.setState({ user: JSON.parse(JSON.stringify(user)) });
        }, 10);

        this.getPermissions = ({ ...props }) =>
            getPermissions.call(this, props.user || user, { ...props });
    }
}
