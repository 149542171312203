import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerAppPopup from '../../../functions/app/handlerPopup';
import setPermissions from '../../../functions/crm/setPermissions';
import getPage from '../../../functions/getPage';
import getQueryFilter from '../../../functions/getQueryFilter';
import handlerPopup from '../../../functions/handlerPopup';

import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import WidgetFilter from '../widget/Filter.jsx';

class TableHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerExport = this.handlerExport.bind(this);
        this.handlerLoadingKey = this.handlerLoadingKey.bind(this);

        setPermissions.call(this);
    }

    links = {
        executors: {
            name: 'manual-executors-inner',
        },
        cars: {
            name: 'manual-cars-inner',
        },
        tariffs: {
            name: 'manual-tariffs-inner',
        },
        'content-blog': {
            name: 'content-blog-inner',
        },
        'content-announce': {
            name: 'content-announce-inner',
        },
        'content-info': {
            name: 'content-info-inner',
        },
        'content-legal': {
            name: 'content-legal-inner',
        },
        'content-googleVision': {
            name: 'content-googleVision-list',
        },
        tags: {
            name: 'manual-tags-inner',
        },
        modelsOfCar: {
            name: 'manual-modelsOfCar-inner',
        },
        joinsDocs: {
            name: 'joins-docs-inner',
        },
        joinsScripts: {
            name: 'joins-scripts-inner',
        },
        joinsTemplates: {
            name: 'joins-templates-inner',
        },
    };

    handlerExport() {
        const { counter, filter, name } = this.props;
        const filterQuery = this.props.filterQuery || getQueryFilter({ filter });

        if (counter > 0) {
            handlerAppPopup({
                name: 'exportPopup',
                isShow: true,
                counter,
                filterQuery,
                type: name,
            });
        }
    }

    handlerLoadingKey(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    render() {
        const {
            name,
            filter,
            handlerFilter,
            getParent,
            checkRights = () => true,
            windowName,
            level = 3,
        } = this.props;
        const link = this.links[name];
        const pageLink = link && getPage({ name: link.name });

        return (
            <div className="widget__headActionsGroups _row">
                <div className="widget__headActionsGroup">
                    {['organizations', 'executors', 'joins', 'paysGroups'].includes(name) && (
                        <div
                            className="widget__headActionsElem widget__headAction"
                            onClick={this.handlerExport}
                        >
                            <div className="widget__headActionInner _col _click">
                                <i className="widget__headActionIcon">
                                    <Icon name="widget-export" />
                                </i>
                            </div>
                        </div>
                    )}
                    {!['pays', 'joinsContracts'].includes(name) && (
                        <WidgetFilter
                            name={name}
                            filter={filter}
                            handlerFilter={handlerFilter}
                            getParent={getParent}
                            windowName={windowName}
                        />
                    )}
                </div>
                {link && pageLink?.contentOfLink && checkRights() && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headActionsElem widget__headLink _main">
                            <Link
                                pageName={link.name}
                                ids={{ [level]: 'new' }}
                                className="widget__headLinkInner _click"
                            >
                                {pageLink.contentOfLink}
                            </Link>
                        </div>
                    </div>
                )}
                {name === 'joins' &&
                    this.getPermissions({
                        key: 'joins',
                        items: [
                            {
                                key: 'invites',
                                rules: ['create'],
                            },
                        ],
                    }) && (
                        <>
                            <div className="widget__headActionsGroup">
                                <div
                                    className="widget__headActionsElem widget__headLink _main"
                                    onClick={() => {
                                        handlerAppPopup({
                                            name: 'uploadJoinsPopup',
                                            isShow: true,
                                        });
                                    }}
                                >
                                    <div className="widget__headLinkInner _click">
                                        Загрузить пачку
                                    </div>
                                </div>
                                <div
                                    className="widget__headActionsElem widget__headLink _main"
                                    onClick={() => {
                                        handlerPopup({
                                            action: 'show',
                                            name: 'popupExecutorInvite',
                                        });
                                    }}
                                >
                                    <div className="widget__headLinkInner _click">+ Пригласить</div>
                                </div>
                            </div>
                        </>
                    )}
                {name === 'chat-templates' &&
                    this.getPermissions({
                        key: 'chat',
                        items: [{ key: 'templates', rules: ['create'] }],
                    }) && (
                        <>
                            <div className="widget__headActionsGroup">
                                <Link
                                    className="widget__headActionsElem widget__headLink _main"
                                    pageName="chat-template-inner"
                                    ids={{ 2: 'new' }}
                                >
                                    <div className="widget__headLinkInner _click">
                                        + Новый шаблон
                                    </div>
                                </Link>
                            </div>
                        </>
                    )}
                {name === 'content-info' && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headActionsElem widget__headLink">
                            <Link
                                pageName="content-infoPublic"
                                className="widget__headLinkInner _click"
                            >
                                Просмотр базы знаний
                            </Link>
                        </div>
                    </div>
                )}
                {name === 'organizations' &&
                    this.getPermissions({
                        key: 'manual',
                        items: [
                            {
                                key: 'organizations',
                                rules: ['create'],
                            },
                        ],
                    }) && (
                        <div className="widget__headActionsGroup">
                            <div className="widget__headActionsElem widget__headLink _main">
                                <Link
                                    pageName="manual-organizations-inner"
                                    ids={{ 3: 'juristic' }}
                                    className="widget__headLinkInner _click"
                                >
                                    + Юр. лицо
                                </Link>
                            </div>
                            <div className="widget__headActionsElem widget__headLink _main">
                                <Link
                                    pageName="manual-organizations-inner"
                                    ids={{ 3: 'physical' }}
                                    className="widget__headLinkInner _click"
                                >
                                    + Физ. лицо
                                </Link>
                            </div>
                        </div>
                    )}
                {name === 'paysGroups' &&
                    this.getPermissions({
                        notAdminCorporation: true,
                        key: 'pays',
                        items: [{ key: 'acts', rules: ['create'] }],
                    }) && (
                        <div className="widget__headActionsGroup">
                            <div className="widget__headActionsElem widget__headLink _main">
                                <div className="widget__headLinkInner _click">
                                    Загрузить пачку актов
                                </div>
                            </div>
                        </div>
                    )}
                {name === 'pays' &&
                    this.getPermissions({
                        notAdminCorporation: true,
                        key: 'pays',
                        items: [{ key: 'acts', rules: ['create'] }],
                    }) && (
                        <div className="widget__headActionsGroup">
                            <div className="widget__headActionsElem widget__headLink _main">
                                <div
                                    className="widget__headLinkInner _click"
                                    onClick={() => {
                                        handlerAppPopup({
                                            name: 'payPopup',
                                            isShow: true,
                                        });
                                    }}
                                >
                                    + Новый акт
                                </div>
                            </div>
                        </div>
                    )}
                {name === 'joinsContracts' &&
                    this.getPermissions({
                        notAdminCorporation: true,
                        key: 'joins',
                        items: [
                            {
                                key: 'contracts',
                                rules: ['create'],
                            },
                        ],
                    }) && (
                        <div className="widget__headActionsGroup">
                            <div className="widget__headActionsElem widget__headLink _main">
                                <div
                                    className="widget__headLinkInner _click"
                                    onClick={() => {
                                        handlerAppPopup({
                                            name: 'joinContractPopup',
                                            isShow: true,
                                        });
                                    }}
                                >
                                    + Новый договор
                                </div>
                            </div>
                        </div>
                    )}
                {name === 'mailings' &&
                    this.getPermissions({
                        key: 'chat',
                        items: [
                            {
                                key: 'mailing',
                                rules: ['create'],
                            },
                        ],
                    }) && (
                        <div className="widget__headActionsGroup">
                            <div className="widget__headActionsElem widget__headLink _main">
                                <Link
                                    className="widget__headLinkInner"
                                    pageName="mailing-inner"
                                    ids={{ 2: 'new' }}
                                >
                                    + Новая рассылка
                                </Link>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(TableHead);

TableHead.propTypes = {
    name: PropTypes.string,
    filter: PropTypes.array,
    handlerFilter: PropTypes.object,
    getParent: PropTypes.func,
    checkRights: PropTypes.func,
    counter: PropTypes.number,
    filterQuery: PropTypes.array,
    user: PropTypes.object,
    windowName: PropTypes.string,
    level: PropTypes.number,
};
