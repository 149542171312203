import ManualTableI from '../types.ts';

import { TableColT } from '../static/tables.ts';

const getColWidth: ManualTableI['getColWidth'] = function (colName) {
    const { name } = this.props;
    const table = this.tables[name];
    const isAdmin = this.checkAdmin();
    const col = table.cols[colName as keyof typeof table.cols] as TableColT;

    const getWidth = (thisCol: TableColT) =>
        typeof thisCol.width === 'function' ? thisCol.width(isAdmin) : thisCol.width;

    if (colName === 'corporationInfo') {
        return this.corporationColWidth;
    }

    if (!isAdmin || !col.isAdminMain) {
        return getWidth(col);
    }

    return (
        getWidth(col) -
        this.corporationColWidth +
        table.deletesCols.reduce(
            (prev, deletesCol) =>
                prev + getWidth(table.cols[deletesCol as keyof typeof table.cols] as TableColT),
            0,
        )
    );
};

export default getColWidth;
