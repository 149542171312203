import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (force) {
    const { isJoin } = this.state;
    const fields = this.checkChange(true).model;

    let response;

    await handlerLoading.call(this, 'save');

    try {
        response = await axios.post<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    error?: { name: string; text: string };
                    executorInfo?: {
                        name: string;
                        phone: string;
                    };
                }>;
            }
        >(
            `${process.env.REACT_APP_API}/executor`,
            { fields, isJoin, ...(force === true ? { force } : {}) },
            { headers: getHeaders() },
        );
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    const { success, data } = response.data;

    if (success) {
        const { id } = data;

        if (id) {
            if (isJoin) {
                document.dispatchEvent(
                    new CustomEvent('refreshTable', { detail: { name: 'joins' } }),
                );
            } else {
                changePage<'crm'>({ pageName: 'manual-executors-inner', ids: { 3: id } });
            }
        }

        this.close();
    } else {
        const { error, message, executorInfo } = data;

        if (message === 'Executor create') {
            const info: string[] = [];

            if (executorInfo?.name) {
                info.push(executorInfo.name);
            }

            if (executorInfo?.phone) {
                info.push(executorInfo.phone);
            }

            handlerPopup('alertPopup', {
                isShow: true,
                type: 'inviteExecutor',
                info: info.join(', '),
                callback: save.bind(this, true),
            });
        } else {
            this.setState({ error });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
