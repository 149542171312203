import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link from '../../../../../components/Link.jsx';

import getPage from '../../../../../functions/getPage';
import checkCurrentPage from '../../../../../functions/checkCurrentPage';
import Loader from '../../../../../components/Loader.jsx';
import Animate from '../../../../../components/Animate.jsx';
import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderLink = this.renderLink.bind(this);
        this.filterLink = this.filterLink.bind(this);
        this.handlerLoading = this.handlerLoading.bind(this);
        this.delete = this.delete.bind(this);

        this.groupFirst = React.createRef();
        this.groupSecond = React.createRef();
    }

    links = {
        blog: {
            main: {
                key: 'content-blog-inner-main',
            },
            content: {
                key: 'content-blog-inner-content',
            },
        },
        announce: {
            main: {
                key: 'content-announce-inner-main',
            },
            content: {
                key: 'content-announce-inner-content',
            },
        },
        legal: {
            main: {
                key: 'content-legal-inner-main',
            },
            content: {
                key: 'content-legal-inner-content',
            },
        },
        info: {
            main: {
                key: 'content-info-inner-main',
            },
            content: {
                key: 'content-info-inner-content',
            },
        },
    };

    getLinksOrder(name) {
        const { checkRights } = this.props;

        if (name === 'tabs') {
            return [
                { key: 'main' },
                { key: 'content' },
                ...(this.props.name === 'info' ? [{ key: 'preview' }] : []),
            ].filter(this.filterLink);
        }

        return [{ key: 'publicate' }]
            .filter(this.filterLink)
            .filter((link) => checkRights() || link.key !== 'publicate');
    }

    filterLink({ key }) {
        const { isNew, pagesStore, article, name } = this.props;

        if (article?.section === 'smi') {
            return isNew ? key === 'publicate' : false;
        }

        const isCurrentPage = checkCurrentPage({
            name: `content-${name}-inner-content`,
            pages: pagesStore,
        });

        return key !== 'publicate' ? !isNew : isCurrentPage;
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    renderLink({ prop: key, isShow }) {
        const { loadingKey } = this.state;
        const { article, levels, name } = this.props;
        const link = this.links[name][key];
        let Tag = 'div';
        const propsTag = {};
        let contentOfLink;
        let className = '';

        if (link) {
            const { key: namePage } = link;
            const page = getPage({ name: namePage });

            Tag = Link;
            propsTag.pageName = page.name;
            propsTag.ids = { 3: levels[3] };
            contentOfLink = page.contentOfLink;
        }

        if (key === 'publicate') {
            contentOfLink = 'Опубликовать';
            className = '_main';

            propsTag.onClick = () => {
                if (!loadingKey) {
                    this.handlerLoading('publicate').then(() => {
                        document.dispatchEvent(
                            new CustomEvent('publicateArticle', {
                                detail: { handlerLoading: this.handlerLoading },
                            }),
                        );
                    });
                }
            };
        }

        if (key === 'preview') {
            contentOfLink = 'Просмотр';
            className = '_main';

            Tag = Link;
            propsTag.pageName = 'content-infoPublic-inner';
            propsTag.ids = { 1: article?.url };
        }

        return (
            <Tag
                className={`widget__headLink ${isShow ? '_show' : ''} ${className}`}
                key={key}
                data-key={key}
                {...propsTag}
            >
                <div className="widget__headLinkInner _click">
                    {key === 'publicate' && (
                        <Animate
                            className="widget__headLinkLoader _loader"
                            isShow={loadingKey === 'publicate'}
                        >
                            <div className="widget__headLinkLoaderItem _loaderItem">
                                <Loader className="" />
                            </div>
                        </Animate>
                    )}
                    {contentOfLink}
                </div>
            </Tag>
        );
    }

    delete() {
        const { delete: deleteFn } = this.props;

        if (deleteFn) {
            this.handlerLoading('delete').then(() => {
                deleteFn({}).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    componentDidMount() {
        this.setState({ isInit: true });
    }

    render() {
        const { checkRights } = this.props;
        const { loadingKey } = this.state;

        return (
            <div className="widget__headActionsGroups _row _left">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={this.getLinksOrder('tabs')}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    styles={['width']}
                />
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={this.getLinksOrder()}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    styles={['width']}
                />
                {checkRights() && (
                    <div className="widget__headActionsGroup _marginLeft">
                        <div className="widget__headLink _delete" onClick={this.delete}>
                            <Animate
                                className="widget__headLinkLoader _loader"
                                isShow={loadingKey === 'delete'}
                            >
                                <div className="widget__headLinkLoaderItem _loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    name: PropTypes.string,
    isNew: PropTypes.bool,
    type: PropTypes.string,
    pagesStore: PropTypes.object,
    levels: PropTypes.array,
    article: PropTypes.object,
    checkRights: PropTypes.func,
    delete: PropTypes.func,
};
