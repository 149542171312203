import React from 'react';

import Link from '@components/link/Link.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import ExecutorLimitT from '@global/models/ExecutorLimit.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT, TableBlockCorporationT } from '../../types.ts';

const renderExecutorsLimits: RenderColsT<'executorsLimits'> = function ({
    name: colName,
    item,
    corporationIndex,
    ...props
}) {
    const executorLimit = item as ExecutorLimitT;
    const corporation = props.corporation as TableBlockCorporationT<
        ExecutorLimitT['corporations'][number]
    >;
    let content;
    let className;

    if (corporationIndex === 0) {
        if (colName === 'name') {
            className = '_visible';

            content = (
                <>
                    <Link
                        className="v2table__text _link _full"
                        pageName="manual-executors-inner"
                        ids={{ 3: executorLimit._id }}
                    >
                        {executorLimit.fullName}
                    </Link>
                </>
            );
        }
    }

    if (colName === 'remains' || colName === 'monthRemains') {
        const mediumVal = colName === 'remains' ? 500_000 : 100_000;
        const lowVal = colName === 'remains' ? 150_000 : 30_000;
        const key = colName === 'remains' ? 'yearAmountRemains' : 'monthAmountRemains';
        const value = corporation[key];
        let child;

        let textClass = '';

        if (value < mediumVal) {
            textClass = '_mediumColor';
        }

        if (value < lowVal) {
            textClass = '_alertColor';
        }

        if (colName === 'monthRemains') {
            const { otherData } = this.props;
            const getMoreActions = otherData?.getMoreActions as (data: {
                executorLimit: ExecutorLimitT;
                corporation: ExecutorLimitT['corporations'][number];
            }) => CursorActionsItemT[];

            child = (
                <>
                    <More
                        id={`executorLimit-${executorLimit._id}-${corporation._id}`}
                        items={getMoreActions({ executorLimit, corporation })}
                        stopPropagation={true}
                    />
                </>
            );
        }

        // corporation.isRealyAmount
        // Подтвержденный<br/>доход ФНС

        let textProps;

        if (colName === 'remains' && corporation.yearAmount) {
            let remainsSupportContent = `Доход за год: ${getFormatPrice(corporation.yearAmount)} ₽`;

            if (corporation.isRealyAmount) {
                textClass = '_successColor _mediumWeight';
                remainsSupportContent += '<br/>Подтвержден ФНС';
            }

            textProps = setCursorSupport(
                {
                    _id: `remains-${executorLimit._id}`,
                    content: remainsSupportContent,
                    dir: 'top',
                },
                { targetClassName: '.v2table__text', className: '' },
            );
        }

        if (colName === 'monthRemains' && corporation.monthAmount) {
            textProps = setCursorSupport(
                {
                    _id: `monthRemains-${executorLimit._id}`,
                    content: `Доход за месяц: ${getFormatPrice(corporation.monthAmount)} ₽`,
                    dir: 'top',
                },
                { targetClassName: '.v2table__text', className: '' },
            );
        }

        content = (
            <>
                {child}
                <div className={`v2table__text ${textClass}`} {...textProps}>
                    {getFormatPrice(value)} ₽
                </div>
            </>
        );
    }

    if (colName === 'limit' || colName === 'monthLimit') {
        const key = colName === 'limit' ? 'yearLimit' : 'monthLimit';
        const value = corporation[key];

        content = (
            <>
                <div className={`v2table__text`}>{getFormatPrice(value)} ₽</div>
            </>
        );
    }

    return { content, className };
};

export default renderExecutorsLimits;
