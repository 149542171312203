import React from 'react';

import Button from '@components/button/Button.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { model, loadingKey } = this.state;

    return (
        <>
            <div className="v2popup__head">
                <div className="v2popup__title">Пополнить баланс</div>
                <div className="v2popup__description">
                    <b>Важно:</b> пополнить можно только со счёта вашей компании, 
                    иначе система не сможет зачислить деньги
                </div>
                <div className="v2popup__close _CLICK _top" onClick={this.close.bind(this)}>
                    <Icon name="popup-close" />
                </div>
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <div className="v2popup__contentInner">
                    {model && (
                        <WidgetCard
                            name="corporationAmountDoc"
                            model={model}
                            change={this.change.bind(this)}
                            getValue={this.getValue.bind(this)}
                            withHead={false}
                            className="_empty"
                        />
                    )}
                </div>
            </div>
            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _ROW">
                        <div className="v2popupFoot__button _full">
                            <Button
                                className="_main"
                                onClick={this.download.bind(this)}
                                loading={loadingKey === 'save'}
                            >
                                Скачать счёт на оплату
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderContent;
