import MobPopupI from '../types.ts';

const getDownBreak: MobPopupI['getDownBreak'] = function (isStart) {
    const inner = this.getInner();
    const { isInit } = this.props;

    if (!isInit || isStart) {
        return document.documentElement.clientHeight;
    }

    if (!inner) {
        return 0;
    }

    return inner.offsetHeight + 12;
};

export default getDownBreak;
