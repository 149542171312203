const walletAccount = {
    fieldsOrder: ['bic', 'number', 'addressatName', 'current'],
    fields: {
        bic: {
            support: 'БИК',
            type: 'input',
            regExp: /[^\d]/gi,
        },
        number: {
            support: 'Банк. счёт',
            type: 'input',
            regExp: /[^\d]/gi,
        },
        addressatName: {
            support: 'ФИО получателя',
            type: 'input',
        },
        current: {
            support: 'Сделать основным',
            type: 'switch',
        },
    },
} as const;

export default walletAccount;
