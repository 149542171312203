import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';

class PublicAppButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    buttons = {
        apple: {
            icon: 'app-download-apple',
            href: 'https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3/id1437518854?l=en',
            content: 'App Store',
        },
        google: {
            icon: 'app-download-google',
            href: 'https://play.google.com/store/apps/details?id=com.gnivts.selfemployed&hl=ru',
            content: 'Google Play',
        },
        rustore: {
            icon: 'app-download-rustore',
            href: 'https://apps.rustore.ru/app/com.gnivts.selfemployed',
            content: 'RuStore',
        },
        appgalery: {
            icon: 'app-download-appgalery',
            href: 'https://appgallery.huawei.ru/app/C106258161',
            content: 'App Galery',
        },
    };

    render() {
        const { name, className = '' } = this.props;
        const button = this.buttons[name];

        return (
            <a
                className={`publicAppButton _col _${name} ${className}`}
                href={button.href}
                target="_blank"
                rel="noreferrer"
            >
                <div className="publicAppButton__inner">
                    <i className="publicAppButton__icon">
                        <Icon name={button.icon} />
                    </i>
                    Скачать в<br />
                    <span>{button.content}</span>
                </div>
            </a>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(PublicAppButton);

PublicAppButton.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
};
