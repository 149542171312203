import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';
import Loader from './Loader.jsx';
import Animate from './Animate.jsx';

class WindowActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaders: {},
        };

        this.handlerAction = this.handlerAction.bind(this);
    }

    actions = {
        editName: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Изменить название',
        },
        edit: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Редактировать',
        },
        profile: {
            icon: 'actions-edit',
            key: 'profile',
            content: 'Профиль',
        },
        deleteRole: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить роль',
        },
        editFeedback: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Изменить отзыв',
        },
        deleteFeedback: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить отзыв',
        },
        newPhoto: {
            icon: 'actions-photo',
            key: 'photo',
            content: 'Новое фото',
        },
        deletePhoto: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить фото',
        },
        smsAgain: {
            icon: 'actions-repeat',
            key: 'smsAgain',
            content: 'Повторно отправить смс',
        },
        deleteJoin: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить приглашение',
        },
        logout: {
            icon: 'actions-exit',
            key: 'exit',
            className: '_alert',
            content: 'Выйти',
        },
        editTemplate: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Редактировать шаблон',
        },
        deleteTemplate: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить шаблон',
        },
        choiceChat: {
            key: 'choice',
            icon: 'actions-choice',
            content: 'Выбрать чат',
        },
        deleteChat: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить чат',
        },
        editMessage: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Изменить сообщение',
        },
        deleteMessage: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить сообщение',
        },
        downloadImage: {
            icon: 'actions-download',
            key: 'download',
            content: 'Скачать фотографию',
        },
        addDocForExecutor: {
            icon: 'actions-add',
            key: 'add',
            content: 'Прикрепить к исполнителю',
        },
        setResponsibleSelf: {
            icon: 'actions-responsible',
            key: 'setResponsibleSelf',
            content: 'Взять в работу',
        },
        contextTargetBlank: {
            icon: 'actions-targetBlank',
            key: 'targetBlank',
            content: 'Открыть ссылку в новой вкладке',
        },
        contextCopyLink: {
            icon: 'actions-copyLink',
            key: 'copyLink',
            content: 'Скопировать ссылку',
        },
        downloadContract: {
            icon: 'actions-download',
            key: 'download',
            content: () => {
                const { otherData } = this.props;

                return otherData?.type === 'userProxy' ? 'Скачать доверенность' : 'Скачать договор';
            },
        },
        refreshContract: {
            icon: 'actions-repeat',
            key: 'refresh',
            content: () => {
                const { otherData } = this.props;

                return otherData?.type === 'userProxy'
                    ? 'Обновить доверенность'
                    : 'Обновить договор';
            },
        },
        openContract: {
            icon: 'actions-open',
            key: 'open',
            content: () => {
                const { otherData } = this.props;

                return otherData?.type === 'userProxy' ? 'Открыть доверенность' : 'Открыть договор';
            },
        },
        inactiveContract: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: () => {
                const { otherData } = this.props;

                return otherData?.type === 'userProxy'
                    ? 'Расторгнуть доверенность'
                    : 'Расторгнуть договор';
            },
        },
        editMvd: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Редактировать карточку',
        },
        downloadMvd: {
            icon: 'actions-download',
            key: 'download',
            content: 'Сформировать заявление',
        },
        editPay: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Редактировать акт',
        },
        repeatPay: {
            icon: 'actions-repeat',
            key: 'repeat',
            content: 'Повторить акт',
        },
        logs: {
            icon: 'actions-logs-dark',
            key: 'logs',
            content: 'Журнал логов',
        },
        removeTax: {
            icon: 'actions-remove',
            key: 'remove',
            className: '_alert',
            content: 'Отключить',
        },
        editUser: {
            icon: 'actions-edit',
            key: 'edit',
            content: 'Редактировать',
        },
        deleteUser: {
            icon: 'actions-delete',
            key: 'delete',
            className: '_alert',
            content: 'Удалить сотрудника',
        },
        checkPayError: {
            icon: 'actions-checkError',
            key: 'checkError',
            // className: '_alert',
            content: 'Проверить ошибку',
        },
        createNonResidentAct: {
            icon: 'actions-download',
            key: 'download',
            content: 'Договор-счёт-акт',
        },
        downloadCorporationReportList: {
            icon: 'actions-download',
            key: 'download',
            content: 'Расшифровка списаний',
        },
        repeatMailing: {
            icon: 'actions-repeat',
            key: 'repeat',
            content: 'Повторить',
        },
        withdrawAmount: {
            icon: 'actions-withdraw',
            key: 'withdraw',
            content: 'Вывести средства',
        },
    };

    handlerLoader({ key, value }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const { loaders } = newState;

                loaders[key] = value;

                newState.loaders = loaders;

                return newState;
            }, resolve);
        });
    }

    handlerAction({ name }) {
        const { callback, hide } = this.props;

        if (callback && typeof callback === 'function') {
            this.handlerLoader({ key: name, value: true }).then(() => {
                callback({ key: this.actions[name].key }).then(
                    () => {
                        this.handlerLoader({ key: name, value: false }).then(() => {
                            if (hide) {
                                hide();
                            }
                        });
                    },
                    () => {
                        this.handlerLoader({ key: name, value: false });
                    },
                );
            });
        }
    }

    render() {
        const { loaders } = this.state;
        const { items = [] } = this.props;

        return (
            <div className="windowActions">
                {items.map((name) => {
                    const currentAction = this.actions[name];
                    const { className = '' } = currentAction;

                    return (
                        <div
                            key={name}
                            className={`windowActions__item _click ${
                                (currentAction.key && `_${currentAction.key}`) || ``
                            } ${className}`}
                            onClick={() =>
                                this.handlerAction({
                                    name,
                                })
                            }
                        >
                            <Animate
                                className="windowActions__itemLoader _loader"
                                isShow={loaders[name]}
                            >
                                <div className="windowActions__itemLoaderItem _loaderItem">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <div className="windowActions__itemInner _row">
                                <i className="windowActions__itemIcon">
                                    <Icon name={currentAction.icon} />
                                </i>
                                <span className="windowActions__itemName">
                                    {typeof currentAction.content === 'function'
                                        ? currentAction.content()
                                        : currentAction.content}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(WindowActions);

WindowActions.propTypes = {
    items: PropTypes.array,
    callback: PropTypes.func,
    hide: PropTypes.func,
    otherData: PropTypes.object,
};
