import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function ({ executorLimit, corporation }) {
    const { user } = this.state;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'executorLimits',
                    modelId: executorLimit._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (
        getPermissions(user, {
            adminCorporation: true,
            key: 'pays',
            items: [{ key: 'limits', rules: ['update'] }],
        })
    ) {
        actions.unshift({
            key: 'edit',
            name: 'edit',
            onClick: async () => {
                handlerPopup('executorLimitsPopup', {
                    isShow: true,
                    executorId: executorLimit._id,
                    executorName: executorLimit.fullName,
                    limits: {
                        month: corporation.monthLimit,
                        year: corporation.yearLimit,
                    },
                    corporationId: corporation._id,
                    corporationName: corporation.name,
                    callback: this.tableUpdateItems!.bind(this),
                });
            },
        });
    }

    return actions;
};

export default getMoreActions;
