import { incline } from 'lvovich';

export default function getUserIncline({ user, dec = 'genitive' }) {
    const inclineUser = incline(
        {
            first: user.firstName,
            last: user.secondName,
            middle: user.thirdName,
        },
        dec,
    );

    return {
        firstName: inclineUser.first,
        secondName: inclineUser.last,
        thirdName: inclineUser.middle,
    };
}
