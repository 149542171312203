import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CorporationT } from '@global/models/Corporation.ts';
import DocT from '@global/models/Doc.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (fields) {
    const { model } = this.state;
    const { setModel } = this.props;
    const change = this.checkChange(true);
    const resultFields = fields || change.model!;

    if (resultFields.docs) {
        resultFields.docs = resultFields.docs
            .filter((doc: DocT) => doc)
            .map((doc: DocT) => ({
                ...doc,
                files: (doc.files || []).filter((file) => file),
            }));
    }

    if (change.deleteItems?.length) {
        if (!resultFields.docs) {
            resultFields.docs = [];
        }

        change.deleteItems.forEach((deleteItem) => {
            const deleteItemParts = deleteItem.split('.');
            const [name, id, filesName, fileId] = deleteItemParts;

            if (name === 'docs' && deleteItemParts.length === 2) {
                resultFields.docs.push({ _id: id, isDelete: true, files: [] });
            }

            if (name === 'docs' && filesName === 'files' && fileId) {
                let doc = resultFields.docs.find(
                    (innerDoc: { _id: string }) => innerDoc._id === id,
                );

                if (!doc) {
                    resultFields.docs.push({ _id: id, files: [] });

                    doc = resultFields.docs[resultFields.docs.length - 1];
                }

                doc.files.push({ _id: fileId, isDelete: true });
            }
        });
    }

    // console.log(change);
    // console.log(model?.docs);

    if (0) {
        return;
    }

    try {
        const response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: CorporationT;
                    error?: { name: string; text: string };
                }>;
            }
        >(
            `${process.env.REACT_APP_API}/corporation`,
            { fields: resultFields, id: model?._id },
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            const { model: resultModel } = data;

            await this.init({ fields: resultModel });
            await setModel(resultModel);

            return;
        }

        const { error, message } = data;

        if (message === 'You have verification corporation') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'changePassportDeniedCauseCorpVerify',
                denied: true,
            });
        } else if (message === 'Corporation is verification') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'changeDocsDeniedCauseCorpVerify',
                denied: true,
            });
        }

        this.setState({ error });
    } catch (error) {}

    return Promise.reject();
};

export default save;
