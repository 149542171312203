import React from 'react';

import AnimateChangeUp from '@components/AnimateChangeUp.jsx';
import Button from '@components/Button.jsx';

import CallPopupI from '../types.ts';

const renderFoot: CallPopupI['renderFoot'] = function () {
    const { loadingKey, success, error } = this.state;

    return (
        <div className="publicCallPopup__foot _COL">
            <div className="publicCallPopup__footInner _ROW">
                <div className="publicCallPopup__footButton">
                    <Button
                        className="_shadow _public"
                        onClick={this.sendForm.bind(this)}
                        showLoader={loadingKey === 'send'}
                    >
                        Отправить
                    </Button>
                </div>
                <div className="publicCallPopup__footInfo">
                    Нажимая кнопку «Отправить», вы&nbsp;даёте <br />
                    <a
                        href={`${process.env.REACT_APP_CRM_DOMEN}/legal`}
                        target="_blank"
                        className="_CLICK"
                        rel="noreferrer"
                    >
                        согласие на обработку персональных данных
                    </a>
                </div>
            </div>
            <AnimateChangeUp
                renderKey={success ? true : error ? error.text : undefined}
                className="publicCallPopup__footAlert _center"
            >
                <div
                    className={`publicCallPopup__footAlertItem ${success ? '_success' : ''}`}
                >
                    {success ? 'Заявка успешно отправлена' : error?.text}
                </div>
            </AnimateChangeUp>
        </div>
    );
};

export default renderFoot;
