import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../../manual/card/Head.jsx';
import Field from '../../manual/card/Field.jsx';
import File from '../../manual/card/File.jsx';

class ContentBlogSmi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    fields = [{ name: 'smiLink' }];

    getDescriptionInfo() {
        const { article } = this.props;

        if (article) {
            return {
                description: article.smiLink || '',
            };
        }

        return {
            description: '–',
        };
    }

    render() {
        const {
            article = {},
            checkEditmode,
            changeArticle,
            errors = [],
            uploadMainFile,
            newFiles,
        } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className="manualCard _blogMain">
                <div className="manualCard__head _row">
                    <Head title="СМИ" description={description}></Head>
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__main _row">
                        <div className="manualCard__mainItem _fields">
                            <div className="manualCard__fields _row">
                                {this.fields.map((field) => {
                                    const { name, isReadOnly } = field;
                                    const { [name]: value } = article;
                                    let items;
                                    let isDisabled =
                                        !checkEditmode() ||
                                        (['dateOfPublication', 'timeOfPublication'].indexOf(
                                            name,
                                        ) !== -1 &&
                                            article.type !== 'in-future');

                                    if (
                                        name === 'category' &&
                                        ['vacancies', 'smi'].indexOf(article.section) !== -1
                                    ) {
                                        isDisabled = true;
                                    }

                                    return (
                                        <div className={`manualCard__field _${name}`} key={name}>
                                            <Field
                                                value={value}
                                                type="blog"
                                                name={name}
                                                handler={changeArticle}
                                                group="blogMain"
                                                isDisabled={isDisabled}
                                                isReadOnly={isReadOnly}
                                                isError={errors.indexOf(name) !== -1}
                                                items={items}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="manualCard__mainItem _preview">
                            <File
                                _id={article.smiLogo?._id}
                                file={newFiles.smiLogo || article.smiLogo}
                                folder="articles"
                                name="smiLogo"
                                uploadFile={({ files }) => {
                                    uploadMainFile({ name: 'smiLogo', files });
                                }}
                                isDisabled={!checkEditmode()}
                                className="_contain"
                            >
                                <span>Логотип</span> СМИ
                            </File>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ContentBlogSmi);

ContentBlogSmi.propTypes = {
    serverData: PropTypes.object,
    article: PropTypes.object,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
    changeArticle: PropTypes.func,
    uploadMainFile: PropTypes.func,
    isNew: PropTypes.bool,
    newFiles: PropTypes.object,
};
