import React from 'react';

import getItems from './methods/getItems.ts';
import inputHandler from './methods/inputHandler.ts';

import CodeInputsI from './types.ts';

import renderInput from './renders/renderInput.tsx';

class CodeInputs
    extends React.Component<CodeInputsI['props'], CodeInputsI['state']>
    implements CodeInputsI
{
    parent: CodeInputsI['parent'];

    constructor(props: CodeInputsI['props']) {
        super(props);
        this.state = {
            inputs: {},
        };

        this.parent = React.createRef();
    }

    getItems = getItems;
    inputHandler = inputHandler;

    renderInput = renderInput;

    render() {
        const items = this.getItems();

        return (
            <div ref={this.parent} className="v2codeInputs _ROW">
                {items.map((item) => this.renderInput(item))}
            </div>
        );
    }
}

export default CodeInputs;
