import React from 'react';

import CursorListsItem from '@components/cursorListsItem/CursorListsItem.tsx';

import CursorListI from '../types.ts';

const renderItem: CursorListI['renderItem'] = function ({ item }) {
    const resultItem = this.getItems().find((innerItem) => innerItem._id === item._id);
    const callback = resultItem?.callback;

    return (
        <div
            className={`v2cursorLists__item _${item.dir}`}
            style={
                item.position && {
                    left: `${item.position.left}px`,
                    top: `${item.position.top}px`,
                }
            }
        >
            <CursorListsItem
                name={item.type}
                multi={item.multi}
                callback={callback}
                currents={item.currents}
                filterName={item.filterName}
                items={item.items}
                defaultQuery={item.defaultQuery}
            />
        </div>
    );
};

export default renderItem;
