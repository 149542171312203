import MainI from '../types.ts';

const getRowClassName: MainI['getRowClassName'] = function (pay) {
    if (pay.status === 'wait' && (pay.hasAmountDublicates || pay.hasDublicates)) {
        return '_mediumColorBack';
    }

    if (pay.status === 'error' || pay.status === 'failed') {
        return '_errorColorBack';
    }

    return '';
};

export default getRowClassName;
