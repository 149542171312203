const cards = [
    {
        icon: 'adv-icon-1.svg',
        title: 'Реестр ПО',
        content: 'Разработанное программное обеспечение входит в реестр отечественного ПО',
        support: 'Реестровая запись №21798 от 15.03.2024',
    },
    {
        icon: 'adv-icon-2.png',
        title: 'Партнер ФНС',
        content: 'LIVECARGO CRM — официальный партнер ФНС России',
        support: null,
    },
    {
        icon: 'adv-icon-3.svg',
        title: 'Регистрация для ЭВМ',
        content: 'Свидетельство о государственной регистрации программы для ЭВМ',
        support: 'Реестровая запись №21798 от 15.03.2024',
    },
    {
        icon: 'adv-icon-4.svg',
        title: 'Приложение',
        content:
            'Бесшовная интеграция между нашей CRM системой и мобильным приложением для исполнителя. Все данные хранятся в одном месте.',
        support: null,
    },
    {
        icon: 'adv-icon-5.svg',
        title: 'Интеграции',
        content: '— Telegram<br/>— Модуль &!nbsp;&!nbsp;&!nbsp;&!nbsp;обработки для 1С',
        support: null,
    },
] as const;

export default cards;
