import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import Animate from '../../Animate.jsx';

// import getPage from '../../../functions/getPage';

class Back extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { counterChangePage = 0 } = this.props;

        return (
            <Animate className="widget__headBack" isShow={counterChangePage > 0}>
                <div
                    className="widget__headBackInner _col _click"
                    onClick={() => {
                        window.history.back();
                    }}
                >
                    <div className="widget__headBackIcon">
                        <Icon name="arrow-back" />
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Back);

Back.propTypes = {
    counterChangePage: PropTypes.number,
};
