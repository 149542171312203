import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { userPopup } = this.props;
    const { user } = userPopup;
    const model: I['state']['model'] = {
        _id: 'user',
    };

    if (user) {
        model._id = user._id;
        model.email = user.email;
        model.firstName = user.firstName;
        model.secondName = user.secondName;
        model.thirdName = user.thirdName;
        model.role = user.role;
        model.roleName = user.roleName;

        this.setState({ isEdit: true });
    } else {
        model.new = true;
    }

    this.init({ fields: model, start: !user });
};

export default mountHandler;
