import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '@components/animate/Animate.tsx';
import CallPopup from '@components/crm/public/callPopup/CallPopup.tsx';
import { addYandexMetrika, removeYandexMetrika } from '@functions/addYandexMetrika.ts';

import Pages from '../../components/Pages.jsx';
import MobileMenu from '../../components/crm/public/MobileMenu.jsx';
import PublicTop from '../../components/crm/public/Top.jsx';

import Blog from './public/Blog.jsx';
import Executors from './public/Executors.jsx';
import Legal from './public/Legal.jsx';
import Business from './public/business/Business.tsx';

class Public extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowCallForm: false,
        };

        this.handlerMenu = this.handlerMenu.bind(this);
        this.setRenderNav = this.setRenderNav.bind(this);
        this.handlerLegalNav = this.handlerLegalNav.bind(this);
        this.handlerLegalMissClick = this.handlerLegalMissClick.bind(this);
        this.changeLegalCallback = this.changeLegalCallback.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'public-business': {
            render() {
                return (
                    <>
                        <div className="public__pageBox">
                            <Business callPopupHandler={this.callPopupHandler.bind(this)} />
                        </div>
                    </>
                );
            },
        },
        'public-executors': {
            render() {
                return (
                    <>
                        <div className="public__pageBox">
                            <Executors />
                        </div>
                    </>
                );
            },
        },
        'public-blog': {
            render() {
                return (
                    <>
                        <div className="public__pageBox _full">
                            <Blog />
                        </div>
                    </>
                );
            },
        },
        'public-legal': {
            render() {
                return (
                    <>
                        {this.renderLegal()}
                        <div className="public__pageBox _full">
                            <Legal
                                setRenderNav={this.setRenderNav}
                                changeLegalCallback={this.changeLegalCallback}
                            />
                        </div>
                    </>
                );
            },
        },
    };

    callPopupHandler(isShowCallForm = !this.state.isShowCallForm) {
        this.setState({ isShowCallForm });
    }

    handlerMenu(isShow) {
        const html = document.querySelector('html');

        html.classList[isShow ? 'add' : 'remove']('_hidden');

        this.setState({ menuShow: isShow });
    }

    setRenderNav(fn) {
        this.renderLegalNav = fn;
    }

    handlerLegalNav(legalNavShow) {
        this.setState({ legalNavShow });
    }

    renderLegal() {
        const { device } = this.props;

        return (
            device === 'mobile' && (
                <>
                    <div
                        className="public__pageLegalButton _row"
                        onClick={() => {
                            this.handlerLegalNav(true);
                        }}
                    >
                        Содержание
                    </div>
                </>
            )
        );
    }

    handlerLegalMissClick(e) {
        const { target } = e;
        const content = this.parent.current.querySelector('.public__legalNavContent');

        if (content && !content.contains(target) && target !== content) {
            this.handlerLegalNav(false);
        }
    }

    changeLegalCallback() {
        this.handlerLegalNav(false);
    }

    componentDidMount() {
        setTimeout(() => {
            addYandexMetrika();
        }, 1_000);
    }

    componentWillUnmount() {
        removeYandexMetrika();
    }

    render() {
        const { menuShow, legalNavShow, isShowCallForm } = this.state;
        const { device } = this.props;

        return (
            <div ref={this.parent} className="public">
                <Animate
                    className="public__legalNav _col"
                    isShow={!!legalNavShow}
                    onClick={this.handlerLegalMissClick}
                >
                    <div className="public__legalNavContent">{this.renderLegalNav?.()}</div>
                </Animate>
                {device === 'mobile' && (
                    <Animate className="public__mobileMenu" isShow={!!menuShow}>
                        <MobileMenu
                            handlerMenu={this.handlerMenu}
                            callPopupHandler={this.callPopupHandler.bind(this)}
                        />
                    </Animate>
                )}
                <Animate className="public__callPopup" isShow={isShowCallForm}>
                    <CallPopup handler={this.callPopupHandler.bind(this)} />
                </Animate>
                <div className="public__top">
                    <PublicTop
                        handlerMenu={this.handlerMenu}
                        callPopupHandler={this.callPopupHandler.bind(this)}
                    />
                </div>
                <div className="public__content">
                    <Pages
                        classNamePage="JSpage public__page"
                        filter={(page) => page.parentName === 'public'}
                        pages={this.pages}
                        context={this}
                        fullHeight={true}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
        device: state.device,
    };
}

export default connect(mapStateToProps)(Public);

Public.propTypes = {
    windowIsReady: PropTypes.bool,
    device: PropTypes.string,
};
