import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const stopActs: I['stopActs'] = async function (force) {
    let response;

    try {
        response = await axios.patch<{}, { data: ReqResponseT<{ message?: string }> }>(
            `${process.env.REACT_APP_API}/pays`,
            { action: 'stopPays', ...(force === true ? { force } : {}) },
            { headers: getHeaders() },
        );
    } catch (error) {
        return Promise.reject();
    }

    const { success, data } = response.data;

    if (success) {
        if (!force) {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'stopPays',
                callback: stopActs.bind(this, true),
            });

            return;
        }

        this.tableUpdateItems!(true);

        return;
    }

    const { message } = data;

    if (message === 'Pays not found') {
        handlerPopup('alertPopup', {
            isShow: true,
            type: 'stopPaysEmpty',
            denied: true,
        });
    } else if (message === 'Process already start') {
        setNotification({ notification: 'pays-not-stop' });
    }
};

export default stopActs;
