import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField.tsx';

import I from '../types.ts';

import { CardsT, FieldT } from '../static/cards.ts';

const renderFields: I['renderFields'] = function (props) {
    const { updatedKeys } = this.state;
    const {
        name,
        change,
        model,
        getValue,
        error,
        prop: cardProd,
        addArrayItem,
        deleteArrayItem,
        data,
    } = this.props;
    const card = this.cards[name] as CardsT;
    let fieldsOrder =
        typeof card.fieldsOrder === 'function'
            ? card.fieldsOrder(model, this.props)
            : card.fieldsOrder;
    let FieldsTag: React.ElementType = 'div';
    let fieldsProps: Record<any, unknown> = {
        className: 'v2widgetCard__fields _ROW _ROWWRAP',
    };

    if (props?.fieldsOrder) {
        fieldsOrder = props.fieldsOrder;
    }

    if (props?.fragment) {
        FieldsTag = React.Fragment;
        fieldsProps = {};
    }

    return (
        <FieldsTag {...fieldsProps}>
            {fieldsOrder.map((fieldName, index) => {
                const field = card.fields[fieldName] as FieldT;
                const prop = field.prop || fieldName;
                const { width, margin } = this.getFieldWidth(fieldName, fieldsOrder);
                const disabled =
                    typeof field.disabled === 'function'
                        ? field.disabled(model, this.props)
                        : field.disabled;
                const value = getValue({ model, key: prop })?.value as any;

                let otherData;
                let errorName = field.prop || fieldName;

                if (cardProd) {
                    errorName = `${cardProd}.${errorName}`;
                }

                if (field.type === 'input') {
                    otherData = {
                        regExp: field.regExp,
                        reg: field.reg,
                        concat: field.concat,
                        max: field.max,
                        withCitySupport: field.withCitySupport,
                        asyncHandler: field.asyncHandler,
                        isAmount: field.isAmount,
                        textarea: field.textarea,
                    };
                }

                if (field.type === 'select') {
                    let selectText;

                    if (field.multi) {
                        selectText = (value as { name: string }[])
                            .map((item) => item.name)
                            .join(', ');
                    } else {
                        selectText = getValue({ model, key: field.selectTextKey! })?.value;
                    }

                    otherData = {
                        selectList: field.selectList,
                        selectProps: field.selectProps,
                        selectText: selectText || '',
                        setSelectChange: field.setSelectChange,
                        multi: field.multi,
                    };
                }

                if (field.type === 'date') {
                    otherData = {
                        withTime: field.withTime,
                        withPast: field.withPast,
                        isDouble: field.isDouble,
                        setDate: field.setDate,
                        reg: field.reg || 'date',
                    };
                }

                if (field.type === 'checkbox') {
                    otherData = {
                        checkboxTitle: field.checkboxTitle,
                        checkboxDescription: field.checkboxDescription,
                    };
                }

                if (field.type === 'checkboxes') {
                    otherData = {
                        checkboxesCol: field.checkboxesCol,
                        checkboxes: field.checkboxes,
                    };
                }

                if (field.type === 'text') {
                    otherData = {
                        text: field.text,
                    };
                }

                if (field.type === 'list') {
                    otherData = {
                        list: field.list,
                    };
                }

                if (field.type === 'files') {
                    otherData = {
                        galeryTitle: field.galeryTitle,
                        accepts: field.accepts,
                        maxFiles: field.maxFiles,
                    };
                }

                const onChange = async (
                    changedFields: Parameters<typeof change>[0],
                    update?: boolean,
                ): Promise<Awaited<ReturnType<typeof change>>> => {
                    let changedProps;
                    const keys: string[] = [];

                    if (update === true) {
                        keys.push(fieldName);
                    }

                    if (typeof field.setChange === 'function') {
                        changedProps = field.setChange({ fields: changedFields, model });

                        keys.push(...Object.keys(changedProps));
                    }

                    this.setUpdatedKeys({ keys });

                    await change({ ...changedFields, ...changedProps }, undefined, cardProd);
                };

                return (
                    <div
                        className={`v2widgetCard__field _${fieldName} _type${field.type}`}
                        key={field.type === 'line' ? `${fieldName}${index}` : fieldName}
                        style={{ width, marginRight: `${margin}px` }}
                    >
                        {!field.empty && (
                            <WidgetField
                                name={fieldName}
                                support={field.support}
                                type={field.type}
                                prop={prop}
                                onChange={onChange}
                                value={value}
                                disabled={disabled}
                                cardName={name}
                                iserror={error?.name === errorName}
                                setChange={field.setChange}
                                updatedKey={updatedKeys[fieldName]}
                                model={model}
                                addArrayItem={addArrayItem}
                                deleteArrayItem={deleteArrayItem}
                                getSupportInfo={field.getSupportInfo?.bind(null, model, this.props)}
                                defaultQuery={field.defaultQuery}
                                data={data}
                                action={field.action}
                                readOnly={field.readOnly}
                                {...otherData}
                            />
                        )}
                    </div>
                );
            })}
        </FieldsTag>
    );
};

export default renderFields;
