const executorsMvds = {
    colsOrder: ['name', 'executorStatus', 'date', 'number', 'status', 'comment'],
    cols: {
        name: {
            content: 'Исполнитель',
            width: (admin: boolean) => (admin ? 19 : 25),
        },
        executorStatus: {
            content: 'Статус',
            width: 11,
        },
        date: {
            content: (admin: boolean) => (admin ? 'Дата' : 'Дата подачи'),
            isAdminMain: true,
            width: (admin: boolean) => (admin ? 21 : 12),
        },
        number: {
            content: '№ заявления',
            width: 13,
        },
        status: {
            content: 'Заявление',
            width: (admin: boolean) => (admin ? 15 : 17),
        },
        comment: {
            content: 'Комментарий',
            width: (admin: boolean) => (admin ? 21 : 22),
        },
    },
    adminIndex: 4,
    deletesCols: ['number'],
    url: 'executor-mvd',
    emptyInfo: {
        title: 'Уведомлений пока нет',
        description: 'Они будут появляться здесь<br/>при подключении исполнителей ЕАЭС',
    },
} as const;

export default executorsMvds;
