import getFormatedNumber from './getFormatedNumber.ts';

const namesMonths = [
    ['Январь', 'Января'],
    ['Февраль', 'Февраля'],
    ['Март', 'Марта'],
    ['Апрель', 'Апреля'],
    ['Май', 'Мая'],
    ['Июнь', 'Июня'],
    ['Июль', 'Июля'],
    ['Август', 'Августа'],
    ['Сентябрь', 'Сентября'],
    ['Октябрь', 'Октября'],
    ['Ноябрь', 'Ноября'],
    ['Декабрь', 'Декабря'],
];

export { namesMonths };

export default function getFormatedDate({
    date,
    type,
    withSeconds = false,
    isShortYear = false,
    withTimezone = false,
    MSK = false,
    withoutYear,
}) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(date)) {
        return 'Invalid date';
    }

    let timeZone = new Date().getTimezoneOffset() / -60;

    if (MSK) {
        const mskDiff = timeZone - 4;

        date.setUTCHours(date.getUTCHours() + mskDiff);
    }

    let dateReturn = '';
    const year = isShortYear ? date.getFullYear().toString().slice(2, 4) : date.getFullYear();

    if (date) {
        switch (type) {
            case 'full':
                dateReturn += getFormatedNumber(date.getDate());
                dateReturn += '.';
                dateReturn += getFormatedNumber(date.getMonth() + 1);

                if (!withoutYear) {
                    dateReturn += '.';
                    dateReturn += getFormatedNumber(year);
                }

                dateReturn += ' в ';
                dateReturn += getFormatedNumber(date.getHours());
                dateReturn += ':';
                dateReturn += getFormatedNumber(date.getMinutes());

                if (withSeconds) {
                    dateReturn += ':';
                    dateReturn += getFormatedNumber(date.getSeconds());
                }

                break;
            case 'utc':
                dateReturn += getFormatedNumber(date.getUTCDate());
                dateReturn += '.';
                dateReturn += getFormatedNumber(date.getUTCMonth() + 1);
                dateReturn += '.';
                dateReturn += getFormatedNumber(year);
                dateReturn += ', ';
                dateReturn += getFormatedNumber(date.getUTCHours());
                dateReturn += ':';
                dateReturn += getFormatedNumber(date.getUTCMinutes());

                break;
            case 'fullText':
                dateReturn += date.getDate();
                dateReturn += ' ';
                dateReturn += namesMonths[date.getMonth()][1].toLowerCase();
                dateReturn += ' в ';
                dateReturn += getFormatedNumber(date.getHours());
                dateReturn += ':';
                dateReturn += getFormatedNumber(date.getMinutes());
                break;
            case 'time':
                dateReturn += getFormatedNumber(date.getHours());
                dateReturn += ':';
                dateReturn += getFormatedNumber(date.getMinutes());

                if (withSeconds) {
                    dateReturn += ':';
                    dateReturn += getFormatedNumber(date.getSeconds());
                }

                break;
            case 'text':
                dateReturn += date.getDate();
                dateReturn += ' ';
                dateReturn += namesMonths[date.getMonth()][1].toLowerCase();
                break;
            case 'textYear':
                dateReturn += date.getDate();
                dateReturn += ' ';
                dateReturn += namesMonths[date.getMonth()][1].toLowerCase();
                dateReturn += ' ';
                dateReturn += getFormatedNumber(year);
                dateReturn += ' г.';
                break;
            default:
                dateReturn += getFormatedNumber(date.getDate());
                dateReturn += `.`;
                dateReturn += getFormatedNumber(date.getMonth() + 1);
                dateReturn += `.`;
                dateReturn += getFormatedNumber(year);
                break;
        }
    }

    if (withTimezone) {
        if (timeZone < 10) {
            timeZone = `0${timeZone}`;
        }

        dateReturn += ` GMT +${timeZone}00`;
    }

    if (MSK) {
        dateReturn += ' (МСК)';
    }

    return dateReturn;
}