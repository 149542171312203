import removeTransition from '@functions/removeTransition.ts';

import ListAbsoluteMainI from '../types.ts';

const setParams: ListAbsoluteMainI['setParams'] = function (params) {
    const {
        className,
        callback,
        isSmoothShow = false,
        isNotNullParentSize = false,
        maxHeight,
        realyItems,
    } = this.props;
    const { width } = params;
    let { height } = params;

    if (typeof maxHeight === 'number' && (height as number) > maxHeight) {
        height = maxHeight;
    }

    if (
        (width && height && (width !== this.state.width || height !== this.state.height)) ||
        realyItems
    ) {
        if (!this.isNotNullInit) {
            this.isNotNullInit = true;

            if (callback) {
                callback({
                    type: 'parent',
                    parent: this.parent.current as HTMLElement,
                    params: { width, height },
                    isInit: true,
                });
            }
        }
    }

    if (
        (!isNotNullParentSize || (width && height)) &&
        (width !== this.state.width || height !== this.state.height)
    ) {
        this.setState({ width, height }, () => {
            if (callback) {
                callback({
                    parent: this.parent.current as HTMLElement,
                    type: 'parent',
                    params: { width, height },
                    isRealInit: !this.isInit,
                });
            }

            if (!this.isInit) {
                if (className && !isSmoothShow) {
                    removeTransition({
                        item: `.${className.replace(/ /g, '.')}`,
                        isCurrent: true,
                    });
                }

                this.isInit = true;
            }
        });
    }
};

export default setParams;
