import uploadFile from '@requests/uploadFile.ts';

import I from '../types.ts';

const filesHandler: I['filesHandler'] = async function (e) {
    const { model, addArrayItem, onChange, prop, maxFiles } = this.props;
    const files = e.target.files!;
    let currentMax: number;

    if (maxFiles) {
        currentMax = maxFiles - model[prop].length;
    }

    if (maxFiles && model[prop].length >= maxFiles) {
        return;
    }

    const ids = (await addArrayItem!({
        key: prop,
        item: Object.keys(files)
            .filter((item, index) => currentMax === undefined || index < currentMax)
            .map(() => ({})),
    })) as string[];

    await Promise.all(
        (Object.keys(files) as (keyof typeof files)[]).map(async (key, index) => {
            if (currentMax === undefined || index < currentMax) {
                const id = ids[index];
                const file = files[key];

                const formData = new FormData();

                formData.set('file', file as File);

                const { fullSrc, src, size, name } = await uploadFile({ formData });

                onChange({
                    [`${prop}.${id}.fullSrc`]: fullSrc,
                    [`${prop}.${id}.src`]: src,
                    [`${prop}.${id}.size`]: size,
                    [`${prop}.${id}.name`]: name,
                });
            }
        }),
    );
};

export default filesHandler;
