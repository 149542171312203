import { deleteCursorActions } from '@functions/actionsHandler.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { store } from '@redux/redux.ts';

import CursorActionsI from '../types.ts';

const itemHandler: CursorActionsI['itemHandler'] = async function ({ item, action }) {
    const cursorActions = store.getState().cursorActions;
    const cursorAction = cursorActions
        .find((innerItem) => innerItem._id === item._id)
        ?.items.find((innerAction) => innerAction.name === action.name);

    await handlerLoading.call(this, action.name);

    try {
        await cursorAction?.onClick();

        deleteCursorActions({ id: item._id });
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default itemHandler;
