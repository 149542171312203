import checkClosestParent from './checkClosestParent';
import checkTagParent from './checkTagParent';
import getAllNodes from './getAllNodes';

const actionsInfo = require('../../../infos/crm/editor.json');

export default function handlerInlineNode({ node, key, wrap, updateNode, ...props }) {
    const action = actionsInfo.actions[key];
    const closestParent = checkClosestParent({ node, ...action });
    let resultNode = node;
    const { color, link, targetBlank } = props;

    if (closestParent) {
        const parentNodes = getAllNodes(closestParent);

        parentNodes.forEach((parentNode) => {
            const wrapper = closestParent.cloneNode();
            const clonedNode = parentNode.cloneNode();

            wrapper.appendChild(clonedNode);

            if (parentNode === node) {
                resultNode = clonedNode;
            }

            if (key === 'link') {
                parentNode.replaceWith(...wrapper.childNodes);
            } else {
                parentNode.replaceWith(wrapper);
            }

            if (updateNode) {
                updateNode(parentNode, clonedNode);
            }
        });

        closestParent.replaceWith(...closestParent.childNodes);
    }

    if (!wrap || (key === 'color' && color === 'remove')) {
        if (closestParent && resultNode.parentNode) {
            resultNode.parentNode.replaceWith(resultNode);
        }
    } else if (
        resultNode.parentNode &&
        !checkTagParent({ node: resultNode.parentNode, ...action })
    ) {
        const wrapper = document.createElement(action.tag);

        if (action.className) {
            wrapper.classList.add(action.className);
        }

        if (key === 'color') {
            wrapper.style.color = color;
        }

        if (key === 'link') {
            wrapper.setAttribute('href', link);

            if (targetBlank) {
                wrapper.setAttribute('target', '_blank');
            }
        }

        if (key === 'ancor') {
            wrapper.setAttribute('data-ancor', props.ancorId);
        }

        const clonedNode = resultNode.cloneNode();

        wrapper.appendChild(clonedNode);

        resultNode.replaceWith(wrapper);

        resultNode = clonedNode;
    } else if (key === 'color') {
        resultNode.parentNode.style.color = color;
    }

    // console.log(resultNode);

    return resultNode;
}
