const problemCards = [
    {
        icon: 'about-icon-1.svg',
        title: 'Риск переквалификации трудовых отношений',
        content:
            'При сотрудничестве с самозанятыми возникает риск переквалификации отношений с ними в трудовые. Внимание налоговой привлекают компании, которые взаимодействуют с ИП и самозанятыми.',
    },
    {
        icon: 'about-icon-2.svg',
        title: 'Онбординг и выплаты',
        content:
            'Работа с самозанятыми влечет за собой дополнительную нагрузку. Приходится проверять статус партнера,  обмениваться документами, ждать их подписания, проверять данные в чеке и собирать вручную закрывающие документы.',
    },
    {
        icon: 'about-icon-3.svg',
        title: 'Самозанятые иностранцы (граждане ЕАЭС)',
        content:
            'Сотрудничая с самозанятыми нерезидентами, необходимо подавать уведомление в МВД о заключении договора с иностран-цем в течение 3 рабочих дней со дня его подписания. Все операции по выплатам возна-граждения попадают под валютный контроль',
    },
] as const;

export default problemCards;
