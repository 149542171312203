import React from 'react';

import CallPopupI from '../types.ts';

import { fieldsOrder } from '../static/fields.ts';

const renderContent: CallPopupI['renderContent'] = function () {
    return (
        <div className="publicCallPopup__content">
            <div className="publicCallPopup__form _ROW">
                {fieldsOrder.map((name) => this.renderField(name))}
            </div>
        </div>
    );
};

export default renderContent;
