const rejectVerification = {
    title: 'Отказ в верификации',
    description: `Введите причину для отказа в поле ниже`,
    buttonText: 'Отправить на доработку',
    textMessage: {
        support: 'Причина',
    },
} as const;

export default rejectVerification;
