import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import removeTransition from '../../../functions/removeTransition.ts';

// import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import { dispatcher } from '../../../redux/redux';
import getNotifications from '../../../requests/getNotifications';
import Animate from '../../Animate.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import ListScroll from '../../ListScroll.jsx';
import Loader from '../../Loader.jsx';
import Table from '../manual/Table.jsx';
import Notification from './Notification.jsx';

class Notifications extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderNotification = this.renderNotification.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.hideListCallback = this.hideListCallback.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 10;

    renderNotification({ item }) {
        if (item.id === 'settings') {
            const { isHideAudioNotification } = this.props;

            return (
                <div className="notificationsBar__item">
                    <div className="notificationsBar__itemInner">
                        <div className="notificationsBar__settings _col">
                            <div
                                className="notificationsBar__settingsInner"
                                onClick={() => {
                                    dispatcher({
                                        type: 'isHideAudioNotification',
                                        data: !isHideAudioNotification,
                                        storage: {
                                            isSet: !isHideAudioNotification,
                                        },
                                    });
                                }}
                            >
                                <AnimateChangeUp
                                    className="notificationsBar__settingsBtn _click"
                                    renderKey={isHideAudioNotification}
                                >
                                    {isHideAudioNotification
                                        ? 'Включить звуковое оповещение'
                                        : 'Выключить звуковое оповещение'}
                                </AnimateChangeUp>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="notificationsBar__item">
                <div className="notificationsBar__itemInner">
                    <Notification {...item} />
                </div>
            </div>
        );
    }

    getNotifications() {
        const items = [...(this.state.items || [])].sort((a, b) => b.updateDateTime - a.updateDateTime);

        return [{ _id: 'settings', id: 'settings' }, ...items];
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getNotifications(query).then(
                ({ notifications, isLimit, counter }) => {
                    this.setItems(notifications, false, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    hideListCallback() {
        const notifications = this.getNotifications();
        const items = notifications.filter((item, key) => key < this.stepCounter);

        document.dispatchEvent(
            new CustomEvent('handlerListDynamic', {
                detail: {
                    name: 'notifications',
                    items,
                    callback: () => {
                        this.setState(
                            {
                                counterScroll: this.stepCounter,
                                isListHide: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({ isListHide: false });
                                }, 300);
                            },
                        );
                    },
                },
            }),
        );
    }

    deleteNotification({ id }) {
        this.setState((state) => {
            const newState = { ...state };
            const notifications = JSON.parse(JSON.stringify(newState.items));

            const index = notifications.findIndex((item) => item._id === id);

            if (index !== -1) {
                notifications.splice(index, 1);
            }

            newState.items = notifications;

            return newState;
        });
    }

    checkChangeCorporation() {
        const { user } = this.props;

        if (user && user.idOfCurrentCorporation !== this.corporationId) {
            if (this.corporationId) {
                this.updateItems();
            }

            this.corporationId = user.idOfCurrentCorporation;
        }
    }

    checkChangeNotifications({ oldNotifications, notifications }) {
        let isChange = false;

        if (notifications.length > oldNotifications.length) {
            isChange = true;
        } else if (notifications.length === oldNotifications.length) {
            notifications.forEach((notification) => {
                const oldNotification = oldNotifications.find(
                    (item) => item._id === notification._id,
                );

                if (
                    oldNotification &&
                    new Date(notification.updatedDate).getTime() !==
                        new Date(oldNotification.updatedDate).getTime()
                ) {
                    isChange = true;
                }
            });
        }

        if (isChange) {
            this.playNotificationMusic();
        }
    }

    timerCounter = 0;

    setCheckCounter() {
        this.timerCounter += 1;
    }

    checkCounter() {
        if (this.timerCounter > 0) {
            this.timerCounter = 0;

            this.getItems();
        }
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;
        const { type } = data;

        if (name === 'corporation' && type === 'notifications') {
            const { deleteNotificationId } = data;

            if (deleteNotificationId) {
                this.deleteNotification({ id: deleteNotificationId });
            }

            this.setCheckCounter();
        }
    }

    componentDidMount() {
        this.checkChangeCorporation();
        this.getItems();

        document.addEventListener('getSocketData', this.handlerSocket);

        this.intervalCounterId = setInterval(() => {
            this.checkCounter();
        }, 2_000);
    }

    componentDidUpdate() {
        this.checkChangeCorporation();
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);

        clearInterval(this.intervalCounterId);
    }

    render() {
        const {
            isReady,
            isDisabledScroll,
            isShowLoaderList,
            isListHide,
            isLimit,
            updateListKey,
            isLoadingFilter = false,
        } = this.state;
        const { isChangeCorporationProccess, idOfCurrentCorporation } = this.props;
        const notifications = this.getNotifications();
        const filteredNotifications = notifications.filter((item) => item.id !== 'settings');

        return (
            <div ref={this.parent} className="widget__blockInner">
                <Animate
                    className="notificationsBar__loader _loader"
                    isShow={!isReady || !!isChangeCorporationProccess || isLoadingFilter}
                >
                    <div className="notificationsBar__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <Animate
                    className="notificationsBar__empty"
                    isShow={
                        isReady &&
                        !isChangeCorporationProccess &&
                        filteredNotifications.length === 0 &&
                        !isLoadingFilter
                    }
                >
                    <div className="empty _col _notBack _block">
                        <div className="empty__inner">
                            <div className="empty__title">
                                На данный момент
                                <br />
                                уведомлений нет
                            </div>
                            <p className="empty__content">
                                Они будут отображаться
                                <br />
                                здесь в виде карточек
                            </p>
                        </div>
                    </div>
                </Animate>
                <Animate
                    className="notificationsBar__scrollLoader _loaderScroll"
                    isShow={isShowLoaderList}
                >
                    <div className="notificationsBar__scrollLoaderItem _loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
                <div className="widget__contentInner">
                    <Animate
                        className="notificationsBar__box"
                        isShow={!isChangeCorporationProccess && isReady}
                    >
                        <div className="notificationsBar__itemsInner" key={idOfCurrentCorporation}>
                            <ListScroll
                                isInit={isReady}
                                getParent={() =>
                                    this.parent.current?.querySelector('.widget__contentInner')
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={notifications.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={
                                    isDisabledScroll || !isReady || isListHide || isLoadingFilter
                                }
                                hideCallback={this.hideListCallback}
                            >
                                <ListAbsoluteMain
                                    name="notifications"
                                    className="notificationsBar__items _col"
                                    items={notifications}
                                    renderItem={this.renderNotification}
                                    classNameItem="notificationsBar__item"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    keyUpdateItem={updateListKey}
                                    callback={({ isInit }) => {
                                        if (isInit) {
                                            removeTransition({ item: '.notificationsBar__item' });
                                        }
                                    }}
                                />
                            </ListScroll>
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isHideAudioNotification: state.isHideAudioNotification,
    };
}

export default connect(mapStateToProps)(Notifications);

Notifications.propTypes = {
    user: PropTypes.object,
    isHide: PropTypes.bool,
    handlerHideState: PropTypes.func,
    setCounter: PropTypes.func,
    notifications: PropTypes.array,
    isChangeCorporationProccess: PropTypes.bool,
    idOfCurrentCorporation: PropTypes.string,
    isHideAudioNotification: PropTypes.bool,
};
