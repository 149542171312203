import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import ServerNotAvailablePopup from './ServerNotAvailablePopup.jsx';

class ServerNotAvailablePopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { serverNotAvailablePopup } = this.props;

        return (
            <Animate className="body__mvdPopup" isShow={serverNotAvailablePopup?.isShow}>
                <ServerNotAvailablePopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverNotAvailablePopup: state.serverNotAvailablePopup,
    };
}

export default connect(mapStateToProps)(ServerNotAvailablePopupWrapper);

ServerNotAvailablePopupWrapper.propTypes = {
    serverNotAvailablePopup: PropTypes.object,
};
