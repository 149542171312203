import CalendarI from '@components/calendar/types';
import WidgetFieldI from '@components/crm/widgetField/types';
import { CursorListsTypesT } from '@components/cursorListsItem/types';
import { IconT } from '@components/icon/types';
import { FieldRegsT, InputConcatT } from '@components/input/types';
import { FilterQueryT } from '@global/types';

import cityMain from './cards/cityMain';
import contract from './cards/contract';
import corporationAmountDoc from './cards/corporationAmountDoc';
import corporationIntegration1C from './cards/corporationIntegration1C';
import corporationIntegrationTg from './cards/corporationIntegrationTg';
import corporationLimits from './cards/corporationLimits';
import corporationMain from './cards/corporationMain';
import corporationOther from './cards/corporationOther';
import corporationTariff from './cards/corporationTariff';
import countryMain from './cards/countryMain';
import docEdit from './cards/docEdit';
import executorCreate from './cards/executorCreate';
import executorLimits from './cards/executorLimits';
import executorMain from './cards/executorMain';
import executorMvd from './cards/executorMvd';
import executorShortMain from './cards/executorShortMain';
import executorTypeMain from './cards/executorTypeMain';
import joinDocMain from './cards/joinDocMain';
import joinScriptMain from './cards/joinScriptMain';
import payEdit from './cards/payEdit';
import payProjects from './cards/payProjects';
import roleEdit from './cards/roleEdit';
import tags from './cards/tags';
import userEdit from './cards/userEdit';
import userMain from './cards/userMain';
import userPassport from './cards/userPassport';
import walletAccount from './cards/walletAccount';
import walletCard from './cards/walletCard';

type FieldT = {
    support?: string;
    getSupportInfo?: (
        model: any,
        props: Record<any, unknown>,
    ) =>
        | {
              text: string;
              className: string;
          }
        | undefined;
    type: WidgetFieldI['props']['type'];
    prop?: string;
    group?: string;
    width?: number | ((model: any, data?: Record<any, unknown>) => number);
    regExp?: RegExp;
    reg?: FieldRegsT;
    max?: number;
    concat?: InputConcatT;
    selectTextKey?: string;
    selectList?: CursorListsTypesT;
    selectProps?: Record<string, string | ((data: any) => { key: string; text: string })>;
    setSelectChange?: (model?: any) => Record<string, unknown>;
    setChange?: (data: { fields?: any; model: any }) => Record<string, unknown>;
    disabled?: boolean | ((model: any, data?: Record<any, unknown>) => boolean);
    withTime?: boolean;
    isDouble?: boolean;
    withPast?: boolean;
    setDate?: (data: Parameters<NonNullable<CalendarI['props']['callback']>>[0]) => string;
    checkboxTitle?: string;
    checkboxDescription?: string;
    checkboxesCol?: boolean;
    checkboxes?: (data: any) => { id: string; title: string; description?: string }[];
    empty?: boolean;
    withCitySupport?: boolean;
    text?: string;
    defaultQuery?: FilterQueryT[] | ((model: any, data: any) => FilterQueryT[]);
    asyncHandler?: {
        cond: (value: string) => boolean;
        handler: (value: string) => Promise<Record<any, unknown>>;
    };
    isAmount?: boolean;
    list?: {
        name: CursorListsTypesT;
        button: string;
        render: (data: any) => React.ReactNode;
        multi?: boolean;
    };
    textarea?: boolean;
    galeryTitle?: string | ((model: any) => string);
    accepts?: string;
    maxFiles?: number;
    multi?: boolean;
    inputSupport?: string;
    readOnly?: boolean;
    action?: (
        model: any,
        data?: Record<any, unknown>,
    ) =>
        | undefined
        | {
              icon: IconT;
              onClick: () => void;
              support?: string;
          };
};

type CardsT = {
    title?: string | ((model: any, data?: Record<any, unknown>) => string | undefined);
    description?: string | ((model: any, data?: Record<any, unknown>) => string | undefined);
    fieldsOrder:
        | readonly string[]
        | ((model: any, data?: Record<any, unknown>) => readonly string[]);
    fields: Record<string, FieldT>;
    widthPercent?: boolean;
};

const cards = {
    payProjects,
    tags,
    payEdit,
    contract,
    userEdit,
    userMain,
    userPassport,
    corporationMain,
    corporationOther,
    corporationTariff,
    corporationLimits,
    corporationAmountDoc,
    roleEdit,
    docEdit,
    cityMain,
    countryMain,
    joinDocMain,
    executorTypeMain,
    joinScriptMain,
    executorMain,
    executorShortMain,
    walletCard,
    walletAccount,
    executorCreate,
    executorMvd,
    executorLimits,
    corporationIntegrationTg,
    corporationIntegration1C,
} as const;

export default cards;

export type { CardsT, FieldT };
