import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getPage from '@functions/getPage.ts';

import setPermissions from '../../../functions/crm/setPermissions';

import getExecutorMvds from '../../../requests/getExecutorMvds';
import getJoinContracts from '../../../requests/getJoinContracts';
import getNotifications from '../../../requests/getNotifications';
import getPays from '../../../requests/getPays';
import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import NotificationCounter from '../../NotificationCounter.jsx';

const pages = require('../../../redux/pages').default;

class SideBarNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.filterPage = this.filterPage.bind(this);
        this.filterChildPage = this.filterChildPage.bind(this);
        this.initNav = this.initNav.bind(this);
        this.changeCounter = this.changeCounter.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    maxLen = 3;

    iconsNav = {
        corporations: 'sideBar-corporations',
        orders: 'sideBar-orders',
        market: 'sideBar-orders',
        monitor: 'sideBar-drivers',
        reports: 'sideBar-reports',
        pays: 'sideBar-pays',
        joins: 'sideBar-joins',
        manual: 'sideBar-manual',
    };

    timersNav = {};

    handlerNav({ name, params }) {
        const { setUpdateNavCounter } = this.props;

        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const nav = JSON.parse(JSON.stringify(newState.nav));

                    Object.keys(params).forEach((prop) => {
                        nav[name][prop] = params[prop];
                    });

                    newState.nav = nav;

                    return newState;
                },
                () => {
                    setUpdateNavCounter();

                    resolve();
                },
            );
        });
    }

    handlerList({ name, isShow }) {
        const { nav } = this.state;

        const currentBlock = this.parent.current.querySelector(
            `.sideBar__navBlock[data-name="${name}"]`,
        );
        const list = currentBlock.querySelector('.sideBar__navBlockListInner');
        const { offsetHeight: heightList } = list;

        if (this.timersNav[name]) {
            clearTimeout(this.timersNav[name]);
        }

        if (isShow === undefined) {
            isShow = !nav[name].isShow;

            const navBlocksStates = this.getLocalBlocksStates() || {};

            navBlocksStates[name] = isShow;

            localStorage.setItem('navBlocksStates', JSON.stringify(navBlocksStates));
        }

        this.handlerNav({ name, params: { heightList } }).then(() => {
            if (isShow) {
                this.handlerNav({ name, params: { isShow } }).then(() => {
                    this.timersNav[name] = setTimeout(() => {
                        this.handlerNav({ name, params: { isFresh: true } });
                    }, 300);
                });
            } else {
                this.handlerNav({ name, params: { isFresh: false } }).then(() => {
                    this.timersNav[name] = setTimeout(() => {
                        this.handlerNav({ name, params: { isShow } });
                    }, 10);
                });
            }
        });
    }

    filterPage(page) {
        const { user } = this.state;
        const childPages = pages.filter((childPage) => childPage.parentName === page.name);
        const { getCond } = page;

        if (!user) {
            return false;
        }

        const childConds = !!childPages.find(
            (childPage) => !childPage.getCond || childPage.getCond({ user }).condition,
        );

        if (getCond) {
            return getCond({ user }).condition && childConds;
        }

        return true;
    }

    filterChildPage(page) {
        const { user } = this.state;
        const { getCond } = page;

        if (!user) {
            return false;
        }

        if (getCond) {
            return getCond({ user }).condition;
        }

        return true;
    }

    getLocalBlocksStates() {
        const navBlocksStates = localStorage.getItem('navBlocksStates')
            ? JSON.parse(localStorage.getItem('navBlocksStates'))
            : null;

        return navBlocksStates;
    }

    getPages() {
        return pages.filter((page) =>
            ['corporations', 'joins', 'pays', 'orders', 'reports', 'manual'].includes(page.name),
        );
    }

    initNav() {
        const pagesNav = this.getPages().filter(this.filterPage);

        this.setState((state) => {
            const newState = { ...state };
            const nav = {};
            const navBlocksStates = this.getLocalBlocksStates();

            pagesNav.forEach((page) => {
                const isShow = navBlocksStates?.[page.name] || false;

                nav[page.name] = {
                    isShow,
                    isFresh: isShow,
                };
            });

            newState.nav = nav;

            return newState;
        });
    }

    counterPages = ['joins-invites', 'joins-contracts', 'joins-mvd', 'pays-main'];

    renderCounter({ name }) {
        if (this.counterPages.includes(name)) {
            const counter = this.state[name] || 0;

            return (
                <NotificationCounter
                    className="sideBar__navBlockListItemCounter"
                    counterClassName="_minSize"
                    counter={counter}
                />
            );
        }

        return null;
    }

    getCounter({ name }) {
        const { user } = this.state;

        const requestCounter = () =>
            new Promise((resolve) => {
                if (name === 'joins-invites') {
                    getNotifications({
                        params: [
                            { key: 'type', value: 'counter' },
                            { key: 'name', value: 'checkJoinExecutor' },
                        ],
                    }).then(resolve, resolve);
                }

                if (name === 'joins-contracts') {
                    getJoinContracts({
                        params: [
                            { key: 'dataType', value: 'counter' },
                            { key: 'signStatuses', value: 'notCorporation' },
                        ],
                    }).then(resolve, resolve);
                }

                if (name === 'joins-mvd') {
                    getExecutorMvds({
                        params: [{ key: 'type', value: 'counter' }],
                    }).then(resolve, resolve);
                }

                if (name === 'pays-main') {
                    getPays({
                        params: [
                            { key: 'type', value: 'counter' },
                            { key: 'notCompleted', value: 'true' },
                            { key: 'groupId', value: 'main' },
                        ],
                    }).then(resolve, resolve);
                }
            });

        const page = getPage({ name });

        if (page && (!page.getCond || page.getCond({ user }).condition)) {
            requestCounter().then((info) => {
                if (typeof info?.counter === 'number') {
                    this.setState({ [name]: info.counter });
                }
            });
        }
    }

    changeCorporation() {
        this.counterPages.forEach((name) => {
            this.getCounter({ name });
        });
    }

    changeCounter({ detail }) {
        const { name } = detail;

        this.getCounter({ name });
    }

    componentDidMount() {
        this.setState({ user: this.props.user }, this.initNav);

        this.counterPages.forEach((name) => {
            this.getCounter({ name });
        });

        document.addEventListener('setUser', this.initNav);
        document.addEventListener('changeNavCounter', this.changeCounter);
    }

    componentWillUnmount() {
        document.removeEventListener('setUser', this.initNav);
        document.removeEventListener('changeNavCounter', this.changeCounter);
    }

    render() {
        const { nav } = this.state;
        const pagesNav = this.getPages();

        return (
            <div ref={this.parent} className="sideBar__nav">
                <div className="sideBar__navBlocks">
                    {pagesNav.filter(this.filterPage).map((page) => {
                        const { name, contentOfLink } = page;
                        let childs = pages
                            .filter((child) => child.level === 2 && child.parentName === name)
                            .filter(this.filterChildPage);

                        if (name === 'corporations') {
                            childs = [];
                        }

                        const currentNav = nav?.[name];
                        const HeadTag = Link;
                        const headProps = ['orders', 'corporations'].includes(name)
                            ? { isStopPropagation: true }
                            : {
                                  onClick: () => null,
                              };

                        return (
                            <div
                                className={`sideBar__navBlock ${
                                    currentNav?.isShow ? '_show' : ''
                                } ${childs.length === 0 ? '_empty' : ''}`}
                                key={name}
                                data-name={name}
                            >
                                <div className="sideBar__navBlockInner">
                                    <div
                                        className={`sideBar__navBlockHead _col ${
                                            childs.length > 0 ? '_click' : ''
                                        }`}
                                        onClick={() => {
                                            if (childs.length > 0) {
                                                this.handlerList({ name });
                                            }
                                        }}
                                    >
                                        <HeadTag
                                            className="sideBar__navBlockHeadInner _click"
                                            pageName={name}
                                            {...headProps}
                                        >
                                            <i className="sideBar__navBlockHeadIcon">
                                                <Icon name={this.iconsNav[name]} />
                                            </i>
                                            {contentOfLink}
                                        </HeadTag>
                                    </div>
                                    {childs.length > 0 && (
                                        <div
                                            className="sideBar__navBlockList"
                                            style={
                                                currentNav?.isShow
                                                    ? currentNav.isFresh
                                                        ? {}
                                                        : {
                                                              height: `${currentNav.heightList}px`,
                                                          }
                                                    : { height: 0 }
                                            }
                                        >
                                            <div className="sideBar__navBlockListInner _col">
                                                {childs.map((child) => {
                                                    const {
                                                        name: nameChild,
                                                        contentOfLink: contentOfLinkChild,
                                                    } = child;

                                                    return (
                                                        <Link
                                                            notSave={true}
                                                            pageName={nameChild}
                                                            className="sideBar__navBlockListItem"
                                                            key={nameChild}
                                                        >
                                                            {this.renderCounter({
                                                                name: nameChild,
                                                            })}
                                                            {contentOfLinkChild}
                                                        </Link>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isProccessLogin: state.isProccessLogin,
    };
}

export default connect(mapStateToProps)(SideBarNav);

SideBarNav.propTypes = {
    user: PropTypes.object,
    isProccessLogin: PropTypes.bool,
    id: PropTypes.string,
    setUpdateNavCounter: PropTypes.func,
};
