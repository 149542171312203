import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CodeBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeInput = this.changeInput.bind(this);
        this.blurInputs = this.blurInputs.bind(this);
        this.handlerDown = this.handlerDown.bind(this);
        this.setInputs = this.setInputs.bind(this);

        this.parent = React.createRef();
    }

    inputs = this.props.inputs || [1, 2, 3, 4];

    blurInputs() {
        this.parent.current.querySelectorAll(`input`).forEach((input) => {
            input.blur();
        });
    }

    setError() {
        this.setState(
            (state) => {
                const newState = { ...state };

                newState.isError = true;
                newState.isErrorActivate = true;

                return newState;
            },
            () => {
                this.blurInputs();
            },
        );

        setTimeout(() => {
            this.setState(
                (state) => {
                    const newState = { ...state };
                    const inputs = [...newState.inputs];

                    inputs.forEach((input, keyI) => {
                        inputs[keyI].value = '';
                    });

                    newState.inputs = inputs;
                    newState.isErrorActivate = false;
                    newState.isError = false;

                    return newState;
                },
                () => {
                    this.parent.current.querySelector(`input#input-confirmEmailInput-${0}`).focus();
                },
            );
        }, 700);
    }

    setSuccess() {
        const { callbackSuccess } = this.props;

        setTimeout(() => {
            if (callbackSuccess && typeof callbackSuccess === 'function') {
                callbackSuccess();
            }
        }, 2000);
    }

    changeInput({ key, value }) {
        const { callback, inMoment } = this.props;
        const saveVal = value.replace(/[^a-zA-Z0-9]/, ``).toUpperCase();
        let updateValue;

        this.setState(
            (state) => {
                const newState = { ...state };
                const inputs = [...newState.inputs];

                updateValue = value.replace(/[^a-zA-Z0-9]/, ``).toUpperCase();

                if (updateValue.length > 1) {
                    for (let i = key + 1; i < this.inputs.length; i++) {
                        inputs[i].value = updateValue.split('')[i - key] || '';
                    }
                }

                const [firstLetter] = updateValue;

                updateValue = firstLetter;

                inputs[key].value = updateValue || ``;

                if (
                    (!inputs.find((input) => input.value === '') || inMoment) &&
                    callback &&
                    typeof callback === 'function'
                ) {
                    if (!inMoment) {
                        this.blurInputs();
                    }

                    callback(
                        inputs
                            .filter((input) => input.value)
                            .map((input) => input.value)
                            .join(''),
                    ).then(
                        (success) => {
                            if (success === true) {
                                this.setSuccess();
                            } else {
                                this.setError();
                            }
                        },
                        () => null,
                    );
                }

                newState.inputs = inputs;

                return newState;
            },
            () => {
                if (updateValue && key !== this.inputs.length - 1) {
                    this.parent.current
                        .querySelector(`input#input-confirmEmailInput-${key + 1}`)
                        .focus();
                }

                if (saveVal.length > 1) {
                    if (saveVal.length < this.inputs.length) {
                        const offset = saveVal.length - key;
                        if (
                            this.parent.current.querySelector(
                                `input#input-confirmEmailInput-${offset}`,
                            )
                        ) {
                            this.parent.current
                                .querySelector(`input#input-confirmEmailInput-${offset}`)
                                .focus();
                        } else {
                            this.blurInputs();
                        }
                    } else if (
                        this.parent.current.querySelector(
                            `input#input-confirmEmailInput-${key + 1}`,
                        )
                    ) {
                        this.parent.current
                            .querySelector(`input#input-confirmEmailInput-${key + 1}`)
                            .blur();
                    }
                }
            },
        );
    }

    handlerDown(key) {
        this.setState((state) => {
            const newState = { ...state };
            const inputs = [...newState.inputs];

            inputs[key].isKeyDown = true;

            newState.inputs = inputs;
            return newState;
        });

        setTimeout(() => {
            this.setState((state) => {
                const newState = { ...state };
                const inputs = [...newState.inputs];

                inputs[key].isKeyDown = false;

                newState.inputs = inputs;
                return newState;
            });
        }, 500);
    }

    setInputs() {
        this.setState((state) => {
            const newState = { ...state };

            newState.inputs = this.inputs.map((input) => ({ key: input, value: '' }));

            return newState;
        });
    }

    checkUpdate(isInit) {
        const { updateKey } = this.props;

        if (isInit || updateKey !== this.updateKey) {
            this.updateKey = updateKey;

            this.setInputs();
        }
    }

    componentDidMount() {
        this.checkUpdate(true);
    }

    componentDidUpdate() {
        this.checkUpdate();
    }

    render() {
        const { inputs, isError, isErrorActivate } = this.state;
        const { className, isDisabled, isNumber } = this.props;

        return (
            <div ref={this.parent} className={`codeBox _row ${className || ``}`}>
                {this.inputs.map((input, key) => {
                    const value = (inputs && inputs[key].value) || ``;

                    return (
                        <input
                            type={isNumber ? 'number' : 'text'}
                            className={`codeBox__input ${isError === true ? '_error' : ''} ${
                                isErrorActivate === true ? '_errorAnim' : ''
                            }`}
                            key={key}
                            value={value}
                            id={`input-confirmEmailInput-${key}`}
                            onChange={(e) => {
                                this.handlerDown(key);
                                this.changeInput({ key, value: e.target.value });
                            }}
                            onKeyDown={(e) => {
                                if (
                                    e.keyCode === 8 &&
                                    [0, 1].indexOf(value.length) !== -1 &&
                                    key !== 0
                                ) {
                                    e.preventDefault();
                                    this.parent.current
                                        .querySelector(`input#input-confirmEmailInput-${key - 1}`)
                                        .focus();
                                    this.changeInput({ key, value: '' });
                                }
                            }}
                            autoComplete="off"
                            disabled={isDisabled}
                        />
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(CodeBox);

CodeBox.propTypes = {
    callback: PropTypes.func,
    callbackSuccess: PropTypes.func,
    inputs: PropTypes.array,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isNumber: PropTypes.bool,
    inMoment: PropTypes.bool,
    updateKey: PropTypes.any,
};
