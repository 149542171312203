import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import setPermissions from '../../functions/crm/setPermissions';

import Invites from './joins/invites/Invites.tsx';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        setPermissions.call(this);
    }

    renderJoin() {
        return (
            <div className="body__innerPage _ITEM">
                <Invites inIndexMain={true} />
            </div>
        );
    }

    render() {
        const { user } = this.props;

        return (
            <>
                {this.getPermissions?.({
                    user,
                    key: 'joins',
                    items: [{ key: 'invites', rules: ['read'] }],
                }) && (
                    <ListAbsoluteMain
                        className="body__innerPages _ITEMS"
                        classNameItem="body__innerPage"
                        items={user ? [{ name: user.idOfCurrentCorporation }] : []}
                        renderItem={this.renderJoin.bind(this)}
                        prop="name"
                        withoutParams={true}
                        isNotParamsItem={true}
                        isNotParams={true}
                        isSmoothShow={true}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Index);

Index.propTypes = {
    user: PropTypes.object,
};
