import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import GroupsInnerI from './types.ts';

import Main from '../../../main/Main.tsx';

class GroupsInner
    extends React.Component<GroupsInnerI['props'], GroupsInnerI['state']>
    implements GroupsInnerI
{
    parent: GroupsInnerI['parent'];

    constructor(props: GroupsInnerI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    componentDidMount(): void {
        const { levels } = this.props;
        const groupId = levels[3]!;

        this.setState({ groupId });
    }

    render() {
        const { groupId } = this.state;

        return <>{groupId && <Main groupId={groupId} inGroups={true} />}</>;
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(GroupsInner);
