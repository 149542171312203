import React from 'react';

import ActionChange from '@components/actionChange/ActionChange.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import InfoBlock from '@components/infoBlock/InfoBlock.tsx';
import Input from '@components/input/Input.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import { ExecutorM } from '@global/models/Executor.ts';

import I from '../types.ts';

const renderComment = function (
    this: I,
    {
        item,
    }: {
        item: ExecutorM['comments'][number];
    },
): React.ReactElement {
    return (
        <div className="v2widgetCard__comment _ITEM">
            <Input
                support="Введите комментарий"
                className="_comments"
                textarea={true}
                value={item.content}
                name="comment"
                onChange={async ({ value }) => {
                    await this.change({ [`comments.${item._id}.content`]: value });
                }}
                calcHeight={true}
                calcHeightCb={() => {
                    this.setState({ updatedCommentsKey: new Date().getTime() });
                }}
                minHeight={() => 20}
            />
            <ActionChange
                className="v2widgetCard__commentBtn"
                active={true}
                onClick={() => {
                    this.deleteArrayItem([`comments.${item._id}`]);
                }}
            />
        </div>
    );
};

const renderCommentsCard: I['renderCommentsCard'] = function () {
    const { updatedCommentsKey } = this.state;
    const model = this.state.model!;
    const commentsCounter = model.comments.length;

    return (
        <div className="v2widgetCard ">
            <div className="v2widgetCard__head _ROW">
                <div className="v2widgetCard__title">
                    Комментарии:
                    <AnimateChange
                        className="v2widgetCard__description"
                        renderKey={commentsCounter}
                        styles={['width']}
                    >
                        <div className="v2widgetCard__descriptionInner">
                            {commentsCounter || '–'}
                        </div>
                    </AnimateChange>
                </div>
                <div className="v2widgetCard__actions">
                    <div className="v2widgetCard__actionsButton">
                        <Button
                            className="_blue _minSize"
                            onClick={() => {
                                if (commentsCounter < 10) {
                                    this.addArrayItem({
                                        key: 'comments',
                                        item: {
                                            content: '',
                                        },
                                    });
                                }
                            }}
                        >
                            + Добавить
                        </Button>
                    </div>
                </div>
            </div>
            <div className="v2widgetCard__content _empty _fullHeight _NOSCROLL">
                <InfoBlock
                    title="Комментариев нет"
                    description="Вы можете добавить их<br/>по кнопке выше"
                    className="v2widgetCard__info"
                    isShow={model.comments.length === 0}
                />
                <ListAbsoluteMain
                    className="v2widgetCard__comments _ITEMS"
                    items={model.comments}
                    renderItem={renderComment.bind(this)}
                    classNameItem="v2widgetCard__comment"
                    prop="_id"
                    paramsParent={{ width: true }}
                    itemParams={['height', 'offsetTop']}
                    propsForUpdate={['content']}
                    keyRender={updatedCommentsKey}
                    renderChildren={(node) => (
                        <>
                            <div className="v2widgetCard__commentsInner">{node}</div>
                        </>
                    )}
                />
            </div>
        </div>
    );
};

export default renderCommentsCard;
