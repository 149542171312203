import CorporationsI from '../types.ts';

const getQuery: CorporationsI['getQuery'] = function (this: CorporationsI) {
    const { corporationId } = this.props;

    const query = [];

    if (corporationId) {
        query.push({ key: 'adminCorporationId', value: corporationId });
    }

    return query;
};

export default getQuery;
