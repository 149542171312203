import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerAppPopup from '../../functions/app/handlerPopup';
import getPreviewName from '../../functions/chat/getPreviewName';
import setPermissions from '../../functions/crm/setPermissions';
import handlerPopup from '../../functions/handlerPopup';
import handlerSupportCursor from '../../functions/handlerSupportCursor';
import handlerWindow from '../../functions/handlerWindow';

import setChatResponsible from '../../requests/setChatResponsible';
import Animate from '../Animate.jsx';
import AnimateChangeUp from '../AnimateChangeUp.jsx';
import Avatar from '../Avatar.jsx';
import Icon from '../Icon.jsx';
import Link from '../Link.jsx';
import ListAbsoluteMain from '../ListAbsoluteMain.jsx';
import Loader from '../Loader.jsx';
import CorporationInfo from '../crm/manual/CorporationInfo.jsx';

class WindowTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.filterAction = this.filterAction.bind(this);
        this.handlerUserSettings = this.handlerUserSettings.bind(this);
        this.renderAction = this.renderAction.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    actions = [
        {
            key: 'logs',
            icon: 'actions-logs',
        },
        {
            key: 'user',
            icon: 'widget-user',
        },
        {
            key: 'comments',
            icon: 'chat-messages',
        },
        {
            key: 'invite',
            icon: 'chat-invite',
        },
        {
            key: 'close',
            icon: 'chat-close',
        },
        {
            key: 'setResponsibleSelf',
            icon: 'actions-responsible',
        },
    ];

    getActions() {
        return this.actions.filter(this.filterAction);
    }

    renderAction({ item: action, prop: key }) {
        const { loadingKey } = this.state;
        const otherProps = {};

        if (key === 'setResponsibleSelf') {
            otherProps.onMouseEnter = (e) => {
                handlerSupportCursor({
                    action: 'enter',
                    content: 'Взять в работу',
                    e,
                    data: { className: '_normalCase _center' },
                });
            };
            otherProps.onMouseLeave = (e) => {
                handlerSupportCursor({ action: 'leave', e });
            };
        }

        if (key === 'invite') {
            otherProps.onMouseEnter = (e) => {
                handlerSupportCursor({
                    action: 'enter',
                    content: 'Пригласить',
                    e,
                    data: { className: '_normalCase _center' },
                });
            };
            otherProps.onMouseLeave = (e) => {
                handlerSupportCursor({ action: 'leave', e });
            };
        }

        if (key === 'logs') {
            otherProps.onMouseEnter = (e) => {
                handlerSupportCursor({
                    action: 'enter',
                    content: 'Логи',
                    e,
                    data: { className: '_normalCase _center' },
                });
            };
            otherProps.onMouseLeave = (e) => {
                handlerSupportCursor({ action: 'leave', e });
            };
        }

        return (
            <div
                className={`chatWindowTop__action _col _click _${key}`}
                onClick={(e) => {
                    this.handlerAction({
                        target: e.target,
                        ...action,
                    });

                    handlerSupportCursor({ action: 'leave', e });
                }}
                {...otherProps}
            >
                <div className="chatWindowTop__actionInner _col">
                    <Animate
                        className="chatWindowTop__actionLoader _loader"
                        isShow={loadingKey === action.key}
                    >
                        <div className="chatWindowTop__actionLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <i className="chatWindowTop__actionIcon">
                        <Icon name={action.icon} />
                    </i>
                </div>
            </div>
        );
    }

    filterAction({ key }) {
        const { user } = this.state;
        const { chat, type } = this.props;
        const isClose = chat?.isClose;
        const userSupport = chat?.type === 'support' && chat?.entity.id === user?._id;

        if (userSupport) {
            return false;
        }

        if (key === 'logs') {
            return !!chat;
        }

        if (
            !this.getPermissions({
                key: 'chat',
                items: [{ key: 'chats', rules: ['update'] }],
            })
        ) {
            return false;
        }

        if (key === 'user') {
            return !!chat;
        }

        if (key === 'close') {
            return chat?.responsible === user?._id && !isClose && !chat?.anyInfo.integrationDisable;
        }

        if (type === 'fix' && ['comments'].includes(key)) {
            return false;
        }

        if (key === 'comments') {
            return chat && !chat?.anyInfo.integrationDisable;
        }

        if (key === 'setResponsibleSelf') {
            return chat && chat.responsible !== user?._id;
        }

        if (key === 'invite') {
            return chat && chat.type === 'telegram' && !chat?.anyInfo.userId && chat.anyInfo.phone;
        }

        return true;
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerAction({ target, key }) {
        const { user } = this.state;
        const { getParent, chat, type, checkChangeChat } = this.props;
        const { _id, responsible } = chat;
        const parent = getParent();

        if (key === 'logs') {
            handlerAppPopup({
                name: 'logsPopup',
                isShow: true,
                modelName: 'chat',
                modelId: _id,
            });
        }

        if (key === 'user') {
            handlerAppPopup({
                name: 'listPopup',
                isShow: true,
                currentName: 'users',
                currentId: responsible,
                callback: (idOfResponsible) =>
                    new Promise((resolve) => {
                        setChatResponsible({
                            idOfResponsible,
                            id: _id,
                        }).then(resolve, resolve);
                    }),
            });
        }

        if (key === 'comments') {
            handlerWindow({
                parent,
                parentResize: parent,
                target: target.closest('.chatWindowTop__action'),
                action: 'show',
                name: 'promptCommentsChat',
                className: '_prompt _right',
                uniqKey: `comments-${type}-${_id}`,
                watchesProps: { left: true },
                centers: {
                    left: 0.5,
                    top: 1,
                },
            });
        }

        if (key === 'close') {
            handlerWindow({
                parent,
                parentResize: parent,
                target: target.closest('.chatWindowTop__action'),
                action: 'show',
                name: 'promptCloseChat',
                className: '_prompt _right',
                uniqKey: `close-${type}-${_id}`,
                watchesProps: { left: true },
                centers: {
                    left: 0.5,
                    top: 1,
                },
            });
        }

        if (key === 'setResponsibleSelf') {
            if (!responsible) {
                this.handlerLoading(key).then(() => {
                    setChatResponsible({
                        idOfResponsible: user._id,
                        id: _id,
                    }).then(
                        () => {
                            this.handlerLoading(null);
                        },
                        () => {
                            this.handlerLoading(null);
                        },
                    );
                });
            } else {
                handlerWindow({
                    parent,
                    parentResize: parent,
                    parentScroll: parent.querySelector('.chatList__boxScroll'),
                    target: target.closest('.chatWindowTop__action'),
                    action: 'show',
                    name: 'chatResponsibleAlert',
                    uniqKey: `chatResponsibleAlert-${_id}`,
                    className: '_right _prompt',
                    chatId: _id,
                    isHideFromScroll: true,
                    alertClassName: '_topRight',
                    centers: {
                        left: 0.5,
                        top: 1,
                    },
                });
            }
        }

        if (key === 'invite') {
            handlerPopup({
                action: 'show',
                name: 'popupExecutorInvite',
                executor: { phone: chat.anyInfo.phone },
                callback: () => {
                    checkChangeChat(true);
                },
            });
        }
    }

    getName() {
        const { chat } = this.props;
        const type = chat?.company ? 'company' : chat?.user.type;
        const name = getPreviewName({
            user: chat?.user,
            company: chat?.company,
        });
        let typeName =
            type === 'executor' ? 'Исполнитель – ' : type === 'client' ? 'Клиент – ' : '';

        if (chat?.type === 'support' && chat.entity.type === 'user') {
            typeName = 'Пользователь — ';
        }

        return `${typeName}${name}`;
    }

    getLinkInfo() {
        const { chat } = this.props;

        if (chat?.type === 'telegram' && chat?.anyInfo?.userId) {
            return {
                pageName: 'manual-executors-inner-main',
                ids: { 3: chat.anyInfo.userId },
            };
        }

        const type = chat?.company ? 'company' : chat?.user.type;

        if (type === 'user') {
            return null;
        }

        if (type === 'client' || type === 'company') {
            return {
                pageName: 'manual-organizations-inner-main',
                ids: { 3: type === 'client' ? chat?.user._id : chat?.company._id },
            };
        }

        if (type === 'executor' && chat?.user._id) {
            return {
                pageName: 'manual-executors-inner-main',
                ids: { 3: chat?.user._id },
            };
        }

        return null;
    }

    handlerUserSettings({ target }) {
        const { chat, getParent } = this.props;
        const parent = getParent();

        handlerWindow({
            parent,
            parentResize: parent,
            target: target.closest('.chatWindowTop__infoEdit'),
            action: 'show',
            name: 'chatUserSettings',
            className: '_list _chatUserSettings',
            uniqKey: `chatUserSettings-${chat?._id}`,
            watchesProps: { left: true },
            anyInfo: chat.anyInfo,
            centers: {
                left: 0.5,
                top: 1,
            },
            isNotReverse: true,
        });
    }

    componentDidMount() {
        this.setState({ user: this.props.user });

        const { setContentHeight } = this.props;

        setContentHeight();
    }

    render() {
        const { user } = this.state;
        const { chat } = this.props;
        const linkInfo = this.getLinkInfo();
        const PreviewTag = linkInfo ? Link : 'div';
        const previewProps = linkInfo || {};
        const userName = this.getName();

        return (
            <div ref={this.parent} className="chatWindowTop">
                <div className="chatWindowTop__head _row">
                    <div className="chatWindowTop__info">
                        <AnimateChangeUp
                            className="chatWindowTop__infoInner _row"
                            renderKey={
                                chat
                                    ? `${chat?.entity.id}${chat?.entity.idOfCompany}${chat?.anyInfo.userId}${userName}${chat?.user.phone}${chat?.corporation?.name}`
                                    : null
                            }
                        >
                            <>
                                <PreviewTag
                                    className={`chatWindowTop__infoPreview ${
                                        previewProps ? '_click' : ''
                                    }`}
                                    {...previewProps}
                                >
                                    <Avatar
                                        holder={
                                            chat?.company
                                                ? { ...chat?.company }
                                                : { ...(chat?.user || {}) }
                                        }
                                        type={chat?.company ? 'company' : chat?.user.type}
                                        src={chat?.user?.src}
                                    />
                                </PreviewTag>
                                <div className="chatWindowTop__infoContent _col">
                                    <div className="chatWindowTop__infoName">
                                        <div className="chatWindowTop__infoNameInner">
                                            {userName}
                                        </div>

                                        {['telegram'].includes(chat?.type) &&
                                            this.getPermissions({
                                                key: 'chat',
                                                items: [{ key: 'chats', rules: ['update'] }],
                                            }) && (
                                                <div
                                                    className="chatWindowTop__infoEdit _col _click"
                                                    onClick={this.handlerUserSettings}
                                                >
                                                    <i className="chatWindowTop__infoEditIcon">
                                                        <Icon name="edit" />
                                                    </i>
                                                </div>
                                            )}
                                    </div>
                                    <div className="chatWindowTop__infoDescription _row">
                                        {chat?.user.phone}
                                        {chat?.type === 'support' &&
                                            user?.idOfCurrentCorporation === 'admin' &&
                                            chat?.corporation && (
                                                <>
                                                    <Link
                                                        className="chatWindowTop__infoCorporation _row"
                                                        pageName="corporations-inner"
                                                        ids={{ 2: chat?.corporation._id }}
                                                    >
                                                        <CorporationInfo
                                                            className="_mediumSize"
                                                            {...chat.corporation}
                                                        />
                                                    </Link>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </>
                        </AnimateChangeUp>
                    </div>
                    <ListAbsoluteMain
                        className="chatWindowTop__actions"
                        items={this.getActions()}
                        renderItem={this.renderAction}
                        classNameItem="chatWindowTop__action"
                        prop="key"
                        // paramsParent={{ width: true }}
                        styles={['width']}
                        itemParams={['offsetRight', 'offsetTop']}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        afk: state.afk,
        isShowChatWindow: state.isShowChatWindow,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(WindowTop);

WindowTop.propTypes = {
    user: PropTypes.object,
    chat: PropTypes.object,
    levels: PropTypes.array,
    getParent: PropTypes.func,
    setContentHeight: PropTypes.func,
    type: PropTypes.string,
    checkChangeChat: PropTypes.func,
    setJoinCode: PropTypes.func,
};
