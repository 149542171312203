import React from 'react';

import Main from '../components/Main/Main';

import Proxy from '../../joins/contracts/Contracts';
import ProfileI from '../types';

const pages = {
    'profile-main': {
        render(this: ProfileI) {
            const { model } = this.state;

            return (
                <>
                    <Main model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'profile-proxy': {
        render(this: ProfileI) {
            const { user } = this.props;

            return (
                <>
                    <div className="body__innerPageWrapper">
                        <Proxy type="userProxy" inWidget={false} executorId={user?._id} />
                    </div>
                </>
            );
        },
    },
} as const;

export default pages;
