import handlerLoading from '@functions/handlerLoading.ts';

import AlertI from '../types.ts';

const save: AlertI['save'] = async function () {
    const { model, textMessage } = this.state;

    if (typeof this.callback === 'function') {
        await handlerLoading.call(this, 'save');

        try {
            await this.callback({ ...model, message: textMessage });

            await handlerLoading.call(this, null);

            this.close();

            if (typeof this.successCallback === 'function') {
                this.successCallback();
            }
        } catch (e) {
            const error = e as { name: string; text?: string };

            this.setState({ error });

            await handlerLoading.call(this, null);
        }
    } else {
        this.close();
    }
};

export default save;
