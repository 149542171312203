import React from 'react';
import PropTypes from 'prop-types';
import { rubles } from 'rubles';

import getFormatedDate from '../../functions/getFormatedDate';
import getUserInfo from '../../functions/getUserInfo';
import getEndText from '../../functions/getEndText';
import getFormatPrice from '../../functions/getFormatPrice';

class OrderReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderRow = this.renderRow.bind(this);
        this.readyCallback = this.readyCallback.bind(this);

        this.parent = React.createRef();
    }

    getType() {
        const { pdf } = this.props;
        const { otherData } = pdf;

        return otherData.type;
    }

    getHeadSupport(name) {
        if (name.indexOf('address') !== -1) {
            return `Адрес ${name.split('-')[1]}`;
        }

        return this.cols[name];
    }

    getCols() {
        const type = this.getType();

        return ['number', ...this.colsOrder[type], 'address-1', 'address-2', 'address-3'];
    }

    colsOrder = {
        all: [
            'tariff',
            'orderNumber',
            'startDate',
            'startTime',
            'endDate',
            'endTime',
            'duration',
            'clientAndExecutor',
            'minPrice',
            'extraPrice',
            'servicesPrice',
            'mkad',
            'mkadPrice',
            'changedPrice',
            'totalPrice',
            'totalPrices',
        ],
        clients: [
            'tariff',
            'orderNumber',
            'fullName',
            'car',
            'carNumber',
            'startDate',
            'startTime',
            'endDate',
            'endTime',
            'duration',
            'minPrice',
            'extraPrice',
            'servicesPrice',
            'mkad',
            'mkadPrice',
            'changedPrice',
            'totalPrice',
        ],
        executors: [
            'tariff',
            'orderNumber',
            'fullName',
            'car',
            'carNumber',
            'startDate',
            'startTime',
            'endDate',
            'endTime',
            'duration',
            'minPrice',
            'extraPrice',
            'servicesPrice',
            'mkad',
            'mkadPrice',
            'changedPrice',
            'totalPrice',
        ],
        services: [
            'tariff',
            'orderNumber',
            'fullName',
            'serviceName',
            'car',
            'carNumber',
            'startDate',
            'startTime',
            'endDate',
            'endTime',
            'commission',
            'serviceAmount',
            'totalPrice',
            'savedAmount',
        ],
    };

    cols = {
        number: '№',
        tariff: 'Тариф',
        orderNumber: 'Заказ',
        fullName: 'ФИО',
        car: 'ТС',
        carNumber: 'Гос. номер',
        startDate: 'Дата подачи',
        startTime: 'Подача',
        endDate: 'Дата конца',
        endTime: 'Конец',
        duration: 'В работе',
        clientAndExecutor: 'Клиент | Исполнитель',
        minPrice: 'Подача(₽)',
        extraPrice: 'Почас.',
        servicesPrice: 'Доп.услуги',
        mkad: 'За МКАД',
        mkadPrice: '₽/км',
        changedPrice: 'Корр-ка',
        totalPrice: 'Итого(₽)',
        totalPrices: 'Разница',
        serviceName: 'Служба доставки',
        commission: 'Комиссия LIVECARGO',
        serviceAmount: 'Стоимость заказа',
        savedAmount: 'Ваша экономия',
    };

    getColInner({ order, key, name }) {
        const type = this.getType();

        if (name === 'number') {
            return key + 1;
        }

        if (name === 'tariff') {
            return order.tariffName;
        }

        if (name === 'orderNumber') {
            return order.numberOfOrder;
        }

        if (name === 'fullName') {
            if (type === 'clients') {
                return order.nameOfCompany;
            }

            if (type === 'executors') {
                return getUserInfo({ type: 'name', user: order.executor });
            }

            if (type === 'services') {
                return order.userName;
            }
        }

        if (name === 'car') {
            if (type === 'clients') {
                return (
                    <>
                        {order.carInfo?.mark.name} {order.carInfo?.model.name}
                    </>
                );
            }

            if (type === 'executors') {
                return (
                    <>
                        {order.executor.carInfo?.mark.name} {order.executor.carInfo?.model.name}
                    </>
                );
            }

            if (type === 'services') {
                return order.carInfo?.mark || order.carInfo?.model ? (
                    <>
                        {order.carInfo?.mark || ''} {order.carInfo?.model || ''}
                    </>
                ) : (
                    <>–</>
                );
            }
        }

        if (name === 'carNumber') {
            if (type === 'clients') {
                return order.carInfo?.number;
            }

            if (type === 'executors') {
                return order.executor.carInfo?.number;
            }

            if (type === 'services') {
                return order.carInfo?.number || '–';
            }
        }

        if (name === 'startDate') {
            return getFormatedDate({ date: new Date(order.timeStart), type: 'date' });
        }

        if (name === 'startTime') {
            return getFormatedDate({ date: new Date(order.timeStart), type: 'time' });
        }

        if (name === 'endDate') {
            return getFormatedDate({ date: new Date(order.timeEnd), type: 'date' });
        }

        if (name === 'endTime') {
            return getFormatedDate({ date: new Date(order.timeEnd), type: 'time' });
        }

        if (name === 'duration') {
            return (
                <>
                    {order.counterWork} час{getEndText(order.counterWork, ['', 'а', 'ов'])}
                </>
            );
        }

        if (name === 'clientAndExecutor') {
            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">{order.nameOfCompany}</div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getUserInfo({ type: 'name', user: order.executor })}
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'minPrice') {
            if (type === 'clients') {
                return <> {getFormatPrice(order.tariffAmount)} ₽</>;
            }

            if (type === 'executors') {
                return <> {getFormatPrice(order.executor.tariffAmount)} ₽</>;
            }

            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.tariffAmount)} ₽
                        </div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.executor.tariffAmount)} ₽
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'extraPrice') {
            if (type === 'clients') {
                return <> {getFormatPrice(order.tariffExtraHour)} ₽</>;
            }

            if (type === 'executors') {
                return <> {getFormatPrice(order.executor.tariffExtraHour)} ₽</>;
            }

            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.tariffExtraHour)} ₽
                        </div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.executor.tariffExtraHour)} ₽
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'servicesPrice') {
            if (type === 'clients') {
                const value = order.services.reduce((prev, cur) => prev + cur.price, 0);

                return <> {getFormatPrice(value)} ₽</>;
            }

            if (type === 'executors') {
                const value = order.executor.services.reduce((prev, cur) => prev + cur.price, 0);

                return <> {getFormatPrice(value)} ₽</>;
            }

            const clientsServices = order.services.reduce((prev, cur) => prev + cur.price, 0);
            const executorsServices = order.executor.services.reduce(
                (prev, cur) => prev + cur.price,
                0,
            );

            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(clientsServices)} ₽
                        </div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(executorsServices)} ₽
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'mkad') {
            return <>{order.mkad} км</>;
        }

        if (name === 'mkadPrice') {
            if (type === 'clients') {
                return <> {getFormatPrice(order.priceOfMkad)} ₽</>;
            }

            if (type === 'executors') {
                return <> {getFormatPrice(order.executor.priceOfMkad)} ₽</>;
            }

            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.priceOfMkad)} ₽
                        </div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.executor.priceOfMkad)} ₽
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'changedPrice') {
            if (type === 'clients') {
                return <> {getFormatPrice(order.changedPrice)} ₽</>;
            }

            if (type === 'executors') {
                return <> {getFormatPrice(order.executor.changedPrice)} ₽</>;
            }

            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.changedPrice)} ₽
                        </div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.executor.changedPrice)} ₽
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'totalPrice') {
            if (type === 'clients') {
                return <> {getFormatPrice(order.amount)} ₽</>;
            }

            if (type === 'executors') {
                return <> {getFormatPrice(order.executor.amount)} ₽</>;
            }

            if (type === 'services') {
                return <> {getFormatPrice(order.amount)} ₽</>;
            }

            return (
                <>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.amount)} ₽
                        </div>
                    </div>
                    <div className="pdf__ordersReportTableColBlock _col">
                        <div className="pdf__ordersReportTableColInner">
                            {getFormatPrice(order.executor.amount)} ₽
                        </div>
                    </div>
                </>
            );
        }

        if (name === 'totalPrices') {
            return <> {getFormatPrice(order.amount - order.executor.amount)} ₽</>;
        }

        if (name === 'serviceName') {
            return <>{order.serviceCompanyName}</>;
        }

        if (name === 'commission') {
            return <>{getFormatPrice(order.commissionAmount)} ₽</>;
        }

        if (name === 'serviceAmount') {
            return <>{getFormatPrice(order.tariffAmount)} ₽</>;
        }

        if (name === 'savedAmount') {
            const savedAmount = order.savedAmount.max.price - order.savedAmount.current.price;

            return <>{getFormatPrice(savedAmount)} ₽</>;
        }

        return '–';
    }

    renderRow(order, key, orders) {
        const { _id } = order;

        return (
            <div className="JSpdfElem pdf__elem _col" key={_id}>
                <div className="pdf__ordersReportTableWrapper">
                    <div className={`pdf__ordersReportTable _${this.getType()}`}>
                        <div
                            className={`pdf__ordersReportTableRow _row _order ${
                                key === orders.length - 1 ? '_last' : ''
                            }`}
                            data-key={key}
                        >
                            {this.getCols()
                                .filter((name) => name.indexOf('address') === -1)
                                .map((name) => {
                                    const inner = this.getColInner({ order, key, name });
                                    const isDouble =
                                        [
                                            'clientAndExecutor',
                                            'minPrice',
                                            'extraPrice',
                                            'servicesPrice',
                                            'mkadPrice',
                                            'changedPrice',
                                            'totalPrice',
                                        ].includes(name) && this.getType() === 'all';

                                    return (
                                        <div
                                            className={`pdf__ordersReportTableCol _${name} ${
                                                isDouble ? '_double' : ''
                                            }`}
                                            key={name}
                                        >
                                            <div className="pdf__ordersReportTableColInner">
                                                {inner}
                                            </div>
                                        </div>
                                    );
                                })}
                            {this.getCols()
                                .filter((name) => name.indexOf('address') !== -1)
                                .map((name) => {
                                    const orderKey = +name.split('-')[1];
                                    const addresses = order.route.filter(
                                        (point, pointKey) => pointKey % 3 === orderKey - 1,
                                    );

                                    return (
                                        <div
                                            className={`pdf__ordersReportTableCol _address _${name}`}
                                            key={name}
                                        >
                                            {addresses.map(({ address }, addressKey) => (
                                                <div
                                                    className="pdf__ordersReportTableColAddress"
                                                    key={addressKey}
                                                    data-row={addressKey}
                                                >
                                                    <div className="pdf__ordersReportTableColAddressNumber _col">
                                                        {addressKey * 3 + orderKey}
                                                    </div>
                                                    {address}
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setSize() {
        const { pdf } = this.props;
        const orders = pdf.data.orders;

        orders.forEach((order, key) => {
            const row = this.parent.current.querySelector(
                `.pdf__ordersReportTableRow[data-key="${key}"]`,
            );
            const counterRows = Math.ceil(order.route.length / 3);

            new Array(counterRows).fill(1).forEach((item, colKey) => {
                let maxHeight = -Infinity;

                row.querySelectorAll(
                    `.pdf__ordersReportTableColAddress[data-row="${colKey}"]`,
                ).forEach((col) => {
                    col.style.height = null;

                    const { offsetHeight } = col;

                    if (offsetHeight > maxHeight) {
                        maxHeight = offsetHeight;
                    }
                });

                row.querySelectorAll(
                    `.pdf__ordersReportTableColAddress[data-row="${colKey}"]`,
                ).forEach((col) => {
                    col.style.height = `${maxHeight}px`;
                });
            });

            const clientAndExecutorCol = row.querySelector(
                '.pdf__ordersReportTableCol._clientAndExecutor',
            );

            if (clientAndExecutorCol) {
                const clientAndExecutorColInner = clientAndExecutorCol.querySelector(
                    '.pdf__ordersReportTableColInner',
                );

                clientAndExecutorColInner.style.height = null;

                let maxHeight = clientAndExecutorColInner.offsetHeight / 2;

                clientAndExecutorCol
                    .querySelectorAll('.pdf__ordersReportTableColBlock _col')
                    .forEach((block) => {
                        block.style.height = null;

                        if (block.offsetHeight > maxHeight) {
                            maxHeight = block.offsetHeight;
                        }
                    });

                clientAndExecutorCol
                    .querySelectorAll('.pdf__ordersReportTableColBlock _col')
                    .forEach((block) => {
                        block.style.height = `${maxHeight}px`;
                    });
            }
        });

        this.parent.current
            .querySelectorAll('.pdf__ordersReportTableRow._order:not(._head)')
            .forEach((row, key) => {
                row.classList.remove('_odd');

                if (key % 2 === 1) {
                    row.classList.add('_odd');
                }
            });
    }

    getTotalPrice() {
        const { pdf } = this.props;
        const orders = pdf.data.orders;
        const type = this.getType();

        return orders.reduce((prev, cur) => {
            if (type === 'executors') {
                return prev + cur.executor.amount;
            }

            return prev + cur.amount;
        }, 0);
    }

    renderFoot() {
        const { renderFoot } = this.props;
        const price = this.getTotalPrice();
        const rublesPrice = rubles(price)?.toString();

        const render = () => (
            <div className="pdf__ordersReportFootWrapper">
                <div className="pdf__ordersReportFoot _row">
                    <div className="pdf__ordersReportFootBlock _col _info">
                        <div className="pdf__ordersReportFootAmount">
                            Итого: {getFormatPrice(price)} ₽
                        </div>
                        <p className="pdf__ordersReportFootAmountText">
                            {price === 0 ? (
                                <>0 рублей 00 копеек</>
                            ) : (
                                <>
                                    {rublesPrice[0]?.toUpperCase()}
                                    {rublesPrice?.slice(1)}
                                </>
                            )}
                        </p>
                        <p className="pdf__ordersReportFootInfo">
                            Услуги считаются оказанными ООО {'"'}ЛАЙВКАРГО{'"'} надлежащим образом и
                            Принятыми Заказчиком в указанном в настоящем реестре объеме, <br />
                            если в течение срока, указанного в Договоре № КП 031 от «18» мая 2021
                            г., от Заказчика не поступило мотивированных письменных возражений.{' '}
                            <br />
                            По истечении срока, указанного выше, претензии относительно недостатков
                            услуг, в том числе по количеству (объему) и качеству не принимаются.
                        </p>
                    </div>
                    <div className="pdf__ordersReportFootBlock _col">
                        <div className="pdf__ordersReportFootCorporation">
                            <b>Исполнитель</b>: ООО «ЛАЙВКАРГО»
                        </div>
                        <div className="pdf__ordersReportFootSign">
                            ___________________ / Гомкцян К.А. /
                        </div>
                    </div>
                </div>
            </div>
        );

        return renderFoot({ render });
    }

    renderPagination({ key }) {
        const { renderPagination } = this.props;
        const render = ({ pageCounter }) => (
            <div className="pdf__ordersReportHead _row">
                <div className="pdf__ordersReportHeadCounter">
                    СТРАНИЦА {key + 1}/{pageCounter}
                </div>
            </div>
        );

        return renderPagination({ key, render });
    }

    readyCallback() {
        const { pageCounter = 0 } = this.props;

        new Array(pageCounter).fill({}).forEach((item, key) => {
            if (key !== 0) {
                const page = this.parent.current.querySelector(`.pdf__page[data-key="${key}"]`);

                if (page) {
                    const headRow = document.createElement('div');

                    headRow.classList.add('pdf__elem');
                    headRow.classList.add('_col');

                    headRow.appendChild(
                        this.parent.current
                            .querySelector('.pdf__ordersReportTable._head')
                            .cloneNode(true),
                    );

                    page.prepend(headRow);
                }
            }
        });
    }

    componentDidMount() {
        const { init, setReadyCallback } = this.props;

        setReadyCallback(this.readyCallback);

        this.setSize();

        init();
    }

    render() {
        const { pdf, getPages } = this.props;
        const { corporationInfo } = pdf;
        const orders = pdf.data.orders;

        return (
            <div ref={this.parent} className="pdf__inner _col">
                {getPages().map((page) => this.renderPagination({ key: page }))}
                <div className="pdf__innerBox">
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__ordersReportHead _row">
                            <div className="pdf__ordersReportHeadTitle">
                                Реестр №{pdf.number} от{' '}
                                {getFormatedDate({ date: new Date(pdf.date), type: 'text' })}{' '}
                                {new Date(pdf.date).getFullYear()} г.
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__ordersReportHead _row">
                            <div className="pdf__ordersReportHeadDescription">
                                <b>Заказчику {corporationInfo?.name}</b>: Исполнителем ООО
                                «ЛАЙВКАРГО» были оказаны
                                <br />
                                следующие транспортно-экспедиционные услуги по перевозке грузов:
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col _tableHead">
                        <div className="pdf__ordersReportTableWrapper _top">
                            <div className={`pdf__ordersReportTable _head _${this.getType()}`}>
                                <div className="pdf__ordersReportTableRow _row _head">
                                    {this.getCols().map((name) => (
                                        <div
                                            className={`pdf__ordersReportTableCol _${name}`}
                                            key={name}
                                        >
                                            {this.getHeadSupport(name)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {orders.map(this.renderRow)}
                </div>
                {this.renderFoot()}
            </div>
        );
    }
}

export default OrderReports;

OrderReports.propTypes = {
    pdf: PropTypes.object,
    pageCounter: PropTypes.number,
    handlerPages: PropTypes.func,
    renderFoot: PropTypes.func,
    init: PropTypes.func,
    renderPagination: PropTypes.func,
    getPages: PropTypes.func,
    setReadyCallback: PropTypes.func,
};
