import React from 'react';

import WidgetDocs from '@components/crm/widgetDocs/WidgetDocs.tsx';
import getPermissions from '@functions/crm/getPermissions.ts';

import I from '../types.ts';

const renderDocs: I['renderDocs'] = function () {
    const { model, error, user } = this.state;
    const docs = model?.docs || [];

    const adminRules = getPermissions(user, {
        adminCorporation: true,
        key: 'corporations',
        items: [{ key: 'main', rules: ['update'] }],
    });
    const editRules = getPermissions(user, {
        adminCorporation: !!(model?.verification.status === 'active'),
        key: 'corporations',
        items: [{ key: 'main', rules: ['update'] }],
    });

    return (
        <>
            <WidgetDocs
                docs={docs}
                change={this.change.bind(this)}
                addArrayItem={this.addArrayItem.bind(this)}
                deleteArrayItem={this.deleteArrayItem.bind(this)}
                error={error}
                checkChangeName={() => adminRules}
                checkChange={() => editRules}
                checkAddDocs={() => adminRules}
                checkDeleteDoc={() => adminRules}
                setDoc={(resultDoc) => {
                    this.setDoc(resultDoc);
                }}
            />
        </>
    );
};

export default renderDocs;
