import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../../Icon.jsx';
import getEndText from '../../../../functions/getEndText';
import getFormatedDate from '../../../../functions/getFormatedDate';
import setSpacesInText from '../../../../functions/setSpacesInText';
import Link from '../../../Link.jsx';

class PublicBlogPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { article } = this.props;

        return (
            <Link
                className={`publicBlogPreview ${article.isMain ? '_main' : ''}`}
                pageName="public-blog-inner"
                ids={{ 2: article.url }}
            >
                <img
                    src={`${process.env.REACT_APP_STATIC}/articles/${article.preview.path}`}
                    alt=""
                    className="publicBlogPreview__image"
                />
                <div className="publicBlogPreview__content _col">
                    <div className="publicBlogPreview__head _row">
                        <div className="publicBlogPreview__headInfo _row _section">
                            {article?.sectionText}
                        </div>
                        <div className="publicBlogPreview__headInfo _row">
                            <i className="publicBlogPreview__headInfoIcon">
                                <Icon name="blog-view" />
                            </i>
                            {article.viewCounter || 0}
                        </div>
                    </div>
                    <div
                        className="publicBlogPreview__title"
                        dangerouslySetInnerHTML={{ __html: setSpacesInText(article?.title) }}
                    ></div>
                    <div className="publicBlogPreview__foot _row">
                        <div className="publicBlogPreview__footTime _row">
                            <i className="publicBlogPreview__footTimeIcon">
                                <Icon name="blog-time" />
                            </i>
                            {article?.time}{' '}
                            {getEndText(article?.time, ['минута', 'минуты', 'минут'])}
                        </div>
                        <div className="publicBlogPreview__footDate">
                            {getFormatedDate({
                                date: new Date(article?.createdDate),
                                type: 'textYear',
                            })}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(PublicBlogPreview);

PublicBlogPreview.propTypes = {
    article: PropTypes.object,
};
