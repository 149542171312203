import React from 'react';

import CursorLists from '@components/cursorLists/CursorLists.tsx';

import RootI from '../types.ts';

const renderCursorLists: RootI['renderCursorLists'] = function () {
    return (
        <>
            <CursorLists />
        </>
    );
};

export default renderCursorLists;
