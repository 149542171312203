import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const adminParse: I['adminParse'] = async function ({ actsJoin }) {
    const { payGroup } = this.state;

    try {
        const response = await axios.post<{}, { data: ReqResponseT }>(
            `${process.env.REACT_APP_API}/pays`,
            { adminParseGroup: payGroup!._id, actsJoin: !!actsJoin },
            { headers: getHeaders() },
        );
        const { success } = response.data;

        if (success) {
            changePage<'crm'>({ pageName: 'pays-groups-main' });

            return;
        }
    } catch (error) {}

    return Promise.reject();
};

export default adminParse;
