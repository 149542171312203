import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AlertPopup from '@components/popups/alert/Alert.tsx';
import CalendarPopup from '@components/popups/calendar/Calendar.tsx';
import FilterPopup from '@components/popups/filter/Filter.tsx';

import RootI from '../types.ts';

const renderPopups: RootI['renderPopups'] = function () {
    const { filterPopup, alertPopup, calendarPopup } = this.props;

    return (
        <>
            <Animate
                className="body__v2popup _POPUPWRAPPER _filter _POPUPBACK"
                isShow={!!filterPopup?.isShow}
            >
                <FilterPopup />
            </Animate>
            <Animate
                className="body__v2popup _POPUPWRAPPER _alert _POPUPBACK"
                isShow={!!alertPopup?.isShow}
            >
                <AlertPopup />
            </Animate>
            <Animate
                className="body__v2popup _POPUPWRAPPER _calendar _POPUPBACK"
                isShow={!!calendarPopup?.isShow}
            >
                <CalendarPopup />
            </Animate>
        </>
    );
};

export default renderPopups;
