import handlerLoading from '../../../functions/handlerLoading.ts';

import EditBlockI, { ModelT } from '../types.ts';

import createId from '../../../requests/createId.ts';

const addArrayItem: EditBlockI['addArrayItem'] = async function (
    this: EditBlockI,
    { name, key, item = {} },
) {
    const { [this.name]: model } = this.state as ModelT;

    const template = model?.TEMPLATES && name ? model?.TEMPLATES[name] : {};

    await handlerLoading.call(this, key);

    if (Array.isArray(item)) {
        try {
            const ids = (await createId(item.length)) as string[];

            await Promise.all(
                ids.map(async (id, index) => {
                    await this.change({
                        [key]: {
                            ...JSON.parse(JSON.stringify(template)),
                            _id: id,
                            IS_TEMPLATE: true,
                            FROM_ARRAY: true,
                            TEMPLATE_NAME: name,
                            isCreate: true,
                            ...item[index],
                        },
                    });
                }),
            );

            await handlerLoading.call(this, undefined);

            return ids;
        } catch (error) {}
    } else {
        try {
            const id = await createId();

            await this.change({
                [key]: {
                    ...JSON.parse(JSON.stringify(template)),
                    _id: id,
                    IS_TEMPLATE: true,
                    FROM_ARRAY: true,
                    TEMPLATE_NAME: name,
                    isCreate: true,
                    ...item,
                },
            });

            await handlerLoading.call(this, undefined);

            return id as string;
        } catch (error) {}
    }

    await handlerLoading.call(this, undefined);

    return null;
};

export default addArrayItem;
