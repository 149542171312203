const joins = {
    colsOrder: ['user', 'script', 'date', 'status', 'comment'],
    cols: {
        user: {
            content: 'Исполнитель',
            width: 25,
            isAdminMain: true,
        },
        script: {
            content: 'Сценарий',
            width: 21,
        },
        date: {
            content: 'Приглашён',
            width: 18,
        },
        status: {
            content: 'Статус',
            width: 14,
        },
        comment: {
            content: 'Комментарий',
            width: 22,
        },
    },
    adminIndex: 1,
    deletesCols: ['script'],
    url: 'join',
    emptyInfo: {
        title: 'Подключений пока нет',
        description: 'Вы можете пригласить исполнителя по кнопке справа',
    },
} as const;

export default joins;
