import AnimateI from '../types.ts';

const setStateOfAnimate: AnimateI['setStateOfAnimate'] = function (value) {
    const { isShow, setState } = this.props;

    if (value === 0) {
        this.isShow = isShow;
    }

    // console.log(value);

    return new Promise<void>((resolve) => {
        this.stateOfAnimate = value;

        if (typeof setState === 'function') {
            setState({ value });

            resolve();
        } else {
            this.setState((state) => {
                const newState = { ...state };

                newState.stateOfAnimate = value;

                return newState;
            }, resolve);
        }
    });
};

export default setStateOfAnimate;
