import ItemsI from '../types.ts';

const startGetItems: ItemsI['startGetItems'] = async function () {
    if (!this.isNotStartGetItems && this.getItems) {
        try {
            await this.getItems!({ isStart: true });

            // setTimeout(() => {
            //     this.getItems!({ isStart: true });
            // }, 1000);
        } catch (error) {}
    }
};

export default startGetItems;
