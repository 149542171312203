import I from '../types.ts';

const deleteFilesHandler: I['deleteFilesHandler'] = async function (id, fileId) {
    const { deleteArrayItem, editFiles } = this.props;

    const resultKey = editFiles ? `files.${fileId}` : `docs.${id}.files.${fileId}`;

    await deleteArrayItem([resultKey]);

    this.setState({ updatedItemKey: new Date().getTime() });
};

export default deleteFilesHandler;
