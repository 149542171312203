import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { rolePopup } = this.props;
    const { role } = rolePopup;
    const model: I['state']['model'] = {
        _id: 'role',
    };

    if (role) {
        model._id = role._id;
        model.name = role.name;

        this.setState({ isEdit: true });
    }

    this.init({ fields: model, start: !role });
};

export default mountHandler;
