import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';

import handlerWindow from '../functions/handlerWindow';
import setSpacesInText from '../functions/setSpacesInText';
import Field from './Field.jsx';

class WindowPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
        };

        this.handlerArea = this.handlerArea.bind(this);
        this.continue = this.continue.bind(this);
        this.handlerLoader = this.handlerLoader.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    getInfo() {
        const { name } = this.props;
        const info = {};

        if (name === 'delete') {
            info.title = 'Подтвердите удаление';
            info.description = 'Это действие нельзя отменить';
            info.buttonContinue = 'Удалить';
        }

        if (name === 'addUserAlreadyCreate') {
            info.title = 'Пользователь уже создан';
            info.buttonContinue = 'Пригласить';
        }

        if (name === 'userAdd') {
            info.title = 'Пользователь c данной почтой уже существует';
            info.description = 'Хотите пригласить его в компанию?';
            info.buttonContinue = 'Пригласить';
        }

        if (name === 'cancel') {
            info.title = 'Подтвердите отмену';
            info.description = 'Это действие нельзя отменить';
            info.buttonContinue = 'Отменить заказ';
            info.buttonClose = 'Закрыть';
        }

        if (name === 'closeChat') {
            info.title = 'Подтвердите закрытие';
            info.description = 'Это действие нельзя отменить';
            info.buttonContinue = 'Закрыть обращение';
            info.buttonClose = 'Отменить';
        }

        if (name === 'deleteDoc') {
            info.title = 'Подтвердите удаление';
            info.description = 'Это действие нельзя отменить';
            info.buttonContinue = 'Удалить документ';
            info.buttonClose = 'Закрыть';
        }

        if (name === 'cancelEdo') {
            info.title = 'Введите причину отклонения ЭДО:';
            info.buttonContinue = 'Отклонить ЭДО';
            info.buttonClose = 'Отменить';
            info.input = {
                placeholder: 'Причина',
            };
        }

        if (name === 'commentsChat') {
            info.title = 'Комментарий к чату';
            info.buttonContinue = 'Добавить комментарий';
            info.buttonClose = 'Отменить';
            info.className = '_left';
            info.input = {
                placeholder: 'Введите комментарий',
            };
        }

        if (name === 'deleteExecutorInfo') {
            info.title = 'Ошибка удаления';
            info.description =
                'Нельзя удалить исполнителя, если он имеет хоть один заказ или по нему проводились выплаты';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deleteCarInfo') {
            info.title = 'Ошибка удаления';
            info.description =
                'Нельзя удалить автомобиль, если он имеет хоть один заказ или привязан к исполнителю';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deleteTrailerInfo') {
            info.title = 'Ошибка удаления';
            info.description = 'Нельзя удалить прицеп, если он привязан к автомобилю';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deleteJoinDocInfo') {
            info.title = 'Ошибка удаления';
            info.description =
                'Нельзя удалить документ, если он используется хоть в одном сценарии';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deleteModelOfCarInfo') {
            info.title = 'Ошибка удаления';
            info.description =
                'Нельзя удалить модель, если она используется хоть одним автомобилем';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deleteJoinScriptInfo') {
            info.title = 'Ошибка удаления';
            info.description = 'Нельзя удалить сценарий, если по нему было хоть одно подключение';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deleteJoinTemplateInfo') {
            info.title = 'Ошибка удаления';
            info.description = 'Нельзя удалить шаблон, если он используется хоть в одном сценарии';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'deletePayInfo') {
            info.title = 'Ошибка удаления';
            info.description = 'Нельзя удалить реестр, платежи которого находятся в процессе';
            info.buttonContinue = 'Понятно';
        }

        if (name === 'chatResponsibleAlert') {
            info.title = 'На чат уже<br/>назначен пользователь';
            info.description = 'Всё равно взять данный<br/>чат в работу?';
            info.buttonContinue = 'Взять в работу';
        }

        if (name === 'chatCardNumber') {
            info.title = 'Банковская карта';
            info.buttonContinue = 'Заменить';
        }

        if (name === 'removeTax') {
            info.title = 'Подтвердите отключение';
            info.description = 'Это действие нельзя отменить';
            info.buttonContinue = 'Отключить';
        }

        if (name === 'withdrawTax') {
            info.title = 'Подтвердите вывод средств';
            info.description = 'Это действие нельзя отменить';
            info.buttonContinue = 'Вывести';
        }

        if (name === 'contractInactive') {
            info.title = 'Предупреждение';
            info.description = 'На данный договор подано МВД уведомление';
            info.buttonContinue = 'Расторгнуть';
        }

        if (name === 'contractAlreadyCreateMvd') {
            info.title = 'Предупреждение';
            info.description =
                'В системе есть актуальный договор, на который подано МВД уведомление';
            info.buttonContinue = 'Создать новый';
        }

        if (name === 'contractAlreadyCreate') {
            info.title = 'Предупреждение';
            info.description = 'В системе есть актуальный договор';
            info.buttonContinue = 'Создать новый';
        }

        if (name === 'roleUsersLoseProxies') {
            info.title = 'Предупреждение';
            info.description =
                'Изменение данных прав приведет к расторжению всех доверенностей у исполнителей с этой ролью';
            info.buttonContinue = 'Сохранить изменения';
        }

        if (name === 'roleUserLoseProxy') {
            info.title = 'Предупреждение';
            info.description = 'Смена роли приведет к расторжению доверенности у исполнителя';
            info.buttonContinue = 'Сохранить изменения';
        }

        return info;
    }

    handlerArea({ action, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState({ valueArea: value }, resolve);
            } else {
                resolve();
            }
        });
    }

    handlerLoader(showLoader) {
        return new Promise((resolve) => {
            this.setState({ showLoader }, resolve);
        });
    }

    continue() {
        const { valueArea } = this.state;
        const { callback } = this.props;
        const info = this.getInfo();
        const hide = () => {
            this.handlerLoader(false);
            this.cancel();
        };

        if (callback && typeof callback === 'function' && (!info.input || valueArea)) {
            this.handlerLoader(true).then(() => {
                callback({
                    hide: hide.bind(this),
                    handlerLoader: this.handlerLoader,
                    valueArea,
                });
            });
        }
    }

    cancel() {
        const { hide } = this.props;

        if (hide && typeof hide === 'function') {
            hide();
        }

        handlerWindow({
            action: 'hide',
        });
    }

    render() {
        const { showLoader, valueArea } = this.state;
        const { className, title, buttonContinue, withDelete = true } = this.props;
        const info = this.getInfo();
        const description = this.props.description || info.description;

        return (
            <div
                className={`windowPrompt _col _windowWithCursor ${className} ${
                    info.className || ''
                }`}
            >
                <div className="windowPrompt__head _col">
                    <h4
                        className="windowPrompt__title"
                        dangerouslySetInnerHTML={{
                            __html: setSpacesInText(title || info.title),
                        }}
                    ></h4>
                    {description && (
                        <p
                            className="windowPrompt__description"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(description),
                            }}
                        />
                    )}
                </div>

                {info.input && (
                    <div className="windowPrompt__area">
                        <Field
                            tag="area"
                            support={info.input.placeholder}
                            name="comment"
                            keyName="comment"
                            className="_grey _mediumPadding _mediumRadius"
                            value={valueArea}
                            callback={this.handlerArea}
                        />
                    </div>
                )}
                <div className="windowPrompt__actions _col">
                    <div className="windowPrompt__action" onClick={this.continue}>
                        <Button className="_mainNotBorder _mediumSize" showLoader={showLoader}>
                            {buttonContinue || this.getInfo().buttonContinue}
                        </Button>
                    </div>
                    {withDelete && (
                        <div className="windowPrompt__action _click _cancel" onClick={this.cancel}>
                            {this.getInfo().buttonClose || <>Отменить</>}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(WindowPrompt);

WindowPrompt.propTypes = {
    className: PropTypes.string,
    callback: PropTypes.func,
    hide: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonContinue: PropTypes.string,
    name: PropTypes.string,
    withDelete: PropTypes.bool,
};
