import changePage from '@functions/changePage.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';
import { dispatcher } from '@redux/redux.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function (this: InvitesI, { mailing }) {
    const { user } = this.state;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'mailing',
                    modelId: mailing._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (
        getPermissions(user, {
            key: 'chat',
            items: [{ key: 'mailing', rules: ['create'] }],
        })
    ) {
        actions.push({
            key: 'repeat',
            name: 'repeat',
            text: 'Повторить',
            onClick: async () => {
                await dispatcher<'crm'>({ type: 'repeatMailingId', data: mailing._id });

                changePage<'crm'>({ pageName: 'mailing-inner', ids: { 2: 'new' } });
            },
        });
    }

    return actions;
};

export default getMoreActions;
