import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import { setCursorActions } from '@functions/actionsHandler.ts';

import I from '../types.ts';

import { CardsT } from '../static/cards.ts';

const renderHead: I['renderHead'] = function () {
    const { withHead = true, name, model, data, headActions, moreActions, headInfo } = this.props;

    if (withHead === false) {
        return null;
    }

    const card = this.cards[name] as CardsT;
    const title = typeof card.title === 'function' ? card.title(model, data) : card.title;
    const description =
        typeof card.description === 'function' ? card.description(model, data) : card.description;
    const moreProps =
        moreActions &&
        setCursorActions(
            { _id: `widgetCard-more-${name}`, dir: 'left', items: moreActions },
            { targetClassName: '.v2widgetCard__more' },
        );

    return (
        <div className="v2widgetCard__head _ROW">
            <div className="v2widgetCard__title">
                {title}:
                <AnimateChange
                    className="v2widgetCard__description"
                    renderKey={description || false}
                    styles={['width']}
                >
                    <div className="v2widgetCard__descriptionInner">{description || '–'}</div>
                </AnimateChange>
            </div>
            {headInfo && <div className="v2widgetCard__headInfo">{headInfo}</div>}
            {(headActions || moreActions) && (
                <div className="v2widgetCard__actions _ROW">
                    {headActions}
                    {moreActions && (
                        <Animate
                            className="v2widgetCard__more _CLICK"
                            {...moreProps}
                            isShow={moreActions.length > 0}
                        >
                            <i className="v2widgetCard__moreIcon">
                                <Icon name="more-vertical" />
                            </i>
                        </Animate>
                    )}
                </div>
            )}
        </div>
    );
};

export default renderHead;
