import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import alerts from '../infos/alerts.json';

import getFormatedDate from '../functions/getFormatedDate';
import setSpacesInText from '../functions/setSpacesInText';

import Icon from './Icon.jsx';
import Loader from './Loader.jsx';
import Animate from './Animate.jsx';

const notifications = require('../infos/alerts.json');

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerAction = this.handlerAction.bind(this);
    }

    handlerLoading(isLoading = !this.state.isLoading) {
        return new Promise((resolve) => {
            this.setState({ isLoading }, resolve);
        });
    }

    handlerAction(action) {
        const { deleteNotification, counter, callback } = this.props;

        switch (action) {
            case 'continue':
                if (callback) {
                    this.handlerLoading(true).then(() => {
                        callback().then(
                            () => {
                                this.handlerLoading(false);

                                deleteNotification(counter);
                            },
                            () => {
                                this.handlerLoading(false);
                            },
                        );
                    });
                }

                break;
            case 'cancel':
                deleteNotification(counter);
                break;
            default:
                break;
        }
    }

    getIcon() {
        const { name, icon } = this.props;
        let image;

        if (process.env.REACT_APP_SYSTEM === 'crm' || process.env.REACT_APP_SYSTEM === 'app') {
            return null;
        }

        if (notifications[name]?.icon) {
            image = require(`../img/emodzi/${notifications[name].icon}`);
        }

        if (icon) {
            image = icon;
        }

        return image;
    }

    render() {
        const { isLoading } = this.state;
        const { name, title, description, deleteNotification, counter, isTremor } = this.props;
        const currentNotification = alerts[name] || {};
        const currentTitle = title || currentNotification.title || '';

        return (
            <div
                className={`alert _col _${currentNotification.type} ${
                    isTremor === true ? '_isTremor' : ''
                }  ${!this.getIcon() ? '_noIcon' : ''}`}
            >
                <i
                    className="alert__close"
                    onClick={() => {
                        deleteNotification(counter);
                    }}
                >
                    <Icon name="close" />
                </i>
                {this.getIcon() && <img src={this.getIcon()} alt="" className="alert__icon" />}

                <div className="alert__content">
                    <p
                        className="alert__title"
                        dangerouslySetInnerHTML={{
                            __html: setSpacesInText(currentTitle),
                        }}
                    ></p>
                    {description && (
                        <div
                            className="alert__description"
                            dangerouslySetInnerHTML={{ __html: setSpacesInText(description) }}
                        ></div>
                    )}

                    {currentNotification.type === 'prompt' && (
                        <>
                            <div className="alert__actions _row">
                                {['continue', 'cancel'].map((key) => (
                                    <div
                                        className={`alert__action _row _${key}`}
                                        key={key}
                                        onClick={() => {
                                            this.handlerAction(key);
                                        }}
                                    >
                                        <Animate
                                            className="alert__actionLoader _loader"
                                            isShow={key === 'continue' && isLoading}
                                        >
                                            <i className="alert__actionLoaderIcon _loaderItem">
                                                <Loader />
                                            </i>
                                        </Animate>
                                        {currentNotification?.actions?.[key]}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>

                {process.env.REACT_APP_SYSTEM === 'crm' &&
                    currentNotification.type !== 'prompt' && (
                        <div className="alert__time">
                            {getFormatedDate({ date: new Date(), type: 'time' })}
                        </div>
                    )}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Alert);

Alert.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string,
    deleteNotification: PropTypes.func,
    counter: PropTypes.number,
    isTremor: PropTypes.bool,
    callback: PropTypes.func,
    description: PropTypes.string,
};
