const signPays = {
    colsOrder: ['number', 'name', 'executor', 'amount'],
    cols: {
        number: { content: '№', width: 12 },
        name: { content: 'Назначение', width: 34 },
        executor: { content: 'Исполнитель', width: 30 },
        amount: { content: 'Сумма', width: 24 },
    },
    deletesCols: [],
    url: 'pays',
    emptyInfo: {
        title: 'Актов пока нет',
        description: 'Вы можете создать акт по кнопке справа',
    },
} as const;

export default signPays;
