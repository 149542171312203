import getBlockParent from './getBlockParent';
import getBlockParents from './getBlockParents';
import getRangeNodes from './getRangeNodes';

const actionsInfo = require('../../../infos/crm/editor.json');

export default function handlerInsertTag({ startContainer, endContainer, key, ...props }) {
    const action = actionsInfo.actions[key];
    const wrapper = document.createElement('div');
    let insertPlaceNode;
    const insertNode = document.createElement(action.tag || 'div');
    const isList = ['ol', 'ul'].includes(key);
    const textNode = document.createTextNode('\u200b');
    let resultFocusNode;
    let resultStartContainer;
    let resultEndContainer;

    const parentNodes = getBlockParents({ startContainer, endContainer });
    const blockParent = getBlockParent({ node: startContainer });

    if (isList) {
        const rangeNodes = getRangeNodes({ startContainer, endContainer, withBr: true });

        if (
            rangeNodes.find(
                (node) =>
                    ['OL', 'UL'].includes(node.parentNode?.nodeName) ||
                    node.parentNode?.closest('ol') ||
                    node.parentNode?.closest('ul'),
            )
        ) {
            rangeNodes.forEach((node) => {
                const listParent = node.parentNode?.closest('li');

                if (listParent) {
                    const liWrapper = document.createElement('div');

                    liWrapper.append(...listParent.childNodes);

                    listParent.replaceWith(liWrapper);
                }
            });

            rangeNodes.forEach((node) => {
                const listParent = node.parentNode?.closest('ol') || node.parentNode?.closest('ul');

                if (listParent) {
                    listParent.replaceWith(...listParent.childNodes);
                }
            });

            const range = new Range();

            range.setStart(startContainer, 0);
            range.setEnd(endContainer, endContainer.length);

            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);

            return {};
        }
    }

    if (blockParent) {
        insertPlaceNode = blockParent.previousElementSibling;

        if (!insertPlaceNode) {
            insertPlaceNode = document.createElement('div');

            blockParent.parentNode.insertBefore(insertPlaceNode, blockParent);
        }
    }

    if (isList) {
        if (blockParent) {
            parentNodes.forEach((parentNode, nodeKey) => {
                if (parentNode.innerText?.trim()) {
                    const replacer = document.createElement('li');
                    const newItem = parentNode.cloneNode(true);

                    replacer.appendChild(newItem);

                    newItem.replaceWith(...newItem.childNodes);

                    insertNode.appendChild(replacer);

                    if (nodeKey === parentNodes.length - 1) {
                        resultFocusNode = replacer.childNodes[replacer.childNodes.length - 1];
                    }

                    if (
                        parentNodes[nodeKey + 1] &&
                        parentNode.nextElementSibling &&
                        parentNode.nextElementSibling !== parentNodes[nodeKey + 1]
                    ) {
                        parentNode.nextElementSibling.remove();
                    }
                }

                parentNode.remove();
            });
        }

        // console.log(insertNode);

        if (parentNodes.length === 0) {
            const li = document.createElement('li');

            li.appendChild(textNode);

            resultFocusNode = textNode;

            insertNode.appendChild(li);
        }
    }

    // const emptyDiv = document.createElement('div');

    // emptyDiv.appendChild(textNode);

    if (key === 'table') {
        const { row, col } = props;

        for (let i = 0; i < row; i++) {
            const rowElem = document.createElement('tr');

            for (let j = 0; j < col; j++) {
                const colElem = document.createElement('td');

                if (i === 0 && j === 0) {
                    resultFocusNode = colElem;
                }

                if (props.isTableWidthFix) {
                    colElem.style.width = `${100 / col}%`;
                }

                rowElem.appendChild(colElem);
            }

            insertNode.appendChild(rowElem);
        }
    }

    if (key === 'image') {
        const { src, width } = props;
        const image = document.createElement('img');

        image.setAttribute('src', src);

        if (width) {
            image.style.width = `${width}%`;
        }

        insertNode.appendChild(image);
    }

    if (key === 'doc') {
        const { src, fileName = 'Файл' } = props;
        const doc = document.createElement('div');

        doc.classList.add('_DOC');

        // doc.setAttribute('contenteditable', false);

        const link = document.createElement('a');

        link.setAttribute('href', src);
        link.setAttribute('target', '_blank');

        link.textContent = fileName;

        doc.appendChild(link);

        insertNode.appendChild(doc);
    }

    if (key === 'gap') {
        const gap = document.createElement('div');

        gap.innerHTML = props.content;

        props.setHandlerGaps(gap.querySelector('.editor__gap'));

        insertNode.appendChild(gap);

        gap.replaceWith(...gap.childNodes);

        const afterDiv = document.createElement('div');

        afterDiv.appendChild(textNode);

        insertNode.appendChild(afterDiv);

        resultFocusNode = textNode;
    }

    if (key === 'title') {
        const rangeNodes = getRangeNodes({ startContainer, endContainer });
        const { title } = props;
        const alreadyWrap =
            !!rangeNodes.find((node) => node.parentNode.closest(title === 'main' ? 'H2' : 'H3')) ||
            props.unwrap;

        if (alreadyWrap) {
            rangeNodes.forEach((node) => {
                const parent = node.parentNode.closest(title === 'main' ? 'H2' : 'H3');

                if (parent) {
                    parent.replaceWith(...parent.childNodes);
                }
            });
        } else {
            rangeNodes.forEach((node, nodeIndex) => {
                const otherTitle = node.parentNode.closest(title === 'main' ? 'H3' : 'H2');

                if (otherTitle) {
                    otherTitle.replaceWith(...otherTitle.childNodes);
                }

                const titleElem = document.createElement(title === 'main' ? 'h2' : 'h3');
                const titleText = document.createTextNode(node.nodeValue);

                titleElem.appendChild(titleText);

                if (nodeIndex === 0) {
                    resultStartContainer = titleText;
                }

                if (nodeIndex === rangeNodes.length - 1) {
                    resultEndContainer = titleText;
                }

                node.parentNode.replaceChild(titleElem, node);
            });
        }
    }

    wrapper.appendChild(insertNode);

    if (insertPlaceNode) {
        insertPlaceNode.parentNode.insertBefore(wrapper, insertPlaceNode.nextElementSibling);
        // insertPlaceNode.replaceWith(wrapper);
    }

    return {
        focusNode: resultFocusNode,
        startContainer: resultStartContainer,
        endContainer: resultEndContainer,
    };
}
