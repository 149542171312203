import I from '../types.ts';

const setFakeItems: I['setFakeItems'] = function () {
    const items = new Array(10)
        .fill({
            corporations: [{}],
        })
        .map((item, key) => ({ ...item, _id: `fake-${key}`, cols: [] })) as I['state']['fakeItems'];
    const orderCols =
        this.getOrderCols() as unknown as (typeof this.tables)[keyof typeof this.tables]['colsOrder'];

    items.forEach((item) => {
        orderCols.forEach(() => {
            const counter = Math.round(Math.random() * 2) + 1;

            const cols: I['state']['fakeItems'][number]['cols'][number] = [];

            new Array(counter).fill({}).forEach(() => {
                const width = Math.round(Math.random() * 70) + 30;
                const height = Math.round(Math.random() * 6) + 12;

                cols.push({ width, height });
            });

            item.cols.push(cols);
        });
    });

    this.setState({ fakeItems: items });
};

export default setFakeItems;
