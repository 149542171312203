import React from 'react';
import { connect } from 'react-redux';

import Table from '@components/crm/table/Table.tsx';
import handlerPopup from '@functions/handlerPopup.ts';
import RoleT from '@global/models/Role.ts';
import { StoreT } from '@global/types.ts';

import getModel from './methods/getModel.ts';
import setActive from './methods/setActive.ts';
import setModel from './methods/setModel.ts';

import RolesInnerI from './types.ts';

class RolesInner
    extends React.Component<RolesInnerI['props'], RolesInnerI['state']>
    implements RolesInnerI
{
    constructor(props: RolesInnerI['props']) {
        super(props);
        this.state = {};
    }

    getModel = getModel;
    setModel = setModel;

    setActive = setActive;

    componentDidMount(): void {
        this.getModel();
    }

    render() {
        const { model } = this.state;

        return (
            <>
                <div className={`v2widget__contentWait ${model ? '_show' : ''}`}>
                    {model && (
                        <Table
                            name="corporationRoleSections"
                            isInit={true}
                            isNotLink={true}
                            isWrap={true}
                            isRowHeight={true}
                            staticItems={model.sections}
                            otherData={{
                                setActive: this.setActive.bind(this, model._id),
                            }}
                            getRowClassName={() => '_colCenter'}
                            rowOnClick={(section: RoleT['sections'][number]) => {
                                handlerPopup('roleSectionPopup', {
                                    isShow: true,
                                    role: model,
                                    sectionKey: section.key,
                                    setModel: this.setModel.bind(this),
                                });
                            }}
                        />
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(RolesInner);
