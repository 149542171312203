const signJoinsContracts = {
    colsOrder: ['name', 'userName', 'status'],
    cols: {
        name: {
            content: 'Название',
            width: 35,
        },
        userName: {
            content: 'ФИО',
            width: 41,
        },
        status: {
            content: 'Статус',
            width: 24,
        },
    },
    deletesCols: [],
    url: 'join-contract',
    emptyInfo: {
        title: 'Договоров пока нет',
        description: 'Вы можете создать договор по кнопке справа',
    },
} as const;

export default signJoinsContracts;
