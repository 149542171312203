import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const deleteRequest = async (id: string, force?: boolean) => {
    let query = `id=${id}`;

    if (force) {
        query += `&isForce=true`;
    }

    try {
        const response = await axios.delete<ReqResponseT<{ message: string }>>(
            `${process.env.REACT_APP_API}/pays?${query}`,
            {
                headers: getHeaders(),
            },
        );
        const { success, data } = response.data;
        const { message } = data;

        if (success) {
            return;
        }

        if (message === 'You dont delete this model') {
            return Promise.reject({ denied: true });
        }
    } catch (error) {}

    return Promise.reject({});
};

const deleteGroup: I['deleteGroup'] = async function (force) {
    const { payGroup } = this.state;
    const { groupId } = this.props;

    try {
        await deleteRequest(groupId, force);

        if (!force) {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'deletePayGroup',
                info: payGroup?.name,
                callback: deleteRequest.bind(null, groupId, true),
                successCallback: () => {
                    changePage<'crm'>({ pageName: 'pays-groups-main' });
                },
            });
        }
    } catch (e) {
        const error = e as { denied?: boolean };

        if (error.denied) {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'deletePayGroupDenied',
                denied: true,
            });

            return;
        }
    }

    return Promise.reject();
};

export default deleteGroup;
