import React from 'react';

import LazyImage from '@components/lazyImage/lazyImage.tsx';
import getFileSize from '@functions/getFileSize.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import FileI from './types.ts';

class File extends React.Component<FileI['props'], FileI['state']> implements FileI {
    parent: FileI['parent'];

    constructor(props: FileI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { files, file, deleteHandler, galeryTitle } = this.props;

        return (
            <div ref={this.parent} className="v2file">
                <div className="v2file__inner _ROW">
                    <div
                        className={`v2file__preview ${files ? '_CLICK' : ''}`}
                        onClick={() => {
                            if (files) {
                                handlerPopup('galeryPopup', {
                                    isShow: true,
                                    title: galeryTitle,
                                    files: files
                                        .filter((innerFile) => innerFile.fullSrc)
                                        .map((innerFile) => ({
                                            _id: innerFile._id,
                                            src: innerFile.src,
                                            fullSrc: innerFile.fullSrc,
                                            name: innerFile.name,
                                        })),
                                    currentId: file._id,
                                });
                            }
                        }}
                    >
                        {file.fullSrc && <LazyImage checkFit={true} src={file.fullSrc} />}
                    </div>
                    <div className="v2file__info">
                        <div className="v2file__name">{file.name}</div>
                        <div className="v2file__size">{getFileSize(file.size)}</div>
                    </div>
                </div>
                {deleteHandler && <div className="v2file__delete _CLICK" onClick={deleteHandler} />}
            </div>
        );
    }
}

export default File;
