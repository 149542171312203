import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';

import getActions from './methods/getActions.ts';
import getQuery from './methods/getQuery.ts';

import BalancesI from './types.ts';

class Balances extends WidgetWrapper<BalancesI['props'], BalancesI['state']> implements BalancesI {
    parent: BalancesI['parent'];

    constructor(props: BalancesI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getQuery = getQuery;
    getActions = getActions;

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <Widget
                    name="executorsBalances"
                    title="Операции"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    inWidget={true}
                    className="_white"
                    filterQuery={(this as BalancesI).getFilterQuery?.()}
                >
                    <Table
                        name="executorsBalances"
                        isInit={true}
                        inWidget={true}
                        isNotLink={true}
                        callback={this.tableCallback!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        getQuery={this.getQuery!.bind(this)}
                        // getRowClassName={() => '_colCenter'}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Balances);
