import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';

import ChatTemplatesI from './types.ts';

class ChatTemplates
    extends WidgetWrapper<ChatTemplatesI['props'], ChatTemplatesI['state']>
    implements ChatTemplatesI
{
    parent: ChatTemplatesI['parent'];

    constructor(props: ChatTemplatesI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getActions = getActions;
    getMoreActions = getMoreActions;

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <Widget
                    name="chatTemplates"
                    title="Чат"
                    description="Шаблоны"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as ChatTemplatesI).getFilterQuery?.()}
                >
                    <Table
                        name="chatTemplates"
                        isWrap={true}
                        isInit={true}
                        isRowHeight={true}
                        callback={this.tableCallback!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        link={{
                            pageName: 'chat-template-inner',
                            level: 2,
                        }}
                        otherData={{
                            getMoreActions: this.getMoreActions.bind(this),
                        }}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ChatTemplates);
