import I from '../types.ts';

const deleteHandler: I['deleteHandler'] = async function (id) {
    const { deleteArrayItem } = this.props;

    await deleteArrayItem([`docs.${id}`]);

    this.setState({ updatedItemKey: new Date().getTime() });
};

export default deleteHandler;
