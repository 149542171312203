import React from 'react';

import getFormatPrice from '@functions/getFormatPrice.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import PayProjectT from '@global/models/PayProject.ts';

import { RenderColsT } from '../../types.ts';

const renderPayProjects: RenderColsT<'payProjects'> = function ({ name: colName, item }) {
    const project = item as PayProjectT;
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(project.name) }}
                ></div>
            </>
        );
    }

    if (colName === 'percent') {
        content = (
            <>
                <div className="v2table__text">{getFormatPrice(project.percent)}%</div>
            </>
        );
    }

    if (colName === 'minimal') {
        content = (
            <>
                <div className="v2table__text">{getFormatPrice(project.minimal)} ₽</div>
            </>
        );
    }

    if (colName === 'over') {
        content = (
            <>
                <div className="v2table__text">{getFormatPrice(project.over)}%</div>
            </>
        );
    }

    if (colName === 'pays') {
        content = (
            <>
                <div className="v2table__text">{project.paysCount || 0}</div>
            </>
        );
    }

    if (colName === 'executors') {
        content = (
            <>
                <div className="v2table__text">{project.executorsCount || 0}</div>
            </>
        );
    }

    return { content, className };
};

export default renderPayProjects;
