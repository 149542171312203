import I from '../types.ts';

const eventMoveHandler: I['eventMoveHandler'] = function (e) {
    if (this.isMove) {
        const { x, y } = this.getPosition(e);

        this.moveX = x - this.startX + this.endX;
        this.moveY = y - this.startY + this.endY;

        const borders = this.checkBorders(this.moveX, this.moveY);

        this.moveX = borders.x;
        this.moveY = borders.y;

        // console.log(this.moveY);

        this.imageMove();
    }
};

export default eventMoveHandler;
