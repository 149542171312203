import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import PayT from '@global/models/Pay.ts';

import PaysI from '../types.ts';

const getActions: PaysI['getActions'] = function (this: PaysI) {
    const { currents = [], user, payGroup } = this.state;
    const { groupId } = this.props;
    const items = this.state.items as PayT[];

    const actions = [] as WidgetActionsT[];

    if (
        getPermissions(user, {
            adminCorporation: true,
            key: 'pays',
            items: [{ key: 'acts', rules: ['create'] }],
        }) &&
        payGroup?.adminParse
    ) {
        actions.push({
            type: 'link',
            name: 'parse',
            group: 'link',
            content: 'Распределить акты',
            className: '_main',
            onClick: async () => {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'parseAct',
                    callback: this.adminParse.bind(this),
                });
            },
        });
    }

    if (groupId !== 'main' || user?.idOfCurrentCorporation !== 'admin') {
        const allWaitPays = items.filter(
            (pay) =>
                pay.status === 'wait' &&
                pay.isCorporationSign &&
                (user?.idOfCurrentCorporation !== 'admin' ||
                    ['inactiveTax', 'tax'].includes(pay.type!)),
        );
        const allSignedPays = items.filter(
            (pay) =>
                pay.status === 'wait' &&
                pay.isCorporationSign !== true &&
                (user?.idOfCurrentCorporation !== 'admin' ||
                    ['inactiveTax', 'tax'].includes(pay.type!)),
        );
        const allNotCompletedPays = items.filter(
            (pay) =>
                ['wait', 'error', 'failed'].includes(pay.status) &&
                // !pay.wasStart &&
                (user?.idOfCurrentCorporation !== 'admin' ||
                    ['tax', 'inactiveTax'].includes(pay.type!) ||
                    pay.adminParse),
        );
        const condForPay =
            allWaitPays.length > 0 &&
            currents.every(({ id }) => allWaitPays.find(({ _id }) => _id === id));
        const condForSign =
            allSignedPays.length > 0 &&
            currents.every(({ id }) => allSignedPays.find(({ _id }) => _id === id));
        const condForDelete =
            allNotCompletedPays.length > 0 &&
            currents.every(({ id }) => allNotCompletedPays.find(({ _id }) => _id === id));

        const links = [] as WidgetActionsT[];

        if (
            !payGroup?.adminParse &&
            getPermissions(user, {
                key: 'pays',
                items: [{ key: 'acts', rules: ['sign'] }],
            })
        ) {
            links.push(
                ...([
                    {
                        type: 'link',
                        name: 'sign',
                        content: 'Подписать',
                        className: '_main',
                        group: 'link',
                        disabled: !(currents.length > 0 && condForSign),
                        onClick: this.setPays.bind(this, { isSign: true }),
                    },
                    {
                        type: 'link',
                        name: 'pay',
                        content: 'Оплатить',
                        className: '_main',
                        group: 'link',
                        disabled: !(currents.length > 0 && condForPay),
                        onClick: this.setPays.bind(this, {}),
                    },
                ] as WidgetActionsT[]),
            );
        }

        if (
            getPermissions(user, {
                key: 'pays',
                items: [{ key: 'acts', rules: ['delete'] }],
            })
        ) {
            links.push({
                type: 'link',
                name: 'delete',
                content: 'Удалить',
                className: '_alert',
                group: 'link',
                disabled: !(currents.length > 0 && condForDelete),
                onClick: async () => {
                    handlerPopup('alertPopup', {
                        isShow: true,
                        type: 'deletePays',
                        info: currents.length,
                        callback: this.deletePays.bind(
                            this,
                            currents.map((item) => item.id),
                        ),
                        successCallback: () => {
                            this.setState({ currents: [] });
                            this.tableUpdateItems!();
                        },
                    });
                },
            });
        }

        actions.push(...links);
    }

    if (
        payGroup &&
        getPermissions(user, {
            notAdminCorporation: true,
            key: 'pays',
            items: [{ key: 'acts', rules: ['sign'] }],
        })
    ) {
        const list = [
            {
                name: 'stopAll',
                content: `Откатить<br/>запущенные`,
                onClick: this.stopActs.bind(this),
            },
        ];

        if (payGroup.waitedCounter > 0) {
            list.unshift({
                name: 'payAll',
                content: `Оплатить все`,
                onClick: this.setPays.bind(this, { isAll: true }),
            });
        }

        actions.push({
            type: 'link',
            name: 'more',
            content: 'Ещё',
            group: 'link',
            className: '_white',
            list,
            updatedKey: list.map((item) => item.name).join('-'),
        });
    }

    if (groupId && groupId !== 'main' && 0) {
        actions.push({
            type: 'export',
            name: 'export',
            group: 'buttons',
            exportName: 'pays',
            exportQuery: [{ key: 'groupId', value: groupId }],
        });
    }

    actions.push({
        type: 'filter',
        name: 'filter',
        group: 'buttons',
        filterName: 'pays',
    });

    return actions;
};

export default getActions;
