import React from 'react';
import { connect } from 'react-redux';

import BussinesAboutI from './types.ts';

import renderInfoBlock from './renders/renderInfoBlock.tsx';
import renderProblemBlock from './renders/renderProblemBlock.tsx';
import infoCards from './static/infoCards.ts';
import problemCards from './static/problemCards.ts';

class BussinesAbout
    extends React.Component<BussinesAboutI['props'], BussinesAboutI['state']>
    implements BussinesAboutI
{
    constructor(props: BussinesAboutI['props']) {
        super(props);
        this.state = {};
    }

    problemCards = problemCards;
    infoCards = infoCards;

    renderProblemBlock = renderProblemBlock;
    renderInfoBlock = renderInfoBlock;

    render() {
        return (
            <div className="publicBusinessAbout _SECTION">
                <div className="publicBusinessAbout__inner _INNER _PUBLIC">
                    {this.renderProblemBlock()}
                    {this.renderInfoBlock()}
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(BussinesAbout);
