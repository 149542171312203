import { ListenerT } from '@global/types.ts';

import I from '../types.ts';

const init: I['init'] = function () {
    const image = this.getImage();

    if (image && this.checkImage()) {
        this.setSize();

        (image.addEventListener as ListenerT<React.MouseEvent>)(
            'mousedown',
            this.eventStartHandler,
            { passive: false },
        );
        (document.addEventListener as ListenerT<React.MouseEvent>)(
            'mousemove',
            this.eventMoveHandler,
            { passive: false },
        );
        (document.addEventListener as ListenerT<React.MouseEvent>)(
            'mouseup',
            this.eventEndHandler,
            { passive: false },
        );
    }
};

export default init;
