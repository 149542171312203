import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import setSpacesInText from '../../../functions/setSpacesInText';
import Icon from '../../Icon.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import getFormatedDate from '../../../functions/getFormatedDate';

class NewsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { isRead, _id, title, createdDate, sectionText, setCurrentPage } = this.props;

        return (
            <div ref={this.parent} className={`notification _news ${isRead ? '_read' : ''}`}>
                <div className="notification__type">{sectionText}</div>
                <div className="notification__inner _col">
                    <div
                        className="notification__title"
                        dangerouslySetInnerHTML={{
                            __html: setSpacesInText(title),
                        }}
                    ></div>
                    <div className="notification__info _row">
                        <div className="notification__date">
                            {getFormatedDate({ date: new Date(createdDate), type: 'textYear' })}
                        </div>
                    </div>

                    <div
                        className="notification__link _click"
                        onClick={() => {
                            setCurrentPage('news-inner', _id);
                        }}
                    >
                        <AnimateChangeUp className="notification__linkInner" renderKey={isRead}>
                            <>
                                {isRead ? 'Читать снова' : 'Подробнее об этом'}
                                <i className="notification__linkIcon">
                                    <Icon name="arrow-next" />
                                </i>
                            </>
                        </AnimateChangeUp>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NewsCard);

NewsCard.propTypes = {
    user: PropTypes.object,
    _id: PropTypes.string,
    isRead: PropTypes.bool,
    title: PropTypes.string,
    sectionText: PropTypes.string,
    createdDate: PropTypes.string,
    setCurrentPage: PropTypes.func,
    viewCounter: PropTypes.number,
};
