import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';

import LogsPopupI from '../types.ts';

const getActions: LogsPopupI['getActions'] = function () {
    const actions = [
        {
            type: 'filter',
            name: 'filter',
            group: 'filter',
            filterName: 'popupChatTemplates',
        },
    ] as WidgetActionsT[];

    if (this.getFilter) {
        const filter = this.getFilter();
        const filterSearch = filter?.find((item) => item.main);
        const withFilter = !!(filter && filter.filter((item) => !item.main).length > 0);

        actions.unshift({
            type: 'search',
            name: 'search',
            filterProp: filterSearch?.name,
            group: 'search',
            content: filterSearch?.inputSupport || 'Поиск',
            className: !withFilter ? '_full' : '',
        });
    }

    return actions;
};

export default getActions;
