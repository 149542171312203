export default function downloadFile({ filename, data }) {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    const type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    const file = new Blob([data], { type });
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = filename;

    link.addEventListener('click', (e) => {
        e.stopPropagation();
    });

    link.click();
    document.body.removeChild(link);
}
