import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getArticles from '../../../../requests/getArticles';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';

import TableDefault from '../../../../components/crm/manual/Table.jsx';

import ArticleCard from '../../../../components/crm/content/blog/ArticleCard.jsx';
import setSpacesInText from '../../../../functions/setSpacesInText';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';

class ManualContractsMain extends TableDefault {
    constructor(props) {
        super(props);
        this.state = {
            isDisabledScroll: false,
            isLoadingFilter: false,
        };

        this.renderCard = this.renderCard.bind(this);
        this.setParamsParent = this.setParamsParent.bind(this);
        this.getSortItems = this.getSortItems.bind(this);
        this.changeItem = this.changeItem.bind(this);

        this.parent = React.createRef();
    }

    name = 'articles';

    stepCounter = 12;

    getCards() {
        const { items = [], isLimit } = this.state;

        return isLimit ? items.concat(...[{ _id: 'info' }]) : items;
    }

    changeItem(id, { values }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const items = [...newState.items];

                const index = items.findIndex((item) => item._id === id);

                if (index !== -1) {
                    values.forEach(({ key, value }) => {
                        items[index][key] = value;
                    });
                }

                newState.items = items;

                return newState;
            }, resolve);
        });
    }

    getItems() {
        const { name = 'blog' } = this.props;
        const query = this.getQueryForRequest();
        const { params } = query;

        params.push({ key: 'isPrivate', value: true });

        return new Promise((resolve) => {
            getArticles(name, { params }).then(
                ({ articles, isLimit, counter }) => {
                    if (!this.state.isInit) {
                        setTimeout(() => {
                            this.setState({ isInit: true });
                        }, 300);
                    }

                    this.setItems(articles, false, isLimit, counter).then(resolve);
                },
                () => null,
            );
        });
    }

    renderCard({ item, prop: _id }) {
        const { name } = this.props;
        const cards = this.getCards();
        const article = cards.find((articleLoop) => articleLoop._id === _id) || item;

        return (
            <div className={`contentBlog__card  ${_id === 'info' ? '_info' : ''}`}>
                <div className="contentBlog__cardInner">
                    {_id === 'info' ? (
                        <div className="empty _col _block">
                            <div className="empty__inner">
                                <div className="empty__title">Пока это все статьи</div>
                                <p
                                    className="empty__content"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(
                                            'Чтобы добавить статью,<br/>нажмите «+Добавить статью»<br/>в правом верхнем углу страницы',
                                        ),
                                    }}
                                ></p>
                            </div>
                        </div>
                    ) : (
                        <ArticleCard
                            changeItem={this.changeItem.bind(this, _id)}
                            cardName={name}
                            {...article}
                        />
                    )}
                </div>
            </div>
        );
    }

    setParamsParent(params = {}) {
        const { height: heightCards } = params;

        if (heightCards !== this.state.heightCards) {
            this.setState({ heightCards });
        }
    }

    sortItems(a, b) {
        return new Date(b.dateOfCreate) - new Date(a.dateOfCreate);
    }

    getSortItems(items) {
        return JSON.parse(JSON.stringify(items)).sort(this.sortItems);
    }

    render() {
        const { isReady, isDisabledScroll, isLimit, isInit, isLoadingFilter, counterUpdate } =
            this.state;
        let { heightCards = 0 } = this.state;
        const { parentScroll, handlerLoaderList } = this.props;
        const cards = this.getCards();

        if (!heightCards || isLoadingFilter) {
            heightCards = 300;
        }

        return (
            <div
                ref={this.parent}
                className={`widget _notPadding ${!isLoadingFilter && isReady ? '_ready' : ''}`}
            >
                <Animate className="widget__loader _loader" isShow={!isReady || isLoadingFilter}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _inner _full _scroll">
                    <div className="widget__contentBox">
                        <div className="contentBlog">
                            <ListScroll
                                getParent={() => parentScroll}
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={cards.length}
                                handlerLoaderList={handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={isDisabledScroll || !isInit || isLoadingFilter}
                                key={counterUpdate}
                            >
                                <ListAbsoluteMain
                                    className="contentBlog__cards"
                                    items={cards}
                                    renderItem={this.renderCard}
                                    classNameItem="contentBlog__card"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    itemParams={['offsetRight', 'offsetTop', 'width', 'height']}
                                    resizeParent={document.querySelector('.body')}
                                />
                            </ListScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualContractsMain);

ManualContractsMain.propTypes = {
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
    info: PropTypes.string,
    name: PropTypes.string,
};
