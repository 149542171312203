import axios from 'axios';

import { ReqResponseT } from '@global/types.ts';

import getHeaders from '../functions/getHeaders.ts';

export default async function createId(counter?: number): Promise<void | string | string[]> {
    try {
        const response = await axios.get<ReqResponseT<{ id: string }>>(
            `${process.env.REACT_APP_API}/id?${counter ? `counter=${counter}` : ''}`,
            {
                headers: getHeaders(),
            },
        );
        const { success, data } = response.data;

        if (success) {
            return data.id as string;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject(error);
    }
}
