import I from '../types.ts';

const setLoadings: I['setLoadings'] = function ({ name, value }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state };
            const loadings = { ...newState.loadings };

            loadings[name] = value ?? !loadings[name];

            newState.loadings = loadings;

            return newState;
        }, resolve);
    });
};

export default setLoadings;
