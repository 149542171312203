import PropTypes from 'prop-types';

import React from 'react';

class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPages = this.getPages.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.renderFoot = this.renderFoot.bind(this);
        this.setReadyCallback = this.setReadyCallback.bind(this);

        this.parent = React.createRef();
    }

    toMm(val) {
        return +(val * 0.2645833333333).toFixed(0);
    }

    toPx(val) {
        return +(val * 3.779527559055).toFixed(0);
    }

    getAllNodes(elem, list = []) {
        if (elem.childNodes.length && !this.checkElem(elem)) {
            elem.childNodes.forEach((childNode) => this.getAllNodes(childNode, list));

            return list;
        }

        if (
            (elem.nodeType === 3 || this.checkElem(elem)) &&
            !elem.parentNode?.classList?.contains?.('_abs')
        ) {
            list.push(elem);
        }

        return list;
    }

    getAllTextNodes(elem, list = []) {
        if (elem.childNodes.length) {
            elem.childNodes.forEach((childNode) => this.getAllTextNodes(childNode, list));

            return list;
        }

        if (elem.nodeType === 3 && !elem.parentNode?.classList?.contains?.('_abs')) {
            list.push(elem);
        }

        return list;
    }

    checkElem(elem) {
        return (
            elem.classList?.contains?.('JSpdfElem') ||
            elem.nodeName === 'TR' ||
            elem.classList?.contains?.('_gap')
        );
    }

    getPagesCounters() {
        const { currentPagesCounter = 0, pageStep = 0 } = this.props;

        if (currentPagesCounter === 0) {
            return {
                start: 0,
                end: 0,
            };
        }

        const start = (currentPagesCounter - 1) * pageStep + 1;
        const end = currentPagesCounter * pageStep;

        return {
            start,
            end,
        };
    }

    getPdfInfo() {
        const { pdf } = this.state;
        const pdfInfo = this.pdfs[pdf?.key];

        return pdfInfo;
    }

    getPaddings(isClear) {
        const pdfInfo = this.getPdfInfo();
        const { pagination, paddingTop = 0, paddingBottom = 0 } = pdfInfo;
        let resultPaddingTop = paddingTop;
        let resultPaddingBottom = paddingBottom;

        if (!isClear) {
            if (pagination === 'top') {
                resultPaddingTop += 5;
            }

            if (pagination === 'bottom') {
                resultPaddingBottom += 12;
            }
        }

        return {
            top: this.toPx(resultPaddingTop),
            bottom: this.toPx(resultPaddingBottom),
        };
    }

    removePages(parent, elem, type, breakElem) {
        if (elem === null || elem === parent) {
            return null;
        }

        const nextElem = elem[type === 'next' ? 'nextSibling' : 'previousSibling'];
        const parentNode = elem.parentNode;

        if (elem.nodeType !== 3 && !elem.contains?.(type === 'next' ? breakElem : breakElem)) {
            elem.remove();
        }

        if (elem.nodeType === 3) {
            elem.replaceWith('');
        }

        // console.log(elem, parentNode);

        if (nextElem === null) {
            this.removePages(parent, parentNode, type, breakElem);

            return null;
        }

        this.removePages(parent, nextElem, type, breakElem);

        return null;
    }

    splitToPages() {
        const { pageCounter = 0 } = this.state;

        const parent = this.getParent();

        const pages = document.createElement('div');

        new Array(pageCounter === 0 ? 0 : pageCounter).fill({}).forEach((item, key) => {
            const clonedParent = parent.cloneNode(true);

            const pageNumber = key + 1;

            const startBreak = clonedParent.querySelector(`._break[data-key="${pageNumber}"]`);
            const endBreak = clonedParent.querySelector(`._break[data-key="${pageNumber + 1}"]`);

            if (startBreak) {
                this.removePages(clonedParent, startBreak, 'prev', startBreak);
            }

            if (endBreak) {
                this.removePages(clonedParent, endBreak, 'next', endBreak);
            }

            const page = document.createElement('div');

            page.style.height = `${this.getPageSizes().height}px`;

            page.classList.add('pdf__page');

            page.setAttribute('data-key', key);

            if (pageNumber === pageCounter) {
                page.classList.add('_last');
            }

            page.style.paddingTop = `${
                this.getPaddings().top + this.getOtherPadding({ pageCounter: pageNumber })
            }px`;

            clonedParent.querySelectorAll('._break').forEach((breakElem) => {
                breakElem.remove();
            });

            page.append(...clonedParent.childNodes);

            pages.append(page);
        });

        parent.innerHTML = '';

        parent.append(...pages.childNodes);
    }

    getParent() {
        return document.querySelector('.pdf__innerBox');
    }

    getPageSizes() {
        const pdfInfo = this.getPdfInfo();
        const { orientation } = pdfInfo;

        if (orientation === 'landscape') {
            return {
                width: this.toPx(297),
                height: this.toPx(210) - 1,
            };
        }

        return {
            width: this.toPx(210),
            height: this.toPx(297),
        };
    }

    getOtherPadding({ pageCounter }) {
        const { pdf } = this.state;

        if (pageCounter > 1 && pdf.key === 'ordersReport') {
            return this.toPx(5);
        }

        return 0;
    }

    init() {
        const { pdf } = this.state;
        const { top: paddingTop, bottom: paddingBottom } = this.getPaddings();
        const { height: pageHeight } = this.getPageSizes();
        let allHeight = 0;
        let currentTop = 0;
        let pageCounter = 1;

        const pdfInfo = this.getPdfInfo();

        if (pdfInfo.isImage) {
            const { offsetWidth, offsetHeight } = this.parent.current;

            this.parent.current.setAttribute('data-width', offsetWidth);
            this.parent.current.setAttribute('data-height', offsetHeight + 4);

            return;
        }

        const parent = this.getParent();

        const allTextNodes = this.getAllTextNodes(parent);

        if (1) {
            allTextNodes.forEach((textNode) => {
                const replacer = document.createElement('span');

                replacer.innerHTML = textNode.data
                    ?.split(' ')
                    .map((textItem) =>
                        textItem.length >= 25 ? `<span class="_wrap">${textItem}</span>` : textItem,
                    )
                    .join(' ');

                textNode.replaceWith(replacer);

                replacer.replaceWith(...replacer.childNodes);
            });
        }

        const allNodes = this.getAllNodes(parent);

        // parent.querySelectorAll('table').forEach((table, key) => {
        //     table.querySelectorAll('tr').forEach((row, rowKey) => {
        //         row.querySelectorAll('td').forEach((col, colKey) => {
        //             col.setAttribute('data-table', key);
        //             col.setAttribute('data-row', rowKey);
        //             col.setAttribute('data-col', colKey);
        //         });
        //     });
        // });

        const addBreak = ({ node, startPos, endPos, nodeIsGap }) => {
            const breakElem = document.createElement('p');

            breakElem.classList.add('_break');
            breakElem.setAttribute('data-key', pageCounter);
            breakElem.style.paddingTop = `${paddingTop}px`;

            if (pageCounter > 1 && pdf.key === 'ordersReport') {
                breakElem.style.paddingTop = `${
                    paddingTop + this.getOtherPadding({ pageCounter })
                }px`;
            }

            if (this.checkElem(node)) {
                node.parentNode.insertBefore(breakElem, node);
            } else {
                const wrapper = document.createElement('div');
                const startText = document.createTextNode(node.nodeValue.slice(0, startPos));
                const middleText = document.createTextNode(node.nodeValue.slice(startPos, endPos));
                const endText = document.createTextNode(node.nodeValue.slice(endPos));
                let clonePrev;

                if (node.previousSibling?.classList?.contains?.('_abs')) {
                    clonePrev = node.previousSibling.cloneNode(true);

                    node.previousSibling.remove();
                }

                wrapper.appendChild(startText);
                wrapper.appendChild(breakElem);

                if (clonePrev) {
                    wrapper.appendChild(clonePrev);
                }

                wrapper.appendChild(middleText);
                wrapper.appendChild(endText);

                node.replaceWith(wrapper);

                wrapper.replaceWith(...wrapper.childNodes);
            }

            if (nodeIsGap && 1) {
                if (
                    node.nextSibling?.innerText?.trim().replace(/[\u200B-\u200D\uFEFF]/g, '')
                        .length === 0
                ) {
                    node.nextSibling.remove();
                }

                node.remove();
            }
        };

        let lastNodeHeight = 0;

        allNodes.forEach((node, nodeKey) => {
            const nodeItems = this.checkElem(node) ? [node] : node.nodeValue?.split(' ');

            if (nodeKey === 0) {
                addBreak({ node, nodeKey, startPos: 0, endPos: 0 });
            }

            nodeItems.forEach((nodeItem, key) => {
                let rects;
                let startPos;
                let endPos;

                if (this.checkElem(nodeItem)) {
                    const elemRect = nodeItem.getBoundingClientRect();

                    rects = {
                        top: elemRect.y,
                        left: elemRect.x,
                        width: elemRect.width,
                        height: elemRect.height,
                    };
                } else {
                    const range = document.createRange();

                    startPos =
                        key === 0
                            ? 0
                            : nodeItems
                                  .filter((prevItem, prevKey) => prevKey < key)
                                  .reduce((prev, cur) => prev + cur.length + 1, 0);
                    endPos = startPos + nodeItem.length;

                    range.setStart(node, startPos);
                    range.setEnd(node, endPos);

                    rects = range.getClientRects()[0];
                }

                if (rects) {
                    const { top, height } = rects;

                    const nodeIsGap = nodeItem?.classList?.contains('_gap');

                    lastNodeHeight = height;

                    if (currentTop < top) {
                        const offsetTop = top - currentTop;

                        allHeight += offsetTop;

                        currentTop = top;

                        const pageBreak = pageHeight * pageCounter - paddingBottom;

                        if (allHeight + height >= pageBreak || nodeIsGap) {
                            if (nodeIsGap) {
                                allHeight -= height;
                            }

                            const lastHeight = allHeight;

                            // console.log(
                            //     node,
                            //     nodeItem,
                            //     nodeItems[key - 1],
                            //     offsetTop,
                            //     allHeight,
                            //     height,
                            //     pageBreak,
                            // );

                            allHeight += pageHeight * pageCounter - lastHeight;

                            pageCounter += 1;

                            addBreak({ node, nodeKey, startPos, endPos, nodeIsGap });
                        }
                    }
                }
            });
        });

        allHeight += lastNodeHeight;

        document.querySelector('body').setAttribute('data-counter', pageCounter);

        this.setState({ pageCounter, allHeight }, () => {
            this.splitToPages();

            this.afterInit();
        });
    }

    getPages() {
        const { pageCounter = 0 } = this.state;
        const pdfInfo = this.getPdfInfo();
        const { paginationFull } = pdfInfo;

        return new Array(pageCounter > 0 ? pageCounter - (paginationFull ? 0 : 1) : pageCounter)
            .fill({})
            .map((item, key) => key);
    }

    renderPagination({ key, render }) {
        const { pageCounter } = this.state;
        const { height: pageHeight } = this.getPageSizes();
        const pdfInfo = this.getPdfInfo();
        const { pagination } = pdfInfo;
        const top = pageHeight * key;

        return (
            <div
                className={`pdf__pagenation _col ${pagination === 'top' ? '_top' : ''}`}
                style={{
                    top: `${top}px`,
                    height: `${pageHeight}px`,
                    padding: `${this.getPaddings(true).top * 0.5}px 0 ${
                        this.getPaddings(true).bottom * 0.5
                    }px 0`,
                }}
            >
                <div className="pdf__pagenationBox">{render({ pageCounter })}</div>
            </div>
        );
    }

    renderFoot({ render }) {
        return (
            <div className="pdf__foot _col" style={{ height: `${this.getPageSizes().height}px` }}>
                <div className="pdf__footInner">{render()}</div>
            </div>
        );
    }

    setFoot() {
        const { allHeight } = this.state;
        let { pageCounter } = this.state;

        const foot = document.querySelector('.pdf__foot');
        const { height: pageHeight } = this.getPageSizes();

        if (foot) {
            const footInner = foot.querySelector('.pdf__footInner');
            const prevElem = foot.previousElementSibling;

            if (prevElem) {
                const footHeight = footInner.offsetHeight;
                const resultHeight =
                    allHeight + footHeight + this.toPx(5) + this.getPaddings().bottom;

                const lastPageCounter = Math.ceil(resultHeight / pageHeight);

                console.log(allHeight / pageHeight);

                if (lastPageCounter > pageCounter) {
                    pageCounter = lastPageCounter;

                    this.setState({ pageCounter: lastPageCounter });

                    document.querySelector('body').setAttribute('data-counter', lastPageCounter);
                }

                foot.style.top = `${(pageCounter - 1) * pageHeight}px`;

                foot.style.padding = `${this.getPaddings(true).top}px 0 ${
                    this.getPaddings(true).bottom
                }px 0`;
            }
        }
    }

    setReadyCallback(readyCallback) {
        if (readyCallback) {
            this.readyCallback = readyCallback;
        }
    }

    afterInit() {
        const parent = this.getParent();

        parent.querySelectorAll('table').forEach((table) => {
            if (!table.querySelector('tr') || !table.querySelector('td')) {
                table.remove();
            }
        });

        this.setFoot();

        if (this.readyCallback) {
            this.readyCallback();
        }
    }
}

export default Template;

Template.propTypes = {
    pdf: PropTypes.object,
    currentPagesCounter: PropTypes.number,
    pageStep: PropTypes.number,
};
