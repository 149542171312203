import getUserName from '@functions/getUserName';
import UserT from '@global/models/User';

const executorShortMain = {
    title: 'Личные данные',
    description: (model: UserT) => getUserName({ user: model }),
    fieldsOrder: ['secondName', 'firstName', 'thirdName'],
    fields: {
        secondName: {
            support: 'Фамилия',
            prop: 'secondName',
            type: 'input',
            group: 1,
            width: 1,
        },
        firstName: {
            support: 'Имя',
            prop: 'firstName',
            type: 'input',
            group: 1,
            width: 1,
        },
        thirdName: {
            support: 'Отчество',
            prop: 'thirdName',
            type: 'input',
            group: 1,
            width: 1,
        },
    },
} as const;

export default executorShortMain;
