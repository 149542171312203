import React from 'react';

import Checkbox from '@components/checkbox/Checkbox';

import AlertI from '../../types';

const parseAct = {
    title: 'Распределение актов',
    description: `Из данных актов сформируются реестры, которые будут распределены по компаниям. Данный реестр будет&nbsp;удален`,
    buttonText: 'Распределить',
    otherCancel(this: AlertI) {
        const { model } = this.state;

        return (
            <>
                <div className="v2popup__checkbox _top">
                    <Checkbox
                        name="actsJoin"
                        onChange={() => {
                            this.change({ actsJoin: !model?.actsJoin });
                        }}
                        className="_lightWeight"
                        info="Все акты исполнителя<br/>будут объединены в один акт"
                        value={!!model?.actsJoin}
                    >
                        Объединить акты
                    </Checkbox>
                </div>
            </>
        );
    },
} as const;

export default parseAct;
