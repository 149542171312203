import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';
import handlerSocket from './methods/handlerSocket.ts';

import MailingsI from './types.ts';

import mailingHandler from './actions/mailingHandler.ts';

class Mailings extends WidgetWrapper<MailingsI['props'], MailingsI['state']> implements MailingsI {
    parent: MailingsI['parent'];

    constructor(props: MailingsI['props']) {
        super(props);
        this.state = {};

        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    getActions = getActions;
    getMoreActions = getMoreActions;

    mailingHandler = mailingHandler;
    handlerSocket = handlerSocket;

    componentDidMount(): void {
        const { user } = this.props;

        this.setState({ user });

        (document.addEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <Widget
                    name="chatMailings"
                    title="Чат"
                    description="Рассылки"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as MailingsI).getFilterQuery?.()}
                >
                    <Table
                        name="chatMailings"
                        isWrap={true}
                        isInit={true}
                        isRowHeight={true}
                        callback={this.tableCallback!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        link={{
                            pageName: 'mailing-inner',
                            level: 2,
                        }}
                        otherData={{
                            mailingHandler: this.mailingHandler.bind(this),
                            getMoreActions: this.getMoreActions.bind(this),
                        }}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Mailings);
