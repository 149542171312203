import React from 'react';
import PropTypes from 'prop-types';

class ExecutorOrg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    info = {
        FL: {
            content: 'ФЛ',
            fullContent: 'Физ. лицо',
        },
        SMZ: {
            content: 'СМЗ',
            fullContent: 'Самозанятый',
        },
        IP: {
            content: 'ИП',
            fullContent: 'Индивидуальный предприниматель',
        },
    };

    render() {
        const { name, className = '', full } = this.props;
        const info = this.info[name];

        return (
            <div className={`executorOrg _${name} ${className}`}>
                {info?.[full ? 'fullContent' : 'content']}
            </div>
        );
    }
}

export default ExecutorOrg;

ExecutorOrg.propTypes = {
    name: PropTypes.string,
    full: PropTypes.bool,
    className: PropTypes.string,
};
