import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox.jsx';
import Button from '../Button.jsx';

class EditorTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { callback } = this.props;

        return (
            <div className="editor__actionAlert">
                <div className="editor__actionAlertInner _windowWithCursor _topRight">
                    <div className="editor__actionAlertHead">
                        <div className="editor__actionAlertTitle">
                            Создание таблицы:{' '}
                            <span>
                                {this.state.tableCol !== undefined ? this.state.tableCol + 1 : 0}x
                                {this.state.tableRow !== undefined ? this.state.tableRow + 1 : 0}
                            </span>
                        </div>
                        <div className="editor__actionAlertDescription">
                            Выберите курсором нужное кол-во ячеек и кликните на последнюю
                            из&nbsp;них, чтобы создать таблицу
                        </div>
                    </div>
                    <div className="editor__actionAlertContent _col">
                        {new Array(5).fill({}).map((row, rowKey) => {
                            const isRowActive = this.state.tableRow >= rowKey;

                            return (
                                <div className="editor__actionAlertRow _row" key={rowKey}>
                                    {new Array(8).fill({}).map((col, colKey) => {
                                        const isColActive =
                                            isRowActive && this.state.tableCol >= colKey;

                                        return (
                                            <div
                                                className={`editor__actionAlertCol _col _click ${
                                                    isColActive ? '_active' : ''
                                                } ${
                                                    this.state.tableRow === rowKey &&
                                                    this.state.tableCol === colKey
                                                        ? '_current'
                                                        : ''
                                                }`}
                                                key={colKey}
                                                onClick={() => {
                                                    if (
                                                        this.state.tableRow === rowKey &&
                                                        this.state.tableCol === colKey
                                                    ) {
                                                        this.setState({
                                                            tableRow: undefined,
                                                            tableCol: undefined,
                                                        });
                                                    } else {
                                                        this.setState({
                                                            tableRow: rowKey,
                                                            tableCol: colKey,
                                                        });
                                                    }
                                                }}
                                            ></div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                        <label className="editor__actionAlertCheckbox _click">
                            <div className="editor__actionAlertCheckboxBox">
                                <Checkbox
                                    className="_square _blue"
                                    value={!!this.state.isTableWidthFix}
                                    handler={() => {
                                        this.setState({
                                            isTableWidthFix: !this.state.isTableWidthFix,
                                        });
                                    }}
                                />
                            </div>
                            Фиксированная ширина колонок
                        </label>
                        <div className="editor__actionAlertButton">
                            <Button
                                className="_main _medium2Size"
                                onClick={(e) => {
                                    if (this.state.tableRow !== undefined) {
                                        callback(e, {
                                            row: this.state.tableRow + 1,
                                            col: this.state.tableCol + 1,
                                            isTableWidthFix: this.state.isTableWidthFix,
                                        });

                                        this.setState({
                                            tableRow: undefined,
                                            tableCol: undefined,
                                            isTableWidthFix: undefined,
                                        });
                                    }
                                }}
                            >
                                Создать таблицу
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(EditorTable);

EditorTable.propTypes = {
    callback: PropTypes.func,
};
