import React from 'react';
import { connect } from 'react-redux';

import Table from '@components/crm/table/Table.tsx';

import LogsI from './types.ts';

class Logs extends React.Component<LogsI['props'], LogsI['state']> implements LogsI {
    constructor(props: LogsI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { corporationId, models } = this.props;

        return (
            <>
                <Table
                    name="modelLogs"
                    isNotLink={true}
                    isInit={!!corporationId}
                    getQuery={() =>
                        models || [
                            { key: 'modelId', value: corporationId! },
                            { key: 'modelName', value: 'corporation' },
                        ]
                    }
                    isWrap={true}
                    getRowClassName={() => '_colCenter'}
                />
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Logs);
