import React from 'react';

import UserT from '@global/models/User.ts';

import { RenderColsT } from '../../types.ts';

const renderListUsers: RenderColsT<'listUsers'> = function ({ name: colName, item }) {
    let content;
    let className;

    const user = item as Pick<
        UserT,
        '_id' | 'firstName' | 'secondName' | 'thirdName' | 'fullName' | 'email'
    > & {
        roleName?: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{user.fullName},</div>{' '}
                <div className="v2table__text _grey _NOWRAP">{user.email}</div>
                {user.roleName && <div className="v2table__text">, {user.roleName}</div>}
            </>
        );
    }

    return { content, className };
};

export default renderListUsers;
