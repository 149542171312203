import getEndText from './getEndText';

export default function getDiffTime(dateStr: string | Date): string {
    const date = new Date(dateStr);
    const nowDate = new Date();
    const time = (date.getTime() - nowDate.getTime()) / 60_000;

    if (time < 60) {
        return 'меньше часа';
    }

    if (time < 24 * 60) {
        const hours = Math.round(time / 60);

        return `${hours} ${getEndText(hours, ['час', 'часа', 'часов'])}`;
    }

    const days = Math.round(time / (24 * 60));

    return `${days} ${getEndText(days, ['день', 'дня', 'дней'])}`;
}
