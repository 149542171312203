import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { updatedItemKey } = this.state;
    const { doc, checkChange } = this.props;
    const canChange = !checkChange || checkChange(doc);
    const { files } = doc;
    const resultFiles: { _id: string }[] = [...files];

    if (files.length < 5 && canChange) {
        resultFiles.push({ _id: 'add' });
    }

    const renderKey = (doc as any).version;

    return (
        <div className="v2widgetDoc__content">
            <div className="v2widgetDoc__filesWrapper _NOSCROLL">
                <ListAbsoluteMain
                    className="v2widgetDoc__files _ITEMS"
                    items={resultFiles}
                    renderItem={this.renderFile.bind(this)}
                    classNameItem="v2widgetDoc__file"
                    prop="_id"
                    paramsParent={{ width: 'auto' }}
                    styles={['width']}
                    keyRender={updatedItemKey}
                    keyUpdateItem={`${updatedItemKey}${renderKey}`}
                    sort={(innerFiles) =>
                        [...innerFiles].sort((a, b) => {
                            const aW = a._id === 'add' ? 1 : 0;
                            const bW = b._id === 'add' ? 1 : 0;

                            return aW - bW;
                        })
                    }
                />
            </div>
        </div>
    );
};

export default renderContent;
