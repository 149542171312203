import getCurrentCorporation, {
    CurrentCorporationInfoT,
} from '@functions/crm/getCurrentCorporation.ts';

import I from '../types.ts';

const getCommission: I['getCommission'] = function () {
    const { model } = this.state;
    const { user } = this.props;
    const amount = !model || Number.isNaN(+(model.amount || 0)) ? 0 : +(model.amount || 0);
    const tariff = (getCurrentCorporation({ user }) as CurrentCorporationInfoT)?.tariff;

    let amountCommission = +(amount * (tariff.pay.percent / 100)).toFixed(2);

    if (amountCommission < tariff.pay.min) {
        amountCommission = tariff.pay.min;
    }

    return amountCommission;
};

export default getCommission;
