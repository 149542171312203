import React from 'react';

import Index from '../components/Index/Index.tsx';

import Settings from '../../settings/Settings.tsx';

const pages = {
    'corporations-index': {
        render() {
            return (
                <>
                    <Index />
                </>
            );
        },
    },
    'corporations-inner': {
        render() {
            return (
                <>
                    <Settings inner={true} />
                </>
            );
        },
    },
} as const;

export default pages;
