import AlertI from '../../types';

const userRemoveProxy = {
    title: 'Внимание!',
    description(this: AlertI) {
        return `На сотрудника имеется доверенность. В случае смены роли она будет расторгнута`;
    },
    buttonText: 'Не сохранять',
    cancelText: 'Всё равно сохранить',
} as const;

export default userRemoveProxy;
