import MainI from '../types.ts';

const handlerSocket: MainI['handlerSocket'] = function ({ detail: { name, data } }) {
    // const { groupId } = this.props;

    if (name === 'pays') {
        const { fields } = data;
        const items = (fields?.items as []) || [];

        this.tableUpdateManyItems!({ items });

        if (fields?.item) {
            const item = fields?.item as { _id: string };

            this.tableUpdateItem!({
                id: item._id,
                fields: item,
            });

            // this.updateGroup({ fields });
        }
    }
};

export default handlerSocket;
