import CalendarI from '../types.ts';

const setNextDate: CalendarI['setNextDate'] = function (dir) {
    const { activeDate } = this.state;
    const { withPast } = this.props;

    const date = new Date(activeDate as Date);

    date.setMonth(date.getMonth() + 1 * (dir === 'next' ? 1 : -1));

    const nowDate = new Date();

    nowDate.setDate(1);

    if (this.getId(date) >= this.getId(nowDate) || withPast) {
        this.setDate(date);
    }
};

export default setNextDate;
