import getFormatedDate from '@functions/getFormatedDate';

import { FieldT } from '../cards';

const executorMvd = {
    fieldsOrder: ['number', 'status', 'date', 'comment', 'files'],
    fields: {
        number: {
            support: 'Номер заявления',
            type: 'input',
        },
        status: {
            support: 'Статус заявления',
            prop: 'status',
            selectTextKey: 'statusName',
            type: 'select',
            selectList: 'executorMvdStatuses',
            selectProps: {
                name: 'statusName',
            },
        },
        date: {
            support: 'Дата подачи заявления',
            type: 'date',
            reg: 'date',
            withTime: false,
            withPast: true,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
        comment: {
            support: 'Комментарий',
            type: 'input',
            textarea: true,
        },
        files: {
            support: 'Файлы',
            type: 'files',
            prop: 'files',
            galeryTitle: 'Уведомление МВД',
            accepts: '.jpg,.jpeg,.png,.pdf',
            maxFiles: 5,
        },
    },
} as const;

export default executorMvd;
