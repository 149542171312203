const chatTemplates = {
    colsOrder: ['name', 'tags', 'content', 'files'],
    cols: {
        name: {
            content: 'Название шаблона',
            width: 23,
        },
        tags: {
            content: 'Теги',
            width: 15,
        },
        content: {
            content: 'Текст шаблона',
            width: 48,
        },
        files: {
            content: 'Вложения',
            width: 14,
        },
    },
    deletesCols: [],
    url: 'chat-template',
    emptyInfo: {
        title: 'Шаблонов пока нет',
        description: 'Вы можете создать шаблон по кнопке справа',
    },
} as const;

export default chatTemplates;
