import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import download from '@functions/download.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function (this: I) {
    const { files = [], title, currentId } = this.state;
    const { galeryPopup, pin } = this.props;
    const { withRotate } = galeryPopup;
    const currentFile = files.find((file) => file._id === currentId);
    const actions: WidgetActionsT[] = [
        ...(pin
            ? ([
                  {
                      type: 'action',
                      name: 'pin',
                      group: 'actions',
                      icon: 'pin',
                      onClick: async () => {
                          localStorage.removeItem('galeryPin');

                          await this.savePin();

                          await dispatcher<'crm'>({ type: 'galeryPin', data: false });
                      },
                      cursorSupport: {
                          _id: 'galery-pin',
                          dir: 'bottom',
                          content: 'Открепить',
                      },
                  },
              ] as WidgetActionsT[])
            : ([
                  ...(currentFile?.src
                      ? [
                            {
                                type: 'action',
                                name: 'download',
                                group: 'actions',
                                icon: 'download',
                                onClick: async () => {
                                    await download({
                                        name: title!,
                                        files: [currentFile!],
                                    });
                                },
                                cursorSupport: {
                                    _id: 'galery-download',
                                    dir: 'bottom',
                                    content: 'Скачать файл',
                                },
                            },
                        ]
                      : []),

                  {
                      type: 'action',
                      name: 'blank',
                      group: 'actions',
                      icon: 'link',
                      onClick: async () => {
                          window.open(currentFile?.fullSrc, '_blank');
                      },
                      cursorSupport: {
                          _id: 'galery-blank',
                          dir: 'bottom',
                          content: 'Открыть в браузере',
                      },
                  },
                  {
                      type: 'action',
                      name: 'pin',
                      group: 'actions',
                      icon: 'pin',
                      onClick: async () => {
                          localStorage.setItem('galeryPin', 'true');

                          handlerPopup('galeryPopup', {
                              isShow: true,
                              currentId: this.state.currentId,
                          });

                          await dispatcher<'crm'>({ type: 'galeryPin', data: true });
                      },
                      cursorSupport: {
                          _id: 'galery-pin',
                          dir: 'bottom',
                          content: 'Закрепить',
                      },
                  },
              ] as WidgetActionsT[])),
        {
            type: 'close',
            name: 'close',
            group: 'close',
            onClick: async () => {
                this.close();
            },
        },
    ];

    if (
        withRotate &&
        currentFile &&
        ['jpg', 'jpeg', 'png'].includes(currentFile.fullSrc.split('.').pop()!.toLowerCase())
    ) {
        actions.unshift({
            type: 'action',
            name: 'rotate',
            group: 'actions',
            icon: 'rotate',
            onClick: this.rotate.bind(this),
            cursorSupport: {
                _id: 'galery-rotate',
                dir: 'bottom',
                content: 'Повернуть на 90°',
            },
        });
    }

    if (files.length > 1 && !pin) {
        actions.splice(actions.length - 1, 0, {
            type: 'link',
            name: 'downloadAll',
            group: 'links',
            content: 'Скачать все',
            className: '_main',
            onClick: async () => {
                await download({
                    name: title!,
                    files: files.map((file) => ({
                        name: file.name,
                        src: file.src,
                    })),
                });
            },
        });
    }

    return actions;
};

export default getActions;
