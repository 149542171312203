import React from 'react';

import CorporationInfo from '@components/crm/manual/CorporationInfo.jsx';
import Icon from '@components/icon/Icon.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import { CorporationListT } from '@global/models/Corporation.ts';

import { RenderColsT } from '../../types.ts';

const renderCorporations: RenderColsT<'corporations'> = function ({ name: colName, item }) {
    const corporation = item as CorporationListT;
    let content;
    let className;

    if (colName === 'id') {
        content = <>{corporation.id}</>;
    }

    if (colName === 'name') {
        className = '_visible';

        content = (
            <>
                <div className="v2table__corporation">
                    <CorporationInfo logo={corporation.logo} name={corporation.name} />
                </div>
            </>
        );
    }

    if (colName === 'inn') {
        content = corporation.inn;
    }

    if (colName === 'bic') {
        content = corporation.bic;
    }

    if (colName === 'users') {
        content = corporation.usersCounter;
    }

    if (colName === 'balance') {
        content = <>{getFormatPrice(corporation.balance)} ₽</>;
    }

    if (colName === 'status') {
        const status = corporation.statusText;
        const date = corporation.statusDate;
        let statusClassName = '';

        if (corporation.status === 'active') {
            statusClassName = '_successColor';
        } else {
            statusClassName = '_grey';
        }

        const supportProps =
            date &&
            setCursorSupport(
                {
                    _id: `date-${corporation._id}`,
                    content: getFormatedDate({
                        date: new Date(date),
                        type: 'full',
                    }),
                    dir: 'top',
                },
                { targetClassName: '.v2table__textInfo', className: '' },
            );

        content = (
            <div className={`v2table__text _mediumWeight ${statusClassName}`}>
                {status}{' '}
                {date && (
                    <i className="v2table__textInfo _CLICK" {...supportProps}>
                        <Icon name="info" />
                    </i>
                )}
            </div>
        );
    }

    return { content, className };
};

export default renderCorporations;
