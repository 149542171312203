export default function getDeepValue({ item, name }) {
    if (!item) {
        return null;
    }

    const names = name.split('.');
    const lastKey = names.pop();

    let resultItem = item;

    names.forEach((key) => {
        if (resultItem) {
            resultItem = resultItem[key];
        }
    });

    return {
        item: resultItem,
        lastKey,
        value: resultItem[lastKey],
    };
}
