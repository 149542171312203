import React from 'react';

import LazyImage from '@components/lazyImage/lazyImage.tsx';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    const { files = [], currentId } = this.state;
    const { pin } = this.props;

    return (
        <div className="v2popup__foot">
            <div className="v2popupGaleryFoot _NOSCROLL">
                <div className="v2popupGaleryFoot__files">
                    {files.map((file) => (
                        <div
                            className={`v2popupGaleryFoot__file _CLICK ${currentId === file._id ? '_current' : ''}`}
                            key={file._id}
                            onClick={() => {
                                this.setState({ currentId: file._id });
                            }}
                        >
                            <LazyImage
                                src={file.fullSrc}
                                name={pin ? undefined : file.name}
                                checkFit={true}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default renderFoot;
