import MobPopupI from '../types.ts';

const checkShow: MobPopupI['checkShow'] = function () {
    const { isInit } = this.props;

    if (isInit && !this.isInit) {
        this.isInit = true;

        this.setMaxHeight();
        this.checkChangeDisabled();

        setTimeout(() => {
            this.animate('top');
        }, 10);
    }
};

export default checkShow;
