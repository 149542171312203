import PropTypes from 'prop-types';
import { rubles } from 'rubles';

import React from 'react';

import getFormatPrice from '../../functions/getFormatPrice';
import getFormatedDate from '../../functions/getFormatedDate';
import getUserIncline from '../../functions/getUserIncline';
import getUserInfo from '../../functions/getUserInfo';
import setSpacesInText from '../../functions/setSpacesInText';

import Sign from './Sign.jsx';

class PayAct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderFoot() {
        const { renderFoot, pdf } = this.props;
        const { otherData, data } = pdf;
        const { docDate } = otherData;
        const { signId, signUserInfo, signExecutorInfo } = data;

        const render = () => (
            <>
                <Sign
                    id={signId}
                    docDate={docDate}
                    signUserInfo={signUserInfo}
                    signExecutorInfo={signExecutorInfo}
                />
            </>
        );

        return renderFoot({ render });
    }

    componentDidMount() {
        const { init } = this.props;

        init();
    }

    render() {
        const { pdf } = this.props;
        const { corporationInfo, data } = pdf;
        const { pay, executorInfo, joinContract, prevAmount } = data;
        const rublesAmount = rubles(pay.amount)?.toString();
        const companyAmount = pay.fullAmount - pay.amount;

        let nextAmount = prevAmount;

        if (pay.status !== 'completed') {
            nextAmount += pay.amount;
        }

        const period = `c ${getFormatedDate({
            date: new Date(pay.startPeriod),
            isShortYear: true,
        })} по 
            ${getFormatedDate({
                date: new Date(pay.endPeriod),
                isShortYear: true,
            })}`;

        let passport = '';

        if (executorInfo.passport.series) {
            passport += executorInfo.passport.series;
            passport += ' ';
        }

        if (executorInfo.passport.number) {
            passport += executorInfo.passport.number;
            passport += ', ';
        }

        if (executorInfo.passport.nameGet) {
            passport += 'выдан ';
            passport += executorInfo.passport.nameGet;
            passport += ' ';
        }

        if (passport) {
            passport = passport.slice(0, -1);
        }

        return (
            <div className="pdf__inner _col">
                <div className="pdf__innerBox">
                    {data.organization === 'FL-A' ? (
                        <>
                            <div className="pdfAgentPayAct">
                                <div className="pdfAgentPayAct__head _col">
                                    <div className="pdfAgentPayAct__headContent">
                                        <div className="pdfAgentPayAct__headText">
                                            {corporationInfo.name}
                                        </div>
                                        <div className="pdfAgentPayAct__headText">
                                            {corporationInfo.address}
                                        </div>
                                    </div>
                                </div>
                                <div className="pdfAgentPayAct__title">
                                    Отчет Агента № {pay.number} от{' '}
                                    {getFormatedDate({
                                        date: new Date(pay.date),
                                        type: 'textYear',
                                    })}
                                    <br />
                                    по Договору № {joinContract.number} от{' '}
                                    {getFormatedDate({
                                        date: new Date(joinContract.date),
                                        type: 'textYear',
                                    })}
                                </div>
                                <div className="pdfAgentPayAct__text">
                                    {corporationInfo.name} (далее – {'"'}Агент{'"'}) в лице{' '}
                                    {getUserInfo({
                                        type: 'name',
                                        user: getUserIncline({ user: corporationInfo.holder }),
                                    })}
                                    , действующей на основании Устава, составил настоящий отчет о
                                    том, что Агентом были выполнены следующие действия по поручению
                                    Принципала (
                                    {getUserInfo({
                                        type: 'name',
                                        user: getUserIncline({ user: executorInfo }),
                                    })}
                                    ):
                                </div>
                                <div className="pdfAgentPayAct__text _indent">
                                    <div className="_counter">1.</div>
                                    Агентом по поручению Принципала совершены действия от своего
                                    имени, но за счет Принципала по сбору, анализу, обобщению
                                    информации о наличии заказов на сборку и&nbsp;доставку товаров
                                    из&nbsp;магазинов, поиску Клиентов, проведению необходимых
                                    переговоров с&nbsp;Клиентами, которым необходима сборка
                                    и&nbsp;доставка товаров для целей заключения между Клиентом
                                    и&nbsp;Принципалом договора оказания услуг по сборке и доставке
                                    товаров, приему безналичных платежей Клиентов в полном
                                    соответствии с условиями Договора № {
                                        joinContract.number
                                    } от{' '}
                                    {getFormatedDate({
                                        date: new Date(joinContract.date),
                                        type: 'textYear',
                                    })}
                                </div>
                                <div className="pdfAgentPayAct__text _indent">
                                    <div className="_counter">2.</div> В результате исполнения
                                    поручения Принципала {period} (Отчетный период). Агентом приняты
                                    безналичные платежи Пользователей.
                                </div>
                                <div className="pdfAgentPayAct__text _indent">
                                    <div className="_counter">3.</div> Расшифровка платежей за
                                    Отчетный период {period}.
                                </div>
                                <div className="pdfAgentPayAct__table">
                                    <table>
                                        <tr>
                                            <td>Входящее сальдо на начало отчетного периода</td>
                                            <td>{getFormatPrice(+prevAmount.toFixed(2))} ₽</td>
                                        </tr>
                                        <tr>
                                            <td>Получено от Клиентов в адрес Принципала</td>
                                            <td>{getFormatPrice(+pay.fullAmount.toFixed(2))} ₽</td>
                                        </tr>
                                        <tr>
                                            <td>Удержано Агентом:</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                &nbsp;&nbsp;&nbsp;&nbsp;Агентское вознаграждение
                                            </td>
                                            <td>{getFormatPrice(+companyAmount.toFixed(2))} ₽</td>
                                        </tr>
                                        <tr>
                                            <td>Перечислено Агентом на счет Принципала</td>
                                            <td>{getFormatPrice(+pay.amount.toFixed(2))} ₽</td>
                                        </tr>
                                        <tr>
                                            <td>Исходящее сальдо на конец отчетного периода</td>
                                            <td>{getFormatPrice(+nextAmount.toFixed(2))} ₽</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="pdfAgentPayAct__text">
                                    Поручение считается исполненным Агентом надлежащим образом и
                                    принятым Принципалом в&nbsp;указанном в настоящем Отчете объеме,
                                    если в течение срока, указанного в Договоре №{' '}
                                    {joinContract.number} от{' '}
                                    {getFormatedDate({
                                        date: new Date(joinContract.date),
                                        type: 'textYear',
                                    })}
                                    , от Принципала не поступило мотивированных письменных
                                    возражений.
                                </div>
                                <div className="pdfAgentPayAct__text">
                                    По истечении срока, указанного выше, претензии относительно
                                    недостатков выполненного Агентом поручения, в том числе по
                                    количеству (объему) и качеству не принимаются.
                                </div>
                            </div>
                        </>
                    ) : data.organization === 'SMZ' && data.isNonResident ? (
                        <>
                            <div className="pdfNonResidentPayAct">
                                <div className="pdfNonResidentPayAct__title">
                                    ДОГОВОР-СЧЕТ-АКТ №{pay.number}
                                </div>
                                <div className="pdfNonResidentPayAct__head _row">
                                    <div className="pdfNonResidentPayAct__headItem">г. Москва</div>
                                    <div className="pdfNonResidentPayAct__headItem">
                                        {getFormatedDate({
                                            date: new Date(pay.date),
                                            type: 'textYear',
                                        })}
                                    </div>
                                </div>
                                <div className="pdfNonResidentPayAct__text">
                                    {corporationInfo.name} в лице Генерального директора{' '}
                                    {getUserInfo({
                                        type: 'name',
                                        user: getUserIncline({ user: corporationInfo.holder }),
                                    })}
                                    , действующего на основании Устава, именуемое в дальнейшем
                                    «Заказчик», с одной стороны, и{' '}
                                    {getUserInfo({
                                        type: 'name',
                                        user: getUserIncline({ user: executorInfo }),
                                    })}
                                    , являющийся налогоплательщиком «Налога на профессиональный
                                    доход», именуемый(-ая) в дальнейшем «Исполнитель», с другой
                                    стороны, в дальнейшем именуемые Сторонами, заключили настоящий
                                    Акт о нижеследующем:
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">1.</div>
                                    Исполнитель обязуется в соответствии с условиями ДОГОВОРА
                                    ОКАЗАНИЯ УСЛУГ №{joinContract.number}, в соответствии с
                                    условиями принятыми Исполнителем, оказывать услуги,
                                    перечисленные в договоре, а Заказчик обязуется оплатить услуги,
                                    оказываемые Исполнителем.
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">2.</div>
                                    Итоговая стоимость фактически оказанных услуг определяется
                                    исходя из стоимости каждой конкретной услуги и общего количества
                                    услуг, оказанных Исполнителем, и указывается в п.3 настоящего
                                    документа.
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">3.</div>
                                    <div className="pdfNonResidentPayAct__textInner">
                                        Исполнителем в соответствии с условиями п.1 настоящего
                                        документа оказаны услуги для Заказчика:
                                    </div>
                                    <div className="pdf__table _nonResidentPayAct _3">
                                        <div className="pdf__elem _col JSpdfElem">
                                            <div className="pdf__tableRow _row _head">
                                                <div className="pdf__tableCol _left _number">№</div>
                                                <div className="pdf__tableCol _left _name">
                                                    Наименование услуг
                                                </div>
                                                <div className="pdf__tableCol _left _amount">
                                                    Сумма, руб.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pdf__elem _col JSpdfElem">
                                            <div className="pdf__tableRow _row">
                                                <div className="pdf__tableCol _number">1</div>
                                                <div className="pdf__tableCol _name">
                                                    {pay.name}
                                                </div>
                                                <div className="pdf__tableCol _amount">
                                                    {getFormatPrice(pay.amount)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">4.</div>
                                    Оплата Заказчиком услуг Исполнителю в порядке п.3 настоящего
                                    документа, подтверждает, что услуги оказаны полностью,
                                    своевременно и надлежащим образом. Объем оказанных услуг, их
                                    общая стоимость и размер оплаты согласован. Претензий не
                                    имеется.
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">5.</div>
                                    Стоимость услуг: {getFormatPrice(pay.amount)} руб. (
                                    {rublesAmount}), без НДС.
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">6.</div>
                                    Период оказания услуг: {period}
                                </div>
                                <div className="pdfNonResidentPayAct__text _indent">
                                    <div className="pdfNonResidentPayAct__textCounter">7.</div>
                                    Настоящий документ составлен в двух экземплярах, имеющих равную
                                    юридическую силу, по одному экземпляру для каждой из Сторон.
                                </div>
                                <div className="JSpdfElem pdfNonResidentPayAct__footer _row">
                                    <div className="pdfNonResidentPayAct__footerBlock _col">
                                        <div className="pdfNonResidentPayAct__footerText _head">
                                            <b>Заказчик:</b>
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>{corporationInfo.name}</b>
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>Адрес:</b> {corporationInfo.address}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>ИНН/КПП:</b> {corporationInfo.inn} /{' '}
                                            {corporationInfo.kpp} Расчетный счет:{' '}
                                            {corporationInfo.corrAccount}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>Банк:</b> {corporationInfo.bankName}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText _foot">
                                            <b>Генеральный директор:</b>
                                            <br />
                                            {getUserInfo({
                                                type: 'name',
                                                user: corporationInfo.holder,
                                            })}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            ___________________
                                        </div>
                                    </div>
                                    <div className="pdfNonResidentPayAct__footerBlock _col">
                                        <div className="pdfNonResidentPayAct__footerText _head">
                                            <b>Исполнитель:</b>
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>
                                                {getUserInfo({ type: 'name', user: executorInfo })}
                                            </b>
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>Паспорт:</b> {passport || '–'}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>Адрес регистрации:</b>{' '}
                                            {executorInfo.passport.addressReg || '–'}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>ИНН:</b> {executorInfo.inn}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>Р/с:</b> {executorInfo.bankAccount}, в{' '}
                                            {executorInfo.bankName}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>К/с:</b> {executorInfo.corrAccount} БИК{' '}
                                            {executorInfo.bic}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            <b>Тел.:</b> {executorInfo.phone}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText _foot">
                                            {getUserInfo({ type: 'name', user: executorInfo })}
                                        </div>
                                        <div className="pdfNonResidentPayAct__footerText">
                                            ___________________
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="pdfPayAct">
                                <div className="pdfPayAct__title">
                                    Акт №{pay.number} от{' '}
                                    {getFormatedDate({
                                        date: new Date(pay.date),
                                        type: 'textYear',
                                    })}
                                </div>
                                <div className="pdfPayAct__infos">
                                    <div className="pdfPayAct__info _row JSpdfElem">
                                        <div className="pdfPayAct__infoSupport">Заказчик:</div>
                                        <div className="pdfPayAct__infoValue">
                                            {corporationInfo.name}, ИНН {corporationInfo.inn},{' '}
                                            {corporationInfo.address}, р/с{' '}
                                            {corporationInfo.bankAccount}, в банке{' '}
                                            {corporationInfo.bankName}, БИК {corporationInfo.bic},
                                            к/с {corporationInfo.corrAccount}
                                        </div>
                                    </div>
                                    <div className="pdfPayAct__info _row JSpdfElem">
                                        <div className="pdfPayAct__infoSupport">Исполнитель:</div>
                                        <div className="pdfPayAct__infoValue">
                                            {executorInfo.organizationName ||
                                                getUserInfo({ type: 'name', user: executorInfo })}
                                            , ИНН {executorInfo.inn},{' '}
                                            {executorInfo.passport.addressReg || '–'}, р/с{' '}
                                            {executorInfo.bankAccount}, в банке{' '}
                                            {executorInfo.bankName}, БИК {executorInfo.bic}, к/с{' '}
                                            {executorInfo.corrAccount}
                                        </div>
                                    </div>
                                    <div className="pdfPayAct__info _row JSpdfElem">
                                        <div className="pdfPayAct__infoSupport">Основание:</div>
                                        <div className="pdfPayAct__infoValue">
                                            {joinContract.name} от{' '}
                                            {getFormatedDate({ date: new Date(joinContract.date) })}
                                        </div>
                                    </div>
                                </div>
                                <div className="pdfPayAct__table">
                                    <div className="JSpdfElem pdfPayAct__tableRow _row _head">
                                        <div className="pdfPayAct__tableCol _number">№</div>
                                        <div className="pdfPayAct__tableCol _name">
                                            Наименование работ, услуг
                                        </div>
                                        <div className="pdfPayAct__tableCol _counter">Кол-во</div>
                                        <div className="pdfPayAct__tableCol _counterName">Ед.</div>
                                        <div className="pdfPayAct__tableCol _price">Цена</div>
                                        <div className="pdfPayAct__tableCol _total">Сумма</div>
                                    </div>
                                    <div className="JSpdfElem pdfPayAct__tableRow _row">
                                        <div className="pdfPayAct__tableCol _number">1</div>
                                        <div className="pdfPayAct__tableCol _name">{pay.name}</div>
                                        <div className="pdfPayAct__tableCol _counter">1</div>
                                        <div className="pdfPayAct__tableCol _counterName">шт</div>
                                        <div className="pdfPayAct__tableCol _price">
                                            {pay.amount.toFixed(2)}
                                        </div>
                                        <div className="pdfPayAct__tableCol _total">
                                            {pay.amount.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                                <div className="pdfPayAct__amount _col">
                                    <div className="pdfPayAct__amountRow _row">
                                        <div className="pdfPayAct__amountCol _support">Итого:</div>
                                        <div className="pdfPayAct__amountCol">
                                            {pay.amount.toFixed(2)}
                                        </div>
                                    </div>
                                    <div className="pdfPayAct__amountRow _row">
                                        <div className="pdfPayAct__amountCol _support">
                                            Без налога (НДС)
                                        </div>
                                        <div className="pdfPayAct__amountCol">—</div>
                                    </div>
                                </div>
                                <div className="pdfPayAct__total">
                                    <div className="pdfPayAct__totalBlock">
                                        <div className="pdfPayAct__totalText">
                                            Всего оказано услуг 1, на сумму {pay.amount.toFixed(2)}{' '}
                                            руб.
                                        </div>
                                        <div className="pdfPayAct__totalText _main">
                                            {rublesAmount[0]?.toUpperCase()}
                                            {rublesAmount.slice(1)}
                                        </div>
                                    </div>
                                    <div className="pdfPayAct__totalBlock">
                                        <div
                                            className="pdfPayAct__totalText"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(
                                                    'Вышеперечисленные услуги выполнены полностью и в срок. Заказчик претензий по объему, качеству и срокам оказания услуг не имеет.',
                                                ),
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="JSpdfElem pdfPayAct__sign _row">
                                    <div className="pdfPayAct__signBlock _col">
                                        <div className="pdfPayAct__signTitle">Заказчик</div>
                                        <div className="pdfPayAct__signDescription">
                                            {corporationInfo.name}
                                        </div>
                                        <div className="pdfPayAct__signFoot">
                                            <div className="pdfPayAct__signRole">
                                                <div className="pdfPayAct__signSign _col">
                                                    <div className="pdfPayAct__signSignValue"></div>
                                                    <div className="pdfPayAct__signSignSupport">
                                                        (Должность)
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pdfPayAct__signBox">
                                                <div className="pdfPayAct__signSign _col">
                                                    <div className="pdfPayAct__signSignValue"></div>
                                                    <div className="pdfPayAct__signSignSupport">
                                                        (ФИО)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pdfPayAct__signBlock _col">
                                        <div className="pdfPayAct__signTitle">Исполнитель</div>
                                        <div className="pdfPayAct__signDescription">
                                            {executorInfo.organizationName}
                                        </div>
                                        <div className="pdfPayAct__signFoot">
                                            <div className="pdfPayAct__signRole">
                                                <div className="pdfPayAct__signSign _col">
                                                    <div className="pdfPayAct__signSignValue"></div>
                                                    <div className="pdfPayAct__signSignSupport">
                                                        (Должность)
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pdfPayAct__signBox">
                                                <div className="pdfPayAct__signSign _col">
                                                    <div className="pdfPayAct__signSignValue"></div>
                                                    <div className="pdfPayAct__signSignSupport">
                                                        (ФИО)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {this.renderFoot()}
            </div>
        );
    }
}

export default PayAct;

PayAct.propTypes = {
    pdf: PropTypes.object,
    init: PropTypes.func,
    getPages: PropTypes.func,
    renderFoot: PropTypes.func,
    renderPagination: PropTypes.func,
};
