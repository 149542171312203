import React from 'react';

import getPage from '@functions/getPage.ts';

import PagesI from '../types.ts';

const renderPage: PagesI['renderPage'] = function ({ prop: name }) {
    const { currentPageName } = this.state;
    const {
        classNamePage = '',
        pages,
        context,
        windowHeight,
        fullHeight = false,
        getClassName = () => '',
    } = this.props;
    const allPages = this.getPagesOrder(true);
    const contextPage = pages[name];
    const indexCurrentPage = allPages.findIndex((page) => page.name === currentPageName);
    const indexPage = allPages.findIndex((page) => page.name === name);
    const page = getPage({ name });
    const notPopupPages = this.getPagesOrder().filter((item) => !item.isPopup);

    let state = '';

    if (indexPage > indexCurrentPage) {
        state = '_next';
    }

    if (indexPage < indexCurrentPage) {
        state = '_prev';
    }

    return (
        <div
            className={`${classNamePage} _${name} ${page?.className || ''} ${
                page?.isPopup ? '_popup' : ''
            } ${state} ${getClassName(name)} _NOSCROLL _PAGE ${notPopupPages.length < 1 ? '_end' : ''}`}
            style={fullHeight && name !== 'pdf' ? { height: `${windowHeight}px` } : {}}
        >
            {contextPage?.render.call(context)}
        </div>
    );
};

export default renderPage;
