import { ModelsT } from '../models';

const payProjects: ModelsT = {
    url: 'pay-project',
    logName: 'payProject',
    title: 'Выплаты',
    parentPage: 'pays-projects',
    tablePage: 'pays-projects-main',
    tableName: 'payProjects',
    modelPage: 'pays-projects-inner',
    deleteAlertPopup: 'deletePayProject',
    deleteDeniedAlertPopup: 'deletePayProjectDenied',
    tableLink: {
        pageName: 'pays-projects-inner',
        level: 3,
    },
    tableWrap: true,
    descriptions: {
        table: 'Проекты',
        model: {
            new: 'Новый проект',
            default: 'Проект',
        },
    },
    addButton: 'Добавить проект',
    cardName: 'payProjects',
};

export default payProjects;
