import I from '../types.ts';

const addArrayItem: I['addArrayItem'] = async function (id, { name, item }) {
    const { editFiles } = this.props;
    const resultKey = editFiles ? 'files' : `docs.${id}.files`;
    const resultId = (await this.props.addArrayItem({ name, key: resultKey, item })) as string;

    this.setState({ updatedItemKey: new Date().getTime() });

    return resultId;
};

export default addArrayItem;
