import React from 'react';

import TableI from '../types.ts';

import { TableColT } from '../static/tables.ts';

const renderHeadRow: TableI['renderHeadRow'] = function () {
    const { name, otherData, withHead } = this.props;

    if (withHead === false) {
        return <></>;
    }

    const table = this.tables[name];
    const orderCols = this.getOrderCols();
    const isAdmin = this.checkAdmin();

    const getSupport = (col: TableColT) =>
        typeof col.content === 'function' ? col.content(isAdmin, otherData) : col.content;

    return (
        <div className="v2table__row _head">
            <div className="v2table__rowInner _row">
                {orderCols.map((colName) => {
                    const col = table.cols[colName as keyof typeof table.cols] as TableColT;
                    let support;

                    if (col) {
                        support = getSupport(col);

                        if (isAdmin && col.isAdminMain && support && table.deletesCols.length) {
                            support +=
                                ', ' +
                                table.deletesCols
                                    .filter(
                                        (key) =>
                                            !(
                                                table.cols[
                                                    key as keyof typeof table.cols
                                                ] as TableColT
                                            ).isRemove,
                                    )
                                    .map((key) =>
                                        getSupport(
                                            table.cols[key as keyof typeof table.cols] as TableColT,
                                        ),
                                    )
                                    .join(', ')
                                    .toLowerCase();
                        }
                    }

                    if (isAdmin && colName === 'corporationInfo') {
                        support = 'Компания';
                    }

                    return (
                        <div
                            className={`v2table__col _col _${colName}`}
                            key={colName}
                            style={{
                                width: `${this.getColWidth(colName)}%`,
                            }}
                        >
                            {support}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default renderHeadRow;
