import getFormatedDate from '@functions/getFormatedDate';

import { FieldT } from '../cards';

const docEdit = {
    fieldsOrder: ['name', 'number', 'docType', 'date', 'actionDate'],
    fields: {
        name: {
            support: 'Название',
            type: 'input',
        },
        number: {
            support: 'Номер',
            type: 'input',
        },
        docType: {
            support: 'Тип',
            type: 'select',
            prop: 'docType',
            selectTextKey: 'docTypeName',
            selectList: 'docTypes',
            selectProps: { name: 'docTypeName' },
        },
        date: {
            support: 'Дата выдачи',
            type: 'date',
            reg: 'date',
            withTime: false,
            withPast: true,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
        actionDate: {
            support: 'Дата окончания',
            type: 'date',
            reg: 'date',
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
    },
} as const;

export default docEdit;
