import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListScroll from './ListScroll.jsx';

const emodzi = require('../infos/emodzi.json');

class EmodziList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counterScroll: this.stepCounter,
        };

        this.filterItem = this.filterItem.bind(this);
        this.getMoreItems = this.getMoreItems.bind(this);
        this.hideCallback = this.hideCallback.bind(this);
        this.handlerHide = this.handlerHide.bind(this);

        this.parent = React.createRef();
    }

    stepCounter = 7 * 10;

    getMoreItems(counter) {
        return new Promise((resolve) => {
            this.setState({ counterScroll: counter }, () => {
                resolve();
            });
        });
    }

    filterItem(log, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    hideCallback() {
        this.setState({
            counterScroll: this.stepCounter,
        });
    }

    addEmodzi(symb) {
        const { addEmodzi } = this.props;

        addEmodzi(symb);
    }

    handlerHide({ target }) {
        const { hide } = this.props;

        if (
            typeof hide === 'function' &&
            target !== this.parent.current &&
            !this.parent.current.contains(target)
        ) {
            hide();
        }
    }

    componentDidMount() {
        const { input, startPosition } = this.props;

        this.setState({ isInit: true });

        if (input) {
            input.focus();
            input.selectionEnd = startPosition;
        }

        setTimeout(() => {
            document.addEventListener('click', this.handlerHide);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handlerHide);
    }

    render() {
        const { isInit } = this.state;
        const { dirClassName = '_topCenter', className = '' } = this.props;
        const emodziList = emodzi.filter(this.filterItem);

        return (
            <div
                ref={this.parent}
                className={`emodziList _windowWithCursor ${dirClassName} ${className}`}
            >
                <div className="emodziList__inner">
                    <div className="emodziList__items _row">
                        <ListScroll
                            isInit={isInit}
                            getParent={() =>
                                this.parent.current?.querySelector('.emodziList__inner')
                            }
                            callback={this.getMoreItems}
                            startCounter={this.stepCounter}
                            stepCounter={this.stepCounter}
                            maxCounter={emodzi.length}
                            lengthCurrent={emodziList.length}
                            handlerLoaderList={this.handlerLoaderList}
                            isLimit={emodzi.length === emodziList.length}
                            callbackDuration={0}
                            hideCallback={this.hideCallback}
                        >
                            {emodziList.map((item, key) => (
                                <div className="emodziList__item _col" key={key}>
                                    <div
                                        className="emodziList__itemInner _col _click"
                                        onClick={() => {
                                            this.addEmodzi(item);
                                        }}
                                    >
                                        {item}
                                    </div>
                                </div>
                            ))}
                        </ListScroll>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(EmodziList);

EmodziList.propTypes = {
    addEmodzi: PropTypes.func,
    dirClassName: PropTypes.string,
    className: PropTypes.string,
    hide: PropTypes.func,
    input: PropTypes.node,
    startPosition: PropTypes.number,
};
