import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '@components/animate/Animate.tsx';
import ContractEditPopup from '@components/popups/contractEdit/ContractEdit.tsx';
import ContractSign from '@components/popups/contractSign/ContractSign.tsx';
import CorporationAmountDoc from '@components/popups/corporationAmountDoc/CorporationAmountDoc.tsx';
import CorporationCreate from '@components/popups/corporationCreate/CorporationCreate.tsx';
import CorporationEdit from '@components/popups/corporationEdit/CorporationEdit.tsx';
import CorporationRoleSection from '@components/popups/corporationRoleSection/CorporationRoleSection.tsx';
import Doc from '@components/popups/doc/Doc.tsx';
import ExecutorCreate from '@components/popups/executorCreate/ExecutorCreate.tsx';
import ExecutorLimits from '@components/popups/executorLimits/ExecutorLimits.tsx';
import ExecutorMvd from '@components/popups/executorMvd/ExecutorMvd.tsx';
import Galery from '@components/popups/galery/Galery.tsx';
import LogsPopup from '@components/popups/logs/Logs.tsx';
import PayEditPopup from '@components/popups/payEdit/PayEdit.tsx';
import PayGroupUpload from '@components/popups/payGroupUpload/PayGroupUpload.tsx';
import PaySign from '@components/popups/paySign/PaySign.tsx';
import Role from '@components/popups/role/Role.tsx';
import User from '@components/popups/user/User.tsx';
import Wallet from '@components/popups/wallet/Wallet.tsx';

import Cookie from '../../components/Cookie.jsx';
import CustomHead from '../../components/CustomHead.jsx';
import Pages from '../../components/Pages.jsx';
import ServerNotAvailable from '../../components/ServerNotAvailable.jsx';
import WindowActions from '../../components/WindowActions.jsx';
import Windows from '../../components/Windows.jsx';
import SupportChatBtn from '../../components/crm/SupportChatBtn.jsx';
import NotificationsBarWrapper from '../../components/crm/notificationsBar/Wrapper.jsx';
import ChatExecutorDocPopupWrapper from '../../components/crm/popups/ChatExecutorDocPopupWrapper.jsx';
import ChatTemplatePopup from '../../components/crm/popups/ChatTemplatePopup.jsx';
import ExportPopupWrapper from '../../components/crm/popups/ExportPopupWrapper.jsx';
import ListPopupWrapper from '../../components/crm/popups/ListPopupWrapper.jsx';
import MailingPopup from '../../components/crm/popups/MailingPopup.jsx';
import NewVersionPopupWrapper from '../../components/crm/popups/NewVersionPopupWrapper.jsx';
import PopupExecutorInvite from '../../components/crm/popups/PopupExecutorInvite.jsx';
import ServerNotAvailablePopupWrapper from '../../components/crm/popups/ServerNotAvailablePopupWrapper.jsx';
import TagsPopupWrapper from '../../components/crm/popups/TagsPopupWrapper.jsx';
import UploadJoinsPopupWrapper from '../../components/crm/popups/UploadJoinsPopupWrapper.jsx';
import VerificationPopupWrapper from '../../components/crm/popups/VerificationPopupWrapper.jsx';
import WalletsPopupWrapper from '../../components/crm/popups/WalletsPopupWrapper.jsx';
import SideBarWrapper from '../../components/crm/sideBar/Wrapper.jsx';
import ChatTemplatesPopup from '../../components/popups/chatTemplates/ChatTemplates.tsx';

import copyInBuffer from '../../functions/copyInBuffer';
import checkHasCorporation from '../../functions/crm/checkHasCorporation';
import getCurrentPage from '../../functions/getCurrentPage';
import getPage from '../../functions/getPage';
import getUser from '../../functions/getUser';
import { getCookie } from '../../functions/handlerCookies';
import resize from '../../functions/handlerSize';
import handlerWindow from '../../functions/handlerWindow';
import logout from '../../functions/logout';

import { dispatcher } from '../../redux/redux';
import '../../scss/crm/main.scss';
import Auth from './Auth.jsx';
import Chat from './Chat.jsx';
import Content from './Content.jsx';
import Index from './Index.jsx';
import Joins from './Joins.jsx';
import Manual from './Manual.jsx';
import Pays from './Pays.jsx';
import Pdf from './Pdf.jsx';
import Pep from './Pep.jsx';
import Public from './Public.jsx';
import ChatTemplates from './chatTemplates/ChatTemplates.tsx';
import InfoPublic from './content/InfoPublic.jsx';
import Corporations from './corporations/Corporations.tsx';
import Mailings from './mailings/Mailings.tsx';
import Profile from './profile/Profile.tsx';
import Settings from './settings/Settings.tsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerSocket = this.handlerSocket.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
        this.renderWindowContextMenu = this.renderWindowContextMenu.bind(this);
    }

    pages = {
        'corporation-index': {
            render() {
                return (
                    <div className="body__pageInner">
                        <Index />
                    </div>
                );
            },
        },
        corporations: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Corporations />
                    </div>
                );
            },
        },
        auth: {
            render() {
                return <Auth />;
            },
        },
        chat: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Chat />
                    </div>
                );
            },
        },
        manual: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Manual />
                    </div>
                );
            },
        },
        'content-infoPublic': {
            render() {
                return (
                    <div className="body__pageInner">
                        <InfoPublic />
                    </div>
                );
            },
        },
        content: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Content />
                    </div>
                );
            },
        },
        profile: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Profile />
                    </div>
                );
            },
        },
        settings: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Settings />
                    </div>
                );
            },
        },
        joins: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Joins />
                    </div>
                );
            },
        },
        pays: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Pays />
                    </div>
                );
            },
        },
        'chat-templates': {
            render() {
                return (
                    <div className="body__pageInner">
                        <ChatTemplates />
                    </div>
                );
            },
        },
        'chat-template': {
            render() {
                return (
                    <div className="body__pageInner">
                        <ChatTemplatePopup />
                    </div>
                );
            },
        },
        mailings: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Mailings />
                    </div>
                );
            },
        },
        mailing: {
            render() {
                return (
                    <div className="body__pageInner">
                        <MailingPopup />
                    </div>
                );
            },
        },
        pdf: {
            render() {
                return <Pdf />;
            },
        },
        public: {
            render() {
                return <Public />;
            },
        },
        pep: {
            render() {
                return <Pep />;
            },
        },
    };

    handlerSocket({ detail }) {
        const { user } = this.props;
        const { name, data } = detail;

        if (name === 'logout') {
            dispatcher({ type: 'isProccessLogout', data: true }).then(() => {
                logout(true);
            });
        } else if (name === 'system') {
            const { version } = data;

            if (version !== +process.env.REACT_APP_CRM_VERSION) {
                dispatcher({ type: 'user', data: { ...user, systemVersion: version } }).then(() => {
                    dispatcher({ type: 'newVersionShow', data: true });
                });
            }
        } else {
            const { type, fields, idOfCorporation } = data;

            if (name === 'user') {
                const { idOfUser } = data;

                if (idOfUser === user._id) {
                    dispatcher({ type: 'user', data: { ...user, ...fields } }).then(() => {
                        document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
                    });
                }
            }

            if (type === 'changeInfo' && name === 'corporation') {
                const corporationIndex = user.corporationsInfo.findIndex(
                    (corporation) => corporation._id === idOfCorporation,
                );

                if (corporationIndex !== -1) {
                    const corporationsInfo = JSON.parse(JSON.stringify(user.corporationsInfo));
                    const corporation = corporationsInfo[corporationIndex];

                    ['logo', 'balance', 'fullName', 'shortName', 'opfName'].forEach((key) => {
                        if (fields[key]) {
                            corporation[key] = fields[key];
                        }
                    });

                    user.corporationsInfo = corporationsInfo;

                    dispatcher({ type: 'user', data: { ...user } }).then(() => {
                        document.dispatchEvent(
                            new CustomEvent('changeUser', {
                                detail: { fields: user.corporations },
                            }),
                        );
                    });
                }
            }
        }
    }

    renderWindowContextMenu({ link }) {
        const handler = ({ key }) =>
            new Promise((resolve) => {
                const resultLink = `${process.env.REACT_APP_CRM_DOMEN}/${link}`;

                if (key === 'targetBlank') {
                    window.open(resultLink, '_blank');

                    resolve();
                } else if (key === 'copyLink') {
                    copyInBuffer(resultLink);

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={['contextTargetBlank', 'contextCopyLink']}
                callback={handler}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'contextMenu',
                    });
                }}
            />
        );
    }

    visibilityDocChange({ detail: { isActive } }) {
        const { user } = this.props;

        if (isActive && user) {
            getUser(getCookie(process.env.REACT_APP_HASH), true, true).then(
                () => null,
                () => null,
            );

            resize();

            setTimeout(() => {
                resize();
            }, 10);
        }
    }

    componentDidMount() {
        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);

        document.dispatchEvent(new CustomEvent('click'));
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('visibilityDocChange', this.visibilityDocChange);
    }

    render() {
        const {
            user,
            isHideSideBar,
            isHideNotificationsBar,
            popupExecutorInvite,
            pagesStore,
            isProccessLogout,
            logsPopup,
            chatTemplatesPopup,
            paySignPopup,
            contractSignPopup,
            payPopup,
            joinContractPopup,
            payGroupUpload,
            userPopup,
            corporationEditPopup,
            corporationAmountDocPopup,
            galeryPopup,
            corporationCreatePopup,
            roleSectionPopup,
            rolePopup,
            galeryPin,
            docPopup,
            walletPopup,
            executorCreatePopup,
            executorMvdPopup,
            executorLimitsPopup,
        } = this.props;

        const currentPageName = getCurrentPage({
            pages: pagesStore,
            filter: (page) => !page.level,
        });
        const currentPage = getPage({ name: currentPageName });

        return (
            <>
                <CustomHead
                    title="CRM LIVECARGO"
                    description="Служба доставки для бизнеса &#9989; Машины в любом количестве &#9989; Экспресс-доставка вашего груза &#128222; +7 495 665-82-72"
                >
                    <link rel="icon" href={require('../../img/favicon.ico')} />
                    <meta name="yandex-verification" content="0dcdf70d2b548de3" />
                </CustomHead>
                <Pages
                    classNamePage={`JSTransVertical body__page _col _crm ${
                        user && !checkHasCorporation({ user }) ? '_waitCorporation' : ''
                    } ${isHideSideBar ? '_hideSideBar' : ''} ${
                        isHideNotificationsBar ? '_hideNotificationsBar' : ''
                    }`}
                    filter={(page) =>
                        page.level === 1 ||
                        ['profile', 'auth', 'content-infoPublic', 'pep', 'pdf'].includes(
                            page.name,
                        ) ||
                        page.group === 'public'
                    }
                    pages={this.pages}
                    context={this}
                    fullHeight={true}
                />

                {currentPageName !== 'pdf' && (
                    <>
                        {(user || isProccessLogout) && (
                            <>
                                <SideBarWrapper isShow={currentPage?.group === 'cabinet' && user} />
                                <NotificationsBarWrapper
                                    isShow={currentPage?.group === 'cabinet' && user}
                                />
                            </>
                        )}

                        <Animate className="body__waitBack" isShow={!checkHasCorporation({ user })}>
                            <img
                                src={require('../../img/crm/back-page.png')}
                                alt=""
                                className="body__waitBackImage"
                            />
                        </Animate>
                        <Animate
                            className={`body__popup _executorInvite`}
                            isShow={popupExecutorInvite.isShow}
                        >
                            <PopupExecutorInvite
                                user={user}
                                executor={popupExecutorInvite.executor}
                                callback={popupExecutorInvite.callback}
                            />
                        </Animate>
                        <ExportPopupWrapper />
                        <Cookie />
                        <ChatExecutorDocPopupWrapper />
                        <NewVersionPopupWrapper />
                        <ServerNotAvailablePopupWrapper />

                        <WalletsPopupWrapper />
                        <UploadJoinsPopupWrapper />

                        <ServerNotAvailable
                            isHide={currentPageName === 'public' || currentPageName === 'pdf'}
                        />

                        <ListPopupWrapper />
                        <TagsPopupWrapper />
                        <SupportChatBtn />

                        <VerificationPopupWrapper />

                        <Windows name="contextMenu" renderContent={this.renderWindowContextMenu} />

                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!logsPopup?.isShow}
                        >
                            <LogsPopup />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!chatTemplatesPopup?.isShow}
                        >
                            <ChatTemplatesPopup />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!paySignPopup?.isShow}
                        >
                            <PaySign />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!contractSignPopup?.isShow}
                        >
                            <ContractSign />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!payPopup?.isShow}
                        >
                            <PayEditPopup />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!joinContractPopup?.isShow}
                        >
                            <ContractEditPopup />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!payGroupUpload?.isShow}
                        >
                            <PayGroupUpload />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!userPopup?.isShow}
                        >
                            <User />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!corporationEditPopup?.isShow}
                        >
                            <CorporationEdit />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!corporationAmountDocPopup?.isShow}
                        >
                            <CorporationAmountDoc />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!corporationCreatePopup?.isShow}
                        >
                            <CorporationCreate />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!roleSectionPopup?.isShow}
                        >
                            <CorporationRoleSection />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!rolePopup?.isShow}
                        >
                            <Role />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!docPopup?.isShow}
                        >
                            <Doc />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!walletPopup?.isShow}
                        >
                            <Wallet />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!executorCreatePopup?.isShow}
                        >
                            <ExecutorCreate />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!executorMvdPopup?.isShow}
                        >
                            <ExecutorMvd />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!executorLimitsPopup?.isShow}
                        >
                            <ExecutorLimits />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK"
                            isShow={!!galeryPopup?.isShow && !galeryPin}
                        >
                            <Galery />
                        </Animate>
                        <Animate
                            className="body__v2popup _POPUPWRAPPER _POPUPBACK _pin"
                            isShow={!!galeryPopup?.isShow && galeryPin}
                        >
                            <Galery pin={true} />
                        </Animate>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        dateOfLogout: state.dateOfLogout,
        isHideSideBar: state.isHideSideBar,
        isHideNotificationsBar: state.isHideNotificationsBar,
        popupExecutorInvite: state.popupExecutorInvite,
        pagesStore: state.pages,
        isHideAudioNotification: state.isHideAudioNotification,
        isProccessLogout: state.isProccessLogout,
        logsPopup: state.logsPopup,
        chatTemplatesPopup: state.chatTemplatesPopup,
        paySignPopup: state.paySignPopup,
        contractSignPopup: state.contractSignPopup,
        payPopup: state.payPopup,
        joinContractPopup: state.joinContractPopup,
        payGroupUpload: state.payGroupUpload,
        userPopup: state.userPopup,
        corporationEditPopup: state.corporationEditPopup,
        corporationAmountDocPopup: state.corporationAmountDocPopup,
        galeryPopup: state.galeryPopup,
        corporationCreatePopup: state.corporationCreatePopup,
        roleSectionPopup: state.roleSectionPopup,
        rolePopup: state.rolePopup,
        galeryPin: state.galeryPin,
        docPopup: state.docPopup,
        walletPopup: state.walletPopup,
        executorCreatePopup: state.executorCreatePopup,
        executorMvdPopup: state.executorMvdPopup,
        executorLimitsPopup: state.executorLimitsPopup,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    user: PropTypes.object,
    dateOfLogout: PropTypes.string,
    isHideSideBar: PropTypes.bool,
    isHideNotificationsBar: PropTypes.bool,
    popupExecutorInvite: PropTypes.object,
    pagesStore: PropTypes.object,
    isHideAudioNotification: PropTypes.bool,
    isProccessLogout: PropTypes.bool,
    popupGalery: PropTypes.object,
    logsPopup: PropTypes.object,
};
