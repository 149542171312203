import React from 'react';

import EditBlockI from '@components/editBlock/types';
import SwitchComp from '@components/switch/Switch';
import { FilterBlockT } from '@global/types';

type ParamsT = {
    blockName: string;
    change: EditBlockI['change'];
    index: number;
} & Pick<FilterBlockT, 'value'>;

export default function Switch(props: ParamsT): React.ReactNode {
    const { value, change, index, blockName } = props;

    return (
        <div className="v2filter__blockSwitch">
            <SwitchComp
                value={!!value}
                name={blockName}
                onChange={() => {
                    change({ [`${index}.value`]: !value });
                }}
            />
        </div>
    );
}
