import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const sign: I['sign'] = async function (action) {
    const { code } = this.state;
    const { contractSignPopup } = this.props;
    const { items = [], successCallback, filterQuery = [], type } = contractSignPopup;
    const query = filterQuery.map((item) => `${item.key}=${item.value}`).join('&');
    let response;

    await handlerLoading.call(this, action === 'send' ? 'code' : 'items');

    try {
        response = await axios.patch<
            {},
            { data: ReqResponseT<{ hash?: string; message?: string }> }
        >(
            `${process.env.REACT_APP_HARD_API}/join-contract?${query}`,
            {
                itemsIds: items[0] === 'all' ? 'allCompleted' : items,
                isForce: true,
                type,
                ...(action === 'send' ? { code } : {}),
            },
            { headers: getHeaders() },
        );
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    await handlerLoading.call(this, undefined);

    const { success, data } = response.data;

    if (success) {
        if (action === 'send') {
            this.close();

            if (typeof successCallback === 'function') {
                successCallback();
            }
        } else {
            const { hash } = data;

            this.setState({ hash, currentBlock: 'code' });
        }

        if (action === 'again') {
            setNotification({ notification: 'success-send-code' });
        }

        return;
    }

    const { message } = data;

    if (message === 'Phone is incorrect') {
        setNotification({ notification: 'error-corporation-phone' });
    } else if (message === 'Corporation not verify') {
        handlerPopup<'crm'>('verificationPopup', {
            isShow: true,
            callback: this.close,
        });
    }
};

export default sign;
