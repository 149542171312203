import React from 'react';

import addArrayItem from './methods/addArrayItem.ts';
import addHandler from './methods/addHandler.ts';
import change from './methods/change.ts';
import deleteHandler from './methods/deleteHandler.ts';
import setLoadings from './methods/setLoadings.ts';
import uploadHandler from './methods/uploadHandler.ts';

import WidgetDocI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderFile from './renders/renderFile.tsx';
import renderFoot from './renders/renderFoot.tsx';
import renderHead from './renders/renderHead.tsx';

class WidgetDoc
    extends React.Component<WidgetDocI['props'], WidgetDocI['state']>
    implements WidgetDocI
{
    parent: WidgetDocI['parent'];

    constructor(props: WidgetDocI['props']) {
        super(props);
        this.state = {
            loadings: {},
        };

        this.parent = React.createRef();
    }

    setLoadings = setLoadings;
    uploadHandler = uploadHandler;
    addHandler = addHandler;
    addArrayItem = addArrayItem;
    deleteHandler = deleteHandler;
    change = change;

    renderHead = renderHead;
    renderContent = renderContent;
    renderFoot = renderFoot;
    renderFile = renderFile;

    render() {
        const { editFiles } = this.props;

        return (
            <div ref={this.parent} className={`v2widgetDoc _COL ${editFiles ? '_editFiles' : ''}`}>
                {!editFiles && this.renderHead()}
                {this.renderContent()}
                {!editFiles && this.renderFoot()}
            </div>
        );
    }
}

export default WidgetDoc;
