import React from 'react';

import Media from '@components/media/Media.tsx';
import MobPopup from '@components/mobPopup/MobPopup.tsx';

import FilterI from '../types.ts';

const renderMobile: FilterI['renderMobile'] = function () {
    const { isSizeInit } = this.state;
    const { windowHeight } = this.props;

    return (
        <Media current="mobile">
            <MobPopup
                windowHeight={windowHeight}
                isInit={!!isSizeInit}
                hideCallback={this.close.bind(this)}
                name="filter"
            >
                {this.renderContent()}
            </MobPopup>
        </Media>
    );
};

export default renderMobile;
