import I from '../types.ts';

const change: I['change'] = async function (id, fields, modelName, prop) {
    const { editFiles } = this.props;

    let resultKey: string | undefined = `docs.${id}`;

    if (prop) {
        resultKey += `.${prop}`;
    }

    if (editFiles) {
        resultKey = prop;
    }

    await this.props.change(fields, undefined, resultKey);

    this.setState({ updatedItemKey: new Date().getTime() });
};

export default change;
