import MobPopupI from '../types.ts';

const setMaxHeight: MobPopupI['setMaxHeight'] = function () {
    const { windowHeight } = this.props;
    const inner = this.getInner();

    if (windowHeight && inner) {
        const innerScroll = inner.querySelector('.popup__mobBox') as HTMLElement;

        if (innerScroll) {
            innerScroll.style.maxHeight = `${this.getMaxHeight()}px`;
        }
    }
};

export default setMaxHeight;
