import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import ModelsPage from '@components/crm/modelsPage/ModelsPage.tsx';

import Pages from '../../components/Pages.jsx';

import checkRights from '../../functions/crm/checkRights';
import getRealParams from '../../functions/getRealParams.ts';

import JoinsContracts from './joins/contracts/Contracts.tsx';
import JoinsInvites from './joins/invites/Invites.tsx';
import JoinsMvd from './joins/mvd/Mvd.tsx';
import JoinsScripts from './joins/scripts/Scripts.tsx';
import JoinsTemplates from './joins/templates/Templates.tsx';

class Manual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.setHeightPage = this.setHeightPage.bind(this);
        this.checkRights = this.checkRights.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        'joins-invites': {
            render() {
                return <JoinsInvites />;
            },
        },
        'joins-scripts': {
            render() {
                return <JoinsScripts />;
            },
        },
        'joins-templates': {
            render() {
                return (
                    <JoinsTemplates
                        setHeightPage={this.setHeightPage}
                        checkRights={this.checkRights}
                    />
                );
            },
        },
        'joins-contracts': {
            render() {
                return <JoinsContracts />;
            },
        },
        'joins-mvd': {
            render() {
                return (
                    <JoinsMvd setHeightPage={this.setHeightPage} checkRights={this.checkRights} />
                );
            },
        },
        'joins-docs': {
            render() {
                return <ModelsPage type="joinDocs" />;
            },
        },
    };

    setHeightPage() {
        if (this.parent.current) {
            const page = this.parent.current;

            const { height: heightPage } = getRealParams({
                parent: page,
                elem: '.manual__page._current',
                width: page.offsetWidth,
            });

            if (heightPage !== this.state.heightPage) {
                this.setState({ heightPage });
            }
        }
    }

    checkRights() {
        const { user } = this.props;

        return checkRights({ user, key: 'joins', isEdit: true });
    }

    componentDidMount() {
        this.setHeightPage();
    }

    componentDidUpdate() {
        this.setHeightPage();
    }

    render() {
        const { heightPage = 0 } = this.state;
        const { main } = this.props;

        return (
            <div ref={this.parent} className="manual">
                <div className="manual__inner" style={{ height: `${heightPage}px` }}>
                    {main ? (
                        <>
                            <div className="manual__page _show _current">
                                {this.pages['joins-invites'].render.call(this)}
                            </div>
                        </>
                    ) : (
                        <>
                            <Pages
                                classNamePage="manual__page"
                                filter={(page) => page.parentName === 'joins' && page.level === 2}
                                pages={this.pages}
                                context={this}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Manual);

Manual.propTypes = {
    user: PropTypes.object,
    main: PropTypes.bool,
};
