import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import download from './methods/download.ts';

import Interface from './types.ts';

import renderContent from './renders/renderContent.tsx';

class CorporationAmountDoc
    extends EditBlock<Interface['props'], Interface['state']>
    implements Interface
{
    constructor(props: Interface['props']) {
        super(props);
        this.state = {};
    }

    close = close;
    download = download;

    renderContent = renderContent;

    componentDidMount() {
        this.init({ fields: {} });
    }

    render() {
        return (
            <Popup name="corporationAmountDocPopup" close={this.close.bind(this)}>
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        corporationAmountDocPopup: state.corporationAmountDocPopup,
    };
}

export default connect(mapStateToProps)(CorporationAmountDoc);
