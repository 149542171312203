import InvitesI from '../types.ts';

const handlerSocket: InvitesI['handlerSocket'] = async function ({ detail }) {
    const { name, data } = detail;

    if (name === 'mailing') {
        const { id } = data;

        if (id) {
            await this.tableUpdateItem!({ id, fields: data });
        }
    }
};

export default handlerSocket;
