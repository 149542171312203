import ListScrollI from '../types.ts';

const checkUpdate: ListScrollI['checkUpdate'] = function () {
    const { lengthCurrent, handlerLoaderList, isDisabledScroll, keyUpdate, isLimit } = this.props;

    if (
        !isLimit &&
        (!(isDisabledScroll !== undefined && isDisabledScroll !== null) ||
            isDisabledScroll !== true)
    ) {
        if (lengthCurrent !== this.lengthCurrent) {
            this.lengthCurrent = lengthCurrent;
            this.isProccess = false;

            if (handlerLoaderList) {
                handlerLoaderList(false);
            }

            if (this.timerIdCheckState) {
                clearTimeout(this.timerIdCheckState);
            }

            setTimeout(() => {
                this.checkStateOfLessItems();
            }, 10);
        } else if (keyUpdate !== this.keyUpdate) {
            this.keyUpdate = keyUpdate;

            if (this.timerIdCheckState) {
                clearTimeout(this.timerIdCheckState);
            }

            setTimeout(() => {
                this.checkStateOfLessItems();
            }, 10);
        } else if (isDisabledScroll !== this.isDisabledScroll) {
            this.isDisabledScroll = isDisabledScroll;

            if (handlerLoaderList) {
                handlerLoaderList(false);
            }

            // setTimeout(() => {
            //     this.checkStateOfLessItems();
            // }, 10);
        }
    }

    if (isLimit !== this.isLimit) {
        this.isLimit = isLimit;
        this.isProccess = false;

        if (handlerLoaderList) {
            handlerLoaderList(false);
        }
    }

    if (keyUpdate !== this.keyUpdate) {
        this.keyUpdate = keyUpdate;

        if (handlerLoaderList) {
            handlerLoaderList(false);
        }
    }
};

export default checkUpdate;
