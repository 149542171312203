import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';

import close from './methods/close.ts';
import uploadFile from './methods/uploadFile.ts';

import PayGroupUploadI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class PayGroupUpload
    extends EditBlock<PayGroupUploadI['props'], PayGroupUploadI['state']>
    implements PayGroupUploadI
{
    constructor(props: PayGroupUploadI['props']) {
        super(props);
        this.state = {};
    }

    close = close;
    uploadFile = uploadFile;

    renderContent = renderContent;

    componentDidMount() {
        this.init({ fields: {} });
    }

    render() {
        return (
            <Popup
                name="payGroupUpload"
                close={this.close.bind(this)}
                checkClose={() => !document.querySelector('.v2cursorLists__item')}
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(PayGroupUpload);
