import ListAbsoluteI from '../types.ts';

const checkUpdate: ListAbsoluteI['checkUpdate'] = function () {
    const { parent, disabled, windowIsReady, hasRenderKey, keyRender } = this.props;
    const resultKeyRender = this.getRenderKey();

    if (parent && !disabled && (!hasRenderKey || keyRender !== undefined)) {
        if (!this.isInit) {
            this.isInit = true;
            this.keyRender = resultKeyRender;

            this.getParams({});
        } else if (resultKeyRender !== this.keyRender && windowIsReady) {
            this.keyRender = resultKeyRender;

            this.getParams({});
        }
    }
};

export default checkUpdate;
