import I from '../types.ts';

const eventMoveHandler: I['eventMoveHandler'] = function (e) {
    if (this.isMove) {
        this.moveX = e.pageX - this.startX + this.endX;
        this.moveY = e.pageY - this.startY + this.endY;

        const borders = this.checkBorders(this.moveX, this.moveY);

        this.moveX = borders.x;
        this.moveY = borders.y;

        this.movePinArea();
    }
};

export default eventMoveHandler;
