import setNotification from './setNotification';

export default function copyInBuffer(content, notification = 'success-copy-data') {
    return new Promise((resolve) => {
        navigator.clipboard.writeText(content).then(() => {
            if (process.env.REACT_APP_SYSTEM !== 'app') {
                setNotification({ notification });
            }

            resolve();
        });
    });
}
