import React from 'react';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import getPermissions from '@functions/crm/getPermissions.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import { CorporationT } from '@global/models/Corporation.ts';

import I from '../types.ts';

const renderTariff: I['renderTariff'] = function () {
    const { user } = this.state;
    const model = this.state.model!;
    const { setModel } = this.props;
    const tariff = model.tariff.pay;
    const isAdmin = user?.idOfCurrentCorporation === 'admin';

    const infoProps = setCursorSupport(
        {
            _id: `tariff-info`,
            content:
                'Тариф устанавливает комиссию<br/> в системе за каждую выплату, <br/>сделанную из вашей компании',
            dir: 'top',
        },
        { targetClassName: '.v2widgetCardInfo__info', className: '' },
    );

    return (
        <>
            <div className="v2widgetCard">
                <div className="v2widgetCard__content _full">
                    <div className="v2widgetCardInfo _COL">
                        <i className="v2widgetCardInfo__info _CLICK" {...infoProps}>
                            <Icon name="info" />
                        </i>

                        <div className="v2widgetCardInfo__support">Тариф</div>
                        <div className="v2widgetCardInfo__title">{tariff.percent}%</div>
                        <div className="v2widgetCardInfo__description">
                            {model.tariff.pay.min ? (
                                <>Минимум — {model.tariff.pay.min}₽</>
                            ) : (
                                <>Минимум не установлен</>
                            )}
                        </div>
                        <div className="v2widgetCardInfo__content">
                            <div className="v2widgetCardInfo__option _row">
                                <div className="v2widgetCardInfo__optionText _support">
                                    Процент комиссии
                                </div>
                                <div className="v2widgetCardInfo__optionText">
                                    {model.tariff.pay.percent}%
                                </div>
                            </div>
                            <div className="v2widgetCardInfo__option _row">
                                <div className="v2widgetCardInfo__optionText _support">
                                    Мин. комиссия
                                </div>
                                <div className="v2widgetCardInfo__optionText">
                                    {getFormatPrice(model.tariff.pay.min)} ₽
                                </div>
                            </div>
                        </div>
                        {isAdmin &&
                            !model.isAdmin &&
                            getPermissions(user, {
                                key: 'corporations',
                                items: [{ key: 'main', rules: ['update'] }],
                            }) && (
                                <div className="v2widgetCardInfo__foot _col">
                                    <div className="v2widgetCardInfo__button">
                                        <Button
                                            className="_edit _blue"
                                            onClick={() => {
                                                handlerPopup('corporationEditPopup', {
                                                    isShow: true,
                                                    type: 'tariff',
                                                    corporation: model,
                                                    callback: (data: CorporationT) => {
                                                        setModel(data);
                                                        this.init({ fields: data });
                                                    },
                                                });
                                            }}
                                        >
                                            <i className="_editPrev">
                                                <Icon name="edit-2" />
                                            </i>
                                            Редактировать тариф
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderTariff;
