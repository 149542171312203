import ManualTableI from '../types.ts';

const checkInit: ManualTableI['checkInit'] = function () {
    const { isInit = true, staticItems } = this.props;

    if (isInit && !this.isInit) {
        this.isInit = true;

        if (!staticItems) {
            this.startGetItems();
        } else {
            this.setState({ isReady: true }, this.checkScroll.bind(this));
        }
    }
};

export default checkInit;
