import { Store, legacy_createStore as reduxCreateStore } from 'redux';

import UserT from '@global/models/User.ts';
import { StoreT } from '@global/types';

import { DefaultPropsT, getDefaultState, getReducer } from './actions.ts';
import pages from './pages.ts';

const clearPath = (pathRes: string) => {
    let path = pathRes;
    path = path.length > 1 && path[path.length - 1] === '/' ? path.slice(0, -1) : path;
    const levels = path.split('/');

    return {
        path,
        levels,
    };
};

function createCrmStore(props: DefaultPropsT = {}): Store<StoreT<'crm'>> {
    const resultPages = {} as StoreT['pages'];

    if (props.currentPages) {
        pages.forEach((page) => {
            resultPages[page.name] = {
                isShow: props.currentPages!.includes(page.name),
            };
        });
    }

    let user: UserT | undefined;

    try {
        user = JSON.parse(localStorage.getItem('user') as string);
    } catch (error) {}

    if (user) {
        const { levels } = clearPath(window.location.pathname.slice(1));
        const currentCorporation = user.corporations.find((item) => item.id === levels[0]);

        if (currentCorporation) {
            user.idOfCurrentCorporation = currentCorporation.id;
        }
    }

    const reducer = getReducer<'crm'>({
        ...getDefaultState(props),
        user,
        popupGalery: { isShow: false },
        supportCursor: {
            show: false,
            showAnimate: false,
            name: null,
        },
        windows: {},
        verificationPopup: { isShow: false },
        joinContractPopup: { isShow: false },
        signContractPopup: { isShow: false },
        chatTemplatesPopup: { isShow: false },
        payPopup: { isShow: false },
        chatExecutorDocPopup: { isShow: false },
        logsPopup: { isShow: false },
        serverNotAvailablePopup: { isShow: false },
        rolePopup: { isShow: false },
        userPopup: { isShow: false },
        corporationTariffPopup: { isShow: false },
        corporationLimitsPopup: { isShow: false },
        executorLimitsPopup: { isShow: false },
        popupExecutorInvite: { isShow: false },
        listPopup: { isShow: false },
        tagsPopup: { isShow: false },
        mailingPopup: { isShow: false },
        walletsPopup: { isShow: false },
        uploadJoinsPopup: { isShow: false },
        isHideAudioNotification: !!localStorage.getItem('isHideAudioNotification'),
        isAcceptCookie: !!localStorage.getItem('isAcceptCookie'),
        exportPopup: { isShow: false },
        isHideSideBar: false,
        isHideNotificationsBar: false,
        corporationAmountDocPopup: { isShow: false },
        paySignPopup: { isShow: false },
        contractSignPopup: { isShow: false },
        payGroupUpload: { isShow: false },
        corporationEditPopup: { isShow: false },
        galeryPopup: { isShow: false },
        corporationCreatePopup: { isShow: false },
        roleSectionPopup: { isShow: false },
        galeryPin: !!localStorage.getItem('galeryPin'),
        galeryPinX: localStorage.getItem('galeryPinX')
            ? +(localStorage.getItem('galeryPinX') as string)
            : 20,
        galeryPinY: localStorage.getItem('galeryPinY')
            ? +(localStorage.getItem('galeryPinY') as string)
            : 20,
        docPopup: { isShow: false },
        walletPopup: { isShow: false },
        executorCreatePopup: { isShow: false },
        executorMvdPopup: { isShow: false },
    });

    return reduxCreateStore(reducer);
}

const store = createCrmStore();

export default store;
