import React from 'react';

import ManualTableI, { TableBlockItemT } from '../types.ts';

import CorporationInfo from '../../manual/CorporationInfo.jsx';
import renderCols from './cols/renderCols.tsx';

const renderCol: ManualTableI['renderCol'] = function (props) {
    const { isBlock } = this.props;
    const { item, name: colName, corporation, corporationIndex, fake } = props;

    if (fake) {
        const { index } = props;
        const fakeItem = item as ManualTableI['state']['fakeItems'][number];

        return (
            <div
                className={`v2table__col _full _COL _${colName}`}
                key={colName}
                style={{
                    width: `${this.getColWidth(colName)}%`,
                }}
            >
                <div className="v2table__colInner">
                    {fakeItem.cols[index]?.map((fakeCol, fakeColIndex) => (
                        <div
                            className="v2table__fakeItem _LOADERWAIT"
                            key={fakeColIndex}
                            style={{
                                width: `${fakeCol.width}%`,
                                height: `${fakeCol.height}px`,
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }

    let { content, className = '' } = renderCols.call(this, props);
    const blockItem = item as TableBlockItemT;

    if ((colName as any) === 'corporationInfo') {
        className = '_visible';

        if (isBlock) {
            content = (
                <>
                    {corporation?.name ? (
                        <div
                            className={`v2table__corporation ${
                                corporationIndex === 0 && blockItem.corporations.length > 1
                                    ? '_list'
                                    : ''
                            }`}
                        >
                            <CorporationInfo {...corporation} />
                        </div>
                    ) : (
                        <>–</>
                    )}
                </>
            );
        } else {
            content = blockItem.corporationInfo?.length ? (
                <>
                    {blockItem.corporationInfo.map((innerItem, key) => (
                        <div className="v2table__corporation" key={key}>
                            <CorporationInfo {...innerItem} />
                        </div>
                    ))}
                </>
            ) : (
                '–'
            );
        }
    }

    return (
        <div
            className={`v2table__col _COL _${colName} ${className}`}
            key={colName}
            style={{
                width: `${this.getColWidth(colName)}%`,
            }}
        >
            <div className="v2table__colInner">{content}</div>
        </div>
    );
};

export default renderCol;
