import CalendarI from '../types.ts';

const getBlocks: CalendarI['getBlocks'] = function () {
    const { activeDate } = this.state;

    if (activeDate) {
        return [
            {
                id: +this.getId(activeDate),
                days: this.getDays(activeDate),
            },
        ];
    }

    return [];
};

export default getBlocks;
