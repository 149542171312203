import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';

import UsersI from '../types.ts';

const deleteUser: UsersI['deleteUser'] = async function ({ user }) {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API}/user?id=${user._id}`, {
            headers: getHeaders(),
        });

        const { success } = response.data;

        if (success) {
            setNotification({ notification: 'delete-user' });

            return;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
};

export default deleteUser;
