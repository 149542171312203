import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { docPopup } = this.props;
    const { id } = docPopup;
    const model: I['state']['model'] = {
        _id: 'doc',
    } as I['state']['model'];

    if (id) {
        this.setState({ isEdit: true, id }, async () => {
            const resultDoc = await this.getModel();

            this.setState({ model: resultDoc });

            await this.init({ fields: resultDoc, start: !model });

            this.setHeight();
        });
    } else {
        await this.init({ fields: model, start: !model });
        this.setHeight();
    }
};

export default mountHandler;
