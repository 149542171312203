import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Avatar from '@components/avatar/Avatar.tsx';

// import Link from '../../Link.jsx';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';

import Icon from '../../Icon.jsx';

class HideSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { user, handlerHideState } = this.props;
        const currentCorporation = getCurrentCorporation({ user });

        return (
            <div ref={this.parent} className="hideSideBar _col">
                <div className="hideSideBar__head _col">
                    <i
                        className="hideSideBar__headIcon _click"
                        onClick={(e) => {
                            handlerHideState(true);

                            e.stopPropagation();
                        }}
                    >
                        <Icon name="bar-menu" />
                    </i>
                </div>
                <div className="hideSideBar__content _col">
                    <div className="hideSideBar__description _col">
                        <div className="hideSideBar__descriptionInner">
                            <span>Меню:</span> кликните для раскрытия
                        </div>
                    </div>
                    <div className="hideSideBar__logo _col _click">
                        <div className="hideSideBar__logoInner _col">
                            <AnimateChange
                                className="hideSideBar__logoImage _default"
                                renderKey={currentCorporation?._id}
                                styles={[]}
                            >
                                <Avatar
                                    image={currentCorporation?.logo.fullSrc}
                                    firstSymb={currentCorporation?.fullName?.[0]?.toUpperCase()}
                                />
                            </AnimateChange>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isProccessLogin: state.isProccessLogin,
    };
}

export default connect(mapStateToProps)(HideSideBar);

HideSideBar.propTypes = {
    user: PropTypes.object,
    isProccessLogin: PropTypes.bool,
    handlerHideState: PropTypes.func,
};
