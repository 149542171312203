import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AnimateChange from './AnimateChange.jsx';
import Animate from './Animate.jsx';

class CounterNotRead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { className = '', counterClassName = '', counter = 0 } = this.props;

        return (
            <Animate className={className} isShow={+counter > 0}>
                <div className={`notificationCounter _show ${counterClassName}`}>
                    <div className="notificationCounter__box _col">
                        <AnimateChange
                            className="notificationCounter__inner _col"
                            classNameChild="_child"
                            prop={counter}
                            type="_translateMedium"
                            isDisabled={counter === 0}
                        >
                            {counter}
                        </AnimateChange>
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(CounterNotRead);

CounterNotRead.propTypes = {
    className: PropTypes.string,
    counterClassName: PropTypes.string,
    counter: PropTypes.number,
};
