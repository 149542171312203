import React from 'react';

import PayGroupT from '@global/models/PayGroup';

type ParamsT = { payGroup: PayGroupT; type?: 'sign' };

export default function Status({ payGroup, type }: ParamsT): React.ReactNode {
    const { counter } = payGroup;
    const resultCounter = type === 'sign' ? payGroup.signedCounter : payGroup.completedCounter;
    const completePercent = (resultCounter / counter) * 100;

    return (
        <>
            <div
                className={`v2table__progress ${
                    counter === resultCounter ? '_complete _full' : ''
                }`}
            >
                <div className="v2table__progressInner">
                    <div className="v2table__progressItem" style={{ width: `${completePercent}%` }}>
                        <div className="v2table__progressItemInner" />
                    </div>
                </div>
                <div className="v2table__progressDescription">
                    {resultCounter} из {counter}
                </div>
            </div>
        </>
    );
}
