import PayT from '@global/models/Pay.ts';

import MainI from '../types.ts';

const updateGroup: MainI['updateGroup'] = function ({ fields }) {
    if (this.state.payGroup) {
        this.setState((state) => {
            const newState = { ...state };
            const payGroup = JSON.parse(JSON.stringify(newState.payGroup));
            const deletedPays = fields.deletedPays
                ? (fields.deletedPays as { groupId: string }[]).filter(
                      (item) => item.groupId === payGroup._id,
                  )
                : undefined;

            if (deletedPays) {
                payGroup.counter -= deletedPays.length;
                payGroup.errorCounter -= deletedPays.filter((item) =>
                    ['failed', 'error'].includes((item as PayT).status),
                ).length;
                payGroup.amount -= deletedPays.reduce(
                    (prev, cur) => prev + (cur as PayT).amount,
                    0,
                );
            } else {
                const item = fields?.item as PayT & {
                    isDelete?: boolean;
                    isNew?: boolean;
                    isRemoveError?: boolean;
                    isNotAddError?: boolean;
                };

                if (item?.groupId === payGroup._id) {
                    if (item.isDelete) {
                        payGroup.counter -= 1;

                        if (['failed', 'error'].includes(item.status)) {
                            payGroup.errorCounter -= 1;
                        }
                    } else {
                        if (item.status === 'completed') {
                            payGroup.completedCounter += 1;
                        }

                        if (item.isNew) {
                            payGroup.counter += 1;
                            payGroup.amount += item.amount;
                        }

                        if (['failed', 'error'].includes(item.status) && !item.isNotAddError) {
                            payGroup.errorCounter += 1;
                        }

                        if (item.isRemoveError) {
                            payGroup.errorCounter -= 1;
                        }
                    }
                }
            }

            newState.payGroup = payGroup;

            return newState;
        });
    }
};

export default updateGroup;
