import React from 'react';

import { RenderColsT } from '../../types.ts';

const renderListTagsGroupsColors: RenderColsT<'listTagsGroupsColors'> = function ({
    name: colName,
    item,
}) {
    let content;
    let className;

    const tagGroups = item as {
        _id: string;
        color: string;
        name: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text _right">{tagGroups.name}&nbsp;&nbsp;</div>
                <div className="v2table__color _inline" style={{ background: tagGroups.color }} />
            </>
        );
    }

    return { content, className };
};

export default renderListTagsGroupsColors;
