import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';
import Select from './Select.jsx';

import getDateSumDays from '../functions/getDateSumDays';
import getDaysInMonth from '../functions/getDaysInMonth';
import checkValueOfEmpty from '../functions/checkValueOfEmpty';

const infoDates = require('../infos/dates.json');

class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeMonth = this.changeMonth.bind(this);
        this.checkChoices = this.checkChoices.bind(this);
        this.setHeightMonth = this.setHeightMonth.bind(this);
        this.setDay = this.setDay.bind(this);

        this.parent = React.createRef();
    }

    nowDate = new Date();

    getMonth({ date = new Date() }) {
        const { withPast, withFuture = true } = this.props;
        const month = date.getMonth();
        const year = date.getFullYear();
        const counterDaysThis = getDaysInMonth(month, year);
        const days = [];
        const keyOfNowDay = getDateSumDays(
            this.nowDate.getDate(),
            this.nowDate.getMonth(),
            this.nowDate.getFullYear(),
        );

        date.setDate(1);

        let keyOfStartDay = date.getDay();

        keyOfStartDay = keyOfStartDay === 0 ? 6 : keyOfStartDay - 1;

        const prevDate = new Date(year, month - 1);

        prevDate.setDate(1);

        const counterDaysInPrevMonth = getDaysInMonth(month - 1, year);

        const getOtherProps = ({ day, name }) => {
            const otherDate = new Date(date);

            otherDate.setMonth(otherDate.getMonth() + (name === 'prev' ? -1 : 1));

            return {
                day,
                month: otherDate.getMonth(),
                year: otherDate.getFullYear(),
            };
        };

        for (let i = 0; i < keyOfStartDay; i++) {
            const day = counterDaysInPrevMonth - (keyOfStartDay - 1) + i;
            const key = getDateSumDays(day, month - 1, year);

            days.push({
                isOtherMonth: true,
                counterDay: day,
                isCurrent: keyOfNowDay === key,
                key,
                ...getOtherProps({ day, name: 'prev' }),
            });
        }

        for (let i = keyOfStartDay; i < counterDaysThis + keyOfStartDay; i++) {
            const day = i - (keyOfStartDay - 1);
            const key = getDateSumDays(day, month, year);

            let isDisabled = false;

            if (!withPast && keyOfNowDay > key) {
                isDisabled = true;
            }

            if (!withFuture && keyOfNowDay < key) {
                isDisabled = true;
            }

            days.push({
                counterDay: day,
                isDisabled,
                isCurrent: keyOfNowDay === key,
                key,
                day,
                month,
                year,
            });
        }

        const lastDay = Math.floor(days.length % 7);

        if (lastDay !== 0) {
            for (let i = lastDay; i < 7; i++) {
                const day = i - (lastDay - 1);
                const key = getDateSumDays(day, month + 1, year);

                days.push({
                    isOtherMonth: true,
                    counterDay: day,
                    isCurrent: keyOfNowDay === key,
                    key,
                    ...getOtherProps({ day, name: 'next' }),
                });
            }
        }

        return { days, month, year, key: getDateSumDays(0, month, year) };
    }

    setMonths(months) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.months = months;

                return newState;
            }, resolve);
        });
    }

    isProccessChange = false;

    changeMonth({ action, month: monthRes, year: yearRes }) {
        const { callbackChange } = this.props;

        if (!this.isProccessChange) {
            const { months } = this.state;
            const currentKey = months.findIndex((month) => month.state > -1);
            const currentMonth = months[currentKey];
            const { month, year } = currentMonth;
            let delta = action === 'prev' ? -1 : 1;

            if (monthRes || yearRes) {
                delta = 0;
            }

            const monthResult = checkValueOfEmpty(monthRes) ? monthRes : month + delta;
            const yearResult = checkValueOfEmpty(yearRes) ? yearRes : year;
            const newMonth = this.getMonth({ date: new Date(yearResult, monthResult) });
            const newKey = currentKey === 0 ? 1 : 0;

            months[newKey].state = 0;
            months[newKey].isCurrent = true;
            months[currentKey].state = 0;
            months[currentKey].isCurrent = false;

            Object.keys(newMonth).forEach((prop) => {
                months[newKey][prop] = newMonth[prop];
            });

            this.isProccessChange = true;

            this.setMonths(months).then(() => {
                setTimeout(() => {
                    months[newKey].state = 1;
                    this.setMonths(months).then(() => {
                        if (callbackChange) {
                            callbackChange();
                        }

                        this.setMonths(months).then(() => {
                            setTimeout(() => {
                                months[currentKey] = {
                                    state: -1,
                                };

                                this.setMonths(months).then(() => {
                                    this.isProccessChange = false;
                                });
                            }, 500);
                        });
                    });
                }, 10);
            });
        }
    }

    checkChoices({ day, month, year }) {
        const { currentDate, currentDateDouble } = this.state;
        const { isDouble } = this.props;
        let cond = false;

        if (currentDate) {
            const curDay = currentDate.getDate();
            const curMonth = currentDate.getMonth();
            const curYear = currentDate.getFullYear();

            cond = day === curDay && month === curMonth && year === curYear;

            if (isDouble && currentDateDouble) {
                const curDayDouble = currentDateDouble.getDate();
                const curMonthDouble = currentDateDouble.getMonth();
                const curYearDouble = currentDateDouble.getFullYear();

                cond =
                    cond ||
                    (day === curDayDouble && month === curMonthDouble && year === curYearDouble);
            }
        }

        return cond;
    }

    checkBeetween({ day, month, year }) {
        const { isDouble } = this.props;

        if (isDouble) {
            const { currentDate, currentDateDouble } = this.state;

            if (currentDate && currentDateDouble) {
                const key = getDateSumDays(day, month, year);
                const currentDateKey = getDateSumDays(
                    currentDate.getDate(),
                    currentDate.getMonth(),
                    currentDate.getFullYear(),
                );

                const currentDateDoubleKey = getDateSumDays(
                    currentDateDouble.getDate(),
                    currentDateDouble.getMonth(),
                    currentDateDouble.getFullYear(),
                );

                return key > currentDateKey && key < currentDateDoubleKey;
            }

            return false;
        }

        return false;
    }

    lastChange = 'double';

    setDay({ day, month, year }) {
        const { withPast, isDouble, handlerDate } = this.props;
        const currentDate = new Date();
        const key = getDateSumDays(day, month, year);
        const keyOfNowDay = getDateSumDays(
            this.nowDate.getDate(),
            this.nowDate.getMonth(),
            this.nowDate.getFullYear(),
        );

        if (withPast || key >= keyOfNowDay) {
            let prop = 'currentDate';

            if (isDouble) {
                if (!this.lastChange) {
                    this.lastChange = 'double';
                    prop = 'currentDateDouble';
                } else {
                    this.lastChange = null;
                }
            }

            const dateQuery = { [prop]: currentDate };

            currentDate.setDate(1);
            currentDate.setFullYear(year);
            currentDate.setMonth(month);
            currentDate.setDate(day);

            if (isDouble) {
                if (
                    this.state.currentDate &&
                    this.lastChange === 'double' &&
                    new Date(this.state.currentDate).getTime() > currentDate.getTime()
                ) {
                    this.lastChange = null;
                    dateQuery.currentDateDouble = this.state.currentDate;
                    dateQuery.currentDate = currentDate;
                }

                if (
                    this.state.currentDateDouble &&
                    this.lastChange === null &&
                    new Date(this.state.currentDateDouble).getTime() < currentDate.getTime()
                ) {
                    this.lastChange = 'double';
                    dateQuery.currentDate = this.state.currentDateDouble;
                    dateQuery.currentDateDouble = currentDate;
                }
            }

            this.setState({ ...dateQuery }, () => {
                handlerDate({ ...dateQuery });
            });
        }
    }

    setHeightMonth() {
        const { setHeightBlocks } = this.props;
        const month = this.parent.current.querySelector('.calendar__month._current');

        if (month) {
            const { clientHeight: heightMonth } = month;

            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.heightMonth = heightMonth;

                    return newState;
                },
                () => {
                    if (typeof setHeightBlocks === 'function') {
                        setHeightBlocks();
                    }
                },
            );
        }
    }

    init() {
        const {
            handlerDate,
            startDate,
            startDateDouble,
            isDouble,
            startYear = new Date().getFullYear(),
        } = this.props;

        const checkStartDate = () =>
            new Promise((resolve) => {
                const queryMonth = {};

                if (startDateDouble || startDate) {
                    queryMonth.date = new Date(startDateDouble || startDate);
                } else {
                    const date = new Date();

                    date.setFullYear(startYear);

                    queryMonth.date = date;
                }

                const month = this.getMonth(queryMonth);

                if (startDate || startDateDouble) {
                    this.setState(
                        (state) => {
                            const newState = { ...state };

                            if (startDate) {
                                newState.currentDate = new Date(startDate);
                            }

                            if (startDateDouble) {
                                newState.currentDateDouble = new Date(startDateDouble);
                            }

                            return newState;
                        },
                        () => {
                            handlerDate({
                                currentDate: this.state.currentDate,
                                currentDateDouble: this.state.currentDateDouble,
                            });

                            resolve({ month });
                        },
                    );
                } else if (!isDouble) {
                    this.setState(
                        (state) => {
                            const newState = { ...state };

                            const currentDate = new Date();

                            currentDate.setFullYear(startYear);

                            newState.currentDate = currentDate;

                            return newState;
                        },
                        () => {
                            handlerDate({ currentDate: this.state.currentDate });

                            resolve({ month });
                        },
                    );
                } else {
                    resolve({ month });
                }
            });

        return new Promise((resolve) => {
            checkStartDate().then(({ month }) => {
                this.setMonths([
                    {
                        ...month,
                        state: 1,
                        isCurrent: true,
                    },
                    {
                        state: -1,
                    },
                ]).then(resolve);
            });
        });
    }

    saveStartDate = null;

    saveStartDateDouble = null;

    checkClear() {
        const { startDate, startDateDouble } = this.props;
        const query = {};

        if (this.saveStartDate && !startDate && this.state.currentDate) {
            query.currentDate = null;
        }
        if (this.saveStartDateDouble && !startDateDouble && this.state.currentDateDouble) {
            query.currentDateDouble = null;
        }
        if (Object.keys(query).length) {
            this.lastChange = 'double';

            this.setState({ ...query });
        }

        this.saveStartDate = startDate;
        this.saveStartDateDouble = startDateDouble;
    }

    componentDidMount() {
        const { callbackInit } = this.props;

        this.init().then(() => {
            if (callbackInit) {
                callbackInit();
            }
        });

        this.setHeightMonth();

        const observerMonth = new MutationObserver(() => {
            this.setHeightMonth();
        });

        observerMonth.observe(this.parent.current, {
            childList: true,
            subtree: true,
            characterDataOldValue: true,
        });
    }

    componentDidUpdate() {
        this.checkClear();
    }

    render() {
        const { heightMonth, months } = this.state;
        const { className, type, listYears, isAllDates } = this.props;

        const currentMonth = months?.find((month) => month.isCurrent);

        return (
            <div
                ref={this.parent}
                className={`calendar ${className || ''} ${(type && `_${type}`) || ''}`}
            >
                <div className="calendar__head _row">
                    {type !== 'filter' && (
                        <>
                            <div className="calendar__title">
                                {months?.map((month, key) => {
                                    const {
                                        key: keyMonth,
                                        month: monthText,
                                        year: yearText,
                                    } = month;
                                    const keyOtherMonth = months.find(
                                        (item) => item.key !== keyMonth,
                                    ).key;

                                    let stateAnim;

                                    if (keyOtherMonth) {
                                        stateAnim = keyOtherMonth > keyMonth ? '_prev' : '_next';
                                    } else {
                                        stateAnim = '';
                                    }

                                    return (
                                        <div
                                            className={`calendar__titleInner _row ${stateAnim} ${
                                                month.state === 1 ? '_show' : ''
                                            }`}
                                            key={keyMonth || key}
                                        >
                                            {infoDates.months[monthText]} {yearText}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="calendar__actions _row">
                                <i
                                    className="calendar__action _click _prev"
                                    onClick={() => this.changeMonth({ action: 'prev' })}
                                >
                                    <Icon name="arrow-prev-short" />
                                </i>
                                <i
                                    className="calendar__action _click _next"
                                    onClick={() => this.changeMonth({ action: 'next' })}
                                >
                                    <Icon name="arrow-next-short" />
                                </i>
                            </div>
                        </>
                    )}
                    {type === 'filter' && (
                        <>
                            <div className="calendar__selects _row">
                                <div className="calendar__select _months">
                                    <Select
                                        className="_colorBlue"
                                        name="months"
                                        value={currentMonth?.month}
                                        items={infoDates.months.map((month, key) => ({
                                            content: month,
                                            key,
                                        }))}
                                        callback={async ({ value }) => {
                                            this.changeMonth({ month: value });

                                            return true;
                                        }}
                                    />
                                </div>
                                <div className="calendar__select _year">
                                    <Select
                                        className="_colorBlue"
                                        name="years"
                                        value={+currentMonth?.year}
                                        items={listYears}
                                        callback={async ({ value }) => {
                                            this.changeMonth({ year: value });

                                            return true;
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="calendar__content">
                    <div className="calendar__days _row">
                        {infoDates.days.map((day, key) => (
                            <div className="calendar__day _col" key={key}>
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className="calendar__inner" style={{ height: `${heightMonth}px` }}>
                        <div className="calendar__box" style={{ height: `${heightMonth}px` }}>
                            {months?.map((month, key) => {
                                const { days, key: keyMonth } = month;
                                const keyOtherMonth = months.find(
                                    (item) => item.key !== keyMonth,
                                ).key;

                                let stateAnim;

                                if (keyOtherMonth) {
                                    stateAnim = keyOtherMonth > keyMonth ? '_prev' : '_next';
                                } else {
                                    stateAnim = '';
                                }

                                return (
                                    month.state !== -1 && (
                                        <div
                                            className={`calendar__month _row ${
                                                month.isCurrent ? '_current' : ''
                                            } ${stateAnim} ${month.state === 1 ? '_show' : ''}`}
                                            key={key}
                                        >
                                            {days.map((day, keyDay) => (
                                                <label
                                                    className={`calendar__monthDay ${
                                                        day.isOtherMonth ? '_other' : ''
                                                    } ${day.isCurrent ? '_current' : ''} ${
                                                        day.isDisabled ? '_disabled' : ''
                                                    } ${
                                                        this.checkBeetween(day) ? '_beetween' : ''
                                                    } ${isAllDates ? '_isAllDates' : ''}`}
                                                    key={keyDay}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="calendar__monthDayInput"
                                                        onChange={() => this.setDay(day)}
                                                        disabled={day.isDisabled}
                                                        checked={this.checkChoices(day)}
                                                    />
                                                    <div className="calendar__monthDayView _col _click">
                                                        {day.counterDay}
                                                    </div>
                                                </label>
                                            ))}
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Calendar);

Calendar.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    startDate: PropTypes.string,
    startDateDouble: PropTypes.string,
    setHeightBlocks: PropTypes.func,
    handlerDate: PropTypes.func,
    withPast: PropTypes.bool,
    withFuture: PropTypes.bool,
    isDouble: PropTypes.bool,
    listYears: PropTypes.array,
    callbackInit: PropTypes.func,
    callbackChange: PropTypes.func,
    isAllDates: PropTypes.bool,
    startYear: PropTypes.number,
};
