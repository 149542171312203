import React from 'react';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import handlerLoading from '@functions/handlerLoading.ts';

import I from '../types.ts';

const renderLink: I['renderLink'] = function ({ item }) {
    const { loadingKey } = this.state;
    const { links = [] } = this.props;
    const link = links.find((innerLink) => innerLink.id === item.id);

    return (
        <div className="v2widgetVerificationCard__link _ITEM">
            <Button
                className={`_edit ${item?.className || '_blue'}`}
                onClick={async () => {
                    if (link?.onClick) {
                        await handlerLoading.call(this, item.id);
                        try {
                            await link.onClick();
                        } catch (error) {}
                        await handlerLoading.call(this, undefined);
                    }
                }}
                loading={loadingKey === item.id}
            >
                {item?.content}
                {item?.icon && (
                    <>
                        <i className={item.icon.className}>
                            <Icon name={item.icon.name} />
                        </i>
                    </>
                )}
            </Button>
        </div>
    );
};

export default renderLink;
