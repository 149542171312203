import handlerPopup from '@functions/handlerPopup.ts';

import MainI from '../types.ts';

const getActions: MainI['getActions'] = function () {
    return [
        // {
        //     type: 'export',
        //     name: 'export',
        //     group: 'buttons',
        //     exportName: 'joins',
        // },
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'joins',
        },
        // {
        //     type: 'link',
        //     content: 'Загрузить пачку',
        //     name: 'upload',
        //     className: '_main',
        //     group: 'links',
        //     onClick: async () => {
        //         handlerPopup('uploadJoinsPopup', {
        //             isShow: true,
        //         });
        //     },
        // },
        {
            type: 'link',
            content: '+ Пригласить',
            name: 'invite',
            className: '_main',
            group: 'links',
            onClick: async () => {
                handlerPopup('executorCreatePopup', {
                    isShow: true,
                    isJoin: true,
                });
            },
        },
    ];
};

export default getActions;
