import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import { setCursorSupport } from '@functions/supportHandler.ts';

import CheckboxI from './types.ts';

class Checkbox
    extends React.Component<CheckboxI['props'], CheckboxI['state']>
    implements CheckboxI
{
    constructor(props: CheckboxI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { name, children, disabled, onChange, value, className = '', info } = this.props;
        const supportProps =
            info &&
            setCursorSupport(
                {
                    _id: `checkboxInfo-${name}`,
                    content: info,
                    dir: 'top',
                },
                { targetClassName: '.v2checkbox__info', className: '' },
            );

        return (
            <label className={`v2checkbox ${className}`}>
                <input
                    type="checkbox"
                    className="v2checkbox__input"
                    onChange={() => {
                        onChange({ name, value: !value });
                    }}
                    disabled={disabled}
                    checked={!!value}
                />
                <div className="v2checkbox__inner">
                    <div className="v2checkbox__box _COL">
                        <div className="v2checkbox__boxIcon">
                            <Icon name="done" />
                        </div>
                    </div>
                    {children && (
                        <div className="v2checkbox__text">
                            {children}
                            {info && (
                                <div className="v2checkbox__info" {...supportProps}>
                                    <Icon name="info" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </label>
        );
    }
}

export default Checkbox;
