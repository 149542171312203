import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const model = this.props.model;

    this.setState({ user: this.props.user });

    await this.init({ fields: model, start: !!model.new });

    if (this.mountCallback) {
        this.mountCallback();
    }
};

export default mountHandler;
