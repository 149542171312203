import TagT from '@global/models/Tag';

const countryMain = {
    title: 'Данные',
    description: (model: TagT) => model?.name,
    fieldsOrder: ['name', 'code'],
    fields: {
        name: {
            support: 'Название',
            type: 'input',
            group: '1',
            width: 4,
        },
        code: {
            support: 'Код страны',
            type: 'input',
            group: '1',
            width: 2,
        },
        type: {
            support: 'Группа',
            prop: 'type',
            selectTextKey: 'typeName',
            type: 'select',
            group: '1',
            width: 2,
            selectList: 'countryTypes',
            selectProps: { name: 'typeName' },
        },
    },
} as const;

export default countryMain;
