import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import getEndText from '@functions/getEndText.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';

import MainI from '../types.ts';

const renderHeadTitle: MainI['renderHeadTitle'] = function () {
    const { group, counter } = this.state;
    const { type } = this.props;
    const amount = +(group?.amount || 0).toFixed(2);
    const posAmount = +(group?.posAmount || 0).toFixed(2);
    const negAmount = +(group?.negAmount || 0).toFixed(2);

    return (
        <>
            <div className="v2widget__headData _col _ready">
                <Animate className="v2widget__headDataLoader _LOADER" isShow={!this.state.group}>
                    <div className="v2widget__headDataLoaderTitle _LOADERWAIT" />
                    <div className="v2widget__headDataLoaderDescription _LOADERWAIT" />
                </Animate>
                <AnimateChange
                    className="v2widget__headDataInner _dynamic"
                    renderKey={group ? `${counter}${group.amount}` : undefined}
                    styles={['width']}
                >
                    <>
                        <div className="v2widget__headDataTitle">
                            <>
                                <b>
                                    {getFormatPrice(counter)}{' '}
                                    {getEndText(counter || 0, ['операция', 'операции', 'операций'])}
                                </b>{' '}
                                {type !== 'income' && <>{getFormatPrice(+amount?.toFixed(2))} ₽</>}
                            </>
                        </div>
                        <div className="v2widget__headDataDescription _row">
                            {type === 'income' ? (
                                <>
                                    <div className="v2widget__headDataDescriptionItem _completed">
                                        Заработано: {getFormatPrice(+-negAmount?.toFixed(2))} ₽
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="v2widget__headDataDescriptionItem _completed">
                                        Начислено: {getFormatPrice(+posAmount?.toFixed(2))} ₽
                                    </div>
                                    <div className="v2widget__headDataDescriptionItem _error">
                                        Списано: {getFormatPrice(+-negAmount?.toFixed(2))} ₽
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                </AnimateChange>
            </div>
        </>
    );
};

export default renderHeadTitle;
