import MobPopupI from '../types.ts';

const popupChange: MobPopupI['popupChange'] = function ({
    detail: { isShow, name },
}: {
    detail: { isShow: boolean; name: string };
}) {
    if (isShow === false && name === this.name) {
        this.hide(false);
    }
};

export default popupChange;
