import MobPopupI from '../types.ts';

const handlerEnd: MobPopupI['handlerEnd'] = function (isForce) {
    const inner = this.getInner();

    if (!inner) {
        return;
    }

    if (this.isProccess || isForce === true) {
        this.isProccess = false;
        let moveDirection = this.state.moveDirection || 'start';

        inner.classList.remove('_isDrag');

        if (this.deltaY === -1 || isForce === true) {
            moveDirection = this.animate();

            this.lastY = this.moveY;
        } else {
            this.moveY = 0;
        }

        document.dispatchEvent(
            new CustomEvent('popup', {
                detail: { action: 'end', name: this.name, move: this.moveY },
            }),
        );

        this.setState({ moveDirection });

        this.startY = 0;
        this.deltaY = null;

        this.disabledDocument(false);
    }
};

export default handlerEnd;
