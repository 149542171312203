import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import WalletsPopup from './WalletsPopup.jsx';

class WalletsPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { walletsPopup } = this.props;

        return (
            <Animate className="body__walletsPopup" isShow={walletsPopup.isShow}>
                <WalletsPopup {...walletsPopup} />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        walletsPopup: state.walletsPopup,
    };
}

export default connect(mapStateToProps)(WalletsPopupWrapper);

WalletsPopupWrapper.propTypes = {
    walletsPopup: PropTypes.object,
};
