import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import Tag from '@components/crm/tag/Tag.tsx';
import InfoBlock from '@components/infoBlock/InfoBlock.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import { setCursorList } from '@functions/listsHandler.ts';
import TagT from '@global/models/Tag.ts';

import I from '../types.ts';

const renderTag = function ({ item }: { item: TagT }): React.ReactElement {
    return (
        <div className="v2widgetCard__tag _ITEM">
            <Tag color={item.color}>{item.name}</Tag>
        </div>
    );
};

const renderTagsCard: I['renderTagsCard'] = function () {
    const model = this.state.model!;
    const tagsCounter = model.tags.length;

    const props = setCursorList(
        {
            _id: 'executors-tags',
            type: 'tags',
            dir: 'bottom',
            currents: model.tags.map((tag) => tag.id),
            filterName: 'tags',
            multi: true,
            callback: (modelId, item: any) => {
                if (item) {
                    this.addArrayItem({ item: { ...item, id: item._id }, key: 'tags' });
                } else {
                    this.deleteArrayItem([`tags.${modelId}`]);
                }
            },
            checkClose: () => !document.querySelector('.body__v2popup._filter'),
        },
        { targetClassName: '.v2widgetCard__actionsButton' },
    );

    return (
        <div className="v2widgetCard">
            <div className="v2widgetCard__head _ROW">
                <div className="v2widgetCard__title">
                    Теги:
                    <AnimateChange
                        className="v2widgetCard__description"
                        renderKey={tagsCounter}
                        styles={['width']}
                    >
                        <div className="v2widgetCard__descriptionInner">{tagsCounter || '–'}</div>
                    </AnimateChange>
                </div>
                <div className="v2widgetCard__actions">
                    <div className="v2widgetCard__actionsButton" {...props}>
                        <Button className="_blue _minSize">+ Добавить</Button>
                    </div>
                </div>
            </div>
            <div className="v2widgetCard__content _fullHeight _NOSCROLL">
                <InfoBlock
                    title="Теги не выбраны"
                    description="Вы можете добавить их<br/>по кнопке выше"
                    className="v2widgetCard__info"
                    isShow={model.tags.length === 0}
                />
                <ListAbsoluteMain
                    className="v2widgetCard__tags _ITEMS"
                    items={model.tags}
                    renderItem={renderTag.bind(null)}
                    classNameItem="v2widgetCard__tag"
                    prop="_id"
                    paramsParent={{ width: true }}
                />
            </div>
        </div>
    );
};

export default renderTagsCard;
