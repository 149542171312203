import React from 'react';

import ExecutorTypeT from '@global/models/ExecutorType.ts';

import { RenderColsT } from '../../types.ts';

const renderListExecutorTypes: RenderColsT<'listExecutorTypes'> = function ({
    name: colName,
    item,
}) {
    let content;
    let className;

    const executorType = item as Pick<ExecutorTypeT, '_id' | 'name'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{executorType.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListExecutorTypes;
