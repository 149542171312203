import React from 'react';

import ManualTableI from '@components/crm/table/types';
import Icon from '@components/icon/Icon';
import LoaderBlock from '@components/loaderBlock/LoaderBlock';
import download from '@functions/download';
import getFormatedDate from '@functions/getFormatedDate';
import { setCursorSupport } from '@functions/supportHandler';
import PayT from '@global/models/Pay';

import Fns from './Fns';

type ParamsT = {
    pay: PayT;
    type?: 'sign';
    loadingHandler: ManualTableI['loadingHandler'];
    loadings: ManualTableI['state']['loadings'];
};

export default function Status({ pay, type, loadingHandler, loadings }: ParamsT): React.ReactNode {
    let status = pay.status as string;
    const statusContent = (type === 'sign' ? pay.signStatus : pay.actStatus)?.replace(
        '\n',
        '<br/>',
    );
    let infoContent = '';
    let statusClassName = '_mediumWeight _grey';

    if (!type) {
        if (pay.isCorporationSign === true) {
            status = 'accept';

            statusClassName = '_mediumWeight _mediumColor';
        }

        if (status === 'pending' || status === 'in_progress') {
            status = 'pending';

            statusClassName = '_mediumWeight _mediumColor';
        }

        if (pay.isUpdate) {
            status = 'updatedCard';

            statusClassName = '_mediumWeight _mainColor';
        }

        if (pay.errorText) {
            infoContent += pay.errorText.replace(/\n/gi, '<br/>');
        }

        if (pay.transactionErrorText) {
            if (infoContent) {
                infoContent += '<br/>';
            }

            infoContent += pay.transactionErrorText.replace(/\n/gi, '<br/>');
        }

        if (
            pay.status === 'process' &&
            (pay.executorSign.isComplete || pay.executorSign.isNotSign)
        ) {
            statusClassName = '_mediumWeight _processColor';
        }

        if (pay.status === 'wait-pay') {
            statusClassName = '_mediumWeight _preSuccessColor';
        }

        if (pay.status === 'completed') {
            status = 'completed';

            statusClassName = '_mediumWeight _successColor';

            infoContent = '';

            if (pay.payDate) {
                infoContent = getFormatedDate({ date: new Date(pay.payDate), type: 'full' });
            }
        }
    }

    if (type === 'sign') {
        if (pay.isCorporationSign === true) {
            status = 'accept';

            // statusClassName = '_mediumColor';

            statusClassName = '';
        }

        if (pay.isExecutorSign === true || (pay.executorSign.isNotSign && pay.isCorporationSign)) {
            status = 'completed';

            statusClassName = '_mediumWeight _successColor';
        }
    }

    if (pay.status === 'error' || pay.status === 'failed') {
        status = 'error';

        statusClassName = '_mediumWeight _errorColor';
    }

    const fileSrc = pay[type === 'sign' ? 'actFileName' : 'receiptFileName'];

    const renderFile = ({ fileKey }: { fileKey: string }) => {
        const loadingKey = `${pay._id}-${fileKey}`;

        return (
            fileSrc && (
                <div
                    className="v2table__button _ROW _CLICK"
                    onClick={async () => {
                        const fileName =
                            fileKey === 'downloadAct'
                                ? `Акт_№${pay.number}`
                                : `Чек_по_акту_№${pay.number}`;

                        try {
                            await loadingHandler({ name: loadingKey, loading: true });
                            await download({
                                name: fileName,
                                files: [{ src: `corporations-docs/${fileSrc}` }],
                            });
                        } catch (error) {}
                        await loadingHandler({ name: loadingKey, loading: undefined });
                    }}
                >
                    <LoaderBlock
                        className="v2table__buttonLoader"
                        isShow={!!loadings[loadingKey]}
                    />
                    Скачать
                    <i className="v2table__buttonIcon">
                        <Icon name={type === 'sign' ? 'pays-sign' : 'pays-status'} />
                    </i>
                </div>
            )
        );
    };

    const supportProps = setCursorSupport(
        { _id: `status-${pay._id}`, content: infoContent, dir: 'top' },
        {
            targetClassName: '.v2table__textInfo',
            className: '_upper',
        },
    );

    return (
        <>
            <div className={`v2table__text _bottom _block ${statusClassName}`}>
                <div
                    className={`v2table__text ${statusClassName}`}
                    dangerouslySetInnerHTML={{ __html: statusContent }}
                />{' '}
                {infoContent && (
                    <i className="v2table__textInfo _CLICK" {...supportProps}>
                        <Icon name="info" />
                    </i>
                )}
            </div>
            <div className="v2table__colFlex _COL">
                {renderFile({ fileKey: type === 'sign' ? 'downloadAct' : 'downloadReceipt' })}
            </div>
            {type !== 'sign' && pay.fnsReceiptLink && (
                <div className="v2table__colFlex _COL">
                    <Fns pay={pay} />
                </div>
            )}
        </>
    );
}
