import { FilterQueryT } from '@global/types.ts';

import ContractsI from '../types.ts';

const getQuery: ContractsI['getQuery'] = function (this: ContractsI) {
    const { executorId, type } = this.props;
    const query: FilterQueryT[] = [];

    if (executorId) {
        query.push({ key: 'executorId', value: executorId });
    }

    if (type) {
        query.push({ key: 'type', value: type });
    }

    return query;
};

export default getQuery;
