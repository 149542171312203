import lists from '../lists';

const listJoinScripts = {
    ...lists,
    query: [{ key: 'type', value: 'joinScripts' }],
    emptyInfo: {
        title: 'Сценариев пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listJoinScripts;
