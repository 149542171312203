import ListAbsoluteI from '../types.ts';

const updateList: ListAbsoluteI['updateList'] = function (e) {
    const { detail } = e;
    const { id: idCome, callback, props = {} } = detail;

    const { id } = this.props;

    if (idCome === id) {
        this.getParams(props).then(() => {
            if (callback) {
                callback({ isForce: true });
            }
        });
    }
};

export default updateList;
