import React from 'react';

import Inner from '../components/Inner/Inner';
import Main from '../components/Main/Main';

const pages = {
    'manual-executors-main': {
        render() {
            return (
                <>
                    <Main />
                </>
            );
        },
    },
    'manual-executors-inner': {
        render() {
            return <Inner />;
        },
    },
} as const;

export default pages;
