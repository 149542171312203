import { ModelsT } from '../models';

const countries: ModelsT = {
    url: 'countries',
    logName: 'city',
    title: 'Справочник',
    parentPage: 'manual-countries',
    tablePage: 'manual-countries-main',
    tableName: 'countries',
    modelPage: 'manual-countries-inner',
    deleteAlertPopup: 'deletetCountry',
    descriptions: {
        table: 'Страны',
        model: {
            new: 'Новая страна',
            default: 'Страна',
        },
    },
    addButton: 'Добавить страну',
    cardName: 'countryMain',
};

export default countries;
