import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Head from '../../manual/card/Head.jsx';
import Field from '../../manual/card/Field.jsx';
import File from '../../manual/card/File.jsx';

class ContentInfoMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderFields = this.renderFields.bind(this);

        this.parent = React.createRef();
    }

    fields = {
        main: ['section', 'viewType', 'title'],
        new: ['newSection'],
    };

    files = {
        preview: {
            content: 'Иконка',
        },
        newSectionImage: {
            content: 'Обложка',
        },
    };

    renderFile({ name }) {
        const { article = {}, uploadMainFile, checkEditmode, newFiles } = this.props;
        const fileInfo = this.files[name];

        return (
            <File
                _id={article[name]?._id}
                file={newFiles[name] || article[name]}
                folder="info-articles"
                name={name}
                uploadFile={({ files }) => {
                    uploadMainFile({ name, files });
                }}
                isDisabled={!checkEditmode()}
                className="_minSize _contain"
                typeOfFiles="image"
            >
                {fileInfo.content}
            </File>
        );
    }

    renderFields(fieldsName) {
        const { article = {}, checkEditmode, changeArticle, errors = [] } = this.props;

        return (
            <div className={`manualCard__contentInfoFields _row _${fieldsName}`}>
                {this.fields[fieldsName].map((name) => {
                    const { [name]: value } = article;
                    let items;
                    let isDisabled = !checkEditmode();
                    const isError = errors.indexOf(name) !== -1;

                    if (name === 'section') {
                        items = article.sections?.map((section) => ({
                            key: section._id,
                            content: section.name,
                        }));

                        if (!items || items?.length === 0) {
                            isDisabled = true;
                        }
                    }

                    if (name === 'viewType') {
                        items = article.viewTypes;
                    }

                    return (
                        <div className={`manualCard__contentInfoField _${name}`} key={name}>
                            <Field
                                value={value}
                                type="blog"
                                name={name}
                                handler={changeArticle}
                                group="infoMain"
                                isDisabled={isDisabled}
                                isError={isError}
                                items={items}
                                className={fieldsName === 'new' ? '_vertical' : ''}
                                classNameField={
                                    fieldsName === 'new' ? (isError ? '_whiteBack' : '_white') : ''
                                }
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    getDescriptionInfo() {
        const { article } = this.props;

        if (article?.title) {
            return {
                description: article.title,
            };
        }

        return {
            description: '–',
        };
    }

    render() {
        const { checkEditmode, errors = [] } = this.props;
        const { description } = this.getDescriptionInfo();

        return (
            <div ref={this.parent} className="manualCard _infoMain">
                <div className="manualCard__head _row">
                    <Head title="Общая информация" description={description}></Head>
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__contentInfo _row">
                        <div className="manualCard__contentInfoBlock _row">
                            <div className="manualCard__contentInfoFile _preview">
                                {this.renderFile({ name: 'preview' })}
                            </div>
                            {this.renderFields('main')}
                        </div>
                        <div
                            className={`manualCard__contentInfoBlock _row _new ${
                                checkEditmode() ? '_edit' : ''
                            } ${errors.includes('newSection') ? '_error' : ''}`}
                        >
                            <div className="manualCard__contentInfoFile _preview">
                                {this.renderFile({ name: 'newSectionImage' })}
                            </div>
                            {this.renderFields('new')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ContentInfoMain);

ContentInfoMain.propTypes = {
    serverData: PropTypes.object,
    article: PropTypes.object,
    checkEditmode: PropTypes.func,
    errors: PropTypes.array,
    changeArticle: PropTypes.func,
    uploadMainFile: PropTypes.func,
    isNew: PropTypes.bool,
    newFiles: PropTypes.object,
};
