import React from 'react';

import LazyImage from '@components/lazyImage/lazyImage.tsx';
import { ListenerT } from '@global/types.ts';

import checkBorders from './methods/checkBorders.ts';
import checkImage from './methods/checkImage.ts';
import dbClickHandler from './methods/dbClickHandler.ts';
import eventEndHandler from './methods/eventEndHandler.ts';
import eventMoveHandler from './methods/eventMoveHandler.ts';
import eventStartHandler from './methods/eventStartHandler.ts';
import getImage from './methods/getImage.ts';
import getPosition from './methods/getPosition.ts';
import getSize from './methods/getSize.ts';
import imageMove from './methods/imageMove.ts';
import init from './methods/init.ts';
import setSize from './methods/setSize.ts';

import GaleryImageI from './types.ts';

class GaleryImage
    extends React.Component<GaleryImageI['props'], GaleryImageI['state']>
    implements GaleryImageI
{
    parent: GaleryImageI['parent'];

    constructor(props: GaleryImageI['props']) {
        super(props);
        this.state = {
            isZoom: false,
        };

        this.eventStartHandler = this.eventStartHandler.bind(this);
        this.eventMoveHandler = this.eventMoveHandler.bind(this);
        this.eventEndHandler = this.eventEndHandler.bind(this);

        this.parent = React.createRef();
    }

    startX = 0;
    moveX = 0;
    endX = 0;

    startY = 0;
    moveY = 0;
    endY = 0;

    eventStartHandler = eventStartHandler;
    eventMoveHandler = eventMoveHandler;
    eventEndHandler = eventEndHandler;

    checkImage = checkImage;
    getImage = getImage;
    checkBorders = checkBorders;
    dbClickHandler = dbClickHandler;
    getPosition = getPosition;
    imageMove = imageMove;
    setSize = setSize;
    getSize = getSize;

    init = init;

    componentWillUnmount(): void {
        const image = this.parent.current!;

        (image.removeEventListener as ListenerT<React.MouseEvent>)(
            'mousedown',
            this.eventStartHandler,
        );
        (document.removeEventListener as ListenerT<React.MouseEvent>)(
            'mousemove',
            this.eventMoveHandler,
        );
        (document.removeEventListener as ListenerT<React.MouseEvent>)(
            'mouseup',
            this.eventEndHandler,
        );
    }

    render() {
        const { isZoom, isMove } = this.state;
        const { src, name, scale } = this.props;
        const isImage = this.checkImage();

        return (
            <div
                ref={this.parent}
                className={`v2popupGalery__imageItem ${isMove ? '_disabled' : ''}`}
                onDoubleClick={isImage ? this.dbClickHandler.bind(this) : undefined}
                style={isZoom ? { transform: `scale(${scale})` } : {}}
                onDragStart={(e) => {
                    e.preventDefault();

                    return false;
                }}
            >
                <LazyImage
                    src={src}
                    name={name}
                    showFrame={true}
                    loadCallback={this.init.bind(this)}
                />
            </div>
        );
    }
}

export default GaleryImage;
