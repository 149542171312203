import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import RoleT from '@global/models/Role.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const setActive: I['setActive'] = async function (id, { section, isActive }) {
    let response;

    try {
        response = await axios.patch<{}, { data: ReqResponseT<{ model: RoleT }> }>(
            `${process.env.REACT_APP_API}/role`,
            {
                id,
                fields: { sections: [{ _id: section._id, key: section.key, isActive }] },
            },
            { headers: getHeaders() },
        );
    } catch (error) {
        return Promise.reject();
    }

    const { success, data } = response.data;

    if (success) {
        const { model } = data;

        this.setModel({ model });

        return;
    }
};

export default setActive;
