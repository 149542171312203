export default class Editmode {
    constructor({ context }) {
        this.context = context;

        this.handlerLoading = this.handlerLoading.bind(this);
        this.handlerEdit = this.handlerEdit.bind(this);
    }

    handlerLoading(keyLoading) {
        return new Promise((resolve) => {
            this.context.setState({ keyLoading }, resolve);
        });
    }

    handlerEdit({ editName = null, editCurrent }) {
        return new Promise((resolve) => {
            this.context.setState((state) => {
                const newState = { ...state };

                if (editCurrent === undefined) {
                    newState.editName = editName;
                }

                newState.editCurrent = editCurrent;

                return newState;
            }, resolve);
        });
    }
}
