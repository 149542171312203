import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading';

import CallPopupI from '../types.ts';

const sendForm: CallPopupI['sendForm'] = async function () {
    const { fields } = this.state;
    const formFields: Record<any, unknown> = {};

    (Object.keys(fields) as (keyof typeof fields)[]).forEach((key) => {
        formFields[key] = fields[key];
    });

    await handlerLoading.call(this, 'send');

    try {
        const response = await axios.post<
            { name: string; fields: Record<any, unknown> },
            { data: { success: boolean; data: { error?: { text: string } } } }
        >(
            `${process.env.REACT_APP_API}/form`,
            { name: 'call', fields: formFields },
            { headers: getHeaders() },
        );
        const { success } = response.data;

        if (success) {
            this.setState({ success: true });
        } else {
            const { error } = response.data.data;

            if (error) {
                this.setState({ error });
            }
        }
    } catch (error) {
        this.setState({ error: { text: 'Попробуйте позже' } });
    }

    await handlerLoading.call(this, undefined);
};

export default sendForm;
