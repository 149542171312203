import I from '../types.ts';

const eventEndHandler: I['eventEndHandler'] = function () {
    if (this.isMove) {
        this.isMove = false;

        this.endX = this.moveX;
        this.endY = this.moveY;

        this.setState({ isMove: false });
    }
};

export default eventEndHandler;
