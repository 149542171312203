import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = async function () {
    handlerPopup('galeryPopup', {
        isShow: false,
        title: undefined,
        files: undefined,
        currentId: undefined,
        withRotate: undefined,
    });

    this.savePin();
};

export default close;
