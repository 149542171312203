import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import TagT from '@global/models/Tag.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { model } = this.state;
    const { setModel, config, type } = this.props;
    const change = this.checkChange(true);

    const fields: Record<any, unknown> = change.model!;

    Object.keys(fields).forEach((key) => {
        if (Array.isArray(fields[key])) {
            fields[key] = model![key];
        }
    });

    const body: Record<any, unknown> = {
        type,
        fields,
    };

    const method = model!.new ? 'post' : 'patch';

    if (method === 'patch') {
        body.id = model?._id;
    }

    try {
        const response = await axios[method]<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: TagT;
                    error?: { name: string; text: string };
                }>;
            }
        >(`${process.env.REACT_APP_API}/${config.url}`, { ...body }, { headers: getHeaders() });
        const { success, data } = response.data;

        if (success) {
            const { id, model: resultModel } = data;

            if (method === 'post' && id) {
                await changePage<'crm'>({
                    pageName: config.modelPage,
                    ids: { 3: id },
                });
            }

            await this.init({ fields: resultModel });
            await setModel(resultModel);

            return;
        }

        const { error } = data;

        this.setState({ error });
    } catch (error) {}

    return Promise.reject();
};

export default save;
