const executorTypes = {
    colsOrder: ['name', 'executorsCounter'],
    cols: {
        name: {
            content: 'Название',
            width: 80,
        },
        executorsCounter: {
            content: 'Исполнители',
            width: 20,
        },
    },
    deletesCols: [],
    url: 'executorTypes',
    emptyInfo: {
        title: 'Категорий пока нет',
        description: 'Вы можете создать категорию по кнопке справа',
    },
} as const;

export default executorTypes;
