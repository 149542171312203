import React from 'react';

import Media from '@components/media/Media.tsx';
import Popup from '@components/popup/Popup.tsx';

import FilterI from '../types.ts';

const renderDesktop: FilterI['renderDesktop'] = function () {
    return (
        <Media current="desktop">
            <Popup close={this.close.bind(this)} name="filterPopup">
                {this.renderContent()}
            </Popup>
        </Media>
    );
};

export default renderDesktop;
