import React from 'react';

import Animate from '@components/animate/Animate.tsx';

import I from '../types.ts';

const renderFake: I['renderFake'] = function () {
    const { fakeItems } = this.state;
    const isLoading = this.checkLoading();

    return (
        <>
            <Animate className="v2table__fake" isShow={isLoading}>
                <div className="v2table__fakeInner">
                    {fakeItems.map((item, key) =>
                        this.renderRow({ item, prop: item._id, key, fake: true }),
                    )}
                </div>
            </Animate>
        </>
    );
};

export default renderFake;
