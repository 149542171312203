import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import mountHandler from './methods/mountHandler.ts';
import save from './methods/save.ts';

import ExecutorLimitsI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class ExecutorLimits
    extends EditBlock<ExecutorLimitsI['props'], ExecutorLimitsI['state']>
    implements ExecutorLimitsI
{
    constructor(props: ExecutorLimitsI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;

    mountHandler = mountHandler;
    save = save;
    close = close;

    componentDidMount() {
        this.mountHandler();
    }

    render() {
        return (
            <Popup
                name="executorLimitsPopup"
                close={this.close.bind(this)}
                checkClose={() =>
                    !document.querySelector('.v2cursorLists__item') &&
                    !document.querySelector('.body__v2popup._alert')
                }
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        executorLimitsPopup: state.executorLimitsPopup,
    };
}

export default connect(mapStateToProps)(ExecutorLimits);
