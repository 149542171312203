import React from 'react';
import PropTypes from 'prop-types';
import getFormatedDate from '../../functions/getFormatedDate';
import getUserInfo from '../../functions/getUserInfo';

class Sign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { id, signUserInfo, signExecutorInfo } = this.props;

        return (
            <div className="pdf__sign">
                <div className="pdf__signNumber">Идентификатор документа: {id}</div>
                <div className="pdf__signHead _row">
                    <img
                        src={require('../../img/crm/pdf/logo-short.svg').default}
                        alt=""
                        className="pdf__signHeadLogo"
                    />
                    Документ подписан и передан через «CRM LIVECARGO»
                </div>
                <div className="pdf__signContent _row">
                    {signUserInfo && (
                        <>
                            <div className="pdf__signBlock _col">
                                <div className="pdf__signBlockContent">
                                    <b>Подписан:</b>{' '}
                                    {getFormatedDate({
                                        date: new Date(signUserInfo.date),
                                        type: 'full',
                                        withTimezone: true,
                                    })}
                                </div>
                                <div className="pdf__signBlockContent">
                                    {signUserInfo.corporationName}, ИНН{' '}
                                    {signUserInfo.corporationInn}
                                </div>
                                <div className="pdf__signBlockContent">
                                    {getUserInfo({ type: 'name', user: signUserInfo })}
                                </div>
                                <div className="pdf__signBlockContent">{signUserInfo.signId}</div>
                            </div>
                        </>
                    )}
                    {signExecutorInfo && (
                        <>
                            <div className="pdf__signBlock _col">
                                <div className="pdf__signBlockContent">
                                    <b>Подписан:</b>{' '}
                                    {getFormatedDate({
                                        date: new Date(signExecutorInfo.date),
                                        type: 'full',
                                        withTimezone: true,
                                    })}
                                </div>
                                {signExecutorInfo.inn && (
                                    <div className="pdf__signBlockContent">
                                        ИНН: {signExecutorInfo.inn}
                                    </div>
                                )}
                                <div className="pdf__signBlockContent">
                                    {getUserInfo({ type: 'name', user: signExecutorInfo })}
                                </div>
                                <div className="pdf__signBlockContent">
                                    {signExecutorInfo.signId}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="pdf__signBlock _rules">
                        <div className="pdf__signBlockContent">
                            <b>Доверенность:</b>
                        </div>
                        <div className="pdf__signBlockContent">
                            {signUserInfo?.proxy?.status === 'complete' ? (
                                <>
                                    №{signUserInfo.proxy.number} от{' '}
                                    {getFormatedDate({
                                        date: new Date(signUserInfo.proxy.createdDate),
                                    })}
                                    <br />
                                    Действительна
                                    <br />
                                    до:{' '}
                                    {getFormatedDate({
                                        date: new Date(signUserInfo.proxy.actionDate),
                                    })}
                                </>
                            ) : (
                                <>Не требуется</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sign;

Sign.propTypes = {
    id: PropTypes.string,
    signUserInfo: PropTypes.object,
    signExecutorInfo: PropTypes.object,
};
