import React from 'react';

import CursorActions from '@components/cursorActions/CursorActions.tsx';

import RootI from '../types.ts';

const renderCursorActions: RootI['renderCursorActions'] = function () {
    return (
        <>
            <CursorActions />
        </>
    );
};

export default renderCursorActions;
