const executorsLimits = {
    colsOrder: ['name', 'limit', 'remains', 'monthLimit', 'monthRemains'],
    cols: {
        name: {
            content: 'ФИО исполнителя',
            width: 28,
        },
        limit: {
            content: 'Общий лимит',
            width: 18,
        },
        remains: {
            content: 'Остаток',
            width: 18,
        },
        monthLimit: {
            content: 'Лимит в месяц',
            width: 18,
        },
        monthRemains: {
            content: 'Остаток в месяц',
            width: 18,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'executor-limit',
    emptyInfo: {
        title: 'Исполнителей пока нет',
        description: 'Они будут отображаться здесь<br/>после создания',
    },
} as const;

export default executorsLimits;
