import WidgetWrapperI from '../types.ts';

const currentsHandler: WidgetWrapperI['currentsHandler'] = function (
    this: WidgetWrapperI,
    id,
    currentItem,
    onlyone,
) {
    let resultItem = currentItem;

    this.setState(
        (state) => {
            const newState = { ...state };
            let currents = [...(newState.currents || [])];

            if (Array.isArray(id)) {
                currents = id;
            } else {
                const index = currents.findIndex((item) => item.id === id);

                if (index === -1) {
                    if (onlyone === true) {
                        currents = [];
                    }

                    currents.push({ id, model: currentItem });
                } else {
                    currents.splice(index, 1);

                    resultItem = null;
                }
            }

            newState.currents = currents;

            return newState;
        },
        () => {
            if (this.currentsHandlerCallback) {
                this.currentsHandlerCallback(id, resultItem);
            }
        },
    );
};

export default currentsHandler;
