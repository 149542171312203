import React from 'react';

import CityT from '@global/models/City.ts';

import { RenderColsT } from '../../types.ts';

const renderCities: RenderColsT<'cities'> = function ({ name: colName, item }) {
    let content;
    let className;
    const city = item as CityT;

    if (colName === 'name') {
        content = <>{city.name}</>;
    }

    if (colName === 'region') {
        content = <>{city.region}</>;
    }

    return { content, className };
};

export default renderCities;
