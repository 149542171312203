import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { regs } from '../functions/inputValidate';
import getDateFromString from '../functions/getDateFromString';
import getFormatedNumber from '../functions/getFormatedNumber.ts';

class InputMask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
        };

        this.handlerInput = this.handlerInput.bind(this);
        this.getCursorPosition = this.getCursorPosition.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setValidate = this.setValidate.bind(this);
        this.getPositionsDel = this.getPositionsDel.bind(this);
        this.getCurrentRegs = this.getCurrentRegs.bind(this);
        this.handlerValue = this.handlerValue.bind(this);
        this.getLenSupport = this.getLenSupport.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.handlerEvents = this.handlerEvents.bind(this);

        this.input = React.createRef();
    }

    _focus = false;

    typesOfRegs = [
        {
            items: [
                'date',
                'rate',
                'year',
                'sts',
                'time',
                'numberOfCard',
                'passportSeries',
                'passportNumber',
                'passportCode',
                'numberCard',
                'dateCard',
                'cvvCard',
                'year',
            ],
            key: 'number',
        },
        {
            items: ['phone'],
            key: 'number',
        },
        { items: ['vin'], key: 'vin' },
    ];

    getCurrentRegs() {
        const { validate, reg } = this.props;
        const current =
            reg || this.typesOfRegs.find((type) => type.items.indexOf(validate) !== -1).key;

        return regs[current];
    }

    handlerValue(valRes) {
        const { validate, support } = this.props;

        let val = valRes;

        if (support) {
            val = val.slice(support.content.length);
        }

        switch (validate) {
            case 'vin':
                val = val.toUpperCase();
                break;
            default:
                break;
        }

        return val;
    }

    getLenSupport() {
        const { support } = this.props;

        const lenSup = support ? support.content.length : 0;

        return lenSup;
    }

    getCursorPosition() {
        let caretPos = 0;
        const input = this.input.current;

        if (document.selection) {
            input.focus();
            const sel = document.selection.createRange();
            sel.moveStart('character', -input.value.length);
            caretPos = sel.text.length;
        } else if (input.selectionStart || input.selectionStart === '0') {
            caretPos = input.selectionStart;
        }
        return caretPos - this.getLenSupport() < 0 ? 0 : caretPos - this.getLenSupport();
    }

    // eslint-disable-next-line
    replaceSymbols(str, index, deleteCount, symb) {
        const newStr = str.split('');

        newStr.splice(index, deleteCount, symb);

        return newStr.join('');
    }

    setValue(valRes, start = 0, str, isDelete) {
        let val = valRes;

        return new Promise((resolve) => {
            const { callback, template, charDel, charEmpty, name, support } = this.props;
            let breakLen = start + str.length;

            if (isDelete !== null) {
                breakLen = start - (val.length - isDelete.length);

                for (let i = start - (val.length - isDelete.length); i < start; i++) {
                    if (this.getPositionsDel().indexOf(i) !== -1) {
                        val = this.replaceSymbols(val, i, 1, charDel);
                    } else {
                        val = this.replaceSymbols(val, i, 1, charEmpty);
                    }
                }
            } else {
                for (let i = start; i < breakLen; i++) {
                    if (this.getPositionsDel().indexOf(i) !== -1) {
                        breakLen += 1;

                        for (let j = breakLen - 1; j >= i + 1; j--) {
                            val = this.replaceSymbols(val, j, 1, val[j - 1]);
                        }

                        val = this.replaceSymbols(val, i, 1, charDel);
                    }
                }
            }

            const valArr = val.split('');

            template.split('').forEach((item, key) => {
                if (item !== charDel && item !== charEmpty) {
                    valArr[key] = item;
                }
            });

            val = valArr.join('');

            val = this.setValidate(val.slice(0, template.length));

            if (support) {
                val = `${support.content}${val}`;
            }

            callback({ name, value: val, action: 'change' }).then(() => {
                let firstSymbIndex = this.getFirstCharEmptyIndex();

                if (this.input.current) {
                    const resultPos = breakLen + this.getLenSupport();

                    firstSymbIndex = resultPos < firstSymbIndex ? firstSymbIndex - resultPos : 0;

                    this.input.current.setSelectionRange(
                        resultPos + firstSymbIndex,
                        resultPos + firstSymbIndex,
                    );
                }

                resolve(val);
            });
        });
    }

    getPositionsDel() {
        const { template, charDel } = this.props;
        const arr = [];

        for (let i = 0; i < template.length; i++) {
            if (template[i] === charDel) {
                arr.push(i);
            }
        }

        return arr;
    }

    getFirstCharEmptyIndex() {
        const { template, charEmpty } = this.props;
        let firstSymbIndex = -1;

        template.split('').forEach((item, key) => {
            if (item === charEmpty && firstSymbIndex === -1) {
                firstSymbIndex = key;
            }
        });

        if (firstSymbIndex === -1) {
            firstSymbIndex = 0;
        }

        return firstSymbIndex;
    }

    handlerInput(action, val) {
        const { callback, template, handlerComplete, name, support, isShowTemplate, validate } =
            this.props;
        let { value } = this.props;

        if (support) {
            value = value.slice(support.content.length);
        }
        switch (action) {
            case 'change':
                {
                    const lenVal = val.length - template.length;
                    const start = this.getCursorPosition() - lenVal;
                    let valWithOutTemp = val
                        .slice(start, start + lenVal)
                        .replace(this.getCurrentRegs(), '');
                    let valWas = ``;
                    let isDelete = null;

                    for (let i = 0; i < template.length; i++) {
                        if (i >= start && i < start + valWithOutTemp.length) {
                            if (i === 2 && validate === 'phone' && valWithOutTemp.length === 11) {
                                valWas += '';
                            } else {
                                valWas += valWithOutTemp[i - start];
                            }
                        } else {
                            valWas += value[i];
                        }
                    }

                    if (validate === 'phone' && valWithOutTemp.length === 11) {
                        valWithOutTemp = valWithOutTemp.slice(1);
                    }

                    isDelete = valWas.length > val.length ? val : null;

                    this.setValue(valWas, start, valWithOutTemp, isDelete).then((valInner) => {
                        if (this.checkComplete(valInner) === true) {
                            if (handlerComplete && typeof handlerComplete === 'function') {
                                handlerComplete({ name, value: this.getNeedFormat(valInner) });
                            }
                        }
                    });
                }
                break;
            case 'focus':
                callback({ name, action: 'focus' });

                if (this._focus === false) {
                    this._focus = true;
                    this.input.current.focus();

                    if (!value) {
                        let valueInner;

                        if (support) {
                            valueInner = `${support.content}${template}`;
                        } else {
                            valueInner = template;
                        }

                        const firstSymbIndex = this.getFirstCharEmptyIndex();

                        callback({ name, value: valueInner, action: 'change' }).then(() => {
                            this.input.current.setSelectionRange(
                                this.getLenSupport() + firstSymbIndex,
                                this.getLenSupport() + firstSymbIndex,
                            );
                        });
                    } else {
                        this.input.current.setSelectionRange(0, 0);
                    }

                    this.setState((state) => {
                        const newState = { ...state };

                        newState.isFocus = true;

                        return newState;
                    });
                }
                break;
            case 'blur':
                callback({ name, action: 'blur' });
                this._focus = false;
                if (isShowTemplate !== true && value === template) {
                    callback({ name, value: ``, action: 'change', type: 'blur' }).then(() => {
                        if (handlerComplete && typeof handlerComplete === 'function') {
                            handlerComplete({ name, value: null });
                        }
                    });
                }

                this.setState((state) => {
                    const newState = { ...state };

                    newState.isFocus = false;

                    return newState;
                });
                break;
            default:
                break;
        }
    }

    checkComplete(val) {
        const { charEmpty } = this.props;

        return val.indexOf(charEmpty) === -1;
    }

    setValidate(val) {
        const { validate, charDel, max } = this.props;
        const items = val.split(charDel);

        switch (validate) {
            case 'date':
                {
                    const dateObject = getDateFromString({ date: val });

                    if (+items[0] > 31) {
                        items[0] = 31;
                    }
                    if (+items[0] === 0) {
                        items[0] = '01';
                    }
                    if (+items[1] > 12) {
                        items[1] = 12;
                    }
                    if (+items[1] === 0) {
                        items[1] = '01';
                    }
                    if (+items[2] < 1900) {
                        items[2] = 1900;
                    }

                    if (dateObject && max && typeof max === 'object') {
                        if (dateObject.getTime() > max.getTime()) {
                            items[0] = getFormatedNumber(max.getDate());
                            items[1] = getFormatedNumber(max.getMonth() + 1);
                            items[2] = max.getFullYear();
                        }
                    }
                }
                break;
            case 'rate':
                if (+items[0] > 4) {
                    items[0] = 5;
                    items[1] = 0;
                }
                break;
            case 'year':
                if (+items[0] > +new Date().getFullYear()) {
                    items[0] = +new Date().getFullYear();
                }
                if (+items[0] < 1900) {
                    items[0] = 1900;
                }
                break;
            case 'time':
                if (+items[0] > 23) {
                    items[0] = 23;
                }
                if (+items[1] > 59) {
                    items[1] = 59;
                }
                break;
            case 'dateCard':
                {
                    const nowYear = new Date().getFullYear().toString().slice(2, 4);

                    if (+items[0] < 1) {
                        items[0] = '01';
                    }
                    if (+items[0] > 12) {
                        items[0] = '12';
                    }
                    if (+items[1] < +nowYear) {
                        items[1] = nowYear;
                    }
                }
                break;
            default:
                break;
        }
        return items.join(charDel);
    }

    getNeedFormat(val) {
        const { validate, charDel, support } = this.props;
        const items = val.slice(support ? support.content.length : 0).split(charDel);
        const dateReturn = new Date();

        switch (validate) {
            case 'date':
                {
                    const year = items[2].length === 2 ? `20${items[2]}` : items[2];
                    dateReturn.setDate(+items[0]);
                    dateReturn.setMonth(+items[1] - 1);
                    dateReturn.setFullYear(year);
                }
                break;
            default:
                break;
        }
        return dateReturn.toString();
    }

    setFocus(e) {
        const { value, isDisabled } = this.props;

        if (isDisabled) {
            // e.target.blur();
        } else {
            if (this._focus === false && !value && e) {
                e.preventDefault();
                e.stopPropagation();
            }

            this.handlerInput('focus');
        }
    }

    handlerEvents(e) {
        const { name, id } = this.props;
        const { detail } = e;

        if (detail) {
            const { action } = detail;
            const nameComeEvent = detail.name;

            if (nameComeEvent === (id || name)) {
                switch (action) {
                    case 'focus':
                        this.setFocus();
                        break;
                    default:
                        break;
                }
            }
        }
    }

    componentDidMount() {
        const { isShowTemplate, value, template } = this.props;

        if (isShowTemplate) {
            this.handlerInput('change', value || template);
        }

        document.addEventListener('focus-input', this.handlerEvents);
    }

    componentWillUnmount() {
        document.removeEventListener('focus-input', this.handlerEvents);
    }

    render() {
        const { isFocus } = this.state;
        const { value, className, isDisabled, template, placeholder, isShowTemplate, id, name } =
            this.props;

        return (
            <input
                id={id}
                className={className}
                ref={this.input}
                type="text"
                data-name={name}
                value={
                    (isShowTemplate === true && isFocus === false && !value && template) || value
                }
                onChange={(e) => this.handlerInput('change', this.handlerValue(e.target.value))}
                readOnly={isDisabled}
                onFocus={this.setFocus}
                onMouseDown={this.setFocus}
                onBlur={() => this.handlerInput('blur')}
                placeholder={placeholder || ''}
                autoComplete="off"
                tabIndex={isDisabled && -1}
            />
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(InputMask);

InputMask.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    callback: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    template: PropTypes.string,
    placeholder: PropTypes.string,
    isShowTemplate: PropTypes.bool,
    validate: PropTypes.string,
    charDel: PropTypes.string,
    charEmpty: PropTypes.string,
    support: PropTypes.string,
    handlerComplete: PropTypes.func,
    reg: PropTypes.string,
    min: PropTypes.object,
    max: PropTypes.object,
};
