import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import { dispatcher } from '@redux/redux';

import Animate from '../../../../../components/Animate.jsx';
import Button from '../../../../../components/Button.jsx';
import Edit from '../../../../../components/Edit.jsx';
import Link from '../../../../../components/Link.jsx';
import Main from '../../../../../components/crm/content/info/Main.jsx';
import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import getHeaders from '../../../../../functions/getHeaders';
import getUpdateFormData from '../../../../../functions/getUpdateFormData';
import setNotification from '../../../../../functions/setNotification';

import Editmode from '../../../../../classes/Editmode';

class ContentBlogInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.changeArticle = this.changeArticle.bind(this);
        this.checkFields = this.checkFields.bind(this);

        this.parent = React.createRef();
    }

    name = 'article';

    orderCards = ['main'];

    editmode = new Editmode({
        context: this,
    });

    cards = {
        main: {
            getChangedProps() {
                return ['title', 'section', 'newSection'];
            },
            getRequiredProps() {
                const { article } = this.props;

                return article?.newSection ? ['title', 'newSection'] : ['title', 'section'];
            },
            files: ['preview', 'newSectionImage'],
            render() {
                const { errors } = this.state;
                const { article, uploadMainFile, isNew, newFiles } = this.props;

                return (
                    <Main
                        article={article}
                        checkEditmode={() => this.checkEditmode('main')}
                        changeArticle={({ ...props }) =>
                            this.changeArticle({ ...props, block: 'main' })
                        }
                        errors={errors.main}
                        uploadMainFile={uploadMainFile}
                        isNew={isNew}
                        newFiles={newFiles}
                    />
                );
            },
        },
    };

    formData = new FormData();

    changeArticle({ block, ...props }) {
        const { changeArticle } = this.props;

        return new Promise((resolve) => {
            changeArticle({ ...props }).then(
                () => {
                    if (props.action === 'change' || !props.action) {
                        this.handlerErrors({ action: 'delete', error: props.name, block });
                    }

                    resolve();
                },
                () => null,
            );
        });
    }

    checkChange() {
        const { newFiles, article, articleSave } = this.props;
        const fields = {};
        let isChange = false;
        const changedProps = this.cards[this.state.editName].getChangedProps.call(this);

        this.cards[this.state.editName].files.forEach((keyFile) => {
            if (newFiles[keyFile]?.isLocal) {
                isChange = true;
            }
        });

        changedProps.forEach((key) => {
            const prop = typeof key === 'object' ? key.name : key;

            if (article[prop] !== articleSave[prop]) {
                fields[prop] = article[prop];

                isChange = true;
            }
        });

        return { isChange, fields };
    }

    checkFields() {
        const { isSuccess, errors } = this.validate({});

        return new Promise((resolve, reject) => {
            if (!isSuccess) {
                this.handlerErrors({ action: 'set', errors });

                reject();
            } else {
                resolve();
            }
        });
    }

    clearForm() {
        this.formData = new FormData();
    }

    handlerEditmode({ editName }) {
        const { article, getFormData, clearFiles, getArticle } = this.props;

        const checkEdit = () =>
            new Promise((resolve, reject) => {
                if (editName) {
                    resolve();
                } else {
                    const requiredProps =
                        this.cards[this.state.editName].getRequiredProps.call(this);
                    const { isSuccess, errors } = this.validate({
                        props: requiredProps,
                        nameProp: this.state.editName,
                    });
                    const { isChange, fields } = this.checkChange();

                    if (!isSuccess) {
                        this.handlerErrors({ action: 'set', errors });

                        reject();
                    } else if (!isChange) {
                        resolve();
                    } else {
                        this.formData.set('id', article._id);
                        this.formData.set('fields', JSON.stringify(fields));

                        this.handlerLoadingBlock(this.state.editName).then(() => {
                            axios
                                .patch(
                                    `${process.env.REACT_APP_API}/article`,
                                    getUpdateFormData(getFormData(), this.formData),
                                    {
                                        headers: getHeaders(),
                                    },
                                )
                                .then(
                                    (res) => {
                                        const { success, data } = res.data;

                                        if (success) {
                                            setNotification({
                                                notification: 'success-update-article',
                                            });

                                            this.clearForm();

                                            clearFiles();

                                            getArticle();

                                            resolve();
                                        } else {
                                            const { message } = data;

                                            if (message === 'Section already create') {
                                                setNotification({
                                                    notification: 'article-section-already-create',
                                                });

                                                this.handlerErrors({
                                                    action: 'add',
                                                    error: 'newSection',
                                                    block: 'main',
                                                });
                                            }
                                        }

                                        this.handlerLoadingBlock(null);
                                    },
                                    () => null,
                                );
                        });
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    componentDidMount() {
        const { articleErrors } = this.props;

        this.setState({ isInit: true });

        if (articleErrors) {
            this.handlerErrors({
                action: 'add',
                errors: articleErrors,
                block: 'main',
            });

            dispatcher({ type: 'articleErrors', data: null });
        }
    }

    componentWillUnmount() {
        const { isNew, backToSave } = this.props;

        if (!isNew) {
            backToSave();
        }
    }

    render() {
        const { editName, isLoadingSave, loadingKey } = this.state;
        const { isNew, article, checkRights } = this.props;

        return (
            <div
                ref={this.parent}
                className={`manualContent _parentForEdits _infoArticle ${editName ? '_edit' : ''}`}
            >
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div className="manualContent__cards _row">
                            {this.orderCards.map((name) => {
                                const card = this.cards[name];

                                return (
                                    <div
                                        className={`manualContent__card _parentForEdit _editBack ${
                                            editName === name ? '_current' : ''
                                        } _${name}`}
                                        key={name}
                                        data-key={name}
                                    >
                                        {!isNew && checkRights() && (
                                            <Edit
                                                name={name}
                                                className="manualContent__cardEdit"
                                                editName={editName}
                                                handlerEditmode={this.handlerEditmode}
                                                isLoader={loadingKey === name}
                                            />
                                        )}

                                        {card.render.call(this)}
                                    </div>
                                );
                            })}
                        </div>

                        <Animate
                            className="manualContent__actions"
                            isShow={isNew && article?.section !== 'smi'}
                        >
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="content-info-main"
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName="content-info-inner-content"
                                    prevPromise={this.checkFields}
                                    ids={{ 3: 'new' }}
                                >
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        showLoader={isLoadingSave}
                                        icon={{
                                            type: 'end',
                                            name: 'arrow-next',
                                        }}
                                    >
                                        Далее
                                    </Button>
                                </Link>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
        articleErrors: state.articleErrors,
    };
}

export default connect(mapStateToProps)(ContentBlogInnerMain);

ContentBlogInnerMain.propTypes = {
    newFiles: PropTypes.object,
    serverData: PropTypes.object,
    article: PropTypes.object,
    articleSave: PropTypes.object,
    isInit: PropTypes.bool,
    isNew: PropTypes.bool,
    backToSave: PropTypes.func,
    changeArticle: PropTypes.func,
    uploadMainFile: PropTypes.func,
    getFormData: PropTypes.func,
    clearFiles: PropTypes.func,
    checkRights: PropTypes.func,
    articleErrors: PropTypes.array,
    getArticle: PropTypes.func,
};
