import { ModelsT } from '../models';

const tags: ModelsT = {
    url: 'tag',
    logName: 'tag',
    title: 'Справочник',
    parentPage: 'manual-tags',
    tablePage: 'manual-tags-main',
    tableName: 'tags',
    modelPage: 'manual-tags-inner',
    deleteAlertPopup: 'deleteTag',
    descriptions: {
        table: 'Теги',
        model: {
            new: 'Новый тег',
            default: 'Тег',
        },
    },
    addButton: 'Добавить тег',
    cardName: 'tags',
};

export default tags;
