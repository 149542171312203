import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { ExecutorM } from '@global/models/Executor.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const deleteWallet: I['deleteWallet'] = async function (id) {
    const model = this.state.model!;

    const body = {
        id: model._id,
        fields: {
            wallet: {
                _id: id,
                delete: true,
            },
        },
    };

    await handlerLoading.call(this, 'save');

    try {
        const response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: ExecutorM['wallets'][number];
                    error?: { name: string; text: string };
                    details?: string[];
                }>;
            }
        >(`${process.env.REACT_APP_API}/executor`, { ...body }, { headers: getHeaders() });
        const { success, data } = response.data;

        if (success) {
            const { model: resultModel } = data;

            this.updateWallets(resultModel);
        }
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default deleteWallet;
