import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import getModel from './methods/getModel.ts';
import mountHandler from './methods/mountHandler.ts';
import save from './methods/save.ts';
import setHeight from './methods/setHeight.ts';

import ExecutorMvdI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class ExecutorMvd
    extends EditBlock<ExecutorMvdI['props'], ExecutorMvdI['state']>
    implements ExecutorMvdI
{
    constructor(props: ExecutorMvdI['props']) {
        super(props);
        this.state = {};
    }

    ref: ExecutorMvdI['ref'];

    renderContent = renderContent;

    getModel = getModel;
    mountHandler = mountHandler;
    save = save;
    close = close;
    setHeight = setHeight;

    componentDidMount() {
        this.mountHandler();
    }

    render() {
        return (
            <Popup
                name="executorMvdPopup"
                close={this.close.bind(this)}
                checkClose={() =>
                    !document.querySelector('.v2cursorLists__item') &&
                    !document.querySelector('.body__v2popup._calendar') &&
                    !document.querySelector('.v2popup._galeryPopup') &&
                    !document.querySelector('.body__v2popup._alert')
                }
                setRef={(ref) => {
                    this.ref = ref;
                }}
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        executorMvdPopup: state.executorMvdPopup,
    };
}

export default connect(mapStateToProps)(ExecutorMvd);
