import PagesI from '../types.ts'

const checkChangePage: PagesI['checkChangePage'] = function(){
    const { storePages } = this.props;
    const currentPage = this.getPagesOrder(true).find((page) => storePages[page.name].isShow);

    if (currentPage && currentPage.name !== this.state.currentPageName) {
        setTimeout(() => {
            this.setState({ currentPageName: currentPage.name });
        }, 10);
    }
}

export default checkChangePage