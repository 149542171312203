import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { CorporationT } from '@global/models/Corporation.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const generate1CToken: I['generate1CToken'] = async function (this: I) {
    const { model } = this.state;
    const { setModel } = this.props;

    try {
        const response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: CorporationT;
                    error?: { name: string; text: string };
                }>;
            }
        >(
            `${process.env.REACT_APP_API}/corporation`,
            { id: model?._id, action: 'generate-1Ctoken' },
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            const { model: resultModel } = data;

            await this.init({ fields: resultModel });
            await setModel(resultModel);

            return;
        }

        const { error } = data;

        this.setState({ error });
    } catch (error) {}

    return Promise.reject();
};

export default generate1CToken;
