import getBlockParent from './getBlockParent';
import getRangeNodes from './getRangeNodes';

export default function getBlockParents({ startContainer, endContainer, max, withBr }) {
    const editorArea = document.querySelector('.editor__areaInner');
    const rangeNodes = getRangeNodes({ startContainer, endContainer, withBr });
    const blockParents = [];

    rangeNodes.forEach((rangeNode) => {
        const blockParent = getBlockParent({ node: rangeNode, max });

        if (blockParent && !blockParents.includes(blockParent) && blockParent !== editorArea) {
            blockParents.push(blockParent);
        }
    });

    return blockParents;
}
