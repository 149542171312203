import React from 'react';

import CursorSupportI from '../types.ts';

const renderItem: CursorSupportI['renderItem'] = function ({ item }) {
    return (
        <div
            className={`v2cursorSupport__item _${item.dir} ${item.className || ''}`}
            style={
                item.position && {
                    left: `${item.position.left}px`,
                    top: `${item.position.top}px`,
                }
            }
        >
            <div
                className="v2cursorSupport__itemInner"
                dangerouslySetInnerHTML={{ __html: item.content }}
            ></div>
        </div>
    );
};

export default renderItem;
