import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';
import sortAlphabet from '@functions/sortAlphabet.ts';

import I from '../types.ts';

const renderAllCheckbox: I['renderAllCheckbox'] = function () {
    const { sectionKey } = this.state;
    const model = this.state.model!;
    const section = model.sections.find((innerSection) => innerSection.key === sectionKey);
    const { templateSections } = model;
    const templateSection = templateSections.find((item) => item.key === section?.key);
    const checkAll = templateSection?.items.every(
        (item) =>
            item.rules.every((rule) =>
                section?.items
                    .find((innerItem) => innerItem.key === item.key)
                    ?.rules.includes(rule),
            ) &&
            item.actions.every((action) =>
                section?.items
                    .find((innerItem) => innerItem.key === item.key)
                    ?.actions.includes(action.key),
            ),
    );

    return (
        <label className="v2popup__checkbox">
            <div className="v2popup__checkboxBox">
                <Checkbox
                    className="_green"
                    name="roleSectionAll"
                    onChange={() => {
                        if (checkAll) {
                            const fields: Record<any, unknown> = {};

                            section?.items.forEach((item) => {
                                fields[`sections.${section._id}.items.${item._id}.actions`] = [];
                                fields[`sections.${section._id}.items.${item._id}.rules`] = [];
                            });

                            this.change({ ...fields });
                        } else {
                            const fields: Record<any, unknown> = {};

                            section?.items.forEach((item) => {
                                const templateItem = templateSection?.items.find(
                                    (innerItem) => innerItem.key === item.key,
                                );

                                if (templateItem) {
                                    fields[`sections.${section._id}.items.${item._id}.actions`] = [
                                        ...templateItem.actions.map((action) => action.key),
                                    ].sort(sortAlphabet.bind(null, undefined));
                                    fields[`sections.${section._id}.items.${item._id}.rules`] = [
                                        ...templateItem.rules,
                                    ].sort(sortAlphabet.bind(null, undefined));
                                }
                            });

                            this.change({ ...fields });
                        }
                    }}
                    value={!!checkAll}
                >
                    <b>Полные права</b>
                </Checkbox>
            </div>
        </label>
    );
};

export default renderAllCheckbox;
