import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Editor from '../../../../../components/Editor.jsx';

class Inner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeContent = this.changeContent.bind(this);

        this.parent = React.createRef();
    }

    changeContent({ value }) {
        const { changeArticle } = this.props;

        return new Promise((resolve) => {
            changeArticle({ action: 'change', name: 'content', value }).then(() => {
                resolve();
            }, resolve);
        });
    }

    render() {
        const { article, checkRights } = this.props;

        return (
            <div className="manualContent__content">
                <div
                    ref={this.parent}
                    className={`contentBlogInner ${!checkRights() ? '_notRights' : ''}`}
                >
                    <div className="contentBlogInner__content _col">
                        <Editor
                            name="article"
                            title="Контент статьи"
                            content={article?.content || ''}
                            change={this.changeContent}
                            disabled={!checkRights()}
                            support="Введите (или вставьте) текст статьи"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        blogEditPanel: state.blogEditPanel,
        waitAuth: state.waitAuth,
    };
}

export default connect(mapStateToProps)(Inner);

Inner.propTypes = {
    article: PropTypes.object,
    levels: PropTypes.array,
    waitAuth: PropTypes.bool,
    blogEditPanel: PropTypes.object,
    getParent: PropTypes.func,
    checkRights: PropTypes.func,
    name: PropTypes.string,
    changeArticle: PropTypes.func,
};
