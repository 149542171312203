const executorLimits = {
    fieldsOrder: ['month', 'year'],
    fields: {
        month: {
            support: 'Лимит в месяц',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
            max: 500_000,
        },
        year: {
            support: 'Лимит в год',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
            max: 6_000_000,
        },
    },
} as const;

export default executorLimits;
