import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../Button.jsx';
import Icon from '../../../Icon.jsx';

class PublicExecutorsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    bubbles = [
        {
            title: 'Рядом с домом',
            description: 'Подберем удобную работу<br />в городах или районах',
        },
        {
            title: 'Проверенные заказчики',
            description: 'Все партнеры на платформе<br />проверенные компании',
        },
        {
            title: 'Гибкие слоты оказания услуг',
            description: 'Можно выходить на полный<br />день или на подработку',
        },
    ];

    companies = [
        'sber-logo.svg',
        'yandex-market-logo.svg',
        'yandex-eda-logo.svg',
        'yandex-go-logo.svg',
        'whoosh-logo.svg',
        'samokat-logo.svg',
    ];

    render() {
        const { device } = this.props;
        const registrationLink = `${process.env.REACT_APP_APP_DOMEN}/join/livecargo-smz`;

        return (
            <header className="publicExecutorsHeader">
                <div className="publicExecutorsHeader__inner">
                    <div className="publicExecutorsHeader__content _col">
                        <div className="publicExecutorsHeader__contentInfo _col">
                            <h1 className="publicExecutorsHeader__title">
                                Работа курьером для&nbsp;самозанятых
                            </h1>
                            <p className="publicExecutorsHeader__description">
                                Платформа, где самозанятый из сферы логистики найдет подходящий
                                заказ. Скачайте приложение, заполните профиль и приступайте к работе
                            </p>
                            <a
                                className="publicExecutorsHeader__button"
                                href={registrationLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button>Зарегистрироваться</Button>
                            </a>
                        </div>
                        <div className="publicExecutorsHeader__contentMore">
                            <i className="publicExecutorsHeader__contentMoreIcon">
                                <Icon name="info-more" />
                            </i>
                            Узнать
                            <br />
                            больше
                        </div>
                        {this.bubbles.map((bubble, key) => (
                            <div
                                className={`publicExecutorsHeader__contentBubble _${key + 1}`}
                                key={key}
                            >
                                <div className="publicExecutorsHeader__contentBubbleTitle">
                                    {bubble.title}
                                </div>
                                <p
                                    className="publicExecutorsHeader__contentBubbleDescription"
                                    dangerouslySetInnerHTML={{ __html: bubble.description }}
                                ></p>
                            </div>
                        ))}
                    </div>
                    <div className="publicExecutorsHeader__companies _col">
                        <div className="publicExecutorsHeader__companiesTitle">
                            Заказы от <span>лучших компаний</span> на рынке:
                        </div>
                        <div className="publicExecutorsHeader__companiesItems _row">
                            {this.companies.map((company) => (
                                <div className="publicExecutorsHeader__companiesItem" key={company}>
                                    <img
                                        src={require(`../../../../img/crm/public/${company}`)}
                                        alt=""
                                        className="publicExecutorsHeader__companiesItemLogo"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="publicExecutorsHeader__instruction _row">
                        <div className="publicExecutorsHeader__instructionItemsWrapper">
                            <div className="publicExecutorsHeader__instructionItems _row">
                                <div className="publicExecutorsHeader__instructionItem">
                                    <div className="publicExecutorsHeader__instructionStep _col">
                                        <div className="publicExecutorsHeader__instructionStepTitle">
                                            Регистрируйтесь на&nbsp;нашем сайте
                                        </div>
                                        <div className="publicExecutorsHeader__instructionStepDescription">
                                            Это быстро
                                            <br />
                                            и&nbsp;бесплатно
                                        </div>
                                        <a
                                            href={registrationLink}
                                            target="_blank"
                                            className="publicExecutorsHeader__instructionStepLink"
                                            rel="noreferrer"
                                        >
                                            Регистрация
                                            <i className="publicExecutorsHeader__instructionStepLinkIcon">
                                                <Icon name="arrow-next-2" />
                                            </i>
                                        </a>
                                    </div>
                                </div>
                                <div className="publicExecutorsHeader__instructionItem">
                                    <div className="publicExecutorsHeader__instructionStep _col">
                                        <div className="publicExecutorsHeader__instructionStepTitle">
                                            Получите статус Самозанятого
                                        </div>
                                        <div className="publicExecutorsHeader__instructionStepDescription">
                                            Скачайте и&nbsp;зарегистрируйтесь в&nbsp;приложении
                                            «Мой&nbsp;налог»
                                        </div>
                                        <a
                                            href="https://npd.nalog.ru/app/"
                                            target="_blank"
                                            className="publicExecutorsHeader__instructionStepLink"
                                            rel="noreferrer"
                                        >
                                            «Мой налог»
                                            <i className="publicExecutorsHeader__instructionStepLinkIcon">
                                                <Icon name="arrow-next-2" />
                                            </i>
                                        </a>
                                    </div>
                                </div>
                                <div className="publicExecutorsHeader__instructionItem">
                                    <div className="publicExecutorsHeader__instructionStep _col">
                                        <div className="publicExecutorsHeader__instructionStepTitle">
                                            Подберите подходящую вакансию
                                        </div>
                                        <div className="publicExecutorsHeader__instructionStepDescription">
                                            Или обратитесь к&nbsp;менеджеру
                                        </div>
                                    </div>
                                </div>
                                <div className="publicExecutorsHeader__instructionItem">
                                    <div className="publicExecutorsHeader__instructionStep _col">
                                        <div className="publicExecutorsHeader__instructionStepTitle">
                                            Заключите договор
                                        </div>
                                        <div className="publicExecutorsHeader__instructionStepDescription">
                                            И дождитесь подтверждения от&nbsp;заказчика
                                        </div>
                                    </div>
                                </div>
                                <div className="publicExecutorsHeader__instructionItem">
                                    <div className="publicExecutorsHeader__instructionStep _col">
                                        <div className="publicExecutorsHeader__instructionStepTitle">
                                            Выполняйте задания
                                        </div>
                                        <div className="publicExecutorsHeader__instructionStepDescription">
                                            И получайте вознаграждение
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="publicExecutorsHeader__instructionItem _info">
                            <div className="publicExecutorsHeader__instructionTitle">
                                Как заработать первые деньги?
                            </div>
                            <p className="publicExecutorsHeader__instructionDescription">
                                {device === 'mobile' ? (
                                    <>
                                        Всего 5 шагов —<br />
                                        листайте вправо
                                    </>
                                ) : (
                                    <>Всего 5 шагов</>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
        device: state.device,
    };
}

export default connect(mapStateToProps)(PublicExecutorsHeader);

PublicExecutorsHeader.propTypes = {
    windowIsReady: PropTypes.bool,
    device: PropTypes.string,
};
