import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import Pages from '@components/pages/Pages.tsx';
import { StoreT } from '@global/types.ts';

import Logs from './components/Logs/Logs.tsx';
import Main from './components/Main/Main.tsx';

import getActions from './methods/getActions.ts';
import getQuery from './methods/getQuery.ts';

import ModelsPageInnerI from './types.ts';

import deleteModel from './actions/deleteModel';

class ModelsPageInner
    extends WidgetWrapper<ModelsPageInnerI['props'], ModelsPageInnerI['state']>
    implements ModelsPageInnerI
{
    parent: ModelsPageInnerI['parent'];
    url: ModelsPageInnerI['url'];
    mainPageName: ModelsPageInnerI['mainPageName'];

    constructor(props: ModelsPageInnerI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    deleteModel = deleteModel;

    getActions = getActions;
    getQuery = getQuery;

    componentDidMount(): void {
        const { config, type } = this.props;

        this.url = config.url;
        this.mainPageName = config.tablePage;

        this.getModel();

        this.setState({
            pages: {
                [`${config.modelPage}-main`]: {
                    render(this: ModelsPageInnerI) {
                        const { model } = this.state;

                        return (
                            <>
                                <Main
                                    type={type}
                                    config={config}
                                    model={model!}
                                    setModel={this.setModel.bind(this)}
                                />
                            </>
                        );
                    },
                },
                [`${config.modelPage}-logs`]: {
                    render(this: ModelsPageInnerI) {
                        const { model } = this.state;

                        return (
                            <>
                                <Logs config={config} id={model?._id} />
                            </>
                        );
                    },
                },
            },
        });
    }

    render() {
        const { model, pages } = this.state;
        const { config } = this.props;

        return (
            <>
                <Widget
                    name={config.tableName}
                    title={config.title}
                    descriptionInner={
                        model?.new
                            ? config.descriptions.model.new
                            : config.descriptions.model.default
                    }
                    actions={this.getActions!()}
                    isFull={true}
                >
                    <LoaderBlock
                        className="v2widget__contentLoader"
                        isShow={!model}
                        loaderClassName="_main"
                    />
                    <div className={`v2widget__contentWait ${model ? '_show' : ''}`}>
                        {model && pages && (
                            <Pages
                                className="body__innerPages _ITEMS"
                                classNamePage="body__innerPage _ITEM"
                                filter={(page) => page.parentName === config.modelPage}
                                pages={pages}
                                context={this}
                            />
                        )}
                    </div>
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(ModelsPageInner);
