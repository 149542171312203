import React from 'react';

import Calendar from '@components/calendar/Calendar';
import EditBlockI from '@components/editBlock/types';
import { FilterBlockT } from '@global/types';

import FilterI from '../../types';

type ParamsT = {
    change: EditBlockI['change'];
    index: number;
    updatedKey: FilterBlockT['clearedKey'];
    init: FilterI['setSizeInit'];
} & Pick<FilterBlockT, 'currentDate' | 'doubleCurrentDate'>;

export default function Date(props: ParamsT): React.ReactNode {
    const { currentDate, doubleCurrentDate, change, index, updatedKey, init } = props;

    return (
        <div className="v2filter__blockDate">
            <Calendar
                startDate={currentDate}
                startDoubleDate={doubleCurrentDate}
                inFilter={true}
                momentCallback={({ ...thisProps }) => {
                    const resultProps: Record<any, unknown> = {};

                    Object.keys(thisProps).forEach((key) => {
                        resultProps[`${index}.${key}`] = thisProps[key];
                    });

                    change({ ...resultProps });
                }}
                isDouble={true}
                withPast={true}
                updatedKey={updatedKey}
                init={init}
            />
        </div>
    );
}
