import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';

import setSpacesInText from '../../../functions/setSpacesInText';
import getArticles from '../../../requests/getArticles';

import getFormatedDate from '../../../functions/getFormatedDate';
import Loader from '../../Loader.jsx';
import Animate from '../../Animate.jsx';
import readNotificationsNews from '../../../functions/crm/readNotificationsNews';

class NewsInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderHead() {
        const { article } = this.state;
        const { setCurrentPage } = this.props;

        return (
            <>
                <div
                    className="notificationsBar__newsBack _row _click"
                    onClick={() => setCurrentPage('news')}
                >
                    <i className="notificationsBar__newsBackIcon">
                        <Icon name="arrow-prev" />
                    </i>
                    Назад
                </div>
                <div className="notificationsBar__newsType">{article?.sectionText}</div>
                <div
                    className="notificationsBar__newsTitle"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(article?.title) }}
                ></div>
                <div className="notificationsBar__newsInfo _row">
                    <p className="notificationsBar__newsDate">
                        {getFormatedDate({
                            date: new Date(article?.createdDate),
                            type: 'textYear',
                        })}
                    </p>
                    <div className="notificationsBar__newsView _row">
                        <i className="notificationsBar__newsViewIcon">
                            <Icon name="blog-view" />
                        </i>
                        {article?.viewCounter}
                    </div>
                </div>
            </>
        );
    }

    renderFoot() {
        return (
            <div className="notificationsBar__newsFoot _row">
                <img
                    src={require('../../../img/emodzi/victory-hand.png')}
                    alt=""
                    className="notificationsBar__newsFootIcon"
                />
                Команда CRM LIVECARGO
            </div>
        );
    }

    checkContent(content) {
        const div = document.createElement('div');

        div.innerHTML = content;

        div.querySelectorAll('div').forEach((innerDiv) => {
            if (
                innerDiv.innerText?.replace(/\u200b/gi, '')?.trim().length === 0 &&
                !innerDiv.querySelector('img')
            ) {
                innerDiv.classList.add('_empty');
            }
        });

        return div.innerHTML;
    }

    getArticle() {
        const { id } = this.props;

        getArticles('announce', { id }).then(({ article }) => {
            if (article) {
                article.content = this.checkContent(article.content);
            }

            this.setState({ article }, () => {
                if (!article.isRead) {
                    setTimeout(() => {
                        readNotificationsNews(id);
                    }, 500);
                }
            });
        });
    }

    componentDidMount() {
        this.getArticle();
    }

    render() {
        const { article } = this.state;

        return (
            <>
                <div ref={this.parent} className="widget__blockInner">
                    <div className={`notificationsBar__news ${article ? '_ready' : ''}`}>
                        <Animate className="notificationsBar__newsLoader _loader" isShow={!article}>
                            <div className="notificationsBar__newsLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <div className="notificationsBar__newsInner _col">
                            {this.renderHead()}
                            <div className="notificationsBar__newsContent">
                                <div
                                    className="notificationsBar__newsContentInner"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(article?.content),
                                    }}
                                ></div>
                            </div>
                            {this.renderFoot()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NewsInner);

NewsInner.propTypes = {
    id: PropTypes.string,
    setCurrentPage: PropTypes.func,
};
