import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import checkValueOfEmpty from '../functions/checkValueOfEmpty';

class AnimateChangeUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getItems = this.getItems.bind(this);
        this.renderItem = this.renderItem.bind(this);

        this.parent = React.createRef();
    }

    getItems() {
        const { renderKey } = this.props;

        this.childrens[encodeURIComponent(renderKey)] = this.props.children;

        return checkValueOfEmpty(renderKey, true) ? [{ key: encodeURIComponent(renderKey) }] : [];
    }

    childrens = {};

    renderItem({ prop }) {
        const { className = '' } = this.props;

        return (
            <div className={`animateChangeUp__item _child ${className}`}>
                {this.childrens[prop]}
            </div>
        );
    }

    getRenderKey() {
        const { renderKey, renderListKey } = this.props;

        return `${renderKey}${renderListKey}`;
    }

    render() {
        const {
            prop = 'key',
            className = '',
            isNotParams = false,
            withoutParams,
            parentStyles = ['width', 'height'],
            callback,
            name,
            offsetWidth,
            isNotNullParentSize,
            paramsParent = { width: 'auto', height: 'auto' },
            hasRenderKey,
            isSmoothShow,
        } = this.props;

        return (
            <ListAbsoluteMain
                className={`animateChangeUp ${className} _parent`}
                items={this.getItems()}
                renderItem={this.renderItem}
                classNameItem={`animateChangeUp__item ${className}`}
                prop={prop}
                styles={parentStyles}
                paramsParent={paramsParent}
                defaultLeft={null}
                keyRender={this.getRenderKey()}
                isNotParams={isNotParams}
                isNotParamsItem={true}
                withoutParams={withoutParams}
                callback={callback}
                name={name}
                offsetWidth={offsetWidth}
                isNotNullParentSize={isNotNullParentSize}
                hasRenderKey={hasRenderKey}
                isSmoothShow={isSmoothShow}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsLoad: state.windowIsLoad,
    };
}

export default connect(mapStateToProps)(AnimateChangeUp);

AnimateChangeUp.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    prop: PropTypes.string,
    renderKey: PropTypes.any,
    isNotParams: PropTypes.bool,
    withoutParams: PropTypes.bool,
    parentStyles: PropTypes.array,
    callback: PropTypes.func,
    name: PropTypes.string,
    offsetWidth: PropTypes.number,
    isNotNullParentSize: PropTypes.bool,
    paramsParent: PropTypes.object,
    hasRenderKey: PropTypes.bool,
    isSmoothShow: PropTypes.bool,
    renderListKey: PropTypes.any,
};
