import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import DocT from '@global/models/Doc.ts';

import addArrayItem from './methods/addArrayItem.ts';
import addHandler from './methods/addHandler.ts';
import change from './methods/change.ts';
import deleteFilesHandler from './methods/deleteFilesHandler.ts';
import deleteHandler from './methods/deleteHandler.ts';

import WidgetDocsI from './types.ts';

import renderDoc from './renders/renderDoc.tsx';

class WidgetDocs
    extends React.Component<WidgetDocsI['props'], WidgetDocsI['state']>
    implements WidgetDocsI
{
    parent: WidgetDocsI['parent'];

    constructor(props: WidgetDocsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderDoc = renderDoc;

    change = change;
    addArrayItem = addArrayItem;
    addHandler = addHandler;
    deleteHandler = deleteHandler;
    deleteFilesHandler = deleteFilesHandler;

    render() {
        const { updatedItemKey } = this.state;
        const { docs, editFiles, checkAddDocs, updatedKey } = this.props;
        const resultUpdatedKey = `${updatedKey}${updatedItemKey}`;

        if (editFiles) {
            return (
                <div ref={this.parent} className="v2widgetDocs _editFiles">
                    <div className="v2widgetDocs__inner">
                        <div className="v2widgetDocs__items">
                            {this.renderDoc({ item: docs[0] })}
                        </div>
                    </div>
                </div>
            );
        }

        const resultDocs = [...docs!];

        if (checkAddDocs && checkAddDocs()) {
            resultDocs.push({ _id: 'add' } as DocT);
        }

        return (
            <div ref={this.parent} className="v2widgetDocs">
                <div className="v2widgetDocs__inner">
                    <ListAbsoluteMain
                        className="v2widgetDocs__items _ITEMS"
                        items={resultDocs}
                        renderItem={this.renderDoc.bind(this)}
                        classNameItem="v2widgetDocs__item"
                        prop="_id"
                        paramsParent={{ width: 'auto' }}
                        styles={['width']}
                        keyRender={resultUpdatedKey}
                        keyUpdateItem={resultUpdatedKey}
                        listId="corporation-docs"
                    />
                </div>
            </div>
        );
    }
}

export default WidgetDocs;
