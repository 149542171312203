import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import getCommission from './methods/getCommission.ts';
import mountHandler from './methods/mountHandler.ts';
import save from './methods/save.ts';

import PayEditI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class PayEdit extends EditBlock<PayEditI['props'], PayEditI['state']> implements PayEditI {
    constructor(props: PayEditI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;

    mountHandler = mountHandler;
    save = save;
    close = close;
    getCommission = getCommission;

    componentDidMount() {
        this.mountHandler();
    }

    render() {
        return (
            <Popup
                name="payPopup"
                close={this.close.bind(this)}
                checkClose={() =>
                    !document.querySelector('.v2cursorLists__item') &&
                    !document.querySelector('.body__v2popup._calendar') &&
                    !document.querySelector('.body__v2popup._alert')
                }
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        payPopup: state.payPopup,
    };
}

export default connect(mapStateToProps)(PayEdit);
