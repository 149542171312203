import React from 'react';
import { connect } from 'react-redux';

import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import getActions from './methods/getActions.ts';
import getInfo from './methods/getInfo.ts';

import LogsPopupI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class LogsPopup
    extends WidgetWrapper<LogsPopupI['props'], LogsPopupI['state']>
    implements LogsPopupI
{
    parent: LogsPopupI['parent'];

    constructor(props: LogsPopupI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    close = close;
    getInfo = getInfo;
    getActions = getActions;

    renderContent = renderContent;

    componentDidMount(): void {
        const { logsPopup } = this.props;
        const { modelName, modelId, models } = logsPopup;

        this.setState({ modelName, modelId, models });
    }

    render() {
        return (
            <>
                <Popup
                    name="logsPopup"
                    close={this.close.bind(this)}
                    checkClose={() => !document.querySelector('.v2popup._filterPopup')}
                >
                    <>{this.renderContent()}</>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        logsPopup: state.logsPopup,
    };
}

export default connect(mapStateToProps)(LogsPopup);
