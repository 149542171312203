import React from 'react';

import Input from '@components/input/Input.tsx';

import I from '../types.ts';

const renderInput: I['renderInput'] = function () {
    const { loadingKey } = this.state;
    const {
        model,
        name,
        prop,
        onChange,
        reg,
        regExp,
        concat,
        disabled,
        max,
        isAmount,
        cardName,
        textarea,
        inputSupport,
        readOnly,
    } = this.props;

    const value = this.props.value as string;

    return (
        <>
            <div className="v2widgetField__field">
                <Input
                    name={name}
                    value={value || ''}
                    onChange={async ({ value: resultValue }, e) => {
                        onChange({ [prop]: resultValue });

                        if (e) {
                            this.cityListHandler({ value: resultValue as string, e });
                        }

                        this.asyncInputHandler(resultValue as string);
                    }}
                    support={inputSupport || '–'}
                    reg={reg}
                    regExp={regExp}
                    concat={concat}
                    disabled={disabled || !!loadingKey}
                    readOnly={readOnly}
                    max={max}
                    id={cardName}
                    isAmount={isAmount}
                    updatedKey={model?.UPDATED_KEY}
                    textarea={textarea}
                />
            </div>
        </>
    );
};

export default renderInput;
