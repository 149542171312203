import ManualTableI from '../types.ts';

import { TableT } from '../static/tables.ts';

const getOtherQuery: ManualTableI['getOtherQuery'] = function (data) {
    const { name, otherData } = this.props;
    const table = this.tables[name] as TableT;
    const query = [];

    if (table.query) {
        query.push(...table.query);
    }

    if (name === 'modelLogs') {
        if (otherData) {
            const { modelName, modelId, models } = otherData as {
                modelName: string;
                modelId: string;
                models: { name: string; id: string }[];
            };

            if (models) {
                query.push(
                    ...models.map((model) => ({
                        key: 'models',
                        value: `${model.name}=${model.id}`,
                    })),
                );
            } else {
                query.push({ key: 'modelName', value: modelName });
                query.push({ key: 'modelId', value: modelId });
            }
        }
    }

    query.push(...(this.props.getQuery?.(data) || []));

    return query;
};

export default getOtherQuery;
