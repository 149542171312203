import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { model, loadingKey, error, innerHeight } = this.state;

    return (
        <>
            <div className="v2popup__head _COL">
                <div className="v2popup__title">Уведомление МВД</div>
                <div className={`v2popup__description ${!model ? '_hidden' : ''}`}>
                    На исполнителя {model?.fullName || '–'}
                </div>
                <div className="v2popup__close _CLICK _top" onClick={this.close.bind(this)}>
                    <Icon name="popup-close" />
                </div>
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <div
                    className="v2popup__contentWrapper"
                    style={{ height: `${model ? innerHeight : 250}px` }}
                >
                    <LoaderBlock
                        className="v2popup__contentLoader"
                        isShow={!model}
                        loaderClassName="_main"
                    />
                    <div className={`v2popup__contentInner ${!model ? '_hide' : ''}`}>
                        {model && (
                            <WidgetCard
                                name="executorMvd"
                                model={model}
                                change={this.change.bind(this)}
                                getValue={this.getValue.bind(this)}
                                withHead={false}
                                className="_empty"
                                error={error}
                                addArrayItem={this.addArrayItem.bind(this)}
                                deleteArrayItem={this.deleteArrayItem.bind(this)}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _ROW">
                        <div className="v2popupFoot__button _full">
                            <Button
                                className="_main"
                                onClick={this.save.bind(this)}
                                loading={loadingKey === 'save'}
                                disabled={!this.checkChange().isChange}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                    <AnimateChange
                        className="v2popupFoot__error"
                        renderKey={error?.text}
                        styles={['height']}
                        paramsParent={{ width: true, height: 'auto' }}
                    >
                        <>{error?.text}</>
                    </AnimateChange>
                </div>
            </div>
        </>
    );
};

export default renderContent;
