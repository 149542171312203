import AlertI from '../../types';

const deletePayGroup = {
    title: 'Подтвердите удаление реестра',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить реестр <b>${info}</b>?`;
    },
    buttonText: 'Удалить реестр',
} as const;

export default deletePayGroup;
