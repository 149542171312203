import React from 'react';

import I from '../types.ts';

const renderInput: I['renderInput'] = function (key) {
    const { inputs } = this.state;

    return (
        <div className="v2codeInputs__item" key={key} data-key={key}>
            <input
                type="text"
                className="v2codeInputs__itemInput"
                onChange={(e) => {
                    this.inputHandler({ key, value: e.target.value });
                }}
                value={inputs[key]}
            />
        </div>
    );
};

export default renderInput;
