import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import Interface from '../types.ts';

const getMoreActions: Interface['getMoreActions'] = function ({ role }) {
    const actions: CursorActionsItemT[] = [
        {
            key: 'edit',
            name: 'edit',
            text: 'Редактировать',
            onClick: async () => {
                handlerPopup('rolePopup', {
                    isShow: true,
                    role,
                });
            },
        },
    ];

    return actions;
};

export default getMoreActions;
