import I from '../types.ts';

const getPosition: I['getPosition'] = function (e) {
    const { imageLeft, imageTop } = this.state;
    const pageX = e.pageX - imageLeft!;
    const pageY = e.pageY - imageTop!;

    return { x: pageX, y: pageY };
};

export default getPosition;
