import axios from 'axios';

import getDateFromString from '@functions/getDateFromString.js';
import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (force) {
    const model = this.state.model!;
    const type = model.type;

    const fields: Record<any, unknown> = {
        scriptId: model.scriptId,
        type,
        executorId: model.executorId,
    };

    if (model.createdDate) {
        fields.createdDate = getDateFromString({ date: model.createdDate });
    }

    if (model.actionDate) {
        fields.actionDate = getDateFromString({ date: model.actionDate });
    }

    await handlerLoading.call(this, 'save');

    const response = await axios.post<
        {},
        { data: ReqResponseT<{ message: string; error?: { name: string; text: string } }> }
    >(
        `${process.env.REACT_APP_API}/join-contract`,
        { fields, force: force === true },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        this.close();

        document.dispatchEvent(
            new CustomEvent('refreshTable', { detail: { name: 'joinsContracts' } }),
        );
    } else {
        const { message, error } = data;

        if (error) {
            this.setState({ error });
        }

        if (message === 'Contract already create') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'contractAlreadyCreate',
                important: true,
                info: {
                    fullName: model.executorName,
                    contract: model.executorContract,
                    type,
                },
                callback: save.bind(this, true),
            });
        } else if (message === 'Contract have mvd') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'contractHasMvd',
                important: true,
                info: {
                    fullName: model.executorName,
                    contract: model.executorContract,
                    type,
                },
                callback: save.bind(this, true),
            });
        } else if (message === 'Corporation not verify') {
            handlerPopup('verificationPopup', {
                isShow: true,
                callback: this.close.bind(this),
            });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
