import React from 'react';
import { Provider, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';

import { store } from '../redux/redux';

import Icon from './Icon.jsx';

import handlerSupportCursor from '../functions/handlerSupportCursor';
import Table from './editor/Table.jsx';
import Link from './editor/Link.jsx';
import Image from './editor/Image.jsx';
import Color from './editor/Color.jsx';
import Title from './editor/Title.jsx';

import getSelection from '../functions/crm/editor/getSelection';
import checkClosestParent from '../functions/crm/editor/checkClosestParent';
import getRangeNodes from '../functions/crm/editor/getRangeNodes';
import getAllNodes from '../functions/crm/editor/getAllNodes';

import handlerInlineNode from '../functions/crm/editor/handlerInlineNode';
import handlerInsertTag from '../functions/crm/editor/handlerInsertTag';
import getBlockParents from '../functions/crm/editor/getBlockParents';
import getBlockParent from '../functions/crm/editor/getBlockParent';
import clearContent from '../functions/crm/editor/clearContent';
import Doc from './editor/Doc.jsx';

const actionsInfo = require('../infos/crm/editor.json');

class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerPaste = this.handlerPaste.bind(this);
        this.handlerKeyDown = this.handlerKeyDown.bind(this);
        this.handlerKeyUp = this.handlerKeyUp.bind(this);
        this.saveSelection = this.saveSelection.bind(this);
        this.handlerContent = this.handlerContent.bind(this);
        this.renderGap = this.renderGap.bind(this);
        this.setHandlerGaps = this.setHandlerGaps.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    getArea() {
        return this.parent.current.querySelector('.editor__areaInner');
    }

    renderGap() {
        return renderToStaticMarkup(
            <Provider store={store}>
                <div className="editor__gap _gap" contentEditable={false}>
                    <div className="editor__gapInner _col">
                        <div className="editor__gapContent">Разрыв страницы</div>
                        <div className="editor__gapDelete _row _click">
                            Удалить
                            <i className="editor__gapDeleteIcon">
                                <Icon name="actions-delete" />
                            </i>
                        </div>
                    </div>
                </div>
            </Provider>,
        );
    }

    setHandlerGaps(gap) {
        (gap ? [gap] : this.parent.current.querySelectorAll('.editor__gap')).forEach((innerGap) => {
            innerGap.onclick = () => {
                this.saveContent({ type: 'gaps' });

                innerGap.remove();

                this.changeContent({});
            };
        });
    }

    contentInit() {
        const { content } = this.props;
        const area = this.getArea();

        area.innerHTML = content ? clearContent(content) : `<div>\u200b</div>`;

        // this.checkPatterns({ allNodes: true });
    }

    cloneNode(node, update) {
        const clonedNode = node.cloneNode();

        if (node.nodeType !== 3 && node.nodeName !== 'BR') {
            node.childNodes.forEach((childNode) => {
                clonedNode.appendChild(this.cloneNode(childNode, update));
            });
        } else if (update) {
            update(node, clonedNode);
        }

        return clonedNode;
    }

    saveContent({ type }) {
        if (this.selection && this.selection.startContainer && this.selection.endContainer) {
            const { editAncor } = this.state;
            const savedContent = {
                id: `id-${new Date().getTime()}`,
                time: new Date().getTime(),
                type,
                editAncor,
            };
            const childs = [];

            const update = (node, clonedNode) => {
                if (node === this.selection.startContainer) {
                    savedContent.startContainer = clonedNode;
                }

                if (node === this.selection.endContainer) {
                    savedContent.endContainer = clonedNode;
                }
            };

            this.getArea().childNodes.forEach((child) => {
                const clonedChild = this.cloneNode(child, update);

                childs.push(clonedChild);
            });

            savedContent.childs = childs;
            savedContent.startOffset = this.selection.startOffset;
            savedContent.endOffset = this.selection.endOffset;

            if (this.savedContents.length > 200) {
                this.savedContents.shift();
            }

            if (type === 'input') {
                const groups = {};

                this.savedContents
                    .filter((item) => item.type === 'input')
                    .forEach((item) => {
                        const time = +(item.time / 200).toFixed(0);

                        if (!groups[time]) {
                            groups[time] = [];
                        }

                        groups[time].push(item.id);
                    });

                Object.keys(groups).forEach((key) => {
                    groups[key].shift();

                    groups[key].forEach((id) => {
                        const index = this.savedContents.findIndex((item) => item.id === id);

                        if (index !== -1) {
                            this.savedContents.splice(index, 1);
                        }
                    });
                });
            }

            this.savedContents.push(savedContent);
        }
    }

    changeContent({ action, paste }) {
        const { change } = this.props;

        this.setHandlerGaps();

        if (!action && !paste) {
            this.checkPatterns({ allNodes: false });
        }

        change({ value: this.getArea().innerHTML });
    }

    checkPatterns({ allNodes }) {
        const { patterns } = this.props;

        if ((this.selection || allNodes) && patterns) {
            let nodes = [];
            let startOffset;

            if (allNodes) {
                nodes = getAllNodes(this.getArea());
            } else {
                const { startContainer, endContainer } = this.selection;

                startOffset = this.selection.startOffset;

                if (startContainer === endContainer && startContainer.nodeType === 3) {
                    nodes.push(startContainer);
                } else {
                    nodes = getRangeNodes({ startContainer, endContainer });
                }
            }

            nodes.forEach((node) => {
                if (node.nodeValue) {
                    const pattern = patterns.find((patternItem) => {
                        const patternReg = patternItem.reg
                            .replace(/\$/g, '\\$')
                            .replace(/\{/g, '\\{')
                            .replace(/\}/g, '\\}');
                        const reg = new RegExp(patternReg, 'gim');
                        const matches = Array.from(node.nodeValue.matchAll(reg) || []);
                        const { index } = matches[0] || {};

                        return (
                            node.nodeValue.toLowerCase().includes(patternItem.reg.toLowerCase()) &&
                            index > startOffset - patternItem.reg.length &&
                            index < startOffset
                        );
                    });

                    if (pattern && !node.parentNode.classList.contains('_pattern')) {
                        const parentWrapper = node.parentNode.cloneNode();
                        const wrapper = document.createElement('span');
                        let focusNode;
                        const patternReg = pattern.reg
                            .replace(/\$/g, '\\$')
                            .replace(/\{/g, '\\{')
                            .replace(/\}/g, '\\}');
                        const reg = new RegExp(patternReg, 'gim');

                        // console.log(reg);

                        const matches = Array.from(node.nodeValue.matchAll(reg) || []);

                        if (matches[0]) {
                            const { index } = matches[0];

                            if (index > 0) {
                                const leftNode = document.createTextNode(
                                    node.nodeValue.slice(0, index),
                                );

                                wrapper.appendChild(leftNode);
                            }

                            const patterWrapper = document.createElement('span');

                            patterWrapper.classList.add('_pattern');

                            const patternNode = document.createTextNode(pattern.reg);

                            focusNode = patternNode;

                            patterWrapper.appendChild(patternNode);

                            wrapper.appendChild(patterWrapper);

                            if (index < node.nodeValue.length - 1) {
                                const rightNode = document.createTextNode(
                                    node.nodeValue.slice(index + pattern.reg.length),
                                );

                                wrapper.appendChild(rightNode);
                            }

                            node.parentNode.childNodes.forEach((childNode) => {
                                parentWrapper.append(
                                    ...(childNode === node
                                        ? wrapper.childNodes
                                        : [childNode.cloneNode(true)]),
                                );
                            });

                            node.parentNode.replaceWith(parentWrapper);

                            if (!allNodes) {
                                try {
                                    document
                                        .getSelection()
                                        .collapse(focusNode, startOffset - index);
                                } catch (error) {
                                    document.getSelection().collapse(focusNode, focusNode.length);
                                }
                            }
                        }

                        // console.log(matches);
                    } else if (node.parentNode.classList.contains('_pattern')) {
                        node.parentNode.replaceWith(...node.parentNode.childNodes);

                        if (!allNodes) {
                            document.getSelection().collapse(node, startOffset);
                        }
                    }
                }
            });
        }
    }

    handlerContent({ action }) {
        this.saveSelection();

        if (!this.getArea().innerText?.trim()) {
            this.getArea()
                .querySelectorAll('*:not(div),div:empty')
                .forEach((br) => {
                    br.remove();
                });

            const startDiv = document.createElement('div');
            const startNode = document.createTextNode('\u200b');

            startDiv.appendChild(startNode);

            this.getArea().appendChild(startDiv);

            document.getSelection().collapse(startNode, 0);
        }

        this.changeContent({ action });
    }

    handlerNode({ node, key, wrap, updateNode, ...props }) {
        const action = actionsInfo.actions[key];

        if (action.isInline) {
            return handlerInlineNode({ node, key, wrap, updateNode, ...props });
        }

        return null;
    }

    updateNode(arr, ranges, parentNode, clonedNode) {
        const nodeIndex = arr.indexOf(parentNode);
        const rangeIndex = ranges.indexOf(parentNode);

        if (nodeIndex !== -1) {
            // console.log('wef');
            arr[nodeIndex] = clonedNode;
        }

        if (rangeIndex !== -1) {
            ranges[rangeIndex] = clonedNode;
        }
    }

    clear({ items, ...props }) {
        ['italic', 'uppercase', 'underline', 'color', 'ancor'].forEach((nextKey) => {
            const clearItems = [];

            items.forEach((rangeNode) => {
                const resultNode = this.handlerNode({
                    node: rangeNode,
                    key: nextKey,
                    wrap: false,
                    updateNode: this.updateNode.bind(this, clearItems, items),
                    ...props,
                });

                if (resultNode) {
                    clearItems.push(resultNode);
                }
            });

            items = clearItems;
        });

        items.forEach((item) => {
            if (item.parentNode) {
                const closestLink =
                    item.parentNode?.nodeName === 'A'
                        ? item.parentNode
                        : item.parentNode.closest('a');

                if (closestLink && getAllNodes(closestLink).every((node) => items.includes(node))) {
                    closestLink.replaceWith(...closestLink.childNodes);
                }

                const closestLi =
                    item.parentNode?.nodeName === 'LI'
                        ? item.parentNode
                        : item.parentNode.closest('li');

                if (
                    closestLi &&
                    getAllNodes(closestLi).every((node) => items.includes(node)) &&
                    0
                ) {
                    const closestList = ['UL', 'OL'].includes(item.parentNode?.nodeName)
                        ? item.parentNode
                        : item.parentNode.closest('ul') || item.parentNode.closest('ol');

                    if (
                        closestList &&
                        getAllNodes(closestList).every((node) => items.includes(node))
                    ) {
                        closestList.replaceWith(...closestList.childNodes);
                    }

                    const closestDiv = getBlockParent({ node: item, max: true });

                    if (closestDiv) {
                        closestDiv.replaceWith(...closestDiv.childNodes);
                    }

                    const replacer = document.createElement('div');

                    closestLi.childNodes.forEach((childNode) => {
                        replacer.append(childNode);
                    });

                    closestLi.replaceWith(replacer);
                }
            }
        });

        return items;
    }

    clearBlocks({ startContainer, endContainer, blockParents, rangeNodes, key }) {
        const action = actionsInfo.actions[key];

        blockParents.forEach((blockParent) => {
            if (
                !rangeNodes ||
                getAllNodes(blockParent).every((node) => rangeNodes.includes(node))
            ) {
                Object.keys(actionsInfo.actions)
                    .filter(
                        (innerKey) =>
                            key === 'all' ||
                            (actionsInfo.actions[innerKey].group === action.group &&
                                innerKey !== key),
                    )
                    .forEach((innerKey) => {
                        const innerAction = actionsInfo.actions[innerKey];

                        blockParent.classList.remove(innerAction.className);
                    });

                if (key === 'all') {
                    blockParent.style = null;
                    blockParent.removeAttribute('data-indent');
                }
            }
        });

        if (key === 'all') {
            ['main', 'sub'].forEach((titleKey) => {
                handlerInsertTag({
                    startContainer,
                    endContainer,
                    key: 'title',
                    title: titleKey,
                    unwrap: true,
                });
            });
        }
    }

    getAncorBlocks() {
        return Array.from(this.getArea().querySelectorAll('div')).filter((node) =>
            node.innerText.trim(),
        );
    }

    setHandlerAncor() {
        const blocks = document.querySelectorAll('._editAncor');

        blocks.forEach((block) => {
            block.onclick = () => {
                const { editAncor } = this.state;

                if (editAncor) {
                    const ancorId = +(block.getAttribute('data-ancor') || new Date().getTime());

                    block.setAttribute('data-ancor', ancorId);

                    block.classList.add('_ancorBlock');

                    this.handlerContentActions({ key: 'ancor', ancorId, force: true });

                    blocks.forEach((innerBlock) => {
                        innerBlock.classList.remove('_editAncor');
                    });

                    this.setState({ editAncor: false });

                    document.getSelection().removeAllRanges();
                }
            };
        });
    }

    setEditAncor() {
        const { startContainer } = this.selection;

        const parentAncor = startContainer.parentNode?.classList.contains('_ancor')
            ? startContainer.parentNode
            : startContainer.parentNode?.closest('_ancor');

        if (parentAncor) {
            parentAncor.replaceWith(...parentAncor.childNodes);
            const range = new Range();

            range.setStart(startContainer, 0);
            range.setEnd(startContainer, startContainer.length);

            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);
        } else {
            this.getAncorBlocks().forEach((blockNode) => {
                blockNode.classList.add('_editAncor');
            });

            this.setHandlerAncor();

            this.setState({ editAncor: true });
        }
    }

    handlerContentActions({ key, ...props }) {
        this.saveContent({ type: 'action' });

        const action = actionsInfo.actions[key];

        if (key === 'ancor' && !props.force) {
            this.setEditAncor();

            return;
        }

        const { startContainer, endContainer } = getSelection.call(this, { ...action, key });

        if (
            !startContainer ||
            startContainer === this.getArea() ||
            !endContainer ||
            endContainer === this.getArea()
        ) {
            return;
        }

        if (action.insert) {
            if (key === 'gap') {
                props.content = this.renderGap();
                props.setHandlerGaps = this.setHandlerGaps;
            }

            const {
                focusNode,
                startContainer: newStartContainer,
                endContainer: newEndContainer,
            } = handlerInsertTag({
                startContainer,
                endContainer,
                key,
                ...props,
            });

            const resultStartContainer = newStartContainer || startContainer;
            const resultEndContainer = newEndContainer || endContainer;

            if (focusNode) {
                document.getSelection().collapse(focusNode, focusNode.length);
            }

            const range = new Range();

            range.setStart(resultStartContainer, 0);
            range.setEnd(resultEndContainer, resultEndContainer.length);

            document.getSelection().removeAllRanges();
            document.getSelection().addRange(range);

            this.handlerContent({ action: true });

            return;
        }

        if (key === 'indent') {
            const blockParents = getBlockParents({
                startContainer,
                endContainer,
                withBr: true,
            });

            blockParents.forEach((blockParent) => {
                const indent = +(blockParent.getAttribute('data-indent') || 0) + 1;

                if (indent < 5) {
                    blockParent.setAttribute('data-indent', indent);
                    blockParent.style.paddingLeft = `${20 * indent}px`;
                }
            });

            this.handlerContent({ action: true });

            return;
        }

        if (action.wrap) {
            let blockParents = getBlockParents({
                startContainer,
                endContainer,
                // withBr: true,
            });

            const wrap = !blockParents.every((blockParent) =>
                blockParent.classList.contains(action.className),
            );

            this.clearBlocks({ startContainer, endContainer, blockParents, key });

            if (!wrap) {
                blockParents = getBlockParents({
                    startContainer,
                    endContainer,
                    withBr: true,
                });
            }

            blockParents.forEach((blockParent) => {
                if (!wrap) {
                    blockParent.classList.remove(action.className);
                } else {
                    blockParent.classList.add(action.className);
                }
            });

            this.handlerContent({ action: true });

            return;
        }

        const rangeNodes = getRangeNodes({
            startContainer,
            endContainer,
            withBr: key === 'clear',
        });

        let wrap = !rangeNodes.every(
            (rangeNode) => !!checkClosestParent({ node: rangeNode, ...action }),
        );

        if (key === 'color') {
            wrap = true;
        }

        if (key === 'link') {
            wrap = true;
        }

        let resultItems = [];

        const savedKey = key;

        if (key === 'clear') {
            key = 'bold';
            wrap = false;

            const blockParents = getBlockParents({
                startContainer,
                endContainer,
                withBr: true,
            });

            // console.log(blockParents);

            this.clearBlocks({
                startContainer,
                endContainer,
                blockParents,
                rangeNodes,
                key: 'all',
            });
        }

        rangeNodes.forEach((rangeNode) => {
            const resultNode = this.handlerNode({
                node: rangeNode,
                key,
                wrap,
                updateNode: this.updateNode.bind(this, resultItems, rangeNodes),
                ...props,
            });

            if (resultNode) {
                resultItems.push(resultNode);
            }
        });

        if (savedKey === 'clear') {
            resultItems = this.clear({ items: resultItems, ...props });
        }

        const focusStartNode = resultItems[0];
        const focusEndNode = resultItems[resultItems.length - 1];
        const range = new Range();

        if (!focusEndNode) {
            range.setStart(focusStartNode, 0);
            range.setEnd(focusStartNode, focusStartNode.length);
        } else {
            range.setStart(focusStartNode, 0);
            range.setEnd(focusEndNode, focusEndNode.length);
        }

        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);

        this.handlerContent({ action: true });
    }

    renderContentActions() {
        const { name } = this.props;
        const closeAlert = ({ target }) => {
            const alert = target.closest('.editor__actionAlert');

            alert.style.pointerEvents = 'none';

            setTimeout(() => {
                alert.style.pointerEvents = null;
            }, 10);
        };

        return (
            <div className="editor__actionsInner _row">
                {actionsInfo.order
                    .filter(
                        (key) =>
                            !(
                                name === 'template'
                                    ? ['image', 'doc', 'quote', 'color', 'ancor']
                                    : ['gap']
                            ).includes(key),
                    )
                    .map((key) => {
                        const action = actionsInfo.actions[key];

                        return (
                            <div className={`editor__action _${key}`} key={key}>
                                <div
                                    className="editor__actionInner _col _click"
                                    onClick={() => {
                                        if (!action.settings) {
                                            this.handlerContentActions({ key });
                                        }
                                    }}
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: action.support,
                                            e,
                                            data: { className: '_normalCase _center' },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({ action: 'leave', e });
                                    }}
                                >
                                    <div className="editor__actionIcon">
                                        <Icon name={action.icon} />
                                    </div>
                                </div>
                                {key === 'link' && (
                                    <Link
                                        callback={(e, { link, targetBlank }) => {
                                            closeAlert(e);

                                            this.handlerContentActions({ key, link, targetBlank });
                                        }}
                                    />
                                )}
                                {key === 'color' && (
                                    <Color
                                        callback={(e, { color }) => {
                                            closeAlert(e);

                                            this.handlerContentActions({ key, color });
                                        }}
                                    />
                                )}
                                {key === 'title' && (
                                    <Title
                                        callback={(e, { title }) => {
                                            closeAlert(e);

                                            this.handlerContentActions({ key, title });
                                        }}
                                    />
                                )}
                                {key === 'image' && (
                                    <Image
                                        callback={(e, { src, width }) => {
                                            closeAlert(e);

                                            this.handlerContentActions({ key, src, width });
                                        }}
                                    />
                                )}
                                {key === 'doc' && (
                                    <Doc
                                        callback={(e, { src, fileName }) => {
                                            closeAlert(e);

                                            this.handlerContentActions({ key, src, fileName });
                                        }}
                                    />
                                )}
                                {key === 'table' && (
                                    <Table
                                        callback={(e, { row, col, isTableWidthFix }) => {
                                            closeAlert(e);

                                            this.handlerContentActions({
                                                key,
                                                row,
                                                col,
                                                isTableWidthFix,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
            </div>
        );
    }

    handlerPaste(e) {
        const { target } = e;

        if (target.closest('[contenteditable]')) {
            e.preventDefault();

            this.saveContent({ type: 'paste' });

            const text =
                e.clipboardData.getData('text/html') || e.clipboardData.getData('text/plain');

            const result = clearContent(text, true);

            document.execCommand('insertHtml', false, result);

            if (1) {
                const blockChilds = [];

                this.getArea().childNodes.forEach((child) => {
                    if (child.nodeName === 'DIV') {
                        blockChilds.push(child);
                    }
                });

                blockChilds.forEach((childNode) => {
                    ['previousSibling', 'nextSibling'].forEach((key) => {
                        let currentNode = childNode[key];
                        const wrapper = document.createElement('div');
                        let replacerNode;

                        while (currentNode && currentNode.nodeName !== 'DIV') {
                            const savedNode = currentNode;

                            if (currentNode.nodeType === 3) {
                                wrapper.appendChild(currentNode.cloneNode());
                            }

                            currentNode = currentNode[key];

                            if (savedNode.nodeType === 3 || savedNode.nodeName === 'BR') {
                                if (!replacerNode) {
                                    replacerNode = savedNode;
                                } else {
                                    savedNode.remove();
                                }
                            }
                        }

                        if (replacerNode) {
                            replacerNode.replaceWith(wrapper);
                        }
                    });
                });
            }

            this.changeContent({ paste: true });
        }
    }

    checkEmpty() {
        const { content } = this.props;

        const div = document.createElement('div');

        div.innerHTML = content;

        return div.innerText.trim().length === 0;
    }

    getScrollBox() {
        return this.parent.current.closest('.widget__pageBox._scroll');
    }

    keys = {};

    savedContents = [];

    handlerKeyDown(e) {
        const selection = document.getSelection();
        const { anchorNode } = selection;

        let td;

        this.keys[e.which] = true;

        if (e.which === 8) {
            this.saveContent({ type: 'input' });
        }

        if (anchorNode) {
            td =
                anchorNode.nodeName === 'TD'
                    ? anchorNode
                    : anchorNode.parentNode.nodeName === 'TD'
                    ? anchorNode.parentNode
                    : anchorNode.parentNode.closest('td');
        }

        if (td) {
            const tr = anchorNode.closest
                ? anchorNode.closest('tr')
                : anchorNode.parentNode.closest('tr');

            if (td && e.which === 13 && !this.keys[16]) {
                const tdLast = tr.childNodes[tr.childNodes.length - 1] === td;

                const trParent = tr.parentNode;
                const trLast = trParent.childNodes[trParent.childNodes.length - 1] === tr;

                if (trLast && tdLast) {
                    e.preventDefault();

                    const newRow = document.createElement('tr');

                    for (let i = 0; i < tr.childNodes.length; i++) {
                        const newCol = document.createElement('td');

                        newRow.appendChild(newCol);
                    }

                    tr.parentNode.appendChild(newRow);

                    document.getSelection().collapse(newRow.childNodes[0], 0);
                }
            }

            if (td && e.which === 8 && !td.innerText.trim()) {
                e.preventDefault();

                const tdFirst = tr.childNodes[0] === td;

                const prevTd = td.previousElementSibling;

                if (prevTd) {
                    const prevTdNodes = getAllNodes(prevTd);
                    const prevTdNodesLast = prevTdNodes[prevTdNodes.length - 1];

                    try {
                        document.getSelection().collapse(prevTdNodesLast, prevTdNodesLast.length);
                    } catch (error) {
                        document.getSelection().collapse(prevTd, 0);
                    }
                } else if (tdFirst && !tr.innerText.trim()) {
                    const prevRow = tr.previousElementSibling;

                    if (prevRow) {
                        const lastTd = prevRow.childNodes[prevRow.childNodes.length - 1];

                        if (lastTd) {
                            const lastTdNodes = getAllNodes(lastTd);
                            const lastTdNodesLast = lastTdNodes[lastTdNodes.length - 1];

                            try {
                                document
                                    .getSelection()
                                    .collapse(lastTdNodesLast, lastTdNodesLast.length);
                            } catch (error) {
                                document.getSelection().collapse(lastTd, 0);
                            }
                        }
                    } else {
                        const table = tr.closest('table');

                        table.remove();
                    }

                    tr.remove();
                }
            }
        }

        // console.log(e.which);

        if (this.keys[91] && !this.keys[18]) {
            // b
            if (e.which === 66) {
                e.preventDefault();

                this.handlerContentActions({ key: 'bold' });
            }

            // i
            if (e.which === 73) {
                e.preventDefault();

                this.handlerContentActions({ key: 'italic' });
            }

            // l
            if (e.which === 76 && this.state.copyInfo) {
                e.preventDefault();

                this.handlerContentActions({
                    key: 'link',
                    link: this.state.copyInfo,
                    targetBlank: true,
                });
            }

            // o
            if (e.which === 79) {
                e.preventDefault();

                this.handlerContentActions({ key: 'quote' });
            }

            // s
            if (e.which === 83) {
                e.preventDefault();

                this.handlerContentActions({ key: 'underline' });
            }

            // u
            if (e.which === 85) {
                e.preventDefault();

                this.handlerContentActions({ key: 'uppercase' });
            }

            if (e.which === 90) {
                e.preventDefault();

                if (this.savedContents.length) {
                    const lastContent = this.savedContents.pop();
                    const {
                        childs,
                        startContainer,
                        startOffset,
                        endContainer,
                        endOffset,
                        editAncor,
                    } = lastContent;

                    this.getArea().innerHTML = '';

                    this.getArea().append(...childs);

                    const range = new Range();

                    if (startContainer && endContainer) {
                        range.setStart(startContainer, startOffset);
                        range.setEnd(endContainer, endOffset);

                        document.getSelection().removeAllRanges();
                        document.getSelection().addRange(range);
                    }

                    this.setState({ editAncor });

                    this.setHandlerGaps();
                }
            }
        }
    }

    handlerKeyUp(e) {
        this.keys[e.which] = false;
    }

    beforeInput() {
        this.saveContent({ type: 'input' });
    }

    saveSelection() {
        const { editAncor } = this.state;
        const selection = document.getSelection();

        if (this.getArea().contains(selection.anchorNode) && !editAncor) {
            const { startContainer, startOffset, endContainer, endOffset } =
                selection.getRangeAt(0);

            this.selection = {
                startContainer,
                startOffset,
                endContainer,
                endOffset,
            };
        }
    }

    checkCopied() {
        navigator.clipboard.readText().then(
            (text) => {
                this.setState({ copyInfo: text });
            },
            () => null,
        );
    }

    visibilityDocChange() {
        this.checkCopied();
    }

    componentDidMount() {
        this.contentInit();

        window.addEventListener('focus', this.visibilityDocChange);
        document.addEventListener('copy', this.visibilityDocChange);
        document.addEventListener('selectionchange', this.saveSelection);
        document.addEventListener('keydown', this.handlerKeyDown);
        document.addEventListener('keyup', this.handlerKeyUp);

        this.parent.current.addEventListener('paste', this.handlerPaste);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.visibilityDocChange);
        document.removeEventListener('copy', this.visibilityDocChange);
        document.removeEventListener('selectionchange', this.saveSelection);
        document.removeEventListener('keydown', this.handlerKeyDown);
        document.removeEventListener('keyup', this.handlerKeyUp);

        this.parent.current.removeEventListener('paste', this.handlerPaste);
    }

    render() {
        const { isFocus, editAncor } = this.state;
        const { disabled, children, title, support } = this.props;

        return (
            <div ref={this.parent} className={`editor ${children ? '_withActions' : ''}`}>
                <div className="editor__head _row">
                    <div className="editor__title">{title}</div>
                    {!disabled && (
                        <div className="editor__actions _row">{this.renderContentActions()}</div>
                    )}
                </div>
                <div className="editor__content _col">
                    {children && <div className="editor__contentHead _row">{children}</div>}

                    <div className="editor__area">
                        <div
                            className={`editor__areaSupport ${
                                !isFocus && this.checkEmpty() ? '_show' : ''
                            }`}
                        >
                            {support}
                        </div>
                        <div
                            className="editor__areaInner"
                            onFocus={() => {
                                this.setState({ isFocus: true });
                            }}
                            onBlur={() => {
                                this.setState({ isFocus: false });
                            }}
                            onInput={this.handlerContent}
                            contentEditable={!disabled && !editAncor}
                            onBeforeInput={() => {
                                this.beforeInput();
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Editor);

Editor.propTypes = {
    name: PropTypes.string,
    content: PropTypes.string,
    change: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    patterns: PropTypes.array,
    title: PropTypes.string,
    support: PropTypes.string,
};
