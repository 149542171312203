export default function formatCardNumber(number: string): string {
    if (number) {
        number = number.replace(/ /gi, '');

        if (number.length === 20) {
            return new Array(5)
                .fill({})
                .map((item, key) => number.slice(key * 4, key * 4 + 4))
                .join(' ');
        }

        if (number.length === 16) {
            return new Array(4)
                .fill({})
                .map((item, key) => number.slice(key * 4, key * 4 + 4))
                .join(' ');
        }

        if (number.length === 19) {
            return (
                new Array(4)
                    .fill({})
                    .map((item, key) => number.slice(key * 4, key * 4 + 4))
                    .join(' ') +
                ' ' +
                number.slice(16)
            );
        }
    }

    return number;
}
