const types = {
    card: {
        content: 'Бакновская карта',
    },
    account: {
        content: 'Реквизиты',
    },
} as const;

export default types;
