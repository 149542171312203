import PropTypes from 'prop-types';

import React from 'react';

import getExecutorPassportInfo from '../../functions/getExecutorPassportInfo';
import getFormatedDate from '../../functions/getFormatedDate';
import getUserIncline from '../../functions/getUserIncline';
import getUserInfo from '../../functions/getUserInfo';
import setSpacesInText from '../../functions/setSpacesInText';

import Pagenation from './Pagenation.jsx';
import Sign from './Sign.jsx';

class JoinsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.loadLogo = this.loadLogo.bind(this);
        this.renderPagination = this.renderPagination.bind(this);

        this.parent = React.createRef();
    }

    renderPagination({ key }) {
        const { renderPagination, pdf } = this.props;
        const { otherData } = pdf;
        const { contractId, docDate, isTest } = otherData;

        const render = ({ pageCounter }) => (
            <Pagenation
                pageCurrent={key}
                pageCounter={pageCounter}
                contractId={isTest ? 'demo-id' : contractId}
                docDate={isTest ? new Date().toUTCString() : docDate}
            />
        );

        return renderPagination({ key, render });
    }

    renderFoot() {
        const { renderFoot, pdf } = this.props;
        const { otherData, data } = pdf;
        const { docDate } = otherData;
        const { signId, signUserInfo, signExecutorInfo } = data;

        const render = () => (
            <>
                <Sign
                    id={signId}
                    docDate={docDate}
                    signUserInfo={signUserInfo}
                    signExecutorInfo={signExecutorInfo}
                />
            </>
        );

        return renderFoot({ render });
    }

    setContent() {
        const { pdf } = this.props;
        const { data, otherData, corporationInfo } = pdf;
        const { isTest } = otherData;
        const { contractNumber, content, executorInfo, date } = data;
        const getProp = (value) => value || '–';

        const div = document.createElement('div');

        div.innerHTML = setSpacesInText(content.replace(/&nbsp;/gm, ' ').replace(/\n/gm, ' '));

        div.querySelectorAll('ul,ol').forEach((list) => {
            list.querySelectorAll('li').forEach((item, key) => {
                item.classList.add('_remove');

                const point = document.createElement('span');

                point.classList.add('_listPoint');

                if (list.nodeName === 'UL') {
                    point.innerHTML = '•';
                } else {
                    point.innerHTML = `${key + 1}.`;
                }

                item.prepend(point);
            });
        });

        let resultContent = div.innerHTML;

        if (!isTest) {
            resultContent = resultContent
                .replace(/\$\{number\}/gm, contractNumber)
                .replace(
                    /\$\{date\}/gm,
                    getFormatedDate({ date: date ? new Date(date) : new Date() }),
                )
                .replace(
                    /\$\{corporationHolder\}/gm,
                    getUserInfo({ type: 'name', user: corporationInfo.holder }),
                )
                .replace(
                    /\$\{corporationHolderGen\}/gm,
                    getUserInfo({
                        type: 'name',
                        user: getUserIncline({ user: corporationInfo.holder }),
                    }),
                )
                .replace(/\$\{corporationName\}/gm, getProp(corporationInfo.name))
                .replace(/\$\{corporationCity\}/gm, getProp(corporationInfo.city))
                .replace(/\$\{corporationAddress\}/gm, getProp(corporationInfo.address))
                .replace(/\$\{corporationOgrn\}/gm, getProp(corporationInfo.ogrn))
                .replace(/\$\{corporationInn\}/gm, getProp(corporationInfo.inn))
                .replace(/\$\{corporationKpp\}/gm, getProp(corporationInfo.kpp))
                .replace(/\$\{corporationBic\}/gm, getProp(corporationInfo.bic))
                .replace(/\$\{corporationOgrn\}/gm, getProp(corporationInfo.ogrn))
                .replace(/\$\{corporationAccount\}/gm, getProp(corporationInfo.corrAccount))
                .replace(/\$\{corporationBankAccount\}/gm, getProp(corporationInfo.bankAccount))
                .replace(/\$\{corporationBankName\}/gm, getProp(corporationInfo.bankName))
                .replace(/\$\{executorName\}/gm, getUserInfo({ type: 'name', user: executorInfo }))
                .replace(
                    /\$\{executorNameGen\}/gm,
                    getUserInfo({ type: 'name', user: getUserIncline({ user: executorInfo }) }),
                )
                .replace(
                    /\$\{executorPassport\}/gm,
                    getExecutorPassportInfo({ passport: executorInfo.passport }),
                )
                .replace(/\$\{executorRegAddress\}/gm, getProp(executorInfo.passport.addressReg))
                .replace(/\$\{executorPhone\}/gm, getProp(executorInfo.phone))
                .replace(/\$\{executorEmail\}/gm, getProp(executorInfo.email))
                .replace(/\$\{executorInn\}/gm, getProp(executorInfo.inn))
                .replace(/\$\{executorBic\}/gm, getProp(executorInfo.bic))
                .replace(/\$\{executorAccount\}/gm, getProp(executorInfo.bankAccount))
                .replace(/\$\{executorBank\}/gm, getProp(executorInfo.bankName))
                .replace(/\$\{executorBankAccount\}/gm, getProp(executorInfo.corrAccount))
                .replace(
                    /\$\{executorInitials\}/gm,
                    getUserInfo({ type: 'fullnameShort', user: executorInfo }),
                )
                .replace(/\$\{executorCardNumber\}/gm, getProp(executorInfo.cardNumber));
        }

        return resultContent;
    }

    loadLogo() {
        const { init } = this.props;

        setTimeout(() => {
            init();
        }, 0);
    }

    componentDidMount() {
        const { init, pdf } = this.props;
        const { data } = pdf;
        const { logo } = data;

        if (!logo || !logo.path) {
            init();
        }
    }

    render() {
        const { pdf, getPages } = this.props;
        const { data, otherData } = pdf;
        const { logo, signUserInfo, signExecutorInfo } = data;
        const { isTest } = otherData;
        const withSign = !!signUserInfo || !!signExecutorInfo;

        return (
            <div className="pdf__inner _col">
                {getPages().map((page) => this.renderPagination({ key: page }))}
                <div ref={this.parent} className="pdf__innerBox pdf__joinsTemplate _col">
                    {logo?.path && (
                        <img
                            src={`${process.env.REACT_APP_STATIC}/join-templates/${logo.path}`}
                            alt=""
                            className="JSpdfElem pdf__joinsTemplateLogo"
                            onLoad={this.loadLogo}
                            onError={this.loadLogo}
                        />
                    )}
                    <div
                        className="pdf__joinsTemplateInner"
                        dangerouslySetInnerHTML={{
                            __html: this.setContent(),
                        }}
                    />
                </div>
                {withSign && !isTest && this.renderFoot()}
            </div>
        );
    }
}

export default JoinsTemplate;

JoinsTemplate.propTypes = {
    pdf: PropTypes.object,
    init: PropTypes.func,
    getPages: PropTypes.func,
    renderFoot: PropTypes.func,
    renderPagination: PropTypes.func,
};
