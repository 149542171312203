import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField.tsx';

import I from '../../types.ts';

const renderCorporationOther: I['renderCards'] = function () {
    const { model, name, change, getValue, data } = this.props;

    const onChange = async (
        changedFields: Parameters<typeof change>[0],
    ): Promise<Awaited<ReturnType<typeof change>>> => {
        await change({ ...changedFields });
    };

    return (
        <>
            <div className={`v2widgetCard__blocks ${data?.create ? '_ROW' : '_COL'}`}>
                <div className="v2widgetCard__block _logo">
                    <WidgetField
                        model={model}
                        support="Логотип"
                        name="v2logo"
                        prop="v2logo.src"
                        fileProp="v2logo.fullSrc"
                        type="file"
                        cardName={name}
                        onChange={onChange}
                        value={getValue({ key: 'v2logo.fullSrc' })?.value}
                        fileAccept={['jpeg', 'jpg', 'png', 'svg']}
                    />
                </div>
                <div className="v2widgetCard__block _info">{this.renderFields()}</div>
            </div>
        </>
    );
};

export default renderCorporationOther;
