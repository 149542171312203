const cards = [
    {
        content: '<b>Закрывающие</b><br/>документы',
        icon: 'header-icon-1.svg',
    },
    {
        content: '<b>Онбординг</b><br/>исполнителей',
        icon: 'header-icon-2.svg',
    },
    {
        content: '<b>Массовые</b><br/>выплаты',
        icon: 'header-icon-3.svg',
    },
] as const;

export default cards;
