import CalendarI from '../types.ts';

const init: CalendarI['init'] = async function () {
    const { startDate, startDoubleDate, isDouble } = this.props;
    const date = startDate ? new Date(startDate) : new Date();

    if (isDouble) {
        await this.setDate(date, !!startDate);

        if (startDoubleDate) {
            await this.setDate(new Date(startDoubleDate), true);
        }
    } else {
        await this.setDate(date, true);
    }

    this.setNowTime(startDate);
};

export default init;
