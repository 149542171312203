export default function getFileSize(size: number): string {
    if (size < 1024) {
        return `${size} Б`;
    }

    if (size < 1024 * 1024) {
        return `${+(size / 1024).toFixed(1)} Кб`;
    }

    if (size < 1024 * 1024 * 1024) {
        return `${+(size / (1024 * 1024)).toFixed(1)} Мб`;
    }

    return `${+(size / (1024 * 1024 * 1024)).toFixed(1)} Гб`;
}
