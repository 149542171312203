import React from 'react';

import Input from '@components/input/Input.tsx';
import { deleteCursorList, setCursorList } from '@functions/listsHandler.ts';

import I from '../types.ts';

const renderSelect: I['renderSelect'] = function () {
    const {
        name,
        prop,
        value,
        onChange,
        regExp,
        concat,
        selectList,
        selectText,
        setSelectChange,
        cardName,
        inputSupport,
        disabled,
        multi,
        addArrayItem,
        deleteArrayItem,
    } = this.props;
    const selectProps = this.props.selectProps!;
    const id = `select-${name}-${cardName}`;
    const defaultQuery =
        typeof this.props.defaultQuery === 'function'
            ? this.props.defaultQuery(this.props.model, this.props)
            : this.props.defaultQuery;

    const currents = multi
        ? (value as { key: string }[]).map((item) => item.key) || []
        : value
          ? [value as string]
          : [];

    const props =
        !disabled &&
        setCursorList(
            {
                _id: id,
                type: selectList!,
                dir: 'bottom',
                currents,
                filterName: cardName,
                multi,
                defaultQuery,
                callback: (modelId, model: any) => {
                    const otherFields: Record<any, unknown> = {};

                    if (multi) {
                        if (model) {
                            Object.keys(selectProps).forEach((key) => {
                                otherFields[selectProps[key] as string] = model[key];
                            });

                            addArrayItem!({ key: prop, item: { key: modelId, ...otherFields } });
                        } else {
                            deleteArrayItem!([`${prop}.${modelId}`], 'key');
                        }
                    } else {
                        (Object.keys(selectProps) as (keyof typeof selectProps)[]).forEach(
                            (key) => {
                                const thisProps =
                                    typeof selectProps[key] === 'function'
                                        ? selectProps[key](model)
                                        : selectProps[key];
                                const resultKey =
                                    typeof selectProps[key] === 'function'
                                        ? (thisProps as { key: string }).key
                                        : selectProps[key];
                                const resultValue =
                                    typeof selectProps[key] === 'function'
                                        ? (thisProps as { text: string }).text
                                        : model?.[key];

                                otherFields[resultKey] = resultValue || undefined;
                            },
                        );

                        const updateFields = setSelectChange ? setSelectChange(model) : {};

                        onChange({ [prop]: model?._id || null, ...otherFields, ...updateFields });
                        deleteCursorList({ id });
                    }
                },
                checkClose: () => !document.querySelector('.body__v2popup._filter'),
            },
            { targetClassName: '.v2widgetField__field' },
        );

    return (
        <>
            <div className="v2widgetField__field _select" {...props}>
                <Input
                    name={name}
                    value={selectText || ''}
                    onChange={async () => undefined}
                    support={inputSupport || '–'}
                    regExp={regExp}
                    concat={concat}
                    disabled={true}
                    isStatic={true}
                />
            </div>
        </>
    );
};

export default renderSelect;
