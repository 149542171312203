import AlertI from '../../types';

const deleteWallet = {
    title: 'Подтвердите удаление способа',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить способ оплаты <b>${info}</b>?`;
    },
    buttonText: 'Удалить',
} as const;

export default deleteWallet;
