import MobPopupI from '../types.ts';

const hide: MobPopupI['hide'] = function (withCb = true) {
    const { hideCallback } = this.props;
    const inner = this.getInner();

    if (inner) {
        this.animate('bottom');
    }

    if (typeof hideCallback === 'function' && withCb) {
        hideCallback();
    }
};

export default hide;
