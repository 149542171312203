import removeTransition from '../../../functions/removeTransition.ts';

import ItemsI, { ItemsT } from '../types.ts';

const getMoreItems: ItemsI['getMoreItems'] = function (counter, isStart) {
    const actionPrev = (): Promise<ItemsT | void> =>
        new Promise((resolve) => {
            this.setState({ isDisabledScroll: true }, () => {
                if (isStart) {
                    resolve();
                } else {
                    if (this.controller) {
                        this.controller.abort();
                    }

                    this.controller = new AbortController();

                    if (this.getItems) {
                        this.getItems({}).then(resolve, () => null);
                    }
                }
            });
        });

    if (counter < 0) {
        counter = 0;
    }

    return new Promise((resolve) => {
        this.setState({ counterScroll: counter }, () => {
            actionPrev().then(() => {
                removeTransition({
                    item: this.classNameItem,
                    isCurrent: true,
                });

                setTimeout(() => {
                    this.setState({ isDisabledScroll: false }, resolve);
                }, 300);
            });
        });
    });
};

export default getMoreItems;
