import I from '../types.ts';

const getSize: I['getSize'] = function () {
    const { width, height, isZoom } = this.state;
    const { scale } = this.props;
    const resultWidth = width! * (isZoom ? scale : 1);
    const resultHeight = height! * (isZoom ? scale : 1);
    const diffWidth = width! * scale - width!;
    const diffHeight = height! * scale - height!;

    return { width: resultWidth, height: resultHeight, diffWidth, diffHeight };
};

export default getSize;
