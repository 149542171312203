import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getQuery from './methods/getQuery.ts';

import CorporationsI from './types.ts';

class Corporations
    extends WidgetWrapper<CorporationsI['props'], CorporationsI['state']>
    implements CorporationsI
{
    parent: CorporationsI['parent'];

    constructor(props: CorporationsI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getActions = getActions;
    getQuery = getQuery;

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <Widget
                    name="corporations"
                    title="Компании"
                    description="Всего"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as CorporationsI).getFilterQuery?.()}
                >
                    <Table
                        name="corporations"
                        isInit={true}
                        isWrap={true}
                        isRowHeight={true}
                        init={this.tableInit!.bind(this)}
                        callback={this.tableCallback!.bind(this)}
                        getQuery={this.getQuery!.bind(this)}
                        link={{
                            pageName: 'corporations-inner',
                            level: 2,
                        }}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Corporations);
