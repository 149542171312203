import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import { setCursorSupport } from '@functions/supportHandler.ts';

import I from '../types.ts';

const renderAction: I['renderAction'] = function () {
    const { model, action, prop, data } = this.props;
    const resultAction = action ? action(model, data) : undefined;

    if (!resultAction) {
        return null;
    }

    const supportCursor =
        resultAction.support &&
        setCursorSupport(
            {
                _id: `widgetFieldAction-${prop}`,
                content: resultAction.support,
                dir: 'top',
            },
            { targetClassName: '.v2widgetField__action', className: '' },
        );

    return (
        <>
            <div
                className="v2widgetField__action _CLICK"
                onClick={resultAction.onClick}
                {...supportCursor}
            >
                <div className="v2widgetField__actionIcon">
                    <Icon name={resultAction.icon} />
                </div>
            </div>
        </>
    );
};

export default renderAction;
