import getCurrentCorporation from '@functions/crm/getCurrentCorporation.ts';
import UserT from '@global/models/User.ts';

import I from '../types.ts';

const setLocalFilter: I['setLocalFilter'] = function () {
    const { user, name, localFilterName: propsLocalFilterName, notSaveFilter } = this.props;

    return new Promise((resolve) => {
        if (user && !notSaveFilter) {
            const corporation = getCurrentCorporation({
                user,
                isInfo: false,
            }) as UserT['corporations'][number];
            const id = corporation.id;
            let localFilterName = `${id}-${name}-filter`;

            if (propsLocalFilterName) {
                localFilterName += `-${propsLocalFilterName}`;
            }

            try {
                const localFilter = JSON.parse(localStorage.getItem(localFilterName)!);

                this.setState(
                    {
                        localFilterName,
                        ...(localFilter ? { filterQuery: localFilter } : {}),
                    },
                    resolve,
                );
            } catch (error) {
                resolve();
            }
        } else {
            resolve();
        }
    });
};

export default setLocalFilter;
