import checkValueOfEmpty from '../functions/checkValueOfEmpty';
import getDateSumDays from '../functions/getDateSumDays';

export default class Filter {
    constructor({ context, callback }) {
        this.context = context;
        this.callback = callback;

        this.handlerDropProps = this.handlerDropProps.bind(this);
        this.handlerDrop = this.handlerDrop.bind(this);
        this.handlerItem = this.handlerItem.bind(this);
        this.init = this.init.bind(this);
    }

    handlerDropProps({ key, prop, value }) {
        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = { ...state };
                    const filter = JSON.parse(JSON.stringify(newState[this.prop]));
                    const indexBlock = filter.findIndex((item) => item.key === key);

                    if (indexBlock !== -1) {
                        filter[indexBlock][prop] = value;
                    }

                    newState[this.prop] = filter;

                    return newState;
                },
                () => {
                    if (this.callback && typeof this.callback === 'function') {
                        this.callback({});
                    }

                    resolve();
                },
            );
        });
    }

    timersDrops = {};

    handlerDrop({ key, isShow: isShowRes }) {
        let isShow = isShowRes;

        if (!checkValueOfEmpty(isShowRes)) {
            const indexBlock = this.context.state[this.prop].findIndex((item) => item.key === key);

            isShow = !this.context.state[this.prop][indexBlock].isShow;
        }

        if (this.timersDrops[key]) {
            clearTimeout(this.timersDrops[key]);
        }

        if (isShow) {
            this.handlerDropProps({ key, prop: 'isShow', value: isShow }).then(
                () => {
                    this.timersDrops[key] = setTimeout(() => {
                        this.handlerDropProps({ key, prop: 'isFresh', value: true });
                    }, 300);
                },
                () => null,
            );
        } else {
            this.handlerDropProps({ key, prop: 'isFresh', value: false }).then(
                () => {
                    setTimeout(() => {
                        this.handlerDropProps({ key, prop: 'isShow', value: isShow });
                    }, 10);
                },
                () => null,
            );
        }
    }

    handlerItem({ type, key, action, ...props }) {
        return new Promise((resolve) => {
            const { id } = props;

            this.context.setState(
                (state) => {
                    const newState = { ...state };
                    const filter = JSON.parse(JSON.stringify(newState[this.prop]));
                    const indexBlock = filter.findIndex((item) => item.key === key);

                    if (indexBlock !== -1) {
                        const block = filter[indexBlock];
                        switch (type) {
                            case 'dates':
                                {
                                    const { firstDate, secondDate } = props;

                                    if (action === 'clear') {
                                        block.firstDate = null;
                                        block.secondDate = null;
                                    } else {
                                        if (firstDate) {
                                            block.firstDate = firstDate;
                                        }
                                        if (secondDate) {
                                            block.secondDate = secondDate;
                                        }
                                    }
                                }
                                break;
                            case 'search':
                                if (action === 'clear') {
                                    block.search = '';
                                } else {
                                    const { search } = props;

                                    block.search = search;
                                }
                                break;
                            default:
                                {
                                    const { isMulti = true } = block;

                                    if (action === 'clear') {
                                        block.choices = [];
                                    } else {
                                        const indexItem = block.choices.findIndex(
                                            (item) => item === id,
                                        );

                                        if (!isMulti) {
                                            block.choices = [];
                                        }

                                        if (indexItem === -1) {
                                            block.choices.push(id);
                                        } else if (isMulti) {
                                            block.choices.splice(indexItem, 1);
                                        }
                                    }
                                }
                                break;
                        }
                    }

                    newState[this.prop] = filter;

                    return newState;
                },
                () => {
                    if (this.callback && typeof this.callback === 'function') {
                        this.callback({ isChangeItems: true });
                    }

                    resolve();
                },
            );
        });
    }

    checkEmpty({ type, choices, firstDate, search }) {
        return (
            (type === 'executorCorporation' && 0) ||
            (type === 'dates' && firstDate) ||
            (type === 'search' && search) ||
            choices.length
        );
    }

    checkFilterActive({ blocks }) {
        if (!blocks) {
            return false;
        }

        return blocks.filter(this.checkEmpty).length > 0;
    }

    clear() {
        return new Promise((resolve) => {
            this.context.setState(
                (state) => {
                    const newState = { ...state };
                    const filter = JSON.parse(JSON.stringify(newState[this.prop]));

                    filter.forEach((block, key) => {
                        switch (block.type) {
                            case 'dates':
                                filter[key].firstDate = null;
                                filter[key].secondDate = null;
                                break;
                            case 'search':
                                filter[key].search = '';
                                break;
                            default:
                                filter[key].choices = [];
                                break;
                        }
                    });

                    newState[this.prop] = filter;

                    return newState;
                },
                () => {
                    if (this.callback && typeof this.callback === 'function') {
                        this.callback({ isChangeItems: true });
                    }

                    resolve();
                },
            );
        });
    }

    filterDate({ block, date }) {
        let cond = true;

        if (!date) {
            return false;
        }

        if (block.firstDate) {
            const itemDate = new Date(date);
            const itemDateSum = getDateSumDays(
                itemDate.getDate(),
                itemDate.getMonth(),
                itemDate.getFullYear(),
            );

            const firstDate = new Date(block.firstDate);
            const firstDateSum = getDateSumDays(
                firstDate.getDate(),
                firstDate.getMonth(),
                firstDate.getFullYear(),
            );

            if (!block.secondDate) {
                cond = cond && itemDateSum === firstDateSum;
            } else {
                const secondDate = new Date(block.secondDate);
                const secondDateSum = getDateSumDays(
                    secondDate.getDate(),
                    secondDate.getMonth(),
                    secondDate.getFullYear(),
                );

                cond = cond && itemDateSum >= firstDateSum && itemDateSum <= secondDateSum;
            }
        }

        return cond;
    }

    init({ prop = 'filter', blocks }) {
        return new Promise((resolve) => {
            this.blocks = blocks;
            this.prop = prop;

            this.context.setState((state) => {
                const newState = { ...state };
                const filter = [];

                this.blocks
                    .filter(
                        (block) =>
                            ['dates', 'search', 'switch'].indexOf(block.type) !== -1 ||
                            block.items.length > 1,
                    )
                    .forEach((block) => {
                        filter.push({
                            choices: [],
                            search: '',
                            ...block,
                            isShow: block.isShowInit,
                            isFresh: block.isShowInit,
                        });
                    });

                newState[prop] = filter;

                return newState;
            }, resolve);
        });
    }
}
