import CalendarI from '../types.ts';

const getBlockId: CalendarI['getBlockId'] = function (date) {
    if (!date) {
        return null;
    }

    return `${date.getMonth()}${date.getFullYear()}`;
};

export default getBlockId;
