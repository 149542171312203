import React from 'react';

import Link from '@components/link/Link.tsx';
import getFormatedDate from '@functions/getFormatedDate.ts';
import setDateFromStr from '@functions/setDateFromStr.ts';
import DocT from '@global/models/Doc.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderDocs: RenderColsT<'docs'> = function ({ name: colName, item }) {
    const { user } = this.state;
    let content;
    let className;
    const doc = item as DocT;

    if (colName === 'name') {
        content = <>{doc.name}</>;
    }

    if (colName === 'holder') {
        const linkProps: Record<any, unknown> = {};
        let description;

        if (doc.type === 'corporation') {
            if (user?.idOfCurrentCorporation === 'admin') {
                linkProps.pageName = 'corporations-inner-docs';
                linkProps.ids = { 2: doc.modelId };
            } else {
                linkProps.pageName = 'settings-docs';
            }

            description = <div className="v2table__text">, ИНН&nbsp;{doc.holder?.description}</div>;
        }

        if (doc.type === 'executor') {
            linkProps.pageName = 'manual-executors-inner-docs';
            linkProps.ids = { 3: doc.modelId };
            description = (
                <div className="v2table__text">
                    , тел. <span className="_NOWRAP">{doc.holder?.description}</span>
                </div>
            );
        }

        content = (
            <>
                <Link className="v2table__text _link _full" {...linkProps}>
                    {doc.holder?.name}
                </Link>
                {description}
            </>
        );
    }

    if (colName === 'type') {
        content = doc.typeName;
    }

    if (colName === 'number') {
        content = <>{doc.number || '–'}</>;
    }

    if (colName === 'date') {
        content = (
            <>
                {doc.date && setDateFromStr(doc.date)
                    ? getFormatedDate({ date: new Date(setDateFromStr(doc.date)!) })
                    : '–'}
            </>
        );
    }

    if (colName === 'actionDate') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            doc: DocT;
        }) => CursorActionsItemT[];

        content = (
            <>
                <More
                    id={`doc-${doc._id}`}
                    items={getMoreActions({ doc })}
                    className="_vertCenter"
                />
                {doc.actionDate && setDateFromStr(doc.actionDate)
                    ? getFormatedDate({ date: new Date(setDateFromStr(doc.actionDate)!) })
                    : '–'}
            </>
        );
    }

    return { content, className };
};

export default renderDocs;
