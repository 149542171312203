import React from 'react';

import ExecutorOrg from '@components/crm/ExecutorOrg.jsx';
import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import getDiffTime from '@functions/getDiffTime.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import getUserName from '@functions/getUserName.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import JoinT from '@global/models/Join.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderJoins: RenderColsT<'joins'> = function ({ name: colName, item }) {
    const join = item as JoinT;
    let content;
    let className;
    const isAdmin = this.checkAdmin();

    const renderScript = () => (
        <>
            <Link
                pageName="joins-scripts-inner"
                className="v2table__text _link _full"
                ids={{ '3': join.scriptId }}
            >
                {join.scriptName}
            </Link>{' '}
            <div className="v2table__text _grey _NOWRAP"> /{join.scriptLink}</div>
        </>
    );

    if (colName === 'user') {
        const supportProps = setCursorSupport(
            {
                _id: `mvd-${join._id}`,
                content:
                    'Необходимо подать уведомление<br/>в МВД в течение 3-х дней после<br/>подписания договора',
                dir: 'top',
            },
            { targetClassName: '.v2table__text', className: '' },
        );

        content = (
            <>
                <div className={`v2table__text ${join.chatId ? '_chat' : ''} _block`}>
                    {join.chatId && (
                        <Link
                            className="v2table__textChat _col"
                            pageName="chat-inner"
                            ids={{ '3': join.chatId }}
                        >
                            <i className="v2table__textChatIcon">
                                <Icon name="telegram" />
                            </i>
                        </Link>
                    )}
                    <Link
                        className="v2table__text _link _empty"
                        pageName="manual-executors-inner-main"
                        ids={{ '3': join.executorId! }}
                    >
                        {join.executorName || '–'}
                    </Link>&nbsp;&nbsp;
                    {join.executorOrganization && (
                        <div className="v2table__executorOrg">
                            <ExecutorOrg name={join.executorOrganization} />
                        </div>
                    )}
                </div>
                <div className="v2table__text _block _top">{join.executorPhone}</div>
                {isAdmin && renderScript()}
                {join.mvdAlarm && (
                    <div className="v2table__text _block" {...supportProps}>
                        <div className="v2table__alert _alarm">
                            <div className="v2table__alertIcon">
                                <Icon name="alert-2" />
                            </div>
                            Необходимо подать уведомление, осталось {getDiffTime(join.mvdAlarm)} (До{' '}
                            {getFormatedDate({ date: new Date(join.mvdAlarm) })})
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (colName === 'script') {
        content = renderScript();
    }

    if (colName === 'date') {
        content = (
            <>
                <div className="v2table__text">
                    {item.createdDate
                        ? getFormatedDate({ date: new Date(join.createdDate), type: 'full' })
                        : '–'}
                </div>
                {item.isNonResident && (
                    <div className="v2table__text">
                        <div className="v2table__alert">
                            <div className="v2table__alertIcon">
                                <Icon name="alert-2" />
                            </div>
                            Нерезидент
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (colName === 'status') {
        const supportProps =
            join.statusDate &&
            setCursorSupport(
                {
                    _id: `status-${join._id}`,
                    content: getFormatedDate({ date: new Date(join.statusDate), type: 'full' }),
                    dir: 'top',
                },
                { targetClassName: '.v2table__statusInfo', className: '' },
            );

        content = (
            <>
                <div className={`v2table__status _withInfo _block _join-${join.status}`}>
                    {join.statusText}
                    <i className="v2table__statusInfo _CLICK" {...supportProps}>
                        <Icon name="info" />
                    </i>
                </div>
            </>
        );
    }

    if (colName === 'comment') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            join: JoinT;
        }) => CursorActionsItemT[];
        className = '_full _withMore';

        content = (
            <>
                <More id={`join-${join._id}`} items={getMoreActions({ join })} />
                {join.executorComments && join.executorComments.length > 0 ? (
                    <>
                        {join.executorComments.map((comment, commentKey) => {
                            const userName =
                                comment.idOfCreater === 'system'
                                    ? 'LIVECARGO'
                                    : getUserName({ user: comment.creater, type: 'short' });

                            return (
                                <div className="v2table__comment" key={commentKey}>
                                    <div className="v2table__text _grey">
                                        {getFormatedDate({
                                            date: new Date(comment.date),
                                            type: 'short',
                                        })}
                                        , {userName || '–'}:{' '}
                                    </div>
                                    <div
                                        className="v2table__text _wrapWord"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(comment.content),
                                        }}
                                    ></div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderJoins;
