import React from 'react';

import RoleT from '@global/models/Role.ts';

import { RenderColsT } from '../../types.ts';

const renderListRoles: RenderColsT<'listRoles'> = function ({ name: colName, item }) {
    let content;
    let className;

    const role = item as Pick<RoleT, '_id' | 'name'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{role.name}&nbsp;&nbsp;</div>
            </>
        );
    }

    return { content, className };
};

export default renderListRoles;
