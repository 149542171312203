import I from '../types.ts';

const updateList: I['updateList'] = function ({ detail: { id } }) {
    const { listId } = this.props;

    if (listId && id === listId) {
        this.setState({
            items: this.props.items.map((item) => ({
                ...item,
                isShow: true,
                version: new Date().getTime(),
            })),
        });
    }
};

export default updateList;
