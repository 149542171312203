import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import Pages from '@components/pages/Pages.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getDescription from './methods/getDescription.ts';
import getQuery from './methods/getQuery.ts';

import SettingsI from './types.ts';

import innerPages from './static/innerPages.tsx';
import pages from './static/pages.tsx';

class Settings extends WidgetWrapper<SettingsI['props'], SettingsI['state']> implements SettingsI {
    constructor(props: SettingsI['props']) {
        super(props);
        this.state = {};
    }

    url = 'corporation';
    pages = pages;
    innerPages = innerPages;

    getActions = getActions;
    getQuery = getQuery;
    getDescription = getDescription;

    componentDidMount(): void {
        this.getModel();

        this.setState({ user: this.props.user });
    }

    render() {
        const { model, updatedModelKey } = this.state;
        const { inner } = this.props;
        const resultPages = inner ? this.innerPages : this.pages;
        const title = inner ? 'Компания' : 'Настройки';

        return (
            <>
                <Widget
                    name="corporationSettings"
                    title={title}
                    descriptionInner={this.getDescription()}
                    actions={this.getActions!()}
                    isFull={true}
                >
                    <LoaderBlock
                        className="v2widget__contentLoader"
                        isShow={!model}
                        loaderClassName="_main"
                    />
                    <div className={`v2widget__contentWait ${model ? '_show' : ''}`}>
                        {model && (
                            <Pages
                                className="body__innerPages _ITEMS"
                                classNamePage="body__innerPage _ITEM"
                                filter={(page) =>
                                    page.parentName === (inner ? 'corporations-inner' : 'settings')
                                }
                                pages={resultPages}
                                context={this}
                                keyRender={updatedModelKey}
                            />
                        )}
                    </div>
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        levels: state.levels,
        user: state.user,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(Settings);
