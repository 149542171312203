import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import downloadFile from '../functions/downloadFile';
import handlerPopup from '../functions/app/handlerPopup';

export default function exportItems({ query, type, pageName, fields, inPopup, filename }) {
    return new Promise((resolve) => {
        axios
            .post(
                `${process.env.REACT_APP_API}/export?${query}`, {
                    type,
                    pageName,
                    fields,
                }, {
                    responseType: 'blob',
                    headers: getHeaders(),
                },
            )
            .then(
                (res) => {
                    res.data.text().then(
                        (result) => {
                            try {
                                const { success } = JSON.parse(result);

                                if (success === false) {
                                    this.handlerLoadingKey(null);

                                    return;
                                }
                            } catch (error) {
                                const resultFilename = `${filename}.xlsx`;

                                downloadFile({ filename: resultFilename, data: res.data });

                                if (inPopup) {
                                    handlerPopup({
                                        name: 'exportPopup',
                                        isShow: false,
                                    });
                                }
                            }

                            resolve();
                        },
                        () => null,
                    );
                },
                () => null,
            );
    });
}