import handlerLoading from '@functions/handlerLoading.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const propsSave = this.props.save;

    if (propsSave) {
        await handlerLoading.call(this, 'save');

        try {
            await propsSave();
        } catch (error) {}

        await handlerLoading.call(this, undefined);
    }
};

export default save;
