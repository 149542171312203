import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import Icon from '@components/icon/Icon.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { model, loadingKey, isEdit, error } = this.state;
    const title = isEdit ? `Акт №${model?.number}` : 'Создание нового акта';
    const buttonText = isEdit ? `Сохранить` : 'Создать акт';

    return (
        <>
            <div className="v2popup__head _ROW">
                <div className="v2popup__title">{title}</div>
                <div className="v2popup__close _CLICK" onClick={this.close.bind(this)}>
                    <Icon name="popup-close" />
                </div>
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <div className="v2popup__contentInner">
                    {model && (
                        <WidgetCard
                            name="payEdit"
                            model={model}
                            change={this.change.bind(this)}
                            getValue={this.getValue.bind(this)}
                            withHead={false}
                            className="_empty"
                            error={error}
                        />
                    )}
                </div>
            </div>
            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _ROW">
                        <div className="v2popupFoot__button">
                            <Button
                                className="_main"
                                onClick={this.save.bind(this)}
                                loading={loadingKey === 'save'}
                                disabled={!this.checkChange().isChange}
                            >
                                {buttonText}
                            </Button>
                        </div>
                        <div className="v2popupFoot__info">
                            <p className="v2popupFoot__infoTitle">
                                Итого: {getFormatPrice(+(+(model?.amount || 0)).toFixed(2))}₽
                            </p>
                            <div className="v2popupFoot__infoText">
                                Комиссия: {getFormatPrice(this.getCommission())}₽
                            </div>
                        </div>
                    </div>
                    <AnimateChange
                        className="v2popupFoot__error"
                        renderKey={error?.text}
                        styles={['height']}
                        paramsParent={{ width: true, height: 'auto' }}
                    >
                        <>{error?.text}</>
                    </AnimateChange>
                </div>
            </div>
        </>
    );
};

export default renderContent;
