import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { executorId, corporationId } = this.state;
    const change = this.checkChange(true);

    const fields = {
        corporationId,
        limits: {
            ...change.model!,
        },
    };

    await handlerLoading.call(this, 'save');

    const response = await axios.patch<
        {},
        {
            data: ReqResponseT<{
                message: string;
                error?: { name: string; text: string };
            }>;
        }
    >(
        `${process.env.REACT_APP_API}/executor`,
        {
            id: executorId,
            fields,
        },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        this.close();

        document.dispatchEvent(
            new CustomEvent('refreshTable', { detail: { name: 'executorsLimits' } }),
        );
    } else {
        const { error } = data;

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default save;
