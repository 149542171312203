import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types.ts';

import listStateHandler from './methods/listStateHandler.ts';
import loadingHandler from './methods/loadingHandler.ts';
import searchHandler from './methods/searchHandler.ts';
import setSize from './methods/setSize.ts';

import WidgetI from './types.ts';

import renderBack from './renders/renderBack.tsx';
import renderHead from './renders/renderHead.tsx';
import renderHeadAction from './renders/renderHeadAction.tsx';
import renderHeadActions from './renders/renderHeadActions.tsx';

class Widget extends React.Component<WidgetI['props'], WidgetI['state']> implements WidgetI {
    parent: WidgetI['parent'];

    constructor(props: WidgetI['props']) {
        super(props);
        this.state = {
            listStates: {},
            loadings: {},
            searchs: {},
        };

        this.setSize = this.setSize.bind(this);

        this.parent = React.createRef();
    }

    setSize = setSize;
    listStateHandler = listStateHandler;
    loadingHandler = loadingHandler;
    searchHandler = searchHandler;

    renderHead = renderHead;
    renderHeadActions = renderHeadActions;
    renderHeadAction = renderHeadAction;
    renderBack = renderBack;

    componentDidMount(): void {
        this.setSize();

        document.addEventListener('changeHeightWindow', this.setSize);
    }

    componentWillUnmount(): void {
        document.removeEventListener('changeHeightWindow', this.setSize);
    }

    render() {
        const {
            children,
            isFull,
            className = '',
            inWidget,
            isInit = true,
            showList,
            contentHeight,
            id,
        } = this.props;

        return (
            <div
                ref={this.parent}
                className={`v2widget _ready _fix _parent ${className} ${inWidget ? '_inWidget' : ''} ${isInit ? '_init' : ''} ${showList === false ? '_hideContent' : ''} ${id ? `id${id}` : ''}`}
            >
                {this.renderHead()}
                <div
                    className={`v2widget__content ${isFull ? '_full' : ''}`}
                    style={
                        typeof contentHeight === 'number' ? { height: `${contentHeight}px` } : {}
                    }
                >
                    {children}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        changesPageCounter: state.changesPageCounter,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(Widget);
