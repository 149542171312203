import setAnimate from './setAnimate';

export default function scrollToBlock({ scrollBlock, block, offset = 0 }) {
    const { scrollTop } = scrollBlock;

    const offsetBlock = block.getBoundingClientRect().y;

    setAnimate({
        draw: (progress) => {
            scrollBlock.scrollTo({
                top: scrollTop - progress * (-offsetBlock + offset),
            });
        },
        duration: 300,
    });
}
