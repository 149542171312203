import JoinContractT from '@global/models/JoinContract.ts';

import ContractsI from '../types.ts';

const handlerSocket: ContractsI['handlerSocket'] = async function ({ detail }) {
    const { name, data } = detail;

    if (name === 'joinContracts') {
        const { fields } = data;
        const joinContracts = fields.joinContracts as JoinContractT[];

        if (joinContracts) {
            this.tableUpdateManyItems!({ items: joinContracts });
        } else {
            this.tableUpdateItem!({ id: data.id, fields: data.fields });
        }
    }
};

export default handlerSocket;
