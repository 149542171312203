import FilterI from '../types.ts';

const handlerKey: FilterI['handlerKey'] = function (e) {
    const { keyCode } = e;

    if (keyCode === 27) {
        this.close();
    }

    if (keyCode === 13) {
        const { filterQuery } = this.state;
        const { isChange } = this.checkChange();

        if (isChange) {
            this.send(this.getChange());
        } else if (filterQuery && filterQuery.length) {
            this.send([]);
        } else {
            this.close();
        }
    }
};

export default handlerKey;
