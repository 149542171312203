import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import MainI from '../types.ts';

const deletePays: MainI['deletePays'] = async function (ids) {
    const { groupId } = this.props;

    try {
        const response = await axios.patch<{}, { data: ReqResponseT }>(
            `${process.env.REACT_APP_HARD_API}/pays`,
            {
                id: groupId,
                action: 'delete-pays',
                itemsIds: ids,
            },
            { headers: getHeaders() },
        );

        const { success } = response.data;

        if (success) {
            setNotification({ notification: 'success-change-info' });

            return;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
};

export default deletePays;
