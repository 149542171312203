import React from 'react';

import Checkbox from '@components/checkbox/Checkbox.tsx';

import I from '../types.ts';

const renderCheckbox: I['renderCheckbox'] = function () {
    const { name, value, onChange, checkboxTitle, checkboxDescription, disabled } = this.props;

    return (
        <>
            <div className="v2widgetField__field _checkbox">
                <label className="v2widgetField__checkbox">
                    <div className="v2widgetField__checkboxBox">
                        <Checkbox
                            name={name}
                            onChange={() => {
                                onChange({ [name]: !value });
                            }}
                            disabled={disabled}
                            value={!!value}
                        />
                    </div>
                    <div className="v2widgetField__checkboxTitle">{checkboxTitle}</div>
                    {checkboxDescription && (
                        <div
                            className="v2widgetField__checkboxDescription"
                            dangerouslySetInnerHTML={{ __html: checkboxDescription }}
                        ></div>
                    )}
                </label>
            </div>
        </>
    );
};

export default renderCheckbox;
