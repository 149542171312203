import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import { PageNamesT } from '@redux/pages.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function (this: I) {
    const { user, model } = this.state;
    const { inner } = this.props;

    if (inner) {
        const actions: WidgetActionsT[] = [
            {
                type: 'link',
                name: 'main',
                group: 'links',
                pageName: 'corporations-inner-main',
            },
            {
                type: 'link',
                name: 'operations',
                group: 'links',
                pageName: 'corporations-inner-operations',
            },
            {
                type: 'link',
                name: 'docs',
                group: 'links',
                pageName: 'corporations-inner-docs',
            },
            {
                type: 'link',
                name: 'users',
                group: 'links',
                pageName: 'corporations-inner-users',
            },
            {
                type: 'link',
                name: 'logs',
                group: 'links',
                pageName: 'corporations-inner-logs',
            },
        ];

        return actions;
    }

    const actions: WidgetActionsT[] = [];

    if (getPermissions(user, { key: 'settings', items: [{ key: 'main', rules: ['read'] }] })) {
        actions.push({
            type: 'link',
            name: 'main',
            group: 'links',
            pageName: 'settings-main',
        });
    }

    if (
        getPermissions(user, { key: 'settings', items: [{ key: 'operations', rules: ['read'] }] })
    ) {
        actions.push({
            type: 'link',
            name: 'operations',
            group: 'links',
            pageName: 'settings-operations',
        });
    }

    if (getPermissions(user, { key: 'settings', items: [{ key: 'users', rules: ['read'] }] })) {
        actions.push({
            type: 'link',
            name: 'users',
            group: 'links',
            pageName: 'settings-users',
        });
    }

    const otherPages: { name: string; pageName: PageNamesT['crm']; forAdmin?: boolean }[] = [
        { name: 'roles', pageName: 'settings-roles' },
        { name: 'proxy', pageName: 'settings-proxy' },
        { name: 'integrations', pageName: 'settings-integrations' },
        { name: 'reports', pageName: 'settings-reports' },
        { name: 'incomes', pageName: 'settings-incomes', forAdmin: true },
        { name: 'logs', pageName: 'settings-logs' },
    ];

    const currentOtherPages = otherPages.filter((page) =>
        getPermissions(user, {
            adminCorporation: !!page.forAdmin,
            key: 'settings',
            items: [{ key: page.name, rules: ['read'] }],
        }),
    );

    if (currentOtherPages.length) {
        actions.push({
            type: 'link',
            name: 'more',
            content: 'Ещё',
            group: 'links',
            className: '_white',
            list: currentOtherPages,
        });
    }

    if (user && user.idOfCurrentCorporation !== 'admin') {
        actions.splice(2, 0, {
            type: 'link',
            name: 'docs',
            group: 'links',
            pageName: 'settings-docs',
            className: model && model.verification.status !== 'active' ? '_alert' : '',
        });
    }

    return actions;
};

export default getActions;
