import axios from 'axios';
import getHeaders from '../functions/getHeaders';

export default function changeCardNumberFromChat({ id, cardNumber }) {
    return new Promise((resolve, reject) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/chat`,
                { id, action: 'set-card-number', cardNumber },
                { headers: getHeaders() },
            )
            .then((res) => {
                const { success } = res.data;

                if (success) {
                    resolve();
                } else {
                    reject();
                }
            });
    });
}
