import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import ModelsPage from '@components/crm/modelsPage/ModelsPage.tsx';

import Pages from '../../components/Pages.jsx';

import ManualDocs from './manual/docs/Docs.tsx';
import ManualExecutors from './manual/executors/Executors.tsx';

class Manual extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = {
        'manual-executors': {
            render() {
                return <ManualExecutors />;
            },
        },
        'manual-tags': {
            render() {
                return <ModelsPage type="tags" />;
            },
        },
        'manual-docs': {
            render() {
                return <ManualDocs />;
            },
        },
        'manual-cities': {
            render() {
                return <ModelsPage type="cities" />;
            },
        },
        'manual-countries': {
            render() {
                return <ModelsPage type="countries" />;
            },
        },
        'manual-executorTypes': {
            render() {
                return <ModelsPage type="executorTypes" />;
            },
        },
    };

    render() {
        return (
            <div ref={this.parent} className="manual">
                <Pages
                    classNamePage="manual__page"
                    filter={(page) => page.parentName === 'manual' && page.level === 2}
                    pages={this.pages}
                    context={this}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Manual);

Manual.propTypes = {
    user: PropTypes.object,
};
