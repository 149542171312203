import React from 'react';

import I from '../types.ts';

import renderCode from './blocks/renderCode.tsx';
import renderItems from './blocks/renderItems.tsx';

const renderBlock: I['renderBlock'] = function ({ prop }) {
    return (
        <div className="v2popup__block _ITEM">
            {prop === 'items' && renderItems.call(this)}
            {prop === 'code' && renderCode.call(this)}
        </div>
    );
};

export default renderBlock;
