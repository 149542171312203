import { store } from '@redux/redux';

import getSalt from './getSalt';
import { getCookie } from './handlerCookies';

type BodyT = {
    id?: string;
};

type HeadersT = {
    typeOfSystem: string;
    device: string;
    userAgent: string;
    hash?: string;
    salt?: string;
    userId?: string;
    siteorigin?: string;
    version?: string;
    idOfCurrentCorporation?: string;
    timezoneOffset?: string;
    joinToken?: string;
};

export default function getHeaders(settings?: BodyT): HeadersT {
    const user = store.getState().user;
    const userId = user?._id || settings?.id;
    const headers: HeadersT = {
        typeOfSystem: process.env.REACT_APP_SYSTEM!,
        device: store.getState().device,
        userAgent: navigator.userAgent,
    };
    const joinToken = getCookie('joinToken');

    if (getCookie(process.env.REACT_APP_HASH)) {
        headers.hash = getCookie(process.env.REACT_APP_HASH);
        headers.salt = getSalt({ id: userId })!;
    }

    if (process.env.REACT_APP_SYSTEM === 'crm') {
        headers.siteorigin = process.env.REACT_APP_CRM_DOMEN;
    }

    if (process.env.REACT_APP_SYSTEM === 'app') {
        headers.siteorigin = process.env.REACT_APP_APP_DOMEN;
    }

    if (user) {
        headers.userId = userId;

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            headers.version = user.systemVersion;
            headers.idOfCurrentCorporation = user.idOfCurrentCorporation;
        }

        if (process.env.REACT_APP_SYSTEM === 'app') {
            headers.version = user.systemVersion;
        }
    }

    if (joinToken) {
        headers.joinToken = joinToken;
    }

    headers.timezoneOffset = (new Date().getTimezoneOffset() / 60).toString();

    return headers;
}
