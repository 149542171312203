import React from 'react';

import WidgetField from '@components/crm/widgetField/WidgetField.tsx';

import I from '../../types.ts';

const renderUserMain: I['renderCards'] = function () {
    const { model, name, change, getValue, prop, data } = this.props;

    const onChange = async (
        changedFields: Parameters<typeof change>[0],
    ): Promise<Awaited<ReturnType<typeof change>>> => {
        await change({ ...changedFields }, undefined, prop);
    };

    if (data?.fromCorporation) {
        return <>{this.renderFields()}</>;
    }

    return (
        <>
            <div className="v2widgetCard__blocks _ROW">
                <div className="v2widgetCard__block _logo">
                    <WidgetField
                        model={model}
                        support="Аватар"
                        name="logo"
                        prop="logo.src"
                        fileProp="logo.fullSrc"
                        type="file"
                        cardName={name}
                        onChange={onChange}
                        value={getValue({ key: 'logo.fullSrc' })?.value}
                        fileAccept={['jpeg', 'jpg']}
                    />
                </div>
                <div className="v2widgetCard__block _info">{this.renderFields()}</div>
            </div>
        </>
    );
};

export default renderUserMain;
