import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ExecutorM } from '@global/models/Executor.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { currentType } = this.state;
    const model = this.state.model!;
    const { walletPopup } = this.props;
    const { setModel, executorId } = walletPopup;
    const change = this.checkChange(true);

    const fields: Record<any, unknown> = change.model!;

    const body: Record<any, unknown> = {
        id: executorId,

        fields: {
            wallet: {
                _id: model._id,
                type: currentType,
                ...fields,
            },
        },
    };

    await handlerLoading.call(this, 'save');

    let response;

    try {
        response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: ExecutorM;
                    error?: { name: string; text: string };
                }>;
            }
        >(`${process.env.REACT_APP_API}/executor`, { ...body }, { headers: getHeaders() });
    } catch (error) {
        await handlerLoading.call(this, undefined);

        return;
    }

    const { success, data } = response.data;

    if (success) {
        const { model: resultModel } = data;

        if (setModel) {
            setModel(resultModel);
        }

        this.close();
    } else {
        const { error, message } = data;

        if (message === 'Executor is verification') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'changeExecutorDeniedCauseCorpVerify',
                denied: true,
            });
        }

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default save;
