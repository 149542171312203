import axios from 'axios';

import { ReqResponseT } from '@global/types';

import getHeaders from '../functions/getHeaders';

type ParamsT = {
    formData: FormData;
};

type ReturnT = {
    fullSrc: string;
    src: string;
    name: string;
    size?: number;
};

export default async function uploadFile({ formData }: ParamsT): Promise<ReturnT> {
    let response;

    try {
        response = await axios.post<{}, { data: ReqResponseT<ReturnT> }>(
            `${process.env.REACT_APP_API}/upload-file`,
            formData,
            { headers: getHeaders() },
        );
    } catch (error) {
        return Promise.reject(error);
    }

    const { success, data } = response.data;

    if (success) {
        return data;
    }

    return Promise.reject(data);
}
