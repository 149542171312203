import React from 'react';

import getFormatedDate from '@functions/getFormatedDate.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import JoinTemplateT from '@global/models/JoinTemplate.ts';

import { RenderColsT } from '../../types.ts';

const renderJoinsTemplates: RenderColsT<'joinsTemplates'> = function ({ name: colName, item }) {
    let content;
    let className;

    const joinsTemplate = item as JoinTemplateT;

    if (colName === 'name') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(joinsTemplate.name) }}
                ></div>
            </>
        );
    }

    if (colName === 'date') {
        content = (
            <>
                <div className="v2table__text">
                    {getFormatedDate({ date: new Date(joinsTemplate.createdDate) })}
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderJoinsTemplates;
