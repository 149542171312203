import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import save from './methods/save.ts';

import Interface from './types.ts';

import renderContent from './renders/renderContent.tsx';

class CorporationCreate
    extends EditBlock<Interface['props'], Interface['state']>
    implements Interface
{
    constructor(props: Interface['props']) {
        super(props);
        this.state = {};
    }

    close = close;
    save = save;

    renderContent = renderContent;

    componentDidMount() {
        this.init({
            fields: {
                v2logo: {},
            },
        });
    }

    render() {
        return (
            <Popup name="corporationCreatePopup" close={this.close.bind(this)}>
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        corporationCreatePopup: state.corporationCreatePopup,
    };
}

export default connect(mapStateToProps)(CorporationCreate);
