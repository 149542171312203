import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Avatar from '@components/avatar/Avatar.tsx';

import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import getUserInfo from '../../../functions/getUserInfo';
import handlerWindow from '../../../functions/handlerWindow';

import AnimateChange from '../../AnimateChange.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Icon from '../../Icon.jsx';

class SideBarUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHide: false,
        };

        this.parent = React.createRef();
    }

    componentDidMount() {
        this.setState({ user: this.props.user });
    }

    render() {
        const { user } = this.state;
        const { parent } = this.props;
        const userName = user ? getUserInfo({ type: 'nameShort', user }) : '';
        const currentCorporation = getCurrentCorporation({ user });

        return (
            <div ref={this.parent} className="sideBarUser">
                <div className="sideBarUser__inner _row">
                    <div className="sideBarUser__preview _col">
                        <Avatar
                            image={user?.logo?.fullSrc}
                            firstSymb={user?.secondName?.[0]}
                            secondSymb={user?.firstName?.[0]}
                        />
                    </div>
                    <div className="sideBarUser__info">
                        <div className="sideBarUser__infoInner _col">
                            <div className="sideBarUser__name">
                                <AnimateChange
                                    className="sideBarUser__nameInner"
                                    prop={userName}
                                    type="_translateMedium"
                                >
                                    {userName}
                                </AnimateChange>
                            </div>
                            <AnimateChangeUp
                                className="sideBarUser__description"
                                renderKey={currentCorporation?.roleName}
                                paramsParent={{
                                    width:
                                        this.parent.current?.querySelector(
                                            '.sideBarUser__infoInner',
                                        )?.offsetWidth || true,
                                    height: 'auto',
                                }}
                            >
                                <>{currentCorporation?.roleName || '–'}</>
                            </AnimateChangeUp>
                            <i
                                className="sideBarUser__settings _click"
                                onClick={(e) => {
                                    handlerWindow({
                                        parent,
                                        parentResize: parent,
                                        target: e.target.closest('.sideBarUser__settings'),
                                        action: 'show',
                                        name: 'userActions',
                                        className: '_actions',
                                        uniqKey: 'sideBar',
                                        centers: {
                                            left: 0.5,
                                            top: 0,
                                        },
                                    });
                                }}
                            >
                                <Icon name="more-vertical" />
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isProccessLogin: state.isProccessLogin,
    };
}

export default connect(mapStateToProps)(SideBarUser);

SideBarUser.propTypes = {
    user: PropTypes.object,
    isProccessLogin: PropTypes.bool,
    parent: PropTypes.object,
};
