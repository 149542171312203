import CalendarI, { DayT } from '../types.ts';

const getDays: CalendarI['getDays'] = function (
    this: CalendarI,
    activeDate = this.state.activeDate,
) {
    const { currentDate, doubleCurrentDate } = this.state;
    const { isDouble, withPast } = this.props;
    const days: DayT[] = [];

    if (activeDate) {
        const year = activeDate.getFullYear();
        const month = activeDate.getMonth();

        const date = new Date();

        date.setFullYear(year);
        date.setDate(1);
        date.setMonth(month);
        date.setHours(12, 0, 0, 0);

        const startDay = date.getDay() || 7;

        const startDate = new Date(date);

        startDate.setDate(startDate.getDate() - (startDay - 1));

        const nowDate = new Date();

        nowDate.setHours(12, 0, 0, 0);

        for (let i = 0; i < 6 * 7; i++) {
            const thisDate = new Date(startDate);

            thisDate.setDate(thisDate.getDate() + i);

            const id = this.getId(thisDate);
            const disabled = withPast ? this.getId(nowDate) < id : this.getId(nowDate) > id;

            let isCurrent = this.getId(currentDate) === id;
            let inside = false;
            const isNow = id === this.getId(nowDate);

            if (isDouble) {
                if (doubleCurrentDate) {
                    isCurrent =
                        id === this.getId(currentDate) || id === this.getId(doubleCurrentDate);
                    inside = id > this.getId(currentDate) && id < this.getId(doubleCurrentDate);
                }
            }

            days.push({
                id,
                dayIndex: thisDate.getDay() || 7,
                weekIndex: Math.floor(i / 7),
                day: thisDate.getDate(),
                month: thisDate.getMonth(),
                year: thisDate.getFullYear(),
                isMonthCurrent: thisDate.getMonth() === date.getMonth(),
                isCurrent,
                time: thisDate.getTime(),
                disabled,
                inside,
                isNow,
            });
        }
    }

    return days;
};

export default getDays;
