import I from '../types.ts';

const currentsHandlerCallback: I['currentsHandlerCallback'] = function (this: I, id, currentItem) {
    const { callback } = this.props;

    if (callback) {
        callback(id, currentItem);
    }
};

export default currentsHandlerCallback;
