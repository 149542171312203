import getFormatedDate from '@functions/getFormatedDate.ts';

import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { payPopup } = this.props;
    const model: I['state']['model'] = {
        _id: 'pay',
    };
    const { groupId, isEdit, pay } = payPopup;

    if (groupId && groupId !== 'main') {
        model.groupId = groupId;
    }

    if (pay) {
        model.name = pay.name;

        if (pay.executorId) {
            model.userId = pay.executorId;
            model.executorName = pay.userName;
        }

        const startPeriod = getFormatedDate({ date: new Date(pay.startPeriod) });
        const endPeriod = getFormatedDate({ date: new Date(pay.endPeriod) });

        model.period = `${startPeriod} – ${endPeriod}`;

        if (isEdit) {
            model._id = pay._id;
            model.number = pay.number;
            model.amount = (pay.startAmount ? pay.startAmount : pay.amount).toString();
            model.projectId = pay.projectId;
            model.projectName = pay.projectName;
            model.date = getFormatedDate({ date: new Date(pay.date) });
            model.isNotExecutorSign = !!pay.executorSign.isNotSign;
        }
    }

    if (isEdit) {
        this.setState({ isEdit });
    }

    this.init({ fields: model, start: !isEdit });
};

export default mountHandler;
