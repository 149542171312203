import React from 'react';

import Button from '@components/button/Button.tsx';
import Table from '@components/crm/table/Table.tsx';
import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { loadingKey, sectionKey } = this.state;
    const model = this.state.model!;
    const section = model.sections.find((innerSection) => innerSection.key === sectionKey);

    return (
        <>
            <div className="v2popup__head _COL">
                <div className="v2popup__title">Раздел — {section?.name}</div>
                <div className="v2popup__description _bottom _bigSize">
                    Настройка раздела для роли {model.name}
                </div>
                {this.renderAllCheckbox()}
                <div className="v2popup__close _CLICK _top" onClick={this.close.bind(this)}>
                    <Icon name="popup-close" />
                </div>
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <Table
                    name="corporationRoleSection"
                    isInit={true}
                    isNotLink={true}
                    inWidget={true}
                    staticItems={section!.items}
                    maxHeight={400}
                    minHeight={400}
                    otherData={{
                        role: model,
                        section,
                        change: this.change.bind(this),
                    }}
                />
            </div>
            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _ROW">
                        <div className="v2popupFoot__button">
                            <Button
                                className="_main"
                                onClick={this.save.bind(this)}
                                loading={loadingKey === 'save'}
                                disabled={!this.checkChange().isChange}
                            >
                                Сохранить изменения
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderContent;
