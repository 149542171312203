import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import PayT from '@global/models/Pay.ts';
import { ReqResponseT } from '@global/types.ts';

import Interface from '../types.ts';

const checkError: Interface['checkError'] = async function ({ pay }) {
    try {
        const response = await axios.patch<{}, { data: ReqResponseT<{ pay: PayT }> }>(
            `${process.env.REACT_APP_API}/pays`,
            { action: 'check-error', id: pay._id },
            { headers: getHeaders() },
        );

        const { success, data } = response.data;

        if (success) {
            const { pay: resultPay } = data;

            await this.tableUpdateItem!({ id: resultPay._id, fields: resultPay });

            return;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
};

export default checkError;
