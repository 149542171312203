import React from 'react';
import { connect } from 'react-redux';

import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';

import save from './methods/save.ts';

import MainI from './types.ts';

class Main extends WidgetPageWrapper<MainI['props'], MainI['state']> implements MainI {
    parent: MainI['parent'];

    constructor(props: MainI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    save = save;

    render() {
        const { model, error } = this.state;

        return (
            <>
                <WidgetPage
                    name="profile"
                    save={this.save.bind(this)}
                    disabled={!this.checkChange().isChange}
                    error={error}
                >
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__card _bottom">
                                    <WidgetCard
                                        name="userMain"
                                        model={model}
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                    />
                                </div>
                                <div className="v2widgetPage__card">
                                    <WidgetCard
                                        name="userPassport"
                                        model={model}
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                        data={{
                                            isEAES: true,
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Main);
