import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { executorLimitsPopup } = this.props;
    const { executorId, executorName, corporationId, corporationName, limits } =
        executorLimitsPopup;
    const model: I['state']['model'] = {
        _id: 'user',
        ...limits,
    };

    this.init({ fields: model });

    this.setState({ executorId, executorName, corporationId, corporationName });
};

export default mountHandler;
