import ItemsI, { ItemT } from '../types.ts';

const deleteItem: ItemsI['deleteItem'] = function (this: ItemsI, { id }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state } as ItemsI['state'];
            const items: ItemT[] = JSON.parse(JSON.stringify(newState.items || []));
            const itemIndex = items.findIndex((item) => item._id === id);

            if (itemIndex !== -1) {
                items.splice(itemIndex, 1);
            }

            if (newState.counter) {
                newState.counter -= 1;
            }

            newState.items = items;
            newState.updatedItemKey = new Date().getTime();

            return newState;
        }, resolve);
    });
};

export default deleteItem;
