import ListDynamicI, { IndexesStateItemsT } from '../types.ts';

const deleteItems: ListDynamicI['deleteItems'] = function () {
    const { callback, proccessCallback } = this.props;

    if (callback) {
        callback({ isChangeLen: true });
    }

    const indexesStateItems: IndexesStateItemsT = {};

    (this.state.items || []).forEach((item, key) => {
        indexesStateItems[this.getItem(item)] = key;
    });

    this.deleteItemsTimer = setTimeout(() => {
        this.setState(
            (state) => {
                const newState = { ...state };
                const items = JSON.parse(JSON.stringify(newState.items || []));
                const itemsDelete = JSON.parse(JSON.stringify(Object.keys(this.itemsDelete))).sort(
                    (a: string, b: string) =>
                        (indexesStateItems[b] ?? -1) - (indexesStateItems[a] ?? -1),
                );

                itemsDelete.forEach((id: string) => {
                    if (!this.itemsNotDelete[id]) {
                        const index = indexesStateItems[id.toString()] ?? -1;

                        if (index !== -1) {
                            items.splice(index, 1);
                        }
                    }
                });

                newState.items = items;

                return newState;
            },
            () => {
                this.itemsDelete = {};
                this.itemsNotDelete = {};

                if (proccessCallback) {
                    proccessCallback({ type: 'delete' });
                }
            },
        );
    }, 300);
};

export default deleteItems;
