import removeTransition from '@functions/removeTransition.ts';

import AnimateI from '../types.ts';

const checkStateOfAction: AnimateI['checkStateOfAction'] = function ({ isInit }) {
    const {
        isShow,
        actionInit = () => null,
        actionPrevRemove = () => null,
        actionAfterRemove = () => null,
        animateForInit = false,
        isSmoothShow = false,
        className,
    } = this.props;

    if (isInit && !animateForInit) {
        if (this.stateOfAnimate === -1 && isShow) {
            setTimeout(
                () => {
                    this.setStateOfAnimate(1).then(() => {
                        setTimeout(() => {
                            if (!isSmoothShow && className) {
                                removeTransition({ item: `.${className}` });
                            }
                        }, 10);

                        actionInit();
                    });
                },
                isSmoothShow ? 10 : 0,
            );
        }

        if (this.stateOfAnimate === 1 && !isShow) {
            this.setStateOfAnimate(-1);
        }
    } else if (this.isShow !== isShow) {
        if (this.stateOfAnimate !== 1 && isShow) {
            this.setStateOfAnimate(0).then(() => {
                if (this.timerIdHide) {
                    clearTimeout(this.timerIdHide);

                    this.timerIdHide = undefined;
                }

                this.timerIdShow = setTimeout(() => {
                    this.setStateOfAnimate(1).then(actionInit);
                }, 10);
            });
        }

        if (this.stateOfAnimate === 1 && !isShow) {
            this.setStateOfAnimate(0).then(() => {
                if (this.timerIdShow) {
                    clearTimeout(this.timerIdShow);

                    this.timerIdShow = undefined;
                }

                actionPrevRemove();

                this.timerIdHide = setTimeout(() => {
                    actionAfterRemove();

                    this.setStateOfAnimate(-1);
                }, 310);
            });
        }
    }
};

export default checkStateOfAction;
