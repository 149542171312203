import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = function () {
    handlerPopup('corporationAmountDocPopup', {
        isShow: false,
        id: null,
    });
};

export default close;
