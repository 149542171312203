import React from 'react';

import Main from '../components/Main/Main.tsx';

import Inner from '../Inner.jsx';

const pages = {
    'joins-templates-main': {
        render() {
            return (
                <>
                    <Main />
                </>
            );
        },
    },
    'joins-templates-inner': {
        render() {
            return (
                <>
                    <Inner />
                </>
            );
        },
    },
} as const;

export default pages;
