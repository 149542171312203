import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dispatcher } from '../redux/redux';

import Icon from './Icon.jsx';

import { regs } from '../functions/inputValidate';
import InputMask from './InputMask.jsx';
import Loader from './Loader.jsx';
import Animate from './Animate.jsx';

const fields = require('../infos/fields.json');

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocus: false,
        };

        this.handlerInput = this.handlerInput.bind(this);

        this.input = React.createRef();
    }

    handlerInput({ action, value }) {
        const { regType, callback } = this.props;

        return new Promise((resolve) => {
            switch (action) {
                case 'focus':
                    dispatcher({ type: 'isInputFocus', data: true });

                    this.setState(
                        {
                            isFocus: true,
                        },
                        () => {
                            if (callback && typeof callback === 'function') {
                                callback({ action: 'focus', value: true });
                            }

                            resolve();
                        },
                    );
                    break;
                case 'blur':
                    dispatcher({ type: 'isInputFocus', data: false });

                    this.setState(
                        {
                            isFocus: false,
                        },
                        () => {
                            if (callback && typeof callback === 'function') {
                                callback({ action: 'focus', value: false });
                            }

                            resolve();
                        },
                    );
                    break;
                case 'change':
                    if (callback && typeof callback === 'function') {
                        if (regs[regType]) {
                            value = value.replace(regs[regType], '');
                        }

                        if (regType === 'numberCar') {
                            value = value.toUpperCase();
                        }

                        callback({ action: 'change', value }).then(resolve);
                    }
                    break;
                default:
                    break;
            }
        });
    }

    render() {
        const { isFocus } = this.state;
        const {
            support,
            name,
            value,
            className = '',
            id = name,
            keyName,
            isShowLoader,
            icon,
        } = this.props;
        const field = keyName && fields[keyName];

        return (
            <div
                className={`search _row _${name} ${className} ${isFocus || value ? '_focus' : ''} ${
                    isFocus ? '_active' : ''
                } ${isShowLoader ? '_showLoader' : ''}`}
            >
                <i className="search__icon">
                    <Icon name={icon || 'search'} />
                </i>
                {keyName === 'phone' ? (
                    <InputMask
                        className="search__input"
                        id={`search-${id}`}
                        value={value}
                        template={field?.template}
                        charDel={field?.charDel}
                        charEmpty={field?.charEmpty}
                        validate={field?.validate}
                        callback={this.handlerInput}
                    />
                ) : (
                    <input
                        ref={this.input}
                        type="text"
                        className="search__input"
                        id={`search-${id}`}
                        onFocus={() => this.handlerInput({ action: 'focus' })}
                        onBlur={() => this.handlerInput({ action: 'blur' })}
                        onChange={(e) =>
                            this.handlerInput({ action: 'change', value: e.target.value })
                        }
                        autoComplete="off"
                        value={value}
                    />
                )}
                <label htmlFor={`search-${id}`} className="search__support _row">
                    {support}
                </label>
                <Animate className="search__loader" isShow={isShowLoader}>
                    <div className="search__loaderItem">
                        <Loader className="_main" />
                    </div>
                </Animate>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Search);

Search.propTypes = {
    support: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    callback: PropTypes.func,
    className: PropTypes.string,
    regType: PropTypes.string,
    id: PropTypes.string,
    keyName: PropTypes.string,
    isShowLoader: PropTypes.bool,
    icon: PropTypes.string,
};
