import React from 'react';
// import PropTypes from 'prop-types';
import getModel from '../../../requests/getModel';
import changePage from '../../../functions/changePage';
import setNotification from '../../../functions/setNotification';
import getPageLink from '../../../functions/getPageLink';

class Inner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerErrorDoc = this.handlerErrorDoc.bind(this);
        this.changeDoc = this.changeDoc.bind(this);
        this.uploadFileDoc = this.uploadFileDoc.bind(this);
        this.deleteFileDoc = this.deleteFileDoc.bind(this);
        this.addDoc = this.addDoc.bind(this);
        this.deleteDoc = this.deleteDoc.bind(this);
        this.backToSave = this.backToSave.bind(this);
        this.backToSaveDocs = this.backToSaveDocs.bind(this);
        this.clearFormDataDocs = this.clearFormDataDocs.bind(this);
        this.handlerErrorGetModel = this.handlerErrorGetModel.bind(this);
    }

    formDataDocs = new FormData();

    handlerErrorDoc({ _id, errors }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const target = JSON.parse(JSON.stringify(newState[this.targetName]));
                const { docs = [] } = target;
                const doc = docs.find((docLoop) => docLoop._id === _id);

                if (doc) {
                    doc.errors = errors;

                    const nameInputFirst = errors[0];

                    if (nameInputFirst) {
                        const input = document.querySelector(`#${nameInputFirst}-${_id}`);

                        if (input) {
                            input.focus();
                        }
                    }
                }

                newState[this.targetName] = target;

                return newState;
            }, resolve);
        });
    }

    changeDoc({ _id, action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const target = JSON.parse(JSON.stringify(newState[this.targetName]));
                    const { docs = [] } = target;
                    const doc = docs.find((docLoop) => docLoop._id === _id);

                    if (doc) {
                        doc[name] = value;

                        if (name === 'dateAction' && value !== 'date') {
                            doc.dateActionString = '';
                        }

                        const { errors = [] } = doc;
                        const indexError = errors.indexOf(name);

                        if (indexError !== -1) {
                            errors.splice(indexError, 1);
                        }
                    }

                    newState[this.targetName] = target;

                    return newState;
                });
            } else {
                resolve();
            }
        });
    }

    addDoc() {
        return new Promise((resolve) => {
            getModel({ name: 'doc', type: 'schema' }).then(({ model: docModel }) => {
                this.setState(
                    (state) => {
                        const newState = { ...state };
                        const target = JSON.parse(JSON.stringify(newState[this.targetName]));
                        const { docs = [] } = target;

                        docs.push({ ...docModel, isNew: true });

                        newState[this.targetName] = target;

                        return newState;
                    },
                    () => {
                        resolve(docModel._id);
                    },
                );
            });
        });
    }

    deleteDoc({ _id }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const target = JSON.parse(JSON.stringify(newState[this.targetName]));
                const { docs = [] } = target;
                const indexDoc = docs.findIndex((doc) => doc._id === _id);

                if (indexDoc !== -1) {
                    docs.splice(indexDoc, 1);
                }

                newState[this.targetName] = target;

                return newState;
            }, resolve);
        });
    }

    uploadFileDoc({ type: idOfDoc, files, _id }) {
        const [file] = files;
        const { object } = file;

        this.formDataDocs.set(_id, object);

        this.setState((state) => {
            const newState = { ...state };
            const target = JSON.parse(JSON.stringify(newState[this.targetName]));
            const { docs = [] } = target;
            const doc = docs.find((docLoop) => docLoop._id === idOfDoc);

            if (doc) {
                const currentFile = doc.files.find((fileLoop) => fileLoop._id === _id);

                if (currentFile) {
                    currentFile.isLocal = true;
                    currentFile.file = file;
                    currentFile.file.pathLocal = file.path;
                }
            }

            newState[this.targetName] = target;

            return newState;
        });
    }

    deleteFileDoc({ type: idOfDoc, _id }) {
        this.formDataDocs.delete(_id);

        this.setState((state) => {
            const newState = { ...state };
            const target = JSON.parse(JSON.stringify(newState[this.targetName]));
            const { docs = [] } = target;
            const doc = docs.find((docLoop) => docLoop._id === idOfDoc);

            if (doc) {
                const currentFile = doc.files.find((fileLoop) => fileLoop._id === _id);

                if (currentFile) {
                    if (!newState.filesDocsDelete) {
                        newState.filesDocsDelete = [];
                    }

                    newState.filesDocsDelete.push(_id);
                    currentFile.file = {};
                }
            }

            newState[this.targetName] = target;

            return newState;
        });
    }

    backToSave() {
        this.setState((state) => {
            const newState = { ...state };
            const targetSave = JSON.parse(JSON.stringify(newState[this.targetNameSave]));

            newState[this.targetName] = targetSave;

            return newState;
        });
    }

    backToSaveDocs() {
        this.setState((state) => {
            const newState = { ...state };
            const target = JSON.parse(JSON.stringify(newState[this.targetName]));
            const targetSave = JSON.parse(JSON.stringify(newState[this.targetNameSave]));
            const { docs: docsSave = [] } = targetSave;

            if (docsSave) {
                target.docs = JSON.parse(JSON.stringify(docsSave));
            }

            newState[this.targetName] = target;

            return newState;
        });
    }

    clearFormDataDocs() {
        this.formDataDocs = new FormData();

        this.setState((state) => {
            const newState = { ...state };

            newState.filesDocsDelete = [];

            return newState;
        });
    }

    handlerErrorGetModel({ data: { message, actionCode } }) {
        if (message === 'Id is incorrect' || actionCode === 404) {
            changePage({
                href: getPageLink({ name: this.mainPage }),
            });

            setNotification({ notification: `error-${this.mainPage}-id` });
        }
    }
}

export default Inner;
