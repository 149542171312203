import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from './types.ts';

import renderLink from './renders/renderLink.tsx';

class WidgetVerificationCard extends React.Component<I['props'], I['state']> implements I {
    parent: I['parent'];

    constructor(props: I['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderLink = renderLink;

    render() {
        const { title, description, rejectText, status, links = [] } = this.props;

        return (
            <div ref={this.parent} className="v2widgetCard">
                <div className="v2widgetCard__content _full">
                    <div className={`v2widgetVerificationCard _COL _${status}`}>
                        <i className="v2widgetVerificationCard__icon">
                            <Icon name="corporation-verify" />
                        </i>
                        <AnimateChange
                            className="v2widgetVerificationCard__title"
                            renderKey={title}
                            styles={['height']}
                        >
                            {title}
                        </AnimateChange>
                        <AnimateChange
                            className="v2widgetVerificationCard__description"
                            renderKey={description}
                            styles={['height']}
                        >
                            {description}
                        </AnimateChange>
                        <AnimateChange
                            className="v2widgetVerificationCard__description _message"
                            renderKey={rejectText}
                            styles={['height']}
                            paramsParent={{ width: true }}
                        >
                            {rejectText ? <><span>Причина:</span> {rejectText}</> : <></>}
                        </AnimateChange>
                        <ListAbsoluteMain
                            className="v2widgetVerificationCard__links _ITEMS"
                            items={links}
                            renderItem={this.renderLink.bind(this)}
                            classNameItem="v2widgetVerificationCard__link"
                            prop="id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default WidgetVerificationCard;
