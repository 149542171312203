import { Store } from 'redux';

import { StoreT, SystemT } from '@global/types';

import { SettingsT } from './actions';

const store = (
    process.env.REACT_APP_SYSTEM === 'app'
        ? require('./createAppStore').default
        : require('./createCrmStore').default
) as Store<StoreT<'app'> | StoreT<'crm'>>;

function dispatcher<T extends SystemT>(settings: SettingsT<T>): Promise<void> {
    return new Promise((resolve) => {
        store.dispatch({ ...settings, ...{ resolve } });
    });
}

export { store, dispatcher };
