import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import Switch from '@components/switch/Switch.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { payGroup, currentBlock, isAutoPay } = this.state;
    const { paySignPopup } = this.props;
    const { isSign } = paySignPopup;

    return (
        <>
            <div className="v2popup__close _top _CLICK" onClick={this.close.bind(this)}>
                <Icon name="popup-close" />
            </div>
            <div className="v2popup__content _padding">
                <div className="v2popup__title">
                    Оплата на сумму:{' '}
                    <AnimateChange
                        className="v2popup__titleInner"
                        renderKey={payGroup ? payGroup.amount : false}
                        styles={['width']}
                    >
                        {payGroup ? (
                            <>
                                <>
                                    {getFormatPrice(+payGroup.amount.toFixed(2))} ₽ (
                                    {payGroup?.counter})
                                </>
                            </>
                        ) : (
                            <>–</>
                        )}
                    </AnimateChange>
                </div>
                {this.renderBlocks()}
                <div className="v2popupSign__actions _ROW">
                    {isSign && (
                        <>
                            <div className="v2popupSign__actionsAuto">
                                <div className="v2popupSign__actionsAutoSwitch">
                                    <Switch
                                        value={!!isAutoPay}
                                        name="isAutoPay"
                                        className="_mediumSize"
                                        onChange={() => {
                                            this.setState({ isAutoPay: !isAutoPay });
                                        }}
                                    />
                                </div>
                                <div className="v2popupSign__actionsAutoTitle">
                                    Автооплата выбранных актов
                                </div>
                                <div className="v2popupSign__actionsAutoDescription">
                                    Акты будут оплачены после подписания
                                </div>
                            </div>
                        </>
                    )}
                    <div className="v2popupSign__actionsDots _ROW">
                        {this.blocks.map((block) => (
                            <div
                                className={`v2popupSign__actionsDot ${block === currentBlock ? '_current' : ''}`}
                                key={block}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderContent;
