import AlertI from '../../types';

const inactiveJoinContract = {
    title(this: AlertI) {
        const info = this.state.info as { type?: string };

        if (info.type === 'userProxy') {
            return 'Подтвердите<br/>расторжение доверенности';
        }

        return 'Подтвердите<br/>расторжение договора';
    },
    description(this: AlertI) {
        const info = this.state.info as { name: string; number: number; type?: string };

        if (info.type === 'userProxy') {
            return `Данное действие нельзя отменить. Вы уверены, что хотите расторгнуть доверенность <b>№${info.number}</b>?`;
        }

        return `Данное действие нельзя отменить. Вы уверены, что хотите расторгнуть договор <b>${info.name} №${info.number}</b>?`;
    },
    buttonText: 'Расторгнуть',
} as const;

export default inactiveJoinContract;
