import { monthNames } from '@functions/getFormatedDate.ts';

const executorsTaxes = {
    colsOrder: ['name', 'status', 'amount', 'monthAmount', 'debtAmount'],
    cols: {
        name: {
            content: 'ФИО исполнителя',
            width: (admin: boolean) => (admin ? 23 : 40),
        },
        status: {
            content: 'Статус копилки',
            width: 17,
        },
        amount: {
            content: 'Отложено',
            width: 14,
        },
        monthAmount: {
            content: (admin: boolean, props: { month: number }) =>
                `За ${monthNames[props.month]?.[0].toLowerCase() || 'месяц'}`,
            width: 15,
        },
        debtAmount: {
            content: 'Задолженность',
            width: 15,
        },
    },
    adminIndex: 2,
    deletesCols: [],
    url: 'executor-tax',
    emptyInfo: {
        title: 'Копилок пока нет',
        description: 'Они будут отображаться здесь<br/>после подключения самозанятых',
    },
} as const;

export default executorsTaxes;
