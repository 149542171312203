import React from 'react';

import Animate from '@components/Animate.jsx';
import Icon from '@components/Icon.jsx';

import WidgetI from '../types.ts';

const renderBack: WidgetI['renderBack'] = function () {
    const { changesPageCounter, withBack = true } = this.props;

    if (!withBack || 1) {
        return null;
    }

    return (
        <Animate className="v2widget__headBack" isShow={changesPageCounter > 0}>
            <div
                className="v2widget__headBackInner _col _click"
                onClick={() => {
                    window.history.back();
                }}
            >
                <div className="v2widget__headBackIcon">
                    <Icon name="arrow-back" />
                </div>
            </div>
        </Animate>
    );
};

export default renderBack;
