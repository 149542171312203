import React from 'react';

import BorderDashed from '@components/BorderDashed.jsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';
import LazyImage from '@components/lazyImage/lazyImage.tsx';
import download from '@functions/download.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const Delete = function ({ onClick }: { onClick: () => void }) {
    return (
        <div
            className="v2widgetDoc__fileAction _COL _CLICK"
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onClick();
            }}
        >
            <i className="v2widgetDoc__fileActionIcon">
                <Icon name="delete" />
            </i>
        </div>
    );
};

const renderFile: I['renderFile'] = function ({ item, stateKey, key, isDelete }) {
    if (item._id === 'add') {
        return (
            <div
                className="v2widgetDoc__file _ITEM _COL _CLICK _add"
                onClick={this.addHandler.bind(this)}
            >
                <div className="v2widgetDoc__fileBack">
                    <BorderDashed className="_upload" />
                </div>
                <div className="v2widgetDoc__fileText">
                    Добавить
                    <br />
                    файл
                </div>
            </div>
        );
    }

    const { doc, checkChange } = this.props;
    const canChange = !checkChange || checkChange(doc);

    const resultIndex = isDelete ? stateKey : key;
    const fileText =
        resultIndex === 0
            ? '<b>Лицевая</b><br/>сторона'
            : resultIndex === 1
              ? '<b>Оборотная</b><br/>сторона'
              : '<b>Доп.</b><br/>файл';

    return (
        <label className="v2widgetDoc__file _ITEM _COL _CLICK">
            <input
                type="file"
                className="v2widgetDoc__fileInput"
                onChange={this.uploadHandler.bind(this, item._id)}
                disabled={!canChange}
            />
            <div className="v2widgetDoc__fileBack">
                <BorderDashed className="_upload" />
            </div>
            <i className="v2widgetDoc__fileIcon">
                <Icon name="plus" />
            </i>
            <div
                className="v2widgetDoc__fileText"
                dangerouslySetInnerHTML={{ __html: fileText }}
            ></div>
            {canChange && (
                <Delete
                    onClick={() => {
                        this.deleteHandler(item._id);
                    }}
                />
            )}

            <AnimateChange
                className="v2widgetDoc__filePreview _default"
                renderKey={item.file.fullSrc || undefined}
                styles={[]}
                withoutParams={true}
            >
                <>
                    <LazyImage
                        className="v2widgetDoc__filePreviewFile"
                        src={item.file.fullSrc!}
                        cover={
                            item.file.fullSrc
                                ? ['jpg', 'jpeg'].includes(
                                      item.file.fullSrc.split('.').pop()!.toLowerCase(),
                                  )
                                : false
                        }
                        name={item.file.name}
                    />
                    <div className="v2widgetDoc__fileInfo _COL">
                        <div
                            className="v2widgetDoc__fileText"
                            dangerouslySetInnerHTML={{ __html: fileText }}
                        ></div>
                        <div className="v2widgetDoc__fileInfoActions _ROW">
                            <div
                                className="v2widgetDoc__fileInfoAction _COL"
                                onClick={(e) => {
                                    e.preventDefault();

                                    download({
                                        name: doc.name,
                                        files: [{ src: item.file.src!, name: item.file.name }],
                                    });
                                }}
                            >
                                <i className="v2widgetDoc__fileInfoActionIcon">
                                    <Icon name="corporation-settings-download" />
                                </i>
                            </div>
                            {canChange && (
                                <div
                                    className="v2widgetDoc__fileInfoAction _COL"
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.change(
                                            { src: null, fullSrc: null, name: null },
                                            undefined,
                                            `files.${item._id}.file`,
                                        );
                                    }}
                                >
                                    <i className="v2widgetDoc__fileInfoActionIcon">
                                        <Icon name="corporation-settings-delete" />
                                    </i>
                                </div>
                            )}

                            <div
                                className="v2widgetDoc__fileInfoAction _COL"
                                onClick={(e) => {
                                    e.preventDefault();

                                    handlerPopup('galeryPopup', {
                                        isShow: true,
                                        title: doc.name,
                                        files: doc.files
                                            .filter((file) => file.file.fullSrc)
                                            .map((file) => ({
                                                _id: file._id,
                                                src: file.file.src,
                                                fullSrc: file.file.fullSrc,
                                                name: file.file.name,
                                            })),
                                        currentId: item._id,
                                        withRotate: true,
                                    });
                                }}
                            >
                                <i className="v2widgetDoc__fileInfoActionIcon">
                                    <Icon name="corporation-settings-search" />
                                </i>
                            </div>
                        </div>
                    </div>
                </>
            </AnimateChange>
        </label>
    );
};

export default renderFile;
