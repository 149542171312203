import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../../../../components/Animate.jsx';
import Button from '../../../../../components/Button.jsx';
import Edit from '../../../../../components/Edit.jsx';
import Link from '../../../../../components/Link.jsx';
import ListAbsoluteMain from '../../../../../components/ListAbsoluteMain.jsx';
import Main from '../../../../../components/crm/content/blog/Main.jsx';
import Smi from '../../../../../components/crm/content/blog/Smi.jsx';
import MainDefault from '../../../../../components/crm/manual/Main.jsx';

import getHeaders from '../../../../../functions/getHeaders';
import getUpdateFormData from '../../../../../functions/getUpdateFormData';
import setNotification from '../../../../../functions/setNotification';

import Editmode from '../../../../../classes/Editmode';

class ContentBlogInnerMain extends MainDefault {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };

        this.handlerEditmode = this.handlerEditmode.bind(this);
        this.renderCard = this.renderCard.bind(this);
        this.changeArticle = this.changeArticle.bind(this);
        this.checkFields = this.checkFields.bind(this);

        this.parent = React.createRef();
    }

    name = 'article';

    editmode = new Editmode({
        context: this,
    });

    cards = {
        main: {
            getChangedProps() {
                return ['title', 'metaTitle', 'metaDescription', 'section', 'viewType'];
            },
            getRequiredProps() {
                const { name } = this.props;

                if (name === 'legal') {
                    return ['title', 'place'];
                }

                return ['title', 'place', 'section'];
            },
            files: ['preview'],
            render() {
                const { errors } = this.state;
                const { article, uploadMainFile, isNew, newFiles, name } = this.props;

                return (
                    <Main
                        newFiles={newFiles}
                        article={article}
                        checkEditmode={() => this.checkEditmode('main')}
                        changeArticle={({ ...props }) =>
                            this.changeArticle({ ...props, block: 'main' })
                        }
                        errors={errors.main}
                        uploadMainFile={uploadMainFile}
                        isNew={isNew}
                        name={name}
                    />
                );
            },
        },
        smi: {
            getChangedProps() {
                return ['smiLink'];
            },
            getRequiredProps() {
                return [];
            },
            files: ['smiLogo'],
            render() {
                const { errors } = this.state;
                const { article, uploadMainFile, isNew, newFiles } = this.props;

                return (
                    <Smi
                        newFiles={newFiles}
                        article={article}
                        checkEditmode={() => this.checkEditmode('smi')}
                        changeArticle={({ ...props }) =>
                            this.changeArticle({ ...props, block: 'smi' })
                        }
                        errors={errors.main}
                        uploadMainFile={uploadMainFile}
                        isNew={isNew}
                    />
                );
            },
        },
    };

    formData = new FormData();

    changeArticle({ block, ...props }) {
        const { changeArticle } = this.props;

        return new Promise((resolve) => {
            changeArticle({ ...props }).then(
                () => {
                    if (props.action === 'change' || !props.action) {
                        this.handlerErrors({ action: 'delete', error: props.name, block });
                    }

                    resolve();
                },
                () => null,
            );
        });
    }

    getOrderCards() {
        const { article } = this.props;
        const orderCards = [{ key: 'main' }];

        if (article?.section === 'smi') {
            orderCards.push({ key: 'smi' });
        }

        return orderCards;
    }

    checkChange() {
        const { article, articleSave, newFiles } = this.props;
        const fields = {};
        let isChange = false;
        const changedProps = this.cards[this.state.editName].getChangedProps.call(this);

        this.cards[this.state.editName].files.forEach((keyFile) => {
            if (newFiles[keyFile]?.isLocal) {
                isChange = true;
            }
        });

        changedProps.forEach((key) => {
            const prop = typeof key === 'object' ? key.name : key;

            if (article[prop] !== articleSave[prop]) {
                fields[prop] = article[prop];

                isChange = true;
            }
        });

        return { isChange, fields };
    }

    renderCard({ prop: name, key: order, isShow, params }) {
        const { editName, loadingKey } = this.state;
        const { isNew, checkRights } = this.props;
        const card = this.cards[name];

        return (
            <div
                className={`manualContent__card _parentForEdit _editBack ${
                    editName === name ? '_current' : ''
                } _${name} ${isShow ? '_show' : ''}`}
                key={name}
                data-key={name}
                style={{
                    transform: `translate(${params?.offsetLeft}px,${params?.offsetTop}px)`,
                    order,
                    zIndex: this.getOrderCards().length - order,
                }}
            >
                {!isNew && checkRights() && (
                    <Edit
                        name={name}
                        className="manualContent__cardEdit"
                        editName={editName}
                        handlerEditmode={this.handlerEditmode}
                        isLoader={loadingKey === name}
                    />
                )}

                {card.render.call(this)}
            </div>
        );
    }

    checkFields() {
        const { isSuccess, errors } = this.validate({});

        return new Promise((resolve) => {
            if (!isSuccess) {
                this.handlerErrors({ action: 'set', errors });
            } else {
                resolve();
            }
        });
    }

    clearForm() {
        this.formData = new FormData();
    }

    handlerEditmode({ editName }) {
        const { article, getFormData, clearFiles } = this.props;

        const checkEdit = () =>
            new Promise((resolve) => {
                if (editName) {
                    resolve();
                } else {
                    const requiredProps =
                        this.cards[this.state.editName].getRequiredProps.call(this);
                    const { isSuccess, errors } = this.validate({
                        props: requiredProps,
                        nameProp: this.state.editName,
                    });
                    const { isChange, fields } = this.checkChange();

                    if (!isSuccess) {
                        this.handlerErrors({ action: 'set', errors });
                    } else if (!isChange) {
                        resolve();
                    } else {
                        this.formData.set('id', article._id);
                        this.formData.set('fields', JSON.stringify(fields));

                        this.handlerLoadingBlock(this.state.editName).then(() => {
                            axios
                                .patch(
                                    `${process.env.REACT_APP_API}/article`,
                                    getUpdateFormData(getFormData(), this.formData),
                                    {
                                        headers: getHeaders(),
                                    },
                                )
                                .then(
                                    (res) => {
                                        const { success } = res.data;

                                        if (success) {
                                            setNotification({
                                                notification: 'success-update-article',
                                            });

                                            this.clearForm();

                                            clearFiles();

                                            resolve();
                                        }

                                        this.handlerLoadingBlock(null);
                                    },
                                    () => null,
                                );
                        });
                    }
                }
            });

        checkEdit().then(() => {
            this.editmode.handlerEdit({ editName });
        });
    }

    componentDidMount() {
        this.setState({ isInit: true });
    }

    componentWillUnmount() {
        const { isNew, backToSave } = this.props;

        if (!isNew) {
            backToSave();
        }
    }

    render() {
        const { editName, isLoadingSave } = this.state;
        const { isNew, article, name } = this.props;

        return (
            <div
                ref={this.parent}
                className={`manualContent _parentForEdits _article ${
                    editName ? '_edit' : ''
                } _absolute`}
            >
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <ListAbsoluteMain
                            className="manualContent__cards"
                            items={this.getOrderCards()}
                            renderItem={this.renderCard}
                            classNameItem="manualContent__card"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                        />

                        <Animate
                            className="manualContent__actions"
                            isShow={isNew && article?.section !== 'smi'}
                        >
                            <div className="manualContent__actionsInner _row">
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName={`content-${name}-main`}
                                >
                                    <Button className="_mainEmpty _medium2Size">Отменить</Button>
                                </Link>
                                <Link
                                    className="manualContent__actionsButton"
                                    pageName={`content-${name}-inner-content`}
                                    prevPromise={this.checkFields}
                                    ids={{ 3: 'new' }}
                                >
                                    <Button
                                        className="_mainNotBorder _medium2Size"
                                        showLoader={isLoadingSave}
                                        icon={{
                                            type: 'end',
                                            name: 'arrow-next',
                                        }}
                                    >
                                        Далее
                                    </Button>
                                </Link>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(ContentBlogInnerMain);

ContentBlogInnerMain.propTypes = {
    newFiles: PropTypes.object,
    serverData: PropTypes.object,
    article: PropTypes.object,
    articleSave: PropTypes.object,
    isInit: PropTypes.bool,
    isNew: PropTypes.bool,
    backToSave: PropTypes.func,
    changeArticle: PropTypes.func,
    uploadMainFile: PropTypes.func,
    getFormData: PropTypes.func,
    clearFiles: PropTypes.func,
    checkRights: PropTypes.func,
    name: PropTypes.string,
};
