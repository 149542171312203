import handlerPopup from '@functions/handlerPopup.ts';

import MainI from '../types.ts';

const getActions: MainI['getActions'] = function () {
    return [
        // {
        //     type: 'export',
        //     name: 'export',
        //     group: 'buttons',
        //     exportName: 'executors',
        // },
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'executors',
        },
        {
            type: 'link',
            content: '+ Добавить исполнителя',
            name: 'addExecutor',
            className: '_main',
            group: 'links',
            onClick: async () => {
                handlerPopup('executorCreatePopup', {
                    isShow: true,
                });
            },
        },
    ];
};

export default getActions;
