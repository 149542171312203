import ManualTableI from '@components/crm/table/types';
import WidgetCardI from '@components/crm/widgetCard/types';
import contents from '@components/popups/alert/static/contents';
import { PageNamesT } from '@redux/pages';

import cities from './models/cities';
import countries from './models/countries';
import executorTypes from './models/executorTypes';
import joinDocs from './models/joinDocs';
import payProjects from './models/payProjects';
import tags from './models/tags';

type ModelsT = {
    url: string;
    title: string;
    parentPage: PageNamesT['crm'];
    tablePage: PageNamesT['crm'];
    tableName: ManualTableI['props']['name'];
    tableLink?: {
        pageName: PageNamesT['crm'];
        level?: number;
    };
    tableWrap?: boolean;
    modelPage: PageNamesT['crm'];
    logName: string;
    deleteAlertPopup: keyof typeof contents;
    deleteDeniedAlertPopup?: keyof typeof contents;
    descriptions: {
        table: string;
        model: {
            new: string;
            default: string;
        };
    };
    addButton: string;
    cardName: WidgetCardI['props']['name'];
};

type ModelsNamesT = keyof typeof models;

const models = {
    cities,
    tags,
    countries,
    payProjects,
    joinDocs,
    executorTypes,
} as const;

export default models;

export type { ModelsT, ModelsNamesT };
