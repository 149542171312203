import CalendarI from '../types.ts';

const setDateText: CalendarI['setDateText'] = function (name, { value }) {
    const { momentCallback } = this.props;
    const dateProp = name;
    const dateTextProp = `${dateProp}Text`;

    const currentDate = new Date(this.state[dateProp] as Date);
    const [day, month, year] = value.toString().split('.');

    return new Promise((resolve) => {
        if (value?.toString().replace(/[^\d]/gi, '').length === 8) {
            currentDate.setDate(1);

            if (year?.replace(/[^\d]/gi, '').length === 4) {
                currentDate.setFullYear(+year);
            }

            if (month?.replace(/[^\d]/gi, '').length === 2) {
                currentDate.setMonth(+month - 1);
            }

            if (day?.replace(/[^\d]/gi, '').length === 2) {
                currentDate.setDate(+day);
            }

            const activeDate = new Date(currentDate);

            activeDate.setDate(1);
            activeDate.setHours(0, 0, 0, 0);

            this.setState({ [dateTextProp]: value, [dateProp]: currentDate, activeDate }, () => {
                if (typeof momentCallback === 'function') {
                    momentCallback({ [dateProp]: currentDate.getTime() });
                }

                resolve();
            });
        } else {
            this.setState({ [dateTextProp]: value });

            if (value?.toString().replace(/[^\d]/gi, '').length === 0) {
                this.setState(
                    {
                        [dateProp]: null,
                        ...(dateProp === 'currentDate'
                            ? { doubleCurrentDate: null, doubleCurrentDateText: '' }
                            : {}),
                    },
                    () => {
                        if (typeof momentCallback === 'function') {
                            momentCallback({
                                [dateProp]: null,
                                ...(dateProp === 'currentDate' ? { doubleCurrentDate: null } : {}),
                            });
                        }

                        resolve();
                    },
                );
            } else {
                resolve();
            }
        }
    });
};

export default setDateText;
