const s = function (): boolean {
    return typeof window === 'undefined';
};

const w: Window = s()
    ? ({
          innerHeight: 1000,
      } as unknown as Window)
    : window;

const d: Document = s()
    ? ({
          documentElement: {
              clientWidth: 1000,
          },
          querySelector: () => null,
          cookie: '',
      } as unknown as Document)
    : document;

export { s, w, d };
