import DocsI from '../types.ts';

const getActions: DocsI['getActions'] = function () {
    return [
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'docs',
        },
        // {
        //     type: 'link',
        //     content: '+ Добавить тег',
        //     name: 'add',
        //     className: '_main',
        //     group: 'links',
        //     onClick: async () => {
        //         changePage<'crm'>({ pageName: 'manual-tags-inner-main', ids: { 3: 'new' } });
        //     },
        // },
    ];
};

export default getActions;
