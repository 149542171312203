import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';
import { setCursorSupport } from '@functions/supportHandler.ts';

import WidgetI from '../../types.ts';

const renderAction: WidgetI['renderHeadAction'] = function ({ item, prop }) {
    const { loadings } = this.state;
    const { actions } = this.props;
    const action = actions?.find((innerAction) => innerAction.name === prop);
    const onClick = action?.onClick;
    const cursorSupport =
        item.cursorSupport &&
        setCursorSupport(item.cursorSupport, {
            targetClassName: '.v2widget__headAction',
        });

    return (
        <div className="v2widget__headAction" {...cursorSupport}>
            <div
                className="v2widget__headActionInner _COL _CLICK"
                onClick={async () => {
                    if (onClick) {
                        await this.loadingHandler({ name: item.name, loading: true });
                        try {
                            await onClick();
                        } catch (error) {}
                        await this.loadingHandler({ name: item.name, loading: false });
                    }
                }}
            >
                <LoaderBlock
                    className="v2widget__headActionLoader"
                    isShow={!!loadings[item.name]}
                />
                <i className="v2widget__headActionIcon">
                    <Icon name={item.icon!} />
                </i>
            </div>
        </div>
    );
};

export default renderAction;
