import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';

import GroupsMainI from './types.ts';

class GroupsMain
    extends WidgetWrapper<GroupsMainI['props'], GroupsMainI['state']>
    implements GroupsMainI
{
    parent: GroupsMainI['parent'];

    constructor(props: GroupsMainI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getActions = getActions;

    componentDidMount(): void {
        this.setState({ user: this.props.user });
    }

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <Widget
                    name="paysGroups"
                    title="Выплаты"
                    description="Реестр актов"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as GroupsMainI).getFilterQuery?.()}
                >
                    <Table
                        name="paysGroups"
                        isInit={true}
                        isWrap={true}
                        isRowHeight={true}
                        init={this.tableInit!.bind(this)}
                        callback={this.tableCallback?.bind(this)}
                        link={{
                            pageName: 'pays-groups-inner-main',
                            level: 3,
                        }}
                        getRowClassName={() => '_colCenter'}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(GroupsMain);
