import { dispatcher, store } from '../../redux/redux';
import changePage from '../changePage';
import getPageLink from '../getPageLink';

export default function changeCorporation({ user = store.getState().user, id }) {
    return new Promise((resolve) => {
        dispatcher({
            type: 'user',
            data: { ...user, idOfCurrentCorporation: id },
        }).then(() => {
            changePage({
                href: getPageLink({ name: 'corporation', ids: { 0: id } }),
            }).then(() => {
                document.dispatchEvent(new CustomEvent('changeCorporation', { detail: { id } }));

                document.dispatchEvent(new CustomEvent('changeUser', { detail: {} }));

                resolve();
            });
        });
    });
}
