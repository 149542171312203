import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';

import ContractsI from '../types.ts';

const titles = {
    userProxy: 'доверенностей',
    default: 'договоров',
} as const;

const renderHeadTitle: ContractsI['renderHeadTitle'] = function () {
    const { counter, completedCounter } = this.state;
    const { type } = this.props;
    const isReady = typeof completedCounter === 'number';

    return (
        <>
            <div className={`v2widget__headData _col _ready`}>
                <Animate className="v2widget__headDataLoader _LOADER" isShow={!isReady}>
                    <div className="v2widget__headDataLoaderTitle _LOADERWAIT" />
                    <div className="v2widget__headDataLoaderDescription _LOADERWAIT" />
                </Animate>
                <AnimateChange
                    className="v2widget__headDataInner"
                    renderKey={isReady ? `${counter}${completedCounter}` : undefined}
                >
                    <>
                        <div className="v2widget__headDataTitle">
                            <>
                                <b>Всего {titles[type || 'default']}:</b> {counter}
                            </>
                        </div>
                        <div className="v2widget__headDataDescription _row">
                            <div className="v2widget__headDataDescriptionItem _completed">
                                Подписано: {completedCounter}
                            </div>
                        </div>
                    </>
                </AnimateChange>
            </div>
        </>
    );
};

export default renderHeadTitle;
