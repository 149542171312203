import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import InfoBlock from '@components/infoBlock/InfoBlock.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import getEndText from '@functions/getEndText.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const renderWalletsCard: I['renderWalletsCard'] = function () {
    const { initedKey, user } = this.state;
    const model = this.state.model!;
    const walletsCounter = model.wallets.length;

    return (
        <div className="v2widgetCard">
            <div className="v2widgetCard__head _ROW">
                <div className="v2widgetCard__title">
                    Добавленные способы оплаты:{' '}
                    <AnimateChange
                        className="v2widgetCard__description"
                        renderKey={walletsCounter}
                        styles={['width']}
                    >
                        <div className="v2widgetCard__descriptionInner">
                            {walletsCounter ? (
                                <>
                                    {walletsCounter}{' '}
                                    {getEndText(walletsCounter, ['способ', 'способа', 'способов'])}
                                </>
                            ) : (
                                '–'
                            )}
                        </div>
                    </AnimateChange>
                </div>
                {user &&
                    (user.idOfCurrentCorporation === 'admin' ||
                        model?.verification.status !== 'active') && (
                        <>
                            <div className="v2widgetCard__actions">
                                <div className="v2widgetCard__actionsButton">
                                    <Button
                                        className="_main _shadow _minSize"
                                        onClick={() => {
                                            handlerPopup('walletPopup', {
                                                isShow: true,
                                                executorId: model._id,
                                                setModel: this.updateWallets.bind(this),
                                                organization: model.organization,
                                            });
                                        }}
                                    >
                                        + Способ оплаты
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
            </div>
            <div className="v2widgetCard__content _fullHeight _NOSCROLL">
                <InfoBlock
                    title="Способов оплаты нет"
                    description="Вы можете добавить их<br/>по кнопке выше"
                    className="v2widgetCard__info"
                    isShow={model.wallets.length === 0}
                />
                <ListAbsoluteMain
                    className="v2widgetCard__wallets _ITEMS"
                    items={model.wallets}
                    renderItem={this.renderWallet.bind(this)}
                    classNameItem="v2widgetCard__wallet"
                    prop="_id"
                    styles={['height']}
                    paramsParent={{ width: true }}
                    minHeight={model.wallets.length === 0 ? 120 : undefined}
                    keyRender={initedKey}
                    keyUpdateItem={initedKey}
                />
            </div>
        </div>
    );
};

export default renderWalletsCard;
