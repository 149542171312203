const corporationOther = {
    fieldsOrder: ['website', 'shortName'],
    fields: {
        website: {
            support: 'Сайт',
            prop: 'website',
            type: 'input',
        },
        shortName: {
            support: 'Краткое название',
            prop: 'shortName',
            type: 'input',
        },
    },
} as const;

export default corporationOther;
