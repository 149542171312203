import FilterI from '../types.ts';

const send: FilterI['send'] = function (result) {
    const { filterPopup } = this.props;
    const { callback } = filterPopup;

    if (typeof callback === 'function') {
        callback(result);

        this.close();
    }
};

export default send;
