import { PageGetCondT, StoreT } from '@global/types';

const appPages = [
    {
        links: ['start'],
        name: 'start',
        getRedirect() {
            return { name: 'index' };
        },
        getCond({ user, isProccessLogin, isProccessLogout }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !user || isProccessLogin || isProccessLogout,
            };
        },
    },
    {
        links: ['auth'],
        name: 'auth',
        getRedirect() {
            return { name: 'index' };
        },
        getCond({ user, isProccessLogin, isProccessLogout }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !user || isProccessLogin || isProccessLogout,
            };
        },
    },
    {
        links: ['', undefined],
        name: 'index',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['corporations'],
        name: 'corporations',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['corporations-list'],
        name: 'corporations-list',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['balance'],
        name: 'balance',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['settings'],
        name: 'settings',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['', undefined],
        name: 'settings-index',
        contentOfLink: 'Настройки',
        level: 1,
        parentName: 'settings',
    },
    {
        links: ['profile'],
        name: 'settings-profile',
        contentOfLink: 'Данные аккаунта',
        level: 1,
        parentName: 'settings',
    },
    {
        links: ['legal'],
        name: 'settings-legal',
        contentOfLink: 'О приложении',
        level: 1,
        parentName: 'settings',
    },
    {
        links: ['', undefined],
        name: 'settings-legal-index',
        level: 2,
        parentName: 'settings-legal',
    },
    {
        links: [],
        name: 'settings-legal-inner',
        level: 2,
        parentName: 'settings-legal',
    },
    {
        links: ['wallet'],
        name: 'settings-wallet',
        contentOfLink: 'Реквизиты',
        level: 1,
        parentName: 'settings',
        getRedirect() {
            return {
                name: 'settings',
            };
        },
    },
    {
        links: ['tax'],
        name: 'settings-tax',
        contentOfLink: 'Налоговая копилка',
        level: 1,
        parentName: 'settings',
        getRedirect() {
            return {
                name: 'settings',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: user?.organization === 'SMZ',
            };
        },
    },
    {
        links: ['fns'],
        name: 'settings-fns',
        contentOfLink: 'Мой налог',
        level: 1,
        parentName: 'settings',
        getRedirect() {
            return {
                name: 'settings',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: user?.organization === 'SMZ',
            };
        },
    },
    {
        links: ['chat'],
        name: 'chat',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['', undefined],
        name: 'chat-index',
        contentOfLink: 'Поддержка',
        level: 1,
        parentName: 'chat',
    },
    {
        links: [],
        name: 'chat-inner',
        contentOfLink: 'Чат поддержки',
        level: 1,
        parentName: 'chat',
    },
    {
        links: ['pays'],
        name: 'pays',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['', undefined, 'all'],
        name: 'pays-all',
        contentOfLink: 'Все',
        level: 1,
        parentName: 'pays',
    },
    {
        links: ['process'],
        name: 'pays-process',
        contentOfLink: 'На подпись',
        level: 1,
        parentName: 'pays',
    },
    {
        links: ['completed'],
        name: 'pays-completed',
        contentOfLink: 'Оплаченные',
        level: 1,
        parentName: 'pays',
    },
    {
        links: ['notifications'],
        name: 'notifications',
        getRedirect() {
            return {
                name: 'start',
            };
        },
        getCond({ user }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !!user,
            };
        },
    },
    {
        links: ['', undefined, 'new'],
        name: 'notifications-new',
        level: 1,
        parentName: 'notifications',
    },
    {
        links: ['completed'],
        name: 'notifications-completed',
        level: 1,
        parentName: 'notifications',
    },
    {
        links: ['join'],
        name: 'join',
        getRedirect() {
            return { name: 'index' };
        },
        getCond({ user, isProccessLogin, isProccessLogout }: StoreT<'app'>): PageGetCondT {
            return {
                condition: !user || isProccessLogin || isProccessLogout,
            };
        },
    },
    {
        links: [],
        name: 'join-inner',
        level: 1,
        parentName: 'join',
    },
] as const;

export default appPages;
