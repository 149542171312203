import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Pages from '../../../components/Pages.jsx';
import Animate from '../../../components/Animate.jsx';
import Loader from '../../../components/Loader.jsx';
import Windows from '../../../components/Windows.jsx';
import Search from '../../../components/Search.jsx';

import InfoHead from '../../../components/crm/widget/InfoHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';

import Main from './infoPublic/Main.jsx';
import Inner from './infoPublic/Inner.jsx';

import shortText from '../../../functions/shortText';

class ContentBlog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            infoHead: {},
        };

        this.setInfoHead = this.setInfoHead.bind(this);

        this.parent = React.createRef();
    }

    headPages = {
        'content-infoPublic-main': {
            render() {
                return <div className="widget__headNameItemContent">База знаний</div>;
            },
        },
        'content-infoPublic-inner': {
            render() {
                const { infoHead } = this.state;
                const { article } = infoHead;
                const name = shortText({ str: article?.title || '', stop: 50 });

                return <div className="widget__headNameItemContent">База знаний – {name}</div>;
            },
        },
    };

    headActions = {
        'content-infoPublic-main': {
            render() {
                const { search = '' } = this.state;

                return (
                    <div className="widget__headActionsGroups _row">
                        <div className="widget__headActionsGroup">
                            <div className="widget__headActionsElem widget__headSearch _article">
                                <Search
                                    className="_lightBlue _widget"
                                    support="Поиск по базе знаний. Что вас интересует?"
                                    value={search}
                                    callback={({ action, value }) =>
                                        new Promise((resolve) => {
                                            if (action === 'change') {
                                                this.setState({ search: value }, resolve);
                                            } else {
                                                resolve();
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                );
            },
        },
        'content-infoPublic-inner': {
            render() {
                const { innerSearch = '' } = this.state;

                return (
                    <div className="widget__headActionsGroups _row">
                        <div className="widget__headActionsGroup">
                            {false && (
                                <div className="widget__headActionsElem widget__headSearch _articleInner">
                                    <Search
                                        className="_lightBlue _widget"
                                        support="Поиск по статье"
                                        value={innerSearch}
                                        callback={({ action, value }) =>
                                            new Promise((resolve) => {
                                                if (action === 'change') {
                                                    this.setState({ innerSearch: value }, resolve);
                                                } else {
                                                    resolve();
                                                }
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            },
        },
    };

    pages = {
        'content-infoPublic-main': {
            render() {
                const { isShowLoaderList, filter, search } = this.state;
                const { checkRights } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Main
                                    setInfoHead={this.setInfoHead}
                                    initCallbackFilter={this.initCallbackFilter}
                                    setFilter={this.setFilter}
                                    filter={filter}
                                    checkRights={checkRights}
                                    search={search}
                                />
                            </div>
                        </div>
                        <Animate className="widget__pageLoader _loaderScroll" isShow={isShowLoaderList}>
                            <div className="widget__pageLoaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </>
                );
            },
        },
        'content-infoPublic-inner': {
            render() {
                const { innerSearch } = this.state;
                const { checkRights } = this.props;

                return (
                    <>
                        <div className="widget__pageBox">
                            <div className="widget__pageInner _notPadding">
                                <Inner
                                    setInfoHead={this.setInfoHead}
                                    checkNew={this.checkNew}
                                    getParent={() => this.parent.current}
                                    checkRights={checkRights}
                                    name="info"
                                    search={innerSearch}
                                />
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            const { infoHead } = newState;

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    render() {
        const { counterChangePage } = this.props;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="editPanel" renderContent={this.renderEditPanel} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Контент"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <Pages
                                    classNamePage="widget__headNameItemInner _page"
                                    filter={(page) =>
                                        page.parentName === 'content-infoPublic' && page.level === 1
                                    }
                                    pages={this.headPages}
                                    context={this}
                                />
                            </InfoHead>
                            <div className="widget__headActions">
                                <Pages
                                    classNamePage="widget__headActionsInner _page"
                                    filter={(page) =>
                                        page.parentName === 'content-infoPublic' && page.level === 1
                                    }
                                    pages={this.headActions}
                                    context={this}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <Pages
                        classNamePage="widget__page _deep1"
                        filter={(page) =>
                            page.parentName === 'content-infoPublic' && page.level === 1
                        }
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(ContentBlog);

ContentBlog.propTypes = {
    setHeightPage: PropTypes.func,
    levels: PropTypes.array,
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
};
