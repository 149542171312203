import React from 'react';

import Tag from '@components/crm/tag/Tag';
import JoinScriptT from '@global/models/JoinScript';
import TagT from '@global/models/Tag';

const joinScriptMain = {
    fieldsOrder: [
        'name',
        'line',
        'link',
        'isOnlyInvite',
        'line',
        'templateId',
        'line',
        'payProjectId',
        'line',
        'contractSign',
        'line',
        'tags',
        'line',
        'executorOrganization',
    ],
    fields: {
        name: {
            support: 'Название сценария',
            type: 'input',
        },
        line: {
            type: 'line',
        },
        link: {
            support: 'Ссылка',
            type: 'input',
            concat: {
                position: 'start',
                text: '/link/',
                exp: /[^A-z-\d]/gi,
            },
        },
        isOnlyInvite: {
            type: 'checkbox',
            checkboxTitle: 'Регистрация доступна только по приглашению',
            checkboxDescription:
                'Исполнители без приглашения не смогут пройти<br/>регистрацию по данному сценарию',
            disabled: (model: { new?: boolean }) => !model.new,
        },
        templateId: {
            support: 'Шаблон договора',
            prop: 'templateId',
            selectTextKey: 'templateName',
            type: 'select',
            selectList: 'joinTemplates',
            selectProps: { name: 'templateName' },
            disabled: (model: { new?: boolean }) => !model.new,
        },
        payProjectId: {
            support: 'Проект',
            prop: 'payProjectId',
            selectTextKey: 'payProjectName',
            type: 'select',
            selectList: 'payProjects',
            selectProps: { name: 'payProjectName' },
        },
        contractSign: {
            support: 'Подписание договора',
            type: 'checkboxes',
            checkboxes(data: JoinScriptT) {
                return Object.keys(data.contractSignTypes).map((key) => ({
                    id: key,
                    title: data.contractSignTypes[key].name,
                }));
            },
            disabled: (model: { new?: boolean }) => !model.new,
        },
        tags: {
            support: 'Теги',
            type: 'list',
            prop: 'tags',
            list: {
                name: 'tags',
                render(tag: TagT) {
                    return <Tag color={tag.color}>{tag.name}</Tag>;
                },
                multi: true,
                button: 'Выбрать теги',
            },
        },
        executorOrganization: {
            support: 'В каком статусе может регистрироваться исполнитель',
            type: 'checkboxes',
            checkboxesCol: true,
            checkboxes(data: JoinScriptT) {
                return Object.keys(data.executorOrganizations).map((key) => ({
                    id: key,
                    title: data.executorOrganizations[key].name,
                    description: data.executorOrganizations[key].description,
                }));
            },
            disabled: (model: { new?: boolean }) => !model.new,
        },
    },
} as const;

export default joinScriptMain;
