import React from 'react';

import EditBlock from '@components/editBlock/EditBlock.tsx';

import mountHandler from './methods/mountHandler.ts';

import WidgetPageWrapperI from './types.ts';

class WidgetPageWrapper<P = {}, S = {}>
    extends EditBlock<WidgetPageWrapperI<P, S>['props'], WidgetPageWrapperI<P, S>['state']>
    implements WidgetPageWrapperI<P, S>
{
    parent: WidgetPageWrapperI['parent'];

    constructor(props: WidgetPageWrapperI<P, S>['props']) {
        super(props);
        this.state = {} as WidgetPageWrapperI<P, S>['state'];

        this.parent = React.createRef();
    }

    mountHandler = mountHandler;

    componentDidMount(): void {
        this.mountHandler();
    }
}

export default WidgetPageWrapper;
