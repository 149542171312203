const corporationReports = {
    colsOrder: ['period', 'doc', 'amount', 'status'],
    cols: {
        period: { content: 'Период',
            width: 17,
        },
        doc: { content: 'Документ',
            width: 44,
            isAdminMain: true,
        },
        amount: { content: 'Сумма',
            width: 16,
        },
        status: { content: 'Статус',
            width: 23,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'corporation-report',
    emptyInfo: {
        title: 'Отчётов пока нет',
        description: 'Они будут формироваться здесь<br/>1-го числа каждого месяца',
    },
} as const;

export default corporationReports;
