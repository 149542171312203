import React from 'react';

import setSpacesInText from '@functions/setSpacesInText';

import Business from '../types.ts';

const renderProblemBlock: Business['renderProblemBlock'] = function () {
    return (
        <div className="publicBusinessAbout__problem _COL">
            <h2 className="publicBusinessAbout__problemTitle _TITLE _PUBLIC">Проблематика:</h2>
            <div className="publicBusinessAbout__problemCards _ROW">
                {this.problemCards.map((card, cardKey) => {
                    const { title, content, icon } = card;

                    return (
                        <div
                            className={`publicBusinessAbout__problemCard _${cardKey + 1}`}
                            key={cardKey}
                        >
                            <img
                                src={require(
                                    `../../../../../../../img/crm/public/business/${icon}`,
                                )}
                                alt=""
                                className="publicBusinessAbout__problemCardIcon"
                            />
                            <div
                                className="publicBusinessAbout__problemCardTitle"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(title) }}
                            ></div>
                            <div
                                className="publicBusinessAbout__problemCardContent"
                                dangerouslySetInnerHTML={{ __html: setSpacesInText(content) }}
                            ></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default renderProblemBlock;
