import uploadFile from '@requests/uploadFile.ts';

import I from '../types.ts';

const uploadHandler: I['uploadHandler'] = async function (id, e) {
    const file = e.target.files![0];
    const formData = new FormData();

    formData.set('file', file);

    await this.setLoadings({ name: id, value: true });

    try {
        const { fullSrc, src, name } = await uploadFile({ formData });

        await this.change({ src, fullSrc, name }, undefined, `files.${id}.file`);
    } catch (error) {}

    await this.setLoadings({ name: id, value: false });
};

export default uploadHandler;
