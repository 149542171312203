import getCurrentPage from '@functions/getCurrentPage.ts';
import getPage from '@functions/getPage.ts';

import I from '../types.ts';

const getDescription: I['getDescription'] = function () {
    const { model } = this.state;
    const { inner, storePages } = this.props;

    if (inner) {
        return model?.shortName || model?.opfName || '';
    }

    const currentPage = getCurrentPage({
        storePages,
        filter: (page) => page.parentName === 'settings',
    });

    if (currentPage) {
        const page = getPage({ name: currentPage });

        return page.contentOfLink;
    }

    return '';
};

export default getDescription;
