import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dispatcher } from '../../../redux/redux';

import removeTransition from '../../../functions/removeTransition.ts';

import SideBar from './Bar.jsx';
import HideSideBar from './HideBar.jsx';

import Animate from '../../Animate.jsx';
import setNotification from '../../../functions/setNotification';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';

class SideBarWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowHideState: false,
        };

        this.checkHide = this.checkHide.bind(this);
        this.middleCheckHide = this.middleCheckHide.bind(this);
        this.handlerHideState = this.handlerHideState.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeCorporation = this.changeCorporation.bind(this);
        this.setUser = this.setUser.bind(this);

        this.hideSideBar = React.createRef();
    }

    handlerHideState(isShowHideState = !this.state.isShowHideState) {
        this.setState({ isShowHideState });
    }

    checkHide() {
        const { isHideSideBar } = this.props;

        if (isHideSideBar && window.widthValue > 1400) {
            dispatcher({ type: 'isHideSideBar', data: false });

            removeTransition({ item: '.body__sideBar' });
        }

        if (!isHideSideBar && window.widthValue <= 1400) {
            dispatcher({ type: 'isHideSideBar', data: true });

            removeTransition({ item: '.body__sideBar' });
        }
    }

    middleCheckHide() {
        this.checkHide();

        setTimeout(() => {
            this.checkHide();
        }, 10);
    }

    handlerMissClick({ target }) {
        const { isShowHideState } = this.state;

        if (this.hideSideBar.current) {
            const bar = this.hideSideBar.current.querySelector('.sideBar');

            if (isShowHideState && bar !== target && !bar.contains(target)) {
                this.setState({ isShowHideState: false });
            }
        }
    }

    changeCorporation({ detail: { id: idOfCurrentCorporation } }) {
        const { user } = this.props;

        if (idOfCurrentCorporation) {
            const currentCorporation = getCurrentCorporation({ user });

            setNotification({
                notification: 'success-change-company',
                title: `Вы успешно перешли в&nbsp;<b>${
                    currentCorporation.shortName || currentCorporation.fullName
                }</b>`,
            });
        }
    }

    changePage() {
        this.setState({ isShowHideState: false });
    }

    setUser() {
        this.setState({ user: this.props.user });
    }

    componentDidMount() {
        this.setUser();

        this.checkHide();

        document.addEventListener('changeCorporation', this.changeCorporation);
        document.addEventListener('changePage', this.changePage);
        document.addEventListener('click', this.handlerMissClick);
        document.addEventListener('changeWidthWindow', this.middleCheckHide);
    }

    componentWillUnmount() {
        document.removeEventListener('changeCorporation', this.changeCorporation);
        document.removeEventListener('changePage', this.changePage);
        document.removeEventListener('click', this.handlerMissClick);
        document.removeEventListener('changeWidthWindow', this.middleCheckHide);
    }

    render() {
        const { isShowHideState, user } = this.state;
        const { isHideSideBar, isShow ,} = this.props;
        const inAdmin = user?.idOfCurrentCorporation === 'admin';

        return (
            <>
                {isHideSideBar ? (
                    <>
                        <Animate className="body__sideBar _hide" isShow={isShow}>
                            <HideSideBar handlerHideState={this.handlerHideState} />
                        </Animate>
                        <Animate
                            refObject={this.hideSideBar}
                            className="body__sideBar _showHideState"
                            // key={!!dateOfLogout}
                            isShow={isShowHideState && isShow}
                        >
                            <Animate
                                className="body__sideBarInner _admin"
                                isShow={inAdmin}
                                isSmoothShow={true}
                            >
                                <SideBar
                                    setUser={this.setUser}
                                    isHide={true}
                                    handlerHideState={this.handlerHideState}
                                    admin={true}
                                />
                            </Animate>
                            <Animate
                                className="body__sideBarInner"
                                isShow={!inAdmin}
                                isSmoothShow={true}
                            >
                                <SideBar
                                    setUser={this.setUser}
                                    isHide={true}
                                    handlerHideState={this.handlerHideState}
                                />
                            </Animate>
                        </Animate>
                    </>
                ) : (
                    <>
                        <Animate className="body__sideBar" isShow={isShow}>
                            <Animate
                                className="body__sideBarInner _admin"
                                isShow={inAdmin}
                                isSmoothShow={true}
                            >
                                <SideBar setUser={this.setUser} admin={true} />
                            </Animate>
                            <Animate
                                className="body__sideBarInner"
                                isShow={!inAdmin}
                                isSmoothShow={true}
                            >
                                <SideBar setUser={this.setUser} />
                            </Animate>
                        </Animate>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        dateOfLogout: state.dateOfLogout,
        isHideSideBar: state.isHideSideBar,
    };
}

export default connect(mapStateToProps)(SideBarWrapper);

SideBarWrapper.propTypes = {
    user: PropTypes.object,
    dateOfLogout: PropTypes.number,
    isHideSideBar: PropTypes.bool,
    isShow: PropTypes.bool,
};
