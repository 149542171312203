import checkNotUnicode from './checkNotUnicode';

export default function getUserInfo({ type, holderType, icon, user, joinWords }) {
    switch (type) {
        case 'icon': {
            let folder;

            if (holderType === 'client') {
                folder = 'clients';
            }

            if (holderType === 'user') {
                folder = 'users';
            }

            if (holderType === 'company') {
                folder = 'companies';
            }

            if (!folder || !icon) {
                return null;
            }

            return require(`../img/icons/${folder}/icon-${icon}.png`);
        }
        case 'name': {
            if (user?.name) {
                return user.name;
            }

            let name = ``;

            if (user) {
                ['secondName', 'firstName', 'thirdName'].forEach((prop) => {
                    if (user[prop]) {
                        name += user[prop];
                        name += ` `;
                    }
                });
            }

            name = name.slice(0, -1);

            if (joinWords) {
                name = name.replaceAll(' ', '&nbsp;');
            }

            return name;
        }
        case 'doubleName': {
            if (user?.name) {
                return user.name;
            }

            let name = ``;

            if (user) {
                ['secondName', 'firstName'].forEach((prop) => {
                    if (user[prop]) {
                        name += user[prop];
                        name += ` `;
                    }
                });
            }

            name = name.slice(0, -1);

            if (joinWords) {
                name = name.replaceAll(' ', '&nbsp;');
            }

            return name;
        }
        case 'nameShort': {
            let name = ``;

            if (user && user.firstName) {
                name = user.firstName;

                if (user.secondName && user.secondName[0]) {
                    name += ` `;

                    if (checkNotUnicode(user.secondName)) {
                        name += user.secondName[0].toUpperCase();
                        name += `.`;
                    } else {
                        name += user.secondName;
                    }
                }
            }

            return name;
        }
        case 'fullnameShort': {
            if (user?.name) {
                return user.name;
            }

            let name = ``;

            if (user) {
                name += user.secondName;

                if (user.firstName) {
                    name += ' ';

                    ['firstName', 'thirdName'].forEach((prop) => {
                        if (user[prop] && user[prop][0]) {
                            if (checkNotUnicode(user[prop])) {
                                name += user[prop][0].toUpperCase();
                                name += `.`;
                            } else {
                                name += user[prop];
                            }
                        }
                    });
                }
            }

            return name;
        }
        default:
            return null;
    }
}
