import axios from 'axios';

import React from 'react';
import { connect } from 'react-redux';

import handlerAppPopup from '../../../functions/app/handlerPopup';
import getEndText from '../../../functions/getEndText';
import getHeaders from '../../../functions/getHeaders';
import setNotification from '../../../functions/setNotification';
import setSpacesInText from '../../../functions/setSpacesInText';

import HandlerFile from '../../../classes/File';
import getJoinScripts from '../../../requests/getJoinScripts';
import Animate from '../../Animate.jsx';
import BorderDashed from '../../BorderDashed.jsx';
import Icon from '../../Icon.jsx';
import Loader from '../../Loader.jsx';
import Select from '../../Select.jsx';

class UploadJoinsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.close = this.close.bind(this);
    }

    handlerFile = new HandlerFile({ typeOfFiles: 'excel' });

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    uploadFile({ target }) {
        const { scriptId } = this.state;
        const formData = new FormData();

        if (scriptId) {
            formData.set('joinScriptId', scriptId);
        }

        this.handlerLoading('load').then(() => {
            this.handlerFile.uploadFiles({ target, formData }).then(
                () => {
                    axios
                        .post(`${process.env.REACT_APP_HARD_API}/upload-joins`, formData, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success, data } = res.data;

                                if (success) {
                                    const { successCounter } = data;
                                    const title = `${successCounter} ${getEndText(successCounter, [
                                        'приглашение',
                                        'приглашения',
                                        'приглашениий',
                                    ])} успешно создано`;

                                    setNotification({
                                        notification: 'success-upload-joins',
                                        title,
                                    });

                                    handlerAppPopup({
                                        name: 'uploadJoinsPopup',
                                        isShow: false,
                                    });
                                } else {
                                    const { message } = data;

                                    if (message === 'Dont upload more 500 items') {
                                        setNotification({
                                            notification: 'error-upload-joins-more',
                                        });
                                    }
                                }

                                this.handlerLoading(null);
                            },
                            () => null,
                        );
                },
                () => null,
            );
        });
    }

    close() {
        handlerAppPopup({
            name: 'uploadJoinsPopup',
            isShow: false,
        });
    }

    setScript({ value }) {
        return new Promise((resolve) => {
            this.setState({ scriptId: value }, resolve);
        });
    }

    async getScripts() {
        const { joinScripts } = await getJoinScripts({
            params: [{ key: 'forInvite', value: true }],
        });

        this.setState({
            joinScripts: joinScripts.map((item) => ({ key: item._id, content: item.name })),
        });
    }

    componentDidMount() {
        this.getScripts();
    }

    render() {
        const { loadingKey, joinScripts = [], scriptId } = this.state;

        return (
            <div className="uploadOrdersPopup _col">
                <div className="uploadOrdersPopup__inner">
                    <div className="uploadOrdersPopup__steps _static">
                        <div className="uploadOrdersPopup__step _show">
                            <div className="uploadOrdersPopupUpload _joins">
                                <div className="uploadOrdersPopupUpload__inner">
                                    <div
                                        className="uploadOrdersPopupUpload__headClose _click _static"
                                        onClick={() => {
                                            this.close();
                                        }}
                                    >
                                        <Icon name="close-circle" />
                                    </div>
                                    <div className="uploadOrdersPopupUpload__content">
                                        <div className="uploadOrdersPopupUpload__contentHead _row">
                                            <p className="uploadOrdersPopupUpload__contentHeadTitle">
                                                Оформление пачки приглашений
                                                <br />
                                                загрузкой Excel-файла
                                            </p>
                                        </div>
                                        <div className="uploadOrdersPopupUpload__contentSteps _static">
                                            <div className="uploadOrdersPopupUpload__contentStep _show">
                                                <p
                                                    className="uploadOrdersPopupUpload__contentDescription"
                                                    dangerouslySetInnerHTML={{
                                                        __html: setSpacesInText(
                                                            'Выберите сценарий подключения. Затем скачайте шаблон, заполните в нём все данные и загрузите заполненный файл сюда.',
                                                        ),
                                                    }}
                                                ></p>
                                                <div className="uploadOrdersPopupUpload__contentButtons _row">
                                                    <div className="uploadOrdersPopupUpload__contentButton _select">
                                                        <Select
                                                            support="Выберите сценарий"
                                                            name="joinScript"
                                                            value={scriptId}
                                                            callback={this.setScript.bind(this)}
                                                            className={`_fullWidthList _bigSize`}
                                                            items={joinScripts}
                                                        />
                                                    </div>
                                                    <div className="uploadOrdersPopupUpload__contentButton _download _full">
                                                        <a
                                                            className="uploadOrdersPopup__button _row"
                                                            href={require(
                                                                `../../../img/crm/joins.xlsx`,
                                                            )}
                                                            download="Шаблон_пачки_подключений.xlsx"
                                                        >
                                                            Скачать шаблон
                                                            <i className="uploadOrdersPopup__buttonIcon">
                                                                <Icon name="file-type-doc" />
                                                            </i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <label className="uploadOrdersPopupUpload__contentUpload _col">
                                                    <div className="uploadOrdersPopupUpload__contentUploadBack">
                                                        <BorderDashed className="_upload" />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        className="uploadOrdersPopupUpload__contentUploadInput"
                                                        onChange={({ target }) => {
                                                            this.uploadFile({ target });
                                                        }}
                                                    />
                                                    <div className="uploadOrdersPopupUpload__contentUploadIcon">
                                                        <Animate
                                                            className="uploadOrdersPopupUpload__contentUploadIconItem"
                                                            isShow={!loadingKey}
                                                        >
                                                            <Icon name="file-upload" />
                                                        </Animate>
                                                        <Animate
                                                            className="uploadOrdersPopupUpload__contentUploadIconItem"
                                                            isShow={!!loadingKey}
                                                        >
                                                            <Loader className="_main" />
                                                        </Animate>
                                                    </div>

                                                    <p className="uploadOrdersPopupUpload__contentUploadTitle">
                                                        Загрузите или перетащите
                                                        <br />
                                                        сюда заполненный файл
                                                    </p>
                                                    <p className="uploadOrdersPopupUpload__contentUploadDescription">
                                                        В формате .xlsx
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(UploadJoinsPopup);

UploadJoinsPopup.propTypes = {};
