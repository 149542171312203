import ListAbsoluteMainI from '../types.ts';

const checkResizeParent: ListAbsoluteMainI['checkResizeParent'] = function () {
    const { resizeParent } = this.props;

    if (resizeParent && !this.isInitResize) {
        this.isInitResize = true;

        this.handlerResize();
    }
};

export default checkResizeParent;
