const payProjects = {
    colsOrder: ['name', 'percent', 'minimal', 'over', 'pays', 'executors'],
    cols: {
        name: {
            content: 'Название',
            width: 41,
            isAdminMain: true,
        },
        percent: {
            content: 'Процент',
            width: 12,
        },
        minimal: {
            content: 'Мин. сумма',
            width: 12,
        },
        over: {
            content: 'Надбавка',
            width: 12,
        },
        pays: {
            content: 'Акты',
            width: 9,
        },
        executors: {
            content: 'Исполнители',
            width: 14,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'pay-project',
    emptyInfo: {
        title: 'Проектов пока нет',
        description: 'Вы можете добавить новый по кнопке справа',
    },
} as const;

export default payProjects;
