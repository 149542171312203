import React from 'react';

import Avatar from '@components/avatar/Avatar.tsx';
import Link from '@components/link/Link.tsx';
import getCurrentCorporation from '@functions/crm/getCurrentCorporation.ts';
import getUserName from '@functions/getUserName.ts';
import UserT from '@global/models/User.ts';
import { FileM } from '@global/types.ts';

import ManualTableI from '../types.ts';

type ThisUserT = Pick<UserT, '_id' | 'firstName' | 'secondName' | 'thirdName'> & {
    type?: string;
    logo?: FileM;
};

const renderUser: ManualTableI['renderUser'] = function ({ item, withLink }) {
    let user = (item.user || item.userInfo) as ThisUserT;

    if (!user) {
        user = {
            _id: 'empty',
            firstName: '',
            secondName: 'Удалённый аккаунт',
        };
    }

    const { user: globalUser } = this.state;
    let username = user && getUserName({ user });
    let src = user?.logo?.fullSrc;

    if (item.type === 'corporation') {
        const currentCorporation = getCurrentCorporation({
            user: globalUser,
        }) as UserT['corporationsInfo'][number];

        if (currentCorporation) {
            const corporationName = currentCorporation.shortName || currentCorporation.fullName;

            username = `CRM ${corporationName || ''}`;

            if (currentCorporation.logo?.fullSrc) {
                src = currentCorporation.logo?.fullSrc;
            } else {
                username = (corporationName || ' ')[0];
            }
        }
    }

    const avatarName = username ? username[0] : '–';

    const TagAvatar = withLink ? Link : 'div';
    const propAvatar = withLink ? { href: 'manual-executors-inner', keyUniq: user._id } : {};

    return (
        <div
            className={`v2table__user _row ${user.type === 'system' ? '_system' : ''} ${
                withLink ? '_withLink' : ''
            }`}
        >
            <TagAvatar className="v2table__userAvatar" {...propAvatar}>
                <Avatar className="_circle _grey" firstSymb={avatarName} image={src} />
            </TagAvatar>
            <div className="v2table__userInfo">
                <p className="v2table__text">{username}</p>
            </div>
        </div>
    );
};

export default renderUser;
