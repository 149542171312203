import React from 'react';

import ListScroll from '@components/listScroll/ListScroll.tsx';

import ManualTableI from '../types.ts';

const renderContent: ManualTableI['renderContent'] = function () {
    const { items = [], isLimit, isDisabledScroll } = this.state;
    const { isWrap, staticItems } = this.props;

    if (staticItems) {
        return (
            <>
                <div className={`v2table__content _new _col ${isWrap ? '_wrapContent' : ''}`}>
                    {staticItems.map((item, index) =>
                        this.renderRow({ item, prop: item._id, key: index }),
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <ListScroll
                getParent={this.getScrollParent.bind(this)}
                callback={this.getMoreItems.bind(this)}
                startCounter={this.stepCounter}
                stepCounter={this.stepCounter}
                maxCounter={Infinity}
                lengthCurrent={items.length}
                handlerLoaderList={this.handlerLoaderList.bind(this)}
                isLimit={!!isLimit}
                isDisabledScroll={!!isDisabledScroll}
            >
                <div className={`v2table__content _new _col ${isWrap ? '_wrapContent' : ''}`}>
                    {items.map((item, index) =>
                        this.renderRow({ item, prop: item._id, key: index }),
                    )}
                </div>
            </ListScroll>
        </>
    );
};

export default renderContent;
