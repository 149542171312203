import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getDateSumDays from '../functions/getDateSumDays';
import getEnvParams from '../functions/getEnvParams';
import getFormatedNumber from '../functions/getFormatedNumber.ts';
import getRealParams from '../functions/getRealParams.ts';
import getUserInfo from '../functions/getUserInfo';
import removeTransition from '../functions/removeTransition.ts';

import HandlerFilter from '../classes/Filter';
import Animate from './Animate.jsx';
import AnimateChange from './AnimateChange.jsx';
import Button from './Button.jsx';
import Calendar from './Calendar.jsx';
import Icon from './Icon.jsx';
import Search from './Search.jsx';
import Switch from './Switch.jsx';
import List from './filter/List.jsx';

const infoDates = require('../infos/dates.json');

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drops: {},
        };

        this.handlerButton = this.handlerButton.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.setHeight = this.setHeight.bind(this);
        this.keySave = this.keySave.bind(this);

        this.parent = React.createRef();
    }

    info = {
        statusOrder: {
            title: 'Статус заказа',
        },
        statusesExecutor: {
            title: 'Статус исполнителя',
        },
        categoriesExecutor: {
            title: 'Категория',
        },
        organizationsExecutor: {
            title: 'Тип',
        },
        projects: {
            title: 'Проекты',
        },
        typeOrder: {
            title: 'Тип заказа',
        },
        creater: {
            title: 'Сотрудник',
        },
        dates: {
            title: 'Дата',
        },
        tariffsCategories: {
            title: 'Категория',
        },
        typeBodyworks: {
            title: 'Тип кузова',
        },
        userRoles: {
            title: 'Роли',
        },
        userActivity: {
            title: 'Активность',
        },
        responsible: {
            title: 'Ответственный',
        },
        crew: {
            title: 'Экипаж',
        },
        corporationBalanceType: {
            title: 'Наименование услуги',
        },
        searchForNumberOrder: {
            title: 'Поиск по номеру заказа',
            support: 'Введите номер',
            regType: 'number',
        },
        searchForId: {
            title: 'Поиск по ID',
            support: 'Введите ID',
            regType: 'number',
        },
        searchForNumberCar: {
            title: 'Поиск по рег. номер',
            support: 'Введите рег. номер',
            regType: 'numberCar',
        },
        searchForName: {
            title: 'Поиск по ФИО',
            support: 'Введите ФИО',
            regType: 'russianText',
        },
        searchForGroup: {
            title: 'Поиск по группе',
            support: 'Введите название группы',
        },
        searchForResponsible: {
            title: 'Поиск по сотруднику',
            support: 'Введите ФИО',
            regType: 'russianText',
        },
        searchForOrgName: {
            title: 'Поиск по наименованию',
            support: 'Введите название',
            regType: 'russianText',
        },
        searchForNaming: {
            title: 'Поиск по названию',
            support: 'Введите название',
        },
        searchForInn: {
            title: 'Поиск по ИНН',
            support: 'Введите номер',
            regType: 'number',
        },
        searchForIdEdo: {
            title: 'Поиск по номеру договора',
            support: 'Введите номер',
            regType: 'number',
        },
        searchForTitle: {
            title: 'Поиск по заголовку',
            support: 'Введите заголовок',
        },
        searchForTags: {
            title: 'Поиск по тегам',
            support: 'Введите название тега',
        },
        searchForContent: {
            title: 'Поиск по тексту',
            support: 'Введите часть текста',
        },
        searchForPhone: {
            title: 'Поиск по телефону',
            support: 'Введите телефону',
            keyName: 'phone',
        },
        searchForWord: {
            title: 'Поиск по слову',
            support: 'Введите слово',
        },
        searchForEmail: {
            title: 'Поиск по E-mail',
            support: 'Введите E-mail',
        },
        searchForNumberPay: {
            title: 'Поиск по номеру платежа',
            support: 'Введите номер',
            regType: 'number',
        },
        searchForActName: {
            title: 'Поиск по назначению',
            support: 'Введите назначение',
        },
        searchForAction: {
            title: 'Поиск по действию',
            support: 'Введите название',
        },
        searchForAmount: {
            title: 'Поиск по сумме',
            support: 'Введите сумму',
            regType: 'numberFloatWithSign',
        },
        searchForLink: {
            title: 'Поиск по ссылке',
            support: 'Введите ссылку',
        },
        searchForJoinTemplate: {
            title: 'Поиск по шаблону',
            support: 'Введите название',
        },
        searchForContract: {
            title: 'Поиск по договору',
            support: 'Введите номер',
        },
        searchForNumber: {
            title: 'Поиск по номеру',
            support: 'Введите номер',
            regType: 'number',
        },
        searchForJoinScripts: {
            title: 'Поиск по сценарию',
            support: 'Введите название',
        },
        searchForExecutor: {
            title: 'Поиск по экипажу',
            support: 'Введите ФИО',
        },
        searchForRole: {
            title: 'Поиск по роли',
            support: 'Введите название',
        },
        onlyActiveContract: {
            title: 'Активный договор',
        },
        markOfCar: {
            title: 'Марка',
        },
        modelOfCar: {
            title: 'Модель',
        },
        cars: {
            title: 'Автомобиль',
        },
        bodyworks: {
            title: 'Кузов',
        },
        loadingsCar: {
            title: 'Погрузка',
        },
        executors: {
            title: 'Исполнитель',
        },
        contractors: {
            title: 'Контрагенты',
        },
        categoriesTariffs: {
            title: 'Категория',
        },
        typesTariffs: {
            title: 'Тип',
        },
        carTypes: {
            title: 'Тип',
        },
        contractStatuses: {
            title: 'Статус договора',
        },
        fnsStatuses: {
            title: 'Подключён в Мой налог',
        },
        typeOrganization: {
            title: 'Тип',
        },
        viewTypeOrganization: {
            title: 'Тип',
        },
        typeContract: {
            title: 'Тип',
        },
        opf: {
            title: 'ОПФ',
        },
        articleSections: {
            title: 'Тип',
        },
        articleCategories: {
            title: 'Категория',
        },
        tagGroup: {
            title: 'Группа',
        },
        modelMark: {
            title: 'Марка',
        },
        clients: {
            title: 'Клиент',
        },
        companies: {
            title: 'Компания',
        },
        executorUsers: {
            title: 'Исполнитель',
        },
        organizations: {
            title: 'Клиент',
        },
        infoArticleSections: {
            title: 'Раздел',
        },
        executorCorporation: {
            title: 'Штатный водитель',
        },
        isActive: {
            title: 'Активный',
        },
        googleVisionEmptyWords: {
            title: 'Незаполненные слова',
        },
        paysStatuses: {
            title: 'Статусы платежа',
        },
        taxesStatuses: {
            title: 'Статусы',
        },
        action: {
            title: 'Действие',
        },
        positiveBalance: {
            title: 'Положительный баланс',
        },
        signStatuses: {
            title: 'Статусы договора',
        },
        corporations: {
            title: 'Компания',
        },
        statuses: {
            title: 'Статусы',
        },
        errorsStatuses: {
            title: 'Ошибки платежа',
        },
        emptyFiles: {
            title: 'Нет файлов',
        },
        hasPays: {
            title: 'Проводились платежи',
        },
        hasntInn: {
            title: 'Нет ИНН',
        },
        articlePlaces: {
            title: 'Место размещения',
        },
    };

    isGetData = false;

    filterCountersSave = {};

    getInfoButton() {
        const { inMoment } = this.props;

        if (this.checkUpdate() && !inMoment) {
            return {
                content: 'Применить',
            };
        }

        return {
            content: 'Сбросить',
        };
    }

    getData() {
        const { filter } = this.props;

        if (!this.isGetData && filter) {
            this.isGetData = true;
        }
    }

    renderContent({ block, item }) {
        const { serverData } = this.props;
        const { orderStatuses, tariffs } = serverData;

        if (block.key === 'statusOrder') {
            return orderStatuses?.[item.id]?.filterContent;
        }

        if (block.key === 'tariffsCategories') {
            return tariffs?.categories.find((category) => category.key === item.id)?.short;
        }

        if (block.key === 'typeBodyworks') {
            return tariffs?.['types-of-configurations'].find((type) => type.key === item.id)?.short;
        }

        return item.content;
    }

    getListItems({ block }) {
        if (block.key === 'responsible') {
            return block.items.map((item) => ({
                ...item,
                iconSrc: getUserInfo({ type: 'icon', holderType: 'user', icon: item.icon }),
                content: getUserInfo({ type: 'name', user: item }),
            }));
        }

        if (block.key === 'creater' || block.key === 'clients') {
            const { serverData } = this.props;
            const { userIcons: icons } = serverData;

            return block.items.map((item) => ({
                ...item,
                iconSrc: getUserInfo({ type: 'icon', icons, icon: item.icon, iconName: 'full' }),
                content: `<b>${item.secondName || ''}</b> ${item.firstName || ''} ${
                    item.thirdName || ''
                }`,
            }));
        }

        if (block.key === 'cars') {
            return block.items.map((item) => ({
                ...item,
                iconSrc: `${process.env.REACT_APP_STATIC}/car-icons/${item.icon}`,
                content: `${item.nameMark}, ${item.nameModel}, ${item.number}`,
                className: '_car',
            }));
        }

        if (block.key === 'bodyworks') {
            return block.items.map((item) => ({
                ...item,
                iconSrc: `${process.env.REACT_APP_STATIC}/car-icons/${item.icon}`,
                className: '_car',
            }));
        }

        if (block.key === 'executors' || block.key === 'executorUsers') {
            return block.items.map((item) => ({
                ...item,
                iconSrc: `${process.env.REACT_APP_STATIC}/images/`,
                content: `<b>${item.secondName}</b> ${item.firstName} ${item.thirdName}`,
            }));
        }

        if (block.key === 'contractors') {
            return block.items.map((item) => ({
                ...item,
                iconSrc: `${process.env.REACT_APP_STATIC}/images/`,
            }));
        }

        if (block.key === 'companies') {
            return block.items.map((item) => ({
                ...item,
                content: `${item.name}, <b>${item.inn}</b>`,
            }));
        }

        if (block.key === 'corporations') {
            return block.items.map((item) => ({
                ...item,
                content: `${item.name}, <b>${item.inn}</b>`,
            }));
        }

        return block.items;
    }

    getHeightInner({ key }) {
        const block = this.parent.current.querySelector(`.filter__block[data-key="${key}"]`);
        const drop = block.querySelector('.filter__blockContentInner');
        const { offsetHeight: heightDrop } = drop;

        return heightDrop;
    }

    isInit = false;

    handlerDate({ type, key, currentDate: firstDate, currentDateDouble: secondDate }) {
        const { handlerFilter } = this.getFilterSettings();

        handlerFilter.handlerItem({
            type,
            key,
            firstDate,
            secondDate,
        });
    }

    getResultDates(block) {
        const { firstDate, secondDate } = block;

        if (firstDate) {
            let str = '';

            const firstDateObj = new Date(firstDate);
            const firstDay = firstDateObj.getDate();
            const firstMonth = firstDateObj.getMonth();
            const firstYear = firstDateObj.getFullYear();

            // console.log(block);

            if (!secondDate) {
                str += firstDay;
                str += ' ';
                str += infoDates.monthsInText[firstMonth].toLowerCase();

                return str;
            }

            const secondDateObj = new Date(secondDate);
            const secondDay = secondDateObj.getDate();
            const secondMonth = secondDateObj.getMonth();
            const secondYear = secondDateObj.getFullYear();

            if (firstDay === secondDay && firstMonth === secondMonth && firstYear === secondYear) {
                str += firstDay;
                str += ' ';
                str += infoDates.monthsInText[firstMonth].toLowerCase();

                return str;
            }

            if (firstMonth === secondMonth && firstYear === secondYear) {
                str += firstDay;
                str += '–';
                str += secondDay;
                str += ' ';
                str += infoDates.monthsInText[firstMonth].toLowerCase();

                return str;
            }

            if (firstYear === secondYear) {
                str += firstDay;
                str += '&nbsp;';
                str += infoDates.monthsShort[firstMonth].toLowerCase();
                str += ' – ';
                str += secondDay;
                str += '&nbsp;';
                str += infoDates.monthsShort[secondMonth].toLowerCase();

                return str;
            }

            str += getFormatedNumber(+firstDay);
            str += '.';
            str += getFormatedNumber(+firstMonth);
            str += '.';
            str += getFormatedNumber(+firstYear.toString().slice(2, 4));
            str += ' – ';
            str += getFormatedNumber(+secondDay);
            str += '.';
            str += getFormatedNumber(+secondMonth);
            str += '.';
            str += getFormatedNumber(+secondYear.toString().slice(2, 4));

            return str;
        }

        return '';
    }

    handlerMissClick({ target, detail }) {
        const { hideFilter } = this.props;

        if (detail?.name) {
            if (detail?.name !== 'filter') {
                hideFilter();
            }
        } else if (this.parent.current !== target && !this.parent.current.contains(target)) {
            hideFilter();
        }
    }

    getFilterSettings() {
        const { inMoment } = this.props;

        return {
            filter: inMoment ? this.props.filter : this.state.filter,
            handlerFilter: inMoment ? this.props.handlerFilter : this.handlerFilter,
        };
    }

    handlerButton() {
        const { filter, handlerFilter } = this.getFilterSettings();
        const { callback, inMoment, hideFilter } = this.props;

        if (this.checkUpdate() && !inMoment) {
            if (callback) {
                callback({ filter, isChange: true }).then(() => {
                    setTimeout(() => {
                        this.setHeight();
                    }, 10);

                    if (handlerFilter.callback) {
                        handlerFilter.callback();
                    }
                });
            }
        } else {
            handlerFilter.clear().then(() => {
                setTimeout(() => {
                    this.setHeight();
                }, 10);

                if (callback) {
                    callback({ filter: this.state.filter, isChange: true });
                }

                if (handlerFilter.callback) {
                    handlerFilter.callback();
                }
            });
        }

        hideFilter();
    }

    checkUpdate() {
        const { filter: filterState } = this.state;
        const { filter: filterProps } = this.props;
        let isUpdate = false;

        if (filterState && filterProps) {
            filterState.forEach((itemState) => {
                const { key } = itemState;
                const itemProps = filterProps.find((block) => block.key === key);

                if (itemState.type === 'search') {
                    isUpdate = isUpdate || itemState.search !== itemProps.search;
                } else if (itemState.type === 'dates') {
                    const getKeyDate = (date) => {
                        if (!date) {
                            return 0;
                        }

                        const dateObj = new Date(date);

                        return getDateSumDays(
                            dateObj.getDate(),
                            dateObj.getMonth(),
                            dateObj.getFullYear(),
                        );
                    };

                    const keyFirstDateState = getKeyDate(itemState.firstDate);
                    const keyFirstDateProps = getKeyDate(itemProps.firstDate);
                    const keySecondDateState = getKeyDate(itemState.secondDate);
                    const keySecondDateProps = getKeyDate(itemProps.secondDate);

                    isUpdate =
                        isUpdate ||
                        !!(keyFirstDateState !== keyFirstDateProps) ||
                        !!(keySecondDateState !== keySecondDateProps);
                } else {
                    itemState.choices.forEach((choice) => {
                        isUpdate = isUpdate || itemProps.choices.indexOf(choice) === -1;
                    });
                    itemProps.choices.forEach((choice) => {
                        isUpdate = isUpdate || itemState.choices.indexOf(choice) === -1;
                    });
                }
            });
        }

        return isUpdate;
    }

    getCondForButton() {
        const { inMoment } = this.props;
        const { filter, handlerFilter } = this.getFilterSettings();

        return (
            filter?.filter(handlerFilter.checkEmpty).length > (inMoment ? 1 : 0) ||
            (!inMoment && this.checkUpdate())
        );
    }

    setHeight(e) {
        if (this.parent.current) {
            const parent = this.parent.current;
            const top = parent.getBoundingClientRect().y;
            const maxHeight = document.documentElement.clientHeight - top - 42;
            const classNames = this.getCondForButton() ? ['_withButton'] : [];

            let { height: heightInner } = getRealParams({
                parent,
                elem: '.filter__inner',
                classNames,
                width: parent.offsetWidth,
                clearStyleElems: ['.filter__box'],
                // isNotRemove: true,
            });

            if (heightInner > maxHeight) {
                heightInner = maxHeight;
            }

            if (heightInner !== this.state.heightInner) {
                if (e) {
                    removeTransition({ item: '.filter__box' });
                }

                this.setState({ heightInner });
            }
        }
    }

    checkChange() {
        const { filter: filterState } = this.state;
        const { filter: filterProps } = this.props;
        let isChange = false;

        if (filterState && filterProps) {
            if (filterProps.length !== filterState.length) {
                isChange = true;
            }
        }

        if (isChange) {
            this.setState({ filter: this.props.filter }, () => {
                this.setHeight();
            });
        }
    }

    handlerFilter = new HandlerFilter({
        context: this,
        callback: () => {
            this.setHeight();
        },
    });

    keySave({ which }) {
        const condForButton = this.getCondForButton();

        if (which === 13 && condForButton) {
            this.handlerButton();
        }
    }

    componentDidMount() {
        const { initHandlerFilter } = this.props;

        this.getData();
        this.checkChange();
        this.setHeight(true);

        this.handlerFilter.init({ blocks: [] });

        if (initHandlerFilter) {
            initHandlerFilter({
                callback: () => {
                    this.setHeight();
                },
            });
        }

        setTimeout(() => {
            document.addEventListener('mousedown', this.handlerMissClick);
            document.addEventListener('continuePropagationClick', this.handlerMissClick);
        }, 10);

        document.addEventListener('changeHeightWindow', this.setHeight);
        document.addEventListener('keydown', this.keySave);
    }

    componentDidUpdate() {
        this.getData();
        this.checkChange();
    }

    componentWillUnmount() {
        const { filter } = this.state;
        const { callback } = this.props;

        document.removeEventListener('mousedown', this.handlerMissClick);
        document.removeEventListener('continuePropagationClick', this.handlerMissClick);
        document.removeEventListener('changeHeightWindow', this.setHeight);
        document.removeEventListener('keydown', this.keySave);

        if (callback) {
            callback({ filter, isNotUpdate: true });
        }
    }

    render() {
        const { heightInner } = this.state;
        const { className = '' } = this.props;
        const condForButton = this.getCondForButton();
        const infoButton = this.getInfoButton();
        const { filter, handlerFilter } = this.getFilterSettings();

        // console.log(this.checkUpdate());

        return (
            <div
                ref={this.parent}
                className={`filter ${className} ${condForButton ? '_withButton' : ''}`}
            >
                <div className="filter__top"></div>
                <div className="filter__wrap">
                    <Animate className="filter__button" isShow={condForButton}>
                        <div className="filter__buttonInner">
                            <AnimateChange
                                className="filter__buttonItem"
                                prop={infoButton.content}
                                type="_translateMedium"
                                id="filterButton"
                                // isDisabled={true}
                            >
                                <Button
                                    className={getEnvParams().classNameFilterButton}
                                    onClick={this.handlerButton}
                                >
                                    {infoButton.content}
                                </Button>
                            </AnimateChange>
                        </div>
                    </Animate>
                    <div
                        className="filter__box"
                        style={heightInner ? { height: `${heightInner}px` } : {}}
                    >
                        <div className="filter__inner">
                            {filter?.map((block, key) => {
                                const infoBlock = this.info[block.key];
                                const isNotEmpty = handlerFilter.checkEmpty(block);

                                if (block.choices.length > 0) {
                                    this.filterCountersSave[block.key] = block.choices.length;
                                }

                                return (
                                    <div
                                        className={`filter__block ${!isNotEmpty ? '_empty' : ''} ${
                                            filter[key]?.isShow ? '_active' : ''
                                        } _${block.type}`}
                                        key={block.key}
                                        data-key={block.key}
                                    >
                                        <div
                                            className="filter__blockHead _row _click"
                                            onClick={() =>
                                                handlerFilter.handlerDrop({ key: block.key })
                                            }
                                        >
                                            <div className="filter__blockName _row">
                                                {infoBlock.title}:
                                                {['dates'].indexOf(block.type) === -1 && (
                                                    <Animate
                                                        className="filter__blockCounter"
                                                        isShow={
                                                            !filter[key]?.isShow &&
                                                            ((block.type === 'search' &&
                                                                block.search) ||
                                                                block.choices.length > 0)
                                                        }
                                                    >
                                                        <AnimateChange
                                                            className="filter__blockCounterInner _col"
                                                            prop={
                                                                (block.type === 'serach' &&
                                                                    block.search) ||
                                                                block.choices.length
                                                            }
                                                            isDisabled={
                                                                (block.type === 'search' &&
                                                                    !block.search) ||
                                                                block.choices.length <= 0
                                                            }
                                                            type="_translateMedium"
                                                        >
                                                            {`${
                                                                (block.type === 'search' && 1) ||
                                                                block.choices.length
                                                            }`}
                                                        </AnimateChange>
                                                    </Animate>
                                                )}
                                                {block.type === 'dates' && (
                                                    <Animate
                                                        className="filter__blockNameInner"
                                                        isShow={this.getResultDates({ ...block })}
                                                    >
                                                        <AnimateChange
                                                            className="filter__blockNameInnerItem"
                                                            prop={this.getResultDates({
                                                                ...block,
                                                            })}
                                                            type="_translateMedium"
                                                            isDisabled={
                                                                !this.getResultDates({ ...block })
                                                            }
                                                        >
                                                            <div
                                                                className="filter__blockNameInnerItemElem"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.getResultDates({
                                                                        ...block,
                                                                    }),
                                                                }}
                                                            ></div>
                                                        </AnimateChange>
                                                    </Animate>
                                                )}
                                            </div>
                                            <div className="filter__blockActions _row">
                                                <div
                                                    className="filter__blockClear _col _click"
                                                    onClick={(e) => {
                                                        e.stopPropagation();

                                                        handlerFilter.handlerItem({
                                                            type: block.type,
                                                            key: block.key,
                                                            action: 'clear',
                                                        });
                                                    }}
                                                >
                                                    <i className="filter__blockClearIcon">
                                                        <Icon name="basket" />
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="filter__blockContent"
                                            style={
                                                filter[key]?.isShow
                                                    ? filter[key].isFresh
                                                        ? {}
                                                        : {
                                                              height: `${this.getHeightInner({
                                                                  key: block.key,
                                                              })}px`,
                                                          }
                                                    : { height: 0 }
                                            }
                                        >
                                            <div className="filter__blockContentInner">
                                                {block.type === 'checkbox' && (
                                                    <div className="filter__tabs _row">
                                                        {block.items.map((item) => (
                                                            <label
                                                                className={`filter__tab ${
                                                                    this.renderContent({
                                                                        block,
                                                                        item,
                                                                    })
                                                                        ? '_isReady'
                                                                        : ''
                                                                }`}
                                                                key={item.id}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="filter__tabInput"
                                                                    checked={
                                                                        !!block.choices.find(
                                                                            (choice) =>
                                                                                choice === item.id,
                                                                        )
                                                                    }
                                                                    onChange={() =>
                                                                        handlerFilter.handlerItem({
                                                                            type: block.type,
                                                                            key: block.key,
                                                                            id: item.id,
                                                                        })
                                                                    }
                                                                />
                                                                <div className="filter__tabView _click">
                                                                    {this.renderContent({
                                                                        block,
                                                                        item,
                                                                    })}
                                                                </div>
                                                            </label>
                                                        ))}
                                                    </div>
                                                )}
                                                {block.type === 'list' && (
                                                    <div className="filter__list">
                                                        <List
                                                            name={block.key}
                                                            items={this.getListItems({ block })}
                                                            choices={block.choices}
                                                            callback={async ({ action, id }) => {
                                                                const result =
                                                                    action === 'change'
                                                                        ? await handlerFilter.handlerItem(
                                                                              {
                                                                                  type: block.type,
                                                                                  key: block.key,
                                                                                  id,
                                                                              },
                                                                          )
                                                                        : await new Promise(
                                                                              (resolve) =>
                                                                                  resolve(),
                                                                          );

                                                                setTimeout(() => {
                                                                    this.setHeight();
                                                                }, 10);

                                                                return result;
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {block.type === 'dates' && (
                                                    <div className="filter__calendar">
                                                        <Calendar
                                                            type="filter"
                                                            handlerDate={({
                                                                currentDate,
                                                                currentDateDouble,
                                                            }) => {
                                                                this.handlerDate({
                                                                    type: block.type,
                                                                    key: block.key,
                                                                    currentDate,
                                                                    currentDateDouble,
                                                                });
                                                            }}
                                                            startDate={block.firstDate}
                                                            startDateDouble={block.secondDate}
                                                            withPast={true}
                                                            isDouble={true}
                                                            listYears={block.listYears}
                                                            callbackInit={() => {
                                                                this.setHeight();
                                                            }}
                                                            callbackChange={() => {
                                                                this.setHeight();
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {block.type === 'search' && (
                                                    <div className="filter__search">
                                                        <Search
                                                            name="filter"
                                                            value={block.search}
                                                            support={infoBlock.support}
                                                            className="_grey"
                                                            regType={infoBlock.regType}
                                                            keyName={infoBlock.keyName}
                                                            id={block.key}
                                                            callback={({ action, value }) =>
                                                                new Promise((resolve) => {
                                                                    if (action === 'change') {
                                                                        handlerFilter
                                                                            .handlerItem({
                                                                                type: block.type,
                                                                                key: block.key,
                                                                                search: value,
                                                                            })
                                                                            .then(resolve);
                                                                    } else {
                                                                        resolve();
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                {block.type === 'switch' && (
                                                    <div className="filter__switch">
                                                        <Switch
                                                            value={block.choices[0] === block.key}
                                                            className="_size3"
                                                            handler={({ action }) => {
                                                                if (action === 'change') {
                                                                    handlerFilter.handlerItem({
                                                                        type: block.type,
                                                                        key: block.key,
                                                                        id: block.key,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(Filter);

Filter.propTypes = {
    className: PropTypes.string,
    filter: PropTypes.array,
    handlerFilter: PropTypes.object,
    serverData: PropTypes.object,
    hideFilter: PropTypes.func,
    callback: PropTypes.func,
    inMoment: PropTypes.bool,
    initHandlerFilter: PropTypes.func,
};
