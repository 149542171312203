import I from '../types.ts';

const checkBorders: I['checkBorders'] = function (x, y) {
    const area = this.getPinArea();

    const leftBorder = 20;
    const rightBorder = document.documentElement.clientWidth - area.offsetWidth - 20;
    const topBorder = 20;
    const bottomBorder = document.documentElement.clientHeight - area.offsetHeight - 20;

    if (x < leftBorder) {
        x = leftBorder;
    }

    if (x > rightBorder) {
        x = rightBorder;
    }

    if (y < topBorder) {
        y = topBorder;
    }

    if (y > bottomBorder) {
        y = bottomBorder;
    }

    return { x, y };
};

export default checkBorders;
