import AlertI from '../../types';

const inactiveJoinContractHasMvd = {
    title: 'Подтвердите<br/>расторжение договора',
    description(this: AlertI) {
        const { info } = this.state;

        return `На данный договор подано МВД уведомление. Вы уверены, что хотите расторгнуть договор <b>${info}</b>?`;
    },
    buttonText: 'Не расторгать',
    cancelText: 'Всё равно расторгнуть',
} as const;

export default inactiveJoinContractHasMvd;
