import getFormatedDate from '@functions/getFormatedDate';
import setDateFromStr from '@functions/setDateFromStr';

import { FieldT } from '../cards';

const contract = {
    fieldsOrder: (model: { type: string }) =>
        model.type === 'userProxy'
            ? ['userId', 'createdDate', 'actionDate']
            : ['executorId', 'scriptId', 'createdDate', 'actionDate'],
    fields: {
        executorId: {
            support: 'Исполнитель',
            prop: 'executorId',
            selectTextKey: 'executorName',
            type: 'select',
            selectList: 'executors',
            selectProps: { fullName: 'executorName', contract: 'executorContract' },
        },
        userId: {
            support: 'Сотрудник',
            prop: 'executorId',
            selectTextKey: 'executorName',
            type: 'select',
            selectList: 'users',
            selectProps: { fullName: 'executorName', contract: 'executorContract' },
        },
        scriptId: {
            support: 'Сценарий',
            prop: 'scriptId',
            selectTextKey: 'scriptName',
            type: 'select',
            selectList: 'joinScripts',
            selectProps: { name: 'scriptName' },
        },
        createdDate: {
            support: 'Дата заключения',
            type: 'date',
            reg: 'date',
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
            setChange({ fields, model }: { fields: any; model: any }) {
                const date = fields.createdDate as string;
                const dateInst = setDateFromStr(date);

                if (dateInst && !model.actionDate) {
                    dateInst.setUTCFullYear(dateInst.getUTCFullYear() + 1);

                    const actionDate = getFormatedDate({ date: dateInst });

                    return {
                        actionDate,
                    };
                }

                return {};
            },
        },
        actionDate: {
            support: 'Дата окончания',
            type: 'date',
            reg: 'date',
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
    },
} as const;

export default contract;
