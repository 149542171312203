import React from 'react';
import { connect } from 'react-redux';

import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';

import save from './methods/save.ts';

import InnerMainI from './types.ts';

class InnerMain
    extends WidgetPageWrapper<InnerMainI['props'], InnerMainI['state']>
    implements InnerMainI
{
    parent: InnerMainI['parent'];

    constructor(props: InnerMainI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    save = save;

    render() {
        const { model, error } = this.state;
        const { config } = this.props;

        return (
            <>
                <WidgetPage
                    name={config.tableName}
                    save={this.save.bind(this)}
                    disabled={!this.checkChange().isChange}
                    error={error}
                >
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__card">
                                    <WidgetCard
                                        name={config.cardName}
                                        model={model}
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                        addArrayItem={this.addArrayItem.bind(this)}
                                        deleteArrayItem={this.deleteArrayItem.bind(this)}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(InnerMain);
