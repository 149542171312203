import axios from 'axios';

import downloadFile from './downloadFile.ts';
import getHeaders from './getHeaders';

type ParamsT = {
    name: string;
    files: { name?: string; src: string; isDelete?: boolean }[];
};

export default async function download({ name, files }: ParamsT): Promise<void> {
    const response = await axios.post<{}, { data: { text: () => Promise<string> } | string }>(
        `${process.env.REACT_APP_API}/download`,
        { files },
        {
            responseType: 'blob',
            headers: getHeaders(),
        },
    );

    try {
        const result = await (response.data as { text: () => Promise<string> }).text();

        JSON.parse(result);
    } catch (error) {
        const resultExp = files.length > 1 ? 'zip' : files[0].src.split('.').pop()!.toLowerCase();
        const fileName = `${name}.${resultExp}`;
        const resultType = files.length > 1 ? 'application/zip' : `image/${resultExp}`;

        console.log(fileName, resultType, response.data);

        downloadFile({ name: fileName, type: resultType, data: response.data as string });
    }
}
