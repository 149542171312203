import handlerPopup from '@functions/handlerPopup.ts';
import { FilterQueryT } from '@global/types.ts';

import ManualTableI from '../types.ts';

const showFilter: ManualTableI['showFilter'] = function ({ detail: { name } }) {
    const { filter, filterQuery } = this.state;

    if (name === this.props.name && filter && filter.length) {
        handlerPopup('filterPopup', {
            isShow: true,
            filter,
            filterQuery,
            name,
            callback: (result: FilterQueryT[]) => {
                this.setFilter.call(this, result);
            },
        });
    }
};

export default showFilter;
