import React from 'react';

import { RenderColsT } from '../../types.ts';

const renderListCountries: RenderColsT<'listCountries'> = function ({ name: colName, item }) {
    let content;
    let className;

    const country = item as {
        _id: string;
        name: string;
        code: string;
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text _right">{country.name}</div>,{' '}
                <div className="v2table__text _right">{country.code}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListCountries;
