import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';

import I from '../types.ts';

const renderMainBlock: I['renderMainBlock'] = function () {
    const { model, error } = this.state;

    return (
        <div className="v2widgetJoinScript__block _main">
            <div className="v2widgetJoinScript__blockInner ">
                <div className="v2widgetJoinScript__blockHead">
                    <div className="v2widgetJoinScript__blockHeadTitle">
                        Общая информация о сценарие
                    </div>
                    <AnimateChange
                        className="v2widgetJoinScript__blockHeadDescription"
                        renderKey={model?.name || '–'}
                        styles={['height']}
                    >
                        <> {model?.name || '–'}</>
                    </AnimateChange>
                </div>
                <div className="v2widgetJoinScript__blockContent _NOSCROLL">
                    <div className="v2widgetJoinScript__blockContentInner">
                        <WidgetCard
                            className="_empty"
                            name="joinScriptMain"
                            model={model!}
                            change={this.change.bind(this)}
                            getValue={this.getValue.bind(this)}
                            error={error}
                            withHead={false}
                            addArrayItem={this.addArrayItem.bind(this)}
                            deleteArrayItem={this.deleteArrayItem.bind(this)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default renderMainBlock;
