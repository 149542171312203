import lists from '../lists';

const listJoinTemplates = {
    ...lists,
    query: [{ key: 'type', value: 'joinTemplates' }],
    emptyInfo: {
        title: 'Шаблонов пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listJoinTemplates;
