import TagT from '@global/models/Tag';

const cityMain = {
    title: 'Данные',
    description: (model: TagT) => model?.name,
    fieldsOrder: ['name', 'region'],
    fields: {
        name: {
            support: 'Название',
            type: 'input',
            group: '1',
            width: 1,
        },
        region: {
            support: 'Регион',
            type: 'input',
            group: '1',
            width: 1,
        },
    },
} as const;

export default cityMain;
