import React from 'react';
import { connect } from 'react-redux';

import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';
import getPermissions from '@functions/crm/getPermissions.ts';
import { StoreT } from '@global/types.ts';

import save from './methods/save.ts';

import MainI from './types.ts';

import renderGroupCards from './renders/renderGroupCards.tsx';
import renderLimits from './renders/renderLimits.tsx';
import renderTariff from './renders/renderTariff.tsx';
import renderVerification from './renders/renderVerification.tsx';

class Main extends WidgetPageWrapper<MainI['props'], MainI['state']> implements MainI {
    parent: MainI['parent'];

    constructor(props: MainI['props']) {
        super(props);
        this.state = {
            currentLimit: 'month',
        };

        this.parent = React.createRef();
    }

    save = save;

    renderVerification = renderVerification;
    renderGroupCards = renderGroupCards;
    renderTariff = renderTariff;
    renderLimits = renderLimits;

    render() {
        const { model, error, user } = this.state;
        const { inner } = this.props;
        const isAdmin = user?.idOfCurrentCorporation === 'admin';
        const isAdminSettings = isAdmin && !inner;
        const isMarginCard =
            (!inner ||
                !getPermissions(user, {
                    key: 'corporations',
                    items: [
                        {
                            key: 'main',
                            rules: ['update'],
                        },
                    ],
                })) &&
            !model?.isAdmin;

        return (
            <>
                <WidgetPage
                    name="corporation"
                    save={this.save.bind(this)}
                    disabled={!this.checkChange().isChange}
                    error={error}
                    className={isMarginCard ? '_marginCard' : ''}
                >
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__card _bottom _group">
                                    {this.renderGroupCards()}
                                </div>
                                {!isAdminSettings && (
                                    <>
                                        <div className="v2widgetPage__card _bottom _tariff">
                                            {this.renderTariff()}
                                        </div>
                                        <div className="v2widgetPage__card _bottom _limit">
                                            {this.renderLimits()}
                                        </div>
                                        <div className="v2widgetPage__card _bottom _verification">
                                            {this.renderVerification()}
                                        </div>
                                    </>
                                )}
                                <div className="v2widgetPage__card _bottom _main">
                                    <WidgetCard
                                        model={model}
                                        name="corporationMain"
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Main);
