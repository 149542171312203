import { FilterQueryT } from '@global/types.ts';

import I from '../types.ts';

const getQuery: I['getQuery'] = function (this: I) {
    const { paySignPopup } = this.props;
    const { groupId, items = [], isSign, filterQuery } = paySignPopup;
    const query: FilterQueryT[] = [];

    query.push({ key: 'notCompleted', value: true });
    query.push({ key: 'withGroup', value: true });
    query.push({ key: 'groupId', value: groupId || 'main' });
    query.push({ key: 'beforePay', value: true });

    if (items[0] !== 'all') {
        query.push({ key: 'isSign', value: !!isSign });
        query.push(...items.map((id) => ({ key: 'listIds', value: id })));
    }

    if (filterQuery) {
        query.push(
            ...filterQuery.filter((item) =>
                ['searchForNumber', 'searchForName', 'searchForPhone', 'searchForAmount'].includes(
                    item.key!,
                ),
            ),
        );
    }

    return query;
};

export default getQuery;
