import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import ListPopup from './ListPopup.jsx';

class ListPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { listPopup } = this.props;
        const { currentName } = listPopup;

        return (
            <Animate className="body__listPopup" isShow={!!currentName}>
                <AnimateChangeUp
                    className="body__listInner"
                    renderKey={currentName}
                    parentStyles={[]}
                    isNotParams={true}
                >
                    <ListPopup {...listPopup} />
                </AnimateChangeUp>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        listPopup: state.listPopup,
    };
}

export default connect(mapStateToProps)(ListPopupWrapper);

ListPopupWrapper.propTypes = {
    listPopup: PropTypes.object,
};
