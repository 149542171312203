import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import ExecutorTaxT from '@global/models/ExecutorTax.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT, TableBlockCorporationT } from '../../types.ts';

const renderExecutorsTaxes: RenderColsT<'executorsTaxes'> = function ({
    name: colName,
    item,
    corporationIndex,
    ...props
}) {
    const executorTax = item as ExecutorTaxT;

    const corporation = props.corporation as TableBlockCorporationT<{
        amount: number;
        prevAmount: number;
        debt: number;
    }>;
    let content;
    let className;

    if (corporationIndex === 0) {
        if (colName === 'name') {
            className = '_visible';

            content = (
                <>
                    <Link
                        pageName="manual-executors-inner-main"
                        ids={{ 3: executorTax._id }}
                        className="v2table__text _link _full"
                        isStopPropagation={true}
                    >
                        {executorTax.fullName || '–'}
                    </Link>
                </>
            );
        }

        if (colName === 'status') {
            let status;

            if (executorTax.fnsTax === 'active') {
                status = '_success';
            } else if (executorTax.fnsTax === 'inactive') {
                status = '_alert';
            } else if (executorTax.fnsTax === 'inactive-process') {
                status = '_alert';
            } else if (executorTax.fnsTax === 'withdraw-process') {
                status = '_medium';
            } else {
                status = '_wait';
            }

            const supportProps = setCursorSupport(
                {
                    _id: `status-${executorTax._id}`,
                    content: getFormatedDate({
                        date: new Date(executorTax.fnsTaxDate),
                        type: 'full',
                    }),
                    dir: 'top',
                },
                { targetClassName: '.v2table__statusInfo', className: '' },
            );

            content = (
                <div className={`v2table__status ${status}`}>
                    {executorTax.statusText}
                    {executorTax.fnsTaxDate && (
                        <i className="v2table__statusInfo _click" {...supportProps}>
                            <Icon name="info" />
                        </i>
                    )}
                </div>
            );
        }
    }

    if (colName === 'amount') {
        content = <>{getFormatPrice(corporation.amount || 0)} ₽</>;
    }

    if (colName === 'monthAmount') {
        content = <>{getFormatPrice(corporation.prevAmount || 0)} ₽</>;
    }

    if (colName === 'debtAmount') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            executorTax: ExecutorTaxT;
        }) => CursorActionsItemT[];

        className = '_withMore';
        content = (
            <>
                {corporationIndex === 0 && (
                    <More
                        id={`executorTax-${executorTax._id}`}
                        items={getMoreActions({ executorTax })}
                        stopPropagation={true}
                    />
                )}
                {getFormatPrice(corporation.debt || 0)} ₽
            </>
        );
    }

    return { content, className };
};

export default renderExecutorsTaxes;
