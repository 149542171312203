import React from 'react';
import PropTypes from 'prop-types';

class InfoHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { title, children, className = '', classNameForName = '' } = this.props;

        return (
            <div className={`widget__headInfo _row ${className}`}>
                <div className={`widget__headName _row ${classNameForName}`}>
                    {title}:<div className="widget__headNameItem">{children}</div>
                </div>
            </div>
        );
    }
}

export default InfoHead;

InfoHead.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    classNameForName: PropTypes.string,
};
