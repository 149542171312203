import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getPage from '../functions/getPage';

import ListAbsoluteMain from './ListAbsoluteMain.jsx';
import removeTransition from '../functions/removeTransition.ts';

class Pages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.getPagesOrder = this.getPagesOrder.bind(this);
        this.renderPage = this.renderPage.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    getPagesOrder(isAll = false) {
        const { storePages, filter, pages } = this.props;
        const currentPagesKeys = Object.keys(storePages).filter(
            (pageKey) =>
                pages[pageKey] &&
                (isAll || storePages[pageKey].isShow) &&
                filter(getPage({ name: pageKey })),
        );

        return currentPagesKeys.map((pageKey) => getPage({ name: pageKey }));
    }

    renderPage({ prop: name }) {
        const { currentPageName } = this.state;
        const {
            classNamePage = '',
            pages,
            context,
            windowHeight,
            fullHeight = false,
            getClassName = () => '',
        } = this.props;
        const allPages = this.getPagesOrder(true);
        const contextPage = pages[name];
        const indexCurrentPage = allPages.findIndex((page) => page.name === currentPageName);
        const indexPage = allPages.findIndex((page) => page.name === name);
        const page = getPage({ name });

        let state = '';

        if (indexPage > indexCurrentPage) {
            state = '_next';
        }

        if (indexPage < indexCurrentPage) {
            state = '_prev';
        }

        return (
            <div
                className={`${classNamePage} _${name} ${page?.className || ''} ${
                    page?.isPopup ? '_popup' : ''
                } ${state} ${getClassName(name)}`}
                style={fullHeight ? { height: `${windowHeight}px` } : {}}
            >
                {contextPage?.render.call(context)}
            </div>
        );
    }

    checkChangePage() {
        const { storePages } = this.props;
        const currentPage = this.getPagesOrder(true).find((page) => storePages[page.name].isShow);

        if (currentPage && currentPage.name !== this.state.currentPageName) {
            setTimeout(() => {
                this.setState({ currentPageName: currentPage.name });
            }, 10);
        }
    }

    changePage({ detail: { changeIsHard } }) {
        const { classNamePage } = this.props;

        if (changeIsHard && classNamePage) {
            removeTransition({ item: `.${classNamePage}`, isCurrent: true });
        }
    }

    componentDidMount() {
        this.checkChangePage();

        this.setState({ classId: `pages-${new Date().getTime()}` });

        document.addEventListener('changePage', this.changePage);
    }

    componentDidUpdate() {
        this.checkChangePage();
    }

    componentWillUnmount() {
        document.removeEventListener('changePage', this.changePage);
    }

    render() {
        const { isInit, classId } = this.state;
        const {
            className = '',
            classNamePage = '',
            callback,
            isNotParams = true,
            keyRender,
            name,
        } = this.props;

        return (
            <>
                <ListAbsoluteMain
                    className={className}
                    items={this.getPagesOrder()}
                    renderItem={this.renderPage}
                    classNameItem={`${classNamePage} ${classId}`}
                    prop="name"
                    isNotParams={isNotParams}
                    isNotParamsItem={true}
                    isShowForce={!isInit}
                    styles={['height']}
                    callback={() => {
                        if (!this.state.isInit) {
                            this.setState({ isInit: true });
                        }

                        if (callback) {
                            callback();
                        }
                    }}
                    resizeParent={document.querySelector('.body__content')}
                    keyRender={keyRender}
                    name={name}
                    paramsParent={{ width: true }}
                    isSmoothShow={true}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        storePages: state.pages,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(Pages);

Pages.propTypes = {
    storePages: PropTypes.object,
    pages: PropTypes.object,
    filter: PropTypes.func,
    className: PropTypes.string,
    classNamePage: PropTypes.string,
    context: PropTypes.object,
    windowHeight: PropTypes.number,
    fullHeight: PropTypes.bool,
    callback: PropTypes.func,
    isNotParams: PropTypes.bool,
    keyRender: PropTypes.any,
    name: PropTypes.string,
    getClassName: PropTypes.func,
};
