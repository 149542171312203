import React from 'react';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import Table from '@components/crm/table/Table.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { counter = 0, currents = [] } = this.state;

    return (
        <>
            <Widget
                name="popupChatTemplates"
                title="Выбрать шаблон для отправки"
                descriptionInner={counter}
                counter={counter}
                actions={this.getActions()}
                updateFilter={(this as I).updateFilter}
                searchItems={(this as I).tableSearchItems}
                className="_white"
                withBack={false}
                filterQuery={(this as I).getFilterQuery?.()}
            >
                <Table
                    name="popupChatTemplates"
                    isNotLink={true}
                    isInit={true}
                    init={this.tableInit!.bind(this)}
                    callback={this.tableCallback!.bind(this)}
                    maxHeight={500}
                    minHeight={500}
                    currents={currents}
                    currentsHandler={this.currentsHandler.bind(this)}
                    currentsLabel={true}
                    notSaveFilter={true}
                />
            </Widget>
            {this.renderFoot()}
        </>
    );
};

export default renderContent;
