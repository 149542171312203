import handlerPopup from '@functions/handlerPopup.ts';

import AlertI from '../types.ts';

const close: AlertI['close'] = function () {
    handlerPopup('alertPopup', {
        isShow: false,
        type: null,
        callback: null,
        denied: null,
        info: null,
        important: null,
    });
};

export default close;
