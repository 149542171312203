import React from 'react';

import Search from '@components/search/Search.tsx';

import I from '../../types.ts';

const renderSearch: I['renderHeadAction'] = function ({ item }) {
    const { searchs } = this.state;
    const { isInit = true, handlerDrop } = this.props;
    const name = item.filterProp || item.name;

    return (
        <div className={`v2widget__headSearch ${isInit ? '_init' : ''}`}>
            <div className="v2widget__headSearchInner">
                <Search
                    support={item.content}
                    onChange={async ({ value }) => {
                        await this.searchHandler({ name, value });
                    }}
                    value={searchs[name] || ''}
                    setFocus={(focus) => {
                        if (focus && handlerDrop) {
                            handlerDrop(true);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default renderSearch;
