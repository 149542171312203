import getPage from '@functions/getPage.ts';
import { StoreT } from '@global/types.ts';

import PagesI from '../types.ts';

const getPagesOrder: PagesI['getPagesOrder'] = function (isAll = false) {
    const { storePages, filter, pages } = this.props;

    const currentPagesKeys = (Object.keys(storePages) as (keyof StoreT['pages'])[]).filter(
        (pageKey) =>
            pages[pageKey] &&
            (isAll || storePages[pageKey].isShow) &&
            filter(getPage({ name: pageKey })),
    );

    return currentPagesKeys.map((pageKey) => getPage({ name: pageKey }));
};

export default getPagesOrder;
