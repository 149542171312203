import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Icon from '@components/icon/Icon.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { currentBlock, counter } = this.state;
    const { contractSignPopup } = this.props;
    const { type } = contractSignPopup;

    return (
        <>
            <div className="v2popup__close _top _CLICK" onClick={this.close.bind(this)}>
                <Icon name="popup-close" />
            </div>
            <div className="v2popup__content _padding">
                <div className="v2popup__title">
                    Подписание {type === 'userProxy' ? 'доверенностей' : 'договоров'}:{' '}
                    <AnimateChange
                        className="v2popup__titleInner"
                        renderKey={counter}
                        styles={['width']}
                    >
                        <>{counter}</>
                    </AnimateChange>
                </div>
                {this.renderBlocks()}
                <div className="v2popupSign__actions _ROW">
                    <div className="v2popupSign__actionsDots _ROW">
                        {this.blocks.map((block) => (
                            <div
                                className={`v2popupSign__actionsDot ${block === currentBlock ? '_current' : ''}`}
                                key={block}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderContent;
