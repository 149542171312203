import I from '../types.ts';

const setSize: I['setSize'] = function () {
    const image = this.getImage()!;
    const imageParent = this.parent.current as HTMLElement;

    const { offsetWidth: parentWidth, offsetHeight: parentHeight } = imageParent;
    let width = image.offsetWidth;
    let height = image.offsetHeight;

    const widthKey = parentWidth / width;

    width *= widthKey;
    height *= widthKey;

    if (height > parentHeight) {
        const heightKey = parentHeight / height;

        width *= heightKey;
        height *= heightKey;
    }

    image.style.width = `${width}px`;
    image.style.height = `${height}px`;

    this.setState({
        width,
        height,
        imageTop: image.offsetTop,
        imageLeft: image.offsetLeft,
    });
};

export default setSize;
