import React from 'react';

import WidgetVerificationCard from '@components/crm/widgetVerificationCard/WidgetVerificationCard.tsx';
import getFormatedDate from '@functions/getFormatedDate.ts';

import I from '../types.ts';

const renderVerificationCard: I['renderVerificationCard'] = function () {
    const model = this.state.model!;

    const title =
        model.verification.status === 'active' ? 'Верификация пройдена' : 'Верификация не пройдена';
    let description = '';

    if (model.verification.status === 'active') {
        description = getFormatedDate({
            date: new Date(model.verification.date!),
            type: 'full',
        });
    } else if (model.verification.status === 'wait') {
        description = 'Компания на проверке';
    } else {
        description = 'На доработке';
    }

    return (
        <>
            <WidgetVerificationCard
                title={title}
                description={description}
                status={model.verification.status}
            />
        </>
    );
};

export default renderVerificationCard;
