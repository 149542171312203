import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import NewVersionPopup from './NewVersionPopup.jsx';

class NewVersionPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { newVersionShow } = this.props;

        return (
            <Animate className="body__bonusPopup" isShow={newVersionShow}>
                <NewVersionPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        newVersionShow: state.newVersionShow,
    };
}

export default connect(mapStateToProps)(NewVersionPopupWrapper);

NewVersionPopupWrapper.propTypes = {
    newVersionShow: PropTypes.bool,
};
