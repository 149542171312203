import getVariables from './getVariables';

export default function setVariables() {
    const variables = getVariables();

    Object.keys(variables).forEach((key) => {
        let value = variables[key].value;

        if (variables[key].type === 'px') {
            value += 'px';
        }

        document.body.style.setProperty(`--${key}`, value);
    });
}
