import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Header from '../../../components/crm/public/executors/Header.jsx';
import About from '../../../components/crm/public/executors/About.jsx';
import AppInfo from '../../../components/crm/public/executors/AppInfo.jsx';
import Faq from '../../../components/crm/public/executors/Faq.jsx';
import FnsInfo from '../../../components/crm/public/executors/FnsInfo.jsx';
import Top from '../../../components/crm/public/Top.jsx';

class PublicExecutors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <div className="public__pageInner _col">
                <div className="public__section">
                    <Header />
                </div>
                <div className="public__section">
                    <About />
                </div>
                <div className="public__section">
                    <AppInfo />
                </div>
                <div className="public__section">
                    <Faq />
                </div>
                <div className="public__section">
                    <FnsInfo />
                </div>
                <div className="public__section _footer">
                    <Top pageName="executors" isFooter={true} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(PublicExecutors);

PublicExecutors.propTypes = {
    windowIsReady: PropTypes.bool,
};
