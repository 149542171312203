const fields = require('../infos/fields.json');
const passwordsRules = require('../infos/passwordsRules.json');

const regs = {
    numberWithSpace: /[^0-9 ]/g,
    number: /[^0-9]/g,
    numberAndSpace: /[^0-9 ]/g,
    numberFloat: /[^0-9,]/g,
    percent: /[^0-9.]/g,
    numberFloatWithSign: /[^0-9,-]/g,
    numberWithSign: /[^0-9-+]/g,
    numberAndEng: /[^0-9a-z]/gi,
    email: /[^0-9A-z@.\-_]/g,
    russianText: /[^а-яё -]/gi,
    russianTextNoSpace: /[^а-яё]/gi,
    englishText: /[^a-z ]/gi,
    vin: /[^0-9A-HJ-NPR-Z]/gi,
    // website: /^(ftp|http|https):\/\/[^ "]+$/,
    russianTextWithSymbols: /[^а-яё ,./\-0-9\\(\\)№]/gi,
    numberAndText: /[^0-9a-zа-яё ]/gi,
    numberCar: /[^0-9a-zа-яё]/gi,
    telegram: /[^0-9a-z_]/gi,
    joinLink: /[^0-9a-z-_]/gi,
    colorHex: /[^0-9abcdef]/gi,
};

const minLens = {
    comments: 20,
};

const checkValidate = ({ value = '', type, reg }) => {
    let flag = true;

    switch (type) {
        case 'name':
            if (!value || value.replace(regs.russianText, '') !== value) {
                flag = false;
            }
            break;
        case 'fullname':
            if (
                value.trim().split(' ').length !== 3 ||
                /^[а-яА-ЯёЁ ]*[а-яА-ЯёЁ]$/i.test(value) === false
            ) {
                flag = false;
            }
            break;
        case 'phone':
            if (/^[\d()\- +]{10,17}\d$/.test(value) !== true) {
                flag = false;
            }
            break;
        case 'email':
            if (/^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i.test(value) !== true) {
                flag = false;
            }
            break;
        case 'password':
            if (/^(?=.*[!@#$%^&*_-])[\w!@#$%^&*_-]{8,}$/i.test(value) !== true) {
                flag = false;
            }
            break;
        case 'inn':
            if (value.length !== 10 && value.length !== 12) {
                flag = false;
            }
            break;
        case 'bic':
            if (value.length !== 9) {
                flag = false;
            }
            break;
        case 'kpp':
            if (value.length !== 9) {
                flag = false;
            }
            break;
        case 'corrAccount':
            if (value.length !== 20) {
                flag = false;
            }
            break;
        case 'bankAcсount':
            if (value.length !== 20) {
                flag = false;
            }
            break;
        case 'comments':
            if (value.trim().length < minLens.comments) {
                flag = false;
            }
            break;
        case 'website':
            if (
                /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
                    value,
                ) !== true
            ) {
                flag = false;
            }
            break;
        case 'time':
            if (!value.match(/\d\d:\d\d/)) {
                flag = false;
            }
            break;
        case 'numberCard':
            if (
                value.replace(/[^\d]/gi, '').length !== 16 &&
                value.replace(/[^\d]/gi, '').length !== 19
            ) {
                flag = false;
            }
            break;
        case 'dateCard':
            if (!value.match(/\d\d\/\d\d/)) {
                flag = false;
            }
            break;
        case 'date':
            if (!value.match(/\d{2}.\d{2}.\d{4}/)) {
                flag = false;
            }
            break;
        default:
            break;
    }

    if (reg && regs[reg]) {
        flag = value.replace(regs[reg], '').length > 0;
    }

    return flag;
};

const inputValidate = ({ name, value = '', isRequire = true }) => {
    const currentField = fields[name];

    if (currentField && (isRequire || value?.length)) {
        const { validate, reg } = currentField;

        if (validate) {
            return checkValidate({ value, type: validate });
        }

        if (reg) {
            return checkValidate({ value, reg });
        }

        if (isRequire) {
            return value.length !== 0;
        }

        return true;
    }

    if (isRequire) {
        return value?.length !== 0;
    }

    return true;
};

const getTextError = ({ name, error }) => {
    const currentField = fields[name];

    if (error === 'passwordsNotEqual') {
        return 'Пароли должны совпадать';
    }

    if (error === 'userAlreadyRegister') {
        return 'Пользователь уже существует';
    }

    if (error === 'userNotFound') {
        return 'Пользователь не найден';
    }

    if (error === 'passwordError') {
        return 'Неверный пароль';
    }

    if (currentField) {
        const { validate } = currentField;

        if (validate === 'name') {
            return 'Поле должно содержать не менее 3-х символов';
        }

        if (validate === 'phone') {
            return 'Телефон введён неверно';
        }

        if (validate === 'email') {
            return 'Email введён неверно';
        }

        if (validate === 'password') {
            return passwordsRules.content.replace(/<br\/>/g, ' ');
        }

        return 'Поле заполнено неверно';
    }

    return 'Поле заполнено неверно';
};

export { regs, minLens, inputValidate, checkValidate, getTextError };
