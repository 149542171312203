import PayProjectT from '@global/models/PayProject';

const payProjects = {
    title: 'Данные',
    description: (model: PayProjectT) => model?.name,
    fieldsOrder: ['name', 'percent', 'minimal', 'over'],
    fields: {
        name: {
            support: 'Название',
            type: 'input',
            group: '1',
            width: 3,
        },
        percent: {
            support: 'Процент',
            type: 'input',
            group: '1',
            width: 2,
            concat: {
                exp: /[^\d.]/gi,
                text: '%',
                position: 'end',
            },
        },
        minimal: {
            support: 'Мин. сумма',
            type: 'input',
            group: '1',
            width: 2,
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
        },
        over: {
            support: 'Надбавка',
            type: 'input',
            group: '1',
            width: 2,
            concat: {
                exp: /[^\d.]/gi,
                text: '%',
                position: 'end',
            },
        },
    },
} as const;

export default payProjects;
