import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import changePage from '../../../functions/changePage';
import checkCurrentPage from '../../../functions/checkCurrentPage';
import changeCorporation from '../../../functions/crm/changeCorporation';
import checkAdminCorporation from '../../../functions/crm/checkAdminCorporation';
import checkHasCorporation from '../../../functions/crm/checkHasCorporation';
import getCurrentCorporation from '../../../functions/crm/getCurrentCorporation';
import setPermissions from '../../../functions/crm/setPermissions';
import getRealParams from '../../../functions/getRealParams.ts';
import handlerPopup from '../../../functions/handlerPopup';
import handlerWindow from '../../../functions/handlerWindow';
import logout from '../../../functions/logout';
import removeTransition from '../../../functions/removeTransition.ts';
import scrollToPosition from '../../../functions/scrollToPosition';

import Animate from '../../Animate.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import WindowActions from '../../WindowActions.jsx';
import Windows from '../../Windows.jsx';
import CounterNotRead from '../../chat/CounterNotRead.jsx';
import Company from './Company.jsx';
import Nav from './Nav.jsx';
import User from './User.jsx';

const pages = require('../../../redux/pages').default;

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateNavCounter: 0,
        };

        this.handlerStateCompany = this.handlerStateCompany.bind(this);
        this.renderHeadAction = this.renderHeadAction.bind(this);
        this.getOrderHeadActions = this.getOrderHeadActions.bind(this);
        this.sortHeadActions = this.sortHeadActions.bind(this);
        this.setContentHeight = this.setContentHeight.bind(this);
        this.renderWindowsUserActions = this.renderWindowsUserActions.bind(this);
        this.getNavs = this.getNavs.bind(this);
        this.renderNav = this.renderNav.bind(this);
        this.scrollToStartContent = this.scrollToStartContent.bind(this);
        this.setUpdateNavCounter = this.setUpdateNavCounter.bind(this);
        this.filterHeadActionListPage = this.filterHeadActionListPage.bind(this);
        this.changeUser = this.changeUser.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    headActions = {
        content: {
            content: 'Контент',
            href: 'content',
            withList: true,
            icon: 'sideBar-content',
        },
        'content-public': {
            content: 'База знаний',
            href: 'content-infoPublic',
            icon: 'sideBar-content',
        },
        settings: {
            href: 'settings',
            icon: 'sideBar-settings',
        },
        chat: {
            href: 'chat',
            withList: true,
            icon: 'sideBar-chat',
        },
        admin: {
            content: 'Админ панель',
            icon: 'admin',
        },
        corporations: {
            icon: 'arrow-prev-2',
        },
    };

    getOrderHeadActions() {
        const { admin } = this.props;
        const user = this.getUser();
        const checkAdmin = checkAdminCorporation({ user });

        const orderActions = [];

        if (
            this.getPermissions({
                user,
                key: 'settings',
            })
        ) {
            orderActions.push({ key: 'settings' });
        }

        if (
            this.getPermissions({ user, key: 'chat', items: [{ key: 'chats', rules: ['read'] }] })
        ) {
            orderActions.push({ key: 'chat' });
        }

        if (checkAdmin && this.getPermissions({ user, key: 'content' })) {
            orderActions.push({ key: 'content' });
        } else {
            orderActions.push({ key: 'content-public' });
        }

        if (!admin) {
            if (user.corporations.find((item) => item.id === 'admin')) {
                orderActions.push({ key: 'admin' });
            }
        } else if (
            user.corporations.filter((item) => item.id !== 'admin').length &&
            this.getNotAdminCorporation()
        ) {
            orderActions.push({ key: 'corporations' });
        }

        return orderActions;
    }

    filterHeadActionListPage(page) {
        const user = this.getUser();

        if (page.name === 'chat') {
            return this.getPermissions({
                user,
                key: 'chat',
                items: [{ key: 'chats', rules: ['read'] }],
            });
        }

        if (page.name === 'chat-templates') {
            return this.getPermissions({
                user,
                key: 'chat',
                items: [{ key: 'templates', rules: ['read'] }],
            });
        }

        if (page.name === 'mailings') {
            return this.getPermissions({
                user,
                key: 'chat',
                items: [{ key: 'mailing', rules: ['read'] }],
            });
        }

        if (page.name === 'content-info') {
            return this.getPermissions({
                user,
                key: 'content',
                items: [{ key: 'info', rules: ['read'] }],
            });
        }

        if (page.name === 'content-blog') {
            return this.getPermissions({
                user,
                key: 'content',
                items: [{ key: 'blog', rules: ['read'] }],
            });
        }

        if (page.name === 'content-legal') {
            return this.getPermissions({
                user,
                key: 'content',
                items: [{ key: 'legal', rules: ['read'] }],
            });
        }

        if (page.name === 'content-googleVision') {
            return this.getPermissions({
                user,
                key: 'content',
                items: [{ key: 'google', rules: ['read'] }],
            });
        }

        return true;
    }

    renderHeadAction({ prop: name, key: order }) {
        const { notAdminCorporation } = this.state;
        const { setUser, isHide, storePages } = this.props;
        const user = this.getUser();
        const corporation = getCurrentCorporation({ user });
        const action = this.headActions[name];
        const { href, icon, withList } = action;
        let { content } = action;
        let LinkTag = Link;
        const linkProps = withList ? { onClick: () => null } : {};
        let list;
        let counter = 0;

        linkProps.style = {
            zIndex: 10 - order,
        };

        if (name === 'settings') {
            if (corporation?.verificationStatus !== 'active') {
                counter += 1;
            }
        }

        if (name === 'admin') {
            LinkTag = 'div';

            linkProps.onClick = () => {
                changeCorporation({ user, id: 'admin' }).then(() => {
                    setTimeout(
                        () => {
                            setUser();
                        },
                        isHide ? 300 : 0,
                    );
                });
            };
        }

        if (name === 'corporations') {
            content = `Вернуться в ${notAdminCorporation.shortName}`;
            LinkTag = 'div';

            linkProps.onClick = () => {
                changeCorporation({ user, id: notAdminCorporation._id }).then(() => {
                    setTimeout(
                        () => {
                            setUser();
                        },
                        isHide ? 300 : 0,
                    );
                });
            };
        }

        if (withList) {
            if (name === 'content') {
                list = pages.filter(
                    (page) =>
                        page.parentName === 'content' &&
                        page.level === 2 &&
                        page.name !== 'content-infoPublic',
                );
            }

            if (name === 'chat') {
                list = pages.filter((page) =>
                    ['chat', 'chat-templates', 'mailings'].includes(page.name),
                );

                linkProps.isCurrent = ['chat', 'chat-templates', 'mailings'].find((chatName) =>
                    checkCurrentPage({ name: chatName, pages: storePages }),
                );
            }

            list = list.filter(this.filterHeadActionListPage);

            if (
                name === 'content' &&
                !this.getPermissions({
                    key: 'content',
                    items: [{ key: 'info', rules: ['read'] }],
                })
            ) {
                list.push(pages.find((page) => page.name === 'content-infoPublic'));
            }
        }

        return (
            <LinkTag
                className={`sideBar__action _${name}`}
                key={name}
                pageName={href}
                {...linkProps}
            >
                <div className="sideBar__actionInner _row _click">
                    {name === 'chat' && (
                        <div className="sideBar__actionCounter">
                            <CounterNotRead type="all" keyUpdate={user?.idOfCurrentCorporation} />
                        </div>
                    )}
                    {name === 'settings' && (
                        <>
                            <div className="sideBar__actionCounter">
                                <Animate
                                    className="notificationCounter"
                                    isShow={counter > 0}
                                    animateForInit={true}
                                >
                                    <div className="notificationCounter__box _col">
                                        <AnimateChangeUp
                                            className="notificationCounter__inner _col"
                                            renderKey={counter > 0 ? counter : undefined}
                                        >
                                            {`${counter}`}
                                        </AnimateChangeUp>
                                    </div>
                                </Animate>
                            </div>
                        </>
                    )}
                    <i className="sideBar__actionIcon">
                        <Icon name={icon} />
                    </i>
                    {content && <div className="sideBar__actionContent">{content}</div>}
                    {name === 'admin' && (
                        <>
                            <i className="sideBar__actionIcon _nextArrow">
                                <Icon name="arrow-next-2" />
                            </i>
                        </>
                    )}
                </div>
                {list && (
                    <div className="sideBar__actionListWrapper">
                        <div className="sideBar__actionList">
                            {list.map((listItem) => {
                                const listItemProps = { pageName: listItem.name };

                                if (listItem.name === 'chat') {
                                    listItemProps.pageName = 'chat-all';
                                }

                                return (
                                    <Link
                                        className="sideBar__actionListItem"
                                        key={listItem.name}
                                        {...listItemProps}
                                    >
                                        {listItem.contentOfLink}
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                )}
                {name === 'corporations' &&
                    user.corporations.filter((item) => item.id !== 'admin').length > 1 && (
                        <>
                            <div
                                className="sideBar__actionCorporations"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Company
                                    user={user}
                                    isShowCompanyList={true}
                                    handlerState={this.handlerStateCompany}
                                    admin={false}
                                    setUser={setUser}
                                    isList={true}
                                />
                            </div>
                        </>
                    )}
            </LinkTag>
        );
    }

    sortHeadActions(actions = []) {
        const resultActions = JSON.parse(JSON.stringify(actions));
        const actionsKeys = Object.keys(this.headActions);

        return resultActions.sort(
            (a, b) => actionsKeys.indexOf(a.key) - actionsKeys.indexOf(b.key),
        );
    }

    handlerStateCompany(isShow = !this.state.isShowCompanyList) {
        return new Promise((resolve) => {
            this.setState({ isShowCompanyList: isShow }, resolve);
        });
    }

    setUpdateNavCounter() {
        this.setState({ updateNavCounter: this.state.updateNavCounter + 1 });
    }

    getNavs() {
        const user = this.getUser();

        if (user?.idOfCurrentCorporation) {
            return [{ id: user.idOfCurrentCorporation }];
        }

        return [];
    }

    renderNav({ prop: id }) {
        return (
            <div className="sideBar__contentNav">
                <Nav id={id} setUpdateNavCounter={this.setUpdateNavCounter} />
            </div>
        );
    }

    scrollToStartContent() {
        const contentDom = this.parent.current?.querySelector('.sideBar__content');

        if (contentDom) {
            scrollToPosition({ position: 'top', parent: contentDom });
        }
    }

    renderWindowsUserActions() {
        const user = this.getUser();
        const handlerRole = ({ key }) =>
            new Promise((resolve) => {
                if (key === 'exit') {
                    logout();

                    resolve();
                } else if (key === 'profile') {
                    changePage({ href: 'profile' });

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={user?.corporations.length > 0 ? ['profile', 'logout'] : ['logout']}
                callback={handlerRole}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'userActions',
                    });
                }}
            />
        );
    }

    setContentHeight(e) {
        const params = getRealParams({
            parent: this.parent.current,
            classNames: ['_static'],
            elems: [
                { id: 'head', className: '.sideBar__head' },
                { id: 'foot', className: '.sideBar__foot' },
            ],
            width: this.parent.current.offsetWidth,
            height: this.parent.current.offsetHeight,
            clearStyleElems: ['.sideBar__content'],
            // isNotRemove: true,
        });
        const heightContent =
            this.parent.current.offsetHeight - params.head.height - params.foot.height;

        if (heightContent !== this.state.heightContent) {
            if (e || !this.isInit) {
                removeTransition({ item: '.sideBar__content' });

                this.isInit = true;
            }

            this.setState({ heightContent });
        }
    }

    getUser() {
        return this.state.user || this.props.user;
    }

    changeUser() {
        const { admin } = this.props;

        if ((this.props.user?.idOfCurrentCorporation === 'admin') === !!admin) {
            this.setState({ user: JSON.parse(JSON.stringify(this.props.user)) });
        }
    }

    getNotAdminCorporation() {
        const user = this.getUser();
        const corporationId =
            localStorage.getItem('saveNotAdminCorporationId') ||
            user.corporations.filter((item) => item.id !== 'admin')[0]?.id;
        const corporation = user.corporationsInfo.find((item) => item._id === corporationId);

        return this.state.notAdminCorporation || corporation;
    }

    componentDidMount() {
        const corporation = this.getNotAdminCorporation();

        if (corporation) {
            this.setState({ notAdminCorporation: corporation });
        } else {
            localStorage.removeItem('saveNotAdminCorporationId');
        }

        this.setContentHeight();

        setPermissions.call(this);

        document.addEventListener('changeHeightWindow', this.setContentHeight);
        document.addEventListener('changeUser', this.changeUser);
    }

    componentWillUnmount() {
        document.removeEventListener('changeHeightWindow', this.setContentHeight);
        document.removeEventListener('changeUser', this.changeUser);
    }

    render() {
        const { isShowCompanyList, heightContent = 0, updateNavCounter } = this.state;
        const { isHide, handlerHideState, admin, setUser } = this.props;
        const user = this.getUser();

        return (
            <>
                <Windows name="userActions" renderContent={this.renderWindowsUserActions} />
                <div
                    ref={this.parent}
                    className={`sideBar ${
                        !checkHasCorporation({ user }) ? '_waitCorporation' : ''
                    } ${admin ? '_admin' : ''} ${isHide ? '_hideMode' : ''}`}
                >
                    {admin && (
                        <div className="sideBar__admin _row">
                            <div className="sideBar__adminIcon">
                                <Icon name="admin" />
                            </div>
                            АДМИН ПАНЕЛЬ
                        </div>
                    )}
                    <Animate
                        className="sideBar__start _col"
                        isShow={!checkHasCorporation({ user })}
                    >
                        <div className="sideBar__startCompany _col">
                            <div className="sideBar__startCompanyIcon _col">
                                <i className="sideBar__startCompanyIconInner">
                                    <Icon name="plus" />
                                </i>
                            </div>
                            <div className="sideBar__startCompanyTitle">
                                Создайте первую компанию
                            </div>
                            <p className="sideBar__startCompanyDescription">
                                Чтобы начать пользоваться CRM-системой — создайте первую компанию
                            </p>
                            <div className="sideBar__startCompanyButton">
                                <Button
                                    className="_medium2Size _mainNotBorder _notPadding"
                                    onClick={() => {
                                        handlerPopup('corporationCreatePopup', {
                                            isShow: true,
                                        });
                                    }}
                                >
                                    Создать компанию
                                </Button>
                            </div>
                        </div>
                        <div className="sideBar__startUser">
                            <User parent={this.parent.current} />
                        </div>
                    </Animate>
                    <div className="sideBar__inner">
                        <div className="sideBar__head">
                            {isHide && (
                                <div className="sideBar__headCloseWrapper">
                                    <div
                                        className="sideBar__headClose _col _click"
                                        onClick={() => {
                                            handlerHideState(false);
                                        }}
                                    >
                                        <i className="sideBar__headCloseIcon">
                                            <Icon name="close" />
                                        </i>
                                    </div>
                                </div>
                            )}
                            <div className="sideBar__company">
                                <Company
                                    user={user}
                                    isShowCompanyList={isShowCompanyList}
                                    handlerState={this.handlerStateCompany}
                                    setContentHeight={this.setContentHeight}
                                    admin={admin}
                                    setUser={setUser}
                                />
                            </div>

                            <ListAbsoluteMain
                                className={`sideBar__actions ${isShowCompanyList ? '_hide' : ''}`}
                                items={this.getOrderHeadActions()}
                                renderItem={this.renderHeadAction}
                                classNameItem="sideBar__action"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                keyRender={user?.idOfCurrentCorporation}
                                callback={() => {
                                    this.setContentHeight();
                                }}
                                parentWidthOffset={14}
                            />
                        </div>
                        <div className="sideBar__content" style={{ height: heightContent }}>
                            <ListAbsoluteMain
                                className="sideBar__contentNavs"
                                items={this.getNavs()}
                                renderItem={this.renderNav}
                                classNameItem="sideBar__contentNav"
                                prop="id"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                isNotParamsItem={true}
                                callback={({ isChangeLen }) => {
                                    if (isChangeLen) {
                                        this.scrollToStartContent();
                                    }
                                }}
                                keyRender={updateNavCounter}
                            />
                        </div>
                        <div className="sideBar__foot _col">
                            <div className="sideBar__user">
                                <User parent={this.parent.current} />
                            </div>
                            <Link className="sideBar__legal" pageName="public-legal">
                                Правовая информация
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        storePages: state.pages,
    };
}

export default connect(mapStateToProps)(SideBar);

SideBar.propTypes = {
    user: PropTypes.object,
    isHide: PropTypes.bool,
    handlerHideState: PropTypes.func,
    storePages: PropTypes.object,
    admin: PropTypes.bool,
    setUser: PropTypes.func,
};
