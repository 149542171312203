import React from 'react';

import Icon from '@components/icon/Icon';
import { setCursorActions } from '@functions/actionsHandler';
import { CursorActionsItemT } from '@global/types';

type ParamsT = {
    id: string;
    items: CursorActionsItemT[];
    stopPropagation?: boolean;
    className?: string;
};

export default function More({ id, items, className = '', stopPropagation }: ParamsT): React.ReactNode {
    const props = setCursorActions(
        { _id: id, dir: 'left', items },
        { targetClassName: '.v2table__more', stopPropagation },
    );

    return items.length > 0 ? (
        <div className={`v2table__more _CLICK ${className}`} {...props}>
            <Icon name="more-vertical" />
        </div>
    ) : null;
}
