import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import checkHolder from '@functions/crm/checkHolder.js';
import getCurrentCorporation from '@functions/crm/getCurrentCorporation.ts';
import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import ContractsI from '../types.ts';

const getActions: ContractsI['getActions'] = function (this: ContractsI) {
    const { user, waitedCounter = 0, currents = [], items = [] } = this.state;
    const { type, executorId } = this.props;
    const actions = [] as WidgetActionsT[];

    if (
        getPermissions(user, {
            notAdminCorporation: true,
            key: 'joins',
            items: [
                {
                    key: 'contracts',
                    rules: ['sign'],
                },
            ],
        }) ||
        type === 'userProxy'
    ) {
        let condForSign = currents.every(({ id }) =>
            (['wait', 'executorSign'] as any[]).includes(
                items.find((innerItem) => innerItem._id === id)?.status,
            ),
        );

        if (type === 'userProxy' && getCurrentCorporation({ user })?.role !== 'holder') {
            condForSign = currents.every(({ id }) =>
                (['wait', 'corporationSign'] as any[]).includes(
                    items.find((innerItem) => innerItem._id === id)?.status,
                ),
            );
        }

        const links = [
            {
                type: 'link',
                name: 'sign',
                group: 'links',
                content: 'Подписать',
                className: '_main',
                disabled: currents.length === 0 || !condForSign,
                onClick: this.setSign.bind(this, {}),
            },
            {
                type: 'link',
                name: 'signAll',
                group: 'links',
                content: 'Подписать все',
                className: '_main',
                disabled: waitedCounter === 0,
                onClick: this.setSign.bind(this, { isAll: true }),
            },
        ] as WidgetActionsT[];

        actions.push(...links);
    }

    if (
        type === 'userProxy' &&
        checkHolder({ user }) &&
        user?.idOfCurrentCorporation !== 'admin' &&
        executorId !== user?._id
    ) {
        actions.push({
            type: 'link',
            name: 'crete',
            group: 'links',
            content: 'Создать',
            className: '_main',
            onClick: async () => {
                handlerPopup('joinContractPopup', {
                    isShow: true,
                    type,
                    successCallback: () => {
                        this.tableUpdateItems!();
                    },
                });
            },
        });
    }

    if (!type && 0) {
        actions.push({
            type: 'export',
            name: 'export',
            group: 'buttons',
            exportName: 'contracts',
        });
    }

    actions.push(
        ...([
            {
                type: 'filter',
                name: 'filter',
                group: 'buttons',
                filterName: 'joinsContracts',
            },
        ] as WidgetActionsT[]),
    );

    return actions;
};

export default getActions;
