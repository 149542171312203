import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Input from '@components/input/Input.tsx';

import SearchI from './types.ts';

class Search extends React.Component<SearchI['props']> implements SearchI {
    constructor(props: SearchI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { value, onChange, support, className = '', setFocus } = this.props;

        return (
            <label className={`v2search ${className}`}>
                <i className="v2search__icon">
                    <Icon name="search" />
                </i>
                <div className="v2search__field">
                    <Input
                        value={value}
                        onChange={onChange}
                        name="search"
                        support={support}
                        withoutLabel={true}
                        setFocus={setFocus}
                    />
                </div>
            </label>
        );
    }
}

export default Search;
