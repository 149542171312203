import { PagesT, StoreT } from '@global/types';
import pages, { PageNamesT } from '@redux/pages';
import { store } from '@redux/redux';

export default function getCurrentPage({
    storePages = store.getState().pages,
    filter,
}: {
    storePages?: StoreT['pages'];
    filter: (page: PagesT) => boolean;
}): PageNamesT['default'] | undefined {
    return pages
        .filter((page) => filter(page as PagesT))
        .find((page) => storePages[page.name]?.isShow)?.name;
}
