import React from 'react';

import ExecutorOrg from '@components/crm/ExecutorOrg.jsx';
import getFormatedDate from '@functions/getFormatedDate.ts';
import { ExecutorT } from '@global/models/Executor.ts';

import { RenderColsT } from '../../types.ts';

const renderListExecutors: RenderColsT<'listExecutors'> = function ({ name: colName, item }) {
    let content;
    let className;

    const executor = item as Pick<
        ExecutorT,
        '_id' | 'firstName' | 'secondName' | 'thirdName' | 'fullName' | 'phone' | 'organization'
    > & {
        contract?: {
            number: number;
            date: string;
        };
    };

    if (colName === 'name') {
        content = (
            <>
                {executor.fullName && <div className="v2table__text">{executor.fullName}, </div>}{' '}
                <div className="v2table__text _grey _NOWRAP">{executor.phone}</div>
                {executor.contract && (
                    <div className="v2table__text">
                        , договор №{executor.contract.number} от{' '}
                        {getFormatedDate({ date: new Date(executor.contract.date) })}
                    </div>
                )}{' '}
                <div className="v2table__executorOrg">
                    <ExecutorOrg name={executor.organization} />
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderListExecutors;
