import getRealParams from '@functions/getRealParams.ts';

import I from '../types.ts';

const setHeightDocs: I['setHeightDocs'] = function () {
    const parent = this.parent.current as HTMLElement;
    const inner = parent?.querySelector('.v2widgetJoinScript__inner') as HTMLElement;

    if (inner) {
        const { height: footHeight } = getRealParams({
            parent,
            elem: '.v2widgetJoinScript__foot',
            width: parent.offsetWidth,
            clearStyleElems: ['.v2widgetJoinScript__foot'],
        });

        this.setState({ docsHeight: inner.offsetHeight - (footHeight as number) - 16 });
    }
};

export default setHeightDocs;
