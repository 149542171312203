import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import ExportPopup from './ExportPopup.jsx';

class ExportPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { exportPopup } = this.props;

        return (
            <Animate className="body__exportPopup" isShow={exportPopup.isShow}>
                <ExportPopup {...exportPopup} />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        exportPopup: state.exportPopup,
    };
}

export default connect(mapStateToProps)(ExportPopupWrapper);

ExportPopupWrapper.propTypes = {
    exportPopup: PropTypes.bool,
};
