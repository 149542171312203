import React from 'react';
import PropTypes from 'prop-types';
import getFormatedDate from '../../functions/getFormatedDate';

class Pagenation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { pageCurrent, pageCounter, contractId, docDate } = this.props;

        return (
            <div className="pdf__pagenationInner _row">
                <div className="pdf__pagenationContent _col">
                    <div className="pdf__pagenationDescription">
                        Передан через LIVECARGO CRM{' '}
                        {getFormatedDate({
                            date: new Date(docDate),
                            // type: 'full',
                            withTimezone: true,
                        })}
                    </div>
                    <div className="pdf__pagenationDescription">{contractId}</div>
                    <div className="pdf__pagenationDescription _bold">
                        Страница {pageCurrent + 1} из {pageCounter}
                    </div>
                </div>
                <div className="pdf__pagenationLogo _col">
                    <img
                        src={require('../../img/crm/pdf/logo-short.svg').default}
                        alt=""
                        className="pdf__pagenationLogoIcon"
                    />
                </div>
            </div>
        );
    }
}

export default Pagenation;

Pagenation.propTypes = {
    pageCurrent: PropTypes.number,
    pageCounter: PropTypes.number,
    contractId: PropTypes.string,
    docDate: PropTypes.string,
};
