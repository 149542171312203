import getCurrentCorporation from './getCurrentCorporation';

export default function checkHolder({ user }) {
    return (
        getCurrentCorporation({
            user,
            isInfo: false,
        })?.role === 'holder'
    );
}
