import heic2any from 'heic2any';

import setNotification from '../functions/setNotification';

const typesOfFiles = require('../infos/typesOfFiles.json');

export default class File {
    constructor({ typeOfFiles = 'image', input, checkDisabledDrag = () => false }) {
        this.typeOfFiles = typeOfFiles;
        this.input = input;
        this.checkDisabledDrag = checkDisabledDrag;
    }

    getSize(size) {
        if (size < 1024) {
            return `${size} Б`;
        }

        if (size < 1024 * 1024) {
            return `${+(size / 1024).toFixed(1)} Кб`;
        }

        if (size < 1024 * 1024 * 1024) {
            return `${+(size / (1024 * 1024)).toFixed(1)} Мб`;
        }

        return `${+(size / (1024 * 1024 * 1024)).toFixed(1)} Гб`;
    }

    convertHEIC(file) {
        return new Promise((resolve) => {
            if (file.type !== 'image/heic') {
                resolve({});
            } else {
                const blobURL = URL.createObjectURL(file);

                fetch(blobURL).then(
                    (blobRes) => {
                        blobRes.blob().then(
                            (blob) => {
                                heic2any({ blob }).then(
                                    (conversionResult) => {
                                        const url = URL.createObjectURL(conversionResult);

                                        resolve({ result: conversionResult, url });
                                    },
                                    () => null,
                                );
                            },
                            () => null,
                        );
                    },
                    () => null,
                );
            }
        });
    }

    uploadFiles({ target, names = [], formData, getName }) {
        const { files } = target;
        const currentTypes = typesOfFiles[this.typeOfFiles];

        return new Promise((resolve) => {
            const getInfoFiles = [];

            if (
                Object.keys(files).filter((key) => currentTypes.indexOf(files[key].type) === -1)
                .length
            ) {
                setNotification({ notification: 'error-types-file' });
            } else if (Object.keys(files).find((key) => files[key].size > Math.pow(1024, 2) * 20)) {
                setNotification({ notification: 'error-size-file' });
            } else {
                Object.keys(files)
                    .filter((key) => currentTypes.indexOf(files[key].type) !== -1)
                    .forEach((key) => {
                        const file = files[key];
                        const fr = new FileReader();
                        const type = file.type;

                        getInfoFiles.push(
                            new Promise((resolveInfo) => {
                                fr.onload = (response) => {
                                    this.convertHEIC(file).then(
                                        ({ result: heicResult, url: heicUrl }) => {
                                            const { result: path } = response.target;
                                            const { name, size } = file;
                                            const keyName = getName ?
                                                getName(key) :
                                                names[key] || `file-${key}`;

                                            let heicFile;

                                            if (heicResult) {
                                                heicFile = heicResult;
                                                heicFile.name = file.name;
                                            }

                                            if (formData) {
                                                formData.set(keyName, heicFile || file);
                                            }

                                            if (typesOfFiles.image.includes(type)) {
                                                const image = new Image();

                                                image.onload = () => {
                                                    if (formData) {
                                                        formData.set(
                                                            `width-${keyName}`,
                                                            image.width,
                                                        );
                                                        formData.set(
                                                            `height-${keyName}`,
                                                            image.height,
                                                        );
                                                    }

                                                    resolveInfo({
                                                        path,
                                                        name,
                                                        size,
                                                        key: keyName,
                                                        type,
                                                        object: file,
                                                        isLocal: true,
                                                        dateOfUpload: new Date(),
                                                        width: image.width,
                                                        height: image.height,
                                                    });
                                                };

                                                image.src = heicUrl || path;
                                            } else {
                                                resolveInfo({
                                                    path,
                                                    name,
                                                    size,
                                                    key: keyName,
                                                    type,
                                                    object: file,
                                                    isLocal: true,
                                                    dateOfUpload: new Date(),
                                                });
                                            }
                                        },
                                        () => null,
                                    );
                                };
                            }),
                        );

                        fr.readAsDataURL(file);
                    });

                Promise.all(getInfoFiles).then(
                    (resultFiles) => {
                        target.value = null;

                        if (this.input) {
                            this.input.value = null;
                        }

                        if (getInfoFiles.length) {
                            resolve({ resultFiles });
                        }
                    },
                    () => null,
                );
            }
        });
    }

    getFilePath({ folder, path }) {
        return `${process.env.REACT_APP_STATIC}/${folder}/${path}`;
    }

    counterDrag = 0;

    setDrag({ area, handlerOver, handlerLeave, handlerDrop }) {
        function preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        }

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
            area.addEventListener(eventName, preventDefaults, false);
        });

        const isAdvancedUpload = (() => {
            const div = document.createElement('div');

            return (
                ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
                'FormData' in window &&
                'FileReader' in window
            );
        })();

        if (isAdvancedUpload) {
            area.addEventListener(
                'dragenter',
                () => {
                    if (!this.checkDisabledDrag()) {
                        this.counterDrag++;
                        handlerOver();
                    }
                },
                false,
            );
            area.addEventListener(
                'dragleave',
                () => {
                    if (!this.checkDisabledDrag()) {
                        this.counterDrag--;

                        if (this.counterDrag === 0) {
                            handlerLeave();
                            this.counterDrag = 0;
                        }
                    }
                },
                false,
            );
            area.addEventListener(
                'drop',
                (e) => {
                    if (!this.checkDisabledDrag()) {
                        this.counterDrag = 0;
                        handlerDrop(e.dataTransfer.files);
                    }
                },
                false,
            );
        }
    }

    checkImage({ type }) {
        return ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'].includes(type);
    }
}