import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import ModelsPage from '@components/crm/modelsPage/ModelsPage.tsx';

import Pages from '../../components/Pages.jsx';

import Groups from './pays/groups/Groups.tsx';
import PaysLimits from './pays/limits/Limits.tsx';
import Main from './pays/main/Main.tsx';
import PaysTax from './pays/tax/Tax.tsx';

class Pays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = {
        'pays-main': {
            render() {
                return <Main groupId="main" />;
            },
        },
        'pays-groups': {
            render() {
                return <Groups />;
            },
        },
        'pays-tax': {
            render() {
                return <PaysTax />;
            },
        },
        'pays-limits': {
            render() {
                return <PaysLimits />;
            },
        },
        'pays-projects': {
            render() {
                return <ModelsPage type="payProjects" />;
            },
        },
    };

    render() {
        return (
            <div ref={this.parent} className="manual">
                <div className="manual__inner">
                    <Pages
                        classNamePage="manual__page"
                        filter={(page) => page.parentName === 'pays'}
                        pages={this.pages}
                        context={this}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Pays);

Pays.propTypes = {
    user: PropTypes.object,
    counterChangePage: PropTypes.number,
};
