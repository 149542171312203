import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import I from '../types.ts';

const getMoreActions: I['getMoreActions'] = function ({ doc }) {
    const actions: CursorActionsItemT[] = [
        {
            key: 'edit',
            name: 'edit',
            onClick: async () => {
                handlerPopup('docPopup', {
                    isShow: true,
                    id: doc._id,
                });
            },
        },
    ];

    return actions;
};

export default getMoreActions;
