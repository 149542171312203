import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dispatcher } from '../../../redux/redux';

import removeTransition from '../../../functions/removeTransition.ts';

import NotificationsBar from './Bar.jsx';
import HideNotificationsBar from './HideBar.jsx';

import Animate from '../../Animate.jsx';
import getNotifications from '../../../requests/getNotifications';
import getArticles from '../../../requests/getArticles';

class NotificationsBarWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowHideState: false,
        };

        this.checkHide = this.checkHide.bind(this);
        this.middleCheckHide = this.middleCheckHide.bind(this);
        this.handlerHideState = this.handlerHideState.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeCorporation = this.changeCorporation.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);

        this.hideSideBar = React.createRef();
    }

    handlerHideState(isShowHideState = !this.state.isShowHideState) {
        this.setState({ isShowHideState });
    }

    checkHide() {
        const { isHideNotificationsBar } = this.props;

        if (isHideNotificationsBar && window.widthValue > 1720) {
            dispatcher({ type: 'isHideNotificationsBar', data: false });

            removeTransition({ item: '.body__notificationsBar' });
        }

        if (!isHideNotificationsBar && window.widthValue <= 1720) {
            dispatcher({ type: 'isHideNotificationsBar', data: true });

            removeTransition({ item: '.body__notificationsBar' });
        }
    }

    middleCheckHide() {
        this.checkHide();

        setTimeout(() => {
            this.checkHide();
        }, 10);
    }

    handlerMissClick({ target }) {
        const { isShowHideState } = this.state;

        if (this.hideSideBar.current) {
            const bar = this.hideSideBar.current.querySelector('.notificationsBar');

            if (isShowHideState && bar !== target && !bar.contains(target)) {
                this.setState({ isShowHideState: false });
            }
        }
    }

    changePage() {
        this.setState({ isShowHideState: false });
    }

    saveCorporation() {
        const { user } = this.props;

        return new Promise((resolve) => {
            this.setState({ idOfCurrentCorporation: user?.idOfCurrentCorporation }, resolve);
        });
    }

    changeCorporation() {
        const { user } = this.props;

        if (user && user.idOfCurrentCorporation !== this.idOfCurrentCorporation) {
            this.idOfCurrentCorporation = user.idOfCurrentCorporation;

            this.setState(
                {
                    isChangeCorporationProccess: true,
                },
                () => {
                    setTimeout(() => {
                        this.saveCorporation().then(() => {
                            this.getNotificationsCounter();

                            this.setState({
                                isChangeCorporationProccess: false,
                            });
                        });
                    }, 300);
                },
            );
        }
    }

    getNotificationsCounter() {
        const { isShow } = this.props;

        if (isShow) {
            getNotifications({ params: [{ key: 'counter', value: true }] }).then(({ counter }) => {
                this.setState({ notificationCounter: counter });
            });
        }
    }

    getAnnounceCounter() {
        const { isShow } = this.props;

        if (isShow) {
            getArticles('announce', { params: [{ key: 'reqType', value: 'notRead' }] }).then(
                ({ counter }) => {
                    this.setState({ announceCounter: counter });
                },
            );
        }
    }

    playNotificationMusic() {
        const { isHideAudioNotification } = this.props;

        if (!isHideAudioNotification) {
            const audio = new Audio();

            audio.addEventListener('canplaythrough', () => {
                audio.play().then(
                    () => null,
                    (err) => {
                        console.log('Error notification music', err);
                    },
                );
            });

            audio.src = require(`../../../img/notify.mp3`);
        }
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;
        const { type } = data;

        if (name === 'corporation' && type === 'notifications') {
            const { deleteNotificationId } = data;

            if (!deleteNotificationId) {
                this.playNotificationMusic();
            }

            this.getNotificationsCounter();
        }

        if (name === 'user' && type === 'changeInfo' && data.fields?.readedNews) {
            this.getAnnounceCounter();
        }
    }

    componentDidMount() {
        this.checkHide();

        this.saveCorporation().then(() => {
            this.setState({
                isInit: true,
            });
        });

        this.changeCorporation();
        this.getNotificationsCounter();
        this.getAnnounceCounter();

        document.addEventListener('changePage', this.changePage);
        document.addEventListener('click', this.handlerMissClick);
        document.addEventListener('changeWidthWindow', this.middleCheckHide);
        document.addEventListener('getSocketData', this.handlerSocket);
    }

    componentDidUpdate() {
        this.changeCorporation();
    }

    componentWillUnmount() {
        document.removeEventListener('changePage', this.changePage);
        document.removeEventListener('click', this.handlerMissClick);
        document.removeEventListener('changeWidthWindow', this.middleCheckHide);
        document.removeEventListener('getSocketData', this.handlerSocket);
    }

    render() {
        const {
            isShowHideState,
            isChangeCorporationProccess,
            idOfCurrentCorporation,
            notificationCounter = 0,
            announceCounter = 0,
        } = this.state;
        const { dateOfLogout, isHideNotificationsBar, isShow } = this.props;

        return (
            <>
                {isHideNotificationsBar ? (
                    <>
                        <Animate className="body__notificationsBar _hide" isShow={isShow}>
                            <HideNotificationsBar
                                handlerHideState={this.handlerHideState}
                                counter={notificationCounter + announceCounter}
                            />
                        </Animate>
                        <Animate
                            refObject={this.hideSideBar}
                            className="body__notificationsBar _showHideState"
                            key={dateOfLogout}
                            isShow={isShowHideState}
                        >
                            <NotificationsBar
                                isHide={true}
                                handlerHideState={this.handlerHideState}
                                isChangeCorporationProccess={isChangeCorporationProccess}
                                idOfCurrentCorporation={idOfCurrentCorporation}
                                notificationCounter={notificationCounter}
                                announceCounter={announceCounter}
                            />
                        </Animate>
                    </>
                ) : (
                    <>
                        <Animate
                            className="body__notificationsBar"
                            key={dateOfLogout}
                            isShow={isShow}
                        >
                            <NotificationsBar
                                isChangeCorporationProccess={isChangeCorporationProccess}
                                idOfCurrentCorporation={idOfCurrentCorporation}
                                notificationCounter={notificationCounter}
                                announceCounter={announceCounter}
                            />
                        </Animate>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        dateOfLogout: state.dateOfLogout,
        isHideNotificationsBar: state.isHideNotificationsBar,
        isHideAudioNotification: state.isHideAudioNotification,
    };
}

export default connect(mapStateToProps)(NotificationsBarWrapper);

NotificationsBarWrapper.propTypes = {
    user: PropTypes.object,
    dateOfLogout: PropTypes.number,
    isHideNotificationsBar: PropTypes.bool,
    isHideAudioNotification: PropTypes.bool,
    isShow: PropTypes.bool,
};
