import ItemsI from '../types.ts';

const setFilter: ItemsI['setFilter'] = function (this: ItemsI, filterQuery) {
    this.setState({ filterQuery }, () => {
        const { localFilterName } = this.state;

        if (localFilterName && filterQuery) {
            localStorage.setItem(localFilterName, JSON.stringify(filterQuery));
        }

        this.updateItems();
    });
};

export default setFilter;
