import I from '../types.ts';

const checkImage: I['checkImage'] = function () {
    const { src } = this.props;
    const clearedSrc = src.split('?')[0];

    return ['jpg', 'jpeg', 'png'].includes(clearedSrc.split('.').pop()!.toLowerCase());
};

export default checkImage;
