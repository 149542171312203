import getEndText from './getEndText';
import setNotification from './setNotification';

export default function setTryNotification({ time = new Date().getTime() }) {
    const differentTime = (+time - new Date().getTime()) / 1_000;
    let minutes = Math.round(differentTime / 60);

    if (minutes <= 1) {
        minutes = 1;
    }

    const timeText = `${minutes} ${getEndText(minutes, ['минуту', 'минуты', 'минут'])}`;

    setNotification({
        notification: 'try-latter',
        title: `Повторный запрос можно отправить через <b>${timeText}</b>`,
    });
}
