import CalendarI from '../types.ts';

const getId: CalendarI['getId'] = function (date) {
    if (date) {
        const thisDate = new Date(date);

        thisDate.setHours(0, 0, 0, 0);

        return thisDate.getTime() / (1_000 * 60 * 60 * 24);
    }

    return 0;
};

export default getId;
