import React from 'react';

import Switch from '@components/switch/Switch.tsx';

import I from '../types.ts';

const renderSwitch: I['renderSwitch'] = function () {
    const { name, prop, value, onChange, disabled } = this.props;

    return (
        <>
            <div className="v2widgetField__field _switch _COL">
                <div className="v2widgetField__switch">
                    <Switch
                        className="_mediumSize"
                        name={name}
                        value={!!value}
                        onChange={() => {
                            onChange({ [prop]: !value });
                        }}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

export default renderSwitch;
