import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { loadingKey, error, currentType, isEdit, organization } = this.state;

    return (
        <>
            <div className="v2popup__head _COL">
                <div className="v2popup__title">Способ оплаты</div>
                <div className="v2popup__description">
                    Заполните данные ниже
                    <br />и сохраните их
                </div>
                <div className="v2popup__close _top _CLICK" onClick={this.close.bind(this)}>
                    <Icon name="popup-close" />
                </div>
                {!isEdit && organization === 'SMZ' && (
                    <div className="v2popup__tabs _ROW">
                        {(Object.keys(this.types) as (keyof typeof this.types)[]).map((type) => (
                            <label className="v2popup__tab _CLICK" key={type}>
                                <input
                                    type="checkbox"
                                    className="v2popup__tabInput"
                                    checked={currentType === type}
                                    onChange={() => {
                                        this.setState({ currentType: type });
                                    }}
                                />
                                <div className="v2popup__tabInner">{this.types[type].content}</div>
                            </label>
                        ))}
                    </div>
                )}
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <ListAbsoluteMain
                    className="v2popup__blocks _ITEMS"
                    items={[{ name: currentType }]}
                    renderItem={this.renderBlock.bind(this)}
                    classNameItem="v2popup__block"
                    prop="name"
                    paramsParent={{ width: true }}
                    styles={['height']}
                    isNotParamsItem={true}
                    allItems={Object.keys(this.types)}
                    currentItemKey={currentType}
                />
            </div>
            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _ROW">
                        <div className="v2popupFoot__button _full">
                            <Button
                                className="_main"
                                onClick={this.save.bind(this)}
                                loading={loadingKey === 'save'}
                                disabled={!this.checkChange().isChange}
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                    <AnimateChange
                        className="v2popupFoot__error"
                        renderKey={error?.text}
                        styles={['height']}
                        paramsParent={{ width: true, height: 'auto' }}
                    >
                        <>{error?.text}</>
                    </AnimateChange>
                </div>
            </div>
        </>
    );
};

export default renderContent;
