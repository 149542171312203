import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Avatar from './avatar/Avatar';

class AuthSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { user } = this.props;
        const { firstName: name } = user || {};

        return (
            <div className={`authSuccess _col _${process.env.REACT_APP_SYSTEM}`}>
                <div className="authSuccess__content _col">
                    {user.logo.fullSrc && (
                        <div className="authSuccess__preview _col">
                            <div className="authSuccess__previewIcon">
                                <Avatar
                                    className="_grey"
                                    image={user.logo.fullSrc}
                                    firstSymb={user.secondName?.[0]}
                                    secondSymb={user.firstName?.[0]}
                                />
                            </div>
                        </div>
                    )}

                    <h3 className="authSuccess__name">{name}</h3>
                    <p className="authSuccess__description">
                        ДОБРО ПОЖАЛОВАТЬ
                        <br />В LIVECARGO CRM!
                    </p>
                    <img
                        src={require('../img/rocket.gif')}
                        alt=""
                        className="authSuccess__rocket"
                    />
                    <p className="authSuccess__status">ВХОДИМ В СИСТЕМУ...</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(AuthSuccess);

AuthSuccess.propTypes = {
    user: PropTypes.object,
};
