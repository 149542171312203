import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import mountHandler from './methods/mountHandler.ts';
import save from './methods/save.ts';

import RoleI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class Role extends EditBlock<RoleI['props'], RoleI['state']> implements RoleI {
    constructor(props: RoleI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;

    mountHandler = mountHandler;
    save = save;
    close = close;

    componentDidMount() {
        this.mountHandler();
    }

    render() {
        return (
            <Popup name="rolePopup" close={this.close.bind(this)}>
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        rolePopup: state.rolePopup,
    };
}

export default connect(mapStateToProps)(Role);
