import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import UserT from '@global/models/User.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { setModel } = this.props;
    const fields = this.checkChange(true).model;

    try {
        const response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: UserT;
                    error?: { name: string; text: string };
                }>;
            }
        >(`${process.env.REACT_APP_API}/user`, { fields }, { headers: getHeaders() });
        const { success, data } = response.data;

        if (success) {
            const { model: resultModel } = data;

            await this.init({ fields: resultModel });
            await setModel(resultModel);

            return;
        }

        const { error, message } = data;

        if (message === 'You have verification corporation') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'changePassportDeniedCauseCorpVerify',
                denied: true,
            });
        }

        this.setState({ error });
    } catch (error) {}

    return Promise.reject();
};

export default save;
