import React from 'react';

import JoinTemplateT from '@global/models/JoinTemplate.ts';

import { RenderColsT } from '../../types.ts';

const renderListJoinTemplates: RenderColsT<'listJoinTemplates'> = function ({
    name: colName,
    item,
}) {
    let content;
    let className;

    const joinTemplate = item as Pick<JoinTemplateT, '_id' | 'name'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{joinTemplate.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListJoinTemplates;
