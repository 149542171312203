import React from 'react';
import { connect } from 'react-redux';

import ListAbsolute from '@components/listAbsolute/ListAbsolute.tsx';
import { StoreT } from '@global/types.ts';

import checkChangeCurrentKey from './methods/checkChangeCurrentKey.ts';
import checkResizeParent from './methods/checkResizeParent.ts';
import getListProps from './methods/getListProps.ts';
import handlerResize from './methods/handlerResize.ts';
import setParams from './methods/setParams.ts';

import ListAbsoluteMainI from './types.ts';

import renderFullItem from './renders/renderFullItem.tsx';
import renderItem from './renders/renderItem.tsx';

class ListAbsoluteMain
    extends React.Component<ListAbsoluteMainI['props'], ListAbsoluteMainI['state']>
    implements ListAbsoluteMainI
{
    observer: ListAbsoluteMainI['observer'];
    parent: ListAbsoluteMainI['parent'];

    constructor(props: ListAbsoluteMainI['props']) {
        super(props);
        this.state = {};

        this.setParams = this.setParams.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.renderFullItem = this.renderFullItem.bind(this);

        this.parent = React.createRef();
    }

    setParams = setParams;
    handlerResize = handlerResize;
    checkResizeParent = checkResizeParent;
    checkChangeCurrentKey = checkChangeCurrentKey;
    getListProps = getListProps;

    renderItem = renderItem;
    renderFullItem = renderFullItem;

    componentDidMount() {
        this.setState({ isInit: true });
        this.checkResizeParent();
        this.checkChangeCurrentKey();
    }

    componentDidUpdate() {
        this.checkResizeParent();
        this.checkChangeCurrentKey();
    }

    componentWillUnmount(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    render() {
        const { keyResize = '' } = this.state;
        const {
            className = '',
            classNameItem,
            items = [],
            isNotParams,
            callback,
            renderChildren = (inner: React.ReactElement): React.ReactElement => inner,
            disabled,
            hasRenderKey,
            parentWidthOffset,
            name,
            realyItems,
            sort,
            listId,
            ...otherProps
        } = this.props;

        const Tag = className ? 'div' : React.Fragment;
        const tagProps = this.getListProps();

        return (
            <Tag {...tagProps}>
                {renderChildren(
                    <ListAbsolute
                        parent={this.parent.current as HTMLElement}
                        realyItems={realyItems}
                        items={items}
                        classNameItem={classNameItem}
                        setParamsParent={this.setParams}
                        isNotParams={isNotParams}
                        callback={({ ...props }) => {
                            if (callback) {
                                callback({ ...props });
                            }

                            this.checkChangeCurrentKey();
                        }}
                        {...otherProps}
                        renderItem={this.renderItem}
                        keyRender={otherProps.keyRender}
                        keyResize={keyResize}
                        disabled={disabled}
                        hasRenderKey={hasRenderKey}
                        parentWidthOffset={parentWidthOffset}
                        name={name}
                        sort={sort}
                        listId={listId}
                    />,
                )}
            </Tag>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(ListAbsoluteMain);
