import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Main from './blog/Main.jsx';

import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import Inner from './blog/Inner.jsx';

class PublicBlog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: 'main',
        };

        this.renderPage = this.renderPage.bind(this);
        this.setPagesKey = this.setPagesKey.bind(this);

        this.parent = React.createRef();
    }

    renderPage({ item, prop: id }) {
        return (
            <div
                className={`public__innerPage ${item.name === 'main' ? '_blogMain' : '_blogInner'}`}
            >
                {item.name === 'main' && (
                    <>
                        <Main sections={this.sections} setPagesKey={this.setPagesKey} />
                    </>
                )}
                {item.name === 'inner' && (
                    <>
                        <Inner id={id} setPagesKey={this.setPagesKey} />
                    </>
                )}
            </div>
        );
    }

    sections = ['', 'business', 'executors', 'cases', 'smi'];

    checkPage() {
        const { levels } = this.props;

        if (levels[1] === 'blog') {
            const level = levels[2] || '';

            this.pageName = !this.sections.includes(level) ? 'inner' : 'main';
            this.pageId = this.pageName === 'inner' ? level : 'main';

            if (
                this.pageName !== this.state.pageName ||
                (this.pageId && this.pageId !== this.state.pageId)
            ) {
                this.setState({
                    pageName: this.pageName,
                    pageId: this.pageId,
                });
            }
        }
    }

    setPagesKey() {
        this.setState({ updatedPagesKey: new Date().getTime() });
    }

    componentDidMount() {
        this.checkPage();
    }

    componentDidUpdate() {
        this.checkPage();
    }

    render() {
        const { pageName, pageId, updatedPagesKey } = this.state;

        return (
            <>
                <div className="public__section _full">
                    <ListAbsoluteMain
                        className="public__innerPages"
                        items={[{ key: pageId, name: pageName }]}
                        renderItem={this.renderPage}
                        classNameItem="public__innerPage"
                        prop="key"
                        styles={[]}
                        isNotParamsItem={true}
                        currentItemKey={pageName}
                        allItems={['main', 'inner']}
                        keyRender={updatedPagesKey}
                    />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(PublicBlog);

PublicBlog.propTypes = {
    changeLegalCallback: PropTypes.func,
    levels: PropTypes.array,
};
