import React from 'react';

import Icon from '@components/Icon.jsx';

import CallPopupI from '../types.ts';

const renderHead: CallPopupI['renderHead'] = function () {
    const { handler } = this.props;

    return (
        <>
            <i
                className="publicCallPopup__close _CLICK"
                onClick={() => {
                    handler(false);
                }}
            >
                <Icon name="public-popup-close" />
            </i>
            <div className="publicCallPopup__head _COL">
                <img
                    src={require('../../../../../img/crm/public/callPopup/call-icon.svg').default}
                    alt=""
                    className="publicCallPopup__icon"
                />
                <div className="publicCallPopup__title">Перезвоните мне</div>
                <div className="publicCallPopup__description">
                    Заполните поля ниже и мы свяжемся
                    <br /> с вами в ближайшее время
                </div>
            </div>
        </>
    );
};

export default renderHead;
