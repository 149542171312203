import React from 'react';

import BusinessHeaderI from '../types.ts';

const renderBoard: BusinessHeaderI['renderBoard'] = function () {
    return (
        <div className="publicBusinessHeader__board">
            {this.cards.map((card, cardKey) => (
                <div className={`publicBusinessHeader__boardCard _${cardKey + 1}`} key={cardKey}>
                    <img
                        src={require(`../../../../../../../img/crm/public/business/${card.icon}`)}
                        alt=""
                        className="publicBusinessHeader__boardCardIcon"
                    />
                    <div
                        className="publicBusinessHeader__boardCardTitle"
                        dangerouslySetInnerHTML={{ __html: card.content }}
                    ></div>
                </div>
            ))}
            <img
                src={require('../../../../../../../img/crm/public/business/header-image.png')}
                alt=""
                className="publicBusinessHeader__boardImage"
            />
            <img
                src={require('../../../../../../../img/crm/public/business/header-back.png')}
                alt=""
                className="publicBusinessHeader__boardBack"
            />
        </div>
    );
};

export default renderBoard;
