import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../Button.jsx';

class EditorTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { title } = this.state;
        const { callback } = this.props;

        return (
            <div className="editor__actionAlert">
                <div className="editor__actionAlertInner _windowWithCursor _topRight">
                    <div className="editor__actionAlertHead">
                        <div className="editor__actionAlertTitle">Добавление заголовка</div>
                        <div className="editor__actionAlertDescription">
                            Выберите необходимый размер заголовка h1-h6
                        </div>
                    </div>
                    <div className="editor__actionAlertContent _col">
                        <div className="editor__actionAlertContentTitles _col">
                            <div
                                className={`editor__actionAlertContentTitle _click ${
                                    title === 'main' ? '_current' : ''
                                }`}
                                onClick={() => {
                                    this.setState({ title: 'main' });
                                }}
                            >
                                Основной
                            </div>
                            <div
                                className={`editor__actionAlertContentTitle _click _sub ${
                                    title === 'sub' ? '_current' : ''
                                }`}
                                onClick={() => {
                                    this.setState({ title: 'sub' });
                                }}
                            >
                                Подзаголовок
                            </div>
                        </div>
                        <div className="editor__actionAlertButton">
                            <Button
                                className="_main _medium2Size"
                                onClick={(e) => {
                                    callback(e, { title });

                                    this.setState({
                                        title: null,
                                    });
                                }}
                            >
                                Добавить заголовок
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(EditorTitle);

EditorTitle.propTypes = {
    callback: PropTypes.func,
};
