import changePage from '@functions/changePage.ts';
import { PageNamesT } from '@redux/pages.ts';

import MainI from '../types.ts';

const getActions: MainI['getActions'] = function (this: MainI) {
    const { config } = this.props;

    return [
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: config.tableName,
        },
        {
            type: 'link',
            content: config.addButton,
            name: 'add',
            className: '_main',
            group: 'links',
            onClick: async () => {
                changePage<'crm'>({
                    pageName: `${config.modelPage}-main` as PageNamesT['crm'],
                    ids: { 3: 'new' },
                });
            },
        },
    ];
};

export default getActions;
