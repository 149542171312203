import AlertI from '../../types';

const withdrawTax = {
    title: 'Подтвердите вывод средств',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите вывести средства для <b>${info}</b>?`;
    },
    buttonText: 'Вывести',
} as const;

export default withdrawTax;
