const corporations = {
    colsOrder: ['id', 'name', 'inn', 'bic', 'users', 'balance', 'status'],
    cols: {
        id: {
            content: 'ID',
            width: 6.5,
        },
        name: {
            content: 'Название',
            width: 23.5,
        },
        inn: {
            content: 'ИНН',
            width: 13,
        },
        bic: {
            content: 'БИК',
            width: 13,
        },
        users: {
            content: 'Сотрудники',
            width: 11,
        },
        balance: {
            content: 'Баланс',
            width: 15,
        },
        status: {
            content: 'Статус',
            width: 18,
        },
    },
    deletesCols: [],
    url: 'corporation',
    emptyInfo: {
        title: 'Компаний пока нет',
        description: 'Они будут сохраняться здесь',
    },
} as const;

export default corporations;
