import { store } from '../../redux/redux';
import handlerPopup from '../app/handlerPopup';
import getCurrentCorporation from './getCurrentCorporation';

export default function checkVerification({ set }) {
    const user = store.getState().user;

    if (user) {
        const dateKey = `${new Date().getUTCDate()}-${new Date().getUTCMonth()}-${new Date().getUTCFullYear()}`;
        const corporation = getCurrentCorporation({ user });

        if (
            corporation &&
            corporation.role === 'holder' &&
            ['reject', 'wait'].includes(corporation.verificationStatus)
        ) {
            const storageKey = `verification-${corporation._id}`;
            const saveDate = localStorage.getItem(storageKey);

            if (set) {
                localStorage.setItem(storageKey, dateKey);

                handlerPopup({ name: 'verificationPopup', isShow: false });
            } else if (!saveDate || saveDate !== dateKey) {
                handlerPopup({ name: 'verificationPopup', isShow: true });
            }
        }
    }
}
