import { FilterQueryT } from '@global/types.ts';

import FilterI from '../types.ts';

const getChange: FilterI['getChange'] = function () {
    const { model: filter = [] } = this.state;
    const { fields } = this.checkChange(true);
    const resultQuery: FilterQueryT[] = [];

    Object.keys(fields).forEach((key) => {
        const [index, prop] = key.split('.');
        const block = filter[+index];
        const value = fields[key] as string;

        if (block.type === 'date') {
            const resultProp = prop === 'currentDate' ? 'startDate' : 'endDate';

            if (fields[key]) {
                resultQuery.push({ key: `${resultProp}_${block.name}`, name: 'date', value });
            }
        } else if (fields[key]) {
            resultQuery.push({ key: block.name, name: block.name, value });
        }
    });

    return resultQuery;
};

export default getChange;
