import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class PublicExecutorsAppInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        return (
            <section className="publicExecutorsInfo">
                <div className="publicExecutorsInfo__inner">
                    <div className="publicExecutorsInfo__content _col">
                        <div className="publicExecutorsInfo__contentBack" />
                        <div className="publicExecutorsInfo__info _col">
                            <div className="publicExecutorsInfo__title">
                                Для исполнителей появилось новое удобное приложение!
                            </div>
                            <div className="publicExecutorsInfo__description">
                                Выполняй задания и зарабатывай
                                <br />
                                быстро, легально и просто
                            </div>
                        </div>
                        <img
                            src={require('../../../../img/crm/public/executors-info-image-1.png')}
                            alt=""
                            className="publicExecutorsInfo__image"
                        />
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
    };
}

export default connect(mapStateToProps)(PublicExecutorsAppInfo);

PublicExecutorsAppInfo.propTypes = {
    windowIsReady: PropTypes.bool,
};
