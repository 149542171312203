import { dispatcher, store } from '../redux/redux';

let timerId = null;
const position = {
    x: 0,
    y: 0,
    screenX: 0,
    screenY: 0,
};
const settings = {
    break: 3,
    offsetY: 27,
};
let target = null;

const handlerMove = (e, scroll) => {
    const support = document.querySelector('.body__supportCursor');

    if (support) {
        settings.offsetY = support.offsetHeight + 5;
        if (scroll !== true) {
            position.x = e.clientX - support.offsetWidth / 2;
            position.y = e.clientY - settings.offsetY - settings.break;
            position.screenY = position.y - window.pageYOffset;
            if (e.pageX - support.offsetWidth / 2 < settings.break) {
                position.x = settings.break;
            }
            if (
                e.pageX + support.offsetWidth / 2 >
                document.documentElement.clientWidth - settings.break
            ) {
                position.x =
                    document.documentElement.clientWidth - settings.break - support.offsetWidth;
            }
            if (e.pageY - window.pageYOffset < settings.offsetY + settings.break) {
                position.y = settings.break;
            }
        }

        support.style.transform = `translate(${position.x}px,${
            scroll === true ? window.pageYOffset + position.screenY : position.y
        }px)`;
    }
};

export default function handlerSupportCursor({ action, content, e, data: dataRes = null }) {
    if (e && typeof e.persist === 'function') {
        e.persist();
    }
    if (e && ['enter', 'click'].indexOf(action) !== -1) {
        target = e.target;
    }
    const event = e;
    let data = dataRes;

    if (data === null) {
        data = { className: null };
    }

    if (store.getState().device !== 'mobile') {
        switch (action) {
            case 'enter':
                if (timerId) {
                    clearTimeout(timerId);
                }
                dispatcher({
                    type: 'supportCursor',
                    data: {
                        show: true,
                        showAnimate: false,
                        content,
                        ...data,
                    },
                }).then(() => {
                    handlerMove(event);
                });
                setTimeout(() => {
                    dispatcher({
                        type: 'supportCursor',
                        data: {
                            show: true,
                            showAnimate: true,
                            content,
                        },
                    });
                }, 10);
                break;
            case 'click':
                if (timerId) {
                    clearTimeout(timerId);
                }
                dispatcher({
                    type: 'supportCursor',
                    data: {
                        show: true,
                        showAnimate: true,
                        content,
                    },
                }).then(() => {
                    handlerMove(event);
                });

                break;
            case 'move':
                if (timerId) {
                    clearTimeout(timerId);
                }

                dispatcher({
                    type: 'supportCursor',
                    data: {
                        show: true,
                        showAnimate: true,
                        content,
                    },
                }).then(() => {
                    handlerMove(event);
                });
                break;
            case 'leave':
                {
                    if (timerId) {
                        clearTimeout(timerId);
                    }

                    const dataForLeave = {
                        show: true,
                        showAnimate: false,
                    };
                    if (content) {
                        dataForLeave.content = content;
                    }

                    timerId = setTimeout(() => {
                        dispatcher({
                            type: 'supportCursor',
                            data: {
                                show: false,
                                showAnimate: false,
                                name: null,
                                className: null,
                            },
                        });
                    }, 300);

                    dispatcher({
                        type: 'supportCursor',
                        data: dataForLeave,
                    });
                }
                break;
            default:
                break;
        }
    }
}

setInterval(() => {
    if (document.contains(target) === false) {
        target = null;
        // handlerSupportCursor({ action: 'leave' });
    }
}, 100);

document.addEventListener('mousemove', handlerMove);

window.addEventListener('scroll', () => {
    const support = document.querySelector('.body__supportCursor');

    if (support) {
        // handlerSupportCursor({ action: 'leave', isScroll: true });
    }
});
