import React from 'react';

import Media from '@components/media/Media.tsx';
import MobPopup from '@components/mobPopup/MobPopup.tsx';

import AlertI from '../types.ts';

const renderMobile: AlertI['renderMobile'] = function () {
    const { windowHeight } = this.props;

    return (
        <>
            <Media current="mobile">
                <MobPopup
                    isInit={true}
                    windowHeight={windowHeight}
                    hideCallback={this.close.bind(this)}
                >
                    {this.renderContent()}
                </MobPopup>
            </Media>
        </>
    );
};

export default renderMobile;
