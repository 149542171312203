import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon.jsx';
import Animate from './Animate.jsx';

class ActionChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    orderActions = ['add', 'delete'];

    render() {
        const { isHas, ...props } = this.props;
        const stateAction = isHas ? 'delete' : 'add';

        return (
            <Animate {...props}>
                <div className={`action _col _click _animate _${stateAction}`}>
                    {this.orderActions.map((action) => (
                        <Animate
                            key={action}
                            className={`action__icon _${action}`}
                            isShow={action === stateAction}
                        >
                            <Icon name={action} />
                        </Animate>
                    ))}
                </div>
            </Animate>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ActionChange);

ActionChange.propTypes = {
    isHas: PropTypes.bool,
};
