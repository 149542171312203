import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getModel: I['getModel'] = async function () {
    const { id } = this.state;

    try {
        const { item } = await getListItems({
            url: 'executor-mvd',
            query: [{ key: 'id', value: id! }],
        });

        await this.init({ fields: item });

        this.setHeight();
    } catch (error) {}
};

export default getModel;
