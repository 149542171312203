import React from 'react';

import EditBlockI from '@components/editBlock/types';
import InputComp from '@components/input/Input.tsx';
import { FilterBlockT } from '@global/types';

type ParamsT = {
    blockName: string;
    change: EditBlockI['change'];
    index: number;
    regExp?: RegExp;
} & Pick<FilterBlockT, 'inputSupport' | 'value' | 'reg'>;

export default function Input(props: ParamsT): React.ReactNode {
    const { blockName, inputSupport, value, change, index, reg, regExp } = props;

    return (
        <div className="v2filter__blockInput">
            <InputComp
                name={blockName}
                // className="_grey"
                support={inputSupport}
                value={value as string}
                onChange={async ({ value: newValue }) => {
                    await change({ [`${index}.value`]: newValue });
                }}
                reg={reg}
                regExp={regExp}
                returnTemplate={reg ? reg.toLowerCase().includes('date') : false}
            />
        </div>
    );
}
