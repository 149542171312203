import AlertI from '../../types';

const inviteExecutor = {
    title: 'Исполнитель уже существует',
    description(this: AlertI) {
        const { info } = this.state;

        return `Исполнитель <b>${info}</b> уже есть в системе. Хотите отправить ему запрос на подключение?`;
    },
    buttonText: 'Отправить',
} as const;

export default inviteExecutor;
