import { dispatcher } from '@redux/redux.ts';
import RootI from '../types.ts';

const checkAfk: RootI['checkAfk'] = function () {
    if (this.timerAfk) {
        clearTimeout(this.timerAfk);
        dispatcher({ type: 'afk', data: false });
    }

    this.timerAfk = setTimeout(() => {
        dispatcher({ type: 'afk', data: true });
    }, 5_000);
};

export default checkAfk;
