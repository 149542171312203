import handlerLoading from '@functions/handlerLoading.ts';

import I from '../types.ts';

const asyncInputHandler: I['asyncInputHandler'] = async function (value) {
    const { asyncHandler, onChange } = this.props;

    if (asyncHandler && value && asyncHandler.cond(value as string)) {
        await handlerLoading.call(this, 'asyncCheck');

        try {
            const asyncData = await asyncHandler.handler(value as string);

            onChange({ ...asyncData });
        } catch (error) {}

        await handlerLoading.call(this, undefined);
    }
};

export default asyncInputHandler;
