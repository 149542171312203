import axios from 'axios';

export default function getPdf() {
    const query = window.location.search.slice(1);

    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_HARD_API}/pdf?${query}`).then((res) => {
            const { success, data } = res.data;

            if (success) {
                resolve(data);
            } else {
                reject();
            }
        });
    });
}
