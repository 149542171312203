import React from 'react';

import ExecutorOrg from '@components/crm/ExecutorOrg.jsx';
import CorporationInfo from '@components/crm/manual/CorporationInfo.jsx';
import { ExecutorT } from '@global/models/Executor.ts';
import JoinScriptT from '@global/models/JoinScript.ts';
import { ModelCorporationInfoT } from '@global/types.ts';

import { RenderColsT } from '../../types.ts';

const renderListJoinScripts: RenderColsT<'listJoinScripts'> = function ({ name: colName, item }) {
    let content;
    let className;

    const joinScript = item as Pick<JoinScriptT, '_id' | 'name' | 'link'> & {
        corporationInfo: ModelCorporationInfoT[];
        organization: ExecutorT['organization'];
    };

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{joinScript.name},</div>{' '}
                <div className="v2table__executorOrg">
                    <ExecutorOrg name={joinScript.organization} />
                </div>{' '}
                <div className="v2table__text _grey">/{joinScript.link}</div>
                {joinScript.corporationInfo?.[0] && (
                    <div className="v2table__corporation _top">
                        <CorporationInfo {...joinScript.corporationInfo[0]} />
                    </div>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderListJoinScripts;
