import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import List from '../components/chat/List.jsx';
import Window from '../components/chat/Window.jsx';
import Animate from '../components/Animate.jsx';

import changePage from '../functions/changePage';
import getEnvParams from '../functions/getEnvParams';

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changePage = this.changePage.bind(this);
        this.changeCompany = this.changeCompany.bind(this);
    }

    changePage({ detail }) {
        const { group, name } = detail;

        if (this.idOfChat) {
            if (group !== getEnvParams().nameParentChat) {
                this.setState({ isLeave: true });
            }
            if (name === getEnvParams().nameParentChat || group === getEnvParams().nameParentChat) {
                this.setState({ isLeave: false });
                this.idOfChat = null;
            }
        }
    }

    changeCompany() {
        changePage({
            href: 'cabinet-chat',
            pagesNotHide: ['auth'],
            isNotChangeUrl: true,
            id: null,
        });
    }

    componentDidMount() {
        const { setHeightPage } = this.props;

        setHeightPage();

        document.addEventListener('changeCompany', this.changeCompany);
        document.addEventListener('changePage', this.changePage);
    }

    componentWillUnmount() {
        document.removeEventListener('changeCompany', this.changeCompany);
        document.removeEventListener('changePage', this.changePage);
    }

    render() {
        const { levels, getParent, setFilterCallback, checkRights, getChatsCounters } = this.props;
        const idOfChat = getEnvParams().checkChangeChat({ levels, isCheckParent: true })
            ? levels[getEnvParams().keyLevelsChatInner]
            : null;

        if (idOfChat) {
            this.idOfChat = idOfChat;
        }

        return (
            <div className="chat _row">
                <div className="chat__list">
                    <List
                        getParent={getParent}
                        setFilterCallback={setFilterCallback}
                        getChatsCounters={getChatsCounters}
                    />
                </div>
                <div className="chat__window">
                    <Animate className="chat__windowInfo" isShow={!idOfChat}>
                        <div
                            className={`empty _col _block ${
                                process.env.REACT_APP_SYSTEM === 'crm' ? '_notBack _notRad' : ''
                            }`}
                        >
                            <h4 className="empty__title">Выберите чат</h4>
                            <p className="empty__content">
                                Для начала работы выберите чат из списка слева
                            </p>
                        </div>
                    </Animate>
                    <Animate className="chat__windowInner" isShow={!!idOfChat}>
                        <Window type="cabinet" getParent={getParent} checkRights={checkRights} />
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(Chat);

Chat.propTypes = {
    levels: PropTypes.array,
    setHeightPage: PropTypes.func,
    getParent: PropTypes.func,
    setFilterCallback: PropTypes.func,
    checkRights: PropTypes.func,
    getChatsCounters: PropTypes.func,
};
