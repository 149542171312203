import React from 'react';

import { s } from '@functions/seo.ts';

import checkStateOfAction from './methods/checkStateOfAction.ts';
import setStateOfAnimate from './methods/setStateOfAnimate.ts';

import AnimateI from './types.ts';

class Animate
    extends React.PureComponent<AnimateI['props'], AnimateI['state']>
    implements AnimateI
{
    parent: AnimateI['parent'];

    constructor(props: AnimateI['props']) {
        super(props);
        this.state = {
            stateOfAnimate: -1,
            heightInner: 0,
        };

        this.parent = React.createRef();
    }

    stateOfAnimate = -1;

    setStateOfAnimate = setStateOfAnimate;
    checkStateOfAction = checkStateOfAction;

    componentDidMount() {
        this.checkStateOfAction({ isInit: true });
    }

    componentDidUpdate() {
        this.checkStateOfAction({});
    }

    render() {
        const { stateOfAnimate, heightInner } = this.state;
        const { children, className, onClick, style = {}, classInner, ...otherProps } = this.props;
        const styleResult: { [s: string]: string | number } = { ...style };

        if (classInner) {
            styleResult.height = `${stateOfAnimate === 1 ? heightInner : 0}px`;
        }

        return (
            (s() ? !!otherProps.isShow : stateOfAnimate > -1) && (
                <div
                    ref={this.parent}
                    className={`${className} ${stateOfAnimate === 1 ? '_show' : ''} _ANIMATE`}
                    onClick={(e: React.MouseEvent): void => {
                        if (onClick && typeof onClick === 'function') {
                            onClick(e);
                        }
                    }}
                    style={styleResult}
                    {...(s() ? {} : { ...otherProps })}
                >
                    {children}
                </div>
            )
        );
    }
}

export default Animate;
