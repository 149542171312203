const joinsScripts = {
    colsOrder: ['active', 'name', 'template', 'docs', 'counter'],
    cols: {
        active: {
            content: 'Активен',
            width: 10,
        },
        name: {
            content: 'Название сценария',
            width: 25,
        },
        template: {
            content: 'Шаблон договора',
            width: 25,
            isAdminMain: true,
        },
        docs: {
            content: 'Документы',
            width: 27,
        },
        counter: {
            content: 'Регистраций',
            width: 13,
        },
    },
    adminIndex: 1,
    deletesCols: ['docs'],
    url: 'join-script',
    emptyInfo: {
        title: 'Сценариев пока нет',
        description: 'Вы можете создать сценарий по кнопке справа',
    },
} as const;

export default joinsScripts;
