import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import DocT from '@global/models/Doc.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { isEdit, model } = this.state;
    const { docPopup } = this.props;
    const { setModel } = docPopup;
    const method = isEdit ? 'patch' : 'post';
    const change = this.checkChange(true);
    const fields = change.model!;

    if (change.deleteItems?.length) {
        change.deleteItems.forEach((deleteItem) => {
            const deleteItemParts = deleteItem.split('.');
            const [filesName, fileId] = deleteItemParts;

            if (filesName === 'files' && fileId) {
                if (!fields.files) {
                    fields.files = [];
                }

                fields.files.push({ _id: fileId, isDelete: true });
            }
        });
    }

    await handlerLoading.call(this, 'save');

    const response = await axios[method]<
        {},
        {
            data: ReqResponseT<{
                message: string;
                error?: { name: string; text: string };
                model?: DocT;
            }>;
        }
    >(
        `${process.env.REACT_APP_API}/docs`,
        { fields, ...(isEdit ? { id: model!._id } : {}) },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        this.close();

        if (typeof setModel === 'function') {
            setModel({ model: data.model });
        }

        document.dispatchEvent(new CustomEvent('refreshTable', { detail: { name: 'docs' } }));
    } else {
        const { error, message } = data;

        if (message === 'Executor is verification') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'changeExecutorDeniedCauseCorpVerify',
                denied: true,
            });
        } else if (message === 'Corporation is verification') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'changeDocsDeniedCauseCorpVerify',
                denied: true,
            });
        }

        this.setState({ error });
    }

    await handlerLoading.call(this, undefined);
};

export default save;
