import React from 'react';
import { connect } from 'react-redux';

import Pages from '@components/pages/Pages.tsx';

import Inner from './components/Inner/Inner.tsx';
import Main from './components/Main/Main.tsx';

import ModelsPageI from './types.ts';

import models from './static/models.ts';

class ModelsPage
    extends React.Component<ModelsPageI['props'], ModelsPageI['state']>
    implements ModelsPageI
{
    constructor(props: ModelsPageI['props']) {
        super(props);
        this.state = {};
    }

    models = models;

    componentDidMount(): void {
        const { type } = this.props;
        const config = this.models[type];

        this.setState({
            pages: {
                [config.tablePage]: {
                    render() {
                        return (
                            <>
                                <Main type={type} config={config} />
                            </>
                        );
                    },
                },
                [config.modelPage]: {
                    render() {
                        return (
                            <>
                                <Inner type={type} config={config} />
                            </>
                        );
                    },
                },
            },
        });
    }

    render() {
        const { pages } = this.state;
        const { type } = this.props;
        const config = this.models[type];

        return (
            <>
                {pages && (
                    <Pages
                        className="body__innerPages _ITEMS"
                        classNamePage="body__innerPage _ITEM"
                        filter={(page) => page.parentName === config.parentPage}
                        pages={pages}
                        context={this}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ModelsPage);
