import blurAll from '@functions/blurAll.ts';
import removeTransition from '@functions/removeTransition.ts';
import { FilterBlockT, ListenerT } from '@global/types.ts';

import FilterI from '../types.ts';

const startInit: FilterI['startInit'] = function () {
    const { filterPopup } = this.props;
    const filter: FilterBlockT[] = JSON.parse(JSON.stringify(filterPopup.filter || []));
    const { filterQuery = [] } = filterPopup;

    filterQuery.forEach(({ name, key, value }) => {
        const block = filter.find((item) => item.name === name) as FilterBlockT;

        if (block) {
            if (block.type === 'date') {
                const resultProp = key?.includes('startDate_')
                    ? 'currentDate'
                    : 'doubleCurrentDate';

                block[resultProp] = value as string;
            } else if (['checkbox', 'select'].includes(block.type)) {
                if (!block.value) {
                    block.value = [];
                }

                (block.value as (string | boolean)[]).push(value);
            } else {
                block.value = value;
            }
        }
    });

    this.init({ fields: filter.filter((block) => !block.main) });

    this.setState(
        {
            name: filterPopup.name,
            filterQuery: [...filterQuery],
            isSizeInit: !filter.find((item) => (item as FilterBlockT).type === 'date'),
        },
        () => {
            removeTransition({ item: '.v2filter__button' });
        },
    );

    (document.addEventListener as ListenerT<KeyboardEvent>)('keydown', this.handlerKey);

    blurAll();
};

export default startInit;
