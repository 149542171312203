import React from 'react';

import CityT from '@global/models/City.ts';

import { RenderColsT } from '../../types.ts';

const renderListCities: RenderColsT<'listCities'> = function ({ name: colName, item }) {
    let content;
    let className;

    const city = item as CityT;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{city.name}</div>,{' '}
                <div className="v2table__text _grey">{city.region}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListCities;
