import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import RoleT from '@global/models/Role.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (force) {
    const { model } = this.state;
    const { roleSectionPopup } = this.props;
    const { setModel } = roleSectionPopup;
    const resultFields = this.checkChange(true).model as RoleT;

    if (resultFields.sections) {
        resultFields.sections = model!.sections;
    }

    let response;

    try {
        response = await axios.patch<
            {},
            { data: ReqResponseT<{ model: RoleT; message?: string }> }
        >(
            `${process.env.REACT_APP_API}/role`,
            {
                id: model?._id,
                fields: resultFields,
                ...(force === true ? { force } : {}),
            },
            { headers: getHeaders() },
        );
    } catch (error) {
        return Promise.reject();
    }

    const { success, data } = response.data;

    if (success) {
        this.init({ fields: data.model });

        if (typeof setModel === 'function') {
            setModel({ model: data.model });
        }

        return;
    }

    const { message } = data;

    if (message === 'Users lose proxies') {
        handlerPopup('alertPopup', {
            isShow: true,
            important: true,
            type: 'roleUsersHasProxies',
            callback: this.save.bind(this, true),
        });
    }

    return;
};

export default save;
