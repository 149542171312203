import lists from '../lists';

const listPayProjects = {
    ...lists,
    query: [{ key: 'type', value: 'payProjects' }],
    emptyInfo: {
        title: 'Проектов пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listPayProjects;
