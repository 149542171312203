import handlerLoading from '@functions/handlerLoading.ts';
import uploadFile from '@requests/uploadFile.ts';

import I from '../types.ts';

const fileHandler: I['fileHandler'] = async function ({ target }) {
    const { onChange, prop, fileProp } = this.props;
    const file = target.files![0];

    const formData = new FormData();

    formData.set('file', file);

    await handlerLoading.call(this, prop);

    try {
        const response = await uploadFile({ formData });

        let nameProp: string | string[] = prop.split('.');

        nameProp.pop();

        nameProp.push('name');

        nameProp = nameProp.join('.');

        onChange({
            [prop]: response.src,
            [nameProp]: response.name,
            ...(fileProp ? { [fileProp]: response.fullSrc } : {}),
        });
    } catch (error) {}

    target.value = '';

    await handlerLoading.call(this, undefined);
};

export default fileHandler;
