import React from 'react';

import Button from '@components/button/Button.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import { setCursorList } from '@functions/listsHandler.ts';

import I from '../types.ts';

const renderItem = function (
    list: NonNullable<I['props']['list']>,
    { item }: { item: { _id: string } },
) {
    if (item._id === 'empty') {
        return <div className="v2widgetField__listItem _ITEM _empty">Не выбрано</div>;
    }

    return <div className="v2widgetField__listItem _ITEM">{list.render(item)}</div>;
};

const renderList: I['renderList'] = function () {
    const { model, name, cardName, prop, addArrayItem, deleteArrayItem } = this.props;
    const list = this.props.list!;
    const items = model[prop] as { _id: string }[];

    let resultItems = [...items];

    if (items.length === 0) {
        resultItems = [{ _id: 'empty' }];
    }

    const id = `list-${name}-${cardName}`;

    const props = setCursorList(
        {
            _id: id,
            type: list.name,
            dir: 'bottom',
            currents: items.map((item) => item._id),
            filterName: list.name,
            multi: true,
            callback: (modelId, item: any) => {
                if (item) {
                    addArrayItem!({ item, key: 'tags' });
                } else {
                    deleteArrayItem!([`tags.${modelId}`]);
                }
            },
            checkClose: () => !document.querySelector('.body__v2popup._filter'),
        },
        { targetClassName: '.v2widgetField__listButton' },
    );

    return (
        <div className="v2widgetField__field _list">
            <div className="v2widgetField__list">
                <div className="v2widgetField__listCurrent">
                    <ListAbsoluteMain
                        className="v2widgetField__listItems _ITEMS"
                        items={resultItems}
                        renderItem={renderItem.bind(null, list)}
                        classNameItem="v2widgetField__listItem"
                        prop="_id"
                        paramsParent={{ width: true }}
                    />
                </div>
                <div className="v2widgetField__listButton" {...props}>
                    <Button className="_blue _minSize">{list.button}</Button>
                </div>
            </div>
        </div>
    );
};

export default renderList;
