import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = function () {
    handlerPopup('roleSectionPopup', {
        isShow: false,
        role: undefined,
        sectionKey: undefined,
        setModel: undefined,
    });
};

export default close;
