import React from 'react';

import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';

import I from '../types.ts';

const renderBlocks: I['renderBlocks'] = function () {
    const { currentBlock } = this.state;

    return (
        <>
            <ListAbsoluteMain
                className="v2popup__blocks _ITEMS"
                items={this.getBlocks()}
                renderItem={this.renderBlock.bind(this)}
                classNameItem="v2popup__block"
                prop="name"
                paramsParent={{ width: true }}
                styles={['height']}
                isNotParamsItem={true}
                allItems={this.blocks}
                currentItemKey={currentBlock}
            />
        </>
    );
};

export default renderBlocks;
