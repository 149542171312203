import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { joinContractPopup } = this.props;
    const { type } = joinContractPopup;
    const model: I['state']['model'] = {
        _id: 'contract',
        type,
    };

    this.init({ fields: model, start: true });
};

export default mountHandler;
