import TableI from '../types.ts';

const checkPropsQuery: TableI['checkPropsQuery'] = function () {
    const { queryKey } = this.props;

    if (queryKey !== this.queryKey) {
        this.queryKey = queryKey;

        this.updateItems();
    }
};

export default checkPropsQuery;
