import InvitesI from '../types.ts';

const handlerSocket: InvitesI['handlerSocket'] = async function ({ detail }) {
    const { name, data } = detail;

    if (name === 'join') {
        const { fields } = data;

        if (fields?.isCreate) {
            await this.tableUpdateItems!();
        } else if (data.fields) {
            await this.tableUpdateItem!({ id: data.id, fields: data.fields });
        }
    }
};

export default handlerSocket;
