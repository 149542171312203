import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import TagT from '@global/models/Tag.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const model = this.state.model!;
    const { setModel } = this.props;
    const change = this.checkChange(true);
    const { deleteItems = [] } = change;

    const fields: Record<any, unknown> = change.model!;

    const body: Record<any, unknown> = {
        fields,
    };

    if (fields.tags || deleteItems.find((item) => item.split('.')[0] === 'tags')) {
        fields.tags = model.tags.map((tag) => ({ id: tag._id }));
    }

    if (fields.docs || deleteItems.find((item) => item.split('.')[0] === 'docs')) {
        fields.docs = model.docs.map((doc) => ({
            _id: doc._id,
            id: doc.id,
            isRequire: doc.isRequire,
        }));
    }

    const method = model!.new ? 'post' : 'patch';

    if (method === 'patch') {
        body.id = model?._id;
    }

    await handlerLoading.call(this, 'save');

    try {
        const response = await axios[method]<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: TagT;
                    error?: { name: string; text: string };
                }>;
            }
        >(`${process.env.REACT_APP_API}/join-script`, { ...body }, { headers: getHeaders() });
        const { success, data } = response.data;

        if (success) {
            const { id, model: resultModel } = data;

            if (method === 'post' && id) {
                await changePage<'crm'>({
                    pageName: 'joins-scripts-inner',
                    ids: { 3: id },
                });
            }

            await this.init({ fields: resultModel });
            await setModel(resultModel);
        } else {
            const { error } = data;

            this.setState({ error });
        }
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default save;
