import React from 'react';

import WidgetVerificationCard from '@components/crm/widgetVerificationCard/WidgetVerificationCard.tsx';
import changePage from '@functions/changePage.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';

import I from '../types.ts';

const renderVerification: I['renderVerification'] = function () {
    const model = this.state.model!;
    const { user, inner } = this.props;

    const title =
        model.verification.status === 'active' ? 'Верификация пройдена' : 'Верификация не пройдена';
    let description = '';
    const links = [];

    if (model.verification.status === 'active') {
        description = getFormatedDate({
            date: new Date(model.verification.date),
            type: 'full',
        });
    } else if (model.verification.status === 'wait') {
        description = 'Компания на проверке';
    } else {
        description = 'На доработке';
    }

    if (user?.idOfCurrentCorporation !== 'admin') {
        links.push({
            id: 'detils',
            content: 'К деталям',
            icon: {
                className: '_arrowNext',
                name: 'arrow-next-2' as const,
            },
            onClick: async () => {
                changePage({ pageName: inner ? 'corporations-inner-docs' : 'settings-docs' });
            },
        });
    }

    return (
        <>
            <WidgetVerificationCard
                title={title}
                description={description}
                status={model.verification.status}
                links={links}
            />
        </>
    );
};

export default renderVerification;
