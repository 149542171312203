import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = function () {
    handlerPopup('executorLimitsPopup', {
        isShow: false,
        executorId: undefined,
        executorName: undefined,
        limits: undefined,
        corporationId: undefined,
        corporationName: undefined,
    });
};

export default close;
