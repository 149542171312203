import I from '../types.ts';

const dbClickHandler: I['dbClickHandler'] = function (e) {
    const imageParent = this.parent.current!.parentNode as HTMLElement;
    const pageY = e.pageY - imageParent.getBoundingClientRect().y;
    const pageX = e.pageX - imageParent.getBoundingClientRect().x;
    const { diffHeight, diffWidth } = this.getSize();
    const percentY = pageY / imageParent.offsetHeight;
    const percentX = pageX / imageParent.offsetWidth;

    this.setState({ isZoom: !this.state.isZoom }, () => {
        const resultY = this.state.isZoom ? (percentY - 0.5) * diffHeight : 0;
        const resultX = this.state.isZoom ? (percentX - 0.5) * diffWidth : 0;

        this.moveY = -resultY;
        this.endY = -resultY;
        this.moveX = -resultX;
        this.endX = -resultX;

        this.imageMove();
    });
};

export default dbClickHandler;
