import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import TagsPopup from './TagsPopup.jsx';

class TagsPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { tagsPopup } = this.props;

        return (
            <Animate
                className="body__tagsPopup"
                isShow={tagsPopup?.isShow}
            >
                <TagsPopup />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        tagsPopup: state.tagsPopup,
    };
}

export default connect(mapStateToProps)(TagsPopupWrapper);

TagsPopupWrapper.propTypes = {
    tagsPopup: PropTypes.object,
};
