import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../Animate.jsx';
import VerificationPopup from './VerificationPopup.jsx';

class VerificationPopupWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { verificationPopup } = this.props;

        return (
            <Animate className="body__bonusPopup _blur" isShow={verificationPopup.isShow}>
                <VerificationPopup {...verificationPopup} />
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        verificationPopup: state.verificationPopup,
    };
}

export default connect(mapStateToProps)(VerificationPopupWrapper);

VerificationPopupWrapper.propTypes = {
    verificationPopup: PropTypes.object,
};
