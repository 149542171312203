import AlertI from '../../types';

const deleteUser = {
    title: 'Подтвердите <br />удаление сотрудника',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить сотрудника <b>${info}</b>?`;
    },
    buttonText: 'Удалить сотрудника',
} as const;

export default deleteUser;
