import React from 'react';

import Button from '@components/button/Button.tsx';
import Icon from '@components/icon/Icon.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import getPermissions from '@functions/crm/getPermissions.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import { CorporationLimitT, CorporationT } from '@global/models/Corporation.ts';

import I from '../types.ts';

const renderLimit = function ({ item }: { item: CorporationLimitT }) {
    return (
        <div className="v2widgetCardInfo__optionsBlock">
            <div className="v2widgetCardInfo__option _row">
                <div className="v2widgetCardInfo__optionText _support">Самозанятым</div>
                <div className="v2widgetCardInfo__optionText">{getFormatPrice(item.SMZ)} ₽</div>
            </div>
            <div className="v2widgetCardInfo__option _row">
                <div className="v2widgetCardInfo__optionText _support">Физ. лицам</div>
                <div className="v2widgetCardInfo__optionText">{getFormatPrice(item.FL)} ₽</div>
            </div>
            <div className="v2widgetCardInfo__option _row">
                <div className="v2widgetCardInfo__optionText _support">ИП</div>
                <div className="v2widgetCardInfo__optionText">{getFormatPrice(item.IP)} ₽</div>
            </div>
        </div>
    );
};

const renderLimitHead = function ({ item }: { item: CorporationLimitT & { remains: number } }) {
    return (
        <div className="v2widgetCardInfo__optionsBlock">
            <div className="v2widgetCardInfo__title">{getFormatPrice(item.all)} ₽</div>
            <div className="v2widgetCardInfo__description">
                Остаток: {getFormatPrice(item.remains)} ₽
            </div>
        </div>
    );
};

const renderLimits: I['renderLimits'] = function () {
    const { user, currentLimit, initedKey } = this.state;
    const { model, inner, setModel } = this.props;
    const isAdmin = user?.idOfCurrentCorporation === 'admin';
    const limits = [
        {
            key: currentLimit,
            ...model.limits[currentLimit],
            remains: model.balanceRemains[currentLimit],
        },
    ];
    const isMarginCard =
        (!inner ||
            !getPermissions(user, {
                key: 'corporations',
                items: [{ key: 'main', rules: ['update'] }],
            })) &&
        !model.isAdmin;

    const infoProps = setCursorSupport(
        {
            _id: `limits-info`,
            content:
                'Лимит — максимальная сумма, на которую <br/>компания может совершать денежные <br/>операции в установленный период',
            dir: 'top',
        },
        { targetClassName: '.v2widgetCardInfo__info', className: '' },
    );

    return (
        <>
            <div className="v2widgetCard _hidden">
                <div className="v2widgetCard__content _full">
                    <div className="v2widgetCardInfo _COL">
                        <i className="v2widgetCardInfo__info _CLICK" {...infoProps}>
                            <Icon name="info" />
                        </i>
                        <div className="v2widgetCardInfo__support">
                            Лимит{' '}
                            <label className="v2widgetCardInfo__supportTab">
                                <input
                                    type="checkbox"
                                    className="v2widgetCardInfo__supportTabInput"
                                    checked={currentLimit === 'month'}
                                    onChange={() => {
                                        this.setState({
                                            currentLimit: 'month',
                                        });
                                    }}
                                />
                                <div className="v2widgetCardInfo__supportTabContent">в месяц</div>
                            </label>
                            <span>{' / '}</span>
                            <label className="v2widgetCardInfo__supportTab">
                                <input
                                    type="checkbox"
                                    className="v2widgetCardInfo__supportTabInput"
                                    checked={currentLimit === 'year'}
                                    onChange={() => {
                                        this.setState({ currentLimit: 'year' });
                                    }}
                                />
                                <div className="v2widgetCardInfo__supportTabContent">в год</div>
                            </label>
                        </div>
                        <ListAbsoluteMain
                            className="v2widgetCardInfo__options"
                            items={limits}
                            renderItem={renderLimitHead}
                            classNameItem="v2widgetCardInfo__optionsBlock"
                            prop="key"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isNotParamsItem={true}
                            currentItemKey={currentLimit}
                            allItems={['month', 'year']}
                            keyUpdateItem={initedKey}
                        />
                        <div className="v2widgetCardInfo__content _dynamic">
                            <ListAbsoluteMain
                                className="v2widgetCardInfo__options"
                                items={limits}
                                renderItem={renderLimit}
                                classNameItem="v2widgetCardInfo__optionsBlock"
                                prop="key"
                                paramsParent={{ width: true }}
                                styles={['height']}
                                isNotParamsItem={true}
                                currentItemKey={currentLimit}
                                allItems={['month', 'year']}
                                classNames={isMarginCard ? ['_marginCard'] : []}
                                keyUpdateItem={initedKey}
                            />
                        </div>
                        {isAdmin &&
                            !model.isAdmin &&
                            getPermissions(user, {
                                key: 'corporations',
                                items: [
                                    {
                                        key: 'main',
                                        rules: ['update'],
                                    },
                                ],
                            }) && (
                                <div className="v2widgetCardInfo__foot _COL">
                                    <div className="v2widgetCardInfo__button _row _click">
                                        <Button
                                            className="_edit _blue"
                                            onClick={() => {
                                                handlerPopup('corporationEditPopup', {
                                                    isShow: true,
                                                    type: 'limits',
                                                    limitType: currentLimit,
                                                    corporation: model,
                                                    callback: (data: CorporationT) => {
                                                        setModel(data);
                                                        this.init({ fields: data });
                                                    },
                                                });
                                            }}
                                        >
                                            <i className="_limitPrev">
                                                <Icon name="corporation-limit" />
                                            </i>
                                            Редактировать лимиты
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderLimits;
