type UserT = {
    firstName?: string | null;
    secondName: string | null;
    thirdName?: string | null;
};

type ParamsT = { user?: UserT; type?: 'short' | 'double' };

export default function getUserName({ user, type }: ParamsT): string {
    const names: string[] = [];

    if (user) {
        if (type === 'short') {
            (['secondName', 'firstName'] as (keyof UserT)[]).forEach((key) => {
                if (user[key]) {
                    names.push(
                        key === 'secondName'
                            ? user[key]
                            : `${(user[key] as string)[0]?.toUpperCase()}.`,
                    );
                }
            });
        } else if (type === 'double') {
            (['secondName', 'firstName'] as (keyof UserT)[]).forEach((key) => {
                if (user[key]) {
                    names.push(user[key] as string);
                }
            });
        } else {
            (['secondName', 'firstName', 'thirdName'] as (keyof UserT)[]).forEach((key) => {
                if (user[key]) {
                    names.push(user[key] as string);
                }
            });
        }
    }

    return names.join(' ');
}
