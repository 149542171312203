const users = {
    colsOrder: ['name', 'email', 'status', 'role', 'proxy'],
    cols: {
        name: {
            content: 'ФИО',
            width: 25,
            isAdminMain: true,
        },
        email: {
            content: 'E-mail',
            width: 25,
        },
        status: {
            content: 'Статус',
            width: 17,
        },
        role: {
            content: 'Роль',
            width: 17,
        },
        proxy: {
            content: 'Доверенность',
            width: 16,
        },
    },
    adminIndex: 3,
    deletesCols: ['email'],
    url: 'user',
    emptyInfo: {
        title: 'Сотрудников пока нет',
        description: 'Они будут появляться здесь после регистрации',
    },
} as const;

export default users;
