import axios from 'axios';

import getHeaders from '../functions/getHeaders';
import setNotification from '../functions/setNotification';

export default function closeChat({ id }) {
    return new Promise((resolve) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/chat`,
                {
                    id,
                    action: 'close',
                },
                {
                    headers: getHeaders(),
                },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    resolve();

                    if (success) {
                        setNotification({ notification: 'success-close-chat' });
                    }
                },
                () => null,
            );
    });
}
