import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const close: I['close'] = function () {
    handlerPopup('walletPopup', {
        isShow: false,
        wallet: undefined,
        setModel: undefined,
        executorId: undefined,
    });
};

export default close;
