const joinsContracts = {
    colsOrder: ['name', 'userName', 'status', 'date'],
    cols: {
        name: {
            content: 'Название',
            width: 34,
        },
        userName: {
            content: 'ФИО',
            width: 36,
            isAdminMain: true,
        },
        status: {
            content: 'Статус',
            width: 15,
        },
        date: {
            content: 'Создан',
            width: 15,
        },
    },
    adminIndex: 1,
    deletesCols: ['status'],
    url: 'join-contract',
    emptyInfo: {
        title: 'Договоров пока нет',
        description: 'Вы можете создать договор по кнопке справа',
    },
} as const;

export default joinsContracts;
