import { CorporationT } from '@global/models/Corporation';

const corporationIntegrationTg = {
    title: 'Telegram',
    description: (model: CorporationT['integration']) =>
        model?.tgToken ? 'Подключен' : 'Не подключен',
    fieldsOrder: ['token'],
    widthPercent: true,
    fields: {
        token: {
            support: 'Токен',
            prop: 'tgToken',
            type: 'input',
            group: 1,
            width: 50,
        },
    },
} as const;

export default corporationIntegrationTg;
