const modelLogs = {
    colsOrder: ['content', 'user', 'date'],
    cols: {
        content: {
            content: 'Действие',
            width: 50,
            isAdminMain: true,
        },
        user: {
            content: 'Пользователь',
            width: 30,
        },
        date: {
            content: 'Дата и время',
            width: 20,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'model-logs',
    emptyInfo: {
        title: 'Логов пока нет',
        description: 'Они будут сохраняться здесь при различных изменениях',
    },
} as const;

export default modelLogs;
