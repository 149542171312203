import getAllNodes from './getAllNodes';

const actionsInfo = require('../../../infos/crm/editor.json');

export default function clearContent(content, paste) {
    const editor = document.querySelector('.editor__areaInner');
    const div = document.createElement('div');

    content = content.replace(/<!--[\s\S]+?-->/g, '');

    div.innerHTML = content;

    const metas = div.querySelectorAll('meta,link,style,*:empty:not(br):not(img)');

    metas.forEach((meta) => {
        if (meta) {
            meta.parentNode?.removeChild(meta);
        }
    });

    div.querySelectorAll('h1,h4,h5,h6').forEach((title) => {
        const titleWrapper = document.createElement('div');

        titleWrapper.innerHTML = `<b>${title.innerHTML}</b>`;

        title.replaceWith(titleWrapper);
    });

    div.querySelectorAll('*').forEach((elem) => {
        // elem.removeAttribute('class');
        elem.removeAttribute('title');

        if (!elem.getAttribute('data-indent')) {
            let width;

            if (elem.style.width && elem.style.width.includes('%')) {
                width = elem.style.width;
            }

            elem.removeAttribute('style');

            elem.style.width = width;
        }

        Object.keys(elem.attributes).forEach((key) => {
            const nameAttr = elem.attributes[key].nodeName;

            if (
                nameAttr.indexOf('data') !== -1 &&
                !['data-ancor', 'data-indent', 'src'].includes(nameAttr)
            ) {
                elem.removeAttribute(nameAttr);
            }
        });
    });

    div.querySelectorAll('p:empty').forEach((p) => {
        p.remove();
    });

    if (1) {
        div.querySelectorAll('p:not(:empty)').forEach((p) => {
            const wrapper = document.createElement('div');
            const pWrapper = document.createElement('div');
            const brDiv = document.createElement('div');

            brDiv.appendChild(document.createElement('br'));

            p.childNodes.forEach((child) => {
                pWrapper.appendChild(child.cloneNode(true));
            });

            wrapper.appendChild(pWrapper);
            wrapper.appendChild(brDiv);

            p.replaceWith(...wrapper.childNodes);
        });
    }

    div.querySelectorAll('*:not(img)').forEach((elem) => {
        if (!elem.getAttribute('data-indent')) {
            elem.style.padding = null;
        }

        elem.style.display = null;
        elem.style.margin = null;
        elem.style.textAlign = null;
        // elem.style.width = null;
        elem.style.height = null;
        elem.style.color = null;
        elem.style.fontSize = null;
        elem.style.fontStyle = null;
        elem.style.fontFamily = null;
        elem.style.fontWeight = null;
        elem.style.lineHeight = null;
        elem.style.background = null;
        elem.style.backgroundColor = null;
        elem.style.whiteSpace = null;

        if (elem.getAttribute('class')) {
            elem.getAttribute('class')
                .split(' ')
                .forEach((classItem) => {
                    if (
                        !Object.keys(actionsInfo.actions).find(
                            (key) => actionsInfo.actions[key].className === classItem,
                        ) &&
                        !['_gap', '_ancorBlock', '_pattern', '_DOC'].find((innerClass) =>
                            classItem.includes(innerClass),
                        )
                    ) {
                        elem.classList.remove(classItem);
                    }
                });
        }
    });

    div.querySelectorAll('a').forEach((elem) => {
        elem.removeAttribute('style');
    });

    if (1) {
        div.childNodes.forEach((childNode) => {
            if (childNode.nodeType === 3) {
                const wrapper = document.createElement('div');
                const clonedNode = childNode.cloneNode();

                wrapper.appendChild(clonedNode);

                childNode.replaceWith(wrapper);
            }
        });
    }

    if (!paste) {
        div.querySelectorAll('div').forEach((innerDiv) => {
            if (
                innerDiv.parentNode &&
                !innerDiv.parentNode.getAttribute('class') &&
                innerDiv.parentNode?.nodeName === 'DIV' &&
                innerDiv.parentNode !== editor
            ) {
                innerDiv.parentNode.replaceWith(...innerDiv.parentNode.childNodes);
            }
        });
    }

    if (!paste) {
        getAllNodes(div).forEach((textNode) => {
            let parent = textNode.parentNode;
            let className = '';

            while (
                parent &&
                parent.parentNode &&
                parent.parentNode !== div &&
                !parent.closest('._gap') &&
                !parent.closest('._pattern')
            ) {
                const savedParent = parent.parentNode;

                if (parent.nodeName === 'DIV') {
                    if (parent.getAttribute('class')) {
                        className += ' ';
                        className += parent.getAttribute('class');
                    }

                    parent.replaceWith(...parent.childNodes);
                }

                parent = savedParent;
            }

            if (parent && className) {
                // console.log(parent, className);

                className.split(' ').forEach((classNameItem) => {
                    if (classNameItem) {
                        parent.classList.add(classNameItem);
                    }
                });

                // console.log(className, parent.innerText);
            }
        });
    }

    if (1) {
        div.querySelectorAll('div,p,table,ul,ol,h2,h3').forEach((blockNode) => {
            ['b', 'i', 'span'].forEach((tag) => {
                let inlineParent = blockNode.closest(tag);

                while (inlineParent) {
                    getAllNodes(blockNode).forEach((textNode) => {
                        // console.log(textNode);
                        const innerWrapper = document.createElement(tag);

                        innerWrapper.appendChild(textNode.cloneNode());

                        textNode.replaceWith(innerWrapper);
                    });

                    inlineParent.replaceWith(...inlineParent.childNodes);

                    inlineParent = blockNode.closest(tag);
                }
            });
        });
    }

    if (!paste) {
        div.childNodes.forEach((childNode) => {
            if (childNode.nodeName === 'BR') {
                childNode.remove();
            } else if (!['div'].includes(childNode.nodeName.toLowerCase())) {
                const wrapper = document.createElement('div');

                if (['UL', 'OL'].includes(childNode.nodeName)) {
                    wrapper.appendChild(childNode.cloneNode(true));

                    childNode.replaceWith(wrapper);
                } else {
                    if (childNode.nodeType === 3) {
                        wrapper.appendChild(childNode.cloneNode(true));
                    } else {
                        childNode.childNodes.forEach((node) => {
                            wrapper.appendChild(node.cloneNode(true));
                        });
                    }

                    childNode.replaceWith(wrapper);
                }
            }
        });
    }

    div.querySelectorAll('b,i,span').forEach((inlineNode) => {
        if (inlineNode.querySelector('div,td')) {
            getAllNodes(inlineNode).forEach((textNode) => {
                const innerWrapper = document.createElement(inlineNode.tagName.toLowerCase());

                innerWrapper.appendChild(textNode.cloneNode());

                textNode.replaceWith(innerWrapper);
            });

            inlineNode.replaceWith(...inlineNode.childNodes);
        }
    });

    if (!div.innerText?.trim()) {
        const startDiv = document.createElement('div');
        const startNode = document.createTextNode('\u200b');

        startDiv.appendChild(startNode);
    }

    return div.innerHTML;
}
