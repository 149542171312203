import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const refreshDoc: I['refreshDoc'] = async function ({ pay, type }) {
    try {
        const response = await axios.patch<{}, { data: ReqResponseT }>(
            `${process.env.REACT_APP_API}/pays`,
            { id: pay._id, action: 'refresh-docs', type },
            { headers: getHeaders() },
        );
        const { success } = response.data;

        if (success) {
            setNotification({ notification: 'success-change-info' });

            return;
        }
    } catch (error) {}

    return Promise.reject();
};

export default refreshDoc;
