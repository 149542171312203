import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';
import getQuery from './methods/getQuery.ts';

import UsersI from './types.ts';

import deleteUser from './actions/deleteUser.ts';

class Users extends WidgetWrapper<UsersI['props'], UsersI['state']> implements UsersI {
    parent: UsersI['parent'];

    constructor(props: UsersI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getActions = getActions;
    getMoreActions = getMoreActions;
    getQuery = getQuery;

    deleteUser = deleteUser;

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <Widget
                    name="users"
                    title="Сотрудники"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    className="_white"
                    withBack={false}
                    inWidget={true}
                    filterQuery={(this as UsersI).getFilterQuery?.()}
                >
                    <Table
                        name="users"
                        isBlock={true}
                        isNotLink={true}
                        isInit={true}
                        inWidget={true}
                        init={this.tableInit!.bind(this)}
                        callback={this.tableCallback!.bind(this)}
                        otherData={{
                            getMoreActions: this.getMoreActions.bind(this),
                        }}
                        getQuery={this.getQuery!.bind(this)}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Users);
