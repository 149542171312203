import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import changePage from '../../../functions/changePage';
import deleteNotifications from '../../../functions/crm/deleteNotifications';
import downloadReq from '../../../functions/download';
import getEndText from '../../../functions/getEndText';
import getFormatedDate from '../../../functions/getFormatedDate';
import getPageLink from '../../../functions/getPageLink';
import getUserInfo from '../../../functions/getUserInfo';
import setSpacesInText from '../../../functions/setSpacesInText';
import shortText from '../../../functions/shortText';

import Button from '../../Button.jsx';
import Link from '../../Link.jsx';
import CorporationInfo from '../manual/CorporationInfo.jsx';

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    startNotifications = {
        checkJoinExecutor: {
            title: 'Подключен новый исполнитель',
            button: {
                content: 'Подключить',
                pageName: 'manual-executors-inner-docs',
            },
        },
        newChatMessage: {
            title: 'Новое сообщение!',
            button: {
                content: 'Ответить',
                pageName: 'chat-inner',
            },
        },
        pdfReady: {
            title: 'Генерация завершена',
            button: {
                content: 'Скачать файл',
            },
        },
        pdfError: {
            title: 'Ошибка генерации файла',
            button: {
                content: 'Попробовать позже',
            },
        },
        setChatResponsible: {
            title() {
                const { data } = this.props;

                return (
                    <>
                        Вас назначили ответственным
                        <br />
                        на&nbsp;
                        <Link
                            className="notification__titleLink"
                            pageName="chat-inner"
                            ids={{ 3: data?.chatId }}
                        >
                            чат
                        </Link>
                    </>
                );
            },
            button: {
                content: 'Взять в работу 🤝',
            },
        },
        disabledSignService: {
            title: 'Сервис подписания недоступен',
            description: 'Подписание банковских транзакций приостановлено',
            className: '_alert',
        },
    };

    getNotificationInfo() {
        const { name, data } = this.props;
        const notification = { ...this.startNotifications[name] };

        if (notification) {
            if (name === 'checkJoinExecutor') {
                notification.description = `<b>${getUserInfo({
                    user: data.executorInfo,
                    type: 'name',
                })}</b>, <span class="_noWrap">${data.executorInfo?.phone}</span>`;

                notification.button.ids = { 3: data.executorId };
            }

            if (name === 'newChatMessage') {
                const content = data.chatInfo?.content
                    ? shortText({ type: 'word', str: data.chatInfo.content, stop: 12 })
                    : data.chatInfo.files
                      ? `${data.chatInfo.files} ${getEndText(data.chatInfo.files, [
                            'файл',
                            'файла',
                            'файлов',
                        ])}`
                      : '';
                notification.description = `Обращение: ${content}`;

                notification.button.ids = { 3: data.chatId };
            }

            if (name === 'pdfReady') {
                notification.description = data.name;
                notification.button.downloadLink = data.fileName;
            }

            if (name === 'pdfError') {
                notification.description = 'Попробуйте ещё раз';
                notification.button.downloadLink = true;
                notification.className = '_alert';
            }

            return notification;
        }

        return {};
    }

    handerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    handlerButton() {
        const { _id, name, data, canUserDelete } = this.props;
        const notificationInfo = this.getNotificationInfo();

        if (canUserDelete) {
            this.handerLoading('loading').then(() => {
                const { id } = this.props;

                deleteNotifications({ id }).then(() => {
                    if (name === 'setChatResponsible') {
                        changePage({
                            href: getPageLink({
                                name: 'chat-inner',
                                ids: { 3: data.chatId },
                            }),
                        });
                    }

                    this.handerLoading(null);
                });
            });
        } else if (notificationInfo.button?.pageName) {
            const { pageName, ids } = notificationInfo.button;

            changePage({ href: getPageLink({ name: pageName, ids }) });
        } else if (notificationInfo.button?.downloadLink) {
            this.handerLoading('loading').then(() => {
                downloadReq({
                    files: [
                        {
                            src: data.fileName,
                            folder: 'corporations-docs',
                            name: data.name,
                            notificationId: _id,
                            pdfId: data.pdfId,
                            isCheckNotification: notificationInfo.button?.downloadLink === true,
                        },
                    ],
                }).then(
                    () => {
                        this.handerLoading(null);
                    },
                    () => null,
                );
            });
        }
    }

    render() {
        const { loadingKey } = this.state;
        const { createdDate, group, startIndex, corporationInfo } = this.props;
        const notificationInfo = this.getNotificationInfo();
        const isStart = group === 'start';

        return (
            <div
                ref={this.parent}
                className={`notification ${isStart ? '_forStart' : ''} ${
                    notificationInfo.className || ''
                }`}
            >
                <div className="notification__inner _col">
                    <div className="notification__support _ROW">
                        {isStart ? (
                            <>Шаг {startIndex}</>
                        ) : (
                            <>{getFormatedDate({ date: new Date(createdDate), type: 'full' })}</>
                        )}
                        {corporationInfo && corporationInfo[0] && (
                            <>
                                <div className="notification__supportCorporation">
                                    <CorporationInfo {...corporationInfo[0]} />
                                </div>
                            </>
                        )}
                    </div>
                    {typeof notificationInfo.title === 'function' ? (
                        <>
                            <div className="notification__title">
                                {notificationInfo.title.call(this)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className="notification__title"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(notificationInfo.title),
                                }}
                            ></div>
                        </>
                    )}

                    <p
                        className="notification__description"
                        dangerouslySetInnerHTML={{
                            __html: setSpacesInText(notificationInfo.description),
                        }}
                    ></p>
                    {notificationInfo.button && (
                        <div className="notification__button" onClick={() => this.handlerButton()}>
                            <Button
                                className="_crm _blue _normalSizeLoader"
                                showLoader={!!loadingKey}
                            >
                                {notificationInfo.button.content}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Notification);

Notification.propTypes = {
    user: PropTypes.object,
    _id: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    createdDate: PropTypes.string,
    data: PropTypes.object,
    group: PropTypes.string,
    startIndex: PropTypes.number,
    canUserDelete: PropTypes.bool,
    corporationInfo: PropTypes.array,
};
