import React, { MouseEvent } from 'react';

import { ListenerT } from '@global/types.ts';

import outClickHandler from './methods/outClickHandler.ts';

import PopupI from './types.ts';

class Popup extends React.Component<PopupI['props']> implements PopupI {
    parent: React.RefObject<HTMLDivElement>;
    sendHandler: PopupI['sendHandler'];

    constructor(props: PopupI['props']) {
        super(props);
        this.state = {};

        this.outClickHandler = this.outClickHandler.bind(this);

        this.parent = React.createRef();
    }

    outClickHandler = outClickHandler;

    componentDidMount(): void {
        const { sendHandler, checkSend, setRef } = this.props;

        if (setRef) {
            setRef(this.parent.current as HTMLElement);
        }

        if (sendHandler) {
            this.sendHandler = (e) => {
                if (e.which === 13 && (!checkSend || checkSend())) {
                    sendHandler();
                }
            };
            this.sendHandler = this.sendHandler.bind(this);
        }

        setTimeout(() => {
            (document.addEventListener as ListenerT<MouseEvent>)('click', this.outClickHandler);

            if (this.sendHandler) {
                (document.addEventListener as ListenerT<KeyboardEvent>)(
                    'keydown',
                    this.sendHandler,
                );
            }
        }, 10);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as ListenerT<MouseEvent>)('click', this.outClickHandler);

        if (this.sendHandler) {
            (document.removeEventListener as ListenerT<KeyboardEvent>)('keydown', this.sendHandler);
        }
    }

    render() {
        const { children, className = '', name } = this.props;

        return (
            <>
                <div ref={this.parent} className={`v2popup _NOSCROLL _COL _${name} ${className}`}>
                    <div className="v2popup__inner _COL">{children}</div>
                </div>
            </>
        );
    }
}

export default Popup;
