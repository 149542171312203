import React from 'react';
import { connect } from 'react-redux';

import inputHandler from './methods/inputHandler.ts';
import sendForm from './methods/sendForm.ts';

import AdvantagesI from './types.ts';

import renderBlocks from './renders/renderBlocks.tsx';
import renderCards from './renders/renderCards.tsx';
import renderForm from './renders/renderForm.tsx';
import renderFormField from './renders/renderFormField.tsx';
import blocks from './static/blocks.tsx';
import cards from './static/cards.ts';
import fields, { fieldsOrder } from './static/fields.ts';

class Advantages
    extends React.Component<AdvantagesI['props'], AdvantagesI['state']>
    implements AdvantagesI
{
    constructor(props: AdvantagesI['props']) {
        super(props);
        this.state = {
            fields: {},
        };
    }

    cards = cards;
    blocks = blocks;
    fields = fields;

    inputHandler = inputHandler;
    sendForm = sendForm;

    renderCards = renderCards;
    renderBlocks = renderBlocks;
    renderForm = renderForm;
    renderFormField = renderFormField;

    componentDidMount(): void {
        const stateFields: AdvantagesI['state']['fields'] = {};

        fieldsOrder.forEach((key) => {
            stateFields[key] = '';
        });

        this.setState({ fields: stateFields });
    }

    render() {
        return (
            <div className="publicBusinessAdvantages _SECTION">
                <div className="publicBusinessAdvantages__inner _INNER _PUBLIC">
                    <div className="publicBusinessAdvantages__head _COL">
                        <div className="publicBusinessAdvantages__title _TITLE _PUBLIC">
                            Преимущества
                            <br />
                            <span>LIVECARGO CRM</span>
                        </div>
                        <div className="publicBusinessAdvantages__description _TEXT _PUBLIC">
                            От собственного приложения —<br />
                            до массовых выплат исполнителям.
                        </div>
                    </div>
                    {this.renderCards()}
                    {this.renderBlocks()}
                </div>
                {this.renderForm()}
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Advantages);
