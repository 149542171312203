const executors = {
    colsOrder: ['fullname', 'organization', 'type', 'phone', 'balance', 'status', 'contract'],
    cols: {
        status: {
            content: 'Статус',
            width: 13.5,
        },
        type: {
            content: 'Категория',
            width: 11.5,
        },
        fullname: {
            content: 'ФИО',
            width: 26.5,
            isAdminMain: true,
        },
        balance: {
            content: 'Баланс',
            width: 10,
        },
        organization: {
            content: 'ОПФ',
            width: 6.5,
        },
        phone: {
            content: 'Телефон',
            width: 14,
        },
        contract: {
            content: 'Договор',
            width: 18,
        },
    },
    adminIndex: 4,
    deletesCols: ['phone'],
    url: 'executor',
    emptyInfo: {
        title: 'Исполнителей пока нет',
        description: 'Вы можете добавить нового по кнопке справа',
    },
} as const;

export default executors;
