import { dispatcher, store } from '../redux/redux.ts';
import checkSeoMode from './checkSeoMode';
import removeTransition from './removeTransition.ts';

const isSeoMode = checkSeoMode();

const mediaM = process.env.REACT_APP_SYSTEM === 'site' ? 1100 : 500;

function setWindowParams() {
    const div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    window.widthValue = document.documentElement.clientWidth - scrollWidth;
    window.heightValue = window.innerHeight;
}

function resize(isStart) {
    setWindowParams();

    if (isStart) {
        if (document.documentElement.clientWidth > mediaM || isSeoMode) {
            dispatcher({ type: 'device', data: 'desktop' });
        } else {
            dispatcher({ type: 'device', data: 'mobile' });
        }
    }

    if (window.widthPrevValue !== window.widthValue) {
        document.dispatchEvent(new CustomEvent('changeWidthWindow'));

        removeTransition({});
    }

    if (window.heightPrevValue !== window.heightValue) {
        removeTransition({
            item: '.widget__content,.JSTransVertical,.body__page._app,.body__content',
            isCurrent: true,
        });

        if (!store.getState().isInputFocus) {
            dispatcher({ type: 'windowHeight', data: window.heightValue }).then(() => {
                document.dispatchEvent(new CustomEvent('changeHeightWindow'));
            });
        } else {
            // document.dispatchEvent(new CustomEvent('changeHeightWindow'));
        }
    }

    if (!isStart) {
        if (window.widthPrevValue > mediaM && window.widthValue <= mediaM) {
            document.dispatchEvent(
                new CustomEvent('stateResize', {
                    detail: { type: 'mobile' },
                }),
            );
            dispatcher({ type: 'device', data: 'mobile' });
        }
        if (window.widthPrevValue <= mediaM && window.widthValue > mediaM) {
            document.dispatchEvent(
                new CustomEvent('stateResize', {
                    detail: { type: 'desktop' },
                }),
            );
            dispatcher({ type: 'device', data: 'desktop' });
        }
    }

    window.widthPrevValue = window.widthValue;
    window.heightPrevValue = window.heightValue;
}

export default resize;
