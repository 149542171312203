import React from 'react';
import { connect } from 'react-redux';

import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import getBlocks from './methods/getBlocks.ts';
import getQuery from './methods/getQuery.ts';
import sign from './methods/sign.ts';

import PaySignI from './types.ts';

import renderBlock from './renders/renderBlock.tsx';
import renderBlocks from './renders/renderBlocks.tsx';
import renderContent from './renders/renderContent.tsx';

class PaySign extends WidgetWrapper<PaySignI['props'], PaySignI['state']> implements PaySignI {
    parent: PaySignI['parent'];

    constructor(props: PaySignI['props']) {
        super(props);
        this.state = {
            currentBlock: 'items',
        };

        this.parent = React.createRef();
    }

    blocks = ['items', 'code'];

    close = close;
    getBlocks = getBlocks;
    getQuery = getQuery;
    sign = sign;

    renderContent = renderContent;
    renderBlocks = renderBlocks;
    renderBlock = renderBlock;

    render() {
        return (
            <>
                <Popup name="paySignPopup" close={this.close.bind(this)}>
                    <>{this.renderContent()}</>
                </Popup>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        paySignPopup: state.paySignPopup,
    };
}

export default connect(mapStateToProps)(PaySign);
