import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';

import DocsI from './types.ts';

class Docs extends WidgetWrapper<DocsI['props'], DocsI['state']> implements DocsI {
    constructor(props: DocsI['props']) {
        super(props);
        this.state = {
            counter: 0,
        };
    }

    getActions = getActions;
    getMoreActions = getMoreActions;

    render() {
        const { counter } = this.state;

        return (
            <>
                <Widget
                    name="docs"
                    title="Справочник"
                    description="Документы"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as DocsI).getFilterQuery?.()}
                >
                    <Table
                        name="docs"
                        isInit={true}
                        isNotLink={true}
                        callback={this.tableCallback!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        getRowClassName={() => '_colCenter'}
                        otherData={{
                            getMoreActions: this.getMoreActions.bind(this),
                        }}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Docs);
