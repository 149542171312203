import React from 'react';

import Link from '@components/link/Link.tsx';
import getEndText from '@functions/getEndText.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import RoleT from '@global/models/Role.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderCorporationRoles: RenderColsT<'corporationRoles'> = function ({ name: colName, item }) {
    const role = item as RoleT;
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(role.name) }}
                ></div>
            </>
        );
    }

    if (colName === 'usersCounter') {
        content = (
            <>
                <div className="v2table__text">
                    {role.usersCounter || 0}{' '}
                    {getEndText(role.usersCounter || 0, ['сотрудник', 'сотрудника', 'сотрудников'])}
                </div>
            </>
        );
    }

    if (colName === 'actions') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            role: RoleT;
        }) => CursorActionsItemT[];

        content = (
            <>
                {role._id === 'holder' ? (
                    <div className="v2table__text _grey">Системная роль</div>
                ) : (
                    <>
                        <More
                            id={`role-${role._id}`}
                            items={getMoreActions({ role })}
                            stopPropagation={true}
                        />
                        <Link
                            className="v2table__text _link _full _CLICK"
                            pageName="settings-roles-inner"
                            ids={{ 3: role._id }}
                        >
                            Настроить роль
                        </Link>
                    </>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderCorporationRoles;
