import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const taxHandler: I['taxHandler'] = async function ({ executorTax, type }) {
    const response = await axios.patch<{}, { data: ReqResponseT<{ message: string }> }>(
        `${process.env.REACT_APP_API}/executor`,
        { id: executorTax._id, action: 'handler-tax', type: type || 'inactive' },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        await this.tableUpdateItems!.call(this);

        return;
    }

    const { message } = data;

    if (message === 'Executor has tax pay') {
        setNotification({ notification: 'executor-has-tax-pay' });
    } else if (message === 'Nothing for withdraw') {
        setNotification({ notification: 'executor-tax-nothing-withdraw' });
    } else if (message === 'Tax not withdraw') {
        setNotification({ notification: 'executor-tax-not-withdraw' });
    }

    return Promise.reject();
};

export default taxHandler;
