import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function ({ executorTax }) {
    const { user } = this.state;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'executorTax',
                    modelId: executorTax._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    if (
        executorTax.fnsTax === 'active' &&
        getPermissions(user, {
            adminCorporation: true,
            key: 'pays',
            items: [{ key: 'tax', rules: [], actions: ['setInactive'] }],
        })
    ) {
        actions.unshift({
            key: 'remove',
            name: 'removeTax',
            onClick: async () => {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'removeTax',
                    info: executorTax.fullName,
                    callback: this.taxHandler.bind(this, { executorTax }),
                });
            },
            text: 'Отключить',
        });
    }

    if (
        executorTax.totalAmount > 0 &&
        !['withdraw-process', 'inactive-process'].includes(executorTax.fnsTax) &&
        getPermissions(user, {
            adminCorporation: true,
            key: 'pays',
            items: [{ key: 'tax', rules: [], actions: ['withdrawAmount'] }],
        })
    ) {
        actions.unshift({
            key: 'withdrawAmount',
            name: 'withdrawAmount',
            onClick: async () => {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'withdrawTax',
                    info: executorTax.fullName,
                    callback: this.taxHandler.bind(this, { executorTax, type: 'withdraw' }),
                });
            },
            text: 'Вывести средства',
        });
    }

    return actions;
};

export default getMoreActions;
