import React from 'react';

import Button from '@components/button/Button';
import CodeInputs from '@components/codeInputs/CodeInputs';
import Icon from '@components/icon/Icon';
import LoaderBlock from '@components/loaderBlock/LoaderBlock';
import getFormatPrice from '@functions/getFormatPrice';

import PaySignI from '../../types';

export default function renderCode(this: PaySignI): React.ReactNode {
    const { payGroup, code, loadingKey } = this.state;

    return (
        <>
            <div className="v2popup__description _bottomLine">
                Мы отправили смс-код на ваш номер
                <br />
                телефона — введите код в поле ниже
            </div>
            <div className="v2popupSign">
                <div className="v2popupSign__codes _ROW">
                    <div className="v2popupSign__codesBox">
                        <CodeInputs
                            count={6}
                            callback={(resultCode) => {
                                this.setState({ code: resultCode });
                            }}
                        />
                    </div>
                    <div className="v2popupSign__codesInfo _COL">
                        <div className="v2popupSign__codesSupport">Код из смс</div>
                        <div
                            className="v2popupSign__codesBtn _CLICK"
                            onClick={this.sign.bind(this, 'again')}
                        >
                            Отправить повторно
                            <LoaderBlock
                                className="v2popupSign__codesBtnLoader _FULL"
                                isShow={loadingKey === 'items'}
                            />
                        </div>
                    </div>
                </div>
                <div className="v2popupSign__foot _COL">
                    <div className="v2popupSign__button">
                        <Button
                            className="_main"
                            disabled={code?.length !== 6}
                            loading={loadingKey === 'code'}
                            onClick={this.sign.bind(this, 'send')}
                        >
                            Оплатить {getFormatPrice(+payGroup!.amount.toFixed(2))} ₽
                            <i className="_arrowNext">
                                <Icon name="arrow-next" />
                            </i>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
