import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { corporationEditPopup } = this.props;
    const { type, limitType, corporation } = corporationEditPopup;
    const model: NonNullable<I['state']['model']> = {
        _id: corporation?._id,
        name: corporation?.fullName || corporation?.opfName,
    } as NonNullable<I['state']['model']>;

    if (type === 'tariff') {
        model.percent = corporation?.tariff.pay.percent.toString();
        model.min = corporation?.tariff.pay.min.toString();
    } else if (type === 'limits') {
        model.all = corporation?.limits[limitType!].all.toString();
        model.SMZ = corporation?.limits[limitType!].SMZ.toString();
        model.FL = corporation?.limits[limitType!].FL.toString();
        model.IP = corporation?.limits[limitType!].IP.toString();
    }

    this.setState({ type, limitType });

    this.init({ fields: model });
};

export default mountHandler;
