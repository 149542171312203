import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from './Field.jsx';
import Animate from './Animate.jsx';

import getWidthText from '../functions/getWidthText';
import AnimateChange from './AnimateChange.jsx';

import { checkValidate } from '../functions/inputValidate';
import sortForAlphabet from '../functions/sortForAlphabet';
import Icon from './Icon.jsx';
import Search from './Search.jsx';
import getEndText from '../functions/getEndText';
import handlerPopup from '../functions/app/handlerPopup';

const infoSelects = require('../infos/selects.json');
const infoSelectsInputs = require('../infos/selectsInputs.json');

class Select extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
        };

        this.handlerDrop = this.handlerDrop.bind(this);
        this.handlerMissClick = this.handlerMissClick.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.filterListItem = this.filterListItem.bind(this);
        this.filterSearchItem = this.filterSearchItem.bind(this);

        this.parent = React.createRef();
    }

    getProp(key) {
        const { type, serverData, inServerData } = this.props;
        const currentInfo = infoSelects[type];

        // console.log(currentInfo, type);

        let prop = this.props[key] || currentInfo?.[key];

        if (inServerData && key === 'items') {
            prop = serverData[type] || [];
        }

        if (
            prop &&
            key === 'items' &&
            ['executorTowns', 'executorPassportCountries'].includes(type)
        ) {
            prop = sortForAlphabet({ arr: prop, prop: 'content' }).sort((a, b) => {
                const weightA = a.key === 'other' ? 0 : 1;
                const weightB = b.key === 'other' ? 0 : 1;

                return weightB - weightA;
            });
        }

        return prop;
    }

    getCurrent() {
        const { value, propItem = 'key' } = this.props;
        const items = this.getProp('items');

        return items?.find((item) => item[propItem] === value);
    }

    handlerChoice({ action, [this.props.propItem || 'key']: value, name = this.props.name }) {
        const isMulti = this.getProp('isMulti');
        const { propsForResponse = {} } = this.props;
        const isDisabled = this.getDisabled();

        return new Promise((resolve) => {
            const { callback } = this.props;

            if (
                action === 'change' &&
                value !== this.props.value &&
                !isDisabled &&
                callback &&
                typeof callback === 'function'
            ) {
                callback({ action, name, value, ...propsForResponse }).then(() => {
                    if (!isMulti) {
                        this.handlerDrop(false);
                    }

                    resolve();
                });
            }
        });
    }

    renderCurrent() {
        const { device, type, isDisabled, id, isEditmode, className, counterSupports } = this.props;
        const isMulti = this.getProp('isMulti');
        const items = this.getProp('items');
        const currentItem = this.getCurrent();
        const infoSelect = infoSelects[type];
        const support = (type && infoSelect?.support) || this.props.support;
        const currentContent = currentItem?.content || support;
        let result = currentContent;

        if (isMulti) {
            if (this.props.value && this.props.value.length) {
                if (counterSupports) {
                    result = `${this.props.value.length} ${getEndText(
                        this.props.value.length,
                        counterSupports,
                    )}`;
                } else {
                    const sortValue = sortForAlphabet({ arr: this.props.value });

                    result = sortValue
                        .map(
                            (item) =>
                                items?.find((itemLoop) => itemLoop.key === item.name)?.content,
                        )
                        .join(', ');
                }
            } else {
                result = support;
            }
        }

        if (currentItem) {
            const { otherField } = this.props;
            const { type: typeItem } = currentItem;

            if (typeItem === 'times') {
                result = (
                    <div
                        className={`select__times _row ${this.checkComplete() ? '_complete' : ''}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {infoSelect.inputs.map((input) => {
                            const infoSelectInputs = infoSelectsInputs[input];
                            const { support: supportInput } = infoSelectInputs;
                            const widthOfInput = getWidthText({
                                text: otherField[input],
                                size: device === 'mobile' ? 18 : 14,
                                isInput: true,
                            });

                            return (
                                <div className="select__timesItem _row" key={input}>
                                    <div className="select__timesSupport">{supportInput}</div>
                                    <div
                                        className="select__timesInput"
                                        style={{ width: `${widthOfInput}px` }}
                                    >
                                        <Field
                                            keyName="time"
                                            name={input}
                                            support=""
                                            value={otherField[input]}
                                            callback={({ action, value, name }) =>
                                                this.handlerChoice({ action, key: value, name })
                                            }
                                            group={`${input}-${id || 'select'}`}
                                            className={`_select`}
                                            isShowTemplate={true}
                                            setStateComplete={this.checkComplete()}
                                            isDisabled={isDisabled}
                                            isEditmode={isEditmode}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            }

            if (typeItem === 'input') {
                result = (
                    <div className="select__input" onClick={(e) => e.stopPropagation()}>
                        <Field
                            keyName="date"
                            name={otherField.keyInput}
                            id={id}
                            support=""
                            value={otherField.date}
                            callback={({ action, value, name }) =>
                                this.handlerChoice({ action, key: value, name })
                            }
                            group="select"
                            className={`${className} _inSelect`}
                            isShowTemplate={true}
                            isDisabled={isDisabled}
                            isEditmode={isEditmode}
                        />
                    </div>
                );
            }
        }

        if (items?.find((item) => item.color) && currentItem) {
            return (
                <>
                    <div className="select__color _col">
                        <div
                            className="select__colorInner"
                            style={{ background: currentItem?.color }}
                        />
                    </div>
                </>
            );
        }

        return result;
    }

    handlerDrop(isActive = !this.state.isActive) {
        const { items, dropCallback } = this.props;
        const isDisabled = this.getDisabled();

        if (
            (!items || items.filter(this.filterListItem).length > 0) &&
            (isDisabled === undefined || !isDisabled)
        ) {
            this.setState({ isActive }, () => {
                if (dropCallback) {
                    dropCallback({ isActive });
                }

                setTimeout(() => {
                    if (isActive) {
                        this.setHeightDrop();
                    } else {
                        this.setHeightDrop(0);
                    }
                }, 50);
            });
        }
    }

    filterListItem(item) {
        const { hideCurrentItemInList, propItem = 'key', value } = this.props;

        return !hideCurrentItemInList || item[propItem] !== value;
    }

    getDisabled() {
        const { items } = this.props;

        let isDisabled = this.props.isDisabled;

        if (items && items.filter(this.filterListItem).length < 1) {
            isDisabled = undefined;
        }

        return isDisabled;
    }

    getHeightDrop() {
        if (this.parent.current) {
            const drop = this.parent.current.querySelector('.select__drop');

            if (drop) {
                return drop.offsetHeight;
            }
        }

        return 0;
    }

    setHeightDrop(heightDrop = this.getHeightDrop()) {
        if (heightDrop !== this.state.heightDrop) {
            this.setState({ heightDrop });
        }
    }

    checkComplete() {
        const { type, value } = this.props;
        const isMulti = this.getProp('isMulti');

        if (type === 'times') {
            const { otherField } = this.props;
            const { timeStart, timeEnd } = otherField;

            if (value === 'any') {
                return true;
            }

            if (timeStart && timeEnd) {
                return (
                    checkValidate({ value: timeStart, type: 'time' }) &&
                    checkValidate({ value: timeEnd, type: 'time' })
                );
            }

            return false;
        }

        if (isMulti) {
            return value && value.length > 0;
        }

        return !!value;
    }

    checkChangeComplete() {
        const { setStateComplete } = this.props;

        if (
            setStateComplete &&
            typeof setStateComplete === 'function' &&
            this.isComplete !== this.checkComplete()
        ) {
            this.isComplete = this.checkComplete();
            setStateComplete(this.isComplete);
        }
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ search: value }, resolve);
            }
        });
    }

    filterSearchItem(item) {
        const { search } = this.state;
        const { withSearch } = this.props;

        return (
            !withSearch ||
            !search ||
            search.length < 2 ||
            item.content.toLowerCase().includes(search.toLowerCase())
        );
    }

    handlerMissClick(e) {
        const { isActive } = this.state;
        const { target } = e;

        if (isActive && this.parent.current !== target && !this.parent.current.contains(target)) {
            this.handlerDrop(false);
        }
    }

    componentDidMount() {
        this.setHeightDrop();
        this.checkChangeComplete();

        document.addEventListener('mousedown', this.handlerMissClick);
    }

    componentDidUpdate() {
        this.checkChangeComplete();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handlerMissClick);
    }

    render() {
        const { isActive, heightDrop, search = '' } = this.state;
        const {
            className,
            supportView,
            isStatic,
            setStateComplete,
            error,
            value,
            isEditmode,
            propItem = 'key',
            notItems,
            withSearch,
            type,
            data,
        } = this.props;

        const isMulti = this.getProp('isMulti');
        const items = this.getProp('items')?.filter(
            (item) =>
                !notItems || !notItems.find((notItem) => notItem[propItem] === item[propItem]),
        );
        const filteredItems = items?.filter(this.filterSearchItem);
        const currentItem = this.getCurrent();
        const isEmpty = isMulti ? !value || value.length === 0 : !currentItem;
        const isDisabled = this.getDisabled();
        const condForList = items?.filter(this.filterListItem).length > 0;
        const DropWrap = isStatic ? 'div' : React.Fragment;
        const props = isStatic
            ? {
                  className: 'select__dropWrap',
                  style: isStatic ? { height: `${heightDrop}px` } : {},
              }
            : {};
        const propUpdate = isMulti ? this.renderCurrent() : value;

        return (
            <div
                ref={this.parent}
                className={`select ${isEmpty ? '_empty' : ''} ${className || ``} ${
                    isActive ? '_active' : ''
                } ${isDisabled ? '_disabled' : ''} ${
                    isDisabled !== undefined && !isDisabled ? '_isEdit' : ''
                } ${!condForList ? '_emptyList' : ''} ${setStateComplete ? '_checkComplete' : ''} ${
                    this.checkComplete() ? '_complete' : ''
                } ${error ? '_error' : ''} ${isEditmode ? '_editmode' : ''}`}
            >
                <div
                    className="select__view _row _click"
                    onClick={() => {
                        if (!isDisabled) {
                            if (type === 'executorTowns') {
                                if (process.env.REACT_APP_SYSTEM === 'crm') {
                                    handlerPopup({
                                        isShow: true,
                                        name: 'listPopup',
                                        currentName: 'towns',
                                        currentId: value,
                                        callback: (newValue) => {
                                            this.handlerChoice({
                                                action: 'change',
                                                key: newValue,
                                            });
                                        },
                                    });
                                } else {
                                    handlerPopup({
                                        name: 'appTownsPopup',
                                        isShow: true,
                                        currentId: value,
                                        callback: (newValue) => {
                                            this.handlerChoice({
                                                action: 'change',
                                                key: newValue,
                                            });
                                        },
                                    });
                                }
                            } else if (type === 'executorPassportCountries') {
                                if (process.env.REACT_APP_SYSTEM === 'crm') {
                                    handlerPopup({
                                        isShow: true,
                                        name: 'listPopup',
                                        currentName: 'countries',
                                        currentId: value,
                                        callback: (newValue) => {
                                            this.handlerChoice({
                                                action: 'change',
                                                key: newValue,
                                            });
                                        },
                                    });
                                } else {
                                    handlerPopup({
                                        name: 'appCountriesPopup',
                                        isShow: true,
                                        currentId: value,
                                        callback: (newValue) => {
                                            this.handlerChoice({
                                                action: 'change',
                                                key: newValue,
                                            });
                                        },
                                        data,
                                    });
                                }
                            } else if (items?.length) {
                                this.handlerDrop();
                            }
                        }
                    }}
                >
                    {supportView}
                    <AnimateChange
                        prop={propUpdate}
                        className="select__viewInner _row"
                        type="_translateMedium"
                    >
                        {this.renderCurrent()}
                    </AnimateChange>
                </div>
                {!isDisabled && condForList && (
                    <DropWrap {...props}>
                        <Animate
                            className={`select__drop ${filteredItems.length === 0 ? '_empty' : ''}`}
                            isShow={isActive}
                            actionInit={() => {
                                clearTimeout(this.clearSearchTimerId);
                            }}
                            actionPrevRemove={() => {
                                this.clearSearchTimerId = setTimeout(() => {
                                    this.setState({ search: '' });
                                }, 300);
                            }}
                        >
                            {withSearch && (
                                <div className="select__dropSelect">
                                    <Search
                                        support="Поиск"
                                        className="_select"
                                        callback={this.handlerSearch}
                                        value={search}
                                    />
                                </div>
                            )}
                            <div className="select__dropInner">
                                {filteredItems.map((item, key) => {
                                    let isCurrent = item[propItem] === value;

                                    if (isMulti) {
                                        isCurrent = value?.find(
                                            (valueItem) => valueItem.name === item[propItem],
                                        );
                                    }

                                    return (
                                        <div
                                            className={`select__dropItem _col _click ${
                                                isCurrent ? '_current' : ''
                                            }`}
                                            key={key}
                                            onClick={() =>
                                                this.handlerChoice({
                                                    action: 'change',
                                                    ...item,
                                                })
                                            }
                                        >
                                            {item.color && (
                                                <div className="select__color _col">
                                                    <div
                                                        className="select__colorInner"
                                                        style={{ background: item.color }}
                                                    ></div>
                                                </div>
                                            )}
                                            {item.content}
                                            <Animate
                                                className="select__dropItemCheck"
                                                isShow={isCurrent}
                                            >
                                                <Icon name="done" />
                                            </Animate>
                                        </div>
                                    );
                                })}
                            </div>
                        </Animate>
                    </DropWrap>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        serverData: state.serverData,
    };
}

export default connect(mapStateToProps)(Select);

Select.propTypes = {
    type: PropTypes.string,
    items: PropTypes.array,
    notItems: PropTypes.array,
    name: PropTypes.string,
    keyName: PropTypes.string,
    value: PropTypes.string,
    support: PropTypes.string,
    className: PropTypes.string,
    callback: PropTypes.func,
    otherField: PropTypes.object,
    isDisabled: PropTypes.bool,
    supportView: PropTypes.any,
    propItem: PropTypes.string,
    hideCurrentItemInList: PropTypes.bool,
    isStatic: PropTypes.bool,
    device: PropTypes.string,
    error: PropTypes.string,
    setStateComplete: PropTypes.any,
    isMulti: PropTypes.bool,
    id: PropTypes.string,
    isEditmode: PropTypes.string,
    propsForResponse: PropTypes.object,
    withSearch: PropTypes.bool,
    counterSupports: PropTypes.bool,
    inServerData: PropTypes.bool,
    serverData: PropTypes.object,
    dropCallback: PropTypes.func,
    data: PropTypes.object,
};
