import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import getEndText from '@functions/getEndText.ts';
import getFormatPrice from '@functions/getFormatPrice.ts';
import PayT from '@global/models/Pay.ts';

import MainI from '../types.ts';

const renderHeadTitle: MainI['renderHeadTitle'] = function () {
    const { items, currents = [] } = this.state;
    let payGroup = { ...this.state.payGroup } as MainI['state']['payGroup'];

    const savedPayGroup = { ...payGroup };
    let commission = savedPayGroup.commission || 0;

    if (currents.length) {
        const filteredItems = currents
            .map(({ id }) => items.find((item) => item._id === id))
            .filter((item) => item) as PayT[];

        commission = filteredItems.reduce((prev, cur) => prev + (cur.beforeCommission || 0), 0);

        payGroup = {
            amount: filteredItems.reduce((prev, cur) => prev + cur.amount, 0),
            commission,
            counter: currents.length,
            completedCounter: filteredItems.filter((item) => item.status === 'completed').length,
            errorCounter: filteredItems.filter((item) => ['error', 'failed'].includes(item.status))
                .length,
            type: savedPayGroup.type!,
        } as MainI['state']['payGroup'];
    }

    const amount = +((payGroup?.amount || 0) + commission).toFixed(2);

    return (
        <>
            <div className="v2widget__headData _col _ready">
                <Animate className="v2widget__headDataLoader _LOADER" isShow={!this.state.payGroup}>
                    <div className="v2widget__headDataLoaderTitle _LOADERWAIT" />
                    <div className="v2widget__headDataLoaderDescription _LOADERWAIT" />
                </Animate>
                <AnimateChange
                    className="v2widget__headDataInner _dynamic"
                    renderKey={payGroup ? payGroup.counter : undefined}
                    styles={['width']}
                >
                    <>
                        <div className="v2widget__headDataTitle">
                            <>
                                <b>
                                    {getFormatPrice(payGroup?.counter)}{' '}
                                    {getEndText(payGroup?.counter || 0, ['акт', 'акта', 'актов'])}
                                </b>{' '}
                                {getFormatPrice(+amount?.toFixed(2))} ₽
                                {commission ? (
                                    <>, ком. {getFormatPrice(+commission?.toFixed(2))} ₽</>
                                ) : (
                                    <></>
                                )}
                            </>
                        </div>
                        <div className="v2widget__headDataDescription _row">
                            <div className="v2widget__headDataDescriptionItem _completed">
                                Оплачено: {getFormatPrice(payGroup?.completedCounter)} из{' '}
                                {getFormatPrice(payGroup?.counter)}
                            </div>
                            <div className="v2widget__headDataDescriptionItem _error">
                                Ошибки: {getFormatPrice(payGroup?.errorCounter)}
                            </div>
                        </div>
                    </>
                </AnimateChange>
            </div>
        </>
    );
};

export default renderHeadTitle;
