import I from '../types.ts';

const eventStartHandler: I['eventStartHandler'] = function (e) {
    if (!this.isMove && e.target === this.getPinBtn()) {
        this.isMove = true;

        this.startX = e.pageX;
        this.startY = e.pageY;

        this.setState({ isMove: true });
    }
};

export default eventStartHandler;
