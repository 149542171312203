import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function (this: InvitesI, { template }) {
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'chatTemplate',
                    modelId: template._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    return actions;
};

export default getMoreActions;
