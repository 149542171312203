import React from 'react';

import Inner from '../components/inner/Inner';
import Main from '../components/main/Main';

import I from '../types';

const pages = {
    'settings-roles-main': {
        render(this: I) {
            return (
                <>
                    <Main />
                </>
            );
        },
    },
    'settings-roles-inner': {
        render(this: I) {
            return (
                <>
                    <Inner />
                </>
            );
        },
    },
} as const;

export default pages;
