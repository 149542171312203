import React from 'react';

import setSpacesInText from '@functions/setSpacesInText';

import BusinessAdvantagesI from '../types.ts';

const renderCards: BusinessAdvantagesI['renderCards'] = function () {
    return (
        <div className="publicBusinessAdvantages__cards">
            {this.cards.map((card, cardKey) => {
                const { icon, title, content, support } = card;

                return (
                    <div
                        className={`publicBusinessAdvantages__cardWrapper _COL _${cardKey + 1}`}
                        key={cardKey}
                    >
                        <div className={`publicBusinessAdvantages__card _COL`}>
                            <img
                                src={require(
                                    `../../../../../../../img/crm/public/business/${icon}`,
                                )}
                                alt=""
                                className="publicBusinessAdvantages__cardIcon"
                            />
                            <div
                                className="publicBusinessAdvantages__cardTitle"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(title),
                                }}
                            ></div>
                            <div
                                className="publicBusinessAdvantages__cardText"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(content),
                                }}
                            ></div>
                            {support && (
                                <div
                                    className="publicBusinessAdvantages__cardSupport"
                                    dangerouslySetInnerHTML={{
                                        __html: setSpacesInText(support),
                                    }}
                                ></div>
                            )}

                            {cardKey === 1 && (
                                <a
                                    href=""
                                    className="publicBusinessAdvantages__cardLink _CLICK"
                                    target="_blank"
                                >
                                    Смотреть на сайте ФНС
                                </a>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default renderCards;
