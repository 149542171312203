type ParamsT = {
    name: string;
    data: string;
    type?: string;
};

export default function downloadFile({ name, data, type = 'application/pdf' }: ParamsT): void {
    const link = document.createElement('a');

    link.style.opacity = '0';
    link.style.position = 'fixed';
    link.style.top = '0';
    link.style.left = '0';

    document.body.appendChild(link);

    // const binary = atob(data.replace(/\s/g, ''));
    // const len = binary.length;
    // const buffer = new ArrayBuffer(len);
    // const view = new Uint8Array(buffer);

    // for (let i = 0; i < len; i += 1) {
    //     view[i] = binary.charCodeAt(i);
    // }

    const file = new Blob([data], { type });
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = name;

    link.addEventListener('click', (e) => {
        e.stopPropagation();
    });

    link.click();

    document.body.removeChild(link);
}
