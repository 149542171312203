import I from '../types.ts';

const setUpdatedKeys: I['setUpdatedKeys'] = function ({ keys }) {
    this.setState((state) => {
        const newState = { ...state };
        const updatedKeys = { ...newState.updatedKeys };

        keys.forEach((key) => {
            updatedKeys[key] = new Date().getTime();
        });

        newState.updatedKeys = updatedKeys;

        return newState;
    });
};

export default setUpdatedKeys;
