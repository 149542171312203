import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';

import RolesMainI from './types.ts';

class RolesMain
    extends WidgetWrapper<RolesMainI['props'], RolesMainI['state']>
    implements RolesMainI
{
    parent: RolesMainI['parent'];

    constructor(props: RolesMainI['props']) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getActions = getActions;
    getMoreActions = getMoreActions;

    render() {
        const { counter = 0 } = this.state;

        return (
            <>
                <div className="body__innerPageWrapper">
                    <Widget
                        name="corporationRoles"
                        title="Роли"
                        descriptionInner={counter}
                        counter={counter}
                        actions={this.getActions!()}
                        isFull={true}
                        className="_white"
                        withBack={false}
                        inWidget={true}
                        filterQuery={(this as RolesMainI).getFilterQuery?.()}
                    >
                        <Table
                            name="corporationRoles"
                            isInit={true}
                            inWidget={true}
                            isNotLink={true}
                            init={this.tableInit!.bind(this)}
                            callback={this.tableCallback!.bind(this)}
                            otherData={{
                                getMoreActions: this.getMoreActions.bind(this),
                            }}
                        />
                    </Widget>
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(RolesMain);
