import changePage from '@functions/changePage';
import getUserName from '@functions/getUserName';
import { ExecutorM, ExecutorT } from '@global/models/Executor';
import PayProjectT from '@global/models/PayProject';
import UserT from '@global/models/User';
import { StoreT } from '@global/types';

const executorMain = {
    title: 'Личные данные',
    description: (model: UserT) => getUserName({ user: model }),
    fieldsOrder: (model: UserT, props: { user: StoreT['user'] }) =>
        props.user?.idOfCurrentCorporation === 'admin'
            ? [
                  'secondName',
                  'type',
                  'phone',
                  'organization',
                  'firstName',
                  'town',
                  'telegram',
                  'empty',
                  'thirdName',
                  'inn',
                  'empty2',
                  'empty3',
              ]
            : [
                  'secondName',
                  'type',
                  'phone',
                  'organization',
                  'firstName',
                  'town',
                  'telegram',
                  'inactive',
                  'thirdName',
                  'inn',
                  'payProjectId',
                  'empty3',
              ],
    fields: {
        secondName: {
            support: 'Фамилия',
            prop: 'secondName',
            type: 'input',
            group: 1,
            width: 6,
        },
        firstName: {
            support: 'Имя',
            prop: 'firstName',
            type: 'input',
            group: 2,
            width: 6,
        },
        thirdName: {
            support: 'Отчество',
            prop: 'thirdName',
            type: 'input',
            group: 3,
            width: 6,
        },
        type: {
            support: 'Категория',
            prop: 'type',
            selectTextKey: 'typeName',
            type: 'select',
            selectList: 'executorTypes',
            selectProps: { name: 'typeName' },
            group: 1,
            width: 6,
        },
        town: {
            support: 'Город',
            prop: 'town',
            selectTextKey: 'townName',
            type: 'select',
            selectList: 'cities',
            selectProps: { name: 'townName' },
            group: 2,
            width: 6,
        },
        inn: {
            support: 'ИНН',
            prop: 'inn',
            type: 'input',
            group: 3,
            width: 6,
            getSupportInfo: (model: ExecutorT) =>
                model.organization === 'SMZ' && !model.isJurStatusActive
                    ? {
                          text: 'Налогоплательщик не привязан <br/>к партнеру в Мой налог',
                          className: '_alert',
                      }
                    : undefined,
        },
        phone: {
            support: 'Телефон',
            prop: 'phone',
            type: 'input',
            group: 1,
            width: 6,
            reg: 'phone',
            action: (model: ExecutorM) => {
                if (!model?.appChatId) {
                    return;
                }

                return {
                    icon: 'executor-chat',
                    onClick: () => {
                        changePage({ pageName: 'chat-inner', ids: { '3': model.appChatId! } });
                    },
                    support: 'Перейти в чат',
                };
            },
        },
        telegram: {
            support: 'Телеграм',
            prop: 'telegram',
            type: 'input',
            group: 2,
            width: 6,
            action: (model: ExecutorM) => {
                if (!model?.telegramChatId) {
                    return;
                }

                return {
                    icon: 'telegram',
                    onClick: () => {
                        changePage({ pageName: 'chat-inner', ids: { '3': model.telegramChatId! } });
                    },
                    support: 'Перейти в чат',
                };
            },
        },
        organization: {
            support: 'ОПФ',
            prop: 'organization',
            selectTextKey: 'organizationName',
            type: 'select',
            selectList: 'executorOrganizations',
            selectProps: { shortName: 'organizationName' },
            group: 1,
            width: 3.5,
            disabled: (model: any, props: { user: UserT }) =>
                props.user?.idOfCurrentCorporation !== 'admin',
        },
        empty: {
            empty: true,
            group: 2,
            width: 3.5,
        },
        empty2: {
            empty: true,
            group: 3,
            width: 6,
        },
        empty3: {
            empty: true,
            group: 3,
            width: 3.5,
        },
        inactive: {
            support: 'Отключен',
            type: 'switch',
            group: 2,
            width: 3.5,
        },
        payProjectId: {
            support: 'Проект',
            prop: 'payProjectId',
            selectTextKey: 'payProjectName',
            type: 'select',
            selectList: 'payProjects',
            selectProps: {
                name: (payProject: PayProjectT) => {
                    let payProjectName: string[] = [];

                    if (payProject) {
                        payProjectName = [payProject.name!];

                        if (payProject.minimal) {
                            payProjectName.push(`мин. ${payProject.minimal} ₽`);
                        }

                        if (payProject.percent) {
                            payProjectName.push(`${payProject.percent}%`);
                        }
                    }

                    return {
                        key: 'payProjectName',
                        text: payProjectName.join(', '),
                    };
                },
            },
            group: 3,
            width: 6,
        },
    },
} as const;

export default executorMain;
