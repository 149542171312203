import lists from '../lists';

const listTagsGroupsColors = {
    ...lists,
    query: [{ key: 'type', value: 'tagsGroupsColors' }],
    emptyInfo: {
        title: 'Цветов пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listTagsGroupsColors;
