export default function getBlockParent({ node, max }) {
    const editorArea = document.querySelector('.editor__areaInner');

    let blockParent = node.parentNode;

    if (
        blockParent === editorArea &&
        node.nodeType !== 3 &&
        ['div', 'td'].includes(node.nodeName.toLowerCase())
    ) {
        return node;
    }

    while (
        blockParent &&
        blockParent !== editorArea &&
        blockParent.parentNode !== editorArea &&
        (!['div', 'td'].includes(blockParent.nodeName.toLowerCase()) || max)
    ) {
        blockParent = blockParent.parentNode;
    }

    if (
        blockParent &&
        (blockParent === editorArea || !['div', 'td'].includes(blockParent.nodeName.toLowerCase()))
    ) {
        return null;
    }

    return blockParent;
}
