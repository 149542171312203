import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import removeTransition from '../functions/removeTransition.ts';

class AnimateChange extends React.Component {
    constructor(props) {
        super(props);
        const keyStart = new Date().getTime();

        this.state = {
            items: {
                [keyStart]: {
                    state: 1,
                },
            },
        };

        this.items = {
            [keyStart]: {
                children: this.props.children,
            },
        };

        this.parent = React.createRef();
    }

    setItems(items) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };

                newState.items = items;

                return newState;
            }, resolve);
        });
    }

    setProp({ prop, isInit = false }) {
        const { condForShow, isNotParams } = this.props;

        return new Promise((resolve) => {
            this.propState = prop;

            if (
                (isInit &&
                    !this.condForShow &&
                    ((condForShow !== undefined && condForShow) || condForShow === undefined)) ||
                isNotParams
            ) {
                this.condForShow = true;
            }

            resolve();
        });
    }

    timerId = null;

    proccessKey = null;

    propState = this.props.prop;

    counter = 0;

    checkChange() {
        const { prop: propProps, children, condForShow, callback } = this.props;

        if (this.propState !== propProps) {
            const items = JSON.parse(JSON.stringify(this.state.items));
            const key = +new Date().getTime() + ++this.counter;

            if (this.timerId) {
                clearTimeout(this.timerId);
            }

            this.proccessKey = key;

            items[key] = {
                state: 0,
                // children,
            };

            this.items[key] = {
                children,
            };

            if (
                !this.condForShow &&
                ((condForShow !== undefined && condForShow) || condForShow === undefined)
            ) {
                this.condForShow = true;
                items[key].isHard = true;
            }

            Object.keys(items).forEach((keyThis) => {
                if (+keyThis !== key) {
                    items[keyThis].isPrev = true;
                }
            });

            this.setProp({ prop: propProps }).then(() => {
                this.setItems(items).then(() => {
                    this.timerId = setTimeout(() => {
                        const { items: itemsThis } = this.state;

                        Object.keys(itemsThis).forEach((keyThis) => {
                            if (+keyThis !== key) {
                                itemsThis[keyThis].state = 0;
                            } else {
                                itemsThis[keyThis].state = 1;
                            }
                        });

                        this.setItems(itemsThis).then(() => {
                            this.timerId = setTimeout(() => {
                                const { items: itemsThis2 } = this.state;

                                Object.keys(itemsThis2).forEach((keyThis) => {
                                    if (+keyThis !== key) {
                                        if (Object.keys(itemsThis2).length > 1) {
                                            delete itemsThis2[keyThis];
                                            delete this.items[keyThis];
                                        }
                                    } else {
                                        itemsThis2[keyThis].isHard = false;
                                    }
                                });

                                this.setItems(itemsThis2).then(() => {
                                    const { items: itemsThis3 } = this.state;
                                    this.proccessKey = null;

                                    Object.keys(itemsThis3).forEach((keyItem) => {
                                        this.items[keyItem].children = this.props.children;
                                    });

                                    this.setItems(itemsThis3);
                                });
                            }, 500);
                        });
                        if (callback) {
                            callback();
                        }
                    }, 10);
                });
            });
        } else if (this.proccessKey === null) {
            const { items } = this.state;

            Object.keys(items).forEach((key) => {
                this.items[key].children = this.props.children;
            });
        }
    }

    childWithProps(child) {
        const { className } = this.props;

        const props = {
            className: `${className || ''} ${child?.props?.className || ''}`,
        };

        return (
            (['undefined', 'string', 'number'].indexOf(typeof child) === -1 &&
                child !== null &&
                React.cloneElement(child, props)) ||
            child
        );
    }

    isProccessParams = false;

    setParams(isNeed = false) {
        const { isNotParams, parent, isExactWidth, callbackParams } = this.props;
        const elem = this.parent.current?.querySelector('.animateChange__elem:not(._prev)');
        const child = elem?.children?.[0] || elem;

        if (child && (!isNotParams || isNeed)) {
            let { offsetWidth: width, offsetHeight: height } = child;

            if (isExactWidth) {
                width = child.getBoundingClientRect().width;
            }

            if (parent) {
                const { offsetWidth: widthParent, offsetHeight: heightParent } = parent;

                width = widthParent;
                height = heightParent;

                if (isExactWidth) {
                    width = parent.getBoundingClientRect().width;
                }
            }

            if (this.props.id === 'test') {
                // console.log(width, child);
            }

            if (
                !this.isProccessParams &&
                width &&
                height &&
                (width !== this.state.width || height !== this.state.height)
            ) {
                this.isProccessParams = true;

                this.setState(
                    (state) => {
                        const newState = { ...state };

                        newState.width = width;
                        newState.height = height;

                        return newState;
                    },
                    () => {
                        this.isProccessParams = false;
                        child.style.maxWidth = null;

                        setTimeout(() => {
                            this.setState((state) => {
                                const newState = { ...state };

                                newState.isGetParams = true;

                                return newState;
                            });
                        }, 10);

                        if (callbackParams && typeof callbackParams === 'function') {
                            callbackParams({ width, height });
                        }
                    },
                );
            } else {
                // child.style.maxWidth = null;
            }
        }
    }

    componentDidMount() {
        const { prop, className, id, isNotParams, animateForInit, callback } = this.props;
        let classElem = ``;

        this.propState = this.props.prop;

        setTimeout(() => {
            if (callback) {
                callback();
            }
        }, 10);

        if (className && !animateForInit) {
            classElem = `.${className}`;

            if (id) {
                classElem += `[data-id="${id}"]`;
            }
            removeTransition({ item: classElem, isCurrent: true });
        }

        this.setParams();
        this.setProp({ prop, isInit: true }).then(() => {
            if (className && !animateForInit) {
                removeTransition({ item: classElem, isCurrent: true });
            }

            this.setParams();
        });

        if (isNotParams) {
            this.setState({ isGetParams: true });
        }
    }

    componentDidUpdate() {
        const { windowIsLoad, isDisabled } = this.props;

        if (!isDisabled) {
            this.isDisabled = false;
            this.checkChange();
            this.setParams();
        }

        if (isDisabled) {
            this.isDisabled = isDisabled;
        }

        if (windowIsLoad && !this.windowIsLoad) {
            this.windowIsLoad = true;

            this.setParams(true);
        }
    }

    render() {
        const { items, width, height, isGetParams } = this.state;
        const {
            children,
            className,
            classNameParent,
            classNameChild,
            type,
            condForShow,
            id,
            onClick,
            isNotParams,
            isDisabled,
        } = this.props;
        const cond =
            (((condForShow !== undefined && condForShow) || condForShow === undefined) &&
                width &&
                height) ||
            isNotParams;

        return (
            <div
                ref={this.parent}
                className={`animateChange _parent ${className || ''} ${classNameParent || ''} ${
                    type || ''
                } ${isGetParams ? '_init' : ''}`}
                data-id={id}
                style={isNotParams ? {} : { width: `${width}px`, height: `${height}px` }}
                onClick={onClick || (() => null)}
            >
                {Object.keys(items).map(
                    (key) =>
                        items[key].state > -1 && (
                            <div
                                className={`animateChange__elem _child ${
                                    items[key].state === 1 && cond ? '_show' : ''
                                } ${items[key].isPrev ? '_prev' : ''} ${className || ''} ${
                                    classNameChild || ''
                                } ${items[key].isHard || !isGetParams ? '_hard' : ''}`}
                                key={key}
                            >
                                {items[key].isPrev && this.items[key].children
                                    ? this.childWithProps(this.items[key].children)
                                    : this.childWithProps(
                                          isDisabled ? this.items[key].children : children,
                                      )}
                            </div>
                        ),
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsLoad: state.windowIsLoad,
    };
}

export default connect(mapStateToProps)(AnimateChange);

AnimateChange.propTypes = {
    children: PropTypes.node,
    prop: PropTypes.any,
    className: PropTypes.string,
    classNameParent: PropTypes.string,
    classNameChild: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    condForShow: PropTypes.bool,
    onClick: PropTypes.func,
    isNotParams: PropTypes.bool,
    isDisabled: PropTypes.bool,
    animateForInit: PropTypes.bool,
    parent: PropTypes.object,
    isExactWidth: PropTypes.bool,
    windowIsLoad: PropTypes.bool,
    callbackParams: PropTypes.func,
    callback: PropTypes.func,
};
