import React from 'react';
import PropTypes from 'prop-types';
import getArticles from '../../../../requests/getArticles';
import setSpacesInText from '../../../../functions/setSpacesInText';
import getFormatedDate from '../../../../functions/getFormatedDate';

import Animate from '../../../Animate.jsx';
import Loader from '../../../Loader.jsx';

class PublicLegalInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerHeight: 400,
        };

        this.parent = React.createRef();
    }

    setHeight() {
        const inner = this.parent.current.querySelector('.publicLegalHeader__pageBoxInner');
        const publicEl = document.querySelector('.public');

        if (inner) {
            let innerHeight = inner.offsetHeight;
            const offsetTop = -(
                publicEl.getBoundingClientRect().y - inner.getBoundingClientRect().y
            );

            if (innerHeight > document.documentElement.clientHeight - offsetTop) {
                innerHeight = document.documentElement.clientHeight - offsetTop;
            }

            this.setState({ innerHeight }, () => {
                setTimeout(() => {
                    this.setState({ innerHeight: undefined });
                }, 300);
            });
        }
    }

    async getArticle() {
        const { url } = this.props;

        const { article } = await getArticles('legal', { params: [{ key: 'url', value: url }] });

        this.setState({ article }, this.setHeight.bind(this));
    }

    componentDidMount() {
        this.getArticle();
    }

    render() {
        const { article, innerHeight } = this.state;

        return (
            <>
                <div ref={this.parent} className="publicLegalHeader__pageWrapper">
                    <Animate className="publicLegalHeader__pageLoader _loader" isShow={!article}>
                        <div className="publicLegalHeader__pageLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div
                        className={`publicLegalHeader__pageBox ${article ? '_ready' : ''}`}
                        style={innerHeight ? { height: `${innerHeight}px` } : {}}
                    >
                        <div className="publicLegalHeader__pageBoxInner">
                            {article && (
                                <>
                                    <div className="publicLegalHeader__pageHead">
                                        <h1
                                            className="publicLegalHeader__pageTitle"
                                            dangerouslySetInnerHTML={{
                                                __html: setSpacesInText(article.title),
                                            }}
                                        ></h1>
                                        <div className="publicLegalHeader__pageDescription">
                                            <span>Дата публикации:</span>{' '}
                                            {getFormatedDate({
                                                date: new Date(article.createdDate),
                                                type: 'textYear',
                                            })}
                                        </div>
                                    </div>
                                    <div
                                        className="publicLegalHeader__pageContent"
                                        dangerouslySetInnerHTML={{
                                            __html: setSpacesInText(article?.content),
                                        }}
                                    ></div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PublicLegalInner;

PublicLegalInner.propTypes = {
    url: PropTypes.string,
    updatePagesKey: PropTypes.func,
};
