import React from 'react';

import Input from '@components/input/Input.tsx';

import CallPopupI from '../types.ts';

const renderField: CallPopupI['renderField'] = function (name) {
    const { fields } = this.state;
    const stateField = fields[name];
    const field = this.fields[name];

    return (
        <div className={`publicCallPopup__formField _${name}`}>
            <div className="publicCallPopup__formFieldInput">
                <Input
                    value={stateField}
                    name={name}
                    onChange={this.inputHandler.bind(this)}
                    support={field.support}
                    reg={field.reg}
                    textarea={field.textarea}
                    isShowSupportInFocus={true}
                />
            </div>
        </div>
    );
};

export default renderField;
