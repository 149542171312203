import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { model, id } = this.state;
    const change = this.checkChange(true);
    const fields = change.model!;

    if (fields.files || change.deleteItems?.find((item) => item.split('.')[0] === 'files')) {
        fields.files = model!.files.map((file) => ({
            _id: file._id,
            src: file.src,
            name: file.name,
            size: file.size,
        }));
    }

    await handlerLoading.call(this, 'save');

    const response = await axios.patch<
        {},
        {
            data: ReqResponseT<{
                message: string;
                error?: { name: string; text: string };
            }>;
        }
    >(`${process.env.REACT_APP_API}/executor-mvd`, { id, fields }, { headers: getHeaders() });

    const { success, data } = response.data;

    if (success) {
        this.close();

        document.dispatchEvent(
            new CustomEvent('refreshTable', { detail: { name: 'executorsMvds' } }),
        );
    } else {
        const { error } = data;

        if (error) {
            this.setState({ error });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
