import axios from 'axios';

import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import TagT from '@global/models/Tag.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (props) {
    const model = this.state.model!;
    const { setModel } = this.props;
    const change = this.checkChange(true);
    const { deleteItems = [] } = change;

    const fields: Record<any, unknown> = change.model!;

    const body: Record<any, unknown> = {
        fields,
    };

    if (fields.tags || deleteItems.find((item) => item.split('.')[0] === 'tags')) {
        fields.tags = model.tags.map((tag) => ({ id: tag._id }));
    }

    if (fields.comments || deleteItems.find((item) => item.split('.')[0] === 'comments')) {
        fields.comments = model.comments;
    }

    const method = model!.new ? 'post' : 'patch';

    if (method === 'patch') {
        body.id = model?._id;
    }

    if (props === true) {
        body.force = true;
    }

    if (typeof props === 'object') {
        body.fields = {
            ...(body.fields as {}),
            ...props,
        };
    }

    await handlerLoading.call(this, 'save');

    try {
        const response = await axios[method]<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: TagT;
                    error?: { name: string; text: string };
                    details?: string[];
                }>;
            }
        >(`${process.env.REACT_APP_API}/executor`, { ...body }, { headers: getHeaders() });
        const { success, data } = response.data;

        if (success) {
            const { id, model: resultModel } = data;

            if (method === 'post' && id) {
                await changePage<'crm'>({
                    pageName: 'joins-scripts-inner',
                    ids: { 3: id },
                });
            }

            await this.init({ fields: resultModel });
            await setModel(resultModel);
        } else {
            const { message } = data;
            let { error } = data;

            if (props) {
                return Promise.reject(error);
            }

            if (message === 'Executor is verification') {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'changeExecutorDeniedCauseCorpVerify',
                    denied: true,
                });
            } else if (message === 'Executor is invite') {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'changeExecutorDeniedCauseInvite',
                    denied: true,
                });
            } else if (message === 'Organization change denied executor has pays') {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'changeExecutorOrgDeniedCausePays',
                    denied: true,
                });
            } else if (message === 'Organization change denied executor has tax') {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'changeExecutorOrgDeniedCauseTax',
                    denied: true,
                });
            } else if (message === 'Executor need join') {
                handlerPopup('alertPopup', {
                    isShow: true,
                    type: 'executorActive',
                    callback: save.bind(this),
                });

                error = undefined;
            } else if (message === 'Organization change' && props !== true) {
                const { details } = data;

                if (details) {
                    handlerPopup('alertPopup', {
                        isShow: true,
                        type: 'changeExecutorOrg',
                        info: details,
                        callback: save.bind(this, true),
                    });
                }
            }

            this.setState({ error });
        }
    } catch (error) {}

    await handlerLoading.call(this, undefined);
};

export default save;
