import BusinessAdvantagesI from '../types.ts';

const inputHandler: BusinessAdvantagesI['inputHandler'] = function ({ name, value }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state };
            const fields = { ...newState.fields };

            fields[name as keyof typeof fields] = value as string;

            newState.fields = fields;

            return newState;
        }, resolve);
    });
};

export default inputHandler;
