import AlertI from '../../types';

const changeExecutorOrg = {
    title: 'Предупреждение',
    description(this: AlertI) {
        const info = this.state.info as string[];
        const descriptions = [];

        if (info.includes('contractsWillRemoves')) {
            descriptions.push(
                'При смене правовой формы у исполнителя <b>будут расторгнуты</b> все активные договоры',
            );
        }

        if (info.includes('fnsWillRemove')) {
            descriptions.push(
                'Исполнитель привязан к партнеру в «Мой налог». Данное партнерство <b>будет прекращено</b>',
            );
        }

        if (info.includes('fnsTaxWillRemove')) {
            descriptions.push('У исполнителя подключена налоговая копилка. Она будет отключена');
        }

        return descriptions.join('. ');
    },
    buttonText: 'Сменить форму',
} as const;

export default changeExecutorOrg;
