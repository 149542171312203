import React from 'react';

import { RenderColsT } from '../../types.ts';

const renderStaticList: RenderColsT<'staticList'> = function ({ name: colName, item }) {
    let content;
    let className;

    const model = item as { _id: string; content: string };

    if (colName === 'name') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: model.content }}
                ></div>
            </>
        );
    }

    return { content, className };
};

export default renderStaticList;
