const joinsDocs = {
    colsOrder: ['name', 'files', 'date'],
    cols: {
        name: {
            content: 'Название',
            width: 40,
        },
        files: {
            content: 'Кол-во файлов',
            width: 16,
        },
        date: {
            content: 'Дата создания',
            width: 44,
            isAdminMain: true,
        },
    },
    adminIndex: 1,
    deletesCols: [],
    url: 'join-doc',
    emptyInfo: {
        title: 'Документов пока нет',
        description: 'Вы можете создать документ по кнопке справа',
    },
} as const;

export default joinsDocs;
