import CallPopupI from '../types.ts';

const outClickHandler: CallPopupI['outClickHandler'] = function ({ target }) {
    const inner = this.parent.current?.querySelector('.publicCallPopup__inner') as HTMLElement;
    const { handler } = this.props;

    if (inner && target !== inner && !inner.contains(target as HTMLElement)) {
        handler(false);
    }
};

export default outClickHandler;
