import PropTypes from 'prop-types';

import React from 'react';

import getFormatPrice from '../../functions/getFormatPrice';
import getFormatedDate from '../../functions/getFormatedDate';
import getUserInfo from '../../functions/getUserInfo';

class PayReceipt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    componentDidMount() {
        const { init } = this.props;

        init();
    }

    render() {
        const { pdf } = this.props;
        const { data } = pdf;
        const { pay, executor, joinContract } = data;
        const { amount, transactionCardNumber: cardNumber } = pay;

        return (
            <div className="pdf__inner _col">
                <div className="pdf__innerBox">
                    <div className="pdfPayReceipt">
                        <div className="pdfPayReceipt__title">Платёж №{pay.number}</div>
                        <div className="pdfPayReceipt__row _row">
                            <div className="pdfPayReceipt__col _support">Дата:</div>
                            <div className="pdfPayReceipt__col">
                                {getFormatedDate({
                                    date: new Date(pay.date),
                                    type: 'fullText',
                                    MSK: true,
                                })}
                            </div>
                        </div>
                        <div className="pdfPayReceipt__row _row">
                            <div className="pdfPayReceipt__col _support">Статус:</div>
                            <div className="pdfPayReceipt__col">
                                <div className="pdfPayReceipt__status">
                                    <img
                                        src={require('../../img/crm/pdf/done.png')}
                                        alt=""
                                        className="pdfPayReceipt__statusIcon"
                                    />
                                    Оплачен
                                </div>
                            </div>
                        </div>
                        <div className="pdfPayReceipt__row _row">
                            <div className="pdfPayReceipt__col _support">Идентификатор:</div>
                            <div className="pdfPayReceipt__col">{pay.transactionId}</div>
                        </div>
                        <div className="pdfPayReceipt__row _row">
                            <div className="pdfPayReceipt__col _support">Получатель:</div>
                            <div className="pdfPayReceipt__col _bold">
                                {cardNumber}
                                <br />
                                {getUserInfo({ type: 'name', user: executor })}
                            </div>
                        </div>
                        <div className="pdfPayReceipt__row _row">
                            <div className="pdfPayReceipt__col _support">Основание:</div>
                            <div className="pdfPayReceipt__col">
                                {joinContract.name} от{' '}
                                {getFormatedDate({ date: new Date(joinContract.date) })}
                            </div>
                        </div>
                        <div className="pdfPayReceipt__row _row _foot">
                            <div className="pdfPayReceipt__col _support"></div>
                            <div className="pdfPayReceipt__col">
                                <div className="pdfPayReceipt__amount">
                                    <div className="pdfPayReceipt__amountRow _row">
                                        <div className="pdfPayReceipt__amountCol">
                                            Сумма выплаты:
                                        </div>
                                        <div className="pdfPayReceipt__amountCol">
                                            {getFormatPrice(amount)} ₽
                                        </div>
                                    </div>
                                    <div className="pdfPayReceipt__amountRow _row">
                                        <div className="pdfPayReceipt__amountCol">Списано:</div>
                                        <div className="pdfPayReceipt__amountCol">
                                            {getFormatPrice(amount)} ₽
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PayReceipt;

PayReceipt.propTypes = {
    pdf: PropTypes.object,
    init: PropTypes.func,
    getPages: PropTypes.func,
    renderFoot: PropTypes.func,
    renderPagination: PropTypes.func,
};
