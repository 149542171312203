import React from 'react';

import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';

import I from '../types.ts';

const renderBlock: I['renderBlock'] = function ({ item }) {
    const { error } = this.state;
    const model = this.state.model!;
    const cardName = item.name === 'card' ? 'walletCard' : 'walletAccount';

    return (
        <div className="v2popup__block _ITEM">
            <div className="v2popup__contentInner">
                <WidgetCard
                    name={cardName}
                    model={model}
                    change={this.change.bind(this)}
                    getValue={this.getValue.bind(this)}
                    withHead={false}
                    className="_empty"
                    error={error}
                />
            </div>
        </div>
    );
};

export default renderBlock;
