import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { executorMvdPopup } = this.props;
    const { id } = executorMvdPopup;

    this.setState({ id }, this.getModel.bind(this));
};

export default mountHandler;
