import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import CursorActionsI, { ActionT } from '../types.ts';

const renderItem: CursorActionsI['renderItem'] = function ({ item }) {
    const { loadingKey } = this.state;

    return (
        <div
            className={`v2cursorActions__item _${item.dir}`}
            style={
                item.position && {
                    left: `${item.position.left}px`,
                    top: `${item.position.top}px`,
                }
            }
        >
            {item.items.map((action) => {
                const actionInfo = this.actions[action.key] as ActionT;
                const { name, text } = action;

                return (
                    <div
                        className={`v2cursorActions__itemAction _CLICK _${action.key}`}
                        key={name}
                        onClick={this.itemHandler.bind(this, { item, action })}
                    >
                        <div className="v2cursorActions__itemActionInner">
                            <LoaderBlock
                                className="v2cursorActions__itemActionLoader"
                                isShow={loadingKey === action.name}
                            />
                            <i className="v2cursorActions__itemActionIcon">
                                <Icon name={actionInfo.icon} />
                            </i>
                            {text || actionInfo.text}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default renderItem;
