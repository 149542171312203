import React from 'react';

import getFormatPrice from '@functions/getFormatPrice.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import ExecutorBalanceT from '@global/models/ExecutorBalance.ts';

import { RenderColsT } from '../../types.ts';

const renderExecutorsBalances: RenderColsT<'executorsBalances'> = function ({
    name: colName,
    item,
}) {
    const executorBalance = item as ExecutorBalanceT;
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <div
                    className="v2table__text"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(executorBalance.content) }}
                ></div>
            </>
        );
    }

    if (colName === 'amount') {
        const amountClassName = executorBalance.amount > 0 ? '_successColor' : '_alertColor';

        content = (
            <>
                <div className={`v2table__text ${amountClassName} _mediumWeight`}>
                    {executorBalance.amount > 0 ? '+' : ''}
                    {getFormatPrice(executorBalance.amount)} ₽
                </div>
            </>
        );
    }

    if (colName === 'total') {
        content = (
            <>
                <div className="v2table__text">{getFormatPrice(executorBalance.total)} ₽</div>
            </>
        );
    }

    // if (colName === 'user') {
    //     content = <>{this.renderUser({ item: executorBalance })}</>;
    // }

    if (colName === 'date') {
        content = (
            <>
                <div className="v2table__text">
                    {getFormatedDate({ date: new Date(executorBalance.createdDate), type: 'full' })}
                </div>
            </>
        );
    }

    return { content, className };
};

export default renderExecutorsBalances;
