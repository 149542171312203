import I from '../../types.ts';

import renderCorporationMain from './renderCorporationMain.tsx';
import renderCorporationOther from './renderCorporationOther.tsx';
import renderUserMain from './renderUserMain.tsx';

const renderCards: I['renderCards'] = function () {
    const { name } = this.props;

    if (name === 'userMain') {
        return renderUserMain.call(this);
    }

    if (name === 'corporationOther') {
        return renderCorporationOther.call(this);
    }

    if (name === 'corporationMain') {
        return renderCorporationMain.call(this);
    }

    return this.renderFields();
};

export default renderCards;
