import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import handlerLoading from '@functions/handlerLoading.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function (inviteId, force) {
    const { isEdit, model } = this.state;
    const method = isEdit ? 'patch' : 'post';
    const fields = this.checkChange(true).model;

    await handlerLoading.call(this, 'save');

    const response = await axios[method]<
        {},
        {
            data: ReqResponseT<{
                inviteUser?: { _id: string; name: string; email: string };
                message: string;
                error?: { name: string; text: string };
            }>;
        }
    >(
        `${process.env.REACT_APP_API}/user`,
        {
            fields,
            inviteId: typeof inviteId === 'string' ? inviteId : undefined,
            ...(isEdit ? { id: model?._id } : {}),
            ...(force === true ? { force } : {}),
        },
        { headers: getHeaders() },
    );

    const { success, data } = response.data;

    if (success) {
        const { inviteUser } = data;

        if (inviteUser) {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'inviteUser',
                info: inviteUser,
                callback: save.bind(this, inviteUser._id),
            });

            await handlerLoading.call(this, undefined);

            return;
        }

        this.close();

        document.dispatchEvent(new CustomEvent('refreshTable', { detail: { name: 'users' } }));
    } else {
        const { message, error } = data;

        if (error) {
            this.setState({ error });
        }

        console.log(message);

        if (message === 'User lose proxy') {
            handlerPopup('alertPopup', {
                isShow: true,
                type: 'userRemoveProxy',
                important: true,
                callback: save.bind(this, undefined, true),
            });
        }
    }

    await handlerLoading.call(this, undefined);
};

export default save;
