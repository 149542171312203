import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import formatCardNumber from '@functions/formatCardNumber.ts';
import handlerPopup from '@functions/handlerPopup.ts';

import I from '../types.ts';

const renderWallet: I['renderWallet'] = function ({ item: wallet }) {
    const { user } = this.state;
    const model = this.state.model!;
    const isCurrent = wallet._id === model.currentWallet;
    const disabled = !(
        user &&
        (user.idOfCurrentCorporation === 'admin' || model?.verification.status !== 'active')
    );

    return (
        <div className="v2widgetCard__wallet _ITEM">
            <div className={`v2widgetWallet _${wallet.type} ${isCurrent ? '_current' : ''}`}>
                <div className="v2widgetWallet__inner">
                    <div className="v2widgetWallet__items _ROW">
                        {wallet.type === 'card' && (
                            <>
                                <div
                                    className={`v2widgetWallet__item _COL ${
                                        wallet.number ? '_short' : ''
                                    }`}
                                >
                                    <div className="v2widgetWallet__value">
                                        {wallet.number ? (
                                            <>*{wallet.number?.replace(/[^\d]/gi, '')?.slice(-4)}</>
                                        ) : (
                                            <>–</>
                                        )}
                                    </div>
                                </div>
                                <div className="v2widgetWallet__item _col">
                                    <div className="v2widgetWallet__value">Банковская карта</div>
                                </div>
                                <div className="v2widgetWallet__item _col">
                                    <div className="v2widgetWallet__value">
                                        {wallet.number ? formatCardNumber(wallet.number) : '–'}
                                    </div>
                                </div>

                                <div className="v2widgetWallet__item _current _col">
                                    <div className="v2widgetWallet__value">Основной счёт</div>
                                </div>
                            </>
                        )}
                        {wallet.type === 'account' && (
                            <>
                                <div className="v2widgetWallet__item _col">
                                    <div className="v2widgetWallet__support">БИК</div>
                                    <div className="v2widgetWallet__value">{wallet.bic}</div>
                                </div>
                                <div className="v2widgetWallet__item _col">
                                    <div className="v2widgetWallet__support">Получатель</div>
                                    <div className="v2widgetWallet__value">
                                        {wallet.addressatName}
                                    </div>
                                </div>
                                <div className="v2widgetWallet__item _col">
                                    <div className="v2widgetWallet__support">Банк. счёт</div>
                                    <div className="v2widgetWallet__value">
                                        {wallet.number ? formatCardNumber(wallet.number) : '–'}
                                    </div>
                                </div>

                                <div className="v2widgetWallet__item _current _col">
                                    <div className="v2widgetWallet__support">&nbsp;</div>
                                    <div className="v2widgetWallet__value">Основной счёт</div>
                                </div>
                                <div className="v2widgetWallet__item _col _bankName">
                                    <div className="v2widgetWallet__support">
                                        Наименование банка
                                    </div>
                                    <div className="v2widgetWallet__value">{wallet.bankName}</div>
                                </div>
                            </>
                        )}
                    </div>
                    {!disabled && (
                        <div className="v2widgetWallet__actions _ROW">
                            <div
                                className="v2widgetWallet__action _CLICK"
                                onClick={() => {
                                    handlerPopup('walletPopup', {
                                        isShow: true,
                                        wallet,
                                        executorId: model._id,
                                        setModel: this.updateWallets.bind(this),
                                    });
                                }}
                            >
                                <Icon name="actions-edit" />
                            </div>
                            <div
                                className="v2widgetWallet__action _CLICK"
                                onClick={() => {
                                    handlerPopup('alertPopup', {
                                        isShow: true,
                                        type: 'deleteWallet',
                                        info: wallet.number,
                                        callback: this.deleteWallet.bind(this, wallet._id),
                                    });
                                }}
                            >
                                <Icon name="actions-delete" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default renderWallet;
