import ItemsI, { ItemT } from '../types.ts';

const addItem: ItemsI['addItem'] = function (this: ItemsI, { item }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state } as ItemsI['state'];
            const items: ItemT[] = JSON.parse(JSON.stringify(newState.items || []));

            if (!items.find((innerItem) => innerItem._id === item._id)) {
                items.push(item);
            }

            newState.items = items;
            newState.updatedItemKey = new Date().getTime();

            return newState;
        }, resolve);
    });
};

export default addItem;
