import { RealParamsItemT } from '@functions/getRealParams.ts';

import ListAbsoluteI from '../types.ts';

const renderItem: ListAbsoluteI['renderItem'] = function ({ ...props }) {
    const { prop } = props;
    const { resultParams } = this.state;
    const { renderItem: propsRenderItem } = this.props;
    const resultParam = resultParams?.[prop] as RealParamsItemT;

    return propsRenderItem({ ...props, params: resultParam });
};

export default renderItem;
