import React from 'react';

import CursorSupport from '@components/cursorSupport/CursorSupport.tsx';
import Media from '@components/media/Media.tsx';

import RootI from '../types.ts';

const renderCursorSupport: RootI['renderCursorSupport'] = function () {
    return (
        <>
            <Media current="desktop">
                <CursorSupport />
            </Media>
        </>
    );
};

export default renderCursorSupport;
