import axios from 'axios';
import getHeaders from '../functions/getHeaders';
import downloadFile from '../functions/downloadFile';

export default function downloadCorporationReportList({ id, filename }) {
    return new Promise((resolve) => {
        axios
            .get(`${process.env.REACT_APP_API}/corporation-report?downloadId=${id}`, {
                responseType: 'blob',
                headers: getHeaders(),
            })
            .then(
                (res) => {
                    res.data.text().then(
                        (result) => {
                            try {
                                const { success } = JSON.parse(result);

                                if (success === false) {
                                    return;
                                }
                            } catch (error) {
                                const resultFilename = `${filename}.xlsx`;

                                downloadFile({ filename: resultFilename, data: res.data });
                            }

                            resolve();
                        },
                        () => null,
                    );
                },
                () => null,
            );
    });
}