import axios from 'axios';

import getHeaders from '../functions/getHeaders';

export default function getChatTemplates({ id, params = [], signal }) {
    let query = '';

    if (id) {
        query += `id=${id}&`;
    }

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/chat-template?${query}`, {
                signal,
                headers: getHeaders(),
            })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;
                    const { chatTemplates = [], chatTemplate, isLimit, counter } = data;

                    if (success) {
                        resolve({
                            chatTemplates,
                            chatTemplate,
                            isLimit,
                            counter,
                        });
                    } else {
                        reject();
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
