import changePage from '@functions/changePage.ts';
import connectSockets from '@functions/connectSockets.js';
import getCurrentPage from '@functions/getCurrentPage.ts';
import getUser from '@functions/getUser.js';
import { getCookie, setCookie } from '@functions/handlerCookies.js';
import setVariables from '@functions/setVariables.js';

import RootI from '../types.ts';

const init: RootI['init'] = async function () {
    const { storePages } = this.props;
    const currentPage = getCurrentPage({ storePages, filter: (page) => !page.level });

    setVariables();

    if (process.env.REACT_APP_SYSTEM === 'crm' && currentPage !== 'pdf') {
        const activateHash = getCookie('activateHash');
        const activateId = getCookie('activateId');

        if (activateHash && activateId) {
            setCookie(process.env.REACT_APP_HASH, activateHash);

            try {
                await getUser(activateHash, true, false, activateId);

                changePage({ pageName: 'corporation' });
            } catch (error) {}
        } else {
            if (!getCookie(process.env.REACT_APP_HASH)) {
                connectSockets();
            }

            try {
                await getUser(getCookie(process.env.REACT_APP_HASH));
            } catch (error) {
                Promise.resolve();
            }
        }
    }

    this.checkAfk();
    this.checkPage();

    document.addEventListener('visibilitychange', () => {
        document.dispatchEvent(
            new CustomEvent('visibilityDocChange', { detail: { isActive: !document.hidden } }),
        );
    });
};

export default init;
