import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import handlerPopup from '@functions/handlerPopup.ts';

import WidgetI from '../../types.ts';

const renderExport: WidgetI['renderHeadAction'] = function ({ item, prop }) {
    const { counter, actions } = this.props;
    const action = actions?.find((innerAction) => innerAction.name === prop);
    const { exportName } = item;
    const exportQuery = action?.exportQuery || [];

    return (
        <div className="v2widget__headAction">
            <div
                className="v2widget__headActionInner _COL _CLICK"
                onClick={() => {
                    const { filterQuery } = this.props;

                    if (counter && counter > 0) {
                        handlerPopup('exportPopup', {
                            isShow: true,
                            counter,
                            filterQuery: [...(filterQuery || []), ...exportQuery],
                            type: exportName,
                        });
                    }
                }}
            >
                <i className="v2widget__headActionIcon">
                    <Icon name="widget-export" />
                </i>
            </div>
        </div>
    );
};

export default renderExport;
