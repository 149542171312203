import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '../../../../components/crm/manual/Table.jsx';
import Search from '../../../../components/Search.jsx';

import getArticles from '../../../../requests/getArticles';
import Link from '../../../../components/Link.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import ListScroll from '../../../../components/ListScroll.jsx';
import Preview from '../../../../components/crm/public/blog/Preview.jsx';
import Loader from '../../../../components/Loader.jsx';
import Animate from '../../../../components/Animate.jsx';
import Top from '../../../../components/crm/public/Top.jsx';

class PublicBlogMain extends Table {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderArticle = this.renderArticle.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);

        this.parent = React.createRef();
    }

    classNameItem = '.publicBlog__article:not(._info)';

    getQueryForRequest() {
        const { currentSection, search = '' } = this.state;
        const query = super.getQueryForRequest();

        if (currentSection !== 'all') {
            query.params.push({ key: 'articleSections', value: currentSection });
        }

        if (search?.length > 2) {
            query.params.push({ key: 'searchForTitle', value: search });
        }

        return query;
    }

    getItems() {
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            getArticles('blog', { ...query }).then(({ articles, isLimit, counter, sections }) => {
                this.setItems(articles, false, isLimit, counter).then(() => {
                    this.setState({ sections: [{ key: 'all', content: 'Все' }, ...sections] });

                    resolve();
                });
            });
        });
    }

    stepCounter = 12;

    getScrollParent() {
        return document
            .querySelector('.public__page._public-blog')
            ?.querySelector('.public__innerPage._show');
    }

    renderArticle({ item, prop: id }) {
        if (id === 'info') {
            return (
                <div className={`publicBlog__article _info`}>
                    <div className="publicBlog__articleInner">
                        <div className="empty _col _block _public">
                            <div className="empty__inner">
                                {item.counter === 0 ? (
                                    <>
                                        <div className="empty__title">Пусто</div>
                                        <p className="empty__content">
                                            Пока что материалов <br />в данном разделе нет
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <div className="empty__title">Это все статьи</div>
                                        <p className="empty__content">
                                            Пока что это все материалы,
                                            <br />
                                            которые есть в данном разделе
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={`publicBlog__article ${item.isMain ? '_main' : ''}`}>
                <div className="publicBlog__articleInner">
                    <Preview article={item} main={item.isMain} />
                </div>
            </div>
        );
    }

    getArticlesItems() {
        const { items = [] } = this.state;

        return [...items, { _id: 'info', counter: items.length }];
    }

    loadingCallback() {
        const { setPagesKey } = this.props;

        setPagesKey();
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                const isUpdate =
                    value.length > 2 || (value.length <= 2 && this.state.search?.length > 2);

                this.setState({ search: value, isLoadingFilter: isUpdate }, () => {
                    if (isUpdate) {
                        clearTimeout(this.searchTimerId);

                        this.searchTimerId = setTimeout(() => {
                            this.updateItems();
                        }, 300);
                    }
                });
            }
        });
    }

    renderSearch() {
        const { search = '' } = this.state;

        return (
            <div className="publicBlog__headSearch">
                <Search
                    icon="search-2"
                    value={search}
                    className="_grey _public"
                    support="Введите название статьи"
                    callback={this.handlerSearch}
                />
            </div>
        );
    }

    checkPage() {
        const { levels, sections } = this.props;

        if (levels[1] === 'blog') {
            this.section = levels[2] || 'all';

            if (
                this.section !== this.state.currentSection &&
                (sections.includes(this.section) || this.section === 'all')
            ) {
                this.setState({ currentSection: this.section }, () => {
                    if (!this.isInit) {
                        this.isInit = true;

                        this.getItems();
                    } else {
                        this.updateItems();
                    }
                });
            }
        }
    }

    componentDidMount() {
        this.checkPage();
    }

    componentDidUpdate() {
        this.checkPage();
    }

    render() {
        const {
            sections,
            currentSection,
            isReady,
            items = [],
            isLimit,
            isDisabledScroll,
            isLoadingFilter = false,
            isShowLoaderList = false,
            counterUpdate,
            contentHeight = 400,
        } = this.state;
        const { setPagesKey, device } = this.props;

        return (
            <>
                <div
                    className={`publicBlog _SECTION ${isReady && !isLoadingFilter ? '_ready' : ''}`}
                >
                    <div className="publicBlog__inner _col">
                        <div className="publicBlog__head _row">
                            <div className="publicBlog__headBlock _col">
                                <h1 className="publicBlog__headTitle">Наш блог</h1>
                                {device === 'mobile' && this.renderSearch()}
                                <div className="publicBlog__headTabsWrapper">
                                    <div
                                        className={`publicBlog__headTabs _row ${
                                            isReady ? '_show' : ''
                                        }`}
                                    >
                                        {(sections || [{ content: 'Все', key: 'all' }]).map(
                                            (section) => (
                                                <Link
                                                    className={`publicBlog__headTab ${
                                                        currentSection === section.key
                                                            ? '_current'
                                                            : ''
                                                    }`}
                                                    key={section.key}
                                                    pageName="public-blog-inner"
                                                    ids={{
                                                        2: section.key === 'all' ? '' : section.key,
                                                    }}
                                                >
                                                    {section.content}
                                                </Link>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </div>
                            {device === 'desktop' && (
                                <div className="publicBlog__headBlock _col">
                                    {this.renderSearch()}
                                </div>
                            )}
                        </div>
                        <div
                            className="publicBlog__content"
                            style={{ height: isLoadingFilter ? `${38}rem` : `${contentHeight}px` }}
                        >
                            <Animate
                                className="publicBlog__loader _loader"
                                isShow={!isReady || isLoadingFilter}
                            >
                                <div className="publicBlog__loaderItem _loaderItem _public">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <Animate
                                className="publicBlog__loader _loaderScroll _white"
                                isShow={isReady && isShowLoaderList}
                            >
                                <div className="publicBlog__loaderItem _loaderItem _public">
                                    <Loader className="_main" />
                                </div>
                            </Animate>
                            <ListScroll
                                isInit={isReady}
                                getParent={this.getScrollParent}
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={items.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                otherItemsCounter={1}
                                isDisabledScroll={isDisabledScroll || !isReady || isLoadingFilter}
                                hideCallback={this.hideListCallback}
                                offsetY={() =>
                                    document.querySelector('.publicTop._footer')?.offsetHeight
                                }
                                key={counterUpdate}
                            >
                                <ListAbsoluteMain
                                    className="publicBlog__articles"
                                    items={this.getArticlesItems()}
                                    renderItem={this.renderArticle}
                                    classNameItem="publicBlog__article"
                                    prop="_id"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    itemParams={['offsetRight', 'offsetTop', 'width', 'height']}
                                    resizeParent={document.querySelector('.body')}
                                    minHeight={400}
                                    callback={({ type, params }) => {
                                        if (type === 'parent') {
                                            this.setState(
                                                { contentHeight: params.height },
                                                setPagesKey,
                                            );
                                        } else {
                                            setPagesKey();
                                        }
                                    }}
                                    propsForUpdate={['counter']}
                                />
                            </ListScroll>
                        </div>
                    </div>
                </div>
                <div className="public__section _footer">
                    <Top isFooter={true} />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        windowIsReady: state.windowIsReady,
        levels: state.levels,
        device: state.device,
    };
}

export default connect(mapStateToProps)(PublicBlogMain);

PublicBlogMain.propTypes = {
    levels: PropTypes.array,
    setPagesKey: PropTypes.func,
    sections: PropTypes.array,
    device: PropTypes.string,
};
