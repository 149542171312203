import React from 'react';
import { connect } from 'react-redux';

import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';
import { StoreT } from '@global/types.ts';

import save from './methods/save';
import setDoc from './methods/setDoc';

import renderDocs from './renders/renderDocs.tsx';
import renderVerification from './renders/renderVerification.tsx';
import DocsI from './types';

class Docs extends WidgetPageWrapper<DocsI['props'], DocsI['state']> implements DocsI {
    parent: DocsI['parent'];

    constructor(props: DocsI['props']) {
        super(props);
        this.state = {
            currentLimit: 'month',
        };

        this.parent = React.createRef();
    }

    save = save;
    setDoc = setDoc;

    renderVerification = renderVerification;
    renderDocs = renderDocs;

    render() {
        const { model, error } = this.state;

        return (
            <>
                <WidgetPage
                    name="corporationVerification"
                    save={this.save.bind(this)}
                    disabled={!this.checkChange().isChange}
                    error={error}
                >
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__cardScroll _NOSCROLL">
                                    <div className="v2widgetPage__cardScrollInner _ROW">
                                        <div className="v2widgetPage__card _bottom _verification">
                                            {this.renderVerification()}
                                        </div>
                                        <div className="v2widgetPage__card _bottom _docs">
                                            {this.renderDocs()}
                                        </div>
                                    </div>
                                </div>
                                <div className="v2widgetPage__card _bottom">
                                    <WidgetCard
                                        model={model}
                                        name="executorShortMain"
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                    />
                                </div>
                                <div className="v2widgetPage__card _bottom">
                                    <WidgetCard
                                        model={model}
                                        name="userPassport"
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                        data={{
                                            isEAES:
                                                !!model &&
                                                ['SMZ', 'IP'].includes(model.organization!),
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Docs);
