import ItemsI, { ItemT } from '../types.ts';

const updateManyItems: ItemsI['updateManyItems'] = function (
    this: ItemsI,
    { items: updatedItems },
) {
    return new Promise((resolve) => {
        this.setState(
            (state) => {
                const newState = { ...state } as ItemsI['state'];
                const items: ItemT[] = JSON.parse(JSON.stringify(newState.items || []));

                updatedItems.forEach((updatedItem) => {
                    const itemIndex = items.findIndex((item) => item._id === updatedItem._id);

                    if (itemIndex !== -1) {
                        Object.keys(updatedItem).forEach((key) => {
                            const value = this.getValue({
                                model: items[itemIndex],
                                key,
                            });

                            if (value) {
                                const { model, key: lastKey } = value;

                                if (model) {
                                    model[lastKey] = updatedItem[key];
                                }
                            }
                        });
                    }
                });

                newState.items = items;
                newState.updatedItemKey = new Date().getTime();

                return newState;
            },
            () => {
                if (this.props.callback) {
                    this.props.callback({ items: this.state.items });
                }

                resolve();
            },
        );
    });
};

export default updateManyItems;
