import React from 'react';

import getEndText from '@functions/getEndText';
import ChatTemplateT from '@global/models/ChatTemplate';

type ParamsT = {
    template: ChatTemplateT;
    name?: string;
};

const Files = function ({ template, name }: ParamsT): React.ReactNode {
    return (
        <>
            {template.files.length ? (
                <>
                    <div
                        className={`v2table__text _block ${name === 'popupChatTemplates' ? '_grey' : ''}`}
                    >
                        {template.files.length}{' '}
                        {getEndText(template.files.length, ['файл', 'файла', 'файлов'])}
                    </div>
                </>
            ) : (
                <>
                    <div className="v2table__text _block _grey">Файлов нет</div>
                </>
            )}
        </>
    );
};

export default Files;
