import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox.jsx';
import Button from '../Button.jsx';
import Field from '../Field.jsx';

class EditorLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { link, targetBlank } = this.state;
        const { callback } = this.props;

        return (
            <div className="editor__actionAlert">
                <div className="editor__actionAlertInner _windowWithCursor _topRight">
                    <div className="editor__actionAlertHead">
                        <div className="editor__actionAlertTitle">Выделение ссылки</div>
                        <div className="editor__actionAlertDescription">
                            Введите ссылку в поле ниже в&nbsp;формате https://example.ru
                        </div>
                    </div>
                    <div className="editor__actionAlertContent _col">
                        <div className="editor__actionAlertField">
                            <Field
                                keyName="website"
                                name="link"
                                support="Введите ссылку"
                                value={link}
                                callback={({ action, value }) => {
                                    if (action === 'change') {
                                        this.setState({ link: value });
                                    }
                                }}
                                className={`_grey`}
                            />
                        </div>
                        <label className="editor__actionAlertCheckbox _click">
                            <div className="editor__actionAlertCheckboxBox">
                                <Checkbox
                                    className="_square _blue"
                                    value={!!targetBlank}
                                    handler={() => {
                                        this.setState({
                                            targetBlank: !targetBlank,
                                        });
                                    }}
                                />
                            </div>
                            Открывать в другом окне
                        </label>
                        <div className="editor__actionAlertButton">
                            <Button
                                className="_main _medium2Size"
                                onClick={(e) => {
                                    callback(e, { link, targetBlank });

                                    this.setState({
                                        link: '',
                                        targetBlank: false,
                                    });
                                }}
                            >
                                Добавить ссылку
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(EditorLink);

EditorLink.propTypes = {
    callback: PropTypes.func,
};
