import ListDynamicI from '../types.ts';

const deleteItem: ListDynamicI['deleteItem'] = function (id) {
    if (!this.itemsDelete[id]) {
        if (this.prevDeleteItemsTimer) {
            clearTimeout(this.prevDeleteItemsTimer);
        }

        if (this.deleteItemsTimer) {
            clearTimeout(this.deleteItemsTimer);
        }

        this.itemsDelete[id] = true;

        this.prevDeleteItemsTimer = setTimeout(() => {
            this.deleteItems();
        }, 30);
    }
};

export default deleteItem;
