import React from 'react';
import { connect } from 'react-redux';

import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Popup from '@components/popup/Popup.tsx';
import { ListenerT, StoreT } from '@global/types.ts';

import checkBorders from './methods/checkBorders.ts';
import close from './methods/close.ts';
import eventEndHandler from './methods/eventEndHandler.ts';
import eventMoveHandler from './methods/eventMoveHandler.ts';
import eventStartHandler from './methods/eventStartHandler.ts';
import getActions from './methods/getActions.ts';
import getPinArea from './methods/getPinArea.ts';
import getPinBtn from './methods/getPinBtn.ts';
import movePinArea from './methods/movePinArea.ts';
import pinInit from './methods/pinInit.ts';
import savePin from './methods/savePin.ts';

import Interface from './types.ts';

import rotate from './actions/rotate.ts';
import renderContent from './renders/renderContent.tsx';
import renderFoot from './renders/renderFoot.tsx';

class Galery extends WidgetWrapper<Interface['props'], Interface['state']> implements Interface {
    constructor(props: Interface['props']) {
        super(props);
        this.state = {};

        this.eventStartHandler = this.eventStartHandler.bind(this);
        this.eventMoveHandler = this.eventMoveHandler.bind(this);
        this.eventEndHandler = this.eventEndHandler.bind(this);
    }

    startX = 0;
    moveX = 0;
    endX = 0;

    startY = 0;
    moveY = 0;
    endY = 0;

    eventStartHandler = eventStartHandler;
    eventMoveHandler = eventMoveHandler;
    eventEndHandler = eventEndHandler;
    checkBorders = checkBorders;

    renderContent = renderContent;
    renderFoot = renderFoot;

    close = close;
    getActions = getActions;
    getPinArea = getPinArea;
    movePinArea = movePinArea;
    pinInit = pinInit;
    getPinBtn = getPinBtn;
    savePin = savePin;

    rotate = rotate;

    componentDidMount() {
        const { galeryPopup } = this.props;
        const { files = [] } = galeryPopup;

        this.setState({
            title: galeryPopup.title,
            files,
            currentId: galeryPopup.currentId || files[0]?._id,
        });

        this.pinInit();
    }

    componentWillUnmount() {
        if (this.props.pin) {
            const pinBtn = this.getPinBtn();

            if (pinBtn) {
                (pinBtn.removeEventListener as ListenerT<React.MouseEvent>)(
                    'mousedown',
                    this.eventStartHandler,
                );
                (document.removeEventListener as ListenerT<React.MouseEvent>)(
                    'mousemove',
                    this.eventMoveHandler,
                );
                (document.removeEventListener as ListenerT<React.MouseEvent>)(
                    'mouseup',
                    this.eventEndHandler,
                );
            }
        }
    }

    render() {
        const { isMove } = this.state;
        const { pin } = this.props;

        return (
            <Popup
                name="galeryPopup"
                close={this.close.bind(this)}
                className={`${pin ? '' : '_full'} ${isMove ? '_disabled' : ''}`}
                checkClose={() => false}
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        galeryPopup: state.galeryPopup,
        galeryPinX: state.galeryPinX,
        galeryPinY: state.galeryPinY,
    };
}

export default connect(mapStateToProps)(Galery);
