import getEndText from '@functions/getEndText';

import AlertI from '../../types';

const deletePays = {
    title: 'Подтвердите удаление актов',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить <b>${info} ${getEndText(info as number, ['акт', 'акта', 'актов'])}</b>?`;
    },
    buttonText: 'Удалить акты',
} as const;

export default deletePays;
