import I from '../types.ts';

const listStateHandler: I['listStateHandler'] = function ({ key, show }) {
    this.setState((state) => {
        const newState = { ...state };
        const listStates = { ...newState.listStates };

        listStates[key] = show ?? !listStates[key];

        newState.listStates = listStates;

        return newState;
    });
};

export default listStateHandler;
