import LinkI from '../types.ts';

const checkChange: LinkI['checkChange'] = function (start) {
    const { user, ids } = this.props;
    const corporationId = user?.idOfCurrentCorporation ?? 'notAuth';

    let isChange = false;

    if (ids && this.ids) {
        Object.keys(this.ids).forEach((key) => {
            if (ids[+key] !== this.ids[+key]) {
                isChange = true;
            }
        });
    }

    if (start) {
        isChange = true;
    }

    if (corporationId !== this.corporationId || isChange) {
        this.corporationId = corporationId;

        if (ids) {
            this.ids = { ...ids };
        }

        this.setHref();
    }
};

export default checkChange;
