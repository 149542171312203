import I from '../types.ts';

import { CardsT, FieldT } from '../static/cards.ts';

const getWidth = (field: FieldT, model: any, data?: Record<any, unknown>) =>
    typeof field.width === 'function' ? field.width(model, data) : field.width;

const getFieldWidth: I['getFieldWidth'] = function (fieldName, propsFieldsOrder) {
    const { name, model, data } = this.props;
    const card = this.cards[name] as CardsT;
    const field = card.fields[fieldName as keyof typeof card.fields] as FieldT;
    const fieldsOrder =
        propsFieldsOrder ||
        (typeof card.fieldsOrder === 'function'
            ? card.fieldsOrder(this.props.model, this.props)
            : card.fieldsOrder);
    const groupFields = fieldsOrder.filter(
        (thisName) => card.fields[thisName].group === field.group,
    );

    if (!field.group || !field.width) {
        return {
            width: `100%`,
            margin: 0,
        };
    }

    let margin = 24;

    if (card.widthPercent) {
        if (groupFields[groupFields.length - 1] === fieldName) {
            return {
                width: `${getWidth(field, model, data)}%`,
                margin: 0,
            };
        }

        return {
            width: `calc(${getWidth(field, model, data)}% - ${margin}px)`,
            margin,
        };
    }

    const allWidth = groupFields.reduce(
        (prev, thisName) => prev + getWidth(card.fields[thisName], model, data)!,
        0,
    );
    const step = 100 / allWidth;

    let width;

    if (groupFields.length === 1) {
        margin = 0;
        width = `${getWidth(field, model, data)! * step}%`;
    } else {
        width = `calc(${getWidth(field, model, data)! * step}% - ${(24 * (groupFields.length - 1)) / groupFields.length}px)`;
    }

    if (groupFields[groupFields.length - 1] === fieldName) {
        margin = 0;
    }

    return {
        width,
        margin,
    };
};

export default getFieldWidth;
