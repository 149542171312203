import getFormatedNumber from '../../../functions/getFormatedNumber.ts';

import CalendarI from '../types.ts';

const setNowTime: CalendarI['setNowTime'] = function (startDate) {
    const date = startDate ? new Date(startDate) : new Date();

    this.setState({
        currentTime: `${getFormatedNumber(date.getHours())}:${getFormatedNumber(
            date.getMinutes(),
        )}`,
        updatedTimeKey: new Date().getTime(),
    });
};

export default setNowTime;
