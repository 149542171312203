import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const setActive: I['setActive'] = async function (this: I, { joinScript, isActive }) {
    try {
        const response = await axios.patch<{}, { data: ReqResponseT }>(
            `${process.env.REACT_APP_API}/join-script`,
            { id: joinScript._id, fields: { isActive } },
            { headers: getHeaders() },
        );

        const { success } = response.data;

        if (success) {
            await this.tableUpdateItem!({ id: joinScript._id, fields: { isActive } });

            return;
        }

        return Promise.reject();
    } catch (error) {
        return Promise.reject();
    }
};

export default setActive;
