import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import mounthHandler from './methods/mounthHandler.ts';
import save from './methods/save.ts';

import types from './static/types.ts';
import WalletI from './types.ts';

import renderBlock from './renders/renderBlock.tsx';
import renderContent from './renders/renderContent.tsx';

class Wallet extends EditBlock<WalletI['props'], WalletI['state']> implements WalletI {
    parent: WalletI['parent'];

    constructor(props: WalletI['props']) {
        super(props);
        this.state = {
            currentType: 'card',
        };

        this.parent = React.createRef();
    }

    types = types;

    close = close;
    save = save;
    mounthHandler = mounthHandler;

    renderContent = renderContent;
    renderBlock = renderBlock;

    componentDidMount(): void {
        this.mounthHandler();
    }

    render() {
        return (
            <Popup
                name="walletPopup"
                close={this.close.bind(this)}
                checkClose={() => !document.querySelector('.body__v2popup._alert')}
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        walletPopup: state.walletPopup,
    };
}

export default connect(mapStateToProps)(Wallet);
