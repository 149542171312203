import React from 'react';
import { connect } from 'react-redux';

import { StoreT } from '@global/types';

import CalendarI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class CalendarPopup
    extends React.Component<CalendarI['props'], CalendarI['state']>
    implements CalendarI
{
    constructor(props: CalendarI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;

    render() {
        return <>{this.renderContent()}</>;
    }
}

function mapStateToProps(state: StoreT) {
    return {
        calendarPopup: state.calendarPopup,
        windowHeight: state.windowHeight,
    };
}

export default connect(mapStateToProps)(CalendarPopup);
