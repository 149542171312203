import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function () {
    const actions = [] as WidgetActionsT[];

    actions.push({
        type: 'filter',
        name: 'filter',
        group: 'buttons',
        filterName: 'executorsBalances',
    });

    return actions;
};

export default getActions;
