import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HandlerFile from '../../classes/File';

import Icon from '../Icon.jsx';
import BorderDashed from '../BorderDashed.jsx';
import uploadFile from '../../requests/uploadFile';
import handlerLoading from '../../functions/handlerLoading';
import Loader from '../Loader.jsx';
import Animate from '../Animate.jsx';
import Field from '../Field.jsx';

class EditorDoc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentWidth: 100,
            uploadProcess: false,
        };

        this.uploadFile = this.uploadFile.bind(this);
        this.checkCloseWindow = this.checkCloseWindow.bind(this);

        this.parent = React.createRef();
    }

    handlerFile = new HandlerFile({ typeOfFiles: 'docs' });

    async uploadFile(e) {
        const { fileName } = this.state;
        const { callback } = this.props;
        const formData = new FormData();

        await handlerLoading.call(this, 'save');

        const { resultFiles } = await this.handlerFile.uploadFiles({ target: e.target, formData });

        if (resultFiles && resultFiles[0]) {
            try {
                const { src } = await uploadFile({ formData });

                callback(e, { src, fileName });

                this.setState({ fileName: null });

                handlerLoading.call(this, null, { uploadProcess: false });
            } catch (error) {
                handlerLoading.call(this, null, { uploadProcess: false });
            }
        } else {
            handlerLoading.call(this, null, { uploadProcess: false });
        }
    }

    checkCloseWindow() {
        setTimeout(() => {
            if (!this.state.loadingKey) {
                this.setState({ uploadProcess: false });
            }
        }, 500);
    }

    componentDidMount() {
        window.addEventListener('focus', this.checkCloseWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.checkCloseWindow);
    }

    render() {
        const { loadingKey, uploadProcess, fileName } = this.state;

        return (
            <div className={`editor__actionAlert ${uploadProcess || loadingKey ? '_show' : ''}`}>
                <div className="editor__actionAlertInner _windowWithCursor _topRight">
                    <div className="editor__actionAlertHead">
                        <div className="editor__actionAlertTitle">Файл</div>
                        <div className="editor__actionAlertDescription">
                            Загрузите файл в поле ниже
                        </div>
                    </div>
                    <div className="editor__actionAlertContent _col">
                        <label
                            className="editor__actionAlertFile"
                            onClick={() => {
                                this.setState({ uploadProcess: true });
                            }}
                        >
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={this.uploadFile}
                            />
                            <div className="editor__actionAlertFileBox _col">
                                <Animate
                                    className="editor__actionAlertFileLoader _loader"
                                    isShow={loadingKey === 'save'}
                                >
                                    <div className="editor__actionAlertFileLoaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                                <div className="editor__actionAlertFileBack">
                                    <BorderDashed className="_upload2" rx={14} ry={14} />
                                </div>
                                <i className="editor__actionAlertFileIcon">
                                    <Icon name="add" />
                                </i>
                                <div className="editor__actionAlertFileTitle">Загрузите файл</div>
                                <div className="editor__actionAlertFileDescription">
                                    Нажмите и выберите <br />
                                    нужный файл
                                </div>
                            </div>
                        </label>
                        <div className="editor__actionAlertField">
                            <Field
                                name="fileName"
                                support="Введите название файла"
                                value={fileName}
                                callback={({ action, value }) => {
                                    if (action === 'change') {
                                        this.setState({ fileName: value });
                                    }
                                }}
                                className={`_grey`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(EditorDoc);

EditorDoc.propTypes = {
    callback: PropTypes.func,
};
