import React from 'react';

import Loader from '@components/Loader.jsx';
import Checkbox from '@components/checkbox/Checkbox.tsx';
import Link from '@components/link/Link.tsx';
import getFormatedDate from '@functions/getFormatedDate.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import JoinContractT from '@global/models/JoinContract.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

import Status from './joinsContracts/Status.tsx';

const renderJoinsContracts: RenderColsT<'joinsContracts'> = function ({ name: colName, item }) {
    const { currents = [], currentsHandler, name } = this.props;
    const joinContract = item as JoinContractT;
    let content;
    let className;
    const isAdmin = this.checkAdmin();
    const contractName = joinContract.type === 'userProxy' ? 'Доверенность' : 'Договор';

    if (colName === 'name') {
        let actionName;

        if (joinContract.inactive) {
            actionName = (
                <>
                    {contractName}{' '}
                    {joinContract.type === 'userProxy' ? 'расторгнута' : 'расторгнут'}
                </>
            );
        } else if (joinContract.fileName) {
            actionName = (
                <>
                    {contractName} {joinContract.type === 'userProxy' ? 'готова' : 'готов'}
                </>
            );
        } else {
            actionName = <>{contractName} генерируется</>;
        }

        content = (
            <>
                <div
                    className={`v2table__checkbox _COL ${name === 'joinsContracts' ? '_vertical' : ''}`}
                >
                    {name === 'joinsContracts' && (
                        <div className="v2table__checkboxBox">
                            <Checkbox
                                name={joinContract._id}
                                value={
                                    !!currents!.find(
                                        (innerItem) => innerItem.id === joinContract._id,
                                    )
                                }
                                onChange={() => {
                                    currentsHandler!(joinContract._id, joinContract);
                                }}
                            />
                        </div>
                    )}

                    <div className="v2table__text _block">
                        {joinContract.name}, №{joinContract.number}
                    </div>
                    <div className="v2table__text _block _grey _top _loading">
                        {actionName}
                        {!joinContract.inactive && !joinContract.fileName && (
                            <>
                                <div className="v2table__textLoader">
                                    <Loader className="_grey" />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    if (colName === 'userName') {
        className = '_innerCol';
        content = (
            <>
                {joinContract.type === 'userProxy' ? (
                    <>
                        <div className={`v2table__text _block ${isAdmin ? '_bottom' : ''}`}>
                            {joinContract.executorName}
                        </div>
                    </>
                ) : (
                    <>
                        <Link
                            className={`v2table__text _block _link _full ${isAdmin ? '_bottom' : ''}`}
                            pageName="manual-executors-inner"
                            ids={{ '3': joinContract.executorId }}
                            callback={() => {
                                handlerPopup('contractSignPopup', { isShow: false });
                            }}
                        >
                            {joinContract.executorName}
                        </Link>
                    </>
                )}
                {isAdmin && <Status joinContract={joinContract} />}
            </>
        );
    }

    if (colName === 'status') {
        content = (
            <>
                <Status joinContract={joinContract} />
            </>
        );
    }

    if (colName === 'date') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            joinContract: JoinContractT;
        }) => CursorActionsItemT[];

        content = (
            <>
                <More
                    id={`joinContract-${joinContract._id}`}
                    items={getMoreActions({ joinContract })}
                />
                {getFormatedDate({ date: new Date(joinContract.createdDate) })}
            </>
        );
    }

    return { content, className };
};

export default renderJoinsContracts;
