import { ModelsT } from '../models';

const cities: ModelsT = {
    url: 'cities',
    logName: 'city',
    title: 'Справочник',
    parentPage: 'manual-cities',
    tablePage: 'manual-cities-main',
    tableName: 'cities',
    modelPage: 'manual-cities-inner',
    deleteAlertPopup: 'deleteCity',
    descriptions: {
        table: 'Города',
        model: {
            new: 'Новый город',
            default: 'Город',
        },
    },
    addButton: 'Добавить город',
    cardName: 'cityMain',
};

export default cities;
