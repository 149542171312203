import I from '../types.ts';

const mountHandler: I['mountHandler'] = async function () {
    const { stepCounter, user, queryKey, init } = this.props;

    this.stepCounter = stepCounter || 25;
    this.queryKey = queryKey;

    this.setState({ user }, this.setFakeItems);

    await this.setLocalFilter();

    this.checkInit();

    if (init) {
        init({
            updateItems: this.updateItems.bind(this),
            updateItem: this.updateItem.bind(this),
            updateManyItems: this.updateManyItems.bind(this),
            getFilterQuery: this.getFilterQuery.bind(this),
            getFilter: () => this.state.filter,
            updateFilter: this.updateFilter.bind(this),
            searchItems: this.searchItems.bind(this),
        });
    }
};

export default mountHandler;
