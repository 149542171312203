const pays = {
    colsOrder: [
        'number',
        'name',
        'executor',
        'period',
        'amount',
        'date',
        'signStatus',
        'payStatus',
    ],
    cols: {
        number: { content: '№', width: 7 },
        name: { content: 'Назначение', width: 18 },
        executor: { content: 'Исполнитель', width: 19 },
        period: { content: 'Период', width: 10, isRemove: true },
        amount: { content: 'Сумма', width: 11, isAdminMain: true },
        date: { content: 'Дата', width: 10 },
        signStatus: { content: 'Подпись', width: 12.5 },
        payStatus: { content: 'Платёж', width: 12.5 },
    },
    adminIndex: 1,
    deletesCols: ['period', 'date'],
    url: 'pays',
    emptyInfo: {
        title: 'Актов пока нет',
        description: 'Вы можете создать акт по кнопке справа',
    },
} as const;

export default pays;
