import I from '../types.ts';

const loadingHandler: I['loadingHandler'] = function (this: I, { name, loading }) {
    return new Promise((resolve) => {
        this.setState((state) => {
            const newState = { ...state };
            const loadings = { ...newState.loadings };

            loadings[name] = loading;

            newState.loadings = loadings;

            return newState;
        }, resolve);
    });
};

export default loadingHandler;
