import UserT from '@global/models/User';

const userEdit = {
    fieldsOrder: ['secondName', 'firstName', 'thirdName', 'email', 'role'],
    fields: {
        secondName: {
            support: 'Фамилия',
            prop: 'secondName',
            type: 'input',
            disabled(model: UserT & { new?: boolean }) {
                return !model?.new;
            },
        },
        firstName: {
            support: 'Имя',
            prop: 'firstName',
            type: 'input',
            disabled(model: UserT & { new?: boolean }) {
                return !model?.new;
            },
        },
        thirdName: {
            support: 'Отчество',
            prop: 'thirdName',
            type: 'input',
            disabled(model: UserT & { new?: boolean }) {
                return !model?.new;
            },
        },
        email: {
            support: 'Email',
            prop: 'email',
            type: 'input',
            disabled(model: UserT & { new?: boolean }) {
                return !model?.new;
            },
        },
        role: {
            support: 'Роль',
            prop: 'role',
            selectTextKey: 'roleName',
            type: 'select',
            selectList: 'roles',
            selectProps: { name: 'roleName' },
        },
    },
} as const;

export default userEdit;
