import I from '../types.ts';

const getPinArea: I['getPinArea'] = function () {
    const area = document
        .querySelector('._POPUPWRAPPER._pin')
        ?.querySelector('.v2popup__inner') as HTMLElement;

    return area;
};

export default getPinArea;
