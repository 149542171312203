import ManualTableI from '../types.ts';

const getTableClasses: ManualTableI['getTableClasses'] = function () {
    const {
        name = '',
        isRowHeight,
        isBlock,
        inWidget,
        isBlockSkinny,
        isWrap,
        isList,
        className,
    } = this.props;

    return [
        `_${name}`,
        ...(className ? [className] : []),
        ...(isRowHeight ? ['_rowHeight'] : []),
        ...(this.checkAdmin() ? ['_corporationAdmin'] : []),
        ...(isBlock ? ['_rowBlock'] : []),
        ...(isBlockSkinny ? ['_blockSkinny'] : []),
        ...(isWrap ? ['_wrapContent'] : []),
        ...(inWidget ? ['_inWidget'] : []),
        ...(isList ? ['_isList'] : []),
    ];
};

export default getTableClasses;
