import I from '../types.ts';

const imageMove: I['imageMove'] = function () {
    const { isZoom } = this.state;
    const { scale } = this.props;
    const image = this.parent.current!;
    let resultTop = this.moveY;
    let resultLeft = this.moveX;

    const borders = this.checkBorders(resultLeft, resultTop);

    resultLeft = borders.x;
    resultTop = borders.y;

    image.style.transform = `translate(${resultLeft}px, ${resultTop}px) scale(${isZoom ? scale : 1})`;
};

export default imageMove;
