import React from 'react';
import { connect } from 'react-redux';

import EditBlock from '@components/editBlock/EditBlock.tsx';
import Popup from '@components/popup/Popup.tsx';
import { StoreT } from '@global/types.ts';

import close from './methods/close.ts';
import mountHandler from './methods/mountHandler.ts';
import save from './methods/save.ts';

import ContractEditI from './types.ts';

import renderContent from './renders/renderContent.tsx';

class ContractEdit
    extends EditBlock<ContractEditI['props'], ContractEditI['state']>
    implements ContractEditI
{
    constructor(props: ContractEditI['props']) {
        super(props);
        this.state = {};
    }

    renderContent = renderContent;

    mountHandler = mountHandler;
    save = save;
    close = close;

    componentDidMount() {
        this.mountHandler();
    }

    render() {
        return (
            <Popup
                name="joinContractPopup"
                close={this.close.bind(this)}
                checkClose={() =>
                    !document.querySelector('.v2cursorLists__item') &&
                    !document.querySelector('.body__v2popup._calendar') &&
                    !document.querySelector('.body__v2popup._alert')
                }
            >
                <>{this.renderContent()}</>
            </Popup>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
        joinContractPopup: state.joinContractPopup,
    };
}

export default connect(mapStateToProps)(ContractEdit);
