import React from 'react';

import TagT from '@global/models/Tag.ts';

import { RenderColsT } from '../../types.ts';

const renderListDocTypes: RenderColsT<'listDocTypes'> = function ({ name: colName, item }) {
    let content;
    let className;

    const docType = item as Pick<TagT, '_id' | 'name'>;

    if (colName === 'name') {
        content = (
            <>
                <div className="v2table__text">{docType.name}</div>
            </>
        );
    }

    return { content, className };
};

export default renderListDocTypes;
