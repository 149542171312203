import appPages from './pages/appPages';
import crmPages from './pages/crmPages';

const pages = {
    crm: crmPages,
    app: appPages,
} as const;

type PageNamesT = {
    crm: (typeof crmPages)[number]['name'];
    app: (typeof appPages)[number]['name'];
    default: (typeof crmPages)[number]['name'] | (typeof appPages)[number]['name'];
};

const currentPages = pages[process.env.REACT_APP_SYSTEM];

export default currentPages;

export { pages as allPages };

export type { PageNamesT };
