import MobPopupI from '../types.ts';

const checkChangeDisabled: MobPopupI['checkChangeDisabled'] = function () {
    if (
        typeof this.checkHideDisabled === 'function' &&
        this.checkHideDisabled() !== this.isDisabled
    ) {
        this.isDisabled = this.checkHideDisabled();

        this.handlerEvents(!this.checkHideDisabled());
    }
};

export default checkChangeDisabled;
