import axios from 'axios';

import getHeaders from '../functions/getHeaders';

export default function getExecutorMvds({ params = [] }) {
    let query = '';

    params.forEach((param) => {
        query += `${param.key}=${param.value}&`;
    });

    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_API}/executor-mvd?${query}`, {
                headers: getHeaders(),
            })
            .then((res) => {
                if (res?.data?.data && typeof res?.data?.data === 'object') {
                    const { success, data } = res.data;
                    const { executorMvds = [], executorMvd, isLimit, counter } = data;

                    if (success) {
                        resolve({
                            executorMvds,
                            executorMvd,
                            isLimit,
                            counter,
                        });
                    } else {
                        reject();
                    }
                } else {
                    reject();
                }
            }, reject);
    });
}
