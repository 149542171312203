import getFormatedDate from '@functions/getFormatedDate';
import { PassportT } from '@global/types';

import { FieldT } from '../cards';

const userPassport = {
    title: (model: any, data: any) =>
        data?.fromCorporation ? 'Паспорт директора' : 'Удостоверяющий документ',
    description: (model: { passport: PassportT }) =>
        [
            ...(model.passport.series ? [model.passport.series] : []),
            ...(model.passport.number ? [model.passport.number] : []),
        ].join(' '),
    widthPercent: true,
    fieldsOrder: [
        'country',
        'dateGet',
        'nameGet',
        'view',
        'dateAction',
        'series',
        'number',
        'code',
        'birthday',
        'empty',
        'addressReg',
        'addressFact',
    ],
    fields: {
        country: {
            support: 'Страна',
            prop: 'passport.country',
            selectTextKey: 'passport.countryName',
            type: 'select',
            selectList: 'countries',
            selectProps: { name: 'passport.countryName' },
            group: 1,
            width: 27,
            defaultQuery: (model: any, data: any) =>
                data?.data?.isEAES
                    ? [
                          { key: 'types', value: 'RUS' },
                          { key: 'types', value: 'EAES' },
                      ]
                    : [],
        },
        dateGet: {
            support: 'Дата выдачи',
            prop: 'passport.dateGet',
            type: 'date',
            group: 1,
            width: 31,
            withPast: true,
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
        nameGet: {
            support: 'Паспорт выдан',
            prop: 'passport.nameGet',
            type: 'input',
            group: 1,
            width: 42,
        },
        view: {
            support: 'Вид',
            prop: 'passport.view',
            selectTextKey: 'passport.viewName',
            type: 'select',
            selectList: 'passportViews',
            selectProps: { name: 'passport.viewName' },
            group: 2,
            width: 27,
        },
        dateAction: {
            support: 'Действует до',
            prop: 'passport.dateAction',
            type: 'date',
            group: 2,
            width: 31,
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
        series: {
            support: 'Серия',
            prop: 'passport.series',
            type: 'input',
            group: 2,
            width: 20,
        },
        number: {
            support: 'Номер',
            prop: 'passport.number',
            type: 'input',
            group: 2,
            width: 22,
        },
        code: {
            support: 'Подразделение',
            prop: 'passport.code',
            type: 'input',
            group: 3,
            width: 27,
        },
        birthday: {
            support: 'Дата рождения',
            prop: 'passport.birthday',
            type: 'date',
            group: 3,
            width: 31,
            withPast: true,
            withTime: false,
            setDate: ({ date }: Parameters<NonNullable<FieldT['setDate']>>[0]): string =>
                getFormatedDate({ date: new Date(date) }),
        },
        empty: {
            empty: true,
            group: 3,
            width: 10,
        },
        addressReg: {
            support: 'Адрес регистрации',
            prop: 'passport.addressReg',
            type: 'input',
            group: 4,
            width: 100,
            withCitySupport: true,
        },
        addressFact: {
            support: 'Адрес проживания',
            prop: 'passport.addressFact',
            type: 'input',
            group: 5,
            width: 100,
            withCitySupport: true,
        },
    },
} as const;

export default userPassport;
