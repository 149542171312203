import React from 'react';

import ActionChange from '@components/actionChange/ActionChange.tsx';
import Animate from '@components/animate/Animate.tsx';
import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Checkbox from '@components/checkbox/Checkbox.tsx';
import getEndText from '@functions/getEndText.ts';
import JoinScriptT from '@global/models/JoinScript.ts';

import I from '../types.ts';

const renderDoc: I['renderDoc'] = function ({ item }) {
    const model = this.state.model!;
    const doc = item as NonNullable<JoinScriptT['allDocs']>[number];
    const modelDoc = model.docs.find((innerDoc) => innerDoc.id === doc._id);
    const isCurrent = !!modelDoc;

    return (
        <div className={`v2widgetJoinScript__docsItem _ITEM ${isCurrent ? '_current' : ''}`}>
            <div className={`v2widgetJoinScript__doc ${isCurrent ? '_current' : ''}`}>
                <div className="v2widgetJoinScript__docTitle">{doc.name}</div>
                <div className="v2widgetJoinScript__docInfo _ROW">
                    <div className="v2widgetJoinScript__docFiles">
                        {doc.filesCounter}{' '}
                        {getEndText(doc.filesCounter, ['файл', 'файла', 'файлов'])}
                    </div>
                    <Animate className="v2widgetJoinScript__docRequired _ROW" isShow={isCurrent}>
                        <div className="v2widgetJoinScript__docRequiredInner">
                            <Checkbox
                                name="required"
                                onChange={() => {
                                    this.change({
                                        [`docs.${modelDoc?._id}.isRequire`]: !modelDoc?.isRequire,
                                    });
                                }}
                                value={!!modelDoc?.isRequire}
                            >
                                Обязательный
                            </Checkbox>
                        </div>
                    </Animate>
                </div>
                <AnimateChange
                    className="v2widgetJoinScript__docOptions"
                    renderKey={isCurrent ? true : undefined}
                    styles={['height']}
                    callback={() => {
                        this.setState({ updatedListKey: new Date().getTime() });
                    }}
                >
                    <>
                        {isCurrent && (
                            <>
                                <div className="v2widgetJoinScript__docOptionsInner">
                                    <span>Гражданство:</span>{' '}
                                    {doc.countries?.map((country) => country.name).join(', ') || 'Все'}
                                </div>
                            </>
                        )}
                    </>
                </AnimateChange>

                <ActionChange
                    className="v2widgetJoinScript__docBtn _CLICK"
                    active={isCurrent}
                    onClick={() => {
                        if (isCurrent) {
                            this.deleteArrayItem([`docs.${modelDoc._id}`]);
                        } else {
                            this.addArrayItem({
                                name: 'doc',
                                key: 'docs',
                                item: {
                                    id: doc._id,
                                },
                            });
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default renderDoc;
