const corporationLimits = {
    fieldsOrder: ['all', 'SMZ', 'FL', 'IP'],
    fields: {
        all: {
            support: 'Общий',
            prop: 'all',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
        },
        SMZ: {
            support: 'Для самозанятых',
            prop: 'SMZ',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
        },
        FL: {
            support: 'Для физ. лиц',
            prop: 'FL',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
        },
        IP: {
            support: 'Для ИП',
            prop: 'IP',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
        },
    },
} as const;

export default corporationLimits;
