import React from 'react';

import Icon from '@components/icon/Icon';
import { setCursorSupport } from '@functions/supportHandler';
import PayT from '@global/models/Pay';

type ParamsT = {
    pay: PayT;
};

export default function Fns({ pay }: ParamsT): React.ReactNode {
    const { fnsReceiptStatus, fnsReceiptLink } = pay;
    const contents = {
        cancel: 'Аннулирован',
        offline: 'Оффлайн',
    };
    const supportContent = `${contents[fnsReceiptStatus as keyof typeof contents] || 'Актуален'}`;
    const link =
        fnsReceiptStatus === 'offline'
            ? `${process.env.REACT_APP_STATIC}/corporations-docs/${fnsReceiptLink}`
            : fnsReceiptLink;
    const supportProps = setCursorSupport(
        { _id: `fns-${pay._id}`, content: supportContent, dir: 'bottom' },
        { targetClassName: '.v2table__button', className: '' },
    );

    return (
        <a
            className={`v2table__button _ROW _fns _top _${fnsReceiptStatus}`}
            rel="noreferrer"
            href={link!}
            target="_blank"
            {...supportProps}
        >
            ФНС чек
            <i className="v2table__buttonIcon">
                <Icon name="fns" />
            </i>
        </a>
    );
}
