const executorsBalances = {
    colsOrder: ['name', 'amount', 'total', 'date'],
    cols: {
        name: {
            content: 'Наименование операции',
            width: 53,
        },
        amount: {
            content: 'Сумма',
            width: 15,
        },
        total: {
            content: 'Доступно',
            width: 15,
        },
        user: {
            content: 'Пользователь',
            width: 27,
        },
        date: {
            content: 'Дата и время',
            width: 17,
        },
    },
    deletesCols: [],
    url: 'executor-balance',
    emptyInfo: {
        title: 'Операций пока нет',
        description: 'Они будут отображаться здесь<br/>после списаний по актам',
    },
} as const;

export default executorsBalances;
