import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const contractHandler: I['contractHandler'] = async function (
    { joinContract, action },
    force = false,
) {
    try {
        const response = await axios.patch<{}, { data: ReqResponseT<{ message: string }> }>(
            `${process.env.REACT_APP_API}/join-contract`,
            { itemsIds: [joinContract._id], action, type: joinContract.type, isForce: force },
            { headers: getHeaders() },
        );

        const { success, data } = response.data;

        if (success) {
            return;
        }

        if (data.message === 'Contract have mvd') {
            return Promise.reject({ hasMvd: true });
        }
    } catch (error) {
        return Promise.reject({});
    }

    return Promise.reject({});
};

export default contractHandler;
