import getDaysInMonth from './getDaysInMonth';

export default function getDateSumDays(d, m, y) {
    let c = y * 365;

    for (let i = 0; i < m; i++) {
        c += getDaysInMonth(i, y);
    }

    c += d;

    return c;
}
