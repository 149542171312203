import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import getArticles from '../../../requests/getArticles';

import Link from '../../../components/Link.jsx';
import Animate from '../../../components/Animate.jsx';
import Icon from '../../../components/Icon.jsx';
import ListAbsoluteMain from '../../../components/ListAbsoluteMain.jsx';
import Loader from '../../../components/Loader.jsx';

import Top from '../../../components/crm/public/Top.jsx';
import Page from '../../../components/crm/public/legal/Inner.jsx';

class PublicLegal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.publicPageScroll = this.publicPageScroll.bind(this);

        this.parent = React.createRef();
    }

    renderNav() {
        const { shortList, isSmallMenu } = this.state;
        const { levels, changeLegalCallback } = this.props;

        return (
            <div
                className={`publicLegalHeader__nav ${shortList ? '_ready' : ''} ${
                    isSmallMenu ? '_small' : ''
                }`}
            >
                <div className="publicLegalHeader__navTitle">
                    Содержание:
                    <Animate className="publicLegalHeader__navLoader" isShow={!shortList}>
                        <Loader className="_main" />
                    </Animate>
                </div>
                <Animate className="publicLegalHeader__navContent" isShow={!!shortList}>
                    {shortList?.map((page, index) => {
                        const { title, url } = page;
                        const isCurrent = url === levels[2] || (index === 0 && !levels[2]);

                        return (
                            <div
                                className={`publicLegalHeader__navItem ${
                                    isCurrent ? '_current' : ''
                                }`}
                                key={page._id}
                            >
                                <Link
                                    className="publicLegalHeader__navLink"
                                    pageName="public-legal-inner"
                                    ids={{ 2: url }}
                                    callback={() => {
                                        if (typeof changeLegalCallback === 'function') {
                                            changeLegalCallback();
                                        }
                                    }}
                                >
                                    {title}
                                </Link>
                            </div>
                        );
                    })}
                </Animate>
            </div>
        );
    }

    getPages() {
        const { shortList } = this.state;
        const { levels } = this.props;

        if (shortList) {
            const url = levels[2] || shortList[0]?.url;

            if (url) {
                return [{ key: url }];
            }
        }

        return [];
    }

    renderPage({ prop: url }) {
        return (
            <div className="publicLegalHeader__page">
                <div className="publicLegalHeader__pageInner">
                    <Page url={url} updatePagesKey={this.updatePagesKey.bind(this)} />
                </div>
                
                <div className="public__section _footer">
                    <Top isFooter={true} />
                </div>
            </div>
        );
    }

    updatePagesKey() {
        this.setState({ updatedPagesKey: new Date().getTime() });
    }

    async getList() {
        try {
            const { shortList } = await getArticles('legal', {});

            this.setState({ shortList });
        } catch (error) {
            Promise.resolve();
        }
    }

    publicPageScroll({ detail: { isSmallMenu } }) {
        if (isSmallMenu && !this.isSmallMenu) {
            this.isSmallMenu = true;

            this.setState({ isSmallMenu });
        }

        if (!isSmallMenu && this.isSmallMenu) {
            this.isSmallMenu = false;

            this.setState({ isSmallMenu });
        }
    }

    componentDidMount() {
        const { setRenderNav } = this.props;

        this.getList();

        if (typeof setRenderNav === 'function') {
            setRenderNav(this.renderNav.bind(this));
        }

        document.addEventListener('publicPageScroll', this.publicPageScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('publicPageScroll', this.publicPageScroll);
    }

    render() {
        const { device } = this.props;

        return (
            <div className="public__pageInner _full">
                <div className="publicLegalHeader">
                    <div className="publicLegalHeader__inner">
                        {device === 'desktop' && this.renderNav()}
                        <Animate
                            className="publicLegalHeader__button _row _click"
                            isShow={false}
                            onClick={() => {
                                this.scrollToTop();
                            }}
                        >
                            Следующий документ
                            <i className="publicLegalHeader__buttonIcon">
                                <Icon name="arrow-next-2" />
                            </i>
                        </Animate>
                    </div>
                    <ListAbsoluteMain
                        className="publicLegalHeader__pages"
                        items={this.getPages()}
                        renderItem={this.renderPage.bind(this)}
                        classNameItem="publicLegalHeader__page"
                        prop="key"
                        paramsParent={{ width: true }}
                        styles={[]}
                        maxHeight={document.documentElement.clientHeight}
                        isNotParamsItem={true}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        device: state.device,
    };
}

export default connect(mapStateToProps)(PublicLegal);

PublicLegal.propTypes = {
    setRenderNav: PropTypes.func,
    changeLegalCallback: PropTypes.func,
    levels: PropTypes.array,
    device: PropTypes.string,
};
