import ItemsI, { ItemT } from '../types.ts';

const updateItem: ItemsI['updateItem'] = function (this: ItemsI, { id, fields }) {
    return new Promise((resolve) => {
        this.setState(
            (state) => {
                const newState = { ...state } as ItemsI['state'];
                const items: ItemT[] = JSON.parse(JSON.stringify(newState.items || []));

                const itemIndex = items.findIndex((item) => item._id === id);

                if (itemIndex !== -1) {
                    Object.keys(fields).forEach((key) => {
                        const value = this.getValue({
                            model: items[itemIndex],
                            key,
                        });

                        if (value) {
                            const { model, key: lastKey } = value;

                            if (model) {
                                model[lastKey] = fields[key];
                            }
                        }
                    });
                }

                newState.items = items;
                newState.updatedItemKey = new Date().getTime();

                return newState;
            },
            () => {
                if (this.props.callback) {
                    this.props.callback({ items: this.state.items });
                }

                resolve();
            },
        );
    });
};

export default updateItem;
