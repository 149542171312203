import React from 'react';
import PropTypes from 'prop-types';
import getFormatedNumber from '../../functions/getFormatedNumber.ts';

class VoiceBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    startDate;

    intervalId;

    startTimer() {
        const { endVoice } = this.props;
        const timer = this.parent.current.querySelector('.chatVoiceBar__timer');
        this.startDate = new Date();

        this.intervalId = setInterval(() => {
            const nowDate = new Date();

            const differentTime = nowDate.getTime() - this.startDate.getTime();
            const mseconds = +((differentTime % 1_000) / 1000).toFixed(2) * 100;
            const seconds = Math.floor(differentTime / 1_000);
            const minutes = Math.floor(differentTime / 60_000);
            const resultTime = `${getFormatedNumber(minutes)}:${getFormatedNumber(
                seconds,
            )}:${getFormatedNumber(mseconds)}`;

            if (differentTime > 60_000 * 2) {
                endVoice();
            }

            timer.innerHTML = resultTime;
        }, 50);
    }

    checkChange() {
        const { isActive } = this.props;

        if (isActive !== this.isActive) {
            this.isActive = isActive;

            if (isActive) {
                this.startTimer();
            } else {
                clearInterval(this.intervalId);
            }
        }
    }

    componentDidMount() {
        this.checkChange();
    }

    componentDidUpdate() {
        this.checkChange();
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        return (
            <div ref={this.parent} className="chatVoiceBar _row">
                <div className="chatVoiceBar__timer"></div>
            </div>
        );
    }
}

export default VoiceBar;

VoiceBar.propTypes = {
    isActive: PropTypes.bool,
    endVoice: PropTypes.func,
};
