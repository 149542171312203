import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import AnimateChange from '@components/AnimateChange.jsx';
import WindowPrompt from '@components/WindowPrompt.jsx';
import Windows from '@components/Windows.jsx';
import Back from '@components/crm/widget/Back.jsx';
import InfoHead from '@components/crm/widget/InfoHead.jsx';
import changePage from '@functions/changePage.ts';
import getHeaders from '@functions/getHeaders.ts';
import getPageLink from '@functions/getPageLink.ts';
import handlerWindow from '@functions/handlerWindow.js';
import setNotification from '@functions/setNotification.js';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';

import setPermissions from '../../../../functions/crm/setPermissions';

import getJoinTemplates from '../../../../requests/getJoinTemplates';
import HeadInner from './HeadInner.jsx';
import Main from './inner/Main.jsx';

class JoinsTemplatesInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.getJoinTemplate = this.getJoinTemplate.bind(this);
        this.setJoinTemplate = this.setJoinTemplate.bind(this);
        this.changeJoinTemplate = this.changeJoinTemplate.bind(this);
        this.checkPermissions = this.checkPermissions.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    targetName = 'joinTemplate';

    targetNameSave = 'joinTemplateSave';

    isNew = false;

    checkNew() {
        const { levels } = this.props;
        const id = levels[3];

        if (levels[1] === 'joins' && levels[2] === 'templates' && id !== 'main') {
            this.isNew = id === 'new';
        }

        return this.isNew;
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    changeJoinTemplate({ action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState((state) => {
                    const newState = { ...state };
                    const joinTemplate = JSON.parse(JSON.stringify(newState.joinTemplate));
                    const target = joinTemplate;

                    target[name] = value;

                    newState.joinTemplate = joinTemplate;

                    return newState;
                }, resolve);
            } else {
                resolve();
            }
        });
    }

    setJoinTemplate({ joinTemplate }) {
        return new Promise((resolve) => {
            this.setState(
                (state) => {
                    const newState = { ...state };

                    newState.joinTemplate = joinTemplate;
                    newState.joinTemplateSave = JSON.parse(JSON.stringify(joinTemplate));

                    return newState;
                },
                () => {
                    if (!this.state.isInit) {
                        this.setState({ isInit: true });
                    }

                    resolve();
                },
            );
        });
    }

    updateJoinTemplate({ fields }) {
        return new Promise((resolve) => {
            this.setJoinTemplate(
                {
                    joinTemplate: {
                        ...this.state.joinTemplate,
                        ...fields,
                    },
                },
                resolve,
            );
        });
    }

    checkPermissions() {
        return this.getPermissions({
            key: 'joins',
            items: [
                {
                    key: 'templates',
                    rules: ['update'],
                },
            ],
        });
    }

    getJoinTemplate() {
        const { levels } = this.props;
        const id = levels[3];

        return new Promise((resolve) => {
            getJoinTemplates({ id }).then(
                ({ joinTemplate }) => {
                    this.setJoinTemplate({ joinTemplate }).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    delete({ isForce }) {
        const { joinTemplate } = this.state;
        const { windows } = this.props;
        const id = joinTemplate?._id;
        const windowId = `delete-joinTemplate-${id}`;

        return new Promise((resolve, reject) => {
            if (windows[windowId] && !isForce) {
                handlerWindow({
                    action: 'hide',
                    name: windowId,
                });

                resolve();
            } else {
                axios
                    .delete(
                        `${process.env.REACT_APP_API}/join-template?id=${id}${
                            isForce ? '&isForce=true' : ''
                        }`,
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;
                            const { message } = data;

                            const windowName =
                                message === 'You dont delete this model'
                                    ? 'deleteJoinTemplateInfo'
                                    : 'promptDelete';

                            if (!isForce) {
                                if (message === 'You dont delete this model' || success) {
                                    handlerWindow({
                                        parent: this.parent.current,
                                        parentResize: this.parent.current,
                                        target: this.parent.current.querySelector(
                                            '.widget__headLink._delete',
                                        ),
                                        action: 'show',
                                        name: windowName,
                                        className: '_prompt _center',
                                        uniqKey: windowId,
                                        watchesProps: { left: true },
                                        centers: {
                                            left: 0.5,
                                            top: 1,
                                        },
                                    });
                                }
                            } else {
                                handlerWindow({
                                    action: 'hide',
                                    name: windowId,
                                });
                            }

                            if (success) {
                                if (isForce) {
                                    setNotification({ notification: 'success-change-info' });
                                }

                                resolve();
                            } else {
                                reject();
                            }
                        },
                        () => null,
                    );
            }
        });
    }

    renderWindowsPromptDelete() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="delete"
                callback={({ hide, handlerLoader }) => {
                    this.delete({ isForce: true }).then(
                        () => {
                            hide();
                            handlerLoader(false);

                            changePage({
                                href: getPageLink({ name: 'joins-templates-main' }),
                            });
                        },
                        () => {
                            handlerLoader(false);
                        },
                    );
                }}
            />
        );
    }

    renderWindowsDeleteInfo() {
        return (
            <WindowPrompt
                className="_topCenter"
                name="deleteJoinTemplateInfo"
                withDelete={false}
                callback={({ hide, handlerLoader }) => {
                    hide();
                    handlerLoader(false);
                }}
            />
        );
    }

    componentDidMount() {
        this.getJoinTemplate();
    }

    render() {
        const { joinTemplate = {}, joinTemplateSave, isInit } = this.state;
        const { name, isNew } = joinTemplate;

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows
                    name="promptDelete"
                    renderContent={this.renderWindowsPromptDelete.bind(this)}
                />
                <Windows
                    name="deleteJoinTemplateInfo"
                    renderContent={this.renderWindowsDeleteInfo}
                />
                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead title="Подключение">
                                <div className="widget__headNameItemContent">
                                    Шаблон
                                    <AnimateChange
                                        prop={name}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                        classNameParent="_parent"
                                    >
                                        {name ? `${name}` : isNew ? ' новый' : '–'}
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <HeadInner
                                    isNew={this.checkNew()}
                                    delete={this.delete.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className={`widget ${isInit ? '_ready' : ''}`}>
                        <div className="widget__content _full">
                            <Animate className="widget__loader _loader" isShow={!isInit}>
                                <i className="widget__loaderItem _loaderItem">
                                    <Loader className="_main" />
                                </i>
                            </Animate>
                            <div className="widget__contentBox">
                                {isInit && (
                                    <div className="widget__page _show _full">
                                        <div className="widget__pageBox _scroll">
                                            <div className="widget__pageInner">
                                                <Main
                                                    parentScroll={(() =>
                                                        this.parent.current?.querySelector(
                                                            '.widget__pageBox._scroll',
                                                        ))()}
                                                    joinTemplate={joinTemplate}
                                                    joinTemplateSave={joinTemplateSave}
                                                    isInit={isInit}
                                                    changeJoinTemplate={this.changeJoinTemplate}
                                                    setJoinTemplate={this.setJoinTemplate}
                                                    isNew={this.checkNew()}
                                                    getJoinTemplate={this.getJoinTemplate}
                                                    backToSave={this.backToSave}
                                                    checkRights={this.checkPermissions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        serverData: state.serverData,
        typeTag: state.typeTemplates,
        user: state.user,
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(JoinsTemplatesInner);

JoinsTemplatesInner.propTypes = {
    levels: PropTypes.array,
    getParent: PropTypes.func,
    serverData: PropTypes.object,
    typeTag: PropTypes.string,
};
