import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';
import { PageNamesT } from '@redux/pages.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function (this: I) {
    const { model } = this.state;
    const { config } = this.props;
    const actions: WidgetActionsT[] = [
        {
            type: 'link',
            name: 'main',
            group: 'links',
            pageName: `${config.modelPage}-main` as PageNamesT['crm'],
        },
    ];

    if (!model?.new) {
        actions.push({
            type: 'link',
            name: 'logs',
            group: 'links',
            pageName: `${config.modelPage}-logs` as PageNamesT['crm'],
        });
        actions.push({
            type: 'link',
            name: 'delete',
            group: 'delete',
            className: '_alertEmpty',
            content: 'Удалить',
            onClick: this.deleteModel.bind(this, false),
        });
    }

    return actions;
};

export default getActions;
