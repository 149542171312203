import LogsPopupI from '../types.ts';

const getInfo: LogsPopupI['getInfo'] = function () {
    let title = '';

    const { modelName } = this.state;

    if (modelName === 'pay') {
        title = 'акта';
    }

    if (modelName === 'join') {
        title = 'подключения';
    }

    if (modelName === 'executorMvd') {
        title = 'МВД уведомления';
    }

    if (modelName === 'executorTax') {
        title = 'налоговой копилки';
    }

    if (modelName === 'joinContract') {
        title = 'договора';
    }

    if (modelName === 'chat') {
        title = 'чата';
    }

    if (modelName === 'role') {
        title = 'роли';
    }

    if (modelName === 'user') {
        title = 'сотрудника';
    }

    if (modelName === 'corporationVerification') {
        title = 'верификации компании';
    }

    if (modelName === 'executorLimits') {
        title = 'лимитов';
    }

    if (modelName === 'mailing') {
        title = 'рассылки';
    }

    if (modelName === 'chatTemplate') {
        title = 'шаблона чата';
    }

    return {
        title: `Логи ${title}`,
    };
};

export default getInfo;
