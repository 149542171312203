export default function checkChangeArray({ array, targetArray, prop }) {
    return (
        array.length !== targetArray.length ||
        array.find(
            (item) =>
                !targetArray.find(
                    (targetItem) =>
                        (prop ? targetItem[prop] : targetItem) === (prop ? item[prop] : item),
                ),
        )
    );
}
