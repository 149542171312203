import { PopupsT, SystemT } from '@global/types';
import { dispatcher } from '@redux/redux';

export default function handlerPopup<T extends SystemT>(
    name: PopupsT[T],
    { ...props }: { isShow: boolean } & Record<any, unknown>,
): void {
    dispatcher<T>({ type: name as any, data: props });

    document.dispatchEvent(new CustomEvent('popupChange', { detail: { isShow: props.isShow } }));
}
