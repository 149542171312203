import React from 'react';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import Table from '@components/crm/table/Table.tsx';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { modelName, modelId, models, counter = 0 } = this.state;
    const info = this.getInfo();

    return (
        <>
            <Widget
                name="modelLogs"
                title={info.title}
                descriptionInner={counter}
                counter={counter}
                actions={this.getActions()}
                className="_white"
                withBack={false}
                filterQuery={(this as I).getFilterQuery?.()}
            >
                <Table
                    name="modelLogs"
                    isNotLink={true}
                    isInit={!!modelName}
                    isWrap={false}
                    otherData={{
                        modelName,
                        modelId,
                        models,
                    }}
                    callback={this.tableCallback!.bind(this)}
                    init={this.tableInit!.bind(this)}
                    maxHeight={500}
                    minHeight={500}
                    getRowClassName={() => '_colCenter'}
                />
            </Widget>
        </>
    );
};

export default renderContent;
