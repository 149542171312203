import getFormatedDate from '@functions/getFormatedDate';

import AlertI from '../../types';

const contractHasMvd = {
    title: 'Предупреждение',
    description(this: AlertI) {
        const info = this.state.info as {
            fullName: string;
            contract: { number: number; date: string };
        };

        return `На исполнителя <b>${info.fullName}</b> есть действующий договор <b>№${info.contract.number} от ${getFormatedDate({ date: new Date(info.contract.date) })}</b>, на который подано МВД уведомление. Создание нового приведет к его <b>расторжению</b>, а также к повторному <b>созданию МВД уведомления</b>`;
    },
    buttonText: 'Не создавать',
    cancelText: 'Всё равно создать',
} as const;

export default contractHasMvd;
