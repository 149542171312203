import getUserInfo from '../getUserInfo';

export default function getPreviewName({ company, user, type }) {
    if (process.env.REACT_APP_SYSTEM === 'crm') {
        if (type === 'support') {
            return 'CRM LIVECARGO';
        }

        if (user && !user.name && !user.firstName && !user.secondName) {
            return 'Unknown';
        }

        if (!company) {
            return getUserInfo({ type: 'fullnameShort', user });
        }

        return `${getUserInfo({ type: 'fullnameShort', user })} — ${company.name}`;
    }

    return 'Поддержка LIVECARGO';
}
