import I from '../types.ts';

const checkBorders: I['checkBorders'] = function (x, y) {
    const { isZoom, imageLeft, imageTop } = this.state;
    const { diffWidth, diffHeight, width, height } = this.getSize();
    const imageParent = this.parent.current?.parentNode as HTMLElement;

    let leftBorder = 0;
    let rightBorder = 0;
    let topBorder = 0;
    let bottomBorder = 0;

    if (isZoom) {
        if (imageParent.offsetWidth < width) {
            leftBorder += diffWidth / 2 - imageLeft!;
            rightBorder -= diffWidth / 2 - imageLeft!;
        }

        if (imageParent.offsetHeight < height) {
            topBorder += diffHeight / 2 - imageTop!;
            bottomBorder -= diffHeight / 2 - imageTop!;
        }
    }

    // console.log(leftBorder);
    // console.log(rightBorder);
    // console.log(topBorder);
    // console.log(bottomBorder);

    // console.log('x', x);
    // console.log('y', y);

    if (x > leftBorder) {
        x = leftBorder;
    }

    if (x < rightBorder) {
        x = rightBorder;
    }

    if (y > topBorder) {
        y = topBorder;
    }

    if (y < bottomBorder) {
        y = bottomBorder;
    }

    return { x, y };
};

export default checkBorders;
