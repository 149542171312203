const corporationRoleSection = {
    colsOrder: ['name', 'read', 'update', 'create', 'delete', 'sign'],
    cols: {
        name: { content: 'Подраздел', width: 30 },
        read: { content: 'Просмотр', width: 12 },
        update: { content: 'Редактирование', width: 16 },
        create: { content: 'Создание', width: 13 },
        delete: { content: 'Удаление', width: 13 },
        sign: { content: 'Подписание', width: 16 },
    },
    deletesCols: [],
    emptyInfo: {
        title: 'Ролей пока нет',
    },
} as const;

export default corporationRoleSection;
