import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';
import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import Icon from '@components/icon/Icon.tsx';
import copyInBuffer from '@functions/copyInBuffer.js';

import I from '../types.ts';

const renderContent: I['renderContent'] = function () {
    const { model, loadingKey, error, isJoin } = this.state;
    const title = isJoin ? 'Приглашение исполнителя' : 'Создание исполнителя';

    return (
        <>
            <div className="v2popup__head">
                <div className="v2popup__title">{title}</div>
                <div className="v2popup__close _CLICK _top" onClick={this.close.bind(this)}>
                    <Icon name="popup-close" />
                </div>
            </div>
            <div className="v2popup__content _CENTER _NOSCROLL">
                <div className="v2popup__contentInner">
                    {model && (
                        <>
                            <WidgetCard
                                name="executorCreate"
                                model={model}
                                change={this.change.bind(this)}
                                getValue={this.getValue.bind(this)}
                                withHead={false}
                                className="_empty"
                                error={error}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="v2popup__foot">
                <div className="v2popupFoot">
                    <div className="v2popupFoot__inner _ROW">
                        <div className="v2popupFoot__button">
                            <Button
                                className="_main"
                                loading={loadingKey === 'save'}
                                onClick={this.save.bind(this)}
                            >
                                Сохранить
                            </Button>
                        </div>
                        {isJoin && (
                            <>
                                <div className="v2popupFoot__info">
                                    <p className="v2popupFoot__infoTitle">
                                        Мы отправим СМС с ссылкой
                                    </p>
                                    <AnimateChange
                                        className="v2popupFoot__infoText _dynamic"
                                        renderKey={model?.scriptId || false}
                                        styles={['height']}
                                    >
                                        <>
                                            {model?.scriptId ? (
                                                <div
                                                    className="v2popupFoot__infoText _link _CLICK"
                                                    onClick={() => {
                                                        copyInBuffer(
                                                            `https://app.livecargo.ru/join/${model?.scriptLink}`,
                                                        );
                                                    }}
                                                >
                                                    https://app.livecargo.ru/join/
                                                    {model?.scriptLink}
                                                </div>
                                            ) : (
                                                <div className="v2popupFoot__infoText">
                                                    Выберите сценарий
                                                </div>
                                            )}
                                        </>
                                    </AnimateChange>
                                </div>
                            </>
                        )}
                    </div>
                    <AnimateChange
                        className="v2popupFoot__error"
                        renderKey={error?.text}
                        styles={['height']}
                        paramsParent={{ width: true, height: 'auto' }}
                    >
                        <>{error?.text}</>
                    </AnimateChange>
                </div>
            </div>
        </>
    );
};

export default renderContent;
