import React from 'react';

import Input from '@components/input/Input.tsx';

import I from '../types.ts';

const renderHead: I['renderHead'] = function () {
    const { doc, change: propsChange, error, checkChangeName } = this.props;
    const { name, files } = doc;
    const canChangeName = !checkChangeName || checkChangeName(doc);

    return (
        <div className="v2widgetDoc__head _ROW">
            <div className={`v2widgetDoc__name ${!canChangeName ? '_disabled' : ''}`}>
                <Input
                    className="_grey"
                    support="Название документа"
                    name={`doc-${doc._id}-name`}
                    value={name}
                    onChange={async ({ value }) => {
                        await propsChange({ name: value });
                    }}
                    error={error?.name === `docs.${doc._id}.name`}
                    disabled={!canChangeName}
                />
            </div>
            <div className="v2widgetDoc__counter">
                {files.filter((file) => file.file.fullSrc).length}/{files.length}
            </div>
        </div>
    );
};

export default renderHead;
