import React from 'react';
import PropTypes from 'prop-types';

// import setAnimate from '../../../functions/setAnimate';

class MailingProgressCircle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    setProgress(progress) {
        const circle = this.parent.current.querySelector('circle');

        const rad = circle.getBoundingClientRect().width / 2;
        const len = 2 * Math.PI * rad;

        circle.style.strokeDasharray = `${len * progress}px ${len}px`;
    }

    animateProgress(endProgress) {
        // const startProgress = this.progress;

        // this.setProgress(startProgress);

        this.progress = endProgress;

        this.setProgress(this.progress);
    }

    endProgress = null;

    progress = 0;

    checkChangeProgress(isForce) {
        const { progress } = this.props;

        if (progress !== this.endProgress) {
            if (this.animateId) {
                cancelAnimationFrame(this.animateId);
            }

            this.endProgress = progress;

            this.animateProgress(progress, isForce);
        }
    }

    componentDidMount() {
        this.checkChangeProgress(true);
    }

    componentDidUpdate() {
        this.checkChangeProgress();
    }

    componentWillUnmount() {
        if (this.animateId) {
            cancelAnimationFrame(this.animateId);
        }
    }

    render() {
        return (
            <>
                <svg ref={this.parent}>
                    <circle strokeDasharray={`0px 0px`} />
                </svg>
            </>
        );
    }
}

export default MailingProgressCircle;

MailingProgressCircle.propTypes = {
    progress: PropTypes.number,
};
