import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from './Button.jsx';
import Animate from './Animate.jsx';
import { dispatcher } from '../redux/redux';

class Cookie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { user, isAcceptCookie } = this.props;

        return (
            <Animate className="body__cookie" isShow={!!user && !isAcceptCookie}>
                <div className="cookie _row">
                    <img src={require('../img/cookie-icon.png')} alt="" className="cookie__icon" />
                    <div className="cookie__content">
                        ООО «Лайвкарго» использует файлы cookie, данные об&nbsp;IP-адресе
                        и&nbsp;местоположении с&nbsp;целью персонализации сервисов и&nbsp;повышения
                        удобства пользования веб-сайтом. Если вы не&nbsp;хотите, чтобы ваши
                        пользовательские данные обрабатывались — пожалуйста, ограничьте их
                        использование в&nbsp;своём браузере.
                    </div>
                    <div className="cookie__button">
                        <Button
                            className="_crm _mainNotBorder"
                            onClick={() => {
                                localStorage.setItem('isAcceptCookie', true);
                                dispatcher({ type: 'isAcceptCookie', data: true });
                            }}
                        >
                            Принять
                        </Button>
                    </div>
                </div>
            </Animate>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        isAcceptCookie: state.isAcceptCookie,
    };
}

export default connect(mapStateToProps)(Cookie);

Cookie.propTypes = {
    user: PropTypes.object,
    isAcceptCookie: PropTypes.bool,
};
