import getPermissions from '@functions/crm/getPermissions.ts';
import handlerPopup from '@functions/handlerPopup.ts';
import { CursorActionsItemT } from '@global/types.ts';

import InvitesI from '../types.ts';

const getMoreActions: InvitesI['getMoreActions'] = function ({ user }) {
    const { user: globalUser } = this.props;
    const actions = [
        {
            key: 'logs',
            name: 'logs',
            onClick: async () => {
                handlerPopup('logsPopup', {
                    isShow: true,
                    modelName: 'user',
                    modelId: user._id,
                });
            },
        },
    ] as CursorActionsItemT[];

    const currentCorporation = user.corporations.find(
        (item) => item.id === globalUser?.idOfCurrentCorporation,
    );

    if (globalUser && user._id !== globalUser._id) {
        const userCorporation = user.corporations.find(
            (item) => item.id === globalUser.idOfCurrentCorporation,
        );

        if (
            getPermissions(globalUser, {
                key: 'settings',
                items: [
                    {
                        key: 'users',
                        rules: ['delete'],
                    },
                ],
            }) &&
            ((!!userCorporation && userCorporation.role !== 'holder') ||
                user.corporations.filter((corporation) => corporation.id !== 'empty').length === 0)
        ) {
            actions.push({
                key: 'delete',
                name: 'deleteUser',
                text: 'Удалить сотрудника',
                onClick: async () => {
                    handlerPopup('alertPopup', {
                        isShow: true,
                        type: 'deleteUser',
                        info: [
                            ...(user.fullName ? [user.fullName] : []),
                            `<span class="_NOWRAP">${user.email}</span>`,
                        ].join(', '),
                        callback: this.deleteUser.bind(this, { user }),
                        successCallback: () => {
                            this.tableUpdateItems!();
                        },
                    });
                },
            });
        }

        if (
            currentCorporation &&
            getPermissions(globalUser, {
                key: 'settings',
                items: [
                    {
                        key: 'users',
                        rules: ['update'],
                    },
                ],
            }) &&
            !!userCorporation &&
            userCorporation.role !== 'holder'
        ) {
            actions.push({
                key: 'edit',
                name: 'editUser',
                text: 'Редактировать',
                onClick: async () => {
                    handlerPopup<'crm'>('userPopup', {
                        isShow: true,
                        user: {
                            _id: user._id,
                            firstName: user.firstName,
                            secondName: user.secondName,
                            thirdName: user.thirdName,
                            email: user.email,
                            role: userCorporation.role,
                            roleName: userCorporation.roleName,
                        },
                        callback: this.tableUpdateItems!.bind(this),
                    });
                },
            });
        }
    }

    return actions;
};

export default getMoreActions;
