const docs = {
    colsOrder: ['name', 'type', 'holder', 'number', 'date', 'actionDate'],
    cols: {
        name: {
            content: 'Название',
            width: 23,
        },
        type: {
            content: 'Тип',
            width: 12,
        },
        holder: {
            content: 'Владелец',
            width: 23,
        },
        number: {
            content: 'Номер',
            width: 14,
        },
        date: {
            content: 'Дата выдачи',
            width: 14,
        },
        actionDate: {
            content: 'Дата окончания',
            width: 14,
        },
    },
    deletesCols: [],
    url: 'docs',
    emptyInfo: {
        title: 'Документов пока нет',
        description: 'Вы можете создать документ по кнопке справа',
    },
} as const;

export default docs;
