import LogsPopupI from '../types.ts';

const getActions: LogsPopupI['getActions'] = function () {
    return [
        {
            type: 'filter',
            name: 'filter',
            group: 'filter',
            filterName: 'modelLogs',
            exportName: 'modelLogs',
        },
    ];
};

export default getActions;
