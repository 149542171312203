import RoleT from '@global/models/Role.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getModel: I['getModel'] = async function () {
    const { levels } = this.props;
    const id = levels[3];

    try {
        const response = await getListItems({ url: 'role', query: [{ key: 'id', value: id! }] });
        const { item } = response;

        this.setState({ model: item as RoleT });
    } catch (error) {}
};

export default getModel;
