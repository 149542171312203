import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setNotification from '@functions/setNotification.js';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const mailingHandler: I['mailingHandler'] = async function (this: I, { id, action }) {
    try {
        const response = await axios.put<{}, { data: ReqResponseT<{ message?: string }> }>(
            `${process.env.REACT_APP_API}/mailing`,
            { id, action },
            { headers: getHeaders() },
        );

        const { success, data } = response.data;

        if (success) {
            return;
        }

        const { message } = data;

        if (message === 'Executor zero counter') {
            setNotification({ notification: 'mailing-executor-zero-counter' });
        }
    } catch (error) {}

    return Promise.reject();
};

export default mailingHandler;
