import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import Search from '../../Search.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import ListScroll from '../../ListScroll.jsx';
import ActionChange from '../../ActionChange.jsx';
import Table from '../manual/Table.jsx';
import getCountries from '../../../requests/getCountries';
import getUsers from '../../../requests/getUsers';
import getTowns from '../../../requests/getTowns';
import handlerLoading from '../../../functions/handlerLoading';

import Animate from '../../Animate.jsx';
import Loader from '../../Loader.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import sortForAlphabet from '../../../functions/sortForAlphabet';
import setAnimate from '../../../functions/setAnimate';
import handlerPopup from '../../../functions/app/handlerPopup';
import Avatar from '../../Avatar.jsx';

class ListPopup extends Table {
    constructor(props) {
        super(props);
        this.state = {
            currentItemId: null,
        };

        this.renderItem = this.renderItem.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.hide = this.hide.bind(this);
        this.save = this.save.bind(this);
    }

    classNameItem = '.listPopup__item';

    stepCounter = 20;

    info = {
        countries: {
            title: 'Выбор гражданства',
            subTitle: 'Список стран',
            search: 'Начните вводить название страны',
        },
        towns: {
            title: 'Выбор город',
            subTitle: 'Список городов',
            search: 'Начните вводить название города',
        },
        users: {
            title: 'Назначение ответственного',
            subTitle: 'Список сотрудников',
            search: 'Начните вводить ФИО или телефон',
        },
    };

    getQueryForRequest() {
        const { search, currentItemId } = this.state;
        const query = super.getQueryForRequest();

        if (search?.length > 2) {
            query.params.push({ key: 'search', value: search });
        }

        if (currentItemId) {
            query.params.push({ key: 'currentId', value: currentItemId });
        }

        return query;
    }

    getItemsReq({ query, signal }) {
        const { currentName, data } = this.props;

        return new Promise((resolve) => {
            if (currentName === 'countries') {
                if (data?.executorOrganization === 'SMZ') {
                    query.params.push({ key: 'executorOrganization', value: 'SMZ' });
                }

                getCountries({ ...query, signal }).then(({ countries, isLimit, counter }) => {
                    resolve({ items: countries, isLimit, counter });
                });
            } else if (currentName === 'towns') {
                getTowns({ ...query, signal }).then(({ towns, isLimit, counter }) => {
                    resolve({ items: towns, isLimit, counter });
                });
            } else if (currentName === 'users') {
                query.params.push({ key: 'dataType', value: 'list' });

                getUsers({ ...query, signal }).then(({ users, isLimit, counter }) => {
                    resolve({ items: users, isLimit, counter });
                });
            }
        });
    }

    getItems() {
        const query = this.getQueryForRequest();

        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        return new Promise((resolve) => {
            this.getItemsReq({ query, signal: this.controller.signal }).then(
                ({ items, isLimit, counter }) => {
                    this.setItems(items, false, isLimit, counter).then(() => {
                        resolve();
                    });
                },
            );
        });
    }

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ search: value, isLoadingFilter: true }, () => {
                    clearTimeout(this.searchTimer);

                    if (!value || value.length > 2) {
                        this.searchTimer = setTimeout(() => {
                            this.updateItems();
                        }, 300);
                    }

                    resolve();
                });
            }
        });
    }

    scrollToTop() {
        const content = this.parent.current.querySelector('.listPopup__contentInner');
        const { scrollTop } = content;

        if (content) {
            setAnimate({
                draw: (progress) => {
                    content.scrollTop = scrollTop - progress * scrollTop;
                },
                duration: 300,
                callback: () => null,
            });
        }
    }

    getItemSrc({ item }) {
        const { currentName } = this.props;

        if (currentName === 'users') {
            if (item.icon) {
                return require(`../../../img/icons/users/icon-${item.icon}.png`);
            }

            return null;
        }

        return `${process.env.REACT_APP_STATIC}/${item.iconPath}`;
    }

    renderItem({ item, key: order }) {
        const { currentItemId, counter } = this.state;
        const isCurrent = item._id === currentItemId;

        return (
            <div
                className={`listPopup__item ${isCurrent ? '_current' : ''}`}
                style={{ zIndex: counter - order }}
            >
                <div className="listPopup__itemInner">
                    <div className="listPopup__itemAvatar _col">
                        <Avatar holder={item} src={this.getItemSrc({ item })} />
                    </div>
                    <div className="listPopup__itemTitle">{item.content}</div>
                    {item.description && (
                        <div className="listPopup__itemDescription">{item.description}</div>
                    )}
                    <ActionChange
                        className="listPopup__itemAction _click"
                        isShow={true}
                        isSmoothShow={true}
                        isHas={isCurrent}
                        onClick={() => {
                            this.setState(
                                { currentItemId: isCurrent ? null : item._id },
                                this.scrollToTop,
                            );
                        }}
                    />
                </div>
            </div>
        );
    }

    save() {
        const { currentItemId, items = [] } = this.state;
        const { callback } = this.props;
        const currentItem = items.find((item) => item._id === currentItemId);

        if (this.checkChange()) {
            handlerLoading.call(this, 'save').then(() => {
                try {
                    callback(currentItemId, currentItem).then(() => {
                        handlerLoading.call(this, null);

                        this.hide();
                    });
                } catch (error) {
                    handlerLoading.call(this, null);

                    this.hide();
                }
            });
        }
    }

    getItemsModels() {
        const { currentItemId } = this.state;
        const { currentName } = this.props;

        let items = sortForAlphabet({ arr: this.state.items || [], prop: 'content' });

        if (currentName === 'users') {
            items = this.state.items || [];
        }

        return items.sort((a, b) => {
            const aWeight = a._id === currentItemId ? 1 : 0;
            const bWeight = b._id === currentItemId ? 1 : 0;

            return bWeight - aWeight;
        });
    }

    checkChange() {
        const { savedcurrentItemId, currentItemId } = this.state;

        return savedcurrentItemId !== currentItemId;
    }

    hide() {
        const { popupName } = this.props;

        if (process.env.REACT_APP_SYSTEM === 'crm') {
            handlerPopup({ name: 'listPopup', currentName: null });
        } else {
            handlerPopup({ name: popupName, isShow: false, currentName: null });
        }
    }

    componentDidMount() {
        const { currentId } = this.props;

        this.setState({ currentItemId: currentId, savedcurrentItemId: currentId }, () => {
            this.getItems(true);
        });
    }

    render() {
        const {
            isReady,
            isLimit,
            isDisabledScroll,
            isShowLoaderList = false,
            search,
            isLoadingFilter = false,
            counter = 0,
            currentItemId,
            loadingKey,
        } = this.state;
        const { currentName } = this.props;
        const info = this.info[currentName];
        const items = this.getItemsModels();

        return (
            <div ref={this.parent} className={`listPopup _col _${currentName}`}>
                <div className="listPopup__inner">
                    <div className="listPopup__head">
                        <div className="listPopup__title">{info?.title}</div>
                        <i className="listPopup__close _click" onClick={this.hide}>
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className="listPopup__info">
                        <div className="listPopup__search">
                            <Search
                                support={info.search}
                                className="_grey _filter"
                                value={search}
                                callback={this.handlerSearch}
                            />
                        </div>
                        <p className="listPopup__subTitle">
                            {info.subTitle}:{' '}
                            <AnimateChangeUp
                                className="listPopup__subTitleItem"
                                renderKey={counter}
                            >
                                ({counter})
                            </AnimateChangeUp>
                        </p>
                    </div>
                    <div
                        className={`listPopup__content ${
                            isReady && !isLoadingFilter ? '_ready' : ''
                        }`}
                    >
                        <div className="listPopup__contentInner">
                            <ListScroll
                                isInit={isReady}
                                getParent={() =>
                                    this.parent.current?.querySelector('.listPopup__contentInner')
                                }
                                callback={this.getMoreItems}
                                startCounter={this.stepCounter}
                                stepCounter={this.stepCounter}
                                maxCounter={Infinity}
                                lengthCurrent={items.length}
                                handlerLoaderList={this.handlerLoaderList}
                                isLimit={isLimit}
                                isDisabledScroll={isDisabledScroll || !isReady || isLoadingFilter}
                            >
                                <ListAbsoluteMain
                                    className="listPopup__items _col"
                                    items={items}
                                    renderItem={this.renderItem}
                                    classNameItem="listPopup__item"
                                    prop="_id"
                                    styles={['height']}
                                    keyRender={currentItemId}
                                    offsetHeight={this.checkChange() ? 72 : 0}
                                    paramsParent={{ width: true }}
                                    // name="test"
                                />
                            </ListScroll>
                        </div>
                        <Animate className="listPopup__button" isShow={this.checkChange()}>
                            <div className="listPopup__buttonInner">
                                <Button
                                    className="_main"
                                    onClick={this.save}
                                    showLoader={loadingKey === 'save'}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </Animate>
                        <Animate
                            className="listPopup__empty _loader"
                            isShow={isReady && items.length === 0 && !isLoadingFilter}
                        >
                            <div className="empty _col _block _notBack _notPad">
                                <div className="empty__inner">
                                    <div className="empty__title">Ничего не найдено</div>
                                    <p className="empty__content">Попробуйте изменить фильтр</p>
                                </div>
                            </div>
                        </Animate>
                        <Animate
                            className="listPopup__loader _loader"
                            isShow={!isReady || isLoadingFilter}
                        >
                            <div className="listPopup__loaderItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                        <Animate
                            className={`listPopup__loaderScroll _loaderScroll _white ${
                                this.checkChange() ? '_bottom' : ''
                            }`}
                            isShow={isShowLoaderList}
                        >
                            <div className="listPopup__loaderScrollItem _loaderItem">
                                <Loader className="_main" />
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ListPopup);

ListPopup.propTypes = {
    currentName: PropTypes.string,
    currentId: PropTypes.string,
    callback: PropTypes.func,
    data: PropTypes.object,
};
