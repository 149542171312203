import CorporationsI from '../types.ts';

const getActions: CorporationsI['getActions'] = function () {
    return [
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'corporations',
        },
    ];
};

export default getActions;
