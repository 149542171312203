import AlertI from '../../types';

const deleteJoin = {
    title: 'Подтвердите <br />удаление приглашения',
    description(this: AlertI) {
        const { info } = this.state;

        return `Данное действие нельзя отменить. Вы уверены, что хотите удалить приглашение <b>${info}</b>?`;
    },
    buttonText: 'Удалить приглашение',
} as const;

export default deleteJoin;
