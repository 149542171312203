import React from 'react';

import Inner from '../components/Inner/Inner.tsx';
import Main from '../components/Main/Main.tsx';

const pages = {
    'pays-groups-main': {
        render() {
            return (
                <>
                    <Main />
                </>
            );
        },
    },
    'pays-groups-inner': {
        render() {
            return (
                <>
                    <Inner />
                </>
            );
        },
    },
} as const;

export default pages;
