import React from 'react';

import Icon from '@components/icon/Icon.tsx';
import Link from '@components/link/Link.tsx';
import getDiffTime from '@functions/getDiffTime.ts';
import getEndText from '@functions/getEndText.ts';
import getFormatedDate from '@functions/getFormatedDate.ts';
import getUserName from '@functions/getUserName.ts';
import setDateFromStr from '@functions/setDateFromStr.ts';
import setSpacesInText from '@functions/setSpacesInText.ts';
import { setCursorSupport } from '@functions/supportHandler.ts';
import ExecutorMvdT from '@global/models/ExecutorMvd.ts';
import { CursorActionsItemT } from '@global/types.ts';

import More from '../components/More.tsx';

import { RenderColsT } from '../../types.ts';

const renderExecutorsMvds: RenderColsT<'executorsMvds'> = function ({ name: colName, item }) {
    const executorMvd = item as ExecutorMvdT;
    const isAdmin = this.checkAdmin();
    let content;
    let className;

    if (colName === 'name') {
        content = (
            <>
                <Link
                    pageName="manual-executors-inner-main"
                    ids={{ '3': executorMvd.executorId }}
                    className="v2table__text _link _full"
                >
                    {executorMvd.fullName || '–'}
                </Link>
                , {executorMvd.countryName}
                <div
                    className={`v2table__text _block ${
                        executorMvd.contractInactive && executorMvd.type !== 'reject' ? '_grey' : ''
                    }`}
                >
                    {executorMvd.type === 'reject' ? (
                        <>На расторжение договора №{executorMvd.contractNumber}</>
                    ) : (
                        <>
                            Договор №{executorMvd.contractNumber}{' '}
                            {executorMvd.contractInactive ? '(Расторгнут)' : ''}
                        </>
                    )}
                </div>
            </>
        );
    }

    if (colName === 'executorStatus') {
        const supportProps =
            executorMvd.inactiveDate &&
            setCursorSupport(
                {
                    _id: `executorStatus-${executorMvd._id}`,
                    content: getFormatedDate({
                        date: new Date(executorMvd.inactiveDate),
                        type: 'full',
                    }),
                    dir: 'top',
                },
                { targetClassName: '.v2table__statusInfo', className: '' },
            );

        let statusText;
        let statusClassName = '';

        if (executorMvd.executorStatus === 'active') {
            statusText = 'Подключен';
            statusClassName = '_success';
        }

        if (executorMvd.executorStatus === 'inactive') {
            statusText = 'Отключен';
            statusClassName = '_alert';
        }

        content = (
            <div className={`v2table__status ${statusClassName}`}>
                {statusText}
                {executorMvd.executorStatus === 'inactive' && executorMvd.inactiveDate && (
                    <i className="v2table__statusInfo _CLICK" {...supportProps}>
                        <Icon name="info" />
                    </i>
                )}
            </div>
        );
    }

    if (colName === 'date') {
        const text = [];

        if (executorMvd.date && setDateFromStr(executorMvd.date)) {
            text.push(
                `Дата ${getFormatedDate({ date: setDateFromStr(executorMvd.date)!, isShortYear: true })}`,
            );
        }

        if (isAdmin && executorMvd.number) {
            text.push(`№${executorMvd.number}`);
        }

        if (text.length === 0) {
            text.push('–');
        }

        content = <>{text.join(', ')}</>;
    }

    if (colName === 'number') {
        content = <>{executorMvd.number || '–'}</>;
    }

    if (colName === 'status') {
        let statusText;
        let statusClassName = '';

        if (executorMvd.status === 'notsent') {
            statusText = (
                <>
                    Не подано
                    {executorMvd.alarmDate &&
                    (!executorMvd.contractInactive || executorMvd.type === 'reject') ? (
                        <>
                            <br />
                            (Осталось {getDiffTime(executorMvd.alarmDate)})
                        </>
                    ) : (
                        <></>
                    )}
                </>
            );

            statusClassName =
                !executorMvd.contractInactive || executorMvd.type === 'reject' ? '_alert' : '';
        }

        if (executorMvd.status === 'sent') {
            statusText = 'Подано';
            statusClassName = '_success';
        }

        if (executorMvd.status === 'complete') {
            statusText = 'Получено';
            statusClassName = '_success';
        }

        if (executorMvd.status === 'inactive') {
            statusText = 'Не актуально';
            statusClassName = '_wait';
        }

        if (executorMvd.status === 'reject') {
            statusText = 'Отказ';
            statusClassName = '_alert';
        }

        const supportProps =
            executorMvd.statusDate &&
            setCursorSupport(
                {
                    _id: `status-${executorMvd._id}`,
                    content: getFormatedDate({
                        date: new Date(executorMvd.statusDate),
                        type: 'full',
                    }),
                    dir: 'top',
                },
                { targetClassName: '.v2table__statusInfo', className: '' },
            );

        className = '_innerCol';

        content = (
            <>
                <div className={`v2table__status ${statusClassName}`}>
                    {statusText}
                    {executorMvd.statusDate && (
                        <i className="v2table__statusInfo _click" {...supportProps}>
                            <Icon name="info" />
                        </i>
                    )}
                </div>
                <div className={`v2table__text _block _grey`}>
                    {executorMvd.files.length === 0 ? (
                        'Файлов нет'
                    ) : (
                        <>
                            {executorMvd.files.length}{' '}
                            {getEndText(executorMvd.files.length, ['файл', 'файла', 'файлов'])}
                        </>
                    )}
                </div>
            </>
        );
    }

    if (colName === 'comment') {
        const { otherData } = this.props;
        const getMoreActions = otherData?.getMoreActions as (data: {
            executorMvd: ExecutorMvdT;
        }) => CursorActionsItemT[];

        className = '_withMore';
        content = (
            <>
                <More
                    id={`executorMvd-${executorMvd._id}`}
                    items={getMoreActions({ executorMvd })}
                />
                {executorMvd.comment ? (
                    <>
                        <div className="v2table__comment">
                            <div className="v2table__text _grey">
                                {executorMvd.commentCreater
                                    ? getUserName({
                                          user: executorMvd.commentCreater,
                                          type: 'short',
                                      })
                                    : '–'}
                                :{' '}
                            </div>
                            <div
                                className="v2table__text _wrapWord"
                                dangerouslySetInnerHTML={{
                                    __html: setSpacesInText(executorMvd.comment),
                                }}
                            ></div>
                        </div>
                    </>
                ) : (
                    <>–</>
                )}
            </>
        );
    }

    return { content, className };
};

export default renderExecutorsMvds;
