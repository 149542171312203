import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerLoading from '../../functions/handlerLoading';

import HandlerFile from '../../classes/File';
import uploadFile from '../../requests/uploadFile';
import Animate from '../Animate.jsx';
import BorderDashed from '../BorderDashed.jsx';
import Icon from '../Icon.jsx';
import Loader from '../Loader.jsx';

class EditorImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentWidth: 100,
            uploadProcess: false,
        };

        this.uploadFile = this.uploadFile.bind(this);
        this.checkCloseWindow = this.checkCloseWindow.bind(this);

        this.parent = React.createRef();
    }

    handlerFile = new HandlerFile({});

    async uploadFile(e) {
        const { currentWidth } = this.state;
        const { callback } = this.props;
        const formData = new FormData();

        await handlerLoading.call(this, 'save');

        const { resultFiles } = await this.handlerFile.uploadFiles({ target: e.target, formData });

        if (resultFiles && resultFiles[0]) {
            try {
                const { fullSrc } = await uploadFile({ formData });

                callback(e, { src: fullSrc, width: currentWidth });

                handlerLoading.call(this, null, { uploadProcess: false });
            } catch (error) {
                handlerLoading.call(this, null, { uploadProcess: false });
            }
        } else {
            handlerLoading.call(this, null, { uploadProcess: false });
        }
    }

    checkCloseWindow() {
        setTimeout(() => {
            if (!this.state.loadingKey) {
                this.setState({ uploadProcess: false });
            }
        }, 500);
    }

    componentDidMount() {
        window.addEventListener('focus', this.checkCloseWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.checkCloseWindow);
    }

    render() {
        const { currentWidth, loadingKey, uploadProcess } = this.state;

        return (
            <div className={`editor__actionAlert ${uploadProcess || loadingKey ? '_show' : ''}`}>
                <div className="editor__actionAlertInner _windowWithCursor _topRight">
                    <div className="editor__actionAlertHead">
                        <div className="editor__actionAlertTitle">Изображение</div>
                        <div className="editor__actionAlertDescription">
                            Загрузите файл в поле ниже
                        </div>
                    </div>
                    <div className="editor__actionAlertContent _col">
                        <label
                            className="editor__actionAlertFile"
                            onClick={() => {
                                this.setState({ uploadProcess: true });
                            }}
                        >
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={this.uploadFile}
                            />
                            <div className="editor__actionAlertFileBox _col">
                                <Animate
                                    className="editor__actionAlertFileLoader _loader"
                                    isShow={loadingKey === 'save'}
                                >
                                    <div className="editor__actionAlertFileLoaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                                <div className="editor__actionAlertFileBack">
                                    <BorderDashed className="_upload2" rx={14} ry={14} />
                                </div>
                                <i className="editor__actionAlertFileIcon">
                                    <Icon name="add" />
                                </i>
                                <div className="editor__actionAlertFileTitle">Загрузите файл</div>
                                <div className="editor__actionAlertFileDescription">
                                    Нажмите и выберите <br />
                                    нужное изображение
                                </div>
                            </div>
                        </label>

                        <div className="editor__actionAlertSizes _col">
                            <p className="editor__actionAlertSizesSupport">Выберите ширину</p>
                            <div className="editor__actionAlertSizesItems _row">
                                {[33, 50, 100].map((width) => (
                                    <label className="editor__actionAlertSize _click" key={width}>
                                        <input
                                            type="radio"
                                            className="editor__actionAlertSizeInput"
                                            checked={currentWidth === width}
                                            onChange={() => {
                                                this.setState({ currentWidth: width });
                                            }}
                                        />
                                        <div className="editor__actionAlertSizeInner _col">
                                            <div className="editor__actionAlertSizeValue">
                                                {width}%
                                            </div>
                                            <div className="editor__actionAlertSizeBox _col">
                                                <div
                                                    className="editor__actionAlertSizeBoxItem"
                                                    style={{ width: `${width}%` }}
                                                />
                                                <div className="editor__actionAlertSizeCurrent _col">
                                                    <i className="editor__actionAlertSizeCurrentIcon">
                                                        <Icon name="done-3" />
                                                    </i>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(EditorImage);

EditorImage.propTypes = {
    callback: PropTypes.func,
};
