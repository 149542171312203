export default function getAllNodes(elem, list = [], withBr) {
    if (elem.childNodes.length) {
        elem.childNodes.forEach((childNode) => getAllNodes(childNode, list, withBr));

        return list;
    }

    if (elem.nodeType === 3 || (withBr && elem.nodeName === 'BR')) {
        list.push(elem);
    }

    return list;
}
