import BalanceI from '../types.ts';

const getActions: BalanceI['getActions'] = function () {
    return [
        // {
        //     type: 'export',
        //     name: 'export',
        //     group: 'buttons',
        //     exportName: 'operations',
        // },
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'corporationBalances',
        },
    ];
};

export default getActions;
