import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import Icon from '../../../../components/Icon.jsx';
import Link from '../../../../components/Link.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import removeTransition from '../../../../functions/removeTransition.ts';
import searchInText from '../../../../functions/searchInText.jsx';
import ImageLazy from '../../../../components/ImageLazy.jsx';
import getArticles from '../../../../requests/getArticles';

class ContentInfoMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingFilter: false,
        };

        this.renderCard = this.renderCard.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
        this.filterSection = this.filterSection.bind(this);
        this.filterListItem = this.filterListItem.bind(this);

        this.parent = React.createRef();
    }

    checkSearch() {
        const { search } = this.props;

        return search && search.trim().length > 2;
    }

    getApiPoint() {
        if (process.env.REACT_APP_ENV === 'dev') {
            return 'https://crm.livecargo.ru/server-api/static';
        }

        return process.env.REACT_APP_STATIC;
    }

    renderCard({ item: section, prop: _id }) {
        if (_id === 'info') {
            return (
                <div className="contentInfoPublic__card _col _empty">
                    <div className="empty _col _block _notBack">
                        <div className="empty__title">По вашему запросу ничего не найдено</div>
                        <div className="empty__content">
                            Если вы не нашли информацию, которую искали — напишите нам
                        </div>
                    </div>
                </div>
            );
        }

        const sections = this.getSections();
        const index = sections.findIndex((innerSection) => innerSection._id === section._id);

        return (
            <div
                className={`contentInfoPublic__card _col ${index % 2 === 1 ? '_odd' : ''}`}
                key={section._id}
                data-key={section._id}
            >
                <div className="contentInfoPublic__cardInner">
                    <div className="contentInfoPublic__cardCounter _col">{index + 1}</div>
                    <div className="contentInfoPublic__cardTitle">
                        {searchInText({ search: this.props.search, text: section.name }).content}
                    </div>
                    <ListAbsoluteMain
                        className="contentInfoPublic__cardList _col"
                        items={section.items.filter((item) =>
                            this.filterListItem({ title: item.title, sectionName: section.name }),
                        )}
                        renderItem={this.renderListItem}
                        classNameItem="contentInfoPublic__cardListItem"
                        prop="_id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isSmoothShow={true}
                        callback={({ isInit, isChangeLen }) => {
                            if (isInit) {
                                removeTransition({
                                    item: `.contentInfoPublic__card[data-key="${section._id}"] .contentInfoPublic__cardListItem`,
                                    isCurrent: true,
                                });
                            }

                            if (isChangeLen) {
                                this.setState({ updatedKey: new Date().getTime() });
                            }
                        }}
                    />
                </div>
                <ImageLazy
                    src={`${this.getApiPoint()}/info-articles/${section.image.path}`}
                    alt=""
                    className="contentInfoPublic__cardImage"
                />
            </div>
        );
    }

    renderListItem({ item }) {
        const resultContent = searchInText({ search: this.props.search, text: item.title }).content;

        return (
            <div className="contentInfoPublic__cardListItem" key={item._id}>
                <img
                    src={`${this.getApiPoint()}/info-articles/${item.preview.path}`}
                    alt=""
                    className="contentInfoPublic__cardListItemIcon"
                />
                <Link
                    pageName="content-infoPublic-inner"
                    ids={{ 1: item.url }}
                    className="contentInfoPublic__cardListItemLink"
                >
                    {resultContent}
                </Link>
                <i className="contentInfoPublic__cardListItemArrow">
                    <Icon name="arrow-next" />
                </i>
            </div>
        );
    }

    filterSection({ name, items }) {
        return (
            !searchInText({ search: this.props.search, text: name }).isNotSearch ||
            items.find(
                (item) =>
                    !searchInText({ search: this.props.search, text: item.title }).isNotSearch,
            )
        );
    }

    filterListItem({ title, sectionName }) {
        return (
            !searchInText({ search: this.props.search, text: title }).isNotSearch ||
            !searchInText({ search: this.props.search, text: sectionName }).isNotSearch
        );
    }

    getSectionsOrder() {
        const sections = this.getSections().filter(this.filterSection);

        if (sections.length === 0 && this.checkSearch()) {
            return [{ _id: 'info' }];
        }

        return sections;
    }

    getSections() {
        const { articles = [] } = this.state;
        const sections = [];

        articles.forEach((article) => {
            const { section: sectionId } = article;
            let sectionIndex = sections.findIndex((section) => section._id === sectionId);

            if (sectionIndex === -1) {
                sections.push({
                    _id: sectionId,
                    name: article.sectionText,
                    image: article.sectionImage,
                    items: [],
                });

                sectionIndex = sections.length - 1;
            }

            sections[sectionIndex].items.push({
                _id: article._id,
                title: article.title,
                url: article.url,
                preview: article.preview,
            });
        });

        return sections;
    }

    getArticles() {
        getArticles('info', {}).then(
            ({ articles }) => {
                this.setState({ articles: articles.reverse() }, () => {
                    setTimeout(() => {
                        this.setState({ isReady: true });
                    }, 100);
                });
            },
            () => null,
        );
    }

    componentDidMount() {
        this.getArticles();
    }

    render() {
        const { isReady, isLoadingFilter, updatedKey } = this.state;

        return (
            <div
                ref={this.parent}
                className={`widget ${!isLoadingFilter && isReady ? '_ready' : ''}`}
            >
                <Animate className="widget__loader _loader" isShow={!isReady || isLoadingFilter}>
                    <i className="widget__loaderItem _loaderItem">
                        <Loader className="_main" />
                    </i>
                </Animate>
                <div className="widget__content _full _inner _scroll">
                    <div className="widget__contentInner">
                        <div className="widget__contentBox _padding" style={{ height: 'auto' }}>
                            <div className="contentInfoPublic">
                                <div className="contentInfoPublic__inner _col">
                                    <div className="contentInfoPublic__head _col">
                                        <img
                                            src={
                                                require('../../../../img/crm/content/logo.svg')
                                                    .default
                                            }
                                            alt=""
                                            className="contentInfoPublic__logo"
                                        />
                                        <div className="contentInfoPublic__title">
                                            Инструкция по работе с LIVECARGO CRM
                                        </div>
                                        <p className="contentInfoPublic__description">
                                            Наша база знаний расскажет вам как пользоваться
                                            функционалом системы
                                        </p>
                                    </div>
                                    <div className="contentInfoPublic__content">
                                        <ListAbsoluteMain
                                            className="contentInfoPublic__cards _col"
                                            items={this.getSectionsOrder()}
                                            renderItem={this.renderCard}
                                            classNameItem="contentInfoPublic__card"
                                            prop="_id"
                                            paramsParent={{ width: true }}
                                            styles={['height']}
                                            itemParams={['offsetTop', 'height']}
                                            callback={({ isInit }) => {
                                                if (isInit) {
                                                    removeTransition({
                                                        item: '.contentInfoPublic__card',
                                                        isCurrent: true,
                                                    });
                                                }
                                            }}
                                            keyRender={updatedKey}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ContentInfoMain);

ContentInfoMain.propTypes = {
    setHeightPage: PropTypes.func,
    setInfoHead: PropTypes.func,
    filter: PropTypes.array,
    initCallbackFilter: PropTypes.func,
    handlerFilter: PropTypes.func,
    handlerLoaderList: PropTypes.func,
    parentScroll: PropTypes.object,
    search: PropTypes.string,
};
