import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../Button.jsx';
import { dispatcher } from '../../../redux/redux';

class NewVersionPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { user } = this.props;
        const { systemVersion } = user;
        const textVersion = systemVersion.toString().split('').join('.');

        return (
            <div className="bonusPopup _col _version">
                <div className="bonusPopup__inner">
                    <div className="bonusPopup__innerBox _col">
                        <img
                            src={require('../../../img/crm/new-version-icon.svg').default}
                            alt=""
                            className="bonusPopup__icon"
                        />
                        <div className="bonusPopup__title">Вышла новая версия {textVersion}</div>
                        <p className="bonusPopup__description">
                            В CRM были внесены изменения, влияющие на работу всей системы. <br />
                            Во избежание сбоев рекомендуется обновить страницу.
                        </p>
                        <div className="bonusPopup__button">
                            <Button
                                onClick={() => {
                                    window.location.reload(true);
                                }}
                                className="_mainNotBorder _medium2Size"
                            >
                                Обновить страницу
                            </Button>
                        </div>
                        <div
                            className="bonusPopup__button _empty _click"
                            onClick={() => {
                                dispatcher({ type: 'newVersionShow', data: false });

                                setTimeout(() => {
                                    dispatcher({ type: 'newVersionShow', data: true });
                                }, 60_000 * 10);
                            }}
                        >
                            Напомнить позже
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(NewVersionPopup);

NewVersionPopup.propTypes = {
    user: PropTypes.object,
};
