import ListAbsoluteI from '../types.ts';

const getClass: ListAbsoluteI['getClass'] = function (className) {
    return className
        .split(' ')
        .filter((item) => item)
        .join('.');
};

export default getClass;
