import React from 'react';

import WidgetDoc from '@components/crm/widgetDoc/WidgetDoc.tsx';
import InfoBlock from '@components/infoBlock/InfoBlock.tsx';

import I from '../types.ts';

const renderDoc: I['renderDoc'] = function ({ item }) {
    if (item._id === 'add') {
        return (
            <div className="v2widgetDocs__item _ITEM _add">
                <InfoBlock
                    title="Больше документов нет"
                    description="Но вы всегда можете добавить ещё:"
                    button={{
                        text: 'Добавить документ',
                        onClick: this.addHandler.bind(this),
                    }}
                />
            </div>
        );
    }

    const { error, checkChangeName, checkChange, checkDeleteDoc, editFiles, setDoc } = this.props;

    return (
        <div className={`v2widgetDocs__item ${editFiles ? '' : '_ITEM'}`}>
            <WidgetDoc
                doc={item}
                change={this.change.bind(this, item._id)}
                addArrayItem={this.addArrayItem.bind(this, item._id)}
                deleteHandler={this.deleteHandler.bind(this, item._id)}
                deleteFilesHandler={this.deleteFilesHandler.bind(this, item._id)}
                error={error}
                checkChangeName={checkChangeName}
                checkChange={checkChange}
                checkDeleteDoc={checkDeleteDoc}
                editFiles={editFiles}
                setDoc={setDoc}
            />
        </div>
    );
};

export default renderDoc;
