import getUserName from '@functions/getUserName';
import UserT from '@global/models/User';

const userMain = {
    title: 'Личные данные',
    description: (model: UserT) => getUserName({ user: model }),
    fieldsOrder: ['secondName', 'firstName', 'thirdName', 'email', 'phone', 'empty'],
    fields: {
        secondName: {
            support: 'Фамилия',
            prop: 'secondName',
            type: 'input',
            group: 1,
            width: 1,
        },
        firstName: {
            support: 'Имя',
            prop: 'firstName',
            type: 'input',
            group: 1,
            width: 1,
        },
        thirdName: {
            support: 'Отчество',
            prop: 'thirdName',
            type: 'input',
            group: 1,
            width: 1,
        },
        email: {
            support: 'Email',
            prop: 'email',
            type: 'input',
            group: 2,
            width: 1,
            disabled: true,
        },
        phone: {
            support: 'Телефон',
            prop: 'phone',
            type: 'input',
            reg: 'phone',
            group: 2,
            width: 1,
        },
        empty: {
            empty: true,
            group: 2,
            width: 1,
        },
    },
} as const;

export default userMain;
