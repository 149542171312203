import I from '../types.ts';

const searchHandler: I['searchHandler'] = function ({ name, value }) {
    const { updateFilter, searchItems } = this.props;

    return new Promise((resolve) => {
        this.setState(
            (state) => {
                const newState = { ...state };
                const searchs = { ...newState.searchs };

                searchs[name] = value as string;

                newState.searchs = searchs;

                return newState;
            },
            async () => {
                if (updateFilter && searchItems) {
                    await updateFilter({ key: name, value: value as string });

                    searchItems(name);
                }

                resolve();
            },
        );
    });
};

export default searchHandler;
