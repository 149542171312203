import UserT from '@global/models/User';

type ParamsT = {
    user?: Pick<UserT, 'corporations' | 'idOfCurrentCorporation'>;
    isInfo?: boolean;
};

type CurrentCorporationInfoT = UserT['corporationsInfo'][number];
type CurrentCorporationT = UserT['corporations'][number];

export default function getCurrentCorporation({
    user,
    isInfo = true,
}: ParamsT): UserT['corporationsInfo'][number] | UserT['corporations'][number] | null | undefined {
    if (user) {
        const { idOfCurrentCorporation } = user;

        if (!isInfo || process.env.REACT_APP_SYSTEM === 'app') {
            return user.corporations.find((corp) => corp.id === idOfCurrentCorporation);
        }

        return (user as UserT).corporationsInfo.find((corp) =>
            idOfCurrentCorporation === 'admin' ? corp.isAdmin : corp._id === idOfCurrentCorporation,
        );
    }

    return null;
}

export type { CurrentCorporationInfoT, CurrentCorporationT };
