import React from 'react';

import Button from '@components/button/Button.tsx';
import File from '@components/crm/file/File.tsx';
import ListAbsoluteMain from '@components/listAbsoluteMain/ListAbsoluteMain.tsx';
import { FileT } from '@global/types.ts';

import I from '../types.ts';

const renderItem = function (this: I, { item }: { item: FileT }) {
    if (item._id === 'empty') {
        return <div className="v2widgetField__listItem _ITEM _empty">Не выбраны</div>;
    }

    const { model, deleteArrayItem, prop, galeryTitle } = this.props;
    const title = typeof galeryTitle === 'function' ? galeryTitle(model) : galeryTitle;

    return (
        <div className="v2widgetField__listItem _ITEM">
            <File
                galeryTitle={title}
                files={model[prop]}
                file={item}
                deleteHandler={async () => {
                    await deleteArrayItem!([`${prop}.${item._id}`]);
                }}
            />
        </div>
    );
};

const renderFiles: I['renderFiles'] = function () {
    const { model, prop, accepts, maxFiles } = this.props;
    const items = model[prop] as { _id: string }[];

    let resultItems = [...items];

    if (items.length === 0) {
        resultItems = [{ _id: 'empty' }];
    }

    return (
        <div className="v2widgetField__field _list">
            <div className="v2widgetField__list _COL">
                <div className="v2widgetField__listCurrent">
                    <ListAbsoluteMain
                        className="v2widgetField__listItems _ITEMS"
                        items={resultItems}
                        renderItem={renderItem.bind(this)}
                        classNameItem="v2widgetField__listItem"
                        prop="_id"
                        paramsParent={{ width: true }}
                        propsForUpdate={['fullSrc', 'src', 'name', 'size']}
                    />
                </div>
                <label className="v2widgetField__listButton">
                    <Button className="_blue _minSize" label={true}>
                        Добавить файл
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={this.filesHandler.bind(this)}
                            multiple
                            accept={accepts}
                            disabled={maxFiles ? model[prop].length >= maxFiles : false}
                        />
                    </Button>
                </label>
            </div>
        </div>
    );
};

export default renderFiles;
