import lists from '../lists';

const listRoles = {
    ...lists,
    query: [{ key: 'type', value: 'roles' }],
    emptyInfo: {
        title: 'Ролей пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listRoles;
