import setAsyncTimer from '@functions/setAsyncTimer.ts';
import getListItems from '@requests/getListItems.ts';

import TableI, { TableItemT } from '../types.ts';

import { TableT } from '../static/tables.ts';

const getItems: TableI['getItems'] = async function (data) {
    const { name, callback } = this.props;
    const table = this.tables[name] as TableT;

    if (!table.url) {
        return Promise.reject();
    }

    const query = [...this.getQueryForRequest().params, ...this.getOtherQuery(data)];

    try {
        if (0) {
            await setAsyncTimer(1_000);
        }

        const { items, filter, isLimit, counter, ...props } = await getListItems<TableItemT, {}>({
            url: table.url,
            query,
        });

        await this.setItems({ items, filter, counter, isLimit });

        this.rowBlockSizeHandler();

        if (callback) {
            callback({ counter, items: this.state.items, ...props });
        }

        this.checkScroll();

        return { items };
    } catch (error) {
        return Promise.reject();
    }
};

export default getItems;
