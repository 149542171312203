import axios from 'axios';
import getHeaders from '../getHeaders';

export default function readNotificationsNews(id) {
    return new Promise((resolve) => {
        axios
            .patch(
                `${process.env.REACT_APP_API}/user`, { action: 'readNews', articleId: id }, { headers: getHeaders() },
            )
            .then(
                (res) => {
                    const { success } = res.data;

                    if (success) {
                        resolve();
                    }
                },
                () => null,
            );
    });
}