import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import removeTransition from '../functions/removeTransition.ts';

class BorderDashed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    getSize() {
        const box = this.parent.current;

        this.setState(
            {
                width: box.offsetWidth,
                height: box.offsetHeight,
            },
            () => {
                removeTransition({ item: '.borderDashed' });
            },
        );
    }

    componentDidMount() {
        this.getSize();

        setTimeout(() => {
            this.getSize();
        }, 10);
    }

    render() {
        const { width, height } = this.state;
        const { className, rx, ry, isFull } = this.props;
        const widthResult = isFull ? '99%' : width - 2 || 0;
        const heightResult = isFull ? '99%' : height - 2 || 0;

        return (
            <div ref={this.parent} className={`borderDashed ${className || ''}`}>
                <svg width="100%" height="100%" className="borderDashed__svg">
                    <rect
                        className="borderDashed__rect"
                        x="1"
                        y="1"
                        fill="none"
                        rx={rx || 14}
                        ry={ry || 14}
                        width={widthResult}
                        height={heightResult}
                    />
                </svg>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(BorderDashed);

BorderDashed.propTypes = {
    className: PropTypes.string,
    rx: PropTypes.number,
    ry: PropTypes.number,
    isFull: PropTypes.bool,
};
