import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import { ReqResponseT } from '@global/types.ts';

import ReportsI from '../types.ts';

const signReport: ReportsI['signReport'] = async function ({ report }) {
    const response = await axios.patch<{}, { data: ReqResponseT<{}> }>(
        `${process.env.REACT_APP_API}/corporation-report`,
        { id: report._id },
        { headers: getHeaders() },
    );

    const { success } = response.data;

    if (success) {
        return;
    }

    return Promise.reject();
};

export default signReport;
