import { store } from '@redux/redux.ts';

import { PagesT, StorePagesT } from '../global/types.ts';

import getPage from './getPage.ts';
import getPageInfo from './getPageInfo.ts';
import getPageLevel from './getPageLevel.ts';

type PropsT = {
    name: string;
    storePages?: Record<string, StorePagesT>;
    ids?: Record<number, string>;
};

export default function getPageLink({ name, storePages, ids = {} }: PropsT): string {
    const page: PagesT = getPage({ name });

    if (!storePages) {
        storePages = store.getState().pages;
    }

    if (page) {
        const user = store.getState().user;
        const pageInfo = getPageInfo({ name });
        const link =
            page.links.find((loopLink) => !['', undefined].includes(loopLink)) ||
            page.links.find((loopLink) => loopLink !== undefined);
        let resultLink = link;

        if (resultLink === undefined) {
            resultLink = ids[getPageLevel(page)] ?? storePages?.[name]?.id;

            if (page.name === 'corporation') {
                const corporationId: string =
                    user?.idOfCurrentCorporation ||
                    user?.corporations?.[0]?.id ||
                    storePages?.[name]?.id ||
                    'wait';

                if (corporationId) {
                    resultLink = corporationId;
                }
            }
        }

        let nearParent = pageInfo.nearParent;

        while (nearParent) {
            let parentLink =
                nearParent.links.find((loopLink) => !['', undefined].includes(loopLink)) ||
                nearParent.links.find((loopLink) => loopLink !== undefined);

            if (parentLink === undefined) {
                parentLink = ids[getPageLevel(nearParent)] ?? storePages?.[nearParent.name]?.id;

                if (nearParent.name === 'corporation') {
                    const corporationId: string =
                        user?.idOfCurrentCorporation ||
                        user?.corporations?.[0]?.id ||
                        storePages?.[nearParent.name]?.id ||
                        'wait';

                    if (corporationId) {
                        parentLink = corporationId;
                    }
                }
            }

            resultLink = `${parentLink}/${resultLink}`;

            nearParent = getPageInfo({ name: nearParent.name }).nearParent;
        }

        return resultLink;
    }

    return '';
}
