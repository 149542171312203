import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import handlerPopup from '../../../functions/app/handlerPopup';
import handlerLoading from '../../../functions/handlerLoading';
import setAnimate from '../../../functions/setAnimate';
import sortForAlphabet from '../../../functions/sortForAlphabet';

import getTags from '../../../requests/getTags';
import ActionChange from '../../ActionChange.jsx';
import Animate from '../../Animate.jsx';
import AnimateChangeUp from '../../AnimateChangeUp.jsx';
import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import ListScroll from '../../ListScroll.jsx';
import Loader from '../../Loader.jsx';
import Search from '../../Search.jsx';
import Table from '../manual/Table.jsx';

class TagsPopup extends Table {
    constructor(props) {
        super(props);
        this.state = {
            activeItems: [],
        };

        this.renderItem = this.renderItem.bind(this);
        this.handlerSearch = this.handlerSearch.bind(this);
        this.save = this.save.bind(this);
    }

    stepCounter = 25;

    classNameItem = '.tagsList__item';

    handlerSearch({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ search: value }, () => {
                    clearTimeout(this.searchTimer);

                    if (!value || value.length > 2) {
                        this.setState({ isLoadingFilter: true });
                        this.searchTimer = setTimeout(() => {
                            this.updateItems();
                        }, 300);
                    }

                    resolve();
                });
            }
        });
    }

    getQueryForRequest() {
        const { search } = this.state;
        const query = super.getQueryForRequest();

        query.params.push({ key: 'inList', value: true });
        query.params.push({ key: 'sortByGroup', value: true });

        if (search?.length > 2) {
            query.params.push({ key: 'searchForNaming', value: search });
        }

        return query;
    }

    scrollToTop(isActive) {
        const content = this.parent.current
            .querySelector(isActive ? '.tagsList__block._current' : '.tagsList__block._list')
            ?.querySelector('.tagsList__blockContentScroll');
        const { scrollTop } = content;

        if (content) {
            setAnimate({
                draw: (progress) => {
                    content.scrollTop = scrollTop - progress * scrollTop;
                },
                duration: 300,
                callback: () => null,
            });
        }
    }

    getItems() {
        const query = this.getQueryForRequest();

        if (this.controller) {
            this.controller.abort();
        }

        this.controller = new AbortController();

        return new Promise((resolve) => {
            getTags({ ...query, signal: this.controller.signal }).then(
                ({ tags, isLimit, counter }) => {
                    this.setItems(tags, false, isLimit, counter).then(() => {
                        resolve();
                    });
                },
                () => null,
            );
        });
    }

    getTags() {
        const { items = [], activeItems } = this.state;
        const itemsOrder = [];
        const notActiveItems = items.filter(
            (item) => !activeItems.find((activeItem) => activeItem._id === item._id),
        );

        notActiveItems.forEach((item, key) => {
            if (key === 0 || notActiveItems[key - 1].idOfGroup !== item.idOfGroup) {
                itemsOrder.push({
                    _id: `${item.idOfGroup}-group`,
                    color: item.color,
                    name: item.groupName,
                    type: 'group',
                });
            }

            itemsOrder.push({ ...item, type: 'tag' });
        });

        return itemsOrder;
    }

    setActive(id, isActive) {
        this.setState(
            (state) => {
                const newState = { ...state };
                const activeItems = JSON.parse(JSON.stringify(newState.activeItems));

                const item = newState.items.find((innerItem) => innerItem._id === id);
                const activeItemIndex = activeItems.findIndex((innerItem) => innerItem._id === id);

                if (activeItemIndex === -1) {
                    activeItems.push({ ...item });
                } else {
                    activeItems.splice(activeItemIndex, 1);
                }

                newState.activeItems = sortForAlphabet({ arr: activeItems, prop: 'name' });
                newState.updatedKeyScroll = new Date().getTime();

                return newState;
            },
            () => {
                this.scrollToTop(!isActive);
            },
        );
    }

    renderItem(isActive, { item }) {
        return (
            <div className={`tagsList__item _${item.type} ${isActive ? '_active' : ''}`}>
                <div className="tagsList__itemPoint" style={{ background: item.color }} />
                {item.name}
                {item.type === 'group' ? ':' : ''}
                {item.type !== 'group' && (
                    <ActionChange
                        className="tagsList__itemAction _click"
                        isShow={true}
                        isSmoothShow={true}
                        isHas={isActive}
                        onClick={this.setActive.bind(this, item._id, !isActive)}
                    />
                )}
            </div>
        );
    }

    checkChange() {
        const { activeItems = [], savedActiveItems = [] } = this.state;

        let isChange = false;

        if (activeItems.length !== savedActiveItems.length) {
            isChange = true;
        } else {
            savedActiveItems.forEach((savedActiveItem) => {
                if (!activeItems.find((activeItem) => activeItem._id === savedActiveItem._id)) {
                    isChange = true;
                }
            });
        }

        return isChange;
    }

    save() {
        const { activeItems = [] } = this.state;
        const { tagsPopup } = this.props;
        const { callback } = tagsPopup;
        const isChange = this.checkChange();

        if (isChange) {
            handlerLoading.call(this, 'save').then(() => {
                try {
                    callback(
                        activeItems.map((item) => ({
                            _id: item._id,
                            id: item._id,
                            name: item.name,
                            color: item.color,
                            colorOfGroup: item.color,
                        })),
                    ).then(() => {
                        this.hide();

                        handlerLoading.call(this, null);
                    });
                } catch (error) {
                    this.hide();

                    handlerLoading.call(this, null);
                }
            });
        }
    }

    hide() {
        handlerPopup({ name: 'tagsPopup', isShow: false });
    }

    getActiveItems() {
        const { tagsPopup } = this.props;
        const { ids = [] } = tagsPopup;

        if (ids.length) {
            const params = ids.map((id) => ({ key: 'ids', value: id }));

            getTags({ params: [...params, { key: 'inList', value: true }] }).then(
                ({ tags }) => {
                    this.setState({
                        activeItems: sortForAlphabet({ arr: tags, prop: 'name' }),
                        savedActiveItems: JSON.parse(JSON.stringify(tags)),
                        isActiveReady: true,
                    });
                },
                () => null,
            );
        } else {
            this.setState({ isActiveReady: true, savedActiveItems: [] });
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.getActiveItems();
    }

    render() {
        const {
            search,
            isReady,
            isLimit,
            isDisabledScroll,
            isLoadingFilter,
            isShowLoaderList = false,
            counter = 0,
            updatedKeyScroll,
            activeListHeight = 0,
            activeItems,
            isActiveReady,
            loadingKey,
        } = this.state;
        const items = this.getTags();
        const condForSave = this.checkChange();

        return (
            <div ref={this.parent} className="crmPopup _col _tags">
                <div className="crmPopup__inner">
                    <div className="crmPopup__head">
                        <div className="crmPopup__title">Изменить теги:</div>
                        <i className="crmPopup__close _click" onClick={this.hide}>
                            <Icon name="close-circle" />
                        </i>
                    </div>
                    <div className="crmPopup__content">
                        <div className={`tagsList _row ${isReady ? '_ready' : ''}`}>
                            <div
                                className={`tagsList__block _list ${
                                    isLoadingFilter ? '_loading' : ''
                                }`}
                            >
                                <div className="tagsList__title">
                                    Добавить теги:
                                    <AnimateChangeUp
                                        className="tagsList__titleItem"
                                        renderKey={counter}
                                    >
                                        ({counter})
                                    </AnimateChangeUp>
                                </div>
                                <div className="tagsList__search">
                                    <Search
                                        support="Введите название"
                                        className="_grey _filter"
                                        value={search}
                                        callback={this.handlerSearch}
                                    />
                                </div>
                                <div className="tagsList__blockContent">
                                    <div className="tagsList__blockContentScroll">
                                        <ListScroll
                                            isInit={isReady}
                                            getParent={() =>
                                                this.parent.current
                                                    ?.querySelector('.tagsList__block._list')
                                                    ?.querySelector('.tagsList__blockContentScroll')
                                            }
                                            callback={this.getMoreItems}
                                            startCounter={this.stepCounter}
                                            stepCounter={this.stepCounter}
                                            maxCounter={Infinity}
                                            lengthCurrent={this.state.items?.length}
                                            handlerLoaderList={this.handlerLoaderList}
                                            isLimit={isLimit}
                                            isDisabledScroll={
                                                isDisabledScroll || !isReady || isLoadingFilter
                                            }
                                            keyUpdate={updatedKeyScroll}
                                        >
                                            <ListAbsoluteMain
                                                className="tagsList__items _col"
                                                items={items}
                                                renderItem={this.renderItem.bind(this, false)}
                                                classNameItem="tagsList__item"
                                                prop="_id"
                                                paramsParent={{ width: true }}
                                                styles={['height']}
                                                // callback={({ type: cbType, params }) => {
                                                //     if (cbType === 'parent') {
                                                //         this.setState({ listHeight: params.height });
                                                //     }
                                                // }}
                                            />
                                        </ListScroll>
                                    </div>
                                    <Animate
                                        className="tagsList__blockLoader _loader"
                                        isShow={isLoadingFilter || !isReady}
                                    >
                                        <div className="tagsList__blockScrollLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <Animate
                                        className="tagsList__blockScrollLoader _white _loaderScroll"
                                        isShow={isShowLoaderList}
                                    >
                                        <div className="tagsList__blockScrollLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <Animate
                                        className="tagsList__blockEmpty _loader"
                                        isShow={isReady && items.length === 0 && !isLoadingFilter}
                                    >
                                        <div className="empty _col _block _notBack _notPad">
                                            <div className="empty__inner">
                                                <div className="empty__title">Это все теги</div>
                                                <p className="empty__content">
                                                    Вы можете создать новые теги в справочнике
                                                </p>
                                            </div>
                                        </div>
                                    </Animate>
                                </div>
                            </div>
                            <div
                                className={`tagsList__block _current ${
                                    !isActiveReady ? '_loading' : ''
                                }`}
                            >
                                <div className="tagsList__title">
                                    Выбранные теги:{' '}
                                    <AnimateChangeUp
                                        className="tagsList__titleItem"
                                        renderKey={activeItems.length}
                                    >
                                        ({activeItems.length})
                                    </AnimateChangeUp>
                                </div>
                                <div className="tagsList__blockContent">
                                    <div className="tagsList__blockContentScroll">
                                        <ListAbsoluteMain
                                            className="tagsList__items _col"
                                            items={activeItems}
                                            renderItem={this.renderItem.bind(this, true)}
                                            classNameItem="tagsList__item"
                                            prop="_id"
                                            paramsParent={{ width: true }}
                                            styles={['height']}
                                            classNames={['_current']}
                                            callback={({ type: cbType, params }) => {
                                                if (cbType === 'parent') {
                                                    this.setState({
                                                        activeListHeight: params.height,
                                                    });
                                                }
                                            }}
                                            // name="test"
                                        />
                                    </div>
                                    <Animate
                                        className="tagsList__blockLoader _loader"
                                        isShow={!isActiveReady}
                                    >
                                        <div className="tagsList__blockScrollLoaderItem _loaderItem">
                                            <Loader className="_main" />
                                        </div>
                                    </Animate>
                                    <Animate
                                        className={`tagsList__blockEmpty _loader _active ${
                                            condForSave ? '_withButton' : ''
                                        }`}
                                        isShow={
                                            activeItems.length > 0 &&
                                            activeListHeight < 350 - (condForSave ? 54 : 0)
                                        }
                                        style={{
                                            height: `${
                                                480 - activeListHeight - 8 - (condForSave ? 54 : 0)
                                            }px`,
                                        }}
                                    >
                                        <div className="empty _col _block _notPad">
                                            <div className="empty__inner">
                                                <div className="empty__title">Это все теги</div>
                                                <p className="empty__content">
                                                    Пока это все выбранные теги — вы можете добавить
                                                    их из списка слева
                                                </p>
                                            </div>
                                        </div>
                                    </Animate>
                                    <Animate
                                        className={`tagsList__blockEmpty _loader ${
                                            condForSave ? '_withButton' : ''
                                        }`}
                                        isShow={isReady && activeItems.length === 0}
                                    >
                                        <div className="empty _col _block _notBack _notPad">
                                            <div className="empty__inner">
                                                <div className="empty__title">Теги не выбраны</div>
                                                <p className="empty__content">
                                                    Вы можете добавить их из&nbsp;списка слева
                                                </p>
                                            </div>
                                        </div>
                                    </Animate>
                                    <Animate className="tagsList__blockButton" isShow={condForSave}>
                                        <Button
                                            className="_main _medium2Size"
                                            onClick={this.save}
                                            showLoader={loadingKey === 'save'}
                                        >
                                            Сохранить
                                        </Button>
                                    </Animate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        tagsPopup: state.tagsPopup,
    };
}

export default connect(mapStateToProps)(TagsPopup);

TagsPopup.propTypes = {
    tagsPopup: PropTypes.object,
};
