import { CorporationT } from '@global/models/Corporation';
import getDaData from '@requests/getDaData';

const corporationMain = {
    title: 'Юр. данные',
    description: (model: CorporationT) =>
        [
            ...(model.inn ? [`ИНН ${model.inn}`] : []),
            ...(model.bic ? [`БИК ${model.bic}`] : []),
        ].join(', '),
    fieldsOrder: [
        'inn',
        'bic',
        'empty',
        'kpp',
        'fullName',
        'bankName',
        'corrAccount',
        'bankAccount',
    ],
    fields: {
        inn: {
            support: 'ИНН',
            prop: 'inn',
            type: 'input',
            group: 1,
            width: (model: any, data: Record<any, unknown>) => (data?.create ? 4 : 1),
            disabled: (model: any, props: { data?: any }) => !props.data?.create,
            asyncHandler: {
                cond(value: string) {
                    return value.length === 10 || value.length === 12;
                },
                async handler(value: string) {
                    try {
                        const response = await getDaData({ inn: value });

                        return {
                            fullName: response.fullName,
                            shortName: response.shortName,
                            address: response.address,
                            kpp: response.kpp,
                        };
                    } catch (error) {
                        return {};
                    }
                },
            },
        },
        bic: {
            support: 'БИК',
            prop: 'bic',
            type: 'input',
            group: 1,
            width: (model: any, data: Record<any, unknown>) => (data?.create ? 4 : 1),
            asyncHandler: {
                cond(value: string) {
                    return value.length === 9;
                },
                async handler(value: string) {
                    try {
                        const response = await getDaData({ bic: value });

                        return {
                            corrAccount: response.corrAccount,
                            bankName: response.bankName,
                        };
                    } catch (error) {
                        return {};
                    }
                },
            },
        },
        empty: { empty: true, group: 1, width: 1 },
        info: {
            type: 'text',
            text: 'Введите ИНН и БИК — другие поля <b>заполнятся автоматически</b>',
            group: 1,
            width: 5,
        },
        line: {
            type: 'line',
        },
        kpp: {
            support: 'КПП',
            prop: 'kpp',
            type: 'input',
            group: 2,
            width: 3,
            disabled: true,
        },
        fullName: {
            support: 'Название компании',
            prop: 'fullName',
            type: 'input',
            group: 2,
            width: 5,
            disabled: true,
        },
        bankName: {
            support: 'Наименование банка',
            prop: 'bankName',
            type: 'input',
            group: 3,
            width: 1,
            disabled: true,
        },
        corrAccount: {
            support: 'Корр. счёт',
            prop: 'corrAccount',
            type: 'input',
            group: 4,
            width: 1,
            disabled: true,
        },
        bankAccount: {
            support: 'Банк. счёт',
            prop: 'bankAccount',
            type: 'input',
            group: 4,
            width: 1,
        },
    },
} as const;

export default corporationMain;
