import React from 'react';

import Table from '@components/crm/table/Table.tsx';

import LogsI from './types.ts';

class Logs extends React.Component<LogsI['props'], LogsI['state']> implements LogsI {
    constructor(props: LogsI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { id } = this.props;

        return (
            <>
                <Table
                    name="modelLogs"
                    isNotLink={true}
                    isInit={!!id}
                    getQuery={() => [
                        { key: 'modelId', value: id! },
                        { key: 'modelName', value: 'executor' },
                    ]}
                    isWrap={true}
                    getRowClassName={() => '_colCenter'}
                />
            </>
        );
    }
}

export default Logs;
