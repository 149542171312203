import { dispatcher } from '@redux/redux.ts';

import I from '../types.ts';

const savePin: I['savePin'] = async function () {
    const { pin } = this.props;

    if (pin) {
        await dispatcher<'crm'>({ type: 'galeryPinX', data: this.moveX });
        await dispatcher<'crm'>({ type: 'galeryPinY', data: this.moveY });

        localStorage.setItem('galeryPinX', this.moveX.toString());
        localStorage.setItem('galeryPinY', this.moveY.toString());
    }
};

export default savePin;
