import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from '../../Icon.jsx';
import AnimateChange from '../../AnimateChange.jsx';

import handlerWindow from '../../../functions/handlerWindow';
// import removeTransition from '../../../functions/removeTransition.ts';
// import handlerBlogEditPanel from '../functions/handlerBlogEditPanel';

class EditPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLinkPanel: false,
            link: '',
        };

        this.parent = React.createRef();
    }

    buttons = {
        bold: {
            icon: 'blog-text-edit-bold',
        },
        italic: {
            icon: 'blog-text-edit-italic',
        },
        link: {
            icon: 'blog-text-edit-link',
        },
        color: {
            icon: 'blog-text-edit-color',
        },
    };

    orderButtons = ['bold', 'italic', 'color', 'link'];

    linkTabs = ['under', 'usual'];

    colors = ['#e74f2f', '#f9bf00', '#66ca49', '#308bf2', '#6330f2', '#d230f2', '#c4c4c4'];

    handlerButton(name) {
        const { callback } = this.props;

        // document.execCommand('bold', false);

        this.setState((state) => {
            const newState = { ...state };
            const { buttons } = newState;
            const keyOfCurButton = buttons.findIndex((button) => button.name === name);

            buttons[keyOfCurButton].state = document.queryCommandState(name);

            newState.buttons = buttons;

            return newState;
        });

        switch (name) {
            case 'link':
                this.setState(
                    (state) => {
                        const newState = { ...state };

                        newState.showLinkPanel = true;

                        return newState;
                    },
                    () => {
                        this.parent.current
                            .querySelector('.contentEditPanel__settingsInput')
                            .focus();
                    },
                );
                break;
            case 'color':
                this.setState(
                    (state) => {
                        const newState = { ...state };

                        newState.showColorPanel = true;

                        return newState;
                    },
                    () => null,
                );
                break;
            default:
                callback({ name });
                break;
        }
    }

    saveLink() {
        const { link, currentLinkTab } = this.state;
        const { selection, callback } = this.props;
        const { extentOffset, extentNode, anchorOffset, anchorNode } = selection;

        const range = new Range();

        range.setStart(extentNode, extentOffset);
        range.setEnd(anchorNode, anchorOffset);

        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);

        const textLink = document.getSelection();
        const style = currentLinkTab === 'under' ? 'style="text-decoration:underline;"' : '';
        document.execCommand(
            'insertHTML',
            false,
            `<a href="${link}" target="_blank" ${style}>${textLink}</a>`,
        );
        document.getSelection().removeAllRanges();

        callback({ name: 'link' });
    }

    saveColor() {
        const { color, currentColor } = this.state;
        const { selection, callback } = this.props;
        const { extentOffset, extentNode, anchorOffset, anchorNode } = selection;

        const range = new Range();

        range.setStart(extentNode, extentOffset);
        range.setEnd(anchorNode, anchorOffset);

        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);

        document.execCommand('foreColor', false, color || currentColor);

        document.getSelection().removeAllRanges();

        callback({ name: 'color' });
    }

    renderLink() {
        const { link, currentLinkTab } = this.state;

        return (
            <div className="contentEditPanel__settings _col _link">
                <h5 className="contentEditPanel__settingsTitle">Вставка ссылки:</h5>
                <i
                    className="contentEditPanel__settingsClose _click"
                    onClick={() => {
                        handlerWindow({
                            action: 'hide',
                            name: 'editPanel',
                        });
                        document.getSelection().removeAllRanges();
                    }}
                >
                    <Icon name="close" />
                </i>
                <input
                    type="text"
                    className="contentEditPanel__settingsInput"
                    placeholder="Вставьте ссылку здесь"
                    onChange={(e) => this.setState({ link: e.target.value })}
                    value={link}
                />
                <div className="contentEditPanel__settingsActions _row">
                    <div className="contentEditPanel__settingsTabs _row">
                        {this.linkTabs.map((tab) => (
                            <label className={`contentEditPanel__settingsTab _${tab}`} key={tab}>
                                <input
                                    type="radio"
                                    className="contentEditPanel__settingsTabInput"
                                    name="tabLink"
                                    checked={currentLinkTab === tab}
                                    onChange={() => {
                                        this.setState({ currentLinkTab: tab });
                                    }}
                                />
                                <div className="contentEditPanel__settingsTabView _col _click">Aa</div>
                            </label>
                        ))}
                    </div>
                    <div
                        className="contentEditPanel__settingsButton _row _click"
                        onClick={() => this.saveLink()}
                    >
                        Сохранить
                    </div>
                </div>
            </div>
        );
    }

    renderColors() {
        const { color, currentColor } = this.state;

        return (
            <div className="contentEditPanel__settings _col _colors">
                <h5 className="contentEditPanel__settingsTitle">Выбор цвета текста:</h5>
                <i
                    className="contentEditPanel__settingsClose _click"
                    onClick={() => {
                        handlerWindow({
                            action: 'hide',
                            name: 'editPanel',
                        });
                        document.getSelection().removeAllRanges();
                    }}
                >
                    <Icon name="close" />
                </i>
                <div className="contentEditPanel__settingsColors _row">
                    {this.colors.map((item) => (
                        <label className="contentEditPanel__settingsColor" key={item}>
                            <input
                                type="radio"
                                className="contentEditPanel__settingsColorInput"
                                name="color"
                                checked={currentColor === item}
                                onChange={() => {
                                    this.setState({ currentColor: item });
                                }}
                            />
                            <div
                                className="contentEditPanel__settingsColorView _col _click"
                                style={{ background: item }}
                            >
                                <i className="contentEditPanel__settingsColorIcon">
                                    <Icon name="done" />
                                </i>
                            </div>
                        </label>
                    ))}
                </div>
                <input
                    type="text"
                    className="contentEditPanel__settingsInput"
                    placeholder="Или вставьте код цвета"
                    onChange={(e) => this.setState({ color: e.target.value })}
                    value={color}
                />
                <div
                    className="contentEditPanel__settingsButton _row _click"
                    onClick={() => this.saveColor()}
                >
                    Сохранить
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.setState((state) => {
            const newState = { ...state };
            const [currentLinkTab] = this.linkTabs;
            const [currentColor] = this.colors;

            newState.buttons = this.orderButtons.map((name) => ({
                name,
                state: document.queryCommandState(name),
            }));

            newState.currentLinkTab = currentLinkTab;
            newState.currentColor = currentColor;

            return newState;
        });
    }

    renderMain() {
        const { buttons } = this.state;

        return (
            <div className="contentEditPanel__buttons _row">
                {this.orderButtons.map((name) => {
                    const buttonState = buttons && buttons.find((button) => button.name === name);

                    return (
                        <div
                            className={`contentEditPanel__button _col _click ${
                                buttonState?.state === true ? '_active' : ''
                            }`}
                            key={name}
                            onMouseDown={() => this.handlerButton(name)}
                        >
                            <i className="contentEditPanel__buttonIcon">
                                <Icon name={this.buttons[name].icon} />
                            </i>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        const { showLinkPanel, showColorPanel } = this.state;

        return (
            <div
                ref={this.parent}
                className="contentEditPanel"
                onMouseUp={(e) => {
                    e.stopPropagation();
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="contentEditPanel__box">
                    <AnimateChange
                        className="contentEditPanel__inner"
                        prop={`${showLinkPanel}${showColorPanel}`}
                        // isNotParams={true}
                    >
                        {showLinkPanel
                            ? this.renderLink()
                            : showColorPanel
                            ? this.renderColors()
                            : this.renderMain()}
                    </AnimateChange>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        blogEditPanel: state.blogEditPanel,
    };
}

export default connect(mapStateToProps)(EditPanel);

EditPanel.propTypes = {
    selection: PropTypes.object,
    callback: PropTypes.func,
};
