import React from 'react';

import Animate from '@components/animate/Animate.tsx';
import Icon from '@components/icon/Icon.tsx';
import Loader from '@components/loader/Loader.tsx';

import imageLoad from './methods/imageLoad.ts';

import LazyImageI from './types.ts';

class LazyImage
    extends React.Component<LazyImageI['props'], LazyImageI['state']>
    implements LazyImageI
{
    parent: LazyImageI['parent'];

    constructor(props: LazyImageI['props']) {
        super(props);
        this.state = {
            isReady: false,
        };

        this.parent = React.createRef();
    }

    imageLoad = imageLoad;

    render() {
        const { isReady } = this.state;
        const {
            className = '',
            withLoader = true,
            position,
            name,
            checkFit,
            showFrame,
        } = this.props;
        const src = this.props.src.split('?')[0];
        let { cover } = this.props;
        const isImage = ['jpg', 'jpeg', 'svg', 'png'].includes(src.split('.').pop()!.toLowerCase());

        if (checkFit) {
            cover = ['jpg', 'jpeg'].includes(src.split('.').pop()!.toLowerCase());
        }

        return (
            <div
                ref={this.parent}
                className={`v2lazyImage ${isReady ? '_ready' : ''} ${className}`}
            >
                {isImage ? (
                    <>
                        {withLoader && (
                            <Animate className="v2lazyImage__loader" isShow={!isReady}>
                                <Loader />
                            </Animate>
                        )}

                        <img
                            src={this.props.src}
                            alt=""
                            className="v2lazyImage__image"
                            onLoad={this.imageLoad.bind(this)}
                            style={{
                                objectFit: cover ? 'cover' : 'contain',
                                objectPosition: position || 'center',
                            }}
                        />
                    </>
                ) : (
                    <>
                        {showFrame ? (
                            <>
                                <embed
                                    src={`${this.props.src}#toolbar=1&navpanes=0&scrollbar=0`}
                                    width="100%"
                                    height="100%"
                                    type="application/pdf"
                                ></embed>
                            </>
                        ) : (
                            <div className="v2lazyImage__preview _COL">
                                <i className="v2lazyImage__previewIcon">
                                    <Icon name="file-pdf" />
                                </i>
                                {name && <div className="v2lazyImage__previewName">{name}</div>}
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default LazyImage;
