import axios from 'axios';

import getHeaders from '@functions/getHeaders.ts';
import setApiPort from '@functions/setApiPort.ts';
import { CorporationT } from '@global/models/Corporation.ts';
import { ReqResponseT } from '@global/types.ts';

import I from '../types.ts';

const save: I['save'] = async function () {
    const { model } = this.state;
    const { setModel } = this.props;
    const change = this.checkChange(true);
    const integration = change.model!.integration as CorporationT['integration'];

    const fields: Record<any, unknown> = {};

    if (integration) {
        if (integration.tgToken !== undefined) {
            fields.tgToken = integration.tgToken;
        }
    }

    try {
        const response = await axios.patch<
            {},
            {
                data: ReqResponseT<{
                    id?: string;
                    model: CorporationT;
                    error?: { name: string; text: string };
                }>;
            }
        >(
            `${setApiPort('9090')}/corporation`,
            { fields, id: model?._id, action: 'change-integrations' },
            { headers: getHeaders() },
        );
        const { success, data } = response.data;

        if (success) {
            const { model: resultModel } = data;

            await this.init({ fields: resultModel });
            await setModel(resultModel);

            return;
        }

        const { error } = data;

        this.setState({ error });
    } catch (error) {}

    return Promise.reject();
};

export default save;
