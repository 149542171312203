import setAnimate from '@functions/setAnimate.ts';

import MobPopupI from '../types.ts';

const animate: MobPopupI['animate'] = function (dir) {
    let moveDirection = this.state.moveDirection || 'start';
    const downBreak = this.getDownBreak();
    const savedMoveY = this.moveY;
    let resultMove = 0;

    if ((!dir && -this.moveY < downBreak / 3) || dir === 'top') {
        resultMove = 0;

        moveDirection = 'start';
    }

    if ((!dir && -this.moveY >= downBreak / 3) || dir === 'bottom') {
        resultMove = -downBreak;

        moveDirection = 'end';

        this.isHide = true;

        if (this.hide && !dir) {
            this.hide();
        }
    }

    if (dir === 'top') {
        resultMove = 0;
    }

    setAnimate({
        duration: 300,
        draw: (progress) => {
            this.moveY = savedMoveY + (resultMove - savedMoveY) * progress;
            this.lastY = this.moveY;

            this.move();
        },
        getId: (id) => {
            this.animateId = id;
        },
    });

    return moveDirection;
};

export default animate;
