import getPermissions from '@functions/crm/getPermissions.ts';
import { CursorActionsItemT } from '@global/types.ts';
import downloadCorporationReportList from '@requests/downloadCorporationReportList.js';

import ReportsI from '../types.ts';

const getMoreActions: ReportsI['getMoreActions'] = function ({ report }) {
    const { user } = this.state;
    const actions = [
        {
            key: 'download',
            name: 'download',
            text: 'Расшифровка списаний',
            onClick: async () => {
                const filename = `Отчёт_CRM_LIVECARGO_${report.month + 1}-${report.year}.xlsx`;

                await downloadCorporationReportList({ id: report._id, filename });
            },
        },
    ] as CursorActionsItemT[];

    if (
        report.status === 'notSign' &&
        getPermissions(user, {
            notAdminCorporation: true,
            key: 'settings',
            items: [{ key: 'reports', rules: ['sign'] }],
        })
    ) {
        actions.push({
            key: 'edit',
            name: 'sign',
            text: 'Подписать отчёт',
            onClick: async () => {
                await this.signReport({ report });

                this.tableUpdateItems!();
            },
        });
    }

    return actions;
};

export default getMoreActions;
