import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Animate from '../../../../components/Animate.jsx';
import Loader from '../../../../components/Loader.jsx';
import ListAbsoluteMain from '../../../../components/ListAbsoluteMain.jsx';
import setPermissions from '../../../../functions/crm/setPermissions';

class HeadInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.delete = this.delete.bind(this);
        this.createPdf = this.createPdf.bind(this);
        this.filterLink = this.filterLink.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.save = this.save.bind(this);

        setPermissions.call(this);
    }

    orderLinks = [{ key: 'createPdf' }, { key: 'save' }];

    links = {
        createPdf: {
            content: 'Сформировать PDF',
            className: '_mainEmpty',
            handler: this.createPdf,
        },
        save: {
            content: 'Сохранить',
            className: '_main',
            handler: this.save,
        },
    };

    filterLink({ key }) {
        const { isNew } = this.props;

        if (key === 'save' && !isNew) {
            return this.getPermissions({
                key: 'joins',
                items: [
                    {
                        key: 'templates',
                        rules: ['update'],
                    },
                ],
            });
        }

        return !isNew || key !== 'createPdf';
    }

    renderLink({ prop: key }) {
        const { loadingKey } = this.state;
        const link = this.links[key];

        return (
            <div className={`widget__headLink ${link.className}`}>
                <Animate className="widget__headLinkLoader _loader" isShow={loadingKey === key}>
                    <div className="widget__headLinkLoaderItem _loaderItem">
                        <Loader />
                    </div>
                </Animate>
                <div
                    className="widget__headLinkInner _click"
                    onClick={() => {
                        link.handler.call(this);
                    }}
                >
                    {link.content}
                </div>
            </div>
        );
    }

    handlerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    delete() {
        const { delete: deleteFn } = this.props;

        if (deleteFn) {
            this.handlerLoading('delete').then(() => {
                deleteFn({}).then(
                    () => {
                        this.handlerLoading(null);
                    },
                    () => {
                        this.handlerLoading(null);
                    },
                );
            });
        }
    }

    save() {
        this.handlerLoading('save').then(() => {
            document.dispatchEvent(
                new CustomEvent('joinTemplatesSave', {
                    detail: {
                        callback: () => {
                            this.handlerLoading(null);
                        },
                    },
                }),
            );
        });
    }

    async createPdf() {
        await this.handlerLoading('createPdf');

        document.dispatchEvent(
            new CustomEvent('joinTemplatesCreatePdf', {
                detail: {
                    callback: () => {
                        this.handlerLoading(null);
                    },
                },
            }),
        );
    }

    render() {
        const { loadingKey } = this.state;
        const items = this.orderLinks.filter(this.filterLink);

        return (
            <div className="widget__headActionsGroups _row">
                <ListAbsoluteMain
                    className="widget__headActionsGroup _dynamic"
                    items={items}
                    renderItem={this.renderLink}
                    classNameItem="widget__headLink"
                    prop="key"
                    itemParams={['offsetRight']}
                />
                {this.getPermissions({
                    key: 'joins',
                    items: [
                        {
                            key: 'templates',
                            rules: ['delete'],
                        },
                    ],
                }) && (
                    <div className="widget__headActionsGroup">
                        <div className="widget__headLink _delete" onClick={this.delete}>
                            <Animate
                                className="widget__headLinkLoader _loader"
                                isShow={loadingKey === 'delete'}
                            >
                                <div className="widget__headLinkLoaderItem _loaderItem">
                                    <Loader />
                                </div>
                            </Animate>
                            <div className="widget__headLinkInner _click">Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pagesStore: state.pages,
        levels: state.levels,
        user: state.user,
    };
}

export default connect(mapStateToProps)(HeadInner);

HeadInner.propTypes = {
    isNew: PropTypes.bool,
    typeOrganization: PropTypes.string,
    pagesStore: PropTypes.object,
    checkRights: PropTypes.func,
    levels: PropTypes.array,
    delete: PropTypes.func,
};
