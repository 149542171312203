import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getHeaders from '../../../functions/getHeaders';
import { inputValidate } from '../../../functions/inputValidate';
import setAnimate from '../../../functions/setAnimate';
import setNotification from '../../../functions/setNotification';
import setSpacesInText from '../../../functions/setSpacesInText';

import Button from '../../Button.jsx';
import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import ListAbsoluteMain from '../../ListAbsoluteMain.jsx';
import AuthForm from './Form.jsx';

class AuthReg extends AuthForm {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 'start',
        };

        this.registration = this.registration.bind(this);

        this.progress = React.createRef();
        this.parent = React.createRef();
    }

    name = 'registration';

    orderGroups = ['reg-name', 'reg-contacts', 'reg-passwords'];

    allSteps = ['start', 'end', 'final'];

    blocks = {
        start: {
            groups: ['reg-name', 'reg-contacts'],
            render() {
                return (
                    <>
                        <div className="authForm__contentGroups">
                            {['reg-name', 'reg-contacts'].map((nameGroup) =>
                                this.renderGroup({ name: nameGroup }),
                            )}
                        </div>
                        <div className="authForm__contentButton">
                            <Button
                                className="_mainNotBorder _medium2Size"
                                icon={{
                                    type: 'end',
                                    name: 'arrow-next-3',
                                }}
                                onClick={() => {
                                    this.handlerStep({ currentStep: 'end' });
                                }}
                            >
                                Далее
                            </Button>
                        </div>
                        <div className="authForm__contentLinks _row">
                            <Link className="authForm__contentLink" pageName="login">
                                У меня уже есть аккаунт
                            </Link>
                        </div>
                    </>
                );
            },
        },
        end: {
            groups: ['reg-passwords'],
            render() {
                const { loadingKey } = this.state;

                return (
                    <>
                        <div className="authForm__contentGroups">
                            {['reg-passwords'].map((nameGroup) =>
                                this.renderGroup({ name: nameGroup }),
                            )}
                        </div>
                        <div className="authForm__contentButton">
                            <Button
                                className="_mainNotBorder _medium2Size"
                                onClick={this.registration}
                                showLoader={loadingKey === 'registration'}
                            >
                                Зарегистрироваться
                            </Button>
                        </div>
                        <div className="authForm__contentInfo">
                            Нажимая кнопку «Зарегистрироваться», вы&nbsp;подтверждаете своё согласие
                            с условиями <span className="_click">пользовательского соглашения</span>{' '}
                            и <span className="_click">политики обработки персональных данных</span>
                            .
                        </div>
                        <div className="authForm__contentLinks _row">
                            <div
                                className="authForm__contentLink _click"
                                onClick={() => {
                                    this.setState({ currentStep: 'start' });
                                }}
                            >
                                <i className="authForm__contentLinkIcon">
                                    <Icon name="arrow-prev" />
                                </i>
                                Назад
                            </div>
                        </div>
                    </>
                );
            },
        },
        final: {
            groups: [],
            render() {
                return (
                    <>
                        {/* <div className="authForm__contentIcon">
                            <Icon name="forget-password-complete" />
                        </div> */}
                        <p
                            className="authForm__contentDescription _notMargin"
                            dangerouslySetInnerHTML={{
                                __html: setSpacesInText(
                                    'Вы успешно зарегистрировались в системе CRM LIVECARGO! На указанную почту было выслано письмо с инструкцией для завершения регистрации.',
                                ),
                            }}
                        ></p>
                    </>
                );
            },
        },
    };

    progressResult = 0;

    checkProgress() {
        if (this.progress?.current) {
            const { fields } = this.state;
            const progressBox = this.progress.current;
            const allFields = this.getAllFields().filter((item) => item !== 'thirdName');
            let { length: lengthValidateFields } = allFields.filter((nameField) =>
                inputValidate({ name: nameField, value: fields[nameField].value }),
            );

            if (
                fields?.password.value &&
                fields?.passwordAgain.value &&
                fields?.password.value === fields?.passwordAgain.value
            ) {
                lengthValidateFields += 1;
            }

            const prevProgressResult = this.progressResult;
            const progressResult = (lengthValidateFields / (allFields.length + 1)) * 100;

            this.prevLengthValidateFields = lengthValidateFields;

            if (this.idProgressAnimate) {
                cancelAnimationFrame(this.idProgressAnimate);
            }

            if (progressResult !== prevProgressResult) {
                this.idProgressAnimate = setAnimate({
                    draw: (progress) => {
                        this.progressResult = +(
                            prevProgressResult +
                            (progressResult - prevProgressResult) * progress
                        ).toFixed(0);

                        progressBox.innerHTML = this.progressResult;
                    },
                    duration: 300,
                });
            }
        }
    }

    getProgressStatus() {
        let progress = 0;

        if (this.progress.current) {
            const { fields } = this.state;
            const allFields = this.getAllFields().filter((item) => item !== 'thirdName');

            const { length: lengthValidateFields } = allFields.filter((nameField) =>
                inputValidate({ name: nameField, value: fields[nameField].value }),
            );

            progress = (lengthValidateFields / allFields.length) * 100;
        }

        if (progress === 0) {
            return '';
        }

        if (progress < 50) {
            return '_low';
        }

        if (progress < 100) {
            return '_medium';
        }

        if (progress === 100) {
            return '_high';
        }

        return '';
    }

    handlerStep({ currentStep }) {
        const prevActions = () =>
            new Promise((resolve) => {
                if (currentStep === 'end') {
                    const orderFields = this.getAllFields(this.blocks.start.groups).filter(
                        (item) => item !== 'thirdName',
                    );

                    const { isSuccess, errorFieldName } = this.checkValidateFields(orderFields);

                    if (!isSuccess) {
                        if (errorFieldName) {
                            const input = this.parent.current.querySelector(
                                `#input-auth-registration-${errorFieldName}`,
                            );

                            if (input) {
                                input.focus();
                            }
                        }
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            });

        prevActions().then(() => {
            this.setState({ currentStep });
        });
    }

    registration() {
        const { fields } = this.state;
        const resultFields = {};

        ['firstName', 'secondName', 'thirdName', 'email', 'phone', 'password'].forEach(
            (fieldName) => {
                resultFields[fieldName] = fields[fieldName].value;
            },
        );

        const orderFields = this.getAllFields().filter((item) => item !== 'thirdName');

        let { isSuccess, errorFieldName } = this.checkValidateFields(orderFields);

        if (fields.password.value !== fields.passwordAgain.value) {
            isSuccess = false;
            errorFieldName = 'passwordAgain';
        }

        if (!isSuccess) {
            if (errorFieldName) {
                const input = this.parent.current.querySelector(
                    `#input-auth-registration-${errorFieldName}`,
                );

                if (input) {
                    input.focus();
                }
            }
        } else {
            this.handlerLoading('registration').then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_API}/user`,
                        {
                            fields: resultFields,
                        },
                        { headers: getHeaders() },
                    )
                    .then(
                        (res) => {
                            const { success, data } = res.data;

                            if (success) {
                                this.handlerStep({ currentStep: 'final' });
                            } else {
                                const { message } = data;

                                if (message === 'User already register') {
                                    this.handlerStep({ currentStep: 'start' });

                                    setNotification({ notification: 'user-email-already-use' });

                                    this.handlerField({
                                        action: 'error',
                                        name: 'email',
                                        value: 'userAlreadyRegister',
                                    });
                                } else if (message === 'Phone already use') {
                                    this.handlerStep({ currentStep: 'start' });

                                    setNotification({ notification: 'user-phone-already-use' });

                                    this.handlerField({
                                        action: 'error',
                                        name: 'phone',
                                        value: 'userAlreadyRegister',
                                    });
                                }

                                this.handlerLoading(null);
                            }
                        },
                        () => null,
                    );
            });
        }
    }

    render() {
        const { currentStep, counterUpdateError } = this.state;

        return (
            <div ref={this.parent} className="authForm">
                <div className="authForm__inner">
                    <div className="authForm__head">
                        <div className="authForm__headInner">
                            <div className="authForm__headTitle">
                                <div className="authForm__headTitleInner">
                                    Регистрация <br />
                                    в&nbsp;системе
                                </div>
                            </div>
                            {this.renderPagination()}
                            <div className={`authForm__headProgress ${this.getProgressStatus()}`}>
                                <span ref={this.progress} className="authForm__headProgressResult">
                                    0
                                </span>
                                %
                            </div>
                        </div>
                    </div>
                    <ListAbsoluteMain
                        className="authForm__content _dynamic"
                        items={this.getOrderBlocks()}
                        renderItem={this.renderBlock}
                        classNameItem="authForm__contentBlock"
                        prop="id"
                        paramsParent={{ width: true }}
                        styles={['height']}
                        isNotParamsItem={true}
                        resizeParent={document.querySelector('.body')}
                        keyRender={counterUpdateError}
                        currentItemKey={currentStep}
                        allItems={this.allSteps}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(AuthReg);

AuthReg.propTypes = {
    setSuccessLogin: PropTypes.func,
};
