import lists from '../lists';

const listTagsGroups = {
    ...lists,
    query: [{ key: 'type', value: 'tagsGroups' }],
    emptyInfo: {
        title: 'Групп пока нет',
        description: 'Они будут отображаться здесь',
    },
} as const;

export default listTagsGroups;
