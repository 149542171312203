import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    const { loadingKey } = this.state;
    const { save, disabled, error } = this.props;

    if (!save) {
        return null;
    }

    return (
        <div className="v2widgetPage__foot">
            <AnimateChange
                className="v2widgetPage__footError _centerVertical"
                renderKey={error?.text}
                styles={['height']}
            >
                <>
                    {/* <div className="v2widgetPage__footErrorSupport">Ошибка:</div> */}
                    <div className="v2widgetPage__footErrorText">{error?.text}</div>
                </>
            </AnimateChange>

            <div className="v2widgetPage__footActions _ROW">
                <div className="v2widgetPage__footButton">
                    <Button
                        className="_main _whiteDisabled"
                        loading={loadingKey === 'save'}
                        onClick={this.save.bind(this)}
                        disabled={disabled}
                    >
                        Сохранить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default renderFoot;
