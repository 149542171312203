import handlerPopup from '@functions/handlerPopup.ts';

import BalanceI from '../types.ts';

const getActions: BalanceI['getActions'] = function () {
    return [
        {
            type: 'link',
            name: 'addUser',
            group: 'links',
            filterName: 'users',
            content: 'Добавить сотрудника',
            className: '_main',
            onClick: async () => {
                handlerPopup('userPopup', { isShow: true });
            },
        },
        {
            type: 'filter',
            name: 'filter',
            group: 'buttons',
            filterName: 'users',
        },
    ];
};

export default getActions;
