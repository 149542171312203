import CalendarI from '../types.ts';

const save: CalendarI['save'] = function () {
    const { currentDate, doubleCurrentDate, currentTime } = this.state;
    const { callback } = this.props;

    if (currentTime && currentDate) {
        if (currentTime.replace(/[^\d]/gi, '').length !== 4) {
            this.setState({ error: 'Укажите корректное время' });
        } else if (typeof callback === 'function') {
            const resultDate = new Date(currentDate);
            const [hours, minutes] = currentTime.split(':');

            resultDate.setHours(+hours);
            resultDate.setMinutes(+minutes);

            callback({
                date: resultDate.toISOString(),
                doubleDate: doubleCurrentDate?.toISOString(),
            });
        }
    }
};

export default save;
