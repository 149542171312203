import React from 'react';

import SwitchI from './types.ts';

class Switch extends React.Component<SwitchI['props']> implements SwitchI {
    constructor(props: SwitchI['props']) {
        super(props);
        this.state = {};
    }

    render() {
        const { name, value, onChange, disabled, className = '' } = this.props;

        return (
            <>
                <label className={`v2switch ${className}`}>
                    <input
                        type="checkbox"
                        className="v2switch__input"
                        onChange={() => {
                            if (!disabled && name && typeof onChange === 'function') {
                                onChange({ name, value: !value });
                            }
                        }}
                        checked={!!value}
                        disabled={disabled}
                    />
                    <div className="v2switch__inner">
                        <div className="v2switch__point" />
                    </div>
                </label>
            </>
        );
    }
}

export default Switch;
