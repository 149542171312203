import { FilterQueryT } from '@global/types.ts';

import PaysI from '../types.ts';

const getQuery: PaysI['getQuery'] = function (this: PaysI, { isStart, isUpdate }) {
    const query: FilterQueryT[] = [];
    const { groupId, executorId, levels } = this.props;

    if (groupId) {
        query.push({ key: 'groupId', value: groupId });
    }

    if (isStart || isUpdate) {
        query.push({ key: 'withGroup', value: true });
    }

    if (executorId) {
        query.push({ key: 'executorId', value: executorId });
    }

    if (groupId === 'main' && !executorId && levels[3]) {
        query.push({ key: 'payId', value: levels[3] });
    }

    return query;
};

export default getQuery;
