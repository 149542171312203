import DocT from '@global/models/Doc.ts';
import getListItems from '@requests/getListItems.ts';

import I from '../types.ts';

const getModel: I['getModel'] = async function () {
    const { id } = this.state;

    try {
        const { item } = await getListItems<DocT>({
            url: 'docs',
            query: [{ key: 'id', value: id! }],
        });

        return item;
    } catch (error) {
        return Promise.reject(error);
    }
};

export default getModel;
