import { WidgetActionsT } from '@components/crm/manual/widget/types.ts';

import I from '../types.ts';

const getActions: I['getActions'] = function (this: I) {
    const { user } = this.props;
    const actions = [
        {
            type: 'link',
            name: 'main',
            group: 'links',
            pageName: 'profile-main',
        },
    ] as WidgetActionsT[];

    if (user?.idOfCurrentCorporation !== 'admin') {
        actions.push({
            type: 'link',
            name: 'proxy',
            group: 'links',
            pageName: 'profile-proxy',
        });
    }

    return actions;
};

export default getActions;
