import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { CustomListenerT, StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';
import getQuery from './methods/getQuery.ts';
import handlerSocket from './methods/handlerSocket.ts';

import InvitesI from './types.ts';

import deleteJoin from './actions/deleteJoin.ts';
import sendSms from './actions/sendSms.ts';
import renderHead from './renders/renderHead.tsx';

class Invites extends WidgetWrapper<InvitesI['props'], InvitesI['state']> implements InvitesI {
    parent: InvitesI['parent'];
    tableUpdateItem: InvitesI['tableUpdateItem'];
    tableUpdateItems: InvitesI['tableUpdateItems'];

    constructor(props: InvitesI['props']) {
        super(props);
        this.state = {
            counter: 0,
            currentTab: 'all',
        };

        this.handlerSocket = this.handlerSocket.bind(this);

        this.parent = React.createRef();
    }

    getActions = getActions;
    getQuery = getQuery;
    getMoreActions = getMoreActions;

    handlerSocket = handlerSocket;

    deleteJoin = deleteJoin;
    sendSms = sendSms;

    renderHead = renderHead;

    componentDidMount(): void {
        const { user } = this.props;

        this.setState({ user });

        (document.addEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    componentWillUnmount(): void {
        (document.removeEventListener as CustomListenerT)('getSocketData', this.handlerSocket);
    }

    render() {
        const { counter, currentTab } = this.state;

        return (
            <>
                <Widget
                    name="joins"
                    title="Подключение"
                    description="Приглашения"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    className="_innerPadding"
                    filterQuery={(this as InvitesI).getFilterQuery?.()}
                >
                    <Widget
                        name="joins"
                        title="Подключение"
                        description="Приглашения"
                        descriptionInner={counter}
                        counter={counter}
                        isFull={true}
                        className="_white"
                        withBack={false}
                        inWidget={true}
                        renderHead={this.renderHead!.bind(this)}
                    >
                        <Table
                            name="joins"
                            isNotLink={true}
                            isInit={true}
                            callback={this.tableCallback!.bind(this)}
                            inWidget={true}
                            getQuery={this.getQuery!.bind(this)}
                            queryKey={currentTab}
                            otherData={{
                                getMoreActions: this.getMoreActions.bind(this),
                            }}
                            init={this.tableInit!.bind(this)}
                        />
                    </Widget>
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Invites);
