import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import checkValueOfEmpty from '../../../../functions/checkValueOfEmpty';
import AnimateChangeUp from '../../../AnimateChangeUp.jsx';

class ManualCardHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, description, classNameDescription = '', children, isReady } = this.props;

        return (
            <>
                <div
                    className={`manualCard__headTitle _row ${
                        checkValueOfEmpty(isReady) ? '_hide' : ''
                    } ${isReady ? '_show' : ''}`}
                >
                    {title}:
                    <AnimateChangeUp
                        className={`manualCard__headDescription ${classNameDescription}`}
                        renderKey={description}
                        offsetWidth={4}
                        isNotNullParentSize={true}
                        // isNotParams={true}
                    >
                        {description || '–'}
                    </AnimateChangeUp>
                    {children}
                </div>
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ManualCardHead);

ManualCardHead.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    classNameDescription: PropTypes.string,
    isReady: PropTypes.bool,
};
