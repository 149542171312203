import React from 'react';

import AnimateChange from '@components/animateChange/AnimateChange.tsx';
import Button from '@components/button/Button.tsx';

import I from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    const { items = [], currents = [] } = this.state;
    const currentItem = currents[0] ? items.find((item) => item._id === currents[0].id) : null;

    return (
        <>
            <div className="v2popup__foot">
                <div className="v2popupChatTemplateFoot">
                    <div className="v2popupChatTemplateFoot__inner _ROW">
                        <div className="v2popupChatTemplateFoot__button">
                            <Button
                                className="_main"
                                disabled={!currentItem}
                                onClick={this.save.bind(this)}
                            >
                                Выбрать
                            </Button>
                        </div>
                        <div className="v2popupChatTemplateFoot__info _COL">
                            <div className="v2popupChatTemplateFoot__infoSupport">Шаблон:</div>
                            <AnimateChange
                                className="v2popupChatTemplateFoot__infoResult"
                                renderKey={currents[0]?.id || false}
                                styles={['height']}
                            >
                                <>
                                    {currents[0] ? (
                                        <>
                                            <div className="v2popupChatTemplateFoot__infoResultInner">
                                                {currentItem?.name}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="v2popupChatTemplateFoot__infoResultInner _grey">
                                                Не выбран
                                            </div>
                                        </>
                                    )}
                                </>
                            </AnimateChange>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default renderFoot;
