const corporationAmountDoc = {
    fieldsOrder: ['amount'],
    fields: {
        amount: {
            support: 'Сумма',
            prop: 'amount',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
            max: 100_000_000,
        },
    },
} as const;

export default corporationAmountDoc;
