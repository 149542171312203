import React from 'react';
import { connect } from 'react-redux';

import Pages from '@components/pages/Pages.tsx';

import CorporationsI from './types.ts';

import pages from './static/pages.tsx';

class Corporations
    extends React.Component<CorporationsI['props'], CorporationsI['state']>
    implements CorporationsI
{
    constructor(props: CorporationsI['props']) {
        super(props);
        this.state = {};
    }

    pages = pages;

    render() {
        return (
            <>
                <Pages
                    className="body__innerPages _ITEMS"
                    classNamePage="body__innerPage _ITEM"
                    filter={(page) => page.parentName === 'corporations'}
                    pages={this.pages}
                    context={this}
                />
            </>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(Corporations);
