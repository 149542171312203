import React from 'react';
import { connect } from 'react-redux';

import Widget from '@components/crm/manual/widget/Widget.tsx';
import WidgetWrapper from '@components/crm/manual/widgetWrapper/WidgetWrapper.tsx';
import Table from '@components/crm/table/Table.tsx';
import { StoreT } from '@global/types.ts';

import getActions from './methods/getActions.ts';
import getMoreActions from './methods/getMoreActions.ts';

import TaxI from './types.ts';

import taxHandler from './actions/taxHandler.ts';

class Tax extends WidgetWrapper<TaxI['props'], TaxI['state']> implements TaxI {
    parent: TaxI['parent'];

    constructor(props: TaxI['props']) {
        super(props);
        this.state = {
            counter: 0,
        };

        this.parent = React.createRef();
    }

    getActions = getActions;
    getMoreActions = getMoreActions;

    taxHandler = taxHandler;

    componentDidMount(): void {
        this.setState({ user: this.props.user });
    }

    render() {
        const { counter, month } = this.state;

        return (
            <>
                <Widget
                    name="executorsTaxes"
                    title="Выплаты"
                    description="Налоговая копилка"
                    descriptionInner={counter}
                    counter={counter}
                    actions={this.getActions!()}
                    isFull={true}
                    filterQuery={(this as TaxI).getFilterQuery?.()}
                >
                    <Table
                        name="executorsTaxes"
                        isWrap={true}
                        isInit={true}
                        isNotLink={true}
                        isBlock={true}
                        isRowHeight={true}
                        callback={this.tableCallback!.bind(this)}
                        init={this.tableInit!.bind(this)}
                        otherData={{
                            getMoreActions: this.getMoreActions.bind(this),
                            month,
                        }}
                        getRowClassName={() => '_colCenter'}
                    />
                </Widget>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Tax);
