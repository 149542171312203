import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '../../components/Button.jsx';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import Icon from '../../components/Icon.jsx';
import Field from '../../components/Field.jsx';
import Link from '../../components/Link.jsx';

import getHeaders from '../../functions/getHeaders';
import handlerLoading from '../../functions/handlerLoading';
import getFormatedDate from '../../functions/getFormatedDate';
import handlerSupportCursor from '../../functions/handlerSupportCursor';
import getNotPopupPage from '../../functions/getNotPopupPage';

class Pep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handlerSearch = this.handlerSearch.bind(this);
        this.findDoc = this.findDoc.bind(this);

        this.parent = React.createRef();
    }

    getBlocks() {
        const { doc, error } = this.state;

        if (!doc && !error) {
            return [{ id: 'start', key: 'start' }];
        }

        if (error) {
            return [{ id: 'error', key: 'error' }];
        }

        return [{ id: `doc-${doc._id}`, key: 'success', ...doc }];
    }

    renderBlock({ item }) {
        const { key } = item;

        return (
            <div className="pep__block _col">
                {key === 'start' && (
                    <>
                        <div
                            className="pep__info _row _click"
                            onMouseEnter={(e) => {
                                handlerSupportCursor({
                                    action: 'enter',
                                    content:
                                        'Индентификатор документа отображается <br/>на последней странице в блоке подписи',
                                    e,
                                    data: { className: '_normalCase _center' },
                                });
                            }}
                            onMouseLeave={(e) => {
                                handlerSupportCursor({ action: 'leave', e });
                            }}
                        >
                            <i className="pep__infoIcon">
                                <Icon name="info" />
                            </i>
                            Где взять идентификатор?
                        </div>
                    </>
                )}
                {key === 'success' && (
                    <>
                        <div className="pep__result _success">
                            <div className="pep__resultHead _row">
                                <i className="pep__resultHeadIcon">
                                    <Icon name="pep-success" />
                                </i>
                                Найден электронный документ: {item.id}
                            </div>
                            <div className="pep__resultContent">
                                <div className="pep__resultBlock">
                                    <i className="pep__resultBlockIcon">
                                        <Icon name="pep-date" />
                                    </i>
                                    <div className="pep__resultBlockContent">
                                        <div className="pep__resultBlockTitle">
                                            Название и дата документа:
                                        </div>
                                        <div className="pep__resultBlockText">{item.name}</div>
                                    </div>
                                </div>
                                {item.executorSign && (
                                    <div className="pep__resultBlock">
                                        <i className="pep__resultBlockIcon">
                                            <Icon name="pep-user" />
                                        </i>
                                        <div className="pep__resultBlockContent">
                                            <div className="pep__resultBlockTitle">
                                                Подписан{' '}
                                                {getFormatedDate({
                                                    date: new Date(item.executorSign.date),
                                                    type: 'utc',
                                                })}{' '}
                                                UTC
                                            </div>
                                            <div className="pep__resultBlockText">
                                                {item.executorSign.name}
                                                <br />
                                                Идентификатор подписи: {item.executorSign.id}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {item.corporationSign && (
                                    <div className="pep__resultBlock">
                                        <i className="pep__resultBlockIcon">
                                            <Icon name="pep-corporation" />
                                        </i>
                                        <div className="pep__resultBlockContent">
                                            <div className="pep__resultBlockTitle">
                                                Подписан{' '}
                                                {getFormatedDate({
                                                    date: new Date(item.corporationSign.date),
                                                    type: 'utc',
                                                })}{' '}
                                                UTC
                                            </div>
                                            <div className="pep__resultBlockText">
                                                {item.corporationSign.name}
                                                <br />
                                                Идентификатор подписи: {item.corporationSign.id}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {key === 'error' && (
                    <>
                        <div className="pep__result _error">
                            <div className="pep__resultHead _row">
                                <i className="pep__resultHeadIcon">
                                    <Icon name="pep-error" />
                                </i>
                                Ничего не найдено, проверьте идентификатор на ошибки
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    handlerSearch({ action, value }) {
        const resultSearch = value?.replace(/[^\da-z-]/gi, '');

        return new Promise((resolve) => {
            if (action === 'change') {
                this.setState(
                    { search: resultSearch, ...(!value ? { doc: null, error: null } : {}) },
                    resolve,
                );
            } else {
                resolve();
            }
        });
    }

    findDoc() {
        const { search } = this.state;

        if (!search) {
            this.parent.current.querySelector('.pep__areaField').querySelector('input').focus();
        } else {
            handlerLoading.call(this, 'find', { error: null }).then(() => {
                axios
                    .get(`${process.env.REACT_APP_API}/pep?id=${search}`, { headers: getHeaders() })
                    .then((res) => {
                        handlerLoading.call(this, null);

                        const { success, data } = res.data;

                        if (success) {
                            this.setState({ doc: data });
                        } else {
                            this.setState({ error: true });
                        }
                    });
            });
        }
    }

    render() {
        const { search, loadingKey, error } = this.state;
        const { user } = this.props;

        return (
            <div ref={this.parent} className="pep _col">
                <div className="pep__inner _col">
                    <Link
                        className="pep__back _row"
                        pageName={user ? 'corporation' : 'public'}
                        changeIsHard={true}
                    >
                        <i className="pep__backIcon">
                            <Icon name="arrow-prev" />
                        </i>
                        На главную
                    </Link>
                    <Link className="pep__logo" changeIsHard={true} {...getNotPopupPage()}>
                        <img
                            src={require('../../img/crm/logo-center.svg').default}
                            alt=""
                            className="pep__logoIcon"
                        />
                    </Link>
                    <div className="pep__content _col">
                        <div className="pep__title">Проверка электронной подписи</div>
                        <p className="pep__description">
                            Введите идентификатор документа
                            <br />в поле ниже:
                        </p>
                        <div className={`pep__area _row ${error ? '_error' : ''}`}>
                            <div className="pep__areaField">
                                <Field
                                    keyName="pepId"
                                    className="_grey"
                                    callback={this.handlerSearch}
                                    value={search}
                                />
                            </div>
                            <div className="pep__areaButton">
                                <Button
                                    className="_main"
                                    onClick={this.findDoc}
                                    showLoader={loadingKey === 'find'}
                                >
                                    Найти
                                </Button>
                            </div>
                        </div>
                        <ListAbsoluteMain
                            className="pep__blocks"
                            items={this.getBlocks()}
                            renderItem={this.renderBlock}
                            classNameItem="pep__block"
                            prop="id"
                            paramsParent={{ width: true }}
                            styles={['height']}
                            isNotParamsItem={true}
                        />
                    </div>
                    <p className="pep__copy">livecargo {new Date().getFullYear()}</p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(Pep);

Pep.propTypes = {
    user: PropTypes.object,
    counterChangePage: PropTypes.number,
};
