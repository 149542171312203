const countries = {
    colsOrder: ['name', 'code', 'type'],
    cols: {
        name: {
            content: 'Название страны',
            width: 70,
        },
        code: {
            content: 'Код',
            width: 15,
        },
        type: {
            content: 'Группа',
            width: 15,
        },
    },
    deletesCols: [],
    url: 'countries',
    emptyInfo: {
        title: 'Стран пока нет',
        description: 'Вы можете создать страну по кнопке справа',
    },
} as const;

export default countries;
