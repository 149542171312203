import TagT from '@global/models/Tag';

const tags = {
    title: 'Данные',
    description: (model: TagT) => model?.name,
    fieldsOrder: ['name', 'idOfGroup', 'newGroup', 'color'],
    fields: {
        name: {
            support: 'Название',
            type: 'input',
            group: '1',
            width: 5,
        },
        idOfGroup: {
            support: 'Группа',
            prop: 'idOfGroup',
            selectTextKey: 'groupName',
            type: 'select',
            group: '1',
            width: 5,
            selectList: 'tagsGroups',
            selectProps: { name: 'groupName' },
        },
        newGroup: {
            support: 'Новая группа',
            type: 'input',
            group: '1',
            width: 6,
            disabled(model: TagT & { new?: boolean }) {
                return !model?.new;
            },
        },
        color: {
            support: 'Цвет',
            prop: 'color',
            selectTextKey: 'colorName',
            type: 'select',
            group: '1',
            width: 4,
            selectList: 'tagsGroupsColors',
            selectProps: { name: 'colorName' },
        },
    },
} as const;

export default tags;
