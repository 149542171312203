import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getCurrentPage from '../../../functions/getCurrentPage';

import pages from '../../../redux/pages';
import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import AppButton from './AppButton.jsx';

class PublicTop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.checkChange = this.checkChange.bind(this);
        this.pageScroll = this.pageScroll.bind(this);

        this.parent = React.createRef();
    }

    getNav() {
        return pages.filter((page) => page.parentName === 'public' || page.name === 'auth');
    }

    checkSmallMenu(scrollTop) {
        return scrollTop > 30;
    }

    pageScroll({ target }) {
        const { scrollTop } = target;

        this.setTopState({ scrollTop });
    }

    setTopState({ scrollTop }) {
        if (this.checkSmallMenu(scrollTop) && !this.state.isScrollActive) {
            this.setState({ isScrollActive: true });
        }

        if (!this.checkSmallMenu(scrollTop) && this.state.isScrollActive) {
            this.setState({ isScrollActive: false });
        }

        document.dispatchEvent(
            new CustomEvent('publicPageScroll', {
                detail: { scrollTop, isSmallMenu: this.checkSmallMenu(scrollTop) },
            }),
        );
    }

    init() {
        const { storePages } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => page.parentName === 'public',
        });

        const page = document.querySelector(`.public__page._${currentPage}`);

        if (page) {
            const scrollArea =
                page.querySelector('.public__pageBox:not(._full)') ||
                page.querySelector('.public__innerPage._show') ||
                page.querySelector('.publicLegalHeader__page._show');

            if (scrollArea) {
                scrollArea.addEventListener('scroll', this.pageScroll);
            }
        }
    }

    checkChange() {
        const { storePages, levels } = this.props;
        const currentPage = getCurrentPage({
            pages: storePages,
            filter: (page) => page.parentName === 'public',
        });
        let isChange = false;

        if (this.levels) {
            [1, 2, 3].forEach((key) => {
                if (levels[key] !== this.levels[key]) {
                    isChange = true;
                }
            });
        }

        if (currentPage !== this.currentPage || isChange) {
            this.setTopState({ scrollTop: 0 });

            this.init();

            this.currentPage = currentPage;
            this.levels = [...levels];
        }
    }

    componentDidMount() {
        const { isFooter } = this.props;

        if (!isFooter) {
            setTimeout(() => {
                this.checkChange();
            }, 100);
        }
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.checkChange();
        }, 100);
    }

    render() {
        const { isScrollActive } = this.state;
        const { storePages, isFooter, pageName, device, handlerMenu, user, callPopupHandler } =
            this.props;
        const nav = this.getNav();

        return (
            <div
                ref={this.parent}
                className={`publicTop ${isFooter ? '_footer' : ''} ${
                    isScrollActive ? '_scrollActive' : ''
                }`}
            >
                {isFooter && (
                    <>
                        {pageName === 'executors' && (
                            <>
                                <div className="publicTop__inner _row _top">
                                    <div className="publicTop__executorsFooter _row">
                                        <div className="publicTop__executorsFooterBlock _col">
                                            <div className="publicTop__executorsFooterSupport">
                                                Скачать «Мой налог»
                                            </div>
                                            <div className="publicTop__executorsFooterButtons _row">
                                                <div className="publicTop__executorsFooterButton">
                                                    <AppButton name="apple" className="_reverse" />
                                                </div>
                                                <div className="publicTop__executorsFooterButton">
                                                    <AppButton name="google" className="_reverse" />
                                                </div>
                                                <div className="publicTop__executorsFooterButton">
                                                    <AppButton
                                                        name="rustore"
                                                        className="_reverse"
                                                    />
                                                </div>
                                                <div className="publicTop__executorsFooterButton">
                                                    <AppButton
                                                        name="appgalery"
                                                        className="_reverse"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="publicTop__executorsFooterBlock _col _empty">
                                            <div className="publicTop__executorsFooterSupport">
                                                Полезные ссылки
                                            </div>
                                            <div className="publicTop__executorsFooterLinks _row">
                                                <a
                                                    href="https://nalog.ru/"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="publicTop__executorsFooterLink"
                                                >
                                                    Сайт ФНС России
                                                </a>
                                                <a
                                                    href="https://npd.nalog.ru/"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="publicTop__executorsFooterLink"
                                                >
                                                    Сайт НПД
                                                </a>
                                                <a
                                                    href="https://npd.nalog.ru/faq/"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="publicTop__executorsFooterLink"
                                                >
                                                    База знаний
                                                </a>
                                                <a
                                                    href="https://lknpd.nalog.ru/"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="publicTop__executorsFooterLink"
                                                >
                                                    Личный кабинет НПД «Мои Налог»
                                                </a>
                                                <a
                                                    href="https://npd.nalog.ru/app/#howreglk"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="publicTop__executorsFooterLink"
                                                >
                                                    Инструкция по постановке на учет для{' '}
                                                    {device === 'mobile' ? 'смз' : 'самозанятых'}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="publicTop__executorsFooterBlock _col _partners">
                                            <div className="publicTop__executorsFooterSupport">
                                                Официальные партнеры платформы:
                                            </div>
                                            <div className="publicTop__executorsFooterLogos _row">
                                                <img
                                                    src={require('../../../img/crm/public/executors-fns-partner-logo-1.png')}
                                                    alt=""
                                                    className="publicTop__executorsFooterLogo"
                                                />
                                                <img
                                                    src={require('../../../img/crm/public/executors-fns-partner-logo-2.png')}
                                                    alt=""
                                                    className="publicTop__executorsFooterLogo"
                                                />
                                                <img
                                                    src={require('../../../img/crm/public/executors-fns-partner-logo-3.png')}
                                                    alt=""
                                                    className="publicTop__executorsFooterLogo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                <div className="publicTop__inner _row _main">
                    <div className="publicTop__logo">
                        <Link
                            className="publicTop__logoLink"
                            pageName={user ? 'corporation' : 'auth'}
                        >
                            <img
                                src={require(
                                    `../../../img/crm/${isFooter ? 'logo-white.svg' : 'logo.svg'}`,
                                )}
                                alt=""
                                className="publicTop__logoIcon"
                            />
                        </Link>
                    </div>

                    <div className="publicTop__nav _row">
                        {nav.map((item) => {
                            const isCurrent =
                                getCurrentPage({
                                    pages: storePages,
                                    filter: (page) => page.parentName === 'public',
                                }) === item.name;

                            return (
                                <div
                                    className={`publicTop__navItem _col ${
                                        isCurrent ? '_current' : ''
                                    }`}
                                    key={item.name}
                                >
                                    <Link className="publicTop__navLink" pageName={item.name}>
                                        {item.contentOfLink}
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                    <div className="publicTop__actions _ROW">
                        {!isFooter && (
                            <div
                                className="publicTop__call _CLICK _ROW"
                                onClick={() => {
                                    callPopupHandler(true);
                                }}
                            >
                                <i className="publicTop__callIcon">
                                    <Icon name="public-call-button" />
                                </i>
                                Перезвоните мне
                            </div>
                        )}

                        <div className="publicTop__copy">
                            livecargo CRM 2018-{new Date().getUTCFullYear()} ©
                        </div>
                    </div>

                    {device === 'mobile' && !isFooter && (
                        <div
                            className="publicTop__menu"
                            onClick={() => {
                                handlerMenu(true);
                            }}
                        >
                            <Icon name="public-menu" />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        storePages: state.pages,
        device: state.device,
        user: state.user,
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(PublicTop);

PublicTop.propTypes = {
    storePages: PropTypes.object,
    isFooter: PropTypes.bool,
    pageName: PropTypes.string,
    device: PropTypes.string,
    handlerMenu: PropTypes.func,
    user: PropTypes.object,
    levels: PropTypes.array,
    callPopupHandler: PropTypes.func,
};
