import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Window from './Window.jsx';

class Windows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { windows, name, renderContent } = this.props;

        return Object.keys(windows)
            .filter((key) => windows[key].name === name)
            .map((key) => {
                const window = windows[key];

                return (
                    <Window key={key} id={key} {...window}>
                        {renderContent({ ...window })}
                    </Window>
                );
            });
    }
}

function mapStateToProps(state) {
    return {
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(Windows);

Windows.propTypes = {
    windows: PropTypes.object,
    name: PropTypes.string,
    renderContent: PropTypes.func,
};
