import React from 'react';
import { connect } from 'react-redux';

import WidgetCard from '@components/crm/widgetCard/WidgetCard.tsx';
import WidgetPage from '@components/crm/widgetPage/WidgetPage.tsx';
import WidgetPageWrapper from '@components/crm/widgetPageWrapper/WidgetPageWrapper.tsx';
import { StoreT } from '@global/types.ts';

import generate1CToken from './methods/generate1CToken.ts';
import save from './methods/save.ts';

import IntegrationsI from './types.ts';

class Main
    extends WidgetPageWrapper<IntegrationsI['props'], IntegrationsI['state']>
    implements IntegrationsI
{
    parent: IntegrationsI['parent'];

    constructor(props: IntegrationsI['props']) {
        super(props);
        this.state = {
            currentLimit: 'month',
        };

        this.parent = React.createRef();
    }

    save = save;
    generate1CToken = generate1CToken;

    render() {
        const { model, error } = this.state;

        return (
            <>
                <WidgetPage
                    name="corporationIntegrations"
                    save={this.save.bind(this)}
                    disabled={!this.checkChange().isChange}
                    error={error}
                >
                    <>
                        {model && (
                            <div className="v2widgetPage__cards _ROW _ROWWRAP">
                                <div className="v2widgetPage__card _bottom _main">
                                    <WidgetCard
                                        model={model.integration}
                                        name="corporationIntegrationTg"
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                        prop="integration"
                                    />
                                </div>
                                <div className="v2widgetPage__card _main">
                                    <WidgetCard
                                        model={model.integration}
                                        name="corporationIntegration1C"
                                        change={this.change.bind(this)}
                                        getValue={this.getValue.bind(this)}
                                        error={error}
                                        prop="integration"
                                        data={{ generate1CToken: generate1CToken.bind(this) }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                </WidgetPage>
            </>
        );
    }
}

function mapStateToProps(state: StoreT<'crm'>) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Main);
