const corporationTariff = {
    fieldsOrder: ['percent', 'min'],
    fields: {
        percent: {
            support: 'Процент',
            prop: 'percent',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: '%',
                position: 'end',
            },
            max: 100,
        },
        min: {
            support: 'Мин. сумма',
            prop: 'min',
            type: 'input',
            concat: {
                exp: /[^\d.]/gi,
                text: ' ₽',
                position: 'end',
            },
            max: 100_000,
        },
    },
} as const;

export default corporationTariff;
