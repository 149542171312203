import CalendarI from '../types.ts';

const getAllItems: CalendarI['getAllItems'] = function () {
    const { activeDate } = this.state;

    if (!activeDate) {
        return [];
    }

    const prevDate = new Date(activeDate);
    const nextDate = new Date(activeDate);

    prevDate.setMonth(prevDate.getMonth() - 1);
    nextDate.setMonth(nextDate.getMonth() + 1);

    return [this.getBlockId(prevDate), this.getBlockId(activeDate), this.getBlockId(nextDate)];
};

export default getAllItems;
