import React from 'react';
import setSpacesInText from './setSpacesInText';

export default function searchInText({ search, text }) {
    let resultContent = <i dangerouslySetInnerHTML={{ __html: setSpacesInText(text) }}></i>;
    let isNotSearch = false;

    const checkSearch = () => search && search.trim().length > 2;

    if (checkSearch()) {
        if (text.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1) {
            const titleSplit = text.split(new RegExp(search.trim(), 'ig'));
            const titleMatch = text.match(new RegExp(search.trim(), 'ig'));

            resultContent = titleSplit.map((titleItem, titleKey) => {
                const resultTitleContent =
                    titleItem[titleItem.length - 1] !== ' ' ? titleItem : titleItem;

                return (
                    <>
                        <i
                            dangerouslySetInnerHTML={{
                                __html: resultTitleContent,
                            }}
                        ></i>
                        {titleKey !== titleSplit.length - 1 && (
                            <span className="_search">{titleMatch[titleKey]}</span>
                        )}
                    </>
                );
            });
        } else {
            isNotSearch = true;
        }
    }

    return { content: resultContent, isNotSearch };
}
