import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../Button.jsx';
import Field from '../Field.jsx';
import Icon from '../Icon.jsx';

class EditorColor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    colors = ['#3e6aed', '#9da8b4', '#f25130', '#ffb400', '#5cc962', '#00b6ff', 'remove'];

    render() {
        const { color, colorText } = this.state;
        const { callback } = this.props;

        return (
            <div className="editor__actionAlert">
                <div className="editor__actionAlertInner _windowWithCursor _topRight">
                    <div className="editor__actionAlertHead">
                        <div className="editor__actionAlertTitle">Добавление цвета</div>
                        <div className="editor__actionAlertDescription">
                            Введите цвет в поле ниже или выберите из предложенных
                        </div>
                    </div>
                    <div className="editor__actionAlertContent _col">
                        <div className="editor__actionAlertRadios _row">
                            {this.colors.map((itemColor) => (
                                <label className={`editor__actionAlertRadio _${itemColor}`} key={itemColor}>
                                    <input
                                        type="radio"
                                        className="editor__actionAlertRadioInput"
                                        name="color"
                                        checked={color === itemColor}
                                        onChange={() => {
                                            this.setState({ color: itemColor, colorText: null });
                                        }}
                                    />
                                    <div
                                        className="editor__actionAlertRadioView _col _click"
                                        style={
                                            itemColor !== 'remove' ? { background: itemColor } : {}
                                        }
                                    >
                                        <i className="editor__actionAlertRadioIcon">
                                            <Icon name="done-3" />
                                        </i>
                                    </div>
                                </label>
                            ))}
                        </div>
                        <div className="editor__actionAlertField">
                            <Field
                                keyName="colorHex"
                                name="color"
                                support="Введите код цвета"
                                value={colorText}
                                callback={({ action, value }) => {
                                    if (action === 'change') {
                                        this.setState({ colorText: value, color: null });
                                    }
                                }}
                                className={`_grey`}
                            />
                        </div>
                        <div className="editor__actionAlertButton">
                            <Button
                                className="_main _medium2Size"
                                onClick={(e) => {
                                    callback(e, { color: colorText ? `#${colorText}` : color });

                                    this.setState({
                                        colorText: '',
                                        color: null,
                                    });
                                }}
                            >
                                Добавить цвет
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
    };
}

export default connect(mapStateToProps)(EditorColor);

EditorColor.propTypes = {
    callback: PropTypes.func,
};
