export default function getUpdateFormData(formData, targetFormData = new FormData()) {
    const objects = [];
    const notObject = [];

    for (const [key, value] of formData.entries()) {
        if (typeof value === 'object') {
            objects.push([key, value]);
        } else {
            notObject.push([key, value]);
        }
    }

    notObject.forEach(([key, value]) => {
        targetFormData.set(key, value);
    });

    objects.forEach(([key, value]) => {
        targetFormData.set(key, value);
    });

    return targetFormData;
}
