const fields = {
    name: {
        support: 'Ваше имя',
        reg: undefined,
        textarea: undefined,
    },
    phone: {
        support: 'Ваш телефон',
        reg: 'phone',
        textarea: undefined,
    },
    email: {
        support: 'Ваш email',
        reg: undefined,
        textarea: undefined,
    },
    comments: {
        support: 'Комментарий',
        reg: undefined,
        textarea: true,
    },
} as const;

export default fields;

const fieldsOrder = ['name', 'phone', 'email', 'comments'] as const;

export { fieldsOrder };
