import React from 'react';

import Balances from '../components/Balances/Balances';
import Docs from '../components/Docs/Docs';
import Logs from '../components/Logs/Logs';
import Main from '../components/Main/Main';
import Wallets from '../components/Wallets/Wallets.tsx';

import Contracts from '../../../../../joins/contracts/Contracts';
import Pays from '../../../../../pays/main/Main.tsx';
import InnerI from '../types';

const pages = {
    'manual-executors-inner-main': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <Main model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'manual-executors-inner-docs': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <Docs model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'manual-executors-inner-wallets': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <Wallets model={model!} setModel={this.setModel.bind(this)} />
                </>
            );
        },
    },
    'manual-executors-inner-pays': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <div className="body__innerPageWrapper">
                        <Pays groupId="main" executorId={model!._id} />
                    </div>
                </>
            );
        },
    },
    'manual-executors-inner-balance': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <div className="body__innerPageWrapper">
                        <Balances executorId={model!._id} />
                    </div>
                </>
            );
        },
    },
    'manual-executors-inner-contracts': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <div className="body__innerPageWrapper">
                        <Contracts executorId={model!._id} inWidget={false} />
                    </div>
                </>
            );
        },
    },
    'manual-executors-inner-logs': {
        render(this: InnerI) {
            const { model } = this.state;

            return (
                <>
                    <Logs id={model!._id} />
                </>
            );
        },
    },
} as const;

export default pages;
