import { ListenerT } from '@global/types.ts';

import I from '../types.ts';

const pinInit: I['pinInit'] = function () {
    const { pin } = this.props;

    if (pin) {
        const { galeryPinX, galeryPinY } = this.props;

        this.moveX = galeryPinX;
        this.endX = galeryPinX;
        this.moveY = galeryPinY;
        this.endY = galeryPinY;

        this.movePinArea();

        const pinBtn = this.getPinBtn();

        if (pinBtn) {
            (pinBtn.addEventListener as ListenerT<React.MouseEvent>)(
                'mousedown',
                this.eventStartHandler,
                { passive: false },
            );
            (document.addEventListener as ListenerT<React.MouseEvent>)(
                'mousemove',
                this.eventMoveHandler,
                { passive: false },
            );
            (document.addEventListener as ListenerT<React.MouseEvent>)(
                'mouseup',
                this.eventEndHandler,
                { passive: false },
            );
        }
    }
};

export default pinInit;
