import MobPopupI from '../types.ts';

const handlerStart: MobPopupI['handlerStart'] = function (e) {
    const { isInit } = this.state;

    if (this.animateId && !this.isHide) {
        cancelAnimationFrame(this.animateId);
    }

    if (
        !this.isProccess &&
        this.getScroll() < 3 &&
        (isInit === true || isInit === undefined) &&
        !this.isHide
    ) {
        this.isProccess = true;

        this.startY = e.changedTouches[0].pageY;

        this.disabledDocument(true);
    }
};

export default handlerStart;
