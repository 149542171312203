import getDateSumDays from './getDateSumDays';

export default function getQueryFilter({ filter = [] }) {
    const query = [];

    filter.forEach((block) => {
        if (block.choices.length) {
            block.choices.forEach((choice) => {
                query.push({ key: block.key, value: choice });
            });
        }

        if (block.key === 'dates' && block.firstDate) {
            const firstDate = new Date(block.firstDate);
            const keyFirst = getDateSumDays(
                firstDate.getDate(),
                firstDate.getMonth(),
                firstDate.getFullYear(),
            );

            query.push({ key: 'firstDate', value: keyFirst });
            query.push({ key: 'firstDateString', value: firstDate.toString() });

            if (block.secondDate) {
                const secondDate = new Date(block.secondDate);
                const keySecond = getDateSumDays(
                    secondDate.getDate(),
                    secondDate.getMonth(),
                    secondDate.getFullYear(),
                );

                query.push({ key: 'secondDate', value: keySecond });
                query.push({ key: 'secondDateString', value: secondDate.toString() });
            }
        }

        if (block.type === 'search' && block.search) {
            query.push({ key: block.key, value: block.search });
        }
    });

    return query;
}
